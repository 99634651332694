import { TabType } from '@components/tabs/tabs.types';
import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ButtonComponent } from '@components/button/button.component';
import { TableComponent } from '@components/table/table.component';
import { ContractAccessType } from '@human/access/types/contract.access.type';
import { UsersRoutes } from '../../../users.routes';
import { ContractAccessRepository } from '@human/access/repositories/contract/contract.repository';
import { ColumnProperties } from '@components/table/components/columns/column.properties';
import { hasPermissionUtilty } from '@utils/permission/has.permission.utility';
import { ScrollView, View } from 'react-native';

export function ContractsUpdateUserPart(properties: {
  navigation: NavigationProp<UsersRoutes>;

  route: RouteProp<UsersRoutes, 'update'>;
}) {
  const columns: ColumnProperties<ContractAccessType, []>[] = [
    {
      type: 'text',
      width: 169,
      onRender: (row) => row.clinicName,
    },
  ];

  if (hasPermissionUtilty({ name: 'access.roles.editor' })) {
    columns.unshift({
      type: 'flag',
      status: (row) => row.status,
      onPress: (row) => {
        properties.navigation.navigate(row.status ? 'contractDisable' : 'contractEnable', {
          level: 1,
          contractId: row.id,
          userId: properties.route.params.userId,
        });
      },
    });
  }

  const part: TabType = {
    name: 'contracts',
    icon: 'tag',
    content: () => {
      return (
        <ScrollView>
          <TableComponent<ContractAccessType, []>
            prefix={'access.contracts.list'}
            suffix={'list'}
            hideNoDataImage
            header={false}
            repository={(settings) =>
              new ContractAccessRepository().listByUserId({
                userId: properties.route.params.userId,
                search: settings.search,
              })
            }
            columns={columns}
          />
          <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
            <ButtonComponent
              icon="plus"
              prefix="access.users.update.contracts.addAll"
              onPress={() => {
                new ContractAccessRepository().createAll({
                  userId: properties.route.params.userId,
                });
              }}
            />
            <ButtonComponent
              icon="plus"
              prefix="access.users.update.contracts.add"
              onPress={() => {
                properties.navigation.navigate('contractCreate', {
                  level: 1,
                  userId: properties.route.params.userId,
                });
              }}
            />
          </View>
        </ScrollView>
      );
    },
  };

  return part;
}
