import { ButtonComponent } from '@components/button/button.component';
import { TableComponent } from '@components/table/table.component';
import { ActionAlertCrmRepository } from '@human/crm/repositories/action/alert/action.alert.repository';

export function ListActionAlertScreen(properties: {
  onDelete: (settings: { actionAlertId: string }) => void;
  onUpdate: (settings: { actionAlertId: string }) => void;
  onAdd: () => void;
  actionId: string;
  clinicId: string;
  patientId?: string;
  leadId?: string;
}) {
  return (
    <>
      <TableComponent<
        {
          actionAlertId: string;
          alertTime: number;
          expectedAlarmTime: Date;
        },
        []
      >
        key={'actions'}
        prefix={'crm.leads.update.actions.update.alert.list'}
        suffix={'list'}
        columns={[
          {
            type: 'icon',
            icon: 'trash-alt',
            onPress: (row) => {
              properties.onDelete({ actionAlertId: row.actionAlertId });
            },
          },
          {
            type: 'icon',
            icon: 'pencil-alt',
            onPress: (row) => {
              properties.onUpdate({ actionAlertId: row.actionAlertId });
            },
          },
          {
            type: 'date',
            renderDate: (row) => row.expectedAlarmTime,
            name: 'expectedAlarmTime',
          },
        ]}
        repository={() =>
          new ActionAlertCrmRepository().list({
            actionId: properties.actionId,
            clinicId: properties.clinicId,
            patientId: properties.patientId,
            leadId: properties.leadId,
          })
        }
        search={false}
        pagination={false}
      />
      <ButtonComponent
        icon="plus"
        prefix="crm.leads.update.actions.update.alert.add"
        onPress={() => {
          properties.onAdd();
        }}
      />
    </>
  );
}
