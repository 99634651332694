import { NavigationProp, RouteProp } from '@react-navigation/native';
import { TechniqueRoutes } from '../../technique.routes';
import { ModalComponent } from '@components/modal/modal.component';
import { FormComponent } from '@components/form/form.component';
import { TechniqueCrmRepository } from '@human/crm/repositories/technique/technique.repository';

export function DisableTechniqueScreen(properties: {
  navigation: NavigationProp<TechniqueRoutes>;
  route: RouteProp<TechniqueRoutes, 'disable'>;
}) {
  return (
    <ModalComponent icon="trash-alt" prefix="crm.techniques.disable">
      <FormComponent
        prefix="crm.techniques.disable.form"
        padding
        repository={{
          send: () => {
            return new TechniqueCrmRepository()
              .disable({
                techniqueId: properties.route.params.techniqueId,
              })
              .then(() => properties.navigation.goBack());
          },
        }}
        fields={[]}
      />
    </ModalComponent>
  );
}
