import { FormComponent } from '@components/form/form.component';
import { ModalComponent } from '@components/modal/modal.component';
import { ClinicAccessRepository } from '@human/access/repositories/clinic/clinic.repository';
import { EntryTrackAccessRepository } from '@human/access/repositories/entryTrack/entryTrack.repository';
import { EntryTracksRoutes } from '@human/access/sections/entryTracks/entryTracks.routes';
import { EntryTrackAccessType } from '@human/access/types/entryTrack.access.type';
import { CampaignCrmRepository } from '@human/crm/repositories/campaign/campaign.repository';
import { ContactCrmRepository } from '@human/crm/repositories/contact/contact.repository';
import { LeadCrmRepository } from '@human/crm/repositories/lead/lead.repository';
import { NavigationProp, RouteProp } from '@react-navigation/native';
import React from 'react';

export function CreateLeadByEntryTrack(properties: {
  entryTrack: EntryTrackAccessType;
  navigation: NavigationProp<EntryTracksRoutes>;
  route: RouteProp<EntryTracksRoutes, 'update'>;
}) {
  return (
    <ModalComponent icon="user-ninja" prefix="access.entrytracks.update.createLead">
      <FormComponent<{
        description: string;
        clinicId: string;
        contactId: string;
        campaignId: string;
      }>
        prefix="crm.leads.create.form"
        padding
        repository={{
          send: async (data) => {
            await new LeadCrmRepository().create({
              item: data.item,
            });
            await new EntryTrackAccessRepository().confirmReentry({
              entryTrackId: properties.entryTrack.id,
            });
            properties.navigation.goBack();
          },
          get: async () => ({
            clinicId: properties.entryTrack.clinicId,
            contactId: properties.entryTrack.contactId,
            campaignId:
              properties.entryTrack.type === 'gravityForm'
                ? properties.entryTrack.campaignId
                : properties.entryTrack.campaignFbId,
          }),
        }}
        fields={[
          {
            type: 'text',
            name: 'description',
            description: true,
            required: false,
            validations: ['description'],
            lines: 6,
          },
          {
            type: 'select',
            name: 'clinicId',
            description: true,
            required: true,
            repository: ({ search }) => {
              return new ClinicAccessRepository().listForCombo({
                search,
                active: true,
              });
            },
          },
          {
            type: 'select',
            name: 'contactId',
            required: true,
            description: true,
            repository: ({ search }) => {
              return new ContactCrmRepository().listForCombo({
                search,
                active: true,
              });
            },
            pick: async ({ id }) =>
              new ContactCrmRepository().pickForCombo({
                contactId: id,
              }),
          },
          {
            type: 'select',
            name: 'campaignId',
            description: true,
            required: true,
            repository: ({ search }) => {
              return new CampaignCrmRepository().listForCombo({
                search,
                active: true,
              });
            },
          },
        ]}
      />
    </ModalComponent>
  );
}
