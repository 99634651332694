// Modules
import { MainTheme } from '@theme/main/main.theme';
import { StyleSheet } from 'react-native';

/**
 * Notes Styles
 * @constant {StyleSheet} NotesStyles
 */
export const NotesStyles = StyleSheet.create({
  header: {
    flexDirection: 'row',
    padding: 20,
    gap: 15,
    alignItems: 'center',
  },
  messageHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 30,
  },
  actionsContainer: {
    flexDirection: 'row',
    gap: 5,
  },
  actionIcon: {
    height: 20,
    width: 20,
  },
  actionIconShape: {
    fontSize: 20,
    color: 'rgba(255, 255, 255, 0.6)',
  },
  close: {
    position: 'absolute',
    top: 20,
    right: 25,
    zIndex: 2,
    height: 40,
    width: 40,
    borderRadius: 20,
    justifyContent: 'center',
  },
  iconShape: {
    borderRadius: 20,
    justifyContent: 'center',
    alignItems: 'center',
    color: 'rgb(237, 53, 145)',
  },
  container: {
    flex: 1,
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    color: 'rgb(17, 27, 34)',
  },
  subtitle: {
    fontSize: 13,
    fontWeight: '100',
    color: 'rgb(17, 27, 34)',
  },

  message: {
    margin: 20,
    marginBottom: 15,
    marginTop: 0,
    maxWidth: '70%',
    backgroundColor: 'rgb(60, 76, 88)',
    padding: 19,
    borderRadius: 10,
    color: '#fff',
    alignSelf: 'flex-start',
    gap: 10,
  },
  text: {
    color: '#fff',
    marginVertical: 8,
    lineHeight: 20,
  },
  position: {
    color: 'rgba(255, 255, 255, 0.6)',
    fontSize: 8,
    fontWeight: 'bold',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    padding: 5,
    textTransform: 'uppercase',
    letterSpacing: 1,
    paddingHorizontal: 10,
    borderRadius: 35,
    borderTopLeftRadius: 0,
    alignSelf: 'flex-start',
  },
  date: {
    color: 'rgba(255, 255, 255, 0.6)',
    fontSize: 12,
    fontWeight: 'bold',
    textAlign: 'right',
  },

  avatar: {},
  author: {
    fontSize: 16,
    color: 'rgb(255, 101, 178)',
  },
  when: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  hour: {
    color: 'rgba(255, 255, 255, 0.6)',
    fontSize: 12,
    fontWeight: 'bold',
    textAlign: 'right',
  },
  input: {
    backgroundColor: 'rgba(60, 76, 88, 0.32)',
    padding: 20,
    paddingRight: 60,
    borderRadius: 10,
    margin: 10,
    fontSize: 14,
    height: 150,
  },
  darkInput: {
    color: 'rgba(255, 255, 255, 0.8)',
  },
  lightInput: {
    color: 'rgb(17, 27, 34)',
  },
  sendContainer: {
    position: 'absolute',
    bottom: 25,
    right: 30,
    height: 40,
    width: 40,
    borderRadius: 20,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: MainTheme.primaryColor,
  },
  send: {
    color: 'rgb(255, 255, 255)',
    fontSize: 25,
  },
});
