import { IconType } from '../../../components/icon/icon.types';
import { AuthorizedSpaces } from './authorized.spaces';

const getTag = () => process.env.TAG ?? '1.0.0';

export const AuthorizedSections: {
  [key in keyof AuthorizedSpaces]: {
    icon: IconType;
    keywords: string[];
    version: string;
    permission?: string;
  };
} = {
  desktop: {
    icon: 'desktop',
    keywords: [],
    version: '',
  },
  crm: {
    icon: 'users',
    keywords: ['space.card.leads', 'space.card.patients'],
    version: getTag(),
    permission: 'crm',
  },
  access: {
    icon: 'lock',
    keywords: ['space.card.users', 'space.card.roles'],
    version: getTag(),
    permission: 'access',
  },
  board: {
    icon: 'tachometer-alt',
    keywords: ['space.card.dashboard'],
    version: getTag(),
    permission: 'board',
  },
  // tory: {
  //   icon: 'flask',
  //   keywords: ['laboratory', 'lab', 'science'],
  //   version: '1.3',
  //   time: '2024-05-21',
  //   permission: 'tory',
  // },
  profile: {
    icon: 'user',
    keywords: ['space.card.account', 'space.card.password'],
    version: getTag(),
    permission: 'profile',
  },
};
