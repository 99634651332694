import { TextComponent } from '@components/text/text.component';
import { View } from 'react-native';
import { ProfileStyles } from './profile.styles';
import { AvatarComponent } from './components/avatar/avatar.component';
import { useUserState } from '@states/user/user.state';

export function ProfileComponent() {
  const { user } = useUserState();

  return (
    <View style={ProfileStyles.rowContainer}>
      <AvatarComponent selfie={user.selfie} companyCode={'OVO'} />
      <View style={ProfileStyles.textContainer}>
        <TextComponent
          lines={1}
          text={`${user.name} ${user.surname}`}
          bold
          style={ProfileStyles.nameText}
        />
        <TextComponent lines={1} text={user.email ?? ''} style={ProfileStyles.emailText} />
      </View>
    </View>
  );
}
