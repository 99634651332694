import { IconComponent } from '@components/icon/icon.component';
import { useState } from 'react';
import { TouchableOpacity, View } from 'react-native';
import { WidgetComponent } from './components/widget/widget.component';
import { HeatComponent } from './components/heat/heat.component';
import { AnalyticsComponent } from './components/analytics/analytics.component';
import { PerformanceStyles, PerformanceWebStyle } from './performance.styles';
import { ProfileComponent } from './components/profile/profile.component';
import { SessionComponent } from './components/session/session.component';

/**
 * Perfomance Component
 * @description Sección de rendimiento de la aplicación que se muestra a la derecha de la escena.
 * Sirve para mostrar metricas relacionadas con las actividades del usuario.
 *
 * @returns {JSX.Element}
 */

export function PerformanceComponent() {
  const [status, setStatus] = useState(false);

  // deuda técnica de usar el div, pero debido a que en la versión movil no tenemos animaciones y queremos animarlo con css por su performance. No hacer animaciones con reanimated.
  return (
    <div className="PerformanceComponent" style={PerformanceWebStyle(status)}>
      <TouchableOpacity onPress={() => setStatus(!status)} style={PerformanceStyles.statusButton}>
        <IconComponent
          name={status ? 'angle-right' : 'angle-left'}
          iconStyle={PerformanceStyles.statusButtonIcon}
        />
      </TouchableOpacity>
      <View style={PerformanceStyles.container}>
        <ProfileComponent />
        <WidgetComponent name="performance" label={'Actividades'} icon="robot">
          <HeatComponent />
        </WidgetComponent>
        <WidgetComponent name="analytics" icon="chart-pie">
          <AnalyticsComponent />
        </WidgetComponent>
        <WidgetComponent name="sessions" icon="shield-alt">
          <SessionComponent />
        </WidgetComponent>
      </View>
    </div>
  );
}
