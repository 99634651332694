import { NavigationProp, RouteProp } from '@react-navigation/native';
import { TasksRoutes } from '../../tasks.routes';
import { SceneComponent } from '@components/scene/scene.component';
import { CrmSections } from '@human/crm/crm.sections';
import { TableComponent } from '@components/table/table.component';
import { ActionCrmRepository } from '@human/crm/repositories/action/action.repository';
import { IconType } from '@components/icon/icon.types';
import { getTimeDiff } from '@utils/timeDiff/getTimeDiff.utility';
import { hasPermissionUtilty } from '@utils/permission/has.permission.utility';
import { ColumnProperties } from '@components/table/components/columns/column.properties';
import { useTranslation } from 'react-i18next';

export function ListTasksScreen(properties: {
  navigation: NavigationProp<TasksRoutes>;
  route: RouteProp<TasksRoutes, 'list'>;
}) {
  const { t } = useTranslation();
  const links: {
    name: string;
    icon: IconType;
    onPress: () => void;
  }[] = [];
  if (
    hasPermissionUtilty({
      name: 'crm.tasks.creator',
    })
  ) {
    links.push({
      icon: 'plus',
      name: 'create',
      onPress: () => properties.navigation.navigate('actionCreateFromCalendar', {}),
    });
  }

  const columns: ColumnProperties<
    {
      id: string;
      status: boolean;
      contactTime: 'MORNING' | 'AFTERNOON' | 'NO_PREFERENCE';
      subject: string;
      activityId: string;
      activityName: string;
      activityColor: string;
      activityIcon: string;
      resultName: string;
      contactSurname: string;
      leadId: string;
      patientId?: string;
      clinicId: string;
      clinicName: string;
      clinicColor?: string;
      contactId: string;
      contactName: string;
      responsibleName: string;
      responsibleSurname: string;
      resultId: string;
      started: Date;
      finished: Date;
      created: Date;
      selfie: string;
    },
    []
  >[] = [
    {
      type: 'icon',
      renderIcon: () => (hasPermissionUtilty({ name: 'crm.tasks.editor' }) ? 'pencil-alt' : 'eye'),
      onPress: (row) => {
        properties.navigation.navigate('actionUpdate', {
          activityId: row.activityId,
          actionId: row.id,
          clinicId: row.clinicId,
          leadId: row.leadId,
          patientId: row.patientId,
          contactId: row.contactId,
          level: 1,
        });
      },
    },
    {
      type: 'iconText',
      name: 'activity',
      color: (row) => row.activityColor,
      text: (row) => row.activityName,
      icon: (row) => row.activityIcon as IconType,
    },
    {
      type: 'taskStatus',
      name: 'resultId',
      width: 150,
      background: () => 'white',
      color: (row) => (row.resultId ? 'rgba(186, 224, 144, 0.40)' : '#FCEEE2'),
      textColor: (row) => (row.resultId ? 'rgb(40, 167, 69)' : '#f0ad4e'),
      onRender: (row) =>
        row.resultId ? t('common.taskProgress.COMPLETED') : t('common.taskProgress.PENDING'),
    },
    {
      type: 'text',
      name: 'resultName',
      width: 200,
      onRender: (row) => row.resultName ?? t('common.tasks.noResult'),
      color: (row) => (row.resultName ? 'black' : 'grey'),
    },
    {
      type: 'text',
      name: 'contact',
      onRender: (row) => `${row.contactName} ${row.contactSurname}`,
      width: 200,
    },
    {
      type: 'contactTime',
      name: 'contactTime',
      width: 150,
      onRender: (row) => row.contactTime,
    },
    {
      type: 'clinic',
      name: 'clinic',
      onRender: (row) => row.clinicName,
      color: (row) => row.clinicColor,
      width: 200,
    },
    {
      type: 'text',
      name: 'subject',
      onRender: (row) => row.subject ?? '',
      width: 250,
    },
    {
      type: 'user',
      name: 'user',
      onRender: (row) => `${row.responsibleName} ${row.responsibleSurname}`,
      onRenderSelfie: (row) => row.selfie,
      width: 200,
    },
    {
      type: 'date',
      name: 'started',
      renderDate: (row) => row.started,
    },
    {
      type: 'text',
      name: 'finished',
      width: 200,
      onRender: (row) => getTimeDiff({ started: row.started, finished: row.finished }),
    },
  ];

  if (hasPermissionUtilty({ name: 'crm.tasks.editor' })) {
    columns.unshift({
      type: 'flag',
      status: (row) => row.status,
      onPress: (row) => {
        properties.navigation.navigate(row.status ? 'actionDisable' : 'actionEnable', {
          actionId: row.id,
          clinicId: row.clinicId,
          leadId: row.leadId,
        });
      },
    });
  }

  return (
    <SceneComponent
      multiWorkspace
      icon={CrmSections.tasks.icon}
      prefix={'crm.tasks.list'}
      links={links}
    >
      <TableComponent<
        {
          id: string;
          status: boolean;
          contactTime: 'MORNING' | 'AFTERNOON' | 'NO_PREFERENCE';
          subject: string;
          activityId: string;
          activityName: string;
          activityColor: string;
          activityIcon: string;
          contactSurname: string;
          leadId: string;
          patientId?: string;
          clinicId: string;
          clinicName: string;
          clinicColor?: string;
          contactId: string;
          contactName: string;
          responsibleName: string;
          responsibleSurname: string;
          resultId: string;
          resultName: string;
          started: Date;
          finished: Date;
          created: Date;
          selfie: string;
        },
        []
      >
        toggles={[
          {
            name: 'seeDisabled',
            prefix: 'common.tasks',
            label: 'disabled',
            defaultValue: false,
          },
        ]}
        dates
        completed
        prefix={'crm.tasks.list'}
        suffix="list"
        repository={(settings) =>
          new ActionCrmRepository().list({
            ...settings,
            page: settings.page,
            limit: settings.limit,
            search: settings.search,
            completed: settings.completed,
            ...(settings.dates !== undefined && {
              dates: { start: settings.dates.start, end: settings.dates.end },
            }),
          })
        }
        columns={columns}
      />
    </SceneComponent>
  );
}
