import { NavigationContainer } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import 'react-native-gesture-handler';
import { MainApplication } from './src/application.main';
import './src/i18n';
import { FontUtility } from './src/utils/font/font.utility';
import { NavigationConstants } from './src/constants/navigation.constants';
import { ToastProvider } from 'react-native-toast-notifications';
import { IconComponent } from '@components/icon/icon.component';
import { ToolProvider } from './src/context/tool/tool.context';
import { ToasterProvider } from './src/context/toaster/toaster.context';
import * as SplashScreen from 'expo-splash-screen';
import { useValidateHook } from '@hooks/session/validate.hook';
import { PostSplashComponent } from '@components/postSplash/postSplash.component';
import { linking } from './App.linking';

SplashScreen.preventAutoHideAsync();

export default function App() {
  const [isFontLoaded, setIsFontLoaded] = useState(false);
  const isAuthenticated = useValidateHook();

  // This useEffect initializes the market with the test data and loads the source.
  useEffect(() => {
    setTimeout(() => {
      FontUtility().then(() => setIsFontLoaded(true));
    }, 1000);
  }, []);

  if (!isFontLoaded || isAuthenticated === undefined) {
    return <PostSplashComponent />;
  }

  SplashScreen.hide();

  return (
    <View
      style={{
        overflow: 'hidden',
        borderWidth: 1,
        flex: 1,
      }}
    >
      {/* <DeviceIndicator /> */}
      <NavigationContainer
        documentTitle={{
          formatter: (options, route) =>
            `${NavigationConstants.title} | ${options?.title ?? route?.name}`,
        }}
        linking={linking}
      >
        <ToastProvider
          successIcon={<IconComponent name="check-circle" iconStyle={{ color: 'white' }} />}
        >
          <ToolProvider>
            <ToasterProvider>
              <MainApplication isAuthenticated={isAuthenticated} />
            </ToasterProvider>
          </ToolProvider>
        </ToastProvider>
      </NavigationContainer>
    </View>
  );
}
