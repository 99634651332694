import { TextComponent } from '@components/text/text.component';
import React, { createContext, useContext, useState, ReactNode, useRef } from 'react';
import { View } from 'react-native';
import { styles } from './tool.styles';
import { MAX_TOOLTIP_VISIBLE_TIME } from './tool.constants';

interface ToolState {
  updateTool: (props?: { content: string; left: number; top: number }) => void;
  tool?: {
    content: string;
    left: number;
    top: number;
  };
}

const ToolContext = createContext<ToolState | undefined>(undefined);

export const ToolProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [tool, setTool] = useState<{
    content: string;

    left: number;
    top: number;
  }>();

  const timeout = useRef<NodeJS.Timeout>();

  const updateTool = (props?: { content: string; left: number; top: number }) => {
    setTool(props);

    if (props?.content) {
      timeout.current && clearTimeout(timeout.current);
      timeout.current = setTimeout(() => {
        setTool(undefined);
      }, MAX_TOOLTIP_VISIBLE_TIME);
    }
  };

  return (
    <ToolContext.Provider value={{ updateTool, tool }}>
      {tool?.content ? (
        <View
          style={[styles.tooltipContainer, { left: tool.left, top: tool.top }]}
          pointerEvents="none"
        >
          <TextComponent text={tool.content} style={styles.tooltipText} />
        </View>
      ) : null}
      {children}
    </ToolContext.Provider>
  );
};

export const useTool = (): ToolState => {
  const context = useContext(ToolContext);
  if (!context) {
    throw new Error('useTool must be used within an ToolProvider');
  }
  return context;
};
