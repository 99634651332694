import { useEffect, useState } from 'react';
import { useWindowDimensions } from 'react-native';
import { DeviceType } from './device.types';

export function useDeviceHook() {
  const [device, setDevice] = useState<DeviceType>('desktop');
  const { width } = useWindowDimensions();
  useEffect(() => {
    if (width < 800) setDevice('phone');
    else if (width < 1200) setDevice('tablet');
    else if (width <= 1600) setDevice('mini-desktop');
    else setDevice('desktop');
  }, [width]);

  return {
    device,
    phone: device === 'phone',
    tablet: device === 'tablet',
    laptop: device === 'laptop',
    desktop: device === 'desktop',
    miniDesktop: device === 'mini-desktop',
  };
}
