import { ButtonComponent } from '@components/button/button.component';
import { TableComponent } from '@components/table/table.component';
import { ProgramTechniqueCrmRepository } from '@human/crm/repositories/programTechnique/programTechnique.repository';
import { ProgramsRoutes } from '@human/crm/sections/programs/programs.routes';
import { TechniqueCrmType } from '@human/crm/types/technique.crm.type';
import { NavigationProp } from '@react-navigation/native';
import { ScrollView } from 'react-native';

export function TechniquesUpdateProgramPart(properties: {
  level: number;
  programId: string;
  navigation: NavigationProp<ProgramsRoutes>;
}) {
  return (
    <ScrollView>
      <TableComponent<TechniqueCrmType, []>
        key={'techniques'}
        prefix={'crm.techniques.list'}
        suffix={'list'}
        hideNoDataImage
        repository={(settings) =>
          new ProgramTechniqueCrmRepository().list({
            programId: properties.programId,
            search: settings.search,
          })
        }
        columns={[
          {
            type: 'icon',
            icon: 'trash-alt',
            onPress: (row) => {
              properties.navigation.navigate('programTechniqueDelete', {
                programId: properties.programId,
                techniqueId: row.id,
                level: properties.level + 1,
              });
            },
          },
          {
            type: 'text',
            name: 'name',
            width: 200,
            onRender: (row) => row.name,
          },
          {
            type: 'date',
            name: 'created',
            renderDate: (row) => row.created,
          },
        ]}
      />
      <ButtonComponent
        icon="plus"
        prefix="crm.programs.update.techniques.create.title"
        onPress={() => {
          properties.navigation.navigate('programTechniqueCreate', {
            programId: properties.programId,
            level: properties.level + 1,
          });
        }}
      />
    </ScrollView>
  );
}
