import { FormComponent } from '@components/form/form.component';
import { ModalComponent } from '@components/modal/modal.component';
import { AccessSections } from '@human/access/access.sections';
import { DepartmentAccessRepository } from '@human/access/repositories/department/department.repository';
import { DepartmentAccessType } from '@human/access/types/department.access.type';
import { NavigationProp, RouteProp } from '@react-navigation/native';
import { DepartmentsRoutes } from '../../departments.routes';
import { LanguageAccessRepository } from '@human/access/repositories/language/language.repository';
import { CoworkerCrmRepository } from '@human/crm/repositories/coworker/coworker.repository';

export function CreateDepartmentScreen(properties: {
  navigation: NavigationProp<DepartmentsRoutes>;
  route: RouteProp<DepartmentsRoutes, 'departmentCreate'>;
}) {
  return (
    <ModalComponent icon={AccessSections.departments.icon} prefix="access.departments.create">
      <FormComponent<DepartmentAccessType>
        prefix="access.departments.create.form"
        padding
        repository={{
          send: (data) => {
            return new DepartmentAccessRepository()
              .create({
                item: {
                  ...data.item,
                  clinicId: properties.route.params.clinicId,
                },
              })
              .then(() => {
                properties.navigation.goBack();
              });
          },
        }}
        fields={[
          {
            type: 'text',
            name: 'name',
            description: true,
            required: true,
            validations: ['name'],
          },

          {
            type: 'select',
            name: 'languageId',
            description: true,
            required: true,
            repository: ({ search }) =>
              new LanguageAccessRepository().listForCombo({
                search,
                active: true,
              }),
          },
          {
            type: 'select',
            name: 'responsibleId',
            description: true,
            required: true,
            repository: ({ search }) =>
              new CoworkerCrmRepository().listForCombo({
                clinicId: properties.route.params.clinicId,
                search,
                active: true,
              }),
          },
        ]}
      />
    </ModalComponent>
  );
}
