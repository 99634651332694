import { Platform, View } from 'react-native';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { BoardRoutes } from './board.routes';
import { MenuComponent } from '../../../../../components/menu/menu.component';
import { BoardSections } from './board.sections';
import { RouteProp } from '@react-navigation/native';
import { AuthorizedSpaces } from '../../authorized.spaces';
import { useDeviceHook } from '@hooks/device/device.hook';
import { HeaderComponent } from '@components/menu/components/header/header.component';
import { SubordinatesScreen } from './screens/subordinates/subordinates.screen';
import { useMemo } from 'react';

const Drawer = createDrawerNavigator<BoardRoutes>();

const SaleFunnel = Platform.select({
  native: () => require('./screens/funnels/sale.funnel.screen.web').default,
  default: () => require('./screens/funnels/sale.funnel.screen').default,
})();

const Leads = Platform.select({
  native: () => require('./screens/leads/leads.screen').default,
  default: () => require('./screens/leads/leads.screen.web').default,
})();

const SaleSummary = Platform.select({
  native: () => require('./screens/summarySales/summarySales.screen').default,
  default: () => require('./screens/summarySales/summarySales.screen.web').default,
})();

const ExpectedInterventions = Platform.select({
  native: () => require('./screens/expectedInterventions/expectedInterventions.screen').default,
  default: () =>
    require('./screens/expectedInterventions/expectedInterventions.screen.web').default,
})();

const Marketing = Platform.select({
  native: () => require('./screens/marketing_v2/marketing.screen.mobile').default,
  default: () => require('./screens/marketing_v2/marketing.screen').default,
})();

export function BoardSpace(properties: { route: RouteProp<AuthorizedSpaces, 'board'> }) {
  const { phone, tablet } = useDeviceHook();

  const mobileConfig: Array<boolean> = [phone, tablet];

  const isMobile = mobileConfig.some((config) => config);

  return (
    <View
      style={{
        flex: 1,
        flexDirection: 'row',
        overflow: 'hidden',
      }}
    >
      <Drawer.Navigator
        drawerContent={(props) => {
          return <MenuComponent brand="human" {...props} sections={BoardSections} type="board" />;
        }}
        screenOptions={{
          headerTitle: () => <HeaderComponent type={'board'} brand={'human'} />,
          sceneContainerStyle: {
            backgroundColor: '#E4E4E4',
          },
          drawerStyle: {
            width: phone ? 350 : 400,
          },
          headerShown: !isMobile ? false : true,
          drawerType: !isMobile ? 'permanent' : 'slide',
          headerTintColor: 'rgb(237, 53, 145)',
        }}
      >
        <Drawer.Screen
          name="leads"
          component={Leads}
          initialParams={{
            groupId: properties.route.params.groupId,
          }}
        />
        <Drawer.Screen
          name="saleFunnel"
          component={SaleFunnel}
          initialParams={{
            groupId: properties.route.params.groupId,
          }}
        />
        <Drawer.Screen
          name="summarySales"
          component={SaleSummary}
          initialParams={{
            groupId: properties.route.params.groupId,
          }}
        />
        <Drawer.Screen
          name="expectedIntervention"
          component={ExpectedInterventions}
          initialParams={{
            groupId: properties.route.params.groupId,
          }}
        />
        <Drawer.Screen
          name="marketing"
          component={Marketing}
          initialParams={{
            groupId: properties.route.params.groupId,
          }}
        />
        <Drawer.Screen
          name="subordinates"
          component={SubordinatesScreen}
          initialParams={{
            groupId: properties.route.params.groupId,
          }}
        />
      </Drawer.Navigator>
    </View>
  );
}
