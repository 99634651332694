import { StyleSheet } from 'react-native';

export const filterBadgeStyles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: 24,
    marginBottom: 15,
  },
  text: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: 12,
    height:24,
    paddingHorizontal: 8,
    backgroundColor: '#cacaca',
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    lineHeight: 24,
  },
  iconContainer: {
    paddingHorizontal: 6,
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    height:24,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    justifyContent: 'center',
  },
  icon: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: 11,
  },
});
