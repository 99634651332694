import { StyleSheet } from 'react-native';

export const DepartmentRowStyles = StyleSheet.create({
  rowContainer: {
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-between',
    padding: 5,
    paddingVertical: 5,
    borderBottomWidth: 0.5,
    borderBottomColor: '#ddd',
    gap: 20,
  },
  rowContainerMobile: {
    flexDirection: 'row',
    padding: 5,
    paddingVertical: 5,
    borderBottomWidth: 0.5,
    borderBottomColor: '#ddd',
  },
  rowContainerEven: {
    backgroundColor: '#EDEDED',
  },
  rowContainerOdd: {
    backgroundColor: '#FFF',
  },
  rowSpaceColumnContainer: {
    flex: 3,
    justifyContent: 'center',
  },
  rowTotalColumnContainer: {
    flex: 1,
  },
  rowSpaceText: {
    fontWeight: '300',
    fontSize: 12,
    marginHorizontal: 10,
  },
  phoneRowSpaceText: {
    fontWeight: '300',
    fontSize: 16,
    marginHorizontal: 10,
  },
  rowText: {
    color: '#111b22',
    fontWeight: '300',
    fontSize: 12,
  },
  phoneRowText: {
    color: '#111b22',
    fontWeight: '300',
    fontSize: 16,
  },
  centeredRowText: {
    color: '#111b22',
    fontWeight: '300',
    fontSize: 16,
    textAlign: 'center',
  },
  rowColumnContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 10,
  },
  rowColumnContainerMobile: {
    width: 120,
    marginHorizontal: 10,
  },
});
