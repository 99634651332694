import { StyleSheet } from 'react-native';

export const AnalyticsStyles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    gap: 10,
  },
  analyticsWrapper: {
    flex: 1,
    flexDirection: 'column',
    backgroundColor: 'white',
    borderRadius: 4,
    padding: 15,
    gap: 12,
  },
  title: {
    fontSize: 11,
    color: '#999999',
    fontWeight: '300',
  },
  phoneTitle: {
    fontSize: 16,
    color: '#999999',
    fontWeight: '300',
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 20,
    flex: 1,
    gap: 20,
  },
  rowItem: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
    flex: 2,
  },
  mobileRowItemText: {
    fontSize: 16,
  },
  calculation: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  dataContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    backgroundColor: 'white',
    borderRadius: 4,
    padding: 12,
    marginTop: 10,
  },
  dataText: {
    fontSize: 22,
    fontWeight: '700',
  },
  phoneDataText: {
    fontSize: 26,
    fontWeight: '700',
  },
  pinkText: {
    color: '#ED3591',
  },
});
