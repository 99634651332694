import { FormComponent } from '@components/form/form.component';
import { ModalComponent } from '@components/modal/modal.component';
import { NavigationProp } from '@react-navigation/native';
import { CampaignsRoutes } from '../../campaigns.routes';
import { CampaignCrmType } from '@human/crm/types/campaign.crm.type';
import { FunnelCrmRepository } from '@human/crm/repositories/funnel/funnel.repository';
import { CampaignCrmRepository } from '@human/crm/repositories/campaign/campaign.repository';
import { CrmSections } from '@human/crm/crm.sections';
import { LanguageAccessRepository } from '@human/access/repositories/language/language.repository';
import { ActivityCrmRepository } from '@human/crm/repositories/activity/activity.repository';
import { useState } from 'react';
import { ActionComponent } from '../../../../../../../../../components/action/action.component';
import { SelectChannelStep } from '@steps/channel/selectChannel.step';

export function CreateCampaignScreen(properties: { navigation: NavigationProp<CampaignsRoutes> }) {
  const [channelId, setChannelId] = useState<string>();
  const [channelName, setChannelName] = useState<string>();

  return (
    <ModalComponent icon={CrmSections.campaigns.icon} prefix="crm.campaigns.create">
      {!channelId ? (
        <SelectChannelStep
          channelId={channelId}
          onSuccess={({ channelId, channelName }) => {
            setChannelId(channelId);
            setChannelName(channelName);
          }}
        />
      ) : (
        <ActionComponent
          onPress={() => {
            setChannelId(undefined);
            setChannelName(undefined);
          }}
          prefix="crm.campaigns.create.steps.channel.form.channelId.title"
          bubble={channelName}
        />
      )}
      {channelId && (
        <FormComponent<CampaignCrmType>
          padding
          prefix="crm.campaigns.create.form"
          repository={{
            send: (data) => {
              return new CampaignCrmRepository()
                .create({
                  item: data.item,
                })
                .then(() => {
                  properties.navigation.navigate('campaignList');
                });
            },
          }}
          fields={[
            {
              type: 'image',
              name: 'image',
              description: true,
            },
            {
              type: 'text',
              name: 'name',
              description: true,
              required: true,
              validations: ['title'],
            },
            {
              type: 'text',
              name: 'facebookId',
              description: true,
              validations: ['title'],
            },
            {
              type: 'text',
              name: 'code',
              validations: ['code'],
              description: true,
              required: true,
            },
            {
              type: 'select',
              name: 'funnelId',
              description: true,
              required: true,
              repository: ({ search }) =>
                new FunnelCrmRepository().listForCombo({
                  search,
                  channelId,
                  active: true,
                }),
            },
            {
              type: 'select',
              name: 'languageId',
              description: true,
              required: true,
              pick: (properties: { id: string }) => {
                return new LanguageAccessRepository().pickForCombo({ languageId: properties.id });
              },
              repository: ({ search }) =>
                new LanguageAccessRepository()
                  .listForCombo({ search, active: true })
                  .then((languages) => languages),
            },
            {
              type: 'date',
              name: 'startDate',
              description: true,
              required: true,
              format: 'YYYY-MM-DD',
            },
            {
              type: 'select',
              name: 'firstActivityId',
              description: true,
              nullable: true,
              repository: ({ search }) =>
                new ActivityCrmRepository().listForCombo({
                  search,
                  active: true,
                }),
            },
          ]}
        />
      )}
    </ModalComponent>
  );
}
