import { AuthorizedRepository } from '@core/repository/authorized.repository.core';

export class SubordinateBoardRepository extends AuthorizedRepository {
  async countSubordinatesPerformanceByMonth(properties: {
    clinicId?: string;
    month?: number;
    year?: number;
    showInactive: boolean;
  }): Promise<
    {
      id: string;
      language_id: string;
      name: string;
      surname: string;
      lead_contacted_count: number;
      lead_informed_count: number;
      lead_appointed_count: number;
      lead_medical_appointed_count: number;
      lead_medical_evaluated_count: number;
      lead_comercial_attended_count: number;
      lead_converted_count: number;
      lead_medic_attended_count: number;
    }[]
  > {
    return this.fetch('board/subordinate/performance', {
      method: 'GET',
      params: properties,
    });
  }
}
