import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ModalComponent } from '@components/modal/modal.component';
import { FormComponent } from '@components/form/form.component';
import { CampaignCrmType } from '@human/crm/types/campaign.crm.type';
import { ContactsRoutes } from '../../contacts.routes';
import { ContactCrmRepository } from '@human/crm/repositories/contact/contact.repository';
import { useTranslation } from 'react-i18next';

export function DeleteContactScreen(properties: {
  navigation: NavigationProp<ContactsRoutes>;
  route: RouteProp<ContactsRoutes, 'contactDelete'>;
}) {
  const { t } = useTranslation();
  return (
    <ModalComponent icon="trash-alt" prefix="crm.contacts.delete">
      <FormComponent<CampaignCrmType>
        padding
        prefix="crm.contacts.delete.form"
        repository={{
          send: async () => {
            if (!confirm(t('crm.contacts.delete.form.confirm') || '')) return;
            return new ContactCrmRepository()
              .delete({
                contactId: properties.route.params.id,
              })
              .then(() => properties.navigation.goBack());
          },
        }}
        fields={[]}
      />
    </ModalComponent>
  );
}
