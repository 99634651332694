import { NavigationProp, RouteProp } from '@react-navigation/native';
import { CampaignsRoutes } from '../../../campaigns.routes';
import { ModalComponent } from '@components/modal/modal.component';
import { FormComponent } from '@components/form/form.component';
import { CampaignCrmType } from '@human/crm/types/campaign.crm.type';
import { ParticipantRepository } from '@human/crm/repositories/participant/participant.repository';

export function DeleteParticipantCampaignPart(properties: {
  navigation: NavigationProp<CampaignsRoutes>;
  route: RouteProp<CampaignsRoutes, 'deleteParticipant'>;
}) {
  return (
    <ModalComponent
      icon="trash-alt"
      level={properties.route.params.level}
      prefix="crm.campaigns.update.participants.delete"
    >
      <FormComponent<CampaignCrmType>
        padding
        prefix="crm.campaigns.update.participants.delete.form"
        repository={{
          send: () =>
            new ParticipantRepository()
              .delete({
                campaignId: properties.route.params.campaignId,
                clinicId: properties.route.params.clinicId,
              })
              .then(() => properties.navigation.goBack()),
        }}
        fields={[]}
      />
    </ModalComponent>
  );
}
