import { ResultCrmType } from '@human/crm/types/result.crm.type';
import { AuthorizedRepository } from '@core/repository/authorized.repository.core';

export class ResultCrmRepository extends AuthorizedRepository {
  async list(properties: {
    order: keyof ResultCrmType;
    direction: 'asc' | 'desc';
    page: number;
    limit: number;
    search?: string;
  }): Promise<{
    items: ResultCrmType[];
    total: number;
  }> {
    return this.fetch('result/find', {
      method: 'GET',
      params: properties,
    }).then((data) => ({
      total: data.total,
      items: data.items.map((item: any) => ({
        id: item.id,
        name: item.name,
        description: item.description,
        icon: item.icon,
        color: item.color,
        position: item.position,
        time: item.time,
        updated: new Date(item.updated),
        activityId: item.activityId,
        activityName: item.activityName,
        status: item.status,
        created: new Date(item.created),
      })),
    }));
  }

  async findByActivity(properties: {
    activityId: string;
    direction: 'asc' | 'desc';
    page: number;
    limit: number;
    search?: string;
    active?: boolean;
  }): Promise<{
    items: ResultCrmType[];
    total: number;
  }> {
    return this.fetch('result/findByActivity', {
      method: 'GET',
      params: properties,
    }).then((data) => ({
      total: data.total,
      items: data.items.map((item: any) => ({
        id: item.id,
        name: item.name,
        description: item.description,
        icon: item.icon,
        color: item.color,
        position: item.position,
        time: item.time,
        updated: new Date(item.updated),
        activityId: item.activityId,
        activityName: item.activityName,
        status: item.status,
      })),
    }));
  }

  async create(properties: { item: Partial<ResultCrmType> }): Promise<Partial<ResultCrmType>> {
    return this.fetch('result/create', {
      method: 'POST',
      body: properties.item,
    });
  }

  pick(properties: { resultId: string; activityId: string }): Promise<ResultCrmType> {
    return this.fetch('result/pick', {
      method: 'GET',
      params: properties,
    });
  }

  pickNextActivity(properties: {
    resultId: string;
  }): Promise<{ nextActivityId: string; nextActivityDelay: number }> {
    return this.fetch('result/pickNextActivity', {
      method: 'GET',
      params: properties,
    });
  }

  update(properties: { id: string; item: Partial<ResultCrmType> }) {
    return this.fetch(`result/update?id=${properties.item.id}`, {
      method: 'PUT',
      body: properties.item,
    }).then(() => true);
  }

  updateNextActivity(properties: {
    resultId: string;
    activityId: string;
    item: Partial<{ nextActivityId: string; nextActivityDelay: number }>;
  }) {
    return this.fetch(`result/updateNextActivity`, {
      method: 'PUT',
      body: {
        resultId: properties.resultId,
        activityId: properties.activityId,
        nextActivityId: properties.item.nextActivityId,
        nextActivityDelay: properties.item.nextActivityDelay,
      },
    }).then(() => true);
  }

  disable(properties: { activityId: string; resultId: string }): Promise<boolean> {
    return this.fetch('result/disable', {
      method: 'PUT',
      params: properties,
    });
  }

  enable(properties: { activityId: string; resultId: string }): Promise<boolean> {
    return this.fetch('result/enable', {
      method: 'PUT',
      params: properties,
    });
  }
}
