import { FootersStyles } from './footer.styles';
import { Image, Platform, TouchableOpacity, View } from 'react-native';
import { TextComponent } from '@components/text/text.component';
import { IconComponent } from '@components/icon/icon.component';
import { useUserState } from '@states/user/user.state';
import { AvatarComponent } from '@components/performance/components/profile/components/avatar/avatar.component';
import { SessionRepository } from '@components/performance/components/session/repository/session.repository';
import { JwtExpireUtility } from '@utils/jwt/jwtExpire.utility';
import { useDeviceHook } from '@hooks/device/device.hook';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ExpireUtility } from '@utils/date/expire/expire.utility';

/**
 * Footer Component
 * @description Componente de pie de página que indica el nombre y correo del usuario mostrado
 * en la sección inferior del componente Menú.
 * @returns {JSX.Element}
 */

export function FooterComponent() {
  const { logout, user } = useUserState();
  const { t } = useTranslation();

  const { phone } = useDeviceHook();

  const renderExpire = () => {
    const { unit, value } = ExpireUtility(JwtExpireUtility(user.accessToken));

    const auxText =
      value === -1
        ? t('common.time.timeMessage.expired')
        : t('common.time.timeMessage.expiresIn', {
            value,
            unit,
          });

    return (
      <TextComponent
        text={auxText}
        style={[FootersStyles.sessionExpiration, phone && FootersStyles.mobileSizeText]}
      />
    );
  };

  return user ? (
    <View style={[FootersStyles.footer, phone && { padding: 10, gap: 10 }]}>
      {Platform.OS === 'web' && <AvatarComponent small selfie={user.selfie} companyCode={'OVO'} />}
      <View style={phone ? FootersStyles.textPhoneContainer : FootersStyles.textContainer}>
        {user.name && user.surname && (
          <TextComponent
            lines={1}
            text={`${user.name} ${user.surname}`}
            bold
            style={[FootersStyles.profileName, phone && FootersStyles.mobileSizeText]}
          />
        )}
        {user.email && (
          <TextComponent
            lines={1}
            text={user.email}
            style={[FootersStyles.profileEmail, phone && FootersStyles.mobileSizeText]}
          />
        )}
        {user.accessToken && user.accessToken !== '' && renderExpire()}
      </View>
      <TouchableOpacity
        style={{
          marginLeft: 'auto',
        }}
        onPress={() => {
          new SessionRepository().delete({ sessionId: user.sessionId });
          logout();
        }}
      >
        <IconComponent
          name="sign-out-alt"
          iconStyle={phone && FootersStyles.mobileSizeIcon}
          containerstyle={phone && FootersStyles.mobileSizeIconContainer}
        />
      </TouchableOpacity>
    </View>
  ) : null;
}
