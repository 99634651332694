import { TabType } from '@components/tabs/tabs.types';
import { LeadsRoutes } from '../../../leads.routes';
import { NavigationProp, RouteProp } from '@react-navigation/native';
import { FormComponent } from '@components/form/form.component';
import { CoworkerCrmRepository } from '@human/crm/repositories/coworker/coworker.repository';
import { LeadCrmType } from '@human/crm/types/lead.crm.type';
import { LeadCrmRepository } from '@human/crm/repositories/lead/lead.repository';
import { UserAccessRepository } from '@human/access/repositories/user/user.repository';
import { useSuccessToast } from '@hooks/successToast/successToast.hook';
import { hasPermissionUtilty } from '@utils/permission/has.permission.utility';

export function ResponsibleUpdateLeadPart(properties: {
  navigation: NavigationProp<LeadsRoutes>;
  route: RouteProp<LeadsRoutes, 'leadUpdate'>;
}): TabType {
  const { showSuccess } = useSuccessToast();
  return {
    name: 'responsible',
    icon: 'user-ninja',
    content: (settings) => {
      return (
        <FormComponent<LeadCrmType>
          prefix="crm.leads.update.responsible.form"
          readonly={!hasPermissionUtilty({ name: 'crm.leads.editor' })}
          repository={{
            send: (data) => {
              return new LeadCrmRepository()
                .updateResponsible({
                  item: {
                    responsibleId: data.item.responsibleId,
                    clinicId: properties.route.params.clinicId,
                    leadId: properties.route.params.id,
                  },
                })
                .then(() => {
                  settings?.setIsEditing(false);
                  showSuccess();
                });
            },
            get: () => {
              return new LeadCrmRepository().pick({
                id: properties.route.params.id,
                clinicId: properties.route.params.clinicId,
              });
            },
          }}
          fields={[
            {
              type: 'select',
              name: 'responsibleId',
              required: true,
              description: true,
              pick: (props: { id: string }) => {
                return new CoworkerCrmRepository().pickForCombo({
                  clinicId: properties.route.params.clinicId,
                  coworkerId: props.id,
                });
              },
              repository: (settings) => {
                return new CoworkerCrmRepository().listForCombo({
                  clinicId: properties.route.params.clinicId,
                  search: settings.search,
                  active: true,
                });
              },
            },
          ]}
        />
      );
    },
  };
}
