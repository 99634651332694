import { TableComponent } from '@components/table/table.component';
import { LeadCrmRepository } from '@human/crm/repositories/lead/lead.repository';
import { LeadCrmType } from '@human/crm/types/lead.crm.type';
import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ContactsRoutes } from '../../../contacts.routes';
import { ScrollView } from 'react-native';

export function LeadsUpdatePart(properties: {
  navigation: NavigationProp<ContactsRoutes>;
  route: RouteProp<ContactsRoutes, 'update'>;
}) {
  return (
    <ScrollView>
      <TableComponent<LeadCrmType, []>
        prefix={'crm.leads.list'}
        suffix={'list'}
        hideNoDataImage
        repository={(settings) =>
          new LeadCrmRepository().listByContactId({
            contactId: properties.route.params.contactId,
            direction: settings.direction,
            page: settings.page,
            limit: settings.limit,
          })
        }
        columns={[
          {
            type: 'icon',
            icon: 'pencil-alt',
            onPress: (row) => {
              properties.navigation.navigate('leadUpdate', {
                id: row.id,
                contactId: row.contactId,
                clinicId: row.clinicId,
              });
            },
          },
          {
            type: 'clinic',
            name: 'clinicName',
            onRender: (row) => row.clinicName,
            color: (row) => row.clinicColor,
            width: 200,
          },
          {
            type: 'text',
            name: 'campaignName',
            width: 200,
            onRender: (row) => row.campaignName,
          },
          {
            type: 'date',
            name: 'created',
            renderDate: (row) => row.created,
          },
        ]}
      />
    </ScrollView>
  );
}
