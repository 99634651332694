// Modules
import { StyleSheet } from 'react-native';

/**
 * Covers Styles
 * @constant {StyleSheet} CoversStyles
 */
export const CoversStyles = StyleSheet.create({
  container: {
    marginBottom: 20,
  },
  content: {
    gap: 10,
    alignItems: 'center',
  },
  cover: {
    overflow: 'hidden',
    height: 350,
    width: 260,
    backgroundColor: '#000',
    borderRadius: 10,
  },
  image: {
    height: 350,
    width: 260,
    borderRadius: 10,
  },
  title: {
    color: '#fff',
    fontSize: 24,
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 10,
    zIndex: 2,
    fontWeight: '100',
  },
  subtitle: {
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 20,
    marginRight: 20,
    marginTop: 'auto',
    marginBottom: 5,
    zIndex: 2,
    borderColor: 'rgb(125, 125, 125)',
    borderWidth: 0.5,
    paddingVertical: 1.5,
    paddingHorizontal: 6,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    borderRadius: 20,
    alignSelf: 'flex-start',
    
  },
  subtitlePrefix: {
    fontWeight: '100',
    fontSize: 12,
    opacity: 0.5,
    color: '#fff',
  },
  subtitleText: {
    fontWeight: '400',
    fontSize: 12,
    color: '#ddd',
  },
  count: {
    position: 'absolute',
    top: 20,
    right: 20,
    zIndex: 2,
    flexDirection: 'row',
    backgroundColor: 'rgba(39, 48, 53, 0.5)',
    borderRadius: 4,
    borderColor: 'rgba(0, 0, 0, 0.4)',
    borderWidth: 1,
  },
  countPrefix: {
    paddingHorizontal: 8,
    paddingVertical: 4,
    fontWeight: '100',
    fontSize: 10,
    opacity: 0.8,
    color: 'rgba( 255, 255, 255, 0.85)',
    textTransform: 'uppercase',
    borderRightWidth: 1,
    borderRightColor: 'fff',
  },
  countText: {
    paddingHorizontal: 8,
    paddingVertical: 4,
    fontWeight: '400',
    fontSize: 10,
    color: '#fff',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
  },

  detailText: {
    color: '#fff',
    textTransform: 'uppercase',
    letterSpacing: 1,
    fontSize: 8,
  },

  detail: {
    borderRadius: 100,
    borderWidth: 2,
    zIndex: 2,
    marginLeft: 20,
    marginBottom: 20,
    paddingVertical: 2,
    paddingHorizontal: 8,
    alignSelf: 'flex-start',
    borderColor: 'rgb(237, 53, 145)',
    backgroundColor: 'rgba(237, 53, 145, 0.63)',
  },
});
