import Workspace from '@utils/workspace/getWorkspace.utility';
import { OvoclinicLeadsScreen } from './ovoclinicLeads/ovoclinicLeads.screen';
import { OvobankLeadsScreen } from './ovobankLeads/ovobankLeads.screen';

export default function LeadsScreenWeb() {
  return Workspace.select({
    ovoclinic: <OvoclinicLeadsScreen />,
    ovobank: <OvobankLeadsScreen />,
  });
}
