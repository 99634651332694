import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ModalComponent } from '@components/modal/modal.component';
import { FormComponent } from '@components/form/form.component';
import { ActionCrmRepository } from '@human/crm/repositories/action/action.repository';
import { ActionsRoutes } from '../../actions.routes';

export function EnableActionScreen(properties: {
  navigation: NavigationProp<ActionsRoutes>;
  route: RouteProp<ActionsRoutes, 'actionEnable'>;
}) {
  return (
    <ModalComponent icon="trash-alt" prefix="crm.actions.enable">
      <FormComponent
        prefix="crm.actions.enable.form"
        padding
        repository={{
          send: () => {
            return new ActionCrmRepository()
              .enable({
                actionId: properties.route.params.actionId,
                leadId: properties.route.params.leadId,
                clinicId: properties.route.params.clinicId,
              })
              .then(() => {
                properties.navigation.goBack();
              });
          },
        }}
        fields={[]}
      />
    </ModalComponent>
  );
}
