import { AuthorizedRepository } from '@core/repository/authorized.repository.core';
import { EntryTrackAccessType } from '@human/access/types/entryTrack.access.type';
import { contactTimeParserUtility } from '@utils/contactTime/contactTimeParser.utility';
import { filterInputParseUtility } from './utilities/filterInput/filterInputParse.utility';

export class EntryTrackAccessRepository extends AuthorizedRepository {
  async list(properties: {
    page: number;
    limit: number;
    search?: string;
    failed?: boolean;
    gravityForms?: boolean;
    facebook?: boolean;
  }): Promise<{
    items: EntryTrackAccessType[];
    total: number;
  }> {
    return this.fetch('entry-track/find', {
      params: {
        ...properties,
        ...filterInputParseUtility({
          facebook: properties.facebook ?? false,
          gravityForms: properties.gravityForms ?? false,
        }),
      },
      method: 'GET',
    }).then((data) => ({
      items: data.items.map((entryTrack: EntryTrackAccessType) => ({
        id: entryTrack.id,
        name: entryTrack.name,
        surname: entryTrack.surname,
        email: entryTrack.email,
        contactTime: contactTimeParserUtility(entryTrack.contactTime),
        phone: entryTrack.phone,
        type: entryTrack.type,
        error: entryTrack.error,
        created: new Date(entryTrack.created),
        reentried: entryTrack.reentried,
      })),
      total: data.total,
    }));
  }

  async pick(properties: { entryTrackId: string }): Promise<EntryTrackAccessType> {
    return this.fetch('entry-track/pick', {
      params: properties,
      method: 'GET',
    });
  }

  async confirmReentry(properties: { entryTrackId: string }) {
    return this.fetch('entry-track/confirm-reentry', {
      method: 'PUT',
      body: properties,
    });
  }
}
