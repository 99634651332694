import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ModalComponent } from '@components/modal/modal.component';
import { FormComponent } from '@components/form/form.component';
import { CrmSections } from '@human/crm/crm.sections';
import { TabsComponent } from '@components/tabs/tabs.component';
import { t } from 'i18next';
import { ProgramsRoutes } from '../../programs.routes';
import { ProgramCrmType } from '@human/crm/types/program.crm.type';
import { ProgramCrmRepository } from '@human/crm/repositories/program/program.repository';
import { TechniquesUpdateProgramPart } from './parts/techniques/techniques';
import { PhasesUpdateProgramPart } from './parts/program/phases.update.program.part';
import { useSuccessToast } from '@hooks/successToast/successToast.hook';
import { hasPermissionUtilty } from '@utils/permission/has.permission.utility';
import { CyclesUpdateProgramPart } from './parts/cycle/cycles.update.program.part';

export function UpdateProgramscreen(properties: {
  navigation: NavigationProp<ProgramsRoutes>;
  route: RouteProp<ProgramsRoutes, 'update'>;
}) {
  const { showSuccess } = useSuccessToast();
  return (
    <ModalComponent icon={CrmSections.programs.icon} prefix="crm.programs.update">
      <TabsComponent
        layout="horizontal"
        prefix="crm.programs.update"
        tabs={[
          {
            name: 'program',
            icon: CrmSections.programs.icon,
            content: (settings) => {
              return (
                <FormComponent<ProgramCrmType>
                  onChange={(data) => {
                    if (!data.isLocal) return;
                    settings?.setIsEditing(true);
                  }}
                  readonly={!hasPermissionUtilty({ name: 'crm.programs.editor' })}
                  prefix="crm.programs.update.form"
                  repository={{
                    send: (data) => {
                      return new ProgramCrmRepository()
                        .update({
                          item: data.item,
                        })
                        .then(() => {
                          settings?.setIsEditing(false);
                          showSuccess();
                        });
                    },
                    get: () => {
                      return new ProgramCrmRepository().pick({
                        id: properties.route.params.id,
                      });
                    },
                  }}
                  fields={[
                    {
                      type: 'text',
                      name: 'name',
                      required: true,
                      validations: ['name'],
                      description: true,
                    },
                    {
                      type: 'text',
                      name: 'description',
                      required: true,
                      validations: ['description'],
                      description: true,
                      lines: 6,
                    },
                    {
                      type: 'select',
                      name: 'complexity',
                      options: [
                        {
                          value: 'HIGH',
                          label: t('common.complexity.HIGH'),
                        },
                        {
                          value: 'LOW',
                          label: t('common.complexity.LOW'),
                        },
                      ],
                      description: true,
                    },
                    {
                      type: 'float',
                      name: 'price',
                      required: true,
                      validations: ['price'],
                      description: true,
                    },
                    {
                      type: 'text',
                      name: 'code',
                      required: true,
                      validations: ['code'],
                      description: true,
                    },
                  ]}
                />
              );
            },
          },
          {
            icon: CrmSections.techniques.icon,
            name: 'techniques',
            content: () => (
              <TechniquesUpdateProgramPart
                programId={properties.route.params.id}
                navigation={properties.navigation}
                level={properties.route.params.level}
              />
            ),
          },
          {
            icon: CrmSections.phases.icon,
            name: 'phases',
            content: () => (
              <PhasesUpdateProgramPart
                programId={properties.route.params.id}
                navigation={properties.navigation}
              />
            ),
          },
          {
            icon: 'sync-alt',
            name: 'cycles',
            content: () => (
              <CyclesUpdateProgramPart
                programId={properties.route.params.id}
                navigation={properties.navigation}
              />
            ),
          },
        ]}
      />
    </ModalComponent>
  );
}
