import { View, Text, Pressable } from 'react-native';
import React from 'react';
import { LeadsByFunnelYearCountRowStyles } from './leadsByFunnelYearCountRow.styles';
import { useDeviceHook } from '@hooks/device/device.hook';

export function LeadsByFunnelYearCountRow(properties: {
  item: {
    department_id: string;
    department_name: string;
    total_leads: number[];
  };
  last: boolean;
  year: number;
  onCellPress: (settings: { departmentId: string | undefined; month: number }) => void;
}) {
  const { phone } = useDeviceHook();

  return (
    <View
      style={[
        phone
          ? {
              flexDirection: 'row',
              borderBottomWidth: 0.5,
              borderBottomColor: '#ddd',
              backgroundColor: '#FFF',
            }
          : LeadsByFunnelYearCountRowStyles.rowContainer,
        properties.last && LeadsByFunnelYearCountRowStyles.rowContainerLast,
      ]}
    >
      <View
        style={
          phone
            ? { paddingVertical: 5 }
            : LeadsByFunnelYearCountRowStyles.subRowSpaceColumnContainer
        }
      >
        <Text
          style={[
            phone
              ? LeadsByFunnelYearCountRowStyles.phoneSubRowSpaceText
              : LeadsByFunnelYearCountRowStyles.subRowSpaceText,
            properties.last && {
              fontWeight: '500',
            },
          ]}
        >
          {properties.item.department_name}
        </Text>
      </View>

      <View
        style={{
          flex: 10,
          flexDirection: 'row',
        }}
      >
        {properties.item.total_leads.map((total, index) => (
          <Pressable
            disabled={total === 0}
            onPress={() => {
              properties.onCellPress({
                departmentId: properties.item.department_id,
                month: index + 1,
              });
            }}
            style={
              phone
                ? {
                    width: 100,
                    marginHorizontal: 10,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }
                : LeadsByFunnelYearCountRowStyles.subRowColumnContainer
            }
          >
            <Text
              style={[
                phone
                  ? LeadsByFunnelYearCountRowStyles.phoneSubRowText
                  : LeadsByFunnelYearCountRowStyles.subRowText,
                index > new Date().getMonth() &&
                  properties.year === new Date().getFullYear() &&
                  LeadsByFunnelYearCountRowStyles.subRowFutureText,
              ]}
            >
              {total}
            </Text>
          </Pressable>
        ))}
      </View>
    </View>
  );
}
