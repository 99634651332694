import { TextComponent } from '@components/text/text.component';
import { Alert, Platform, TextInput, TouchableOpacity, View } from 'react-native';
import { IconComponent } from '@components/icon/icon.component';
import { NoteStyles } from './note.styles';
import { NoteProperties } from './note.properties';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NoteConstants } from './note.constants';
import { formatTimeHhmmUtility } from '@utils/hhmm/hhmm.utility';
import { HistoricalComponent } from './historical/historical.component';

export function NoteComponent(properties: NoteProperties) {
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState(false);
  const [height, setHeight] = useState<number>();
  const [text, setText] = useState(properties.message.text);
  const [historical, setHistorical] = useState<boolean>(false);

  const submitEdit = () => {
    // If the text has not changed, we should not submit the edit
    if (!text) {
      return;
    }

    properties
      .onEdit({
        noteId: properties.message.id,
        newText: text,
      })
      .then(() => setIsEditing(false));
  };

  const handleEdit = () => {
    // If the user is already editing, we should cancel the edit
    if (isEditing) {
      setText(properties.message.text);
      setIsEditing(false);
      return;
    }
    // If the user is not editing, we should start the edit
    setIsEditing(true);
  };

  const askDelete = () => {
    if (Platform.OS === 'web') {
      confirm(t(NoteConstants.question.description) ?? NoteConstants.question.description) &&
        handleDelete();
      return;
    }

    Alert.alert(
      t(NoteConstants.question.title) ?? NoteConstants.question.title,
      t(NoteConstants.question.description) ?? NoteConstants.question.description,
      [
        {
          text: t(NoteConstants.question.cancel) ?? NoteConstants.question.cancel,
          style: 'cancel',
        },
        {
          text: t(NoteConstants.question.submit) ?? NoteConstants.question.submit,
          onPress: handleDelete,
          style: 'destructive',
        },
      ]
    );
  };

  const handleDelete = () => {
    properties.onDelete({
      noteId: properties.message.id,
    });
  };

  return (
    <View
      style={[
        NoteStyles.message,
        { zIndex: properties.index },
        properties.message.status ? null : NoteStyles.deleted,
      ]}
    >
      <View style={NoteStyles.messageHeader}>
        <View style={NoteStyles.header}>
          <TextComponent
            bold
            style={NoteStyles.author}
            text={`${properties.message.authorName} ${properties.message.authorSurname}`}
          />
          <TextComponent style={NoteStyles.position} text={properties.message.authorEmail} />
        </View>
        {properties.blockActions ? null : (
          <View style={NoteStyles.actionsContainer}>
            {properties.message.logs && properties.historical ? (
              <>
                <TouchableOpacity
                  onBlur={() => setHistorical(false)}
                  style={NoteStyles.actionIcon}
                  onPress={() => {
                    setHistorical(!historical);
                  }}
                >
                  <IconComponent name="history" iconStyle={NoteStyles.actionIconShape} />
                </TouchableOpacity>
                {historical && properties.historical ? (
                  <HistoricalComponent
                    message={properties.message}
                    logs={properties.message.logs}
                  />
                ) : null}
              </>
            ) : null}

            <TouchableOpacity style={NoteStyles.actionIcon} onPress={handleEdit}>
              <IconComponent name="pen" iconStyle={NoteStyles.actionIconShape} />
            </TouchableOpacity>
            <TouchableOpacity style={NoteStyles.actionIcon} onPress={askDelete}>
              <IconComponent name="trash-alt" iconStyle={NoteStyles.actionIconShape} />
            </TouchableOpacity>
          </View>
        )}
      </View>
      {isEditing ? (
        <View>
          <TextInput
            style={[NoteStyles.editText, { height: height }]}
            multiline
            placeholder="Escribe un nota"
            value={text}
            onChangeText={setText}
            onContentSizeChange={(event) => {
              setHeight(event.nativeEvent.contentSize.height);
            }}
          />
          <TouchableOpacity onPress={submitEdit}>
            <IconComponent name="paper-plane" iconStyle={NoteStyles.send} />
          </TouchableOpacity>
        </View>
      ) : (
        <TextComponent style={NoteStyles.text} text={text} />
      )}

      <View style={NoteStyles.when}>
        {properties.source && (
          <TextComponent
            style={NoteStyles.source}
            text={`crm.contacts.notes.source.${properties.source}`}
            translate
          />
        )}
        <TextComponent
          style={NoteStyles.date}
          text={properties.message.updated.toLocaleDateString('es-ES', {
            weekday: 'short', // Día de la semana abreviado
            year: 'numeric', // Año con 4 dígitos
            month: 'short', // Mes abreviado
            day: 'numeric', // Día del mes
          })}
        />
        <TextComponent
          style={NoteStyles.date}
          text={formatTimeHhmmUtility({ date: properties.message.updated })}
        />
      </View>
    </View>
  );
}
