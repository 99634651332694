import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  tooltipContainer: {
    position: 'absolute',
    zIndex: 1,
    backgroundColor: 'black',
    opacity: 0.8,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 10,
    paddingBottom: 10,
    borderRadius: 6,
  },
  tooltipText: {
    color: 'white',
    fontSize: 14,
  },
});
