import { NavigationProp } from '@react-navigation/native';
import { LeadsRoutes } from '../../leads.routes';
import { ModalComponent } from '@components/modal/modal.component';
import { FormComponent } from '@components/form/form.component';
import { LeadCrmRepository } from '@human/crm/repositories/lead/lead.repository';
import { ClinicAccessRepository } from '@human/access/repositories/clinic/clinic.repository';
import { ContactCrmRepository } from '@human/crm/repositories/contact/contact.repository';
import { CampaignCrmRepository } from '@human/crm/repositories/campaign/campaign.repository';

export function CreateLeadScreen(properties: { navigation: NavigationProp<LeadsRoutes> }) {
  return (
    <ModalComponent icon="user-ninja" prefix="crm.leads.create">
      <FormComponent<{
        description: string;
        clinicId: string;
        contactId: string;
        campaignId: string;
      }>
        prefix="crm.leads.create.form"
        padding
        repository={{
          send: (data) => {
            return new LeadCrmRepository()
              .create({
                item: data.item,
              })
              .then(() => {
                properties.navigation.goBack();
              });
          },
        }}
        fields={[
          {
            type: 'text',
            name: 'description',
            description: true,
            required: false,
            validations: ['description'],
            lines: 6,
          },
          {
            type: 'select',
            name: 'clinicId',
            description: true,
            required: true,
            repository: ({ search }) => {
              return new ClinicAccessRepository().listForCombo({
                search,
                active: true,
              });
            },
          },
          {
            type: 'select',
            name: 'contactId',
            description: true,
            repository: ({ search }) => {
              return new ContactCrmRepository().listForCombo({
                search,
                active: true,
              });
            },
          },
          {
            type: 'select',
            name: 'campaignId',
            description: true,
            repository: ({ search }) => {
              return new CampaignCrmRepository().listForCombo({
                search,
                active: true,
              });
            },
          },
        ]}
      />
    </ModalComponent>
  );
}
