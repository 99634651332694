import { TabType } from '@components/tabs/tabs.types';
import { NavigationProp, RouteProp } from '@react-navigation/native';
import { PatientsRoutes } from '../../../patient.routes';
import { TableComponent } from '@components/table/table.component';
import { ActionCrmRepository } from '@human/crm/repositories/action/action.repository';
import { ButtonComponent } from '@components/button/button.component';
import { hasPermissionUtilty } from '@utils/permission/has.permission.utility';
import { useTranslation } from 'react-i18next';
import { ScrollView } from 'react-native';

export function ActionsUpdatePatientPart(properties: {
  navigation: NavigationProp<PatientsRoutes>;
  route: RouteProp<PatientsRoutes, 'patientUpdate'>;
}): TabType {
  const { t } = useTranslation();

  return {
    name: 'actions',
    icon: 'tasks',
    content: () => {
      return (
        <ScrollView>
          <TableComponent<
            {
              id: string;
              activityId: string;
              activityName: string;
              activityColor: string;
              activityIcon: string;
              contactSurname: string;
              leadId: string;
              patientId: string;
              clinicId: string;
              clinicName: string;
              contactId: string;
              contactName: string;
              responsibleName: string;
              responsibleSurname: string;
              resultId: string;
              resultName: string;
              started: Date;
              finished: Date;
              created: Date;
              selfie: string;
            },
            []
          >
            completed
            hideNoDataImage
            repository={(settings) =>
              new ActionCrmRepository().findByPatient({
                completed: settings.completed,
                direction: settings.direction,
                page: settings.page,
                limit: settings.limit,
                patientId: properties.route.params.patientId,
                clinicId: properties.route.params.clinicId,
                search: settings.search,
              })
            }
            prefix={'crm.tasks.list'}
            suffix={'list'}
            columns={[
              {
                type: 'icon',
                icon: 'pencil-alt',
                onPress: (row) => {
                  properties.navigation.navigate('patientActionUpdate', {
                    patientId: properties.route.params.patientId,
                    actionId: row.id,
                    level: properties.route.params.level,
                    clinicId: properties.route.params.clinicId,
                    activityId: row.activityId,
                    contactId: row.contactId,
                  });
                },
              },
              {
                type: 'text',
                name: 'activity',
                onRender: (row) => row.activityName,
                width: 120,
              },
              {
                type: 'user',
                name: 'user',
                onRender: (row) => `${row.responsibleName} ${row.responsibleSurname}`,
                onRenderSelfie: (row) => row.selfie,
                width: 200,
              },
              {
                type: 'taskStatus',
                name: 'resultId',
                width: 150,
                background: () => 'white',
                color: (row) => (row.resultId ? 'rgba(186, 224, 144, 0.40)' : '#FCEEE2'),
                textColor: (row) => (row.resultId ? 'rgb(40, 167, 69)' : '#f0ad4e'),
                onRender: (row) =>
                  row.resultId
                    ? t('common.taskProgress.COMPLETED')
                    : t('common.taskProgress.PENDING'),
              },
              {
                type: 'text',
                name: 'resultName',
                width: 200,
                onRender: (row) => row.resultName ?? t('common.tasks.noResult'),
                color: (row) => (row.resultName ? 'black' : 'grey'),
              },
              {
                type: 'date',
                name: 'started',
                renderDate: (row) => row.started,
              },
              {
                type: 'date',
                name: 'finished',
                renderDate: (row) => row.finished,
              },
            ]}
          />
          {hasPermissionUtilty({
            name: 'crm.tasks.creator',
          }) && (
            <ButtonComponent
              icon="plus"
              prefix="crm.patients.update.tabs.actions.add"
              onPress={() => {
                properties.navigation.navigate('patientActionCreate', {
                  level: properties.route.params.level + 1,
                  patientId: properties.route.params.patientId,
                  clinicId: properties.route.params.clinicId,
                });
              }}
            />
          )}
        </ScrollView>
      );
    },
  };
}
