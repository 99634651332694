import { StyleSheet } from 'react-native';

export const TotalLeadsStateDepartmentsTableStyles = StyleSheet.create({
  phoneColumnContainer: {
    width: 75,
    alignItems: 'center',
  },
  columnContainer: {
    flex: 1,
    justifyContent: 'center',
  },
  columnText: {
    color: '#999',
    fontWeight: '300',
    fontSize: 12,
  },
  phoneColumnText: {
    color: '#999',
    fontWeight: '300',
    fontSize: 16,
  },
  container: {
    backgroundColor: 'white',
    padding: 15,
    paddingBottom: 0,
    borderRadius: 4,
  },
  wrapper: {
    flexDirection: 'column',
    backgroundColor: 'white',
    borderRadius: 4,
  },
  headerWrapper: {
    flex: 2,
  },
  phoneHeaderWrapper: {
    width: 100,
  },
});
