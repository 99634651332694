import { NavigationComponent } from '@components/navigation/navigation.component';
import { CauseRoutes } from './cause.routes';
import { ListCauseScreen } from './screens/list/list.cause.screen';
import { CreateCauseScreen } from './screens/create/create.cause.screen';
import { UpdateCauseScreen } from './screens/update/update.cause.screen';
import { DisableCauseScreen } from './screens/disable/disable.cause.screen';
import { EnableCauseScreen } from './screens/enable/enable.cause.screen';

const Navigation = NavigationComponent<CauseRoutes>();

export function CausesSection() {
  return (
    <Navigation.Navigator>
      <Navigation.Screen name="causeList" component={ListCauseScreen} />
      <Navigation.Screen name="causeUpdate" component={UpdateCauseScreen} />
      <Navigation.Screen name="causeCreate" component={CreateCauseScreen} />
      <Navigation.Screen name="causeEnable" component={EnableCauseScreen} />
      <Navigation.Screen name="causeDisable" component={DisableCauseScreen} />
    </Navigation.Navigator>
  );
}
