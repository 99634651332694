import { FormComponent } from '@components/form/form.component';
import { ModalComponent } from '@components/modal/modal.component';
import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ActivitiesRoutes } from '../../activity.routes';
import { ActivityCrmType } from '@human/crm/types/activity.crm.type';
import { ActivityCrmRepository } from '@human/crm/repositories/activity/activity.repository';
import { TabsComponent } from '@components/tabs/tabs.component';
import { CrmSections } from '@human/crm/crm.sections';
import { UpdateActivityResultsPart } from './parts/update.activity.results.part';
import { useSuccessToast } from '@hooks/successToast/successToast.hook';
import { UpdateActivityScopesPart } from './parts/update.activity.scopes.part';
import { hasPermissionUtilty } from '@utils/permission/has.permission.utility';
import { UpdateActivityCommunicationChannelPart } from './parts/update.activity.communicationChannel.part';
import { UpdateActivityIntegrationsPart } from './parts/update.activity.integrations.part';
export function UpdateActivityScreen(properties: {
  navigation: NavigationProp<ActivitiesRoutes>;

  route: RouteProp<ActivitiesRoutes, 'update'>;
}) {
  const { showSuccess } = useSuccessToast();
  return (
    <ModalComponent icon="tasks" flex={false} prefix="crm.activities.update">
      <TabsComponent
        layout="horizontal"
        prefix="crm.activities.update"
        tabs={[
          {
            name: 'activity',
            icon: CrmSections.activities.icon,
            content: (settings) => {
              return (
                <FormComponent<ActivityCrmType>
                  prefix="crm.activities.update.form"
                  onChange={(data) => {
                    if (!data.isLocal) return;
                    settings?.setIsEditing(true);
                  }}
                  readonly={!hasPermissionUtilty({ name: 'crm.activities.editor' })}
                  repository={{
                    get: () => {
                      return new ActivityCrmRepository().pick({
                        activityId: properties.route.params.activityId,
                      });
                    },
                    send: (data) => {
                      return new ActivityCrmRepository()
                        .update({
                          activityId: properties.route.params.activityId,
                          item: data.item,
                        })
                        .then(() => {
                          settings?.setIsEditing(false);
                          showSuccess();
                        });
                    },
                  }}
                  fields={[
                    {
                      name: 'name',
                      type: 'text',
                      description: true,
                      required: true,
                      validations: ['title'],
                    },
                    {
                      name: 'icon',
                      type: 'icon',
                      description: true,
                      required: true,
                    },
                    {
                      name: 'position',
                      type: 'number',
                      description: true,
                      required: true,
                      validations: ['positive'],
                    },
                    {
                      name: 'description',
                      type: 'text',
                      description: true,
                      lines: 6,
                    },
                    {
                      name: 'color',
                      type: 'color',
                      description: true,
                    },
                    {
                      name: 'time',
                      type: 'number',
                      validations: ['positive'],
                      description: true,
                      required: true,
                    },
                  ]}
                />
              );
            },
          },
          UpdateActivityResultsPart(properties),
          UpdateActivityScopesPart(properties),
          UpdateActivityCommunicationChannelPart(properties),
          UpdateActivityIntegrationsPart({
            ...properties,
            showSuccess: () => showSuccess(),
          }),
        ]}
      />
    </ModalComponent>
  );
}
