import { View } from 'react-native';
import { LeadDisabledProperties } from './lead.disabled.properties';
import { TextComponent } from '@components/text/text.component';
import { LeadDisabledStyles } from './lead.disabled.styles';
import { IconComponent } from '@components/icon/icon.component';
import { AgoComponent } from '@components/ago/ago.component';
import { useTranslation } from 'react-i18next';

export function LeadDisabledComponent(properties: LeadDisabledProperties) {
  const { t } = useTranslation();
  return (
    <View
      style={[
        LeadDisabledStyles.container,
        !properties.canceled && LeadDisabledStyles.inactiveContainer,
        properties.canceled && LeadDisabledStyles.canceledContainer,
      ]}
    >
      <View style={LeadDisabledStyles.contentContainer}>
        <IconComponent
          name="exclamation-triangle"
          containerstyle={[
            LeadDisabledStyles.iconWrapper,
            !properties.canceled && LeadDisabledStyles.inactiveIconWrapper,
            properties.canceled && LeadDisabledStyles.canceledIconWrapper,
          ]}
          iconStyle={[
            LeadDisabledStyles.icon,
            !properties.canceled && LeadDisabledStyles.inactiveIcon,
            properties.canceled && LeadDisabledStyles.canceledIcon,
          ]}
        />
        <View style={LeadDisabledStyles.textContainer}>
          <TextComponent
            text={
              properties.canceled
                ? t('crm.leads.update.status.canceled.title')
                : t('crm.leads.update.status.inactive.title')
            }
            style={LeadDisabledStyles.title}
          />
          <TextComponent
            text={
              properties.canceled
                ? t('crm.leads.update.status.canceled.description')
                : t('crm.leads.update.status.inactive.description')
            }
            style={LeadDisabledStyles.description}
          />
        </View>
        <AgoComponent
          date={properties.statusUpdated}
          style={[
            LeadDisabledStyles.dateBadge,
            !properties.canceled && LeadDisabledStyles.inactiveDateBadge,
            properties.canceled && LeadDisabledStyles.canceledDateBadge,
          ]}
        />
      </View>
    </View>
  );
}
