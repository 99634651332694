import { StyleSheet } from 'react-native';

export const NewLeadsByDepartmentYearsComparativeStyles = StyleSheet.create({
  headerContainer: { flexDirection: 'row', flex: 1, gap: 30, marginBottom: 20 },
  headerContainerMobile: { flexDirection: 'row', flex: 1, gap: 10, marginBottom: 20 },
  headerSpaceHeader: {
    flex: 1,
    gap: 40,
  },
  headerColumnContainer: {
    flex: 1,
  },
  headerText: {
    color: 'black',
    textAlign: 'center',
    fontWeight: '300',
    fontSize: 12,
  },
  phoneHeaderText: {
    color: 'black',
    textAlign: 'center',
    fontWeight: '300',
    fontSize: 16,
    width: 50,
  },
  headerSpacer: {
    height: 1,
    backgroundColor: '#ccc',
    marginVertical: 5,
    gap: 10,
  },

  noDataContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    gap: 5,
  },
});
