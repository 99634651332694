import { View } from 'react-native';
import { TextComponent } from '../../../../../text/text.component';
import { ClinicColumnImplementation } from './clinic.column.properties';
import { OrderTableType } from '../../../../table.properties';
import { ClinicColumnStyles } from './clinic.column.styles';
import { hexToRgba } from '@utils/color/hexToRgba.utility';

export function ClinicColumnComponent<ITEM, ORDER extends OrderTableType<ITEM>>(
  properties: ClinicColumnImplementation<ITEM, ORDER>
) {
  const text = properties.onRender?.(properties.item) ?? properties.name;
  const color = properties.color?.(properties.item);
  const background = properties.background?.(properties.item);

  return (
    <View
      style={[
        ClinicColumnStyles.container,
        {
          backgroundColor: background,
        },
      ]}
    >
      <View
        style={[
          ClinicColumnStyles.bubble,
          {
            backgroundColor: color ? hexToRgba(color, 0.2) : 'rgb(251, 231, 240)',
          },
        ]}
      >
        <TextComponent lines={1} bold text={`OVO`} style={[ClinicColumnStyles.icon]} />
        <TextComponent
          lines={1}
          bold
          text={`${text}`}
          style={[
            ClinicColumnStyles.text,
            {
              color: color ?? ClinicColumnStyles.text.color,
              borderColor: color ? hexToRgba(color, 0.4) : '#f7d1e3',
            },
          ]}
        />
      </View>
    </View>
  );
}
