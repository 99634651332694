import { View, Text } from 'react-native';
import { FilterProperties } from './filter.properties';
import { FilterSelectInput } from '@components/table/components/columns/components/filterSelect/filter.select.input';
import { BoardStyles } from '../../../../common/board.styles';

export function BoardFilterComponent(properties: FilterProperties) {
  return (
    <View style={{ width: properties.width }}>
      <View style={BoardStyles.filterTextContainer}>
        <Text style={BoardStyles.filterTitle}>{properties.title}</Text>
        {properties.subtitle && (
          <Text style={BoardStyles.filterSubtitle}>{properties.subtitle}</Text>
        )}
      </View>
      <View>
        <FilterSelectInput
          type="select"
          value={properties.value}
          name={properties.name}
          hideSearch={!properties.repository}
          onChange={properties.onChange}
          pick={properties.pick}
          repository={properties.repository}
          options={properties.options}
        />
      </View>
    </View>
  );
}
