import { View, Text, TouchableOpacity, ImageBackground } from 'react-native';
import { PassportProperties } from './passport.properties';
import { PassportStyles } from './passport.styles';
import { IconComponent } from '@components/icon/icon.component';
import { useTranslation } from 'react-i18next';
import { TextComponent } from '@components/text/text.component';
import { useDeviceHook } from '@hooks/device/device.hook';

/**
 * @name PassportComponnent
 * @description
 * Component to display the information from a contact.
 *
 * @param {PassportProperties} properties - The properties object containing the tabs of the component.
 *
 * @returns {JSX.Element} The rendered JSX element containing the contact information
 * *
 */

export function PassportComponent(properties: PassportProperties) {
  const { t } = useTranslation();

  const { laptop } = useDeviceHook();

  const handleNavigateCampaign = () => {
    if (properties.campaign?.id && properties.campaign?.languageId) {
      properties.navigation.navigate('campaignUpdate', {
        id: properties.campaign.id,
        languageId: properties.campaign.languageId,
      });
    }
  };

  const renderNameInitialCharacters = () => {
    if (!properties.contact) return null;
    if (!properties.contact.name || !properties.contact.surname) return null;

    return (
      <View style={PassportStyles.initialCharactersContainer}>
        <Text style={PassportStyles.initialCharactersText}>{properties.contact.name[0]}</Text>
        <Text style={PassportStyles.initialCharactersText}>
          {properties.contact.surname ? properties.contact.surname[0] : ''}
        </Text>
      </View>
    );
  };

  const renderConsentChip = (properties: { text: string }) => {
    return (
      <View style={PassportStyles.consentChipContainer}>
        <Text style={PassportStyles.contentText}>{properties.text}</Text>
      </View>
    );
  };

  return (
    <View style={PassportStyles.container}>
      {properties.contact ? (
        <>
          <View
            style={[
              PassportStyles.primaryContactDetailsContainer,
              laptop && PassportStyles.primaryContactDetailsContainerLaptop,
            ]}
          >
            <View
              style={{
                flex: 1,
                flexDirection: 'column',
                justifyContent: 'center',
                gap: 10,
              }}
            >
              <View style={PassportStyles.contactNameContainer}>
                {renderNameInitialCharacters()}
                <View style={PassportStyles.contactNameAndSurname}>
                  <TextComponent
                    style={PassportStyles.contactName}
                    text={properties.contact.name || ''}
                  />
                  <TextComponent
                    style={PassportStyles.contactName}
                    text={properties.contact.surname || ''}
                  />
                </View>
              </View>
              <View style={{ gap: 2 }}>
                <View style={PassportStyles.contactInfoRow}>
                  <IconComponent iconStyle={PassportStyles.passportIcon} name="envelope" />
                  <TextComponent
                    style={PassportStyles.contentText}
                    text={properties.contact.emails?.map((email) => email.email).join(',') || ''}
                  />
                </View>
                <View style={PassportStyles.contactInfoRow}>
                  <IconComponent iconStyle={PassportStyles.passportIcon} name="phone" />
                  <TextComponent
                    style={PassportStyles.contentText}
                    text={
                      properties.contact.phones
                        ?.map((phone) => `${phone.prefix} ${phone.phone}`)
                        .join(',') || ''
                    }
                  />
                </View>
              </View>
            </View>
            <View style={PassportStyles.campaignContainer}>
              {properties.campaign && (
                <TouchableOpacity style={PassportStyles.touchable} onPress={handleNavigateCampaign}>
                  <ImageBackground
                    source={{ uri: properties.campaign?.image }}
                    style={PassportStyles.imageBackground}
                  >
                    <View style={PassportStyles.overlay} />
                    <View style={PassportStyles.row}>
                      <TextComponent style={PassportStyles.hashText} text={'#'} />
                      <TextComponent
                        style={PassportStyles.campaignCode}
                        text={properties.campaign.code || ''}
                      />
                    </View>
                    <TextComponent
                      style={PassportStyles.campaignTitle}
                      text={`${properties.campaign.title}`}
                    />
                  </ImageBackground>
                </TouchableOpacity>
              )}
            </View>
          </View>
          <View style={PassportStyles.divider} />

          <View style={PassportStyles.detailsContainer}>
            <View style={PassportStyles.fieldContainer}>
              <TextComponent
                translate
                text={'crm.leads.update.leadPassport.contactTime'}
                style={PassportStyles.titleText}
              />
              <TextComponent
                translate
                text={`common.contactTime.${properties.contact.contactTime}`}
                style={PassportStyles.contentText}
              />
            </View>
            <View style={PassportStyles.columnDivider} />
            <View style={PassportStyles.fieldContainer}>
              <TextComponent
                translate
                style={PassportStyles.titleText}
                text={'crm.leads.update.leadPassport.created'}
              />
              <TextComponent
                style={PassportStyles.contentText}
                text={properties.contact.created?.toLocaleDateString() || ''}
              />
            </View>
            <View style={PassportStyles.columnDivider} />
            <View style={PassportStyles.fieldContainer}>
              <TextComponent
                style={PassportStyles.titleText}
                translate
                text={'crm.leads.update.leadPassport.consent.title'}
              />
              <View style={PassportStyles.consentContainer}>
                {properties.contact.legal &&
                  renderConsentChip({
                    text: t('crm.leads.update.leadPassport.consent.type.legal'),
                  })}
                {properties.contact.marketing &&
                  renderConsentChip({
                    text: t('crm.leads.update.leadPassport.consent.type.marketing'),
                  })}
              </View>
            </View>
          </View>
        </>
      ) : null}
    </View>
  );
}
