import { FormComponent } from '@components/form/form.component';
import { ModalComponent } from '@components/modal/modal.component';
import { ClinicAccessRepository } from '@human/access/repositories/clinic/clinic.repository';
import { EntryTrackAccessRepository } from '@human/access/repositories/entryTrack/entryTrack.repository';
import { EntryTracksRoutes } from '@human/access/sections/entryTracks/entryTracks.routes';
import { EntryTrackAccessType } from '@human/access/types/entryTrack.access.type';
import { CampaignCrmRepository } from '@human/crm/repositories/campaign/campaign.repository';
import { ContactCrmRepository } from '@human/crm/repositories/contact/contact.repository';
import { NavigationProp, RouteProp } from '@react-navigation/native';
import { contactTimeParserUtility } from '@utils/contactTime/contactTimeParser.utility';
import { t } from 'i18next';

export function CreateContactByEntryTrack(properties: {
  entryTrack: EntryTrackAccessType;
  navigation: NavigationProp<EntryTracksRoutes>;
  route: RouteProp<EntryTracksRoutes, 'update'>;
}) {
  return (
    <ModalComponent icon="user-ninja" prefix="access.entrytracks.update.createContact">
      <FormComponent<{
        name: string;
        surname: string;
        email: string;
        prefix: string;
        phone: string;
        zip: string;
        clinicId: string;
        legal: boolean;
        marketing: boolean;
        contactTime: 'MORNING' | 'AFTERNOON' | 'NO_PREFERENCE';
        campaignId: string;
      }>
        prefix="crm.contacts.create.form"
        padding
        repository={{
          send: async (data) => {
            await new ContactCrmRepository().createFromCampaign({
              item: data.item,
            });
            await new EntryTrackAccessRepository().confirmReentry({
              entryTrackId: properties.entryTrack.id,
            });
            properties.navigation.goBack();
          },
          get: async () => {
            return new EntryTrackAccessRepository()
              .pick({
                entryTrackId: properties.route.params.id,
              })
              .then((entryTrack) => ({
                ...entryTrack,
                marketing: true,
                legal: true,
                contactTime: contactTimeParserUtility(entryTrack.contactTime),
              }));
          },
        }}
        fields={[
          {
            type: 'pair',
            fields: [
              {
                type: 'text',
                name: 'name',
                description: true,
                required: true,
                validations: ['name'],
              },
              {
                type: 'text',
                name: 'surname',
                description: true,
                required: true,
                validations: ['surname'],
              },
            ],
          },
          {
            type: 'pair',
            fields: [
              {
                type: 'text',
                name: 'prefix',
                description: true,
                validations: ['prefix'],
                required: true,
              },
              {
                type: 'text',
                name: 'phone',
                description: true,
                validations: ['phone'],
                required: true,
              },
            ],
          },
          {
            type: 'text',
            name: 'email',
            description: true,
            validations: ['email'],
            required: true,
          },
          {
            type: 'text',
            name: 'zip',
            description: true,
            validations: ['zip'],
          },
          {
            type: 'checkbox',
            name: 'marketing',
            description: true,
          },
          {
            type: 'checkbox',
            description: true,
            name: 'legal',
          },
          {
            type: 'select',
            name: 'contactTime',
            description: true,
            options: [
              {
                value: 'MORNING',
                label: t('common.contactTime.MORNING'),
              },
              {
                value: 'AFTERNOON',
                label: t('common.contactTime.AFTERNOON'),
              },
              {
                value: 'NO_PREFERENCE',
                label: t('common.contactTime.NO_PREFERENCE'),
              },
            ],
          },
          {
            type: 'select',
            description: true,
            name: 'clinicId',
            repository: ({ search }) => {
              return new ClinicAccessRepository().listForCombo({ search, active: true });
            },
            pick: ({ id }) => new ClinicAccessRepository().pickForCombo({ id }),
          },
          {
            type: 'select',
            description: true,
            name: 'campaignId',
            repository: ({ search }) => {
              return new CampaignCrmRepository().listForCombo({
                active: true,
                search,
              });
            },
            pick: ({ id }) => new CampaignCrmRepository().pickForCombo({ campaignId: id }),
          },
        ]}
      />
    </ModalComponent>
  );
}
