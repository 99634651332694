import { NavigationProp, RouteProp, useNavigation } from '@react-navigation/native';
import { SceneComponent } from '../../../../../../../../../components/scene/scene.component';
import { TableComponent } from '../../../../../../../../../components/table/table.component';
import { AccessSections } from '../../../../access.sections';
import { RolesRoutes } from '../../roles.routes';
import { RoleAccessType } from '../../../../types/role.access.type';
import { RoleAccessRepository } from '@human/access/repositories/role/role.repository';
import { IconType } from '@components/icon/icon.types';
import { hasPermissionUtilty } from '@utils/permission/has.permission.utility';
import { ColumnProperties } from '@components/table/components/columns/column.properties';
import { AccessRoutes } from '@human/access/access.routes';

export function ListRolesScreen(properties: {
  navigation: NavigationProp<RolesRoutes>;
  route: RouteProp<RolesRoutes, 'list'>;
}) {
  const accessNavigation = useNavigation<NavigationProp<AccessRoutes>>();

  const links: {
    name: string;
    icon: IconType;
    onPress: () => void;
  }[] = [];
  if (
    hasPermissionUtilty({
      name: 'access.roles.creator',
    })
  ) {
    links.push({
      icon: 'plus',
      name: 'create',
      onPress: () =>
        properties.navigation.navigate('create', {
          groupId: properties.route.params.groupId,
        }),
    });
  }

  const columns: ColumnProperties<RoleAccessType, []>[] = [
    {
      type: 'icon',
      icon: hasPermissionUtilty({ name: 'access.roles.editor' }) ? 'pen' : 'eye',
      onPress: (row) => {
        properties.navigation.navigate('update', {
          roleId: row.id,
          groupId: properties.route.params.groupId,
        });
      },
    },
    {
      name: 'name',
      type: 'text',
      width: 200,
      onRender: (row) => `${row.name}`,
      onPress: (row) => {
        properties.navigation.navigate('update', {
          roleId: row.id,
          groupId: properties.route.params.groupId,
        });
      },
    },
    {
      type: 'number',
      name: 'licenses',
      onRender: (row) => row.licenses ?? 0,
      onPress: (row) => {
        accessNavigation.navigate('users', {
          screen: 'list',
          params: {
            roleId: row.id,
          },
        });
      },
      width: 105,
    },
    {
      type: 'bar',
      name: 'licenses',
      onMax: (row) => row.reduce((a, b) => (a.licenses > b.licenses ? a : b)).licenses,
      onTotal: (row) => row.licenses,
      width: 105,
    },
    {
      name: 'created',
      type: 'date',
      renderDate: (row) => row.created,
    },
  ];

  if (hasPermissionUtilty({ name: 'access.roles.editor' })) {
    columns.unshift({
      type: 'flag',
      status: (row) => row.status,
      onPress: (row) => {
        properties.navigation.navigate(row.status ? 'disable' : 'enable', {
          roleId: row.id,
        });
      },
    });
  }

  return (
    <SceneComponent icon={AccessSections.roles.icon} prefix={'access.roles.list'} links={links}>
      <TableComponent<RoleAccessType, []>
        prefix={'access.roles.list'}
        suffix={'list'}
        repository={(settings) =>
          new RoleAccessRepository().list({
            groupId: properties.route.params.groupId,
            search: settings.search,
            page: settings.page,
            limit: settings.limit,
          })
        }
        columns={columns}
      />
    </SceneComponent>
  );
}
