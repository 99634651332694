/**
 * Filter input parse utility
 * @description Esta funcion se utiliza en la lista de Registros de entrada de leads de ovoAccess
 * Encima de la tabla tenemos dos checks indicando si ver los registros de gravityForms o de facebook
 * Mediante esta funcion auxiliar lo que se pretende es:
 * - Si los dos checks estan activos, no se hace nada ya que el backend interpreta que hay que devolver de los dos tipos
 * - Si solo uno de los dos checks esta activo, se añade al objeto de propiedades que se envia al backend
 */
export const filterInputParseUtility = (properties: {
  gravityForms: boolean;
  facebook: boolean;
}): {
  filterInput?: string;
} => {
  if (properties.gravityForms && properties.facebook) {
    return {};
  }

  if (properties.gravityForms) {
    return {
      filterInput: 'gravityForm',
    };
  }

  if (properties.facebook) {
    return {
      filterInput: 'facebook',
    };
  }

  return {};
};
