import { IconComponent } from '@components/icon/icon.component';
import { TextComponent } from '@components/text/text.component';
import { Fragment } from 'react';
import { TouchableOpacity } from 'react-native';
import { SectionProperties } from './section.properties';
import { SectionStyles } from './section.styles';
import { useNavigation } from '@react-navigation/native';
import { useDeviceHook } from '@hooks/device/device.hook';

/**
 * Section Component
 * @description componente que agrupa los diferentes departamentos de la empresa
 * en el menú de navegación.
 *
 * @param {SectionProperties} properties
 * @returns {JSX.Element}
 */

export function SectionComponent(properties: SectionProperties) {
  const navigation = useNavigation<any>();
  const { phone } = useDeviceHook();

  const links = Object.entries(properties.value).filter(([section, value], index) => {
    const isActive = properties.path === section;
    // search in keywords partial includes

    return true;
  });

  if (links.length === 0) {
    return null;
  }
  return (
    <Fragment>
      <TextComponent
        translate
        text={`${properties.type}.menu.${properties.area}`}
        style={phone ? SectionStyles.phoneMenuTitle : SectionStyles.menuTitle}
      />
      {links.map(([section, value], index) => {
        const isActive = properties.path === section;

        return (
          <TouchableOpacity
            onPress={() => {
              navigation.navigate(section);
            }}
            key={index}
            style={[
              SectionStyles.menuItem,
              isActive ? SectionStyles.menuItemActive : SectionStyles.menuItemInactive,
            ]}
          >
            <IconComponent
              name={value.icon}
              iconStyle={[
                phone ? SectionStyles.menuItemIconMobile : SectionStyles.menuItemIcon,
                isActive ? SectionStyles.menuItemIconActive : SectionStyles.menuItemIconInactive,
              ]}
            />
            <TextComponent
              translate
              text={`${properties.type}.menu.${section}${value.multiWorkspace ? `.${process.env.EXPO_PUBLIC_WORKSPACE}` : ''}`}
              bold={isActive}
              lines={1}
              style={[
                phone ? SectionStyles.menuItemTextMobile : SectionStyles.menuItemText,
                isActive ? SectionStyles.menuItemTextActive : SectionStyles.menuItemTextInactive,
              ]}
            />
          </TouchableOpacity>
        );
      })}
    </Fragment>
  );
}
