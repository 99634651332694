import { StyleSheet } from 'react-native';

export const BoardNoDataStyles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    gap: 5,
  },
  title: {
    fontSize: 22,
  },
  phoneTitle: {
    fontSize: 18,
  },
  description: {
    textAlign: 'center',
    fontSize: 14,
  },
  phoneDescription: {
    textAlign: 'center',
    fontSize: 16,
  },
});
