import { NavigationProp, RouteProp } from '@react-navigation/native';
import { SceneComponent } from '../../../../../../../../../components/scene/scene.component';
import { TableComponent } from '../../../../../../../../../components/table/table.component';
import { ServicesRoutes } from '../../service.routes';
import { ServiceCrmType } from '@human/crm/types/service.crm.type';
import { CrmSections } from '@human/crm/crm.sections';
import { ServiceCrmRepository } from '@human/crm/repositories/service/service.repository';
import { useDeviceHook } from '@hooks/device/device.hook';
import { IconType } from '@components/icon/icon.types';
import { hasPermissionUtilty } from '@utils/permission/has.permission.utility';
import { ColumnProperties } from '@components/table/components/columns/column.properties';
import { useTranslation } from 'react-i18next';

export function ListServicesScreen(properties: {
  navigation: NavigationProp<ServicesRoutes>;
  route: RouteProp<ServicesRoutes, 'serviceList'>;
}) {
  const { desktop, laptop } = useDeviceHook();
  const { t } = useTranslation();

  const links: {
    name: string;
    icon: IconType;
    onPress: () => void;
  }[] = [];
  if (
    hasPermissionUtilty({
      name: 'crm.services.creator',
    })
  ) {
    links.push({
      icon: 'plus',
      name: 'create',
      onPress: () => properties.navigation.navigate('serviceCreate'),
    });
  }

  const columns: ColumnProperties<ServiceCrmType, []>[] = [
    {
      type: 'icon',
      icon: hasPermissionUtilty({ name: 'crm.services.editor' }) ? 'pencil-alt' : 'eye',
      onPress: (row) => {
        properties.navigation.navigate('serviceUpdate', {
          serviceId: row.id,
          nextActivityId: row.nextActivityId,
        });
      },
    },
    {
      name: 'name',
      type: 'text',
      width: 200,
      onRender: (row) => `${row.name}`,
      onPress: (row) => {
        properties.navigation.navigate('serviceUpdate', {
          serviceId: row.id,
          nextActivityId: row.nextActivityId,
        });
      },
    },
    {
      name: 'price',
      type: 'price',
      width: 140,
      onRender: (row) => {
        return row.price;
      },
      onCurrency: (row) => {
        return '€';
      },
    },
    {
      name: 'nextActivityName',
      type: 'text',
      width: 200,
      onRender: (row) => row.nextActivity.name,
    },
    {
      name: 'nextActivityDelay',
      type: 'number',
      width: 250,
      onRender: (row) => {
        return row.nextActivityDelay;
      },
      onSuffix: () => {
        return t('common.time.days');
      },
    },
  ];

  if (hasPermissionUtilty({ name: 'crm.services.editor' })) {
    columns.unshift({
      type: 'flag',
      status: (row) => row.status,
      onPress: (row) => {
        properties.navigation.navigate(row.status ? 'serviceDisable' : 'serviceEnable', {
          serviceId: row.id,
        });
      },
    });
  }

  return (
    <SceneComponent icon={CrmSections.services.icon} prefix={'crm.services.list'} links={links}>
      <TableComponent<ServiceCrmType, []>
        prefix={'crm.services.list'}
        suffix={'list'}
        search
        repository={(settings) => {
          return new ServiceCrmRepository().list({
            order: settings.order || 'name',
            direction: settings.direction,
            page: settings.page,
            limit: settings.limit,
            search: settings.search,
          });
        }}
        columns={columns}
      />
    </SceneComponent>
  );
}
