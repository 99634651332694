import { getWorkspaceUtility } from '@utils/workspace/getWorkspace.utility';

export function getLogoUtility() {
  if (getWorkspaceUtility()?.toUpperCase() === 'OVOCLINIC') {
    return require('../../../assets/images/logo.png');
  }

  if (getWorkspaceUtility()?.toUpperCase() === 'OVOBANK') {
    return require('../../../assets/images/logo_ovobank.png');
  }

  return require('../../../assets/images/logo.png');
}
