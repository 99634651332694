import { IconComponent } from '@components/icon/icon.component';
import { TextComponent } from '@components/text/text.component';
import { useEffect, useState } from 'react';
import { ImageBackground, TouchableOpacity, View } from 'react-native';
import { NewsStyles } from './news.styles';
import { BulletinAccesRepository } from '../../../../screens/authorized/human/spaces/access/repositories/bulletin/bulletin.repository';
import { BulletinAccessType } from '@human/access/types/bulletin.access.type';
import { useBulletinState } from '@states/bulletin/bulletin.state';
import { useDeviceHook } from '@hooks/device/device.hook';
import { LARGE_NEW_CHAR_COUNT } from './news.constants';

export function NewsComponent() {
  const { phone } = useDeviceHook();

  const [news, setNews] = useState<Partial<BulletinAccessType>[]>([
    {
      description:
        'Laura lo ha conseguido, una futura mujer podrá realizar su sueño de ser madre 👏',
      actorName: 'Laura',
      actorSurname: 'Montes',
      clinicName: 'FIV Marbella',
      image:
        'https://www.fivmarbella.com/wp-content/uploads/2021/06/WhatsApp-Image-2021-06-09-at-11.11.28-1.jpeg',
    },
  ]);
  const { bulletin, disableBulletin, enableBulletin } = useBulletinState();

  useEffect(() => {
    if (!bulletin.enabled) return;

    new BulletinAccesRepository().randomPick().then((data) => {
      if (!data.id) return;

      setNews([
        {
          description: data.description,
          actorName: data.actorName,
          actorSurname: data.actorSurname,
          image: data.image,
          clinicName: data.clinicName,
        },
      ]);
    });
  }, []);

  if (!news.length) return null;

  if (!bulletin.enabled)
    return (
      <View style={NewsStyles.bulletinDisabledContainer}>
        <View style={NewsStyles.bulletinNewsDisabled}>
          <ImageBackground source={{ uri: '' }} style={NewsStyles.imageBackground} />
          <View style={NewsStyles.overlay} />
          <View style={{ flex: 1 }}>
            <View style={NewsStyles.headerContent}>
              <TextComponent text="OVO" light style={NewsStyles.headerTextLight} />
              <TextComponent text="NEWS" bold style={NewsStyles.headerTextNews} />
            </View>
            <TextComponent text="Tenemos buenas noticias" light style={NewsStyles.headerSubtitle} />
          </View>

          <TouchableOpacity style={NewsStyles.disabledOpenButton} onPress={enableBulletin}>
            <IconComponent name={'plus'} iconStyle={NewsStyles.disabledOpenIcon} />
          </TouchableOpacity>
        </View>
      </View>
    );

  return (
    <View style={[NewsStyles.container, phone && NewsStyles.mobileContainer]}>
      <TouchableOpacity style={NewsStyles.iconTimesContainer} onPress={disableBulletin}>
        <IconComponent name="times" iconStyle={NewsStyles.iconTimes} />
      </TouchableOpacity>

      <View style={NewsStyles.headerContainer}>
        <View style={NewsStyles.headerContent}>
          <TextComponent text="OVO" light style={NewsStyles.headerTextLight} />
          <TextComponent text="NEWS" bold style={NewsStyles.headerTextNews} />
        </View>
        <TextComponent
          translate
          text={'access.bulletins.bulletin.title'}
          light
          style={NewsStyles.headerSubtitle}
        />
      </View>
      {news.map((news, index) => (
        <View key={index} style={NewsStyles.newsItemContainer}>
          <ImageBackground
            source={{ uri: news.image }}
            style={NewsStyles.imageBackground}
          ></ImageBackground>
          <View style={NewsStyles.overlay} />
          <View>
            <IconComponent
              name="quote-left"
              containerstyle={NewsStyles.iconQuoteContainer}
              iconStyle={NewsStyles.iconQuote}
            />

            <TextComponent
              bold
              text={news.description ?? ''}
              style={[
                NewsStyles.newsMessage,
                (news.description?.length ?? 0) > LARGE_NEW_CHAR_COUNT &&
                  NewsStyles.newsMessageLargeText,
                (news.description?.length ?? 0) < LARGE_NEW_CHAR_COUNT &&
                  NewsStyles.newsMessageSmallText,
              ]}
              lines={6}
            />
            <View style={NewsStyles.userContainer}>
              <View style={NewsStyles.userLine} />
              <TextComponent
                text={`${news.actorName} ${news.actorSurname}`}
                style={NewsStyles.userName}
              />
            </View>
            <TextComponent text={news.clinicName ?? ''} light style={NewsStyles.userLocation} />
          </View>
        </View>
      ))}
    </View>
  );
}
