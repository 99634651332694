import { useEffect, useRef, useState } from 'react';
import { TaggedNotesProperties } from './tagged.notes.properties';
import { PatientNoteCrmType } from '@human/crm/types/note.crm.type';
import { ScrollView, TouchableOpacity, View, TextInput } from 'react-native';
import { TaggedNotesStyles } from './tagged.notes.styles';
import { TaggedNoteComponent } from './components/tagged.note.component';
import { IconComponent } from '@components/icon/icon.component';
import { TagComponent } from './components/tag/tag.component';
import { tags } from './tagged.notes.constants';

export function TaggedNotesComponent(properties: TaggedNotesProperties) {
  const [messages, setMessages] = useState<PatientNoteCrmType[]>();
  const [text, setText] = useState('');
  const [selectedTag, setSelectedTag] = useState<string>(tags[0].name);
  const scrollViewRef = useRef<ScrollView>(null);

  useEffect(() => {
    properties.repository.get().then((messages) => {
      setMessages(messages);
    });
  }, [properties.repository]);

  useEffect(() => {
    scrollViewRef.current?.scrollToEnd({ animated: false });
  }, [messages]);

  const handleSubmit = () => {
    if (text && selectedTag) {
      properties.repository.send({ text, tag: selectedTag }).then(() => {
        properties.repository.get().then((messages) => {
          setMessages(messages);
          setText('');
        });
      });
    }
  };

  return (
    <View style={TaggedNotesStyles.container}>
      <ScrollView
        ref={scrollViewRef}
        style={{ height: '100%' }}
      >
        {messages?.map((message, index) => (
          <TaggedNoteComponent
            historical={true}
            index={messages.length - index}
            key={message.id}
            message={message}
            onEdit={(settings) =>
              properties.repository
                .update({
                  text: settings.newText,
                  noteId: settings.noteId,
                  tag: settings.newTag,
                })
                .then(() =>
                  properties.repository.get().then((messages) => {
                    setMessages(messages);
                  })
                )
            }
            onDelete={(settings) =>
              properties.repository.delete(settings.noteId).then(() =>
                properties.repository.get().then((messages) => {
                  setMessages(messages);
                })
              )
            }
          />
        ))}
      </ScrollView>
      <View style={TaggedNotesStyles.input}>
        <View style={TaggedNotesStyles.tagsContainer}>
          <View style={TaggedNotesStyles.tagsContent}>
            {tags.map((tag) => (
              <TagComponent
                key={tag.name}
                tag={tag.name}
                selected={tag.name === selectedTag}
                onPress={setSelectedTag}
              />
            ))}
          </View>
          <TextInput
            style={[
              TaggedNotesStyles.inputText,
              properties.darkMode ? TaggedNotesStyles.lightInput : TaggedNotesStyles.darkInput,
            ]}
            multiline
            value={text}
            onChangeText={setText}
          />
          <TouchableOpacity onPress={handleSubmit} style={TaggedNotesStyles.sendContainer}>
            <IconComponent name="paper-plane" iconStyle={TaggedNotesStyles.send} />
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
}
