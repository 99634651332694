// Modules
import { StyleSheet } from 'react-native';
import { ActionSelectableStyles } from './components/actionSelectable/actionSelectable.styles';
import { ActionComponent } from '@components/action/action.component';

/**
 * PatientProfile Styles
 * @constant {StyleSheet} PatientProfileStyles
 */
export const PatientProfileStyles = StyleSheet.create({
  header: {
    flexDirection: 'row',
    padding: 20,
    gap: 15,
    alignItems: 'center',
    borderWidth: 1,
    borderColor: 'red',
  },
  close: {
    position: 'absolute',
    top: 4,
    right: 0,
    zIndex: 2,
    height: 40,
    width: 40,
    borderRadius: 20,
    justifyContent: 'center',
  },
  closeShape: {
    borderRadius: 20,
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 'auto',
    fontSize: 25,
    color: 'rgba(255, 255, 255, 0.2)',
  },
  closeIcon: {
    flexGrow: 1,
    height: 60,
  },
  collapse: {
    bottom: 0,
    position: 'absolute',
  },
  container: {
    flex: 1,
    backgroundColor: '#27343d',
    width: 500,
    maxWidth: 400,
    zIndex: 10000000,
  },

  headerContainer: {
    marginBottom: 10,
  },

  headerContent: {
    flexDirection: 'row',
    gap: 30,
    borderWidth: 1,
    borderColor: 'green',
  },
  profilePicture: {
    width: 70,
    height: 70,
    borderRadius: 100,
    backgroundColor: '#66666666',
    alignItems: 'center',
    justifyContent: 'center',
  },
  profilePictureText: {
    color: 'rgba(255, 255, 255, 0.6)',
    fontSize: 30,
  },
  headerText: {
    gap: 10,
    margin: 5,
  },
  headerNameContainer: {
    maxWidth: 200,
  },
  headerName: {
    color: '#eee',
    fontSize: 20,
  },
  status: {
    backgroundColor: '#66666666',
    borderRadius: 15,
    paddingHorizontal: 15,
    paddingVertical: 5,
    alignItems: 'center',
    flexDirection: 'row',
    gap: 10,
  },
  statusIndicator: {
    width: 10,
    height: 10,
    borderRadius: 10,
    borderWidth: 2,
  },
  enableStatusIndicator: {
    backgroundColor: 'rgba(0, 255, 0, 0.1)  ',
    borderColor: '#00FF00',
  },
  disableStatusIndicator: {
    borderColor: '#FF0000',
    backgroundColor: 'rgba(255, 165, 0, 0.1)',
  },
  statusText: {
    color: 'rgba(255, 255, 255, 0.6)',
    fontSize: 13,
  },
  iconRow: {
    flexDirection: 'row',
    gap: 10,
    alignItems: 'center',
    paddingLeft: 20,
  },
  iconContainer: {
    width: 30,
    height: 30,
    borderRadius: 30,
    backgroundColor: '#66666666',
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconStyle: {
    color: 'rgba(255, 255, 255, 0.6)',
    fontSize: 18,
  },
  taggedSection: {
    flex: 1,
  }
});
