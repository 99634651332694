import { useEffect, useRef, useState } from 'react';
import { ScrollView, TextInput, TouchableOpacity, View } from 'react-native';
import { IconComponent } from '@components/icon/icon.component';
import { NotesProperties } from './notes.properties';
import { NotesStyles } from './notes.styles';
import { NoteCrmType } from '@human/crm/types/note.crm.type';
import { NoteComponent } from './components/note.component';

export function NotesComponent(properties: NotesProperties) {
  const [messages, setMessages] = useState<NoteCrmType[]>([]);
  const [text, setText] = useState('');
  const scrollViewRef = useRef<ScrollView>(null);

  useEffect(() => {
    properties.repository.get().then((messages) => {
      setMessages(messages);
    });
  }, []);

  useEffect(() => {
    scrollViewRef.current?.scrollToEnd({ animated: false });
  }, [messages]);

  const handleSubmit = () => {
    if (text) {
      properties.repository.send(text).then(() => {
        properties.repository.get().then((messages) => {
          setMessages(messages);
          setText('');
        });
      });
    }
  };

  return (
    <View style={NotesStyles.container}>
      <ScrollView ref={scrollViewRef} style={{ height: '100%' }}>
        {messages.map((message, index) => (
          <NoteComponent
            historical={true}
            source={properties.source}
            index={messages.length - index}
            key={message.id}
            message={message}
            onEdit={(settings) =>
              properties.repository
                .update({
                  text: settings.newText,
                  noteId: settings.noteId,
                })
                .then(() =>
                  properties.repository.get().then((messages) => {
                    setMessages(messages);
                  })
                )
            }
            onDelete={(settings) =>
              properties.repository.delete(settings.noteId).then(() =>
                properties.repository.get().then((messages) => {
                  setMessages(messages);
                })
              )
            }
          />
        ))}
      </ScrollView>
      <TextInput
        style={[
          NotesStyles.input,
          properties.darkMode ? NotesStyles.darkInput : NotesStyles.lightInput,
        ]}
        multiline
        placeholder="Escribe un nota"
        value={text}
        onChangeText={setText}
      />
      <TouchableOpacity onPress={handleSubmit} style={NotesStyles.sendContainer}>
        <IconComponent name="paper-plane" iconStyle={NotesStyles.send} />
      </TouchableOpacity>
    </View>
  );
}
