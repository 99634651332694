import { FormComponent } from '@components/form/form.component';
import { ModalComponent } from '@components/modal/modal.component';
import { ScreenComponent } from '@components/screen/screen.component';
import { SplashComponent } from '../../../components/splash/splash.component';
import { NavigationProp } from '@react-navigation/native';
import { useUserState } from '../../../states/user/user.state';
import { SessionRepository } from '../repositories/session/session.repository';
import { UnauthorizedRoutes } from '../unauthorized.routes';
import { LogotypeComponent } from '@components/logotype/logotype.component';
import { UserRepository } from '@human/access/repositories/session/user.repository';
import { SafeAreaView } from 'react-native';
import { useState } from 'react';
import { PostSplashComponent } from '@components/postSplash/postSplash.component';

export function LoginScreen(properties: { navigation: NavigationProp<UnauthorizedRoutes> }) {
  const { login, update } = useUserState();
  const [successLogin, setSuccessLogin] = useState(false);

  if (successLogin) {
    return <PostSplashComponent />;
  }

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <ScreenComponent>
        <LogotypeComponent type="human" />
        <SplashComponent type="human" />
        <ModalComponent icon="user" transparent prefix="login" width={500}>
          <FormComponent<{ email: string; signature: string; accessToken: string }>
            prefix="login.form"
            padding
            repository={{
              get: async () => {
                return {
                  email: __DEV__ ? process.env.EXPO_PUBLIC_DEV_EMAIL : '',
                  signature: __DEV__ ? process.env.EXPO_PUBLIC_DEV_PASSWORD : '',
                };
              },
              send: (data) => {
                return new SessionRepository()
                  .login({
                    item: {
                      email: data.item.email,
                      signature: data.item.signature,
                    },
                  })
                  .then((response) => {
                    setSuccessLogin(true);
                    login({
                      user: {
                        id: response.id,
                        accessToken: response.accessToken,
                        expires: response.expires,
                        sessionId: response.sessionId,
                        idioms: response.idioms,
                        selectedIdiom: response.idioms[0],
                      },
                      permissions: response.permissions,
                    });
                    new UserRepository().retrieve().then((user) => {
                      update({
                        user: {
                          ...user,
                          selectedIdiom: user.idioms[0],
                        },
                        permissions: user.permissions,
                      });
                    });
                    properties.navigation.navigate('authorized');
                  });
              },
            }}
            fields={[
              {
                name: 'email',
                icon: 'envelope',
                type: 'text',
                capitalize: 'none',
                validations: ['email'],
              },
              {
                name: 'signature',
                icon: 'key',
                type: 'text',
                secure: true,
                validations: ['password'],
              },
            ]}
            submitableByEnter
          />
        </ModalComponent>
      </ScreenComponent>
    </SafeAreaView>
  );
}
