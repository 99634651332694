import { NavigationProp, RouteProp } from '@react-navigation/native';
import { SpecialistsRoutes } from '../../specialists.routes';
import { ModalComponent } from '@components/modal/modal.component';
import { SpecialistAccessRepository } from '@human/access/repositories/specialist/specialist.repository';
import { FormComponent } from '@components/form/form.component';
import { ClinicAccessRepository } from '@human/access/repositories/clinic/clinic.repository';

export function SpecialistCreateContractScreen(properties: {
  navigation: NavigationProp<SpecialistsRoutes>;
  route: RouteProp<SpecialistsRoutes, 'contractCreate'>;
}) {
  return (
    <ModalComponent
      icon="plus"
      prefix="access.specialists.update.contracts.create"
      level={properties.route.params.level}
    >
      <FormComponent<{ clinicId: string }>
        prefix="access.specialists.update.contracts.create.form"
        padding
        repository={{
          send: (data) => {
            return new SpecialistAccessRepository()
              .createContract({
                specialistId: properties.route.params.specialistId,
                //TODO: No forzar
                clinicId: data.item.clinicId!,
              })
              .then(() => {
                properties.navigation.goBack();
              });
          },
        }}
        fields={[
          {
            type: 'select',
            name: 'clinicId',
            description: true,
            repository: ({ search }) => {
              return new ClinicAccessRepository().listForCombo({
                search,
                active: true,
              });
            },
          },
        ]}
      />
    </ModalComponent>
  );
}
