import { NavigationProp } from '@react-navigation/native';
import { FormComponent } from '@components/form/form.component';
import { ModalComponent } from '@components/modal/modal.component';
import { ClinicsRoutes } from '../../clinics.routes';
import { ClinicAccessType } from '@human/access/types/clinic.access.type';
import { ClinicAccessRepository } from '@human/access/repositories/clinic/clinic.repository';
import { CountryAccessRepository } from '@human/access/repositories/country/country.repository';
import { timeZonesConstant } from '../../../../../../../../../constants/timezones.constants';

export function CreateClinicScreen(properties: { navigation: NavigationProp<ClinicsRoutes> }) {
  return (
    <ModalComponent icon="clinic-medical" prefix="access.clinics.create">
      <FormComponent<ClinicAccessType>
        padding
        prefix="access.clinics.create.form"
        repository={{
          send: (props: { item: Partial<ClinicAccessType> }) => {
            return new ClinicAccessRepository()
              .create({
                item: props.item,
              })
              .then(() => {
                properties.navigation.goBack();
              });
          },
        }}
        fields={[
          {
            type: 'text',
            name: 'name',
            description: true,
            required: true,
            validations: ['name'],
          },
          {
            name: 'country',
            type: 'select',
            description: true,
            required: true,
            repository: ({ search }) => {
              return new CountryAccessRepository().listForCombo({
                search,
                active: true,
              });
            },
          },
          {
            type: 'text',
            name: 'state',
            description: true,
            required: true,
            validations: ['state'],
          },
          {
            type: 'text',
            name: 'city',
            description: true,
            required: true,
            validations: ['city'],
          },
          {
            type: 'text',
            name: 'street',
            description: true,
            required: true,
            validations: ['street'],
          },
          {
            type: 'text',
            name: 'zip',
            description: true,
            required: true,
            validations: ['zip'],
          },
          {
            type: 'map',
            name: 'center',
            description: true,
            marker: {
              icon: 'clinic-medical',
              color: 'red',
            },
          },
          {
            type: 'select',
            name: 'timezone',
            description: true,
            required: true,
            options: timeZonesConstant,
          },
          {
            type: 'text',
            name: 'avatar',
            description: true,
          },
          {
            type: 'text',
            name: 'document',
            required: true,
            description: true,
            validations: ['document'],
          },
          {
            type: 'text',
            name: 'title',
            required: true,
            description: true,
            validations: ['title'],
          },
          {
            type: 'text',
            name: 'description',
            required: true,
            lines: 6,
            validations: ['description'],
            description: true,
          },
          {
            type: 'color',
            name: 'color',
            description: true,
          },
        ]}
      />
    </ModalComponent>
  );
}
