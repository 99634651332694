import { ActionCrmType } from '@human/crm/types/action.crm.type';
import { AuthorizedRepository } from '@core/repository/authorized.repository.core';
import { dayjs } from '@utils/dayjs/dayjs.config';
import { View } from 'react-big-calendar';
export class ActionCrmRepository extends AuthorizedRepository {
  async list(properties: {
    page: number;
    limit: number;
    search?: string;
    completed?: boolean;
    dates?: {
      start: Date;
      end: Date;
    };
    seeDisabled?: boolean;
    calendarMode?: View;
    calendarDate?: string;
    avoidCount?: boolean;
  }): Promise<{
    items: {
      id: string;
      status: boolean;
      contactTime: 'MORNING' | 'AFTERNOON' | 'NO_PREFERENCE';
      subject: string;
      activityId: string;
      activityName: string;
      contactSurname: string;
      activityColor: string;
      activityIcon: string;
      leadId: string;
      patientId?: string;
      clinicId: string;
      clinicName: string;
      clinicColor?: string;
      contactId: string;
      contactName: string;
      phone: string;
      resultId: string;
      resultName: string;
      started: Date;
      finished: Date;
      created: Date;
      selfie: string;
      responsibleSurname: string;
      responsibleName: string;
      leadStatus: boolean;
    }[];
    total: number;
  }> {
    return this.fetch('action/find', {
      method: 'GET',
      params: properties,
    }).then((data) => ({
      items: data.items.map((action: any) => ({
        id: action.id,
        status: action.status,
        activityId: action.activityId,
        subject: action.subject,
        activityName: action.activityName,
        activityColor: action.activityColor,
        activityIcon: action.activityIcon,
        leadId: action.leadId,
        patientId: action.patientId,
        clinicId: action.clinicId,
        clinicName: action.clinicName,
        clinicColor: action.clinicColor,
        contactId: action.contactId,
        contactName: action.contactName,
        contactSurname: action.contactSurname,
        phone: action.phone,
        contactTime: action.contactTime,
        resultId: action.resultId,
        resultName: action.resultName,
        started: new Date(action.started),
        finished: new Date(action.finished),
        created: new Date(action.created),
        selfie: action.selfie,
        responsibleSurname: action.responsibleSurname,
        responsibleName: action.responsibleName,
        leadStatus: action.leadStatus,
      })),
      total: data.total,
    }));
  }

  async findByLead(properties: {
    leadId: string;
    clinicId: string;
    direction: 'asc' | 'desc';
    page: number;
    limit: number;
    search?: string;
    completed?: boolean;
  }): Promise<{
    items: ActionCrmType[];
    total: number;
  }> {
    return this.fetch('action/find/lead', {
      method: 'GET',
      params: properties,
    }).then((data) => ({
      items: data.items.map((action: any) => ({
        id: action.id,
        status: action.status,
        created: new Date(action.created),
        started: new Date(action.started),
        activityId: action.activityId,
        activityName: action.activityName,
        responsibleName: action.responsibleName,
        responsibleSurname: action.responsibleSurname,
        resultId: action.resultId,
        resultName: action.resultName,
      })),
      total: data.total,
    }));
  }

  async findByContact(properties: {
    contactId: string;
    direction: 'asc' | 'desc';
    page: number;
    limit: number;
  }): Promise<{
    items: {
      id: string;
      activityId: string;
      activityName: string;
      contactSurname: string;
      activityColor: string;
      activityIcon: string;
      leadId: string;
      patientId?: string;
      clinicId: string;
      clinicName: string;
      clinicColor: string;
      contactId: string;
      contactName: string;
      resultId: string;
      started: Date;
      finished: Date;
      created: Date;
      selfie: string;
      responsibleSurname: string;
      responsibleName: string;
      campaignName: string;
    }[];
    total: number;
  }> {
    return this.fetch('action/find/contact', {
      method: 'GET',
      params: properties,
    }).then((data) => ({
      items: data.items.map((action: any) => ({
        id: action.id,
        activityId: action.activityId,
        activityName: action.activityName,
        contactId: action.contactId,
        contactName: action.contactName,
        contactSurname: action.contactSurname,
        activityColor: action.activityColor,
        activityIcon: action.activityIcon,
        leadId: action.leadId,
        patientId: action.patientId,
        clinicId: action.clinicId,
        clinicName: action.clinicName,
        clinicColor: action.clinicColor,
        resultId: action.resultId,
        started: new Date(action.started),
        finished: new Date(action.finished),
        created: new Date(action.created),
        selfie: action.selfie,
        responsibleSurname: action.responsibleSurname,
        responsibleName: action.responsibleName,
        campaignName: action.campaignName,
      })),
      total: data.total,
    }));
  }

  async findByPatient(properties: {
    completed?: boolean;
    patientId: string;
    direction: 'asc' | 'desc';
    page: number;
    limit: number;
    clinicId: string;
    search?: string;
  }): Promise<{
    items: {
      id: string;
      activityId: string;
      activityName: string;
      contactSurname: string;
      activityColor: string;
      activityIcon: string;
      leadId: string;
      patientId: string;
      clinicId: string;
      clinicName: string;
      contactId: string;
      contactName: string;
      resultId: string;
      resultName: string;
      started: Date;
      finished: Date;
      created: Date;
      selfie: string;
      responsibleSurname: string;
      responsibleName: string;
    }[];
    total: number;
  }> {
    const params = {
      patientId: properties.patientId,
      clinicId: properties.clinicId,
      direction: properties.direction,
      page: properties.page,
      limit: properties.limit,
      search: properties.search || '',
    };
    return this.fetch('action/find/patient', {
      method: 'GET',
      params:
        properties.completed !== undefined
          ? { ...params, completed: properties.completed }
          : params,
    }).then((data) => ({
      items: data.items.map((action: any) => ({
        id: action.id,
        activityId: action.activityId,
        activityName: action.activityName,
        contactId: action.contactId,
        contactName: action.contactName,
        contactSurname: action.contactSurname,
        activityColor: action.activityColor,
        activityIcon: action.activityIcon,
        leadId: action.leadId,
        patientId: action.patientId,
        clinicId: action.clinicId,
        clinicName: action.clinicName,
        resultId: action.resultId,
        resultName: action.resultName,
        started: new Date(action.started),
        finished: new Date(action.finished),
        created: new Date(action.created),
        selfie: action.selfie,
        responsibleSurname: action.responsibleSurname,
        responsibleName: action.responsibleName,
      })),
      total: data.total,
    }));
  }

  async pickByPatient(properties: {
    actionId: string;
    patientId: string;
    clinicId: string;
  }): Promise<Partial<ActionCrmType>> {
    return this.fetch('action/pick/patient', {
      method: 'GET',
      params: {
        id: properties.actionId,
        patientId: properties.patientId,
        clinicId: properties.clinicId,
      },
    }).then((action) => ({
      id: action.id,
      patientId: action.patientId,
      activityId: action.activityId,
      responsibleId: action.responsibleId,
      observation: action.observation,
      subject: action.subject,
      started: action.started,
      resultId: action.resultId,
      leadId: action.leadId,
    }));
  }

  async pick(properties: {
    actionId: string;
    leadId: string;
    clinicId: string;
  }): Promise<Partial<ActionCrmType>> {
    return this.fetch(`action/pick`, {
      method: 'GET',
      params: {
        id: properties.actionId,
        leadId: properties.leadId,
        clinicId: properties.clinicId,
      },
    }).then((action) => ({
      id: action.id,
      activityId: action.activityId,
      resultId: action.resultId,
      responsibleId: action.responsibleId,
      patientId: action.patientId,
      started: action.started,
      observation: action.observation,
      subject: action.subject,
      leadId: action.leadId,
      communicationChannelId: action.communicationChannelId,
    }));
  }

  async create({
    item,
  }: {
    item: Partial<ActionCrmType & { clinicId: string; leadId: string }>;
  }): Promise<void> {
    return this.fetch('action/create', {
      method: 'POST',
      body: {
        ...item,
        started: item.started ? dayjs(item.started).tz('Europe/Madrid').toISOString() : undefined,
      },
    });
  }

  async createPatientAction({
    item,
  }: {
    item: Partial<ActionCrmType> & { clinicId: string; patientId: string };
  }): Promise<void> {
    return this.fetch('action/create/patient', {
      method: 'POST',
      body: {
        ...item,
        started: item.started ? dayjs(item.started).tz('Europe/Madrid').toISOString() : undefined,
      },
    });
  }

  async updatePatientAction({
    item,
  }: {
    item: Partial<ActionCrmType> & {
      clinicId: string;
      patientId: string;
    };
  }): Promise<void> {
    return this.fetch('action/update/patient', {
      method: 'PUT',
      body: {
        ...item,
        started: item.started ? dayjs(item.started).tz('Europe/Madrid').toISOString() : undefined,
      },
    });
  }

  async update({
    item,
  }: {
    item: Partial<ActionCrmType & { clinicId: string; leadId: string }>;
  }): Promise<void> {
    return this.fetch('action/update', {
      method: 'PUT',
      body: {
        ...item,
        started: item.started ? dayjs(item.started).tz('Europe/Madrid').toISOString() : undefined,
      },
    });
  }

  async addResult(properties: {
    leadId: string;
    clinicId: string;
    resultId: string;
    communicationChannelId?: string;
    actionId: string;
  }) {
    return this.fetch('action/addResult', {
      method: 'PUT',
      body: properties,
    }).then((action) => ({
      id: action.id,
      clinicId: action.clinicId,
      patientId: action.patientId,
      leadId: action.leadId,
      nextActivityId: action.nextActivityId,
      nextActivityDelay: action.nextActivityDelay,
      disableLead: action.disableLead,
      converted: action.converted,
      appointed: action.appointed,
      templateId: action.templateId,
      landbotId: action.landbotId,
      contactId: action.contactId,
      responsibleId: action.responsibleId,
    }));
  }

  async addPatientResult(properties: {
    patientId: string;
    clinicId: string;
    resultId: string;
    actionId: string;
    communicationChannelId?: string;
  }) {
    return this.fetch('action/addResult/patient', {
      method: 'PUT',
      body: properties,
    }).then((action) => ({
      id: action.id,
      clinicId: action.clinicId,
      patientId: action.patientId,
      nextActivityId: action.nextActivityId,
      nextActivityDelay: action.nextActivityDelay,
    }));
  }

  async delete(properties: { leadId: string; clinicId: string; actionId: string }) {
    return this.fetch('action/delete', {
      method: 'DELETE',
      body: {
        leadId: properties.leadId,
        clinicId: properties.clinicId,
        actionId: properties.actionId,
      },
    });
  }

  async disable(properties: { actionId: string; leadId: string; clinicId: string }) {
    return this.fetch('action/disable', {
      method: 'PUT',
      body: properties,
    });
  }

  async enable(properties: { actionId: string; leadId: string; clinicId: string }) {
    return this.fetch('action/enable', {
      method: 'PUT',
      body: properties,
    });
  }

  async transferActions(properties: {
    clinicId: string;
    responsibleId: string;
    actionIds: string[];
  }) {
    return this.fetch('action/transfer', {
      method: 'PUT',
      body: properties,
    });
  }

  async createMassively(properties: {
    activityId: string;
    subject: string;
    leadsData: Array<{ leadId: string; clinicId: string }>;
  }) {
    return this.fetch('action/createMassively', {
      method: 'POST',
      body: properties,
    });
  }
}
