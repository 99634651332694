import { NavigationProp, RouteProp } from '@react-navigation/native';
import { FormComponent } from '@components/form/form.component';
import { ModalComponent } from '@components/modal/modal.component';
import { UsersRoutes } from '../../users.routes';
import { UserAccessType } from '@human/access/types/user.access.type';
export function DeleteUserScreen(properties: {
  navigation: NavigationProp<UsersRoutes>;
  route: RouteProp<UsersRoutes, 'delete'>;
}) {
  return (
    <ModalComponent icon="trash-alt" prefix="access.users.delete">
      <FormComponent<UserAccessType & { password: string }>
        prefix="access.users.delete.form"
        padding
        fields={[
          {
            name: 'status',
            icon: 'lock',
            type: 'checkbox',
            validations: ['true'],
            description: true,
          },
        ]}
        onNext={() =>
          properties.navigation.navigate('password', {
            userId: properties.route.params.userId,
            level: 1,
          })
        }
      />
    </ModalComponent>
  );
}
