import { ActivityIndicator, Platform } from 'react-native';

export function LoaderComponent(properties: { loading: boolean; variant?: 'dark' | 'light' }) {
  const Loader = Platform.select({
    native: () => require('./loader.component.mobile').default,
    default: () => require('./loader.component.web').default,
  })();

  return <Loader loading={properties.loading} variant={properties.variant} />;
}
