import { FormComponent } from '@components/form/form.component';
import { ModalComponent } from '@components/modal/modal.component';
import { TimetableAccessRepository } from '@human/access/repositories/timetable/timetable.repository';
import { NavigationProp, RouteProp } from '@react-navigation/native';
import { TimetableRoutes } from '../../timetables.routes';

export function DeleteTimetableScreen(properties: {
  navigation: NavigationProp<TimetableRoutes>;
  route: RouteProp<TimetableRoutes, 'delete'>;
}) {
  return (
    <ModalComponent icon="trash-alt" prefix="access.timetables.delete">
      <FormComponent
        prefix="access.timetables.delete.form"
        padding
        repository={{
          send: (data) => {
            return new TimetableAccessRepository()
              .delete({
                timetableId: properties.route.params.timetableId,
              })
              .then(() => properties.navigation.goBack());
          },
        }}
        fields={[]}
      />
    </ModalComponent>
  );
}
