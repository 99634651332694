import { FormComponent } from '@components/form/form.component';
import { TabType } from '@components/tabs/tabs.types';
import { ClinicAccessRepository } from '@human/access/repositories/clinic/clinic.repository';
import { CampaignFinancingRepository } from '@human/crm/repositories/campaign/campaignFinancing.repository';
import { NavigationProp, RouteProp } from '@react-navigation/native';
import { CampaignsRoutes } from '../../../campaigns.routes';
import { useSuccessToast } from '@hooks/successToast/successToast.hook';
import { hasPermissionUtilty } from '@utils/permission/has.permission.utility';

export function FinancingCampaignPart(properties: {
  navigation: NavigationProp<CampaignsRoutes>;
  route: RouteProp<CampaignsRoutes, 'campaignUpdate'>;
  campaignId: string;
}): TabType {
  const { showSuccess } = useSuccessToast();
  return {
    name: 'financing',
    icon: 'money-bill',
    content: (settings) => {
      return (
        <FormComponent<{
          budget: number;
          financingClinicId: string;
        }>
          onChange={(data) => {
            if (!data.isLocal) return;
            settings?.setIsEditing(true);
          }}
          readonly={!hasPermissionUtilty({ name: 'crm.campaigns.editor' })}
          prefix="crm.campaigns.update.financing.form"
          repository={{
            get: () => {
              return new CampaignFinancingRepository().pick({
                campaignId: properties.campaignId,
              });
            },
            send: (data) => {
              return new CampaignFinancingRepository()
                .update({
                  campaignId: properties.campaignId,
                  budget: data.item.budget,
                  financingClinicId: data.item.financingClinicId,
                })
                .then(() => {
                  settings?.setIsEditing(false);
                  showSuccess();
                });
            },
          }}
          fields={[
            {
              type: 'float',
              name: 'budget',
              description: true,
              required: true,
              validations: ['price'],
            },
            {
              type: 'select',
              name: 'financingClinicId',
              nullable: true,
              description: true,
              required: true,
              pick: (properties: { id: string }) => {
                return new ClinicAccessRepository().pickForCombo({ id: properties.id });
              },
              repository: (settings) =>
                new ClinicAccessRepository().listForCombo({
                  search: settings.search,
                  active: true,
                }),
            },
          ]}
        />
      );
    },
  };
}
