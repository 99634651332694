// Modules
import { StyleSheet } from 'react-native';

/**
 * Export Button Styles
 * @constant {StyleSheet} ButtonStyles
 */
export const SecondaryButtonStyles = StyleSheet.create({
  button: {
    borderRadius: 4,
  },
  buttonContainer: {
    height: 40,
    borderRadius: 4,
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
    overflow: 'hidden',
    backgroundColor: '#273035',
  },
  buttonLabel: {
    color: '#fff',
    fontSize: 14,
    lineHeight: 15,
    marginLeft: 15,
  },
  iconContainer: {
    height: 40,
    width: 40,
    backgroundColor: '#111b22',
  },
  icon: {
    fontSize: 15,
    textAlign: 'center',
    lineHeight: 40,
    color: '#fff',
  },
});
