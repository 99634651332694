import { TableComponent } from '@components/table/table.component';
import { TabType } from '@components/tabs/tabs.types';
import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ButtonComponent } from '@components/button/button.component';
import { LicenseAccessType } from '@human/access/types/license.access.type';
import { LicenseAccessRepository } from '@human/access/repositories/license/license.repository';
import { UsersRoutes } from '../../../users.routes';
import { ScrollView } from 'react-native';

export function LicensesUpdateUserPart(properties: {
  navigation: NavigationProp<UsersRoutes>;
  route: RouteProp<UsersRoutes, 'update'>;
}): TabType {
  return {
    name: 'licenses',
    icon: 'lock',
    content: () => {
      return (
        <ScrollView>
          <TableComponent<LicenseAccessType, []>
            key={'licenses'}
            prefix={'access.licenses.list'}
            suffix={'list'}
            hideNoDataImage
            repository={(settings) =>
              new LicenseAccessRepository().list({
                userId: properties.route.params.userId,
                search: settings.search,
              })
            }
            columns={[
              {
                type: 'icon',
                icon: 'trash-alt',
                onPress: (row) => {
                  properties.navigation.navigate('licenseDelete', {
                    level: 1,
                    userId: properties.route.params.userId,
                    id: row.id,
                  });
                },
              },
              {
                type: 'text',
                width: 169,
                onRender: (row) => row.role.name,
              },
            ]}
          />
          <ButtonComponent
            icon="plus"
            prefix="access.users.update.licenses.add"
            onPress={() => {
              properties.navigation.navigate('licenseCreate', {
                level: 1,
                userId: properties.route.params.userId,
              });
            }}
          />
        </ScrollView>
      );
    },
  };
}
