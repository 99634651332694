import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ModalComponent } from '@components/modal/modal.component';
import { FormComponent } from '@components/form/form.component';

import { TimetableRoutes } from '../../timetables.routes';
import { TimetableAccessRepository } from '@human/access/repositories/timetable/timetable.repository';

export function DisableTimetableScreen(properties: {
  navigation: NavigationProp<TimetableRoutes>;
  route: RouteProp<TimetableRoutes, 'disable'>;
}) {
  return (
    <ModalComponent icon="trash-alt" prefix="access.timetables.disable">
      <FormComponent
        prefix="access.timetables.disable.form"
        padding
        repository={{
          send: () => {
            return new TimetableAccessRepository()
              .disable({
                timetableId: properties.route.params.timetableId,
              })
              .then(() => {
                properties.navigation.goBack();
              });
          },
        }}
        fields={[]}
      />
    </ModalComponent>
  );
}
