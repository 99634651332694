import { ModalComponent } from '@components/modal/modal.component';
import { NavigationProp, RouteProp } from '@react-navigation/native';
import { TabsComponent } from '@components/tabs/tabs.component';
import { CrmSections } from '@human/crm/crm.sections';
import { TreatmentsRoutes } from '../../treatment.routes';
import { UpdateTreatmentProceduresPart } from './parts/update.treatment.procedures.part';
import { FormUpdateTreatmentScreen } from './parts/form.update.treatment.screen';
import { UpdateTreatmentInterventionsPart } from './parts/update.treatment.interventions.part';
import { UpdateTreatmentMaintenancesPart } from './parts/update.treatment.manteinances.part';

export function UpdateTreatmentScreen(properties: {
  navigation: NavigationProp<TreatmentsRoutes>;
  route: RouteProp<TreatmentsRoutes, 'updateTreatment'>;
}) {
  return (
    <ModalComponent
      level={properties.route.params.level}
      icon={CrmSections.treatments.icon}
      flex={false}
      prefix="crm.treatments.update"
    >
      <TabsComponent
        layout="horizontal"
        prefix="crm.treatments.update"
        multiworkspace
        tabs={[
          {
            name: 'treatment',
            icon: CrmSections.treatments.icon,
            content: (settings) => <FormUpdateTreatmentScreen {...properties} />,
          },
          UpdateTreatmentProceduresPart(properties),
          UpdateTreatmentInterventionsPart(properties),
          UpdateTreatmentMaintenancesPart(properties),
        ]}
      />
    </ModalComponent>
  );
}
