import { FormComponent } from '@components/form/form.component';
import { ModalComponent } from '@components/modal/modal.component';
import { AccountProfileRepository } from '../../repositories/account/account.repository';
import { useUserState } from '@states/user/user.state';
import { ProfileType } from '../../types/profile.type';
import { NavigationProp } from '@react-navigation/native';
import { ProfileRoutes } from '../../profile.routes';
import { useSuccessToast } from '@hooks/successToast/successToast.hook';
import { ImageBackground } from 'react-native';

export function AccountScreen(properties: { navigation: NavigationProp<ProfileRoutes> }) {
  const { update } = useUserState();
  const { showSuccess } = useSuccessToast();
  return (
    <ImageBackground
      source={require('./../../../../../../../../assets/images/ovoprofile.jpg')}
      style={{ flex: 1, position: 'absolute', top: 0, left: 0, bottom: 0, right: 0, zIndex: 1 }}
      resizeMode="cover"
    >
      <ModalComponent transparent icon="user" prefix="profile.account">
        <FormComponent<Partial<ProfileType>>
          padding
          prefix="profile.account.form"
          repository={{
            get: () => {
              return new AccountProfileRepository().pick();
            },
            send: (props: { item: Partial<ProfileType> }) => {
              return new AccountProfileRepository()
                .update({
                  item: props.item,
                })
                .then((user) => {
                  update(user);
                  showSuccess();
                });
            },
          }}
          fields={[
            {
              type: 'pair',
              fields: [
                {
                  type: 'text',
                  name: 'name',
                  required: true,
                  description: true,
                  validations: ['name'],
                },
                {
                  type: 'text',
                  name: 'surname',
                  required: true,
                  description: true,
                  validations: ['surname'],
                },
              ],
            },
            {
              type: 'text',
              name: 'username',
              required: true,
              description: true,
              validations: ['username'],
            },
            {
              type: 'text',
              name: 'phone',
              required: true,
              description: true,
              validations: ['phone'],
            },
          ]}
        />
      </ModalComponent>
    </ImageBackground>
  );
}
