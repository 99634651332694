import { useTranslation } from 'react-i18next';
import { IconColumnComponent } from '../icon/icon.column.component';
import { FlagColumnImplementation } from './flag.column.properties';

export function FlagColumnComponent<T>(properties: FlagColumnImplementation<T, []>) {
  const { t } = useTranslation();

  return (
    <IconColumnComponent<T>
      {...properties}
      type="icon"
      icon={properties.status(properties.item) ? 'unlock' : 'lock'}
      renderColor={() => (properties.status(properties.item) ? 'green' : 'red')}
      popup={
        properties.status(properties.item)
          ? (t('common.flag.unlocked') ?? '')
          : (t('common.flag.locked') ?? '')
      }
      item={properties.item}
    />
  );
}
