// Modules
import { StyleSheet } from 'react-native';

/**
 * Clock Styles
 * @constant {StyleSheet} ClockStyles
 */
export const SelectInputStyles = StyleSheet.create({
  backgroundWhite: {
    backgroundColor: '#f8f8f8',
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderColor: '#e3e3e3',
    shadowColor: '#cccccc',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
  },
  searchInput: {
    flex: 1,
    marginLeft: 10,
  },
  input: {
    borderRadius: 5,
    backgroundColor: 'white',
    height: 42,
    borderWidth: 2,
    fontFamily: 'Lato-Bold',
    cursor: 'pointer',
    paddingLeft: 15,
  },
  inputDisabled: {
    opacity: 0.4,
  },
  inputFocus: {
    borderColor: 'rgba(237, 53, 145, 1)',
  },
  inputUnfocus: {
    borderColor: 'rgba(255, 255, 255, 0.1)',
  },
  inputReadonly: {
    backgroundColor: '#DDDDDD',
    color: '#777777',
    minHeight: 42,
    borderRadius: 5,
  },
  statusContainer: {
    overflow: 'hidden',
    borderRadius: 5,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    height: 42,
    maxHeight: 42,
    shadowOpacity: 0.1,
    shadowRadius: 3.84,
    elevation: 5,
  },

  iconStatusContainer: {
    height: 27,
    borderRadius: 50,
    width: 27,
    position: 'absolute',
    right: 9,
    top: 8,
    zIndex: 1,
  },
  cancelStatusContainer: {
    height: 27,
    borderRadius: 50,
    width: 27,
    position: 'absolute',
    right: 9,
    top: 8,
    zIndex: 1,
  },
  statusStyle: {
    color: 'rgba(237, 53, 145, 1)',
    fontSize: 18,
    textAlign: 'center',
    lineHeight: 27,
  },
  searchIconStyle: {
    color: '#D3D3D3',
    fontSize: 18,
    textAlign: 'center',
    lineHeight: 27,
  },
  options: {
    maxHeight: 300,
    backgroundColor: 'white',
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderColor: '#e3e3e3',

    shadowColor: '#cccccc',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.30,
    shadowRadius: 6,
  },
  optionItem: {
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(142, 142, 142, 0.2)',
    color: '#808080',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignContent: 'center',
  },
  optionItemOdd: {
    backgroundColor: 'rgba(142, 142, 142, 0.1)',
  },
  lastOption: {
    borderBottomWidth: 0,
  },
  emptyOption: {
    marginLeft: 10,
    marginRight: 10,
    padding: 10,
    borderBottomColor: 'rgba(142, 142, 142, 0.2)',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignContent: 'center',
    borderBottomWidth: 0,
  },
  bubbles: {
    flexDirection: 'row',
    alignItems: 'center',
    alignContent: 'center',
    gap: 10,
  },
  bubble: {
    borderRadius: 100,
    borderWidth: 2,
    zIndex: 2,
    padding: 5,
    paddingHorizontal: 10,
    alignSelf: 'flex-start',
    borderColor: 'rgb(237, 53, 145)',
    backgroundColor: 'rgba(237, 53, 145, 0.63)',
    color: '#fff',
  },
});
