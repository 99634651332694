import { CountryAccessType } from '@human/access/types/country.access.type';
import { AuthorizedRepository } from '@core/repository/authorized.repository.core';

export class CountryAccessRepository extends AuthorizedRepository {
  async list(properties: {
    direction: 'asc' | 'desc';
    page: number;
    limit: number;
    search?: string;
    active?: boolean;
  }): Promise<{
    items: CountryAccessType[];
    total: number;
  }> {
    return this.fetch(
      `country/find`,

      {
        method: 'GET',
        params: properties,
      }
    ).then((data) => {
      return {
        items: data.items.map((country: any) => ({
          countryId: country.id,
          countryNameEn: country.countryNameEn,
          countryNameEs: country.countryNameEs,
          flag: country.flag,
          code: country.code,
          prefix: country.prefix,
          geometry: country.geometry,
          description: country.description,
          color: country.color,
          created: country.created,
          updated: country.updated,
          coin: country.coin,
          status: country.status,
        })),
        total: data.total,
      };
    });
  }

  async listForCombo(properties: { search?: string; active?: boolean }): Promise<
    {
      label: string;
      value: string;
    }[]
  > {
    return this.fetch(`country/findForCombo`, {
      method: 'GET',
      params: properties,
    });
  }

  async listForLangCombo(properties: { search?: string; active?: boolean }): Promise<
    {
      label: string;
      value: string;
    }[]
  > {
    return this.fetch(`country/findForLangCombo`, {
      method: 'GET',
      params: properties,
    });
  }

  async pick(properties: { countryId: string }): Promise<Partial<CountryAccessType>> {
    return this.fetch(`country/pick`, {
      method: 'GET',
      params: {
        countryId: properties.countryId,
      },
    }).then((data) => ({
      countryNameEn: data.countryNameEn,
      countryNameEs: data.countryNameEs,
      flag: data.flag,
      code: data.code,
      prefix: data.prefix,
      geometry: data.geometry,
      description: data.description,
      color: data.color,
      coin: data.coin,
      countryId: data.id,
    }));
  }

  async pickForCombo(properties: { countryId: string }): Promise<string> {
    return this.fetch(`country/pickForCombo`, {
      method: 'GET',
      params: properties,
    });
  }

  async pickForLangCombo(properties: { countryId: string }): Promise<string> {
    return this.fetch(`country/pickForLangCombo`, {
      method: 'GET',
      params: properties,
    });
  }

  async pickByCode(properties: { code: string }): Promise<CountryAccessType> {
    return this.fetch(`country/pickByCode`, {
      method: 'GET',
      params: {
        code: properties.code,
      },
    });
  }

  async create(properties: { item: Partial<CountryAccessType> }): Promise<{ countryId: string }> {
    return this.fetch('country/create', {
      method: 'POST',
      body: properties.item,
    }).then((data) => ({
      countryId: data.id,
    }));
  }

  async update(properties: { item: Partial<CountryAccessType> }): Promise<{ countryId: string }> {
    return this.fetch(`country/update?countryId=${properties.item.countryId}`, {
      method: 'PUT',
      body: properties.item,
    }).then((data) => ({
      countryId: data.id,
    }));
  }

  async delete(properties: { id: string }): Promise<void> {
    return this.fetch(`country/remove/${properties.id}`, {
      method: 'DELETE',
    });
  }

  async enable(properties: { countryId: string }): Promise<void> {
    return this.fetch('country/enable', {
      method: 'PUT',
      params: properties,
    });
  }

  async disable(properties: { countryId: string }): Promise<void> {
    return this.fetch('country/disable', {
      method: 'PUT',
      params: properties,
    });
  }
}
