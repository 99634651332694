import { Pressable, View } from 'react-native-web';
import { IconComponent } from '../../../../../icon/icon.component';
import { IconColumnImplementation } from './icon.column.properties';
import { IconColumnStyles } from './icon.column.styles';
import { useRef, useState } from 'react';
import { useTool } from '@context/tool/tool.context';

export function IconColumnComponent<T>(properties: IconColumnImplementation<T, []>) {
  const [hover, setHover] = useState(false);

  const columnRef = useRef<typeof View>(null);
  const { updateTool } = useTool();

  const icon =
    'renderIcon' in properties
      ? properties.renderIcon?.(properties.item)
      : 'icon' in properties
        ? properties.icon
        : '';

  return (
    <Pressable
      ref={columnRef}
      disabled={properties.blockAction}
      onMouseEnter={() => {
        setHover(true);
        if (!properties.popup) return;
        columnRef.current &&
          columnRef.current.measure((x, y, width, height, pageX, pageY) => {
            updateTool({
              content: properties.popup ?? '',
              left: pageX + 10,
              top: pageY - height / 2,
            });
          });
      }}
      onMouseLeave={() => setHover(false)}
      onPress={() => properties.onPress?.(properties.item)}
      style={[
        IconColumnStyles.container,
        hover && IconColumnStyles.hovering,
        icon === 'trash-alt'
          ? IconColumnStyles.containerIsTrash
          : IconColumnStyles.containerIsNotTrash,
      ]}
    >
      <IconComponent
        name={icon as any}
        containerstyle={[
          IconColumnStyles.iconContainer,
          { backgroundColor: properties.renderBackground?.(properties.item) ?? 'transparent' },
        ]}
        iconStyle={[
          IconColumnStyles.iconStyle,
          {
            color:
              icon === 'trash-alt' || icon === 'lock'
                ? IconColumnStyles.iconIsTrash.color
                : (properties.renderColor?.(properties.item) ??
                  IconColumnStyles.iconsIsNotTrash.color),
          },
        ]}
      />
    </Pressable>
  );
}
