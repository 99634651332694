import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ResultsRoutes } from '../../../result.routes';
import { TabType } from '@components/tabs/tabs.types';
import { FormComponent } from '@components/form/form.component';
import { ResultCrmType } from '@human/crm/types/result.crm.type';
import { ResultCrmRepository } from '@human/crm/repositories/result/result.repository';
import { ActivityCrmRepository } from '@human/crm/repositories/activity/activity.repository';
import { useSuccessToast } from '@hooks/successToast/successToast.hook';

export function UpdateResultNextActivity(properties: {
  navigation: NavigationProp<ResultsRoutes>;
  route: RouteProp<ResultsRoutes, 'resultUpdate'>;
}): TabType {
  const { showSuccess } = useSuccessToast();

  return {
    name: 'nextActivity',
    icon: 'calendar-check',
    content: (settings) => (
      <FormComponent<ResultCrmType>
        prefix="crm.results.update.form"
        repository={{
          get: () => {
            return new ResultCrmRepository().pickNextActivity({
              resultId: properties.route.params.resultId,
            });
          },
          send: (data) => {
            return new ResultCrmRepository()
              .updateNextActivity({
                activityId: properties.route.params.activityId,
                resultId: properties.route.params.resultId,
                item: data.item,
              })
              .then(() => {
                settings?.setIsEditing(false);
                showSuccess();
              });
          },
        }}
        fields={[
          {
            name: 'nextActivityId',
            type: 'select',
            description: true,
            nullable: true,
            pick: (props: { id: string }) => {
              return new ActivityCrmRepository().pickForCombo({ activityId: props.id });
            },
            repository: ({ search }) =>
              new ActivityCrmRepository().listForCombo({
                search,
                active: true,
              }),
          },
          {
            type: 'number',
            name: 'nextActivityDelay',
            description: true,
          },
        ]}
      />
    ),
  };
}
