// Modules
import { MainTheme } from '@theme/main/main.theme';
import { StyleSheet } from 'react-native';

/**
 * Tabs Styles
 * @constant {StyleSheet} TabsStyles
 */
export const TabsStyles = StyleSheet.create({
  containerHorizontal: {
    flex: 1,
    flexDirection: 'row',
  },
  containerVertical: {
    flex: 1,
  },
  tabContainerVertical: {
    flexDirection: 'row',
    backgroundColor: '#ddd',
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 15,
    height: 50,
    maxHeight: 50,
    gap: 5,
  },
  tabContainerHorizontal: {
    flex: 2,
    flexDirection: 'column',
    backgroundColor: '#ddd',
  },
  tabIndicator: {
    height: 0,
    position: 'absolute',
    left: 0,
    bottom: 0,
    right: 0,
  },
  tabHorizontal: {
    flexDirection: 'row',
    overflow: 'hidden',
    alignItems: 'center',
    gap: 7,
    padding: 10,
  },
  tabVertical: {
    padding: 7,
    flexDirection: 'row',
    overflow: 'hidden',
    alignItems: 'center',
    borderTopEndRadius: 5,
    borderTopStartRadius: 5,
    gap: 7,
    paddingLeft: 10,
    paddingRight: 15,
    backgroundColor: '#ddd',
  },
  largeTabsInactive: {
    paddingLeft: 15,
  },
  tabActive: {
    backgroundColor: MainTheme.primaryColor,
  },
  tabInactive: {
    backgroundColor: '#ddd',
  },
  tabIcon: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  tabIconActive: {
    color: 'white',
  },
  tabIconInactive: {
    color: '#777',
  },
  tabText: {
    textAlign: 'center',
  },
  tabTextActive: {
    color: 'white',
  },
  tabTextInactive: {
    color: '#777',
  },
  content: {
    padding: 20,
    gap: 20,
    flex: 6,
    backgroundColor: '#eee',
  },
  description: {
    backgroundColor: '#fff',
    padding: 20,
    paddingVertical: 14,
    borderLeftColor: 'rgb(129, 59, 94)',
    borderLeftWidth: 4,
    maxWidth: 450,
  },
  descriptionMobile: {
    padding: 10,
    fontSize: 10,
  },
});
