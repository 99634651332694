import { FormComponent } from '@components/form/form.component';
import { ClinicAccessRepository } from '@human/access/repositories/clinic/clinic.repository';
import { CountryAccessRepository } from '@human/access/repositories/country/country.repository';
import { ClinicAccessType } from '@human/access/types/clinic.access.type';
import { timeZonesConstant } from '../../../../../../../../../../../constants/timezones.constants';
import { useSuccessToast } from '@hooks/successToast/successToast.hook';
import { hasPermissionUtilty } from '@utils/permission/has.permission.utility';

export function DetailUpdateClinicPart(properties: {
  goBack: () => void;
  clinicId: string;
  onChange: (properties: { item: Partial<ClinicAccessType>; isLocal: boolean }) => void;
}) {
  const { showSuccess } = useSuccessToast();
  return (
    <FormComponent<ClinicAccessType>
      onChange={properties.onChange}
      prefix="access.clinics.update.form"
      readonly={!hasPermissionUtilty({ name: 'access.clinics.editor' })}
      repository={{
        send: (props: { item: Partial<ClinicAccessType> }) => {
          return new ClinicAccessRepository()
            .update({
              item: props.item,
            })
            .then(() => showSuccess());
        },
        get: () => {
          return new ClinicAccessRepository()
            .pick({
              id: properties.clinicId,
            })
            .then((response) => {
              return response;
            });
        },
      }}
      fields={[
        {
          type: 'text',
          name: 'name',
          description: true,
          required: true,
          validations: ['name'],
        },

        {
          type: 'select',
          name: 'country',
          description: true,
          pick: (properties: { id: string }) => {
            return new CountryAccessRepository()
              .pickByCode({ code: properties.id })
              .then((country) => {
                return `${country.flag} ${country.countryNameEs}`;
              });
          },
          repository: () => {
            return new CountryAccessRepository().listForCombo({
              active: true,
            });
          },
        },
        {
          type: 'text',
          name: 'state',
          description: true,
          required: true,
          validations: ['state'],
        },
        {
          type: 'text',
          name: 'city',
          description: true,
          required: true,
          validations: ['city'],
        },
        {
          type: 'text',
          name: 'street',
          description: true,
          required: true,
          validations: ['street'],
        },
        {
          type: 'text',
          name: 'zip',
          description: true,
          required: true,
          validations: ['zip'],
        },
        {
          type: 'map',
          name: 'center',
          description: true,
          marker: {
            icon: 'clinic-medical',
            color: 'red',
          },
        },
        {
          type: 'select',
          name: 'timezone',
          description: true,
          required: true,
          options: timeZonesConstant,
        },
        {
          type: 'checkbox',
          name: 'status',
          required: true,
          description: true,
          multiWorkspace: true,
        },
        {
          type: 'text',
          name: 'avatar',
          description: true,
        },
        {
          type: 'text',
          name: 'document',
          required: true,
          description: true,
        },
        {
          type: 'text',
          name: 'title',
          required: true,
          description: true,
          validations: ['title'],
        },
        {
          type: 'text',
          name: 'description',
          description: true,
          required: true,
          lines: 6,
          validations: ['description'],
        },
        {
          type: 'color',
          name: 'color',
          description: true,
        },
      ]}
    />
  );
}
