import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ModalComponent } from '@components/modal/modal.component';
import { FormComponent } from '@components/form/form.component';
import { ProcedureCrmRepository } from '@human/crm/repositories/procedure/procedure.repository';
import { ProceduresRoutes } from '../../procedure.routes';

export function DeleteProceduresScreen(properties: {
  navigation: NavigationProp<ProceduresRoutes>;
  route: RouteProp<ProceduresRoutes, 'deleteProcedure'>;
}) {
  return (
    <ModalComponent icon="trash-alt" prefix="crm.procedures.delete">
      <FormComponent
        prefix="crm.procedures.delete.form"
        padding
        repository={{
          send: () => {
            return new ProcedureCrmRepository()
              .delete({
                techniqueId: properties.route.params.techniqueId,
                clinicId: properties.route.params.clinicId,
                treatmentId: properties.route.params.treatmentId,
                patientId: properties.route.params.patientId,
              })
              .then(() => {
                properties.navigation.goBack();
              });
          },
        }}
        fields={[]}
      />
    </ModalComponent>
  );
}
