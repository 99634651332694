import React from 'react';
import { NavigationProp, RouteProp } from '@react-navigation/native';
import { CampaignsRoutes } from '../../../../campaigns.routes';
import { apiUrl } from '@environment/apiUrl.utility';
import { getWorkspaceUtility } from '@utils/workspace/getWorkspace.utility';
import { WebhookInputComponent } from './components/webhookInput/webhookInput.component';

export function WebhookCampaignScreen(properties: {
  navigation: NavigationProp<CampaignsRoutes>;
  route: RouteProp<CampaignsRoutes, 'campaignUpdate'>;
}) {
  if (getWorkspaceUtility() === 'ovoclinic')
    return (
      <WebhookInputComponent
        apiUrl={`${apiUrl}/webhook/lead?campaignId=${properties.route.params.id}`}
        label="crm.campaigns.update.tabs.webhook.label"
        subtitle="crm.campaigns.update.tabs.webhook.subtitle"
      />
    );

  if (getWorkspaceUtility() === 'ovobank')
    return (
      <>
        <WebhookInputComponent
          apiUrl={`${apiUrl}/webhook/lead-ovobank?campaignId=${properties.route.params.id}`}
          label="crm.campaigns.update.tabs.webhook.label_ovobank"
          subtitle="crm.campaigns.update.tabs.webhook.subtitle_ovobank"
        />
        <WebhookInputComponent
          apiUrl={`${apiUrl}/webhook/lead-ovobank-static?campaignId=${properties.route.params.id}`}
          label="crm.campaigns.update.tabs.webhook.label_ovobank_static"
          subtitle="crm.campaigns.update.tabs.webhook.subtitle_ovobank_static"
        />
      </>
    );
  return <></>;
}
