import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ModalComponent } from '@components/modal/modal.component';
import { FormComponent } from '@components/form/form.component';
import { CauseRoutes } from '../../cause.routes';
import { CauseCrmRepository } from '@human/crm/repositories/cause/cause.repository';

export function EnableCauseScreen(properties: {
  navigation: NavigationProp<CauseRoutes>;
  route: RouteProp<CauseRoutes, 'causeEnable'>;
}) {
  return (
    <ModalComponent icon="trash-alt" prefix="crm.causes.enable">
      <FormComponent
        prefix="crm.causes.enable.form"
        padding
        repository={{
          send: () => {
            return new CauseCrmRepository()
              .enable({
                causeId: properties.route.params.causeId,
                phaseId: properties.route.params.phaseId,
              })
              .then(() => {
                properties.navigation.goBack();
              });
          },
        }}
        fields={[]}
      />
    </ModalComponent>
  );
}
