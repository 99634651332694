// Modules
import { StyleSheet } from 'react-native';

/**
 * Clock Styles
 * @constant {StyleSheet} FilterSelectInputStyles
 */
export const FilterSelectInputStyles = StyleSheet.create({
  searchContainer: {
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    position: 'absolute',
    width: '100%',
    top: 40,
    borderTopColor: '#e3e3e3',
    borderLeftColor:'#e3e3e3',
    borderRightColor:'#e3e3e3',
    borderWidth: 1,
    backgroundColor: '#f8f8f8',
  },

  searchInput: {
    flex: 1,
    marginLeft: 10,
    placeholderTextColor: 'e3e3e3',
  },

  input: {
    borderRadius: 5,
    height: 40,
    cursor: 'pointer',
    paddingLeft: 15,
    color: 'black',
    fontSize: 14,
    lineHeight: 15,
    borderWidth: 1,
    borderColor: '#c0c0c0',
  },
  inputPhone: {
    fontSize: 16,
  },
  inputDisabled: {
    opacity: 0.4,
  },
  inputFocus: {
    borderWidth: 1.5,
    borderColor: '#cccccc',
  },
  inputUnfocus: {
    borderColor: 'rgba(237, 53, 145, 1)',
    borderWidth: 2,
  },
  inputBoard: {
    borderWidth: 1,
    borderColor: '#ddd',
    marginBottom: 4,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  statusContainer: {
    overflow: 'hidden',
    borderRadius: 5,
    backgroundColor: 'rgba(255,255,255, 0.8)',
    height: 40,
    maxHeight: 40,
  },

  cancelStatusContainer: {
    height: 27,
    borderRadius: 50,
    width: 27,
    position: 'absolute',
    right: 9,
    top: 8,
    zIndex: 1,
  },
  iconStatusContainer: {
    height: 27,
    borderRadius: 50,
    width: 27,
    position: 'absolute',
    right: 9,
    top: 8,
    zIndex: 1,
  },

  statusStyle: {
    color: '#111b22',
    fontSize: 16,
    textAlign: 'center',
    lineHeight: 26,
  },

  options: {
    backgroundColor: 'white',
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    position: 'absolute',
    width: '100%',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    borderWidth: 1,
    borderColor: '#e3e3e3',
  },
  optionsContainerBoard: {
    borderWidth: 1,
    borderColor: '#ddd',
    backgroundColor: '#F2F2F2',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
  },
  optionsBoard: {
    borderColor: '#ddd',
    marginLeft: 0,
    marginRight: 0,
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  optionItem: {
    //marginLeft: 10,
    //marginRight: 10,
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(142, 142, 142, 0.2)',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignContent: 'center',
    cursor: 'pointer',
  },
  optionItemOdd: {
    backgroundColor: 'rgba(142, 142, 142, 0.1)',
  },
  lastOption: {
    borderBottomWidth: 0,
  },
  emptyOption: {
    //marginLeft: 10,
    //marginRight: 10,
    padding: 10,
    borderBottomColor: 'rgba(142, 142, 142, 0.2)',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignContent: 'center',
    borderBottomWidth: 0,
  },
  bubbles: {
    flexDirection: 'row',
    alignItems: 'center',
    alignContent: 'center',
    gap: 10,
  },
  bubble: {
    borderRadius: 100,
    borderWidth: 2,
    zIndex: 2,
    padding: 5,
    paddingHorizontal: 10,
    alignSelf: 'flex-start',
    borderColor: 'rgb(237, 53, 145)',
    backgroundColor: 'rgba(237, 53, 145, 0.63)',
    color: '#fff',
  },
  optionLabel: {
    color: 'black',
    fontSize: 14,
    fontFamily: 'Lato-Regular',
  },
  phoneOptionLabel: {
    color: 'black',
    fontSize: 16,
    fontFamily: 'Lato-Regular',
  },
  bubbleLabel: {
    fontWeight: '600',
    color: 'rgb(255, 255, 255)',
    fontSize: 12,
  },
  phoneBubbleLabel: {
    fontWeight: '600',
    color: 'rgb(255, 255, 255)',
    fontSize: 16,
  },
});
