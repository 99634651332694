import { View, Text, useWindowDimensions } from 'react-native';
import { useTranslation } from 'react-i18next';
import { SaleFunnelPerDepartmentTableStyles } from '../../../funnels/components/saleFunnelPerDepartmentTable/saleFunnelPerDepartmentTable.styles';
import { TotalLeadsStateDepartmentsTableRow } from './row/totalLeadsStateDepartmentsTable.row';
import { useDeviceHook } from '@hooks/device/device.hook';
import { TotalLeadsStateDepartmentsTableStyles } from './totalLeadsStateDepartments.styles';

export function TotalLeadsStateDepartmentsTable(properties: {
  data: {
    department_id: string;
    department_name: string;
    total_leads: number;
    lead_appointed_count: number;
    lead_percentage_appointed: number;
    lead_active_count: number;
    lead_inactive_count: number;
  }[];
}) {
  const { t } = useTranslation();
  const { phone } = useDeviceHook();
  const { height } = useWindowDimensions();

  const leadEvent: { [key: string]: string } = {
    total: t('board.sections.marketing.totalLeadsPerState.total'),
    appointed: t('board.sections.marketing.totalLeadsPerState.appointed'),
    percentage: t('board.sections.marketing.totalLeadsPerState.percentage'),
    actives: t('board.sections.marketing.totalLeadsPerState.actives'),
    inactives: t('board.sections.marketing.totalLeadsPerState.inactives'),
  };

  const SaleFunnelPerDepartmentTableColumn = (properties: { text: string }) => {
    return (
      <View
        style={
          phone
            ? TotalLeadsStateDepartmentsTableStyles.phoneColumnContainer
            : TotalLeadsStateDepartmentsTableStyles.columnContainer
        }
      >
        <Text
          style={
            phone
              ? TotalLeadsStateDepartmentsTableStyles.phoneColumnText
              : TotalLeadsStateDepartmentsTableStyles.columnText
          }
          numberOfLines={1}
          adjustsFontSizeToFit
        >
          {properties.text}
        </Text>
      </View>
    );
  };

  return (
    <View
      style={[
        TotalLeadsStateDepartmentsTableStyles.container,
        {
          minHeight: height * 0.3,
        },
      ]}
    >
      <View style={TotalLeadsStateDepartmentsTableStyles.wrapper}>
        <View style={[SaleFunnelPerDepartmentTableStyles.headerContainer]}>
          <View
            style={
              phone
                ? TotalLeadsStateDepartmentsTableStyles.phoneHeaderWrapper
                : TotalLeadsStateDepartmentsTableStyles.headerWrapper
            }
          />
          {Object.values(leadEvent).map((value) => (
            <SaleFunnelPerDepartmentTableColumn text={value} />
          ))}
        </View>
        <View style={SaleFunnelPerDepartmentTableStyles.headerSpacer} />
        {properties.data.map((value, iparent) => (
          <TotalLeadsStateDepartmentsTableRow
            last={iparent + 1 === properties.data.length}
            iparent={iparent}
            row={value}
          />
        ))}
      </View>
    </View>
  );
}
