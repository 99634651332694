import { ScrollView, Text, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import { TwoYearColumnLeadsComparativeStyles } from './twoYearColumnLeadsComparative.styles';
import { ItemByDepartmentYearsComparativeType } from '../newLeadsByDepartmentYearsComparative/newLeadByDepartmentYearsComparative.type';
import { useDeviceHook } from '@hooks/device/device.hook';
import { ComparativeYearsLeadCountRow } from '../newLeadsByDepartmentYearsComparative/components/comparativeYearsLeadCountRow';
import { BoardNoDataComponent } from '../../../../components/noData/boardNoData.component';
import { useEffect, useState } from 'react';

export function TwoYearColumnLeadsComparative(properties: {
  firstYear: number;
  secondYear: number;
  data: ItemByDepartmentYearsComparativeType[];
  interventionType?: boolean;
  onCellPress: (settings: {
    departmentId: string | undefined;
    year: number;
    month?: number;
  }) => void;
}) {
  const { t } = useTranslation();
  const { phone } = useDeviceHook();

  const [rowsData, setRowsData] = useState<
    {
      department_id: string;
      department_name: string;
      total_leads: number[];
    }[]
  >([]);

  useEffect(() => {
    const departments = new Map<string, string>();
    properties.data.forEach((item) => {
      departments.set(item.department_id, item.department_name);
    });
    const formattedData: Array<{
      department_id: string;
      department_name: string;
      total_leads: number[];
    }> = [];
    departments.forEach((department, departmentId) => {
      const departmentData = properties.data
        .filter((item) => item.department_name === department)
        .sort((a, b) => {
          if (a.month === b.month) {
            return a.year - b.year;
          }
          return a.month - b.month;
        });
      const totalFirstYear = departmentData
        .filter((item) => item.year === properties.firstYear)
        .reduce((acc, item) => acc + item.total, 0);

      const totalSecondYear = departmentData
        .filter((item) => item.year === properties.secondYear)
        .reduce((acc, item) => acc + item.total, 0);

      formattedData.push({
        department_id: departmentId,
        department_name: department,
        total_leads: [totalFirstYear, totalSecondYear],
      });
    });

    setRowsData(formattedData);
  }, [properties.data]);

  const LeadCountTableColumn = (settings: {
    header: {
      month?: string;
      subheaders: number[];
    };
  }) => {
    return (
      <View style={[TwoYearColumnLeadsComparativeStyles.headerColumnContainer]}>
        {settings.header.month && (
          <Text style={TwoYearColumnLeadsComparativeStyles.headerText}>
            {t(`board.months.${settings.header.month}`)}
          </Text>
        )}

        <View
          style={{
            flexDirection: 'row',
            gap: 15,
            justifyContent: 'center',
          }}
        >
          {settings.header.subheaders.map((item) => (
            <Text
              key={item}
              style={
                phone
                  ? TwoYearColumnLeadsComparativeStyles.phoneHeaderText
                  : TwoYearColumnLeadsComparativeStyles.headerText
              }
            >
              {item}
            </Text>
          ))}
        </View>
      </View>
    );
  };

  if (properties.data.length === 0) {
    return <BoardNoDataComponent />;
  }

  return (
    <>
      <ScrollView horizontal style={{ flexDirection: 'column' }}>
        <View style={{ flex: 1 }}>
          <View style={TwoYearColumnLeadsComparativeStyles.headerContainer}>
            {!phone && <View style={TwoYearColumnLeadsComparativeStyles.headerSpaceHeader} />}

            {[{ month: undefined, subheaders: [properties.firstYear, properties.secondYear] }].map(
              (item) => (
                <LeadCountTableColumn key={item.month} header={item} />
              )
            )}
          </View>

          {rowsData.map((item, index) => (
            <ComparativeYearsLeadCountRow
              firstYear={properties.firstYear}
              lastYear={properties.secondYear}
              last={index === rowsData.length - 1}
              firstAttemptRow={
                properties.interventionType ? index === rowsData.length - 3 : undefined
              }
              isLossRow={properties.interventionType ? index === rowsData.length - 2 : undefined}
              key={item.department_name}
              item={item}
              onCellPress={properties.onCellPress}
            />
          ))}
        </View>
      </ScrollView>
    </>
  );
}
