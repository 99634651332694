import { CSSProperties } from 'react';
import { StyleSheet } from 'react-native';

export const SlicerStylesWeb: CSSProperties = {
  flex: 1,
  fontFamily: 'Lato-Bold, sans-serif',
  width: 300,
  position: 'absolute',
  right: 10,
  backgroundColor: 'white',
  borderRadius: 5,
  zIndex: 1,
  padding: 15,
  boxShadow: '0 8px 16px 0 rgba(0, 0, 0, 0.2)',
};

export const SlicerStyles = StyleSheet.create({
  iconsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  button: {
    paddingHorizontal: 10,
    borderRadius: 10,
    flexDirection: 'row',
    alignItems: 'center',
    height: 36,
    maxHeight: 38,
    cursor: 'pointer',
  },
  cancelButton: {
    borderWidth: 1,
    borderColor:'rgb(225, 225, 225)',
  },
  cancelButtonIcon: {
    color: '#6e6e6e',
    fontSize: 16,
    fontWeight: 'bold',
  },
  applyButton: {
    backgroundColor: 'rgb(237, 53, 145)',
    borderColor: 'rgb(237, 53, 145)',
  },
  appluButtonIcon: {
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold',
  },
});
