import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ActivitiesRoutes } from '../../activity.routes';
import { ModalComponent } from '@components/modal/modal.component';
import { FormComponent } from '@components/form/form.component';
import { ActivityCommunicationChannelCrmRepository } from '@human/crm/repositories/activityCommunicationChannel/activityCommunicationChannel.repository';

export function DeleteCommunicationChannelScreen(properties: {
  navigation: NavigationProp<ActivitiesRoutes>;
  route: RouteProp<ActivitiesRoutes, 'deleteCommunicationChannel'>;
}) {
  return (
    <ModalComponent
      level={properties.route.params.level}
      icon="trash-alt"
      prefix="crm.activities.update.communicationChannels.delete"
    >
      <FormComponent
        prefix="crm.activities.update.communicationChannels.delete.form"
        padding
        repository={{
          send: () => {
            return new ActivityCommunicationChannelCrmRepository()
              .delete({
                activityId: properties.route.params.activityId,
                communicationChannelId: properties.route.params.communicationChannelId,
              })
              .then(() => {
                properties.navigation.goBack();
              });
          },
        }}
        fields={[]}
      />
    </ModalComponent>
  );
}
