import { TextComponent } from '../../../text/text.component';
import { CardProperties } from './card.properties';
import { IconComponent } from '../../../icon/icon.component';
import { View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import {
  CardWebStyle,
  CardStyles,
  CardWebTabletStyle,
  CardWebLaptopStyle,
} from './card.styles.web';
import { useDeviceHook } from '@hooks/device/device.hook';
import { useTranslation } from 'react-i18next';

/**
 * Card Component
 * @description Carta de presentación de una sección de la aplicación, muestra
 * el icono, el título, la descripción y las palabras clave de la sección.
 *
 * @param {CardProperties} properties
 * @returns {JSX.Element}
 */

export function CardComponent(properties: CardProperties) {
  const navigation = useNavigation<any>();
  const { device, tablet, laptop } = useDeviceHook();
  const { t } = useTranslation();

  return (
    <div
      onClick={() => {
        navigation.navigate(`${properties.title}`);
      }}
      style={{
        ...CardWebStyle(properties.index),
        ...(device === 'tablet' && CardWebTabletStyle),
        ...(device === 'laptop' && CardWebLaptopStyle),
      }}
    >
      <View
        style={{
          ...CardStyles.iconContainer,
          ...(device === 'laptop' && CardStyles.iconContainerLaptop),
        }}
      >
        <IconComponent
          name={properties.icon}
          iconStyle={{ ...CardStyles.icon, ...(device === 'laptop' && CardStyles.iconLaptop) }}
        />
      </View>
      <View
        style={{
          ...CardStyles.headerContainer,
          ...(device === 'laptop' && CardStyles.headerContainerLaptop),
        }}
      >
        <TextComponent
          translate
          text={'OVO'}
          light
          style={{ ...CardStyles.titleOVO, ...(device === 'laptop' && CardStyles.titleOVOlaptop) }}
        />

        <TextComponent
          text={properties.title}
          bold
          style={{
            ...CardStyles.titleBold,
            ...(device === 'laptop' && CardStyles.titleBoldLaptop),
          }}
        />
        <View style={CardStyles.agoContainer}>
          <TextComponent translate text={properties.version} bold style={CardStyles.version} />
        </View>
      </View>

      <TextComponent
        translate
        text={`launch.${properties.title}.description`}
        style={[CardStyles.description, (tablet || laptop) && CardStyles.descriptionSmall]}
      />
      <IconComponent
        name="arrow-right"
        containerstyle={[
          CardStyles.arrowIconContainer,
          (tablet || laptop) && CardStyles.arrowIconContainerSmall,
        ]}
        iconStyle={[CardStyles.arrowIcon, (tablet || laptop) && CardStyles.arrowIconSmall]}
      />
      <View style={[CardStyles.keywordsContainer]}>
        {properties.keywords.map((keyword, index) => {
          return (
            <View key={index} style={CardStyles.keywordBox}>
              <TextComponent
                translate
                text={keyword}
                bold
                style={[
                  CardStyles.keywordText,
                  (device === 'laptop' || device === 'tablet') && CardStyles.keywordLittleText,
                ]}
              />
            </View>
          );
        })}
      </View>
    </div>
  );
}
