import { StyleSheet } from 'react-native';

export const inactiveLeadsReasonTableHeaderStyles = StyleSheet.create({
  container: {
    flex: 10,
    flexDirection: 'row',
  },
  text: {
    flex: 1,
    color: 'black',
    textAlign: 'center',
    fontWeight: '300',
    fontSize: 12,
    marginBottom: 20,
  },
});
