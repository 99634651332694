import { View, Dimensions } from 'react-native';
import { LeadActiveInactiveChartProperties } from './leadActiveInactiveChart.properties';
import { useEffect, useMemo, useState } from 'react';
import { LeadActiveInactiveChartType } from './leadActiveInactiveChart.type';
import { LeadDetailBoardRepository } from '../../../../repositories/leadDetail/leadDetail.repository';
import { graphicsColors } from '../../../../utilities/board.color.utility';
import { Pie } from 'react-chartjs-2';

const { height } = Dimensions.get('window');

export function LeadActiveInactiveChartWeb(properties: LeadActiveInactiveChartProperties) {
  const [data, setData] = useState<LeadActiveInactiveChartType[]>([]);

  const colors = useMemo(
    () =>
      data && data.length > 0 ? data.map((_, i) => graphicsColors[i % graphicsColors.length]) : [],
    [data]
  );

  useEffect(() => {
    new LeadDetailBoardRepository()
      .leadActiveInactiveComparativeByMonth({
        month: properties.month,
        funnelId: properties.funnelId,
        languageId: properties.departmentId,
        clinicId: properties.clinicId,
        year: properties.year,
      })
      .then((response) => {
        setData(response);
      });
  }, [
    properties.month,
    properties.funnelId,
    properties.departmentId,
    properties.clinicId,
    properties.year,
  ]);

  const dataset = {
    labels: data.map((item) => item.funnel),
    datasets: [
      {
        data: data.map((item) => item.lead_count),
        backgroundColor: colors,
        borderWidth: 1,
      },
    ],
  };

  return (
    <View style={{ backgroundColor: 'white', borderRadius: 4, padding: 20, height: height * 0.4 }}>
      <Pie
        data={dataset}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: 'bottom',
              labels: {
                color: '#283038',
              },
            },
            tooltip: {
              enabled: dataset.labels && dataset.labels.length > 0,
              callbacks: {
                label: (tooltipItem: any) => `${tooltipItem.label}: ${tooltipItem.raw} leads`,
              },
            },
          },
        }}
      />
    </View>
  );
}
