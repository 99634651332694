import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import 'react-native-gesture-handler';
import { MainRoutes } from './screens/main.routes';
import { AuthorizedScreen } from './screens/authorized/authorized.screen';
import { UnauthorizedSpace } from './screens/unauthorized/unauthorized.space';

const Stack = createStackNavigator<MainRoutes>();

export function MainApplication(properties: { isAuthenticated: boolean }) {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
      }}
    >
      {!properties.isAuthenticated && (
        <Stack.Screen name="unauthorized" component={UnauthorizedSpace} />
      )}
      {properties.isAuthenticated && (
        <Stack.Screen name="authorized" component={AuthorizedScreen} />
      )}
    </Stack.Navigator>
  );
}
