import { View } from 'react-native';
import { postSplashStyles } from './postSplash.styles';
import Animated, {
  useSharedValue,
  useAnimatedStyle,
  withTiming,
  withSequence,
  withDelay,
  withRepeat,
} from 'react-native-reanimated';
import { useEffect } from 'react';
import { getLogoUtility } from '@utils/logo/getLogo.utility';

export function PostSplashComponent() {
  const scale = useSharedValue(1);
  useEffect(() => {
    scale.value = withRepeat(withTiming(1.1, { duration: 2000 }), -1, true);
  }, [scale]);

  const animatedStyle = useAnimatedStyle(() => ({
    transform: [{ scale: scale.value }],
  }));

  return (
    <View style={postSplashStyles.container}>
      <Animated.Image
        source={getLogoUtility()}
        resizeMode="contain"
        style={[
          {
            width: 450,
            height: 450,
          },
          animatedStyle,
        ]}
      />
    </View>
  );
}
