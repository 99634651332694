export const linking = {
  enabled: true,
  prefixes: ['*'],
  config: {
    screens: {
      authorized: {
        screens: {
          human: {
            screens: {
              access: {
                screens: {
                  departments: {
                    path: 'departments',
                    initialRouteName: 'list',
                    screens: {
                      list: 'list',
                      departmentCreate: 'create',
                      departmentUpdate: {
                        path: 'update',
                      },
                      departmentDelete: 'delete',
                      departmentDisable: 'disable',
                    },
                  },
                  users: {
                    path: 'users',
                    initialRouteName: 'list',
                    screens: {
                      list: 'list',

                      create: 'create',
                      update: {
                        path: 'update',
                      },
                      delete: 'delete',
                      disable: 'disable',
                    },
                    //delete: 'delete',
                    // licenseCreate: 'licenseCreate',
                    // licenseDelete: 'licenseDelete',
                    // contractCreate: 'contractCreate',
                    // contractDelete: 'contractDelete',
                  },
                  roles: {
                    path: 'roles',
                    initialRouteName: 'list',
                    screens: {
                      list: 'list',
                      create: 'create',
                      update: {
                        path: 'update',
                      },
                      delete: 'delete',
                      disable: 'disable',
                    },
                  },
                  specialists: {
                    path: 'specialists',
                    initialRouteName: 'list',
                    screens: {
                      list: 'list',
                      create: 'create',
                      update: {
                        path: 'update',
                      },
                      delete: 'delete',
                      disable: 'disable',
                    },
                  },
                  entryTracks: {
                    path: 'entry-tracks',
                    initialRouteName: 'list',
                    screens: {
                      list: 'list',
                      update: {
                        path: 'update',
                      },
                    },
                  },
                  templates: {
                    path: 'templates',
                    initialRouteName: 'list',
                    screens: {
                      list: 'list',
                      create: 'create',
                      update: {
                        path: 'update',
                      },
                      delete: 'delete',
                      disable: 'disable',
                    },
                  },
                  timetables: {
                    path: 'timetables',
                    initialRouteName: 'list',
                    screens: {
                      list: 'list',
                      create: 'create',
                      update: {
                        path: 'update',
                      },
                      delete: 'delete',
                      disable: 'disable',
                    },
                  },
                  bulletins: {
                    path: 'bulletins',
                    initialRouteName: 'list',
                    screens: {
                      list: 'list',
                      create: 'create',
                      update: {
                        path: 'update',
                      },
                      delete: 'delete',
                      disable: 'disable',
                    },
                  },
                  communicationChannels: {
                    path: 'communication-channels',
                    initialRouteName: 'list',
                    screens: {
                      list: 'list',
                      create: 'create',
                      update: {
                        path: 'update',
                      },
                      delete: 'delete',
                      disable: 'disable',
                    },
                  },
                  clinics: {
                    path: 'clinics',
                    initialRouteName: 'list',
                    screens: {
                      list: 'list',
                      create: 'create',
                      update: {
                        path: 'update',
                      },
                      delete: 'delete',
                      disable: 'disable',
                    },
                  },
                  languages: {
                    path: 'languages',
                    initialRouteName: 'list',
                    screens: {
                      list: 'list',
                      create: 'create',
                      update: {
                        path: 'update',
                      },
                      delete: 'delete',
                      disable: 'disable',
                    },
                  },
                  countries: {
                    path: 'countries',
                    initialRouteName: 'list',
                    screens: {
                      list: 'list',
                      create: 'create',
                      update: {
                        path: 'update',
                      },
                      delete: 'delete',
                      disable: 'disable',
                    },
                  },
                  ovopoints: {
                    path: 'ovopoints',
                    initialRouteName: 'update',
                    screens: {
                      update: 'update',
                    },
                  },
                  settings: {
                    path: 'settings',
                  },
                },
              },
              crm: {
                path: 'crm',
                screens: {
                  dashboard: {
                    path: 'dashboard',
                    initialRouteName: 'dashboard',
                    screens: {
                      dashboard: '',
                      leadUpdate: 'leads/update',
                      actionCreate: 'actions/create',
                      actionUpdate: 'actions/update',
                    },
                  },
                  contacts: {
                    path: 'contacts',
                    initialRouteName: 'list',
                    screens: {
                      list: 'list',
                      create: 'create',
                      update: {
                        path: 'update',
                      },
                      contactDisable: 'disable',
                      contactDelete: 'delete',
                    },
                  },
                  actions: {
                    path: 'actions',
                    initialRouteName: 'list',
                    screens: {
                      list: 'list',
                      actionCreateFromCalendar: 'create',
                    },
                  },
                  tasks: {
                    path: 'tasks',
                    initialRouteName: 'list',
                    screens: {
                      list: 'list',
                      actionCreateFromCalendar: 'create',
                      actionUpdate: {
                        path: 'update',
                      },
                      actionDisable: 'disable',
                    },
                  },
                  transferTask: {
                    path: 'transfer-task',
                    initialRouteName: 'list',
                    screens: {
                      list: 'list',
                      confirmTransfer: 'confirm',
                    },
                  },
                  createTaskMassively: {
                    path: 'create-task-massively',
                    initialRouteName: 'list',
                    screens: {
                      list: 'list',
                      createTasks: 'create',
                    },
                  },
                  leads: {
                    path: 'leads',
                    initialRouteName: 'leadsList',
                    screens: {
                      leadsList: 'list',
                      create: 'create',
                      import: 'import',
                      leadUpdate: {
                        path: 'update',
                      },
                      delete: 'delete',
                      disable: 'disable',
                      actionsCreate: 'actionCreate',
                      actionsUpdate: {
                        path: 'actionUpdate',
                      },
                      actionsDelete: {
                        path: 'actionDelete',
                      },
                    },
                  },
                  campaigns: {
                    path: 'campaigns',
                    initialRouteName: 'campaignList',
                    screens: {
                      campaignList: 'list',
                      campaignCreate: 'create',
                      campaignUpdate: {
                        path: 'update',
                      },
                      campaignDelete: 'delete',
                      createManager: {
                        path: 'createManager',
                      },
                      deleteManager: {
                        path: 'deleteManager',
                      },
                      campaignDisable: 'disable',
                    },
                  },
                  channels: {
                    path: 'channels',
                    initialRouteName: 'list',
                    screens: {
                      list: 'list',
                      create: 'create',
                      update: {
                        path: 'update',
                      },
                      delete: 'delete',
                      disable: 'disable',
                    },
                  },
                  funnels: {
                    path: 'funnels',
                    initialRouteName: 'list',
                    screens: {
                      list: 'list',
                      create: 'create',
                      update: {
                        path: 'update',
                      },
                      delete: 'delete',
                      disable: 'disable',
                    },
                  },

                  activities: {
                    path: 'activities',
                    initialRouteName: 'list',
                    screens: {
                      list: 'list',
                      create: 'create',
                      update: {
                        path: 'update',
                      },
                      delete: 'delete',
                      disable: 'disable',
                    },
                  },
                  programs: {
                    path: 'programs',
                    initialRouteName: 'list',
                    screens: {
                      list: 'list',
                      create: 'create',
                      update: {
                        path: 'update',
                      },
                      delete: 'delete',
                      disable: 'disable',
                    },
                  },
                  services: {
                    path: 'services',
                    initialRouteName: 'serviceList',
                    screens: {
                      serviceList: 'list',
                      serviceCreate: 'create',
                      serviceUpdate: {
                        path: 'update',
                      },
                      serviceDelete: 'delete',
                      serviceDisable: 'disable',
                    },
                  },
                  results: {
                    path: 'results',
                    initialRouteName: 'resultList',
                    screens: {
                      resultList: 'list',
                      resultCreate: 'create',
                      resultUpdate: {
                        path: 'update',
                      },
                      resultDelete: 'delete',
                      resultDisable: 'disable',
                    },
                  },
                  categories: {
                    path: 'categories',
                    initialRouteName: 'list',
                    screens: {
                      list: 'list',
                      create: 'create',
                      update: {
                        path: 'update',
                      },
                      delete: 'delete',
                    },
                  },
                  products: {
                    path: 'products',
                    initialRouteName: 'list',
                    screens: {
                      list: 'list',
                      create: 'create',
                      update: {
                        path: 'update',
                      },
                      delete: 'delete',
                    },
                  },
                  patients: {
                    path: 'patients',
                    initialRouteName: 'patientList',
                    screens: {
                      patientList: 'list',
                      patientCreate: 'create',
                      patientUpdate: {
                        path: 'update',
                      },
                      patientDisable: 'disable',
                      patientDelete: 'delete',
                    },
                  },
                  techniques: {
                    path: 'techniques',
                    initialRouteName: 'list',
                    screens: {
                      list: 'list',
                      techniqueCreate: 'create',
                      update: {
                        path: 'update',
                      },
                      delete: 'delete',
                      disable: 'disable',
                    },
                  },
                  cycles: {
                    path: 'cycles',
                    initialRouteName: 'list',
                    screens: {
                      list: 'list',
                      create: 'create',
                      update: {
                        path: 'update',
                      },
                      disable: 'disable',
                      delete: 'delete',
                    },
                  },
                  maintenances: {
                    path: 'maintenances',
                    initialRouteName: 'listMaintenance',
                    screens: {
                      listMaintenance: 'list',
                      updateMaintenance: {
                        path: 'update',
                      },
                      disableMaintenance: 'disable',
                      deleteMaintenance: 'delete',
                    },
                  },
                  treatments: {
                    path: 'treatments',
                    initialRouteName: 'listTreatment',
                    screens: {
                      listTreatment: 'list',
                      createTreatment: 'create',
                      updateTreatment: {
                        path: 'update',
                      },
                      disableTreatment: 'disable',
                      deleteTreatment: 'delete',
                    },
                  },
                  reasons: {
                    path: 'reasons',
                    initialRouteName: 'list',
                    screens: {
                      list: 'list',
                      create: 'create',
                      update: {
                        path: 'update',
                      },
                      delete: 'delete',
                      disable: 'disable',
                    },
                  },
                  phases: {
                    path: 'phases',
                    initialRouteName: 'list',
                    screens: {
                      list: 'list',
                      create: 'create',
                      update: {
                        path: 'update',
                      },
                      delete: 'delete',
                      disable: 'disable',
                    },
                  },
                  causes: {
                    path: 'causes',
                    initialRouteName: 'causeList',
                    screens: {
                      causeList: 'list',
                      create: 'create',
                      causeUpdate: {
                        path: 'update',
                      },
                      causeDelete: 'delete',
                      causeDisable: 'disable',
                    },
                  },
                },
              },
              profile: {
                path: 'profile',
                initialRouteName: 'account',
                screens: {
                  account: 'account',
                  changePassword: 'changePassword',
                },
              },
              board: {
                path: 'board',
                initialRouteName: 'leads',
                screens: {
                  leads: 'leads',
                  summarySales: 'summary-sales',
                  saleFunnel: 'sale-funnel',
                  expectedIntervention: 'expected-intervention',
                  subordinates: 'subordinates',
                  marketing: 'marketing',
                },
              },
            },
          },
        },
      },
    },
  },
} as any;
