export const EsLocale = {
  logotype: {
    horse: 'Ovohorse',
    human: 'Ovoclinic',
    ovobank: 'Ovobank',
    ovoclinic: 'Ovoclinic',
  },
  calendar: {
    hour: 'Hora',
    minutes: ':',
    save: 'Seleccionar',
    placeholder: 'Selecciona una fecha',
  },
  historical: {
    deleted: 'Eliminado',
    created: 'Created',
    updated: 'Actualizado',
  },
  chat: {
    placeholder: 'Escribe un mensaje',
    title: 'Chat sobre el contacto',
  },
  slicer: {
    confirm: 'Seleccionar',
  },
  export: {
    title: 'Exportar',
    description: 'Exportar los datos de la tabla a un archivo CSV',
  },
  import: {
    title: 'Importar',
  },
  transfer: {
    title: 'Transferir',
  },
  createTaskMassively: {
    title: 'Crear tareas',
  },
  leadEvents: {
    informed: 'V.I comercial realizada',
    contacted: 'Contactados',
    attended: 'V.I Médica realizada',
    appointed: 'Citada V.I comercial',
    medicalAppointed: 'Citada V.I Médica',
    medicalEvaluated: 'Evaluacion médica',
    comercialAttended: 'Presupuesto entregado',
    converted: 'Convertidos',
  },
  form: {
    tabChange: {
      title: 'Estas editando un formulario. ¿Estás seguro de que deseas cambiar de pestaña?',
    },
    update: {
      successToast: 'Formulario actualizado correctamente',
    },
    checkbox: {
      errors: {
        required: 'Debes aceptar para continuar',
      },
    },
    selectable: {
      placeholder: {
        title: 'Selecciona una opción',
        empty: 'No hay resultados',
      },
    },
    date: {
      placeholder: 'Filtrar por fecha',
    },
    json: {
      errors: {
        geojson: 'GeoJSON formato no válido',
      },
    },
    text: {
      errors: {
        notString: 'El valor debe ser una cadena de texto',
        ipIsRequired: 'La dirección IP es requerida',
        ipMustBeAString: 'La dirección IP debe ser una cadena de texto',
        ipMustBeAValidIpAddress: 'La dirección IP debe ser válida',
        countryNameEn: 'El nombre es requerido',
        countryNameEs: 'El nombre es requerido',
        nameIsRequired: 'El nombre es requerido',
        nameMustBeAString: 'El nombre debe ser una cadena de texto',
        nameMustNotContainNumbers: 'El nombre no debe contener números',
        nameMustNotContainSpecialCharacters: 'El nombre no debe contener caracteres especiales',
        nameMustBeAtMost50CharactersLong: 'El nombre debe tener como máximo 50 caracteres',
        dniIsRequired: 'El DNI es requerido',
        dniMustBeAtLeast8CharactersLong: 'El DNI debe tener al menos 8 caracteres',
        dniMustBeAtMost20CharactersLong: 'El dni debe tener como máximo 20 caracteres',
        surnameIsRequired: 'El apellido es requerido',
        surnameMustBeAString: 'El apellido debe ser una cadena de texto',
        surnameMustNotContainNumbers: 'El apellido no debe contener números',
        surnameMustNotContainSpecialCharacters:
          'El apellido no debe contener caracteres especiales',
        surnameMustBeAtMost50CharactersLong: 'El apellido debe tener como máximo 50 caracteres',
        emailIsRequired: 'El correo electrónico es requerido',
        emailMustBeAString: 'El correo electrónico debe ser una cadena de texto',
        emailMustBeAValidEmail: 'El correo electrónico debe ser válido',
        emailMustBeAtLeast3CharactersLong: 'El correo electrónico debe tener al menos 3 caracteres',
        emailMustBeAtMost50CharactersLong:
          'El correo electrónico debe tener como máximo 30 caracteres',
        emailMustContainAnAtSymbol: 'El correo electrónico debe contener un símbolo @',
        emailMustNotContainWhitespace: 'El correo electrónico no debe contener espacios en blanco',
        passwordIsRequired: 'La contraseña es requerida',
        passwordMustBeAString: 'La contraseña debe ser una cadena de texto',
        passwordMustBeAtLeast8CharactersLong: 'La contraseña debe tener al menos 8 caracteres',
        passwordMustBeAtMost30CharactersLong: 'La contraseña debe tener como máximo 30 caracteres',
        passwordMustContainAnUppercaseLetter: 'La contraseña debe contener una letra mayúscula',
        passwordMustContainALowercaseLetter: 'La contraseña debe contener una letra minúscula',
        passwordMustContainANumber: 'La contraseña debe contener un número',
        passwordMustContainASpecialCharacter: 'La contraseña debe contener un carácter especial',
        passwordMustNotContainWhitespace: 'La contraseña no debe contener espacios en blanco',
        passwordsDoNotMatch: 'Las contraseñas no coinciden',
        valueMustBeAnInteger: 'El valor debe ser un número entero',
        valueMustBeAValidNumber: 'El valor debe ser un número válido',
        valueMustBePositive: 'El valor debe ser positivo',
        TEXT_TOO_SHORT: 'El texto es demasiado corto',
        WRONG_EMAIL: 'El formato del correo electrónico es incorrecto',
        REQUIRED: 'Este campo es obligatorio',
        titleMustBeAString: 'El título debe ser una cadena de texto',
        titleIsRequired: 'El título es requerido',
        titleMustBeAtLeast3CharactersLong: 'El título debe tener al menos 3 caracteres',
        titleMustBeAtMost100CharactersLong: 'El título debe tener como máximo 100 caracteres',
        codeMustBeAString: 'El código debe ser una cadena de texto',
        codeIsRequired: 'El código es requerido',
        codeMustBeAtLeast3CharactersLong: 'El código debe tener al menos 3 caracteres',
        codeMustBeAtLeast10CharactersLong: 'El código debe tener al menos 10 caracteres',
        codeMustBeAtMost30CharactersLong: 'El código debe tener como máximo 30 caracteres',
        prefixIsRequired: 'El prefijo es requerido',
        prefixInvalidLength: 'El prefijo debe tener 2 o 3 digitos',
        prefixMustContainOnlyNumbers: 'El prefijo debe contener solo números',
        prefixFirstCharacterMustBePlusSign: 'El prefijo debe comenzar con el signo +',
        prefixMustBeAtLeast1CharactersLong: 'El prefijo de tener minimo 1 dígito',
        prefixMustBeAtMost3CharactersLong: 'El prefijo de tener maximo 3 dígitos',
        countryCodeMustBeAString: 'El código de país debe ser una cadena de texto',
        countryCodeIsRequired: 'El código de país es requerido',
        countryCodeMustBe2CharactersLong: 'El código de país debe tener 2 caracteres',
        countryCodeMustBeUppercase: 'El código de país debe ser en mayúsculas',
        phoneIsRequired: 'El teléfono es requerido',
        phoneMustBeAString: 'El teléfono debe ser una cadena de texto',
        phoneMustContainOnlyNumbers: 'El teléfono debe contener solo números',
        phoneMustNotContainWhitespace: 'El teléfono no debe contener espacios en blanco',
        phoneMustBeAtLeast9CharactersLong: 'El teléfono debe tener al menos 9 digitos',
        phoneMustBeAtMost12CharactersLong: 'El teléfono debe tener como máximo 12 digitos',
        extensionIsRequired: 'La extensión es requerida',
        extensionMustContainOnlyNumbers: 'La extensión debe contener solo números',
        extensionMustBeAtLeast4CharactersLong: 'La extensión debe tener al menos 4 digitos',
        descriptionIsRequired: 'La descripción es requerida',
        descriptionMustBeAString: 'La descripción debe ser una cadena de texto',
        descriptionMustBeAtMost500CharactersLong:
          'La descripción debe tener como máximo 500 caracteres',
        observationIsRequired: 'La observación es requerida',
        observationMustBeAString: 'La observación debe ser una cadena de texto',
        observationMustBeAtLeast1CharactersLong: 'La observación debe tener al menos 1 caracter',
        observationMustBeAtMost150CharactersLong:
          'La observación debe tener como máximo 150 caracteres',
        observationMustBeAtMost500CharactersLong:
          'La observación debe tener como máximo 500 caracteres',
        usernameIsRequired: 'El nombre de usuario es requerido',
        usernameMustBeAString: 'El nombre de usuario debe ser una cadena de texto',
        usernameMustBeAtLeast3CharactersLong:
          'El nombre de usuario debe tener al menos 3 caracteres',
        usernameMustBeAtMost30CharactersLong:
          'El nombre de usuario debe tener como máximo 30 caracteres',
        usernameMustNotContainSpecialCharacters:
          'El nombre de usuario no debe contener caracteres especiales',
        usernameMustNotContainWhitespace:
          'El nombre de usuario no debe contener espacios en blanco',
        zipIsRequired: 'El código postal es requerido',
        zipMustBeAString: 'El código postal debe ser una cadena de texto',
        zipMustBe5CharactersLong: 'El código postal debe tener 5 caracteres',
        zipMustContainOnlyNumbers: 'El código postal debe contener solo números',
        zipMustNotContainWhitespace: 'El código postal no debe contener espacios en blanco',
        zipMustNotContainSpecialCharacters:
          'El código postal no debe contener caracteres especiales',
        streetIsRequired: 'La calle es requerida',
        streetMustBeAString: 'La calle debe ser una cadena de texto',
        streetMustBeAtLeast8CharactersLong: 'La calle debe tener al menos 8 caracteres',
        cityIsRequired: 'La ciudad es requerida',
        cityMustBeAString: 'La ciudad debe ser una cadena de texto',
        stateIsRequired: 'El estado es requerido',
        stateMustBeAString: 'El estado debe ser una cadena de texto',
        coinIsRequired: 'La moneda es requerida',
        coinMustBeAString: 'La moneda debe ser una cadena de texto',
        flagIsRequired: 'La bandera es requerida',
        flagMustBeAString: 'La bandera debe ser una cadena de texto',
        subjectIsRequired: 'El asunto es requerido',
        subjectMustBeAString: 'El asunto debe ser una cadena de texto',
        subjectMustBeAtLeast3CharactersLong: 'El asunto debe tener al menos 3 caracteres',
        subjectMustBeAtMost30CharactersLong: 'El asunto debe tener como máximo 30 caracteres',
        messageIsRequired: 'El mensaje es requerido',
        messageMustBeAString: 'El mensaje debe ser una cadena de texto',
        messageMustBeAtLeast3CharactersLong: 'El mensaje debe tener al menos 3 caracteres',
        messageMustBeAtMost500CharactersLong: 'El mensaje debe tener como máximo 500 caracteres',
        hourIsRequired: 'La hora es requerida',
        hourMustBeValidFormat: 'La hora debe tener un formato válido: HH:MM',
        documentIsRequired: 'El documento es requerido',
        documentMustBeAString: 'El documento debe ser una cadena de texto',
        documentMustBeAtLeast8CharactersLong: 'El documento debe tener al menos 8 caracteres',
        codeMustBeAtLeast2CharactersLong: 'El código debe tener al menos 2 caracteres',
        required: 'Este campo es obligatorio',
        name: {
          notString: 'El nombre debe ser una cadena de texto',
          tooShort: 'El nombre es demasiado corto',
          tooLong: 'El nombre es demasiado largo',
          invalidCharacters: 'El nombre contiene caracteres no válidos',
        },
        email: {
          notValid: 'El correo electrónico no es válido',
          notString: 'El correo electrónico debe ser una cadena de texto',
        },
        phone: {
          invalid: 'El teléfono no es válido',
          notString: 'El teléfono debe ser una cadena de texto',
        },
        surname: {
          notString: 'El apellido debe ser una cadena de texto',
          tooShort: 'El apellido es demasiado corto',
          tooLong: 'El apellido es demasiado largo',
          invalidCharacters: 'El apellido contiene caracteres no válidos',
        },
        boolean: {
          notBoolean: 'El valor debe ser un booleano',
          truthy: {
            notTruthy: 'El valor debe ser verdadero',
          },
        },
        password: {
          notString: 'La contraseña debe ser una cadena de texto',
          tooShort: 'La contraseña es demasiado corta',
          tooLong: 'La contraseña es demasiado larga',
          invalidCharacters: 'La contraseña contiene caracteres no válidos',
          noUpperCase: 'La contraseña debe contener al menos una letra mayúscula',
          noLowerCase: 'La contraseña debe contener al menos una letra minúscula',
          noNumber: 'La contraseña debe contener al menos un número',
          noSpecialCharacter: 'La contraseña debe contener al menos un carácter especial',
        },
        number: {
          notNumber: 'El valor debe ser un número',
        },
        positive: {
          notPositive: 'El valor debe ser positivo',
          notNumber: 'El valor debe ser un número',
        },
        price: {
          notNumber: 'El valor debe ser un número',
          notPositive: 'El valor debe ser positivo',
          notString: 'El valor debe ser una cadena de texto',
        },
        negative: {
          notNegative: 'El valor debe ser negativo',
          notNumber: 'El valor debe ser un número',
        },
        uuid: {
          notUuid: 'El valor debe ser un UUID',
          notString: 'El valor debe ser una cadena de texto',
          empty: 'El valor no puede estar vacío',
        },
        object: {
          notValid: 'El valor debe ser un objeto',
        },
        description: {
          notString: 'La descripción debe ser una cadena de texto',
          tooShort: 'La descripción es demasiado corta',
          tooLong: 'La descripción es demasiado larga',
        },
        observation: {
          notString: 'La observación debe ser una cadena de texto',
          tooShort: 'La observación es demasiado corta',
          tooLong: 'La observación es demasiado larga',
        },
        date: {
          notValid: 'La fecha no es válida',
          notString: 'La fecha debe ser una cadena de texto',
        },
        color: {
          notString: 'El color debe ser una cadena de texto',
          notHex: 'El color debe ser un código hexadecimal',
        },
        code: {
          notString: 'El código debe ser una cadena de texto',
          tooShort: 'El código es demasiado corto',
          tooLong: 'El código es demasiado largo',
        },
        image: {
          notString: 'La imagen debe ser una cadena de texto',
          notBase64: 'La imagen debe ser un código base64',
        },
        phonePrefix: {
          notString: 'El prefijo debe ser una cadena de texto',
          notValid: 'El prefijo no es válido',
        },
        countryCode: {
          notString: 'El código de país debe ser una cadena de texto',
          notValid: 'El código de país no es válido',
        },
        zip: {
          notString: 'El código postal debe ser una cadena de texto',
          notLength: 'El código postal debe tener 5 caracteres',
          notNumber: 'El código postal debe contener solo números',
          hasSpace: 'El código postal no debe contener espacios en blanco',
          hasLetter: 'El código postal no debe contener letras',
          hasSymbol: 'El código postal no debe contener símbolos',
        },
        title: {
          notString: 'El título debe ser una cadena de texto',
          tooShort: 'El título es demasiado corto',
          tooLong: 'El título es demasiado largo',
        },
        username: {
          notString: 'El nombre de usuario debe ser una cadena de texto',
          tooShort: 'El nombre de usuario es demasiado corto',
          tooLong: 'El nombre de usuario es demasiado largo',
          invalidCharacters: 'El nombre de usuario contiene caracteres no válidos',
        },
        ip: {
          notString: 'La dirección IP debe ser una cadena de texto',
          notValid: 'La dirección IP no es válida',
        },
        state: {
          notString: 'El estado debe ser una cadena de texto',
        },
        city: {
          notString: 'La ciudad debe ser una cadena de texto',
        },
        street: {
          notString: 'La calle debe ser una cadena de texto',
        },
        coin: {
          notString: 'La moneda debe ser una cadena de texto',
        },
        flag: {
          notString: 'La bandera debe ser una cadena de texto',
        },
      },
    },
  },
  analytics: {
    title: 'Analíticas',
    filter: 'Comparativa entre la semana pasada y esta semana',
    subtitle:
      'Esta comparativa muestra el número de nuevos leads, tareas realizadas y resultados conseguidos esta semana en comparación con la semana anterios',
    activities: {
      title: 'Tareas',
    },
    leads: {
      title: 'Leads',
    },
    results: {
      title: 'Resultados',
    },
  },
  history: {
    title: 'Historial',
  },
  performance: {
    title: 'Rendimiento',
    subtitle:
      'El rendimiento se calcula sumando los puntos obtenidos por los hitos alcanzados, como contactar un lead, cerrar visitas o realizar una venta. Cuantos más hitos se logran, más puntos se acumulan',
  },
  sessions: {
    title: 'Sesiones Activas',
    subtitle: 'Registro de las veces que se inicia una sesión',
  },
  security: {
    title: 'Sistema Securizado',
  },
  launch: {
    title: 'El futuro de la reproducción asistida',
    subtitle: 'Trazabilidad y seguridad para tu clínica',
    description: {
      ovoclinic:
        'Software securizado y propiedad de Ovoclinic para la gestión de clínicas de reproducción asistida. OVO DESK es una plataforma de acceso restringido y todo acceso fraudulento será notificado a las autorizades de delitos informáticos de la Unión Europea.',
      ovobank:
        'Software securizado y propiedad de Ovobank para la gestión de bancos de óvulos. OVO DESK es una plataforma de acceso restringido y todo acceso fraudulento será notificado a las autorizades de delitos informáticos de la Unión Europea.',
    },
    crm: {
      description:
        'Espacio para la gestión de clientes y la mejora de la relación con ellos. Trazabilidad y seguimiento de las interacciones con los leads.',
    },
    clinic: {
      description:
        'Todo lo que necesitas para gestionar tu clínica, hospital o centro médico. Historias clínicas, turnos, facturación y mucho más.',
    },
    access: {
      description:
        'Cada rol determina las acciones que un usuario puede realizar y la información a la que puede acceder.',
    },
    board: {
      description:
        'Tablero de control y monitoreo de indicadores. Visualización de datos y análisis de la información para la toma de decisiones.',
    },
    tory: {
      description:
        'Resultados clínicos y trazabilidad de las pruebas de laboratorio. Anonimización de datos y tokenización de la información.',
    },
    profile: {
      description:
        'Gestión de la información personal y de la cuenta. Configuración de la cuenta y preferencias de usuario.',
    },
  },
  access: {
    menu: {
      users: 'Usuarios',
      roles: 'Roles',
      specialists: 'Especialistas',
      clinics: 'Clínicas',
      planning: 'Planificación',
      entryTrackDepartment: 'Registro de entrada de leads',
      entryTracks: 'Registros',
      timetables: 'Horarios',
      bulletins: 'Noticias',
      departments: 'Departamentos',
      languages: 'Idiomas',
      countries: 'Países',
      settings: 'Configuración',
      directory: 'Directorio',
      locations: 'Ubicaciones',
      globalization: 'Ubicación',
      communications: 'Comunicaciones',
      communicationChannels: 'Vias de comunicación',
      ovopoints: 'Ovopoints',
      landbot: 'Landbot',
      templates: 'Plantillas',
    },
    entrytracks: {
      list: {
        title: 'Registros de entrada de leads',
        description:
          'Cada registro es un intento de entrada de un Lead mediante el formulario del Landing o una campaña de Facebook.',
        one: 'Registro',
        many: 'Registros',
        failed: 'Fallidos',
        gravityForms: 'Gravity Forms',
        facebook: 'Facebook',
        columns: {
          name: 'Nombre',
          surname: 'Apellidos',
          email: 'Correo electrónico',
          phone: 'Teléfono',
          type: 'Origen de entrada',
          contactTime: 'Preferencia',
          error: 'Error',
          created: 'Creación',
          reentried: 'Reentrada realizada',
        },
      },
      update: {
        createContact: {
          title: 'Crear contacto mediante reentrada',
          subtitle:
            'El contacto no existe en la base de datos. Por favor, rellena los campos necesarios para crear un nuevo contacto.',
        },
        createLead: {
          title: 'Crear lead mediante reentrada',
          subtitle:
            'El contacto ya existe en la base de datos. Por favor, rellena los campos necesarios para crear un nuevo lead asociado al contacto existente.',
        },
      },
    },
    users: {
      list: {
        chart: {
          user: 'Usuarios',
          movement: 'Movimientos',
        },
        params: {
          roleId: 'Usuarios filtrados por el rol',
        },
        month: {
          0: 'Enero',
          1: 'Febrero',
          2: 'Marzo',
          3: 'Abril',
          4: 'Mayo',
          5: 'Junio',
          6: 'Julio',
          7: 'Agosto',
          8: 'Septiembre',
          9: 'Octubre',
          10: 'Noviembre',
          11: 'Diciembre',
          12: 'Total',
        },
        year: {
          0: '2020',
          1: '2021',
          2: '2022',
          3: '2023',
          4: '2024',
          5: '2025',
          6: '2026',
          7: '2027',
          8: '2028',
          9: '2029',
          10: '2030',
          11: '2031',
        },
        columns: {
          name: 'Nombre',
          email: 'Correo electrónico',
          phone: 'Teléfono',
          status: 'Estado',
          createdAt: 'Creado',
          updatedAt: 'Actualizado',
          expiration: 'Caducidad',
          created: 'Creación',
          lastMove: 'Último movimiento',
        },
        title: 'Usuarios',
        description:
          'Personas registradas en el sistema. Desde esta sección, se pueden configurar sus datos y gestionar sus roles, permisos y otras caracteristicas con el fin de adaptar el acceso a sus funciones.',
        create: 'Usuario',
        one: 'Usuario',
        many: 'Usuarios',
        cloneConfirm: '¿Estás seguro de que deseas clonar este usuario?',
        cloneSuccess: 'Usuario clonado correctamente',
      },
      create: {
        title: 'Nuevo usuario',
        subtitle:
          'Permite registrar un usuario completando los datos básicos necesarios. Una vez creado, se podrá ajustar y configurar su acceso, funciones y especificaciones según el rol que desempeñe en el sistema.',
        form: {
          name: {
            title: 'Nombre',
            description:
              'Este campo identifica al usuario en el CRM y su nombre se mostrará en áreas donde tenga roles o responsabilidades, como perfiles, tareas y asignaciones.',
          },
          surname: {
            title: 'Apellidos',
            description:
              'Se mostrarán junto al nombre en secciones como responsable de departamentos, campañas de marketing, gestión de leads y en el dashboard con los datos de rendimiento.',
          },
          password: {
            title: 'Contraseña',
            description:
              'La contraseña debe tener al menos 8 caracteres, una letra mayúscula, una letra minúscula y un número.',
          },
          username: {
            title: 'Username',
            description: 'Nombre de usuario para el acceso al sistema',
          },
          email: {
            title: 'Correo electrónico',
            description:
              'Dirección de correo electrónico donde el usuario recibirá las notificaciones del sistema',
          },
          phone: {
            title: 'Teléfono',
            description: 'Número de contacto del usuario',
          },
          extension: {
            title: 'Extensión',
            description: 'Extensión identificativa dentro del departamento',
          },
          signature: {
            title: 'Contraseña',
            description: 'Contraseña del usuario',
          },
          expiration: {
            title: 'Fecha de expiración',
            description: 'Fecha en la que la cuenta del usuario expirará',
          },
          color: {
            title: 'Color',
            description: 'Color del usuario en el sistema',
          },
          icon: {
            title: 'Icono',
            label: 'Seleccionar icono',
            description: 'Icono identificativo del usuario en el sistema',
            search: {
              placeholder: 'Buscar icono',
            },
          },
          status: {
            title: 'Acceso al sistema',
            description: 'Habilitar o deshabilitar el acceso al sistema para este usuario',
            label: 'Permitir acceso al sistema',
          },
          movementLimit: {
            title: 'Límite de movimientos',
            description:
              'Número máximo de interacciones permitidas con el sistema en 24 horas. Al alcanzar el límite, se bloquearán temporalmente nuevas acciones.',
          },
          attemptLimit: {
            title: 'Límite de intentos',
            description:
              'Número máximo de intentos fallidos permitidos para iniciar sesión en un período de 24 horas. Una vez alcanzado el límite, se bloqueará temporalmente el acceso para proteger la seguridad de la cuenta.',
          },
          submit: 'Crear usuario',
        },
      },
      delete: {
        title: 'Deshabilitar usuario',
        subtitle:
          'El elemento no estará disponible en el CRM y solo las personas con un permiso específico podrán visualizar los elementos inactivos.',
        form: {
          status: {
            title: '¿Estás seguro de que deseas deshabilitar este usuario?',
            description:
              'Deshabilitar un usuario significa que no podrá acceder al sistema. Estará visible como deshabilitado en el tablón de usuarios.',
            label: 'Sí, deseo descativar este usuario',
          },
          submit: 'Deshabilitar',
        },
        passwordForm: {
          title: 'Introduce tu contraseña',
          subtitle: 'Para deshabilitar el usuario, introduce tu contraseña',
          password: {
            title: 'Contraseña',
            description: 'Contraseña del usuario que realiza la acción',
          },
          submit: 'Confirmar',
        },
      },
      enable: {
        title: 'Habilitar usuario',
        subtitle: 'Esta acción permite que el usuario acceda al sistema',
        form: {
          status: {
            title: '¿Estás seguro de que deseas habilitar este usuario?',
            description:
              'Habilitar un usuario significa que podrá acceder al sistema. Estará visible como activo en el tablón de usuarios.',
            label: 'Sí, deseo habilitar este usuario',
          },
          submit: 'Habilitar',
        },
      },
      disable: {
        title: 'Deshabilitar usuario',
        subtitle:
          'El elemento no estará disponible en el CRM y solo las personas con un permiso específico podrán visualizar los elementos inactivos.',
        form: {
          status: {
            title: '¿Estás seguro de que deseas deshabilitarlo?',
            description:
              'Deshabilitar un usuario significa que no podrá acceder al sistema. Estará visible como deshabilitado en el tablón de usuarios.',
            label: 'Sí, deseo deshabilitar este usuario',
          },
          submit: 'Deshabilitar',
        },
      },
      update: {
        access: 'Acceso',
        account: {
          tab: 'Cuenta',
          description: 'Información de la cuenta del usuario',
        },
        tabs: {
          account: {
            title: 'Cuenta',
            description: 'Detalles personales de la cuenta del usuario con acceso al sistema.',
          },
          licenses: {
            title: 'Licencias',
            description:
              'Definen la relación entre el usuario y su rol dentro del sistema. Cada licencia asigna un rol específico, lo que determina los permisos y accesos del usuario.',
          },
          contracts: {
            title: 'Contratos',
            description:
              'Es un acuerdo formal entre el usuario y la clínica que le otorga acceso a la información de una o varias clínicas dentro del sistema. Define los términos de la relación y los permisos para gestionar y visualizar los datos correspondientes.',
          },
          movements: {
            title: 'Tráfico',
            description:
              ' Se refiere al registro y monitoreo de las acciones o peticiones realizadas por los usuarios dentro del sistema. Cada movimiento o solicitud de un usuario se contabiliza como una entrada de tráfico, permitiendo hacer un seguimiento de su actividad y el uso del sistema.',
          },
          attempts: {
            title: 'Intentos',
            description:
              'Los intentos son el registro de los intentos de acceso al sistema realizados por el usuarios.',
          },
          languages: {
            title: 'Idiomas',
            description:
              'Son los idiomas que el usuario habla y puede seleccionar en el sistema. Estos idiomas permiten personalizar la experiencia, adaptando la interfaz a las preferencias lingüísticas del usuario.',
          },
          timetables: {
            title: 'Horarios',
            description:
              'Define la jornada laboral del trabajador, estableciendo los días y las horas en las que está disponible. Este horario afecta a la asignación automática de tareas, ya que solo se asignarán dentro de las franjas horarias establecidas para ese trabajador.',
          },
          changePassword: {
            title: 'Contraseña',
            description:
              'Es la clave personal que permite al usuario acceder al sistema de forma segura. Debe cumplir con las condiciones de tener mínimo 8 caracteres, una mayúscula, un número y un carácter especial.',
          },
          activity: {
            title: 'Actividad',
            description:
              'Si se define una actividad, cuando el responsable del Lead es asignado a este usuario, se creará una tarea automáticamente con la actividad que se haya definido.',
          },
          integrations: {
            title: 'Integraciones',
            description:
              'Al activar esta opción, el sistema detecta que el usuario está vinculado a una integración, como LandBot. Esto provoca que se creen tareas adicionales para un usuario humano, que se encargará de supervisar y verificar los resultados de las tareas asignadas al usuario integrado',
          },
        },
        schedules: {
          create: {
            title: 'Añadir horario',
            subtitle:
              'Asigna un horario específico a un usuario, éste determinará su jornada laboral.',
            form: {
              timetableId: {
                title: 'Selecciona un horario',
                description: 'Elige un horario previamente definido para asignarselo al usuario',
              },
              submit: 'Añadir horario',
            },
          },
          delete: {
            title: 'Desvincular horario',
            subtitle: 'Desvincular horario del usuario',
            form: {
              status: {
                title: '¿Estás seguro de que deseas borrarlo?',
                label: 'Sí, deseo desvincular este horario al usuario',
              },
              submit: 'Desvincular horario',
            },
          },
        },
        languages: {
          add: 'Añadir idioma',
          create: {
            title: 'Añadir idioma',
            subtitle: 'Agregar idioma hablado por el usuario',
            form: {
              languageId: {
                title: 'Idioma',
                description: 'Idioma que habla el usuario',
              },
              submit: 'Añadir',
            },
          },
          delete: {
            title: 'Eliminar idioma',
            subtitle:
              'Al eliminar este elemento se borrará del sistema. Esta acción es irreversible.',
            form: {
              status: {
                title: '¿Estás seguro de continuar?',
                description: 'El idioma se eliminará permanentemente.',
                label: 'Sí, deseo eliminar este idioma',
              },
              submit: 'Confirmar',
            },
          },
        },
        roles: 'Roles',
        title: 'Actualizar usuario',
        subtitle:
          'Modificación y acceso a los permisos y funciones de un usuario, así como a la información relativa a su cuenta.',
        licenses: {
          tab: 'Licencias',
          description:
            'Definen la relación entre el usuario y su rol dentro del sistema. Cada licencia asigna un rol específico, lo que determina los permisos y accesos del usuario.',
          add: 'Añadir',
          create: {
            title: 'Añadir licencia',
            subtitle:
              'Asigna un nuevo rol o licencia a un usuario. Al añadir una, se determina qué áreas y funciones del sistema estarán disponibles para el usuario.',
            form: {
              roleId: {
                title: 'Rol',
                description:
                  'Selecciona el rol adecuado para el usuario, determinando así sus permisos y accesos dentro del sistema según sus responsabilidades',
              },
              submit: 'Crear licencia',
            },
          },
          delete: {
            title: 'Eliminar licencia',
            subtitle:
              'Al eliminar este elemento se borrará del sistema. Esta acción es irreversible.',
            form: {
              status: {
                title: '¿Estás seguro de continuar?',
                description:
                  'La licencia se eliminará permanentemente del sistema, tal vez prefieras desactivarlo. ¿Está seguro de querer eliminarlo?',
                label: 'Sí, deseo eliminar esta licencia',
              },
              submit: 'Eliminar licencia',
            },
          },
        },
        permissions: {
          add: 'Añadir licencia',
        },
        integrations: {
          form: {
            isLandbot: {
              title: 'Activar usuario Landbot',
              description:
                'Al activar esta opción, se vincula al usuario con la integración de LandBot. ',
              label: 'Confirmar integración con LandBot',
            },
            submit: 'Actualizar integraciones',
          },
        },
        contracts: {
          tab: 'Contratos',
          description:
            'Es un acuerdo formal entre el usuario y la clínica que le otorga acceso a la información de una o varias clínicas dentro del sistema. Define los términos de la relación y los permisos para gestionar y visualizar los datos correspondientes.',
          add: 'Añadir contrato',
          addAll: 'Añadir todos los contratos',
          create: {
            title: 'Añadir contrato',
            subtitle:
              'Crea un nuevo acuerdo entre el usuario y la clínica otorgándole acceso a su información.',
            form: {
              clinicId: {
                title: 'Clínica',
                description: 'Selecciona la clínica con la que quieras crear el contrato',
              },
              submit: 'Añadir contrato',
            },
          },
          disable: {
            title: 'Deshabilitar contrato',
            subtitle:
              'El elemento no estará disponible en el CRM y solo las personas con un permiso específico podrán visualizar los elementos inactivos.',
            form: {
              status: {
                title: '¿Estás seguro de que deseas deshabilitarlo?',
              },
              submit: 'Deshabilitar contrato',
            },
          },
          enable: {
            title: 'Habilitar contrato',
            subtitle: 'Esta acción reactiva el contrato formal entre la clínica y el usuario.',
            form: {
              status: {
                title: '¿Estás seguro de que deseas habilitarlo?',
              },
              submit: 'Habilitar contrato',
            },
          },
        },
        timetables: {
          add: 'Añadir horario',
          create: {
            title: 'Nuevo horario',
            subtitle: 'Registrar nuevo horario para el usuario',
            form: {
              day: {
                title: 'Día',
                description: 'Día de la semana en el que el usuario tiene acceso al sistema',
              },
              started: {
                title: 'Inicio',
                description: 'Hora de inicio en la que el usuario tiene acceso al sistema',
              },
              ended: {
                title: 'Fin',
                description: 'Hora de fin en la que el usuario tiene acceso al sistema',
              },
              submit: 'Crear horario',
            },
          },
          delete: {
            title: 'Eliminar horario',
            subtitle:
              'Al eliminar este elemento se borrará del sistema. Esta acción es irreversible.',
            form: {
              status: {
                title: '¿Estás seguro de que deseas eliminarlo?',
                label: 'Sí, deseo eliminar este horario',
              },
              submit: 'Eliminar horario',
            },
          },
        },
        movements: {
          tab: 'Movimientos',
          description:
            'Los movimientos son las peticiones que realiza el usuario al sistema. Por ejemplo, cuando el usuario accede a la pantalla de usuarios, se registra un movimiento.',
        },
        attempts: {
          tab: 'Intentos',
          description:
            'Los intentos son los accesos al inicio de sesión de los usuarios. Tanto los intentos satisfactorios como los incorrectos.',
        },
        form: {
          name: {
            title: 'Nombre',
            description:
              'Este campo identifica al usuario en el CRM y su nombre se mostrará en áreas donde tenga roles o responsabilidades, como perfiles, tareas y asignaciones.',
          },
          surname: {
            title: 'Apellidos',
            description:
              'Se mostrarán junto al nombre en secciones como responsable de departamentos, campañas de marketing, gestión de leads y en el dashboard con los datos de rendimiento.',
          },
          password: {
            title: 'Contraseña',
            description:
              'La contraseña debe tener al menos 8 caracteres, una letra mayúscula, una letra minúscula y un número.',
          },
          username: {
            title: 'Username',
            description: 'Nombre de usuario para la identificación en el sistema',
          },
          email: {
            title: 'Correo electrónico',
            description: 'Email del usuario para poder iniciar sesión en el sistema',
          },
          phone: {
            title: 'Teléfono',
            description: 'Número de contacto del usuario',
          },
          extension: {
            title: 'Extensión',
            description: 'Extensión identificativa dentro del departamento',
          },
          signature: {
            title: 'Contraseña',
            description: 'Contraseña del usuario',
          },
          expiration: {
            title: 'Fecha de expiración',
            description: 'Fecha en la que la cuenta del usuario expirará',
          },
          color: {
            title: 'Color',
            description: 'Color del usuario en el sistema',
          },
          status: {
            title: 'Acceso al sistema',
            description: 'Habilitar o deshabilitar el acceso al sistema para este usuario',
            label: 'Permitir acceso al sistema',
          },
          movementLimit: {
            title: 'Límite de movimientos',
            description:
              'Número máximo de interacciones permitidas con el sistema en 24 horas. Al alcanzar el límite, se bloquearán temporalmente nuevas acciones.',
          },
          attemptLimit: {
            title: 'Límite de intentos',
            description:
              'Número máximo de intentos fallidos permitidos para iniciar sesión en un período de 24 horas. Una vez alcanzado el límite, se bloqueará temporalmente el acceso para proteger la seguridad de la cuenta.',
          },
          submit: 'Actualizar usuario',
        },
        activity: {
          form: {
            activityId: {
              title: 'Actividad',
            },
            submit: 'Actualizar actividad',
          },
        },
      },
    },
    ovopoints: {
      update: {
        title: 'Asignación de ovopoints',
        subtitle:
          'Esta sección asigna "ovopoints" a cada hito alcanzado por el usuario. A medida que el usuario cumpla hitos, los puntos se sumarán y se reflejarán en la tabla de rendimiento de su perfil.',
        form: {
          contactedPoints: {
            title: 'Contacto establecido con el lead',
            description: 'Puntos asignados al establecer contacto con el lead.',
          },
          appointedPoints: {
            title: 'Lead citado para visita informativa comercial',
            description: 'Puntos asignados al agendar una cita informativa comercial con el lead.',
          },
          informedPoints: {
            title: 'Visita comercial informativa realizada',
            description:
              'Puntos asignados por realizar una visita comercial informativa con el lead.',
          },
          medicalAppointedPoints: {
            title: 'Lead citado para visita informativa médica',
            description: 'Puntos asignados al agendar una cita informativa médica con el lead.',
          },
          attendedPoints: {
            title: 'Lead asiste a la cita informativa médica',
            description: 'Ovopoints otorgados cuando el lead asiste a la cita médica programada.',
          },
          medicalEvaluatedPoints: {
            title: 'Lead evaluado por el especialista',
            description: 'Ovopoints otorgados por evaluar al lead.',
          },
          commercialAttendedPoints: {
            title: 'Presupuesto entregado al lead',
            description: 'Ovopoints otorgados por entregar un presupuesto al lead.',
          },
          convertedPoints: {
            title: {
              ovoclinic: 'Lead convertido en paciente',
              ovobank: 'Lead convertido en donante',
            },
            description: {
              ovoclinic: 'Ovopoints otorgados por convertir un lead en paciente.',
              ovobank: 'Ovopoints otorgados por convertir un lead en donante.',
            },
          },
          submit: 'Actualizar ovopoints',
        },
      },
    },
    communicationChannels: {
      list: {
        create: 'Vía de comunicación',
        title: 'Vías de comunicación',
        description:
          'Las vías de comunicación definen los canales disponibles para realizar actividades, como correo electrónico, teléfono o mensajes. Aquí puedes crear y configurar las vías utilizadas en la organización.',
        one: 'Vía de comunicación',
        many: 'Vías de comunicación',
        columns: {
          name: 'Nombre',
          created: 'Fecha de creación',
        },
      },
      create: {
        title: 'Nueva vía de comunicación',
        subtitle: 'Registro de una nueva vía de comunicación en el sistema',
        form: {
          name: {
            title: 'Nombre de la vía de comunicación',
            description:
              'Este campo identifica la vía en el sistema y se utilizará para seleccionarla cuando sea necesario',
          },
          submit: 'Crear vía de comunicación',
        },
      },
      update: {
        title: 'Actualizar vía de comunicación',
        subtitle: 'Modificación de los datos de una vía de comunicación en el sistema',
        form: {
          name: {
            title: 'Nombre de la vía de comunicación',
            description:
              'Este campo identifica la vía en el sistema y se utilizará para seleccionarla cuando sea necesario',
          },
          submit: 'Actualizar vía de comunicación',
        },
      },
      enable: {
        title: 'Habilitar vía de comunicación',
        subtitle: 'Habilitar vía de comunicación en el sistema',
        form: {
          status: {
            title: '¿Estás seguro de que deseas habilitar esta vía de comunicación?',
            label: 'Sí, deseo habilitar esta vía de comunicación',
          },
          submit: 'Habilitar vía de comunicación',
        },
      },
      disable: {
        title: 'Deshabilitar vía de comunicación',
        subtitle:
          'El elemento no estará disponible en el CRM y solo las personas con un permiso específico podrán visualizar los elementos inactivos.',
        form: {
          status: {
            title: '¿Estás seguro de que deseas deshabilitar esta vía de comunicación?',
            label: 'Sí, deseo deshabilitar esta vía de comunicación',
          },
          submit: 'Deshabilitar vía de comunicación',
        },
      },
    },
    specialists: {
      list: {
        create: 'Especialista',
        title: 'Especialista',
        description:
          'El especialista es un profesional que opera como ginecólogo, embriólogo o miembro de atención al paciente. En esta sección se pueden crear y gestionar los perfiles de los especialistas disponibles.',
        one: 'Especialista',
        many: 'Especialistas',
        columns: {
          name: 'Nombre',
          surname: 'Apellidos',
          dni: 'DNI',
          type: 'Tipo',
        },
      },
      create: {
        title: 'Nuevo especialista',
        subtitle: 'Registra un nuevo perfil para que esté disponible como especialista',
        form: {
          name: {
            title: 'Nombre del especialista',
            description:
              'Se utilizará para identificar y hacer referencia al profesional que llevará el caso del lead.',
          },
          surname: {
            title: 'Apellidos',
            description:
              'Se utilizarán junto con el nombre para identificar y referenciar al profesional en el sistema.',
          },
          dni: {
            title: 'DNI',
            description:
              'Este campo se utiliza para ingresar el número de identificación del especialista. Es un identificador único que se almacenará para la integración con Vrepro.',
          },
          type: {
            title: 'Tipo',
            description:
              'Categoría que identifica la especialización del profesional, como Atención al Paciente (ATP), Ginecólogo o Embriólogo.',
          },
          submit: 'Crear especialista',
        },
      },
      update: {
        tabs: {
          specialist: {
            title: 'Especialista',
            description: 'Detalles personales del especialista',
          },
          contracts: {
            title: 'Contratos',
            description:
              'Relación formal entre el especialista y la clínica. Un especialista solo estará disponible como opción para asignar a pacientes si tienen un contrato activo con la misma clínica.',
          },
        },
        contracts: {
          add: 'Añadir contrato',
          list: {
            one: 'Contrato',
            many: 'Contratos',
          },
          create: {
            title: 'Añadir contrato',
            subtitle: 'Crea un nuevo contrato entre el especialista y la clínica.',
            form: {
              clinicId: {
                title: 'Clínica',
                description: 'Selecciona la clínica con la que quieras crear el contrato',
              },
              submit: 'Crear contrato',
            },
          },
          delete: {
            title: 'Eliminar contrato',
            subtitle:
              'Al eliminar este elemento se borrará del sistema. Esta acción es irreversible.',
            form: {
              submit: 'Eliminar contrato',
            },
          },
        },
        title: 'Actualizar especialista',
        subtitle:
          'Modifica la información o los detalles de un especialista ya registrado en el sistema, como su tipo o datos de contacto.',
        form: {
          name: {
            title: 'Nombre',
            description: 'Nombre del especialista',
          },
          surname: {
            title: 'Apellidos',
            description: 'Apellidos del especialista',
          },
          dni: {
            title: 'DNI',
            description: 'Documento de identidad del especialista',
          },
          type: {
            title: 'Tipo',
            description:
              'Categoría que identifica la especialización del profesional, como Atención al Paciente (ATP), Ginecólogo o Embriólogo.',
          },
          submit: 'Actualizar especialista',
        },
      },
      enable: {
        title: 'Habilitar especialista',
        subtitle: 'Esta acción habilita el especialista en el sistema',
        form: {
          submit: 'Habilitar especialista',
        },
      },
      disable: {
        title: 'Deshabilitar especialista',
        subtitle:
          ' l elemento no estará disponible en el CRM y solo las personas con un permiso específico podrán visualizar los elementos inactivos.',
        form: {
          submit: 'Deshabilitar especialista',
        },
      },
    },
    templates: {
      list: {
        title: 'Plantillas',
        description:
          'Gestión de las plantillas para Landbot. Landbot es la herramienta de creación de chatbots que se utiliza actualmente para diseñar los mensajes de las plantillas.',
        create: 'Plantilla',
        columns: {
          label: 'Nombre',
        },
        one: 'Plantilla',
        many: 'Plantillas',
      },
      create: {
        title: 'Nueva plantilla',
        subtitle:
          'En esta sección se crean los registros de las plantillas de LandBot, las cuales luego pueden seleccionarse y asignarse a las actividades',
        form: {
          label: {
            title: 'Nombre de la plantilla',
            description:
              'Es el nombre identificativo que se mostrará al seleccionar una plantilla.',
          },
          landbotId: {
            title: 'ID de la plantilla de Landbot',
            description:
              'Ingresa el ID de la plantilla de Landbot que se asociará a la plantilla de CRM. Landbot es la herramienta de creación de chatbots que se utiliza actualmente para diseñar los mensajes de las plantillas.',
          },
          submit: 'Crear plantilla',
        },
      },
      update: {
        title: 'Actualizar plantilla',
        subtitle: 'Modificación de los datos de una plantilla para Landbot',
        tabs: {
          template: {
            title: 'Plantilla',
            description: 'Nombre de la plantilla y su identificador para identificarla en Landbot',
          },
          templateScope: {
            title: 'Ámbito',
            description:
              'Define la relación entre un departamento y una plantilla. Solo los usuarios con un rol asociado a ese departamento podrán acceder a la plantilla.',
          },
          templateParam: {
            title: 'Parámetros',
            description:
              'Son las variables configuradas en LandBot que se pueden seleccionar e incluir en la plantilla, como nombre, responsable, fecha, entre otras. Estos parámetros permiten personalizar los mensajes y hacer que la plantilla sea dinámica',
          },
        },
        form: {
          label: {
            title: 'Nombre de la plantilla',
            description:
              'Es el nombre identificativo que se mostrará al seleccionar una plantilla en las tareas.',
          },
          landbotId: {
            title: 'Landbot ID',
            description:
              'Ingresa el ID de la plantilla de Landbot que se asociará a la plantilla de CRM. Landbot es la herramienta de creación de chatbots que se utiliza actualmente para diseñar los mensajes de las plantillas.',
          },
          id: {
            title: 'Plantilla',
            description: 'Plantilla a usar en Landbot para mandar el mensaje',
          },
          submit: 'Actualizar plantilla',
        },
      },
      disable: {
        title: 'Deshabilitar plantilla',
        subtitle:
          'El elemento no estará disponible en el CRM y solo las personas con un permiso específico podrán visualizar los elementos inactivos.',
        form: {
          submit: 'Deshabilitar plantilla',
        },
      },
      enable: {
        title: 'Habilitar plantilla',
        subtitle: 'Habilitar plantilla para Landbot',
        form: {
          submit: 'Habilitar plantilla',
        },
      },
      delete: {
        title: 'Eliminar plantilla',
        subtitle: 'Al eliminar este elemento se borrará del sistema. Esta acción es irreversible.',
        form: {
          status: {
            title: '¿Estás seguro de que deseas eliminarlo?',
            label: 'Sí, deseo eliminar esta plantilla',
          },
          submit: 'Eliminar plantilla',
        },
      },
      send: {
        title: 'Enviar plantilla',
        subtitle: 'Enviar plantilla al contacto',
        form: {
          id: {
            title: 'Plantilla',
            description: 'Plantilla a usar en Landbot para mandar el mensaje',
          },
          landbotId: {
            title: 'Landbot ID',
            description: 'ID del bot de Landbot al que se asocia la plantilla',
          },
          submit: 'Enviar plantilla',
        },
      },
      templateScopes: {
        list: {
          title: 'Ámbitos',
          description: 'Gestión de los ámbitos de las plantillas',
          create: 'Ámbito',
          columns: {
            label: 'Nombre',
          },
          one: 'Ámbito',
          many: 'Ámbitos',
        },
        create: {
          title: 'Añadir ámbito',
          subtitle:
            'Asigna un nuevo ámbito a la plantilla. Esto limita el acceso a la misma, de manera que solo los usuarios con roles asociados a ese departamento puedan seleccionarla.',
          form: {
            label: {
              title: 'Nombre',
              description: 'Nombre del ámbito',
            },

            departmentId: {
              title: 'Departamento',
              description: 'Selecciona el departamento al que se asignará el ámbito',
            },
            submit: 'Crear ámbito',
          },
        },
        update: {
          title: 'Actualizar ámbito',
          subtitle: 'Modificación de los datos de un ámbito de plantilla',
          form: {
            label: {
              title: 'Nombre',
              description: 'Nombre del ámbito',
            },
            submit: 'Actualizar ámbito',
          },
        },
        delete: {
          title: 'Eliminar ámbito',
          subtitle:
            'Al eliminar este elemento se borrará del sistema. Esta acción es irreversible.',
          form: {
            status: {
              title: '¿Estás seguro de que deseas eliminarlo?',
              label: 'Sí, deseo eliminar este ámbito',
            },
            submit: 'Eliminar ámbito',
          },
        },
        add: 'Añadir ámbito',
      },
      templateParams: {
        list: {
          title: 'Parámetros',
          description: 'Gestión de los parámetros de las plantillas',
          create: 'Parámetro',
          columns: {
            label: 'Nombre',
            order: 'Orden',
            templateKeyName: 'Parámetro',
          },
          one: 'Parámetro',
          many: 'Parámetros',
        },
        create: {
          title: 'Añadir parámetro',
          subtitle: 'Incluye una nueva variable en la plantilla para personalizar su contenido.',
          form: {
            templateKeyId: {
              title: 'Parámetro',
              description: 'Selecciona el parámetro que tendrá la plantilla',
            },
            order: {
              title: 'Orden',
              description: 'Orden de los parámetros que se mostrarán en la plantilla',
            },
            submit: 'Crear parámetro',
          },
        },
        delete: {
          title: 'Eliminar parámetro',
          subtitle:
            'Al eliminar este elemento se borrará del sistema. Esta acción es irreversible.',
          form: {
            status: {
              title: '¿Estás seguro de que deseas eliminarlo?',
              label: 'Sí, deseo eliminar este parámetro',
            },
            submit: 'Eliminar parámetro',
          },
        },
        add: 'Añadir parámetro',
      },
    },
    languages: {
      list: {
        create: 'Idioma',
        title: 'Idioma',
        description:
          'Sección donde se gestionan los idiomas disponibles, permitiendo su creación, modificación y eliminación. Los idiomas son clave para definir departamentos y segmentar campañas de marketing, lo que afecta la distribución de leads.',
        one: 'Idioma',
        many: 'Idiomas',
        columns: {
          name: 'Nombre',
          country: 'País',
          code: 'Código',
        },
      },
      create: {
        title: 'Nuevo idioma',
        subtitle: 'Creación del idioma y su código asociado para su registro en el sistema.',
        form: {
          name: {
            title: 'Nombre',
            description:
              'introducir el nombre del idioma y, opcionalmente, su código asociado para su registro en el sistema.',
          },
          status: {
            title: 'Estado',
            label: 'Estado',
          },
          countryCode: {
            title: 'País',
            description:
              'Campo para seleccionar el país asociado al idioma, ayudando a contextualizar la lengua en su ubicación geográfica',
          },
          code: {
            title: 'Código',
            description:
              'Campo para ingresar el código estándar del idioma (por ejemplo, "es" para español o "en" para inglés), utilizado para su identificación',
          },
          submit: 'Crear idioma',
        },
      },
      update: {
        title: 'Actualizar idioma',
        subtitle:
          'Permite actualizar la información registrada de un idioma. Los cambios realizados se reflejarán en las asignaciones relacionadas con ese idioma en el sistema.',
        form: {
          name: {
            title: 'Nombre',
            description:
              'introducir el nombre del idioma y, opcionalmente, su código asociado para su registro en el sistema.',
          },
          countryCode: {
            title: 'País',
            description:
              'Campo para seleccionar el país asociado al idioma, ayudando a contextualizar la lengua en su ubicación geográfica',
          },
          code: {
            title: 'Código',
            description:
              'Campo para ingresar el código estándar del idioma (por ejemplo, "es" para español o "en" para inglés), utilizado para su posterior identificación.',
          },
          submit: 'Actualizar idioma',
        },
      },
      enable: {
        title: 'Habilitar idioma',
        subtitle: 'Habilitar idioma en el sistema',
        form: {
          status: {
            title: '¿Estás seguro de que deseas habilitar este idioma?',
            label: 'Sí, deseo habilitar este idioma',
          },
          submit: 'Habilitar idioma',
        },
      },
      disable: {
        title: 'Deshabilitar idioma',
        subtitle:
          'El elemento no estará disponible en el CRM y solo las personas con un permiso específico podrán visualizar los elementos inactivos.',
        form: {
          status: {
            title: '¿Estás seguro de que deseas deshabilitar este idioma?',
            label: 'Sí, deseo deshabilitar este idioma',
          },
          submit: 'Deshabilitar idioma',
        },
      },
    },
    bulletins: {
      bulletin: {
        title: 'Tenemos buenas noticias',
      },
      create: {
        title: 'Nueva noticia',
        subtitle: 'Crea y publica una actualización o anuncio asociado a una clínica específica.',
        steps: {
          clinic: {
            form: {
              title: 'Clínica',
              clinicId: {
                title: 'Clínica',
                description:
                  'Asigna la noticia a una clínica determinada, de manera que solo sea visible para los usuarios que tengan un contrato activo la  misma',
              },
            },
          },
          actor: {
            form: {
              title: 'Compañero',
              actorId: {
                title: 'Compañero',
                description: 'Selecciona el compañero al que se refiere la noticia',
              },
            },
          },
        },
        form: {
          description: {
            title: 'Descripción',
            description:
              'Este texto se verá reflejado en la sección de noticias de la clínica, donde los usuarios podrán leerla. Detalla el texto de la noticia que se desea compartir.',
          },
          image: {
            title: 'Imagen',
            description:
              'Al escribir tres letras se mostraran imagenes para seleccionarlos facilmente. Está imagén se verá de fondo trás la noticia.',
          },
          submit: 'Crear noticia',
        },
      },
      update: {
        title: 'Detalles de la noticia',
        subtitle: 'Detalles de la noticia de la clínica',
        form: {
          clinicId: {
            title: 'Clínica',
            description: 'Clínica a la que pertenece la noticia',
          },
          authorName: {
            title: 'Autor',
            description: 'Autor de la noticia',
          },
          actorName: {
            title: 'Compañero',
            description: 'Compañero del que trata la noticia',
          },
          description: {
            title: 'Descripción',
            description:
              'Para celebrar logros dentro de la clínica, se puede compartir información como: historias de éxito, reconocimientos personales y agradecimientos.',
          },
          image: {
            title: 'Imagen',
            description: 'Imagen de la noticia',
          },
          submit: 'Actualizar noticia',
        },
      },
      list: {
        title: 'Noticias',
        description:
          'Anuncios relacionado con logros, testimonios o celebraciones de la clínica. Estas noticias se crean para ser visibles en una sección común, donde todos los usuarios pueden verlas y estar al tanto de las novedades.',
        create: 'Noticia',
        one: 'Noticia',
        many: 'Noticias',
        columns: {
          clinicName: 'Clínica',
          actorName: 'Compañero',
          authorName: 'Autor',
          created: 'Creación',
        },
      },
      delete: {
        title: 'Deshabilitar noticia',
        subtitle:
          'El elemento no estará disponible en el CRM y solo las personas con un permiso específico podrán visualizar los elementos inactivos.',
        button: 'Deshabilitar',
        form: {
          status: {
            title: '¿Estás seguro de que deseas deshabilitar esta noticia?',
            label: 'Sí, deseo deshabilitar esta noticia',
          },
          submit: 'Deshabilitar',
        },
        passwordForm: {
          title: 'Introduce tu contraseña',
          subtitle: 'Para deshabilitar la noticia, introduce tu contraseña',
          password: {
            title: 'Contraseña',
            description: 'Contraseña del usuario que realiza la acción',
          },
          submit: 'Confirmar',
        },
      },
      enable: {
        title: 'Habilitar noticia',
        subtitle: 'Habilitar noticia de la clínica',
        button: 'Habilitar',
        form: {
          status: {
            title: '¿Estás seguro de que deseas habilitar esta noticia?',
            label: 'Sí, deseo habilitar esta noticia',
          },
          submit: 'Habilitar',
        },
      },
      disable: {
        title: 'Deshabilitar noticia',
        subtitle:
          'El elemento no estará disponible en el CRM y solo las personas con un permiso específico podrán visualizar los elementos inactivos.',
        button: 'Deshabilitar',
        form: {
          status: {
            title: '¿Estás seguro de que deseas deshabilitar esta noticia?',
            label: 'Sí, deseo deshabilitar esta noticia',
          },
          submit: 'Deshabilitar',
        },
      },
    },
    roles: {
      list: {
        columns: {
          name: 'Rol',
          created: 'Creación',
          licenses: 'Licencias',
        },
        title: 'Roles',
        description:
          'Cada rol determina las acciones que un usuario puede realizar y la información a la que puede acceder.',
        create: 'Rol',
        one: 'Rol',
        many: 'Roles',
      },
      create: {
        title: 'Nuevo rol',
        subtitle:
          'Crea un nuevo rol peronalizando los permisos en base a las necesidades especificas de su función.',
        form: {
          name: {
            title: 'Nombre',
            description:
              'Identificador único asignado a un rol que describe su función. Este nombre facilita reconocer y asignar el rol adecuado a los usuarios.',
          },
          general: {
            accordion: 'Permisos generales',
            title: 'Permisos generales',
            description: 'Permisos generales para el rol',
          },
          viewDisabledRecords: {
            title: 'Ver registros deshabilitados',
            description: 'Esta opción habilita la visualización de los registros deshabilitados',
            label: 'Habilitar visualización de registros deshabilitados',
          },
          viewDisabledLeadRecords: {
            title: 'Ver leads deshabilitados',
            description: 'Esta opción habilita la visualización de los leads deshabilitados',
            label: 'Habilitar visualización de leads deshabilitados',
          },
          viewDisabledPatientRecords: {
            title: {
              ovoclinic: 'Ver pacientes deshabilitados',
              ovobank: 'Ver donantes deshabilitados',
            },
            description: {
              ovoclinic: 'Esta opción habilita la visualización de los pacientes deshabilitados',
              ovobank: 'Esta opción habilita la visualización de los donantes deshabilitados',
            },
            label: {
              ovoclinic: 'Habilitar visualización de pacientes deshabilitados',
              ovobank: 'Habilitar visualización de donantes deshabilitados',
            },
          },
          access: {
            accordion: 'OVO Access',
            title: 'Permiso al módulo OVOAccess',
            description: 'Esta opción habilita el acceso al módulo OVOAccess',
            label: 'Habilitar acceso',
          },
          user: {
            accordion: 'Usuarios',
          },
          accessUser: {
            title: 'Ver usuarios',
            description: 'Esta opción habilita la visualización de los usuarios en el sistema',
            label: 'Habilitar visualización de usuarios',
          },
          accessUserCreator: {
            title: 'Crear usuarios',
            description: 'Esta opción habilita la creación de los usuarios en el sistema',
            label: 'Habilitar creación de usuarios',
          },
          accessUserEditor: {
            title: 'Editar usuarios',
            description: 'Esta opción habilita la edición de los usuarios en el sistema',
            label: 'Habilitar edición de usuarios',
          },
          accessUserDestructor: {
            title: 'Eliminar usuarios',
            description: 'Esta opción habilita la eliminación de los usuarios en el sistema',
            label: 'Habilitar eliminación de usuarios',
          },
          accessUserChangePassword: {
            title: 'Cambiar contraseña',
            description:
              'Esta opción habilita el cambio de contraseña de los usuarios en el sistema',
            label: 'Habilitar cambio de contraseña',
          },
          role: {
            accordion: 'Roles',
          },
          accessRole: {
            title: 'Ver roles',
            description: 'Esta opción habilita la visualización de los roles en el sistema',
            label: 'Habilitar visualización de roles',
          },
          accessRoleCreator: {
            title: 'Crear roles',
            description: 'Esta opción habilita la creación de los roles en el sistema',
            label: 'Habilitar creación de roles',
          },
          accessRoleEditor: {
            title: 'Editar roles',
            description: 'Esta opción habilita la edición de los roles en el sistema',
            label: 'Habilitar edición de roles',
          },
          accessRoleDestructor: {
            title: 'Eliminar roles',
            description: 'Esta opción habilita la eliminación de los roles en el sistema',
            label: 'Habilitar eliminación de roles',
          },
          timetable: {
            accordion: 'Horarios',
          },
          accessTimetable: {
            title: 'Ver horarios',
            description: 'Esta opción habilita la visualización de los horarios en el sistema',
            label: 'Habilitar visualización de horarios',
          },
          accessTimetableCreator: {
            title: 'Crear horarios',
            description: 'Esta opción habilita la creación de los horarios en el sistema',
            label: 'Habilitar creación de horarios',
          },
          accessTimetableEditor: {
            title: 'Editar horarios',
            description: 'Esta opción habilita la edición de los horarios en el sistema',
            label: 'Habilitar edición de horarios',
          },
          accessTimetableDestructor: {
            title: 'Eliminar horarios',
            description: 'Esta opción habilita la eliminación de los horarios en el sistema',
            label: 'Habilitar eliminación de horarios',
          },
          bulletin: {
            accordion: 'Noticias',
          },
          accessBulletin: {
            title: 'Ver noticias',
            description: 'Esta opción habilita la visualización de las noticias en el sistema',
            label: 'Habilitar visualización de noticias',
          },
          accessBulletinCreator: {
            title: 'Crear noticias',
            description: 'Esta opción habilita la creación de las noticias en el sistema',
            label: 'Habilitar creación de noticias',
          },
          accessBulletinEditor: {
            title: 'Editar noticias',
            description: 'Esta opción habilita la edición de las noticias en el sistema',
            label: 'Habilitar edición de noticias',
          },
          accessBulletinDestructor: {
            title: 'Eliminar noticias',
            description: 'Esta opción habilita la eliminación de las noticias en el sistema',
            label: 'Habilitar eliminación de noticias',
          },
          accessClinic: {
            title: 'Ver clínicas',
            description: 'Esta opción habilita la visualización de las clínicas en el sistema',
            label: 'Habilitar visualización de clínicas',
          },
          accessClinicCreator: {
            title: 'Crear clínicas',
            description: 'Esta opción habilita la creación de las clínicas en el sistema',
            label: 'Habilitar creación de clínicas',
          },
          accessClinicEditor: {
            title: 'Editar clínicas',
            description: 'Esta opción habilita la edición de las clínicas en el sistema',
            label: 'Habilitar edición de clínicas',
          },
          accessClinicDestructor: {
            title: 'Eliminar clínicas',
            description: 'Esta opción habilita la eliminación de las clínicas en el sistema',
            label: 'Habilitar eliminación de clínicas',
          },
          department: {
            accordion: 'Departamentos',
          },
          accessDepartment: {
            title: 'Ver departamentos',
            description: 'Esta opción habilita la visualización de los departamentos en el sistema',
            label: 'Habilitar visualización de departamentos',
          },
          accessDepartmentCreator: {
            title: 'Crear departamentos',
            description: 'Esta opción habilita la creación de los departamentos en el sistema',
            label: 'Habilitar creación de departamentos',
          },
          accessDepartmentEditor: {
            title: 'Editar departamentos',
            description: 'Esta opción habilita la edición de los departamentos en el sistema',
            label: 'Habilitar edición de departamentos',
          },
          accessDepartmentDestructor: {
            title: 'Eliminar departamentos',
            description: 'Esta opción habilita la eliminación de los departamentos en el sistema',
            label: 'Habilitar eliminación de departamentos',
          },
          language: {
            accordion: 'Idiomas',
          },
          accessLanguage: {
            title: 'Ver idiomas',
            description: 'Esta opción habilita la visualización de los idiomas en el sistema',
            label: 'Habilitar visualización de idiomas',
          },
          accessLanguageCreator: {
            title: 'Crear idiomas',
            description: 'Esta opción habilita la creación de los idiomas en el sistema',
            label: 'Habilitar creación de idiomas',
          },
          accessLanguageEditor: {
            title: 'Editar idiomas',
            description: 'Esta opción habilita la edición de los idiomas en el sistema',
            label: 'Habilitar edición de idiomas',
          },
          accessLanguageDestructor: {
            title: 'Eliminar idiomas',
            description: 'Esta opción habilita la eliminación de los idiomas en el sistema',
            label: 'Habilitar eliminación de idiomas',
          },
          country: {
            accordion: 'Países',
          },
          accessCountry: {
            title: 'Ver países',
            description: 'Esta opción habilita la visualización de los países en el sistema',
            label: 'Habilitar visualización de países',
          },
          accessCountryCreator: {
            title: 'Crear países',
            description: 'Esta opción habilita la creación de los países en el sistema',
            label: 'Habilitar creación de países',
          },
          accessCountryEditor: {
            title: 'Editar países',
            description: 'Esta opción habilita la edición de los países en el sistema',
            label: 'Habilitar edición de países',
          },
          accessCountryDestructor: {
            title: 'Eliminar países',
            description: 'Esta opción habilita la eliminación de los países en el sistema',
            label: 'Habilitar eliminación de países',
          },
          accessSettings: {
            title: 'Ver configuración',
            description: 'Esta opción habilita la visualización de la configuración en el sistema',
            label: 'Habilitar visualización de configuración',
          },
          specialists: {
            accordion: 'Especialistas',
          },
          accessSpecialist: {
            title: 'Ver especialistas',
            description: 'Esta opción habilita la visualización de los especialistas en el sistema',
            label: 'Habilitar visualización de especialistas',
          },
          accessSpecialistCreator: {
            title: 'Crear especialistas',
            description: 'Esta opción habilita la creación de los especialistas en el sistema',
            label: 'Habilitar creación de especialistas',
          },
          accessSpecialistEditor: {
            title: 'Editar especialistas',
            description: 'Esta opción habilita la edición de los especialistas en el sistema',
            label: 'Habilitar edición de especialistas',
          },
          accessSpecialistDestructor: {
            title: 'Eliminar especialistas',
            description: 'Esta opción habilita la eliminación de los especialistas en el sistema',
            label: 'Habilitar eliminación de especialistas',
          },
          commsChannels: {
            accordion: 'Canales de comunicación',
          },
          accessCommsChannel: {
            title: 'Ver canales de comunicación',
            description:
              'Esta opción habilita la visualización de los canales de comunicación en el sistema',
            label: 'Habilitar visualización de canales de comunicación',
          },
          accessCommsChannelCreator: {
            title: 'Crear canales de comunicación',
            description:
              'Esta opción habilita la creación de los canales de comunicación en el sistema',
            label: 'Habilitar creación de canales de comunicación',
          },
          accessCommsChannelEditor: {
            title: 'Editar canales de comunicación',
            description:
              'Esta opción habilita la edición de los canales de comunicación en el sistema',
            label: 'Habilitar edición de canales de comunicación',
          },
          accessCommsChannelDestructor: {
            title: 'Eliminar canales de comunicación',
            description:
              'Esta opción habilita la eliminación de los canales de comunicación en el sistema',
            label: 'Habilitar eliminación de canales de comunicación',
          },
          ovopoints: {
            accordion: 'Ovopoints',
          },
          accessOvopoint: {
            title: 'Ovopoints',
            description: 'Esta opción habilita la visualización de los ovopoints en el sistema',
            label: 'Habilitar visualización de ovopoints',
          },
          accessOvopointEditor: {
            title: 'Editar ovopoints',
            description: 'Esta opción habilita la edición de los ovopoints en el sistema',
            label: 'Habilitar edición de ovopoints',
          },
          templates: {
            accordion: 'Plantillas',
          },
          accessTemplate: {
            title: 'Ver plantillas de WhatsApp',
            description: 'Esta opción habilita la visualización de las plantillas de WhatsApp',
            label: 'Habilitar visualización de plantillas',
          },
          accessTemplateCreator: {
            title: 'Crear plantillas de WhatsApp',
            description: 'Esta opción habilita la creación de las plantillas de WhatsApp',
            label: 'Habilitar creación de plantillas',
          },
          accessTemplateEditor: {
            title: 'Editar plantillas de WhatsApp',
            description: 'Esta opción habilita la edición de las plantillas de WhatsApp',
            label: 'Habilitar edición de plantillas',
          },
          accessTemplateDestructor: {
            title: 'Eliminar plantillas de WhatsApp',
            description: 'Esta opción habilita la eliminación de las plantillas de WhatsApp',
            label: 'Habilitar eliminación de plantillas',
          },
          crm: {
            accordion: 'OVO CRM',
            title: 'Permiso a la aplicación',
            description: 'Esta opción habilita el acceso al módulo OVOCrm',
            label: 'Habilitar acceso',
          },
          tasks: {
            accordion: 'Tareas',
          },
          crmCalendar: {
            title: 'Calendario',
            description: 'Esta opción habilita la visualización del calendario en el sistema',
            label: 'Habilitar visualización de calendario',
          },
          crmTask: {
            title: 'Tareas',
            description: 'Esta opción habilita la visualización de las tareas en el sistema',
            label: 'Habilitar visualización de tareas',
          },
          crmTaskCreator: {
            title: 'Crear tareas',
            description: 'Esta opción habilita la creación de las tareas en el sistema',
            label: 'Habilitar creación de tareas',
          },
          crmTaskEditor: {
            title: 'Editar tareas',
            description: 'Esta opción habilita la edición de las tareas en el sistema',
            label: 'Habilitar edición de tareas',
          },
          crmTaskDestructor: {
            title: 'Eliminar tareas',
            description: 'Esta opción habilita la eliminación de las tareas en el sistema',
            label: 'Habilitar eliminación de tareas',
          },
          crmTaskSeeAll: {
            title: 'Ver todas las tareas',
            description: 'Esta opción habilita la visualización de todas las tareas en el sistema',
            label: 'Habilitar visualización de todas las tareas',
          },
          crmTaskSeeCoworkers: {
            title: 'Ver tareas de compañeros',
            description:
              'Esta opción habilita la visualización de las tareas de los compañeros en el sistema',
            label: 'Habilitar visualización de tareas de compañeros',
          },
          crmTaskUpdateResponsible: {
            title: 'Actualizar el responsable asignado a una tarea',
            description: 'Esta opción habilita el cambio de responsable de una tarea',
            label: 'Habilitar edicion de responsable de tarea',
          },
          leads: {
            accordion: 'Leads',
          },
          crmLead: {
            title: 'Leads',
            description: 'Esta opción habilita la visualización de los leads en el sistema',
            label: 'Habilitar visualización de leads',
          },
          crmLeadCreator: {
            title: 'Crear leads',
            description: 'Esta opción habilita la creación de los leads en el sistema',
            label: 'Habilitar creación de leads',
          },
          crmLeadEditor: {
            title: 'Editar leads',
            description: 'Esta opción habilita la edición de los leads en el sistema',
            label: 'Habilitar edición de leads',
          },
          crmLeadDestructor: {
            title: 'Eliminar leads',
            description: 'Esta opción habilita la eliminación de los leads en el sistema',
            label: 'Habilitar eliminación de leads',
          },
          crmLeadSeeAll: {
            title: 'Ver todos los leads',
            description: 'Esta opción habilita la visualización de todos los leads en el sistema',
            label: 'Habilitar visualización de todos los leads',
          },
          crmLeadSeeCoworkers: {
            title: 'Ver leads de compañeros',
            description: 'Esta opción habilita la visualización de los leads de compañeros',
            label: 'Habilitar visualización de leads de compañeros',
          },
          crmLeadUpdateCampaign: {
            title: 'Actualizar campaña asignada a leads',
            description: 'Esta opción habilita el cambio de campaña de multiples leads',
            label: 'Habilitar edicion de campaña de leads',
          },
          patients: {
            accordion: {
              ovoclinic: 'Pacientes',
              ovobank: 'Donantes',
            },
          },
          crmPatient: {
            title: {
              ovoclinic: 'Pacientes',
              ovobank: 'Donantes',
            },
            description: {
              ovoclinic: 'Esta opción habilita la visualización de los pacientes en el sistema',
              ovobank: 'Esta opción habilita la visualización de los donantes en el sistema',
            },
            label: {
              ovoclinic: 'Habilitar visualización de pacientes',
              ovobank: 'Habilitar visualización de donantes',
            },
          },
          crmPatientCreator: {
            title: {
              ovoclinic: 'Crear pacientes',
              ovobank: 'Crear donantes',
            },
            description: {
              ovoclinic: 'Esta opción habilita la creación de los pacientes en el sistema',
              ovobank: 'Esta opción habilita la creación de los donantes en el sistema',
            },
            label: {
              ovoclinic: 'Habilitar creación de pacientes',
              ovobank: 'Habilitar creación de donantes',
            },
          },
          crmPatientEditor: {
            title: {
              ovoclinic: 'Editar pacientes',
              ovobank: 'Editar donantes',
            },
            description: {
              ovoclinic: 'Esta opción habilita la edición de los pacientes en el sistema',
              ovobank: 'Esta opción habilita la edición de los donantes en el sistema',
            },
            label: {
              ovoclinic: 'Habilitar edición de pacientes',
              ovobank: 'Habilitar edición de donantes',
            },
          },
          crmPatientDestructor: {
            title: {
              ovoclinic: 'Eliminar pacientes',
              ovobank: 'Eliminar donantes',
            },
            description: {
              ovoclinic: 'Esta opción habilita la eliminación de los pacientes en el sistema',
              ovobank: 'Esta opción habilita la eliminación de los donantes en el sistema',
            },
            label: {
              ovoclinic: 'Habilitar eliminación de pacientes',
              ovobank: 'Habilitar eliminación de donantes',
            },
          },
          crmPatientSeeAll: {
            title: {
              ovoclinic: 'Ver todos los pacientes',
              ovobank: 'Ver todos los donantes',
            },
            description: {
              ovoclinic:
                'Esta opción habilita la visualización de todos los pacientes en el sistema',
              ovobank: 'Esta opción habilita la visualización de todos los donantes en el sistema',
            },
            label: {
              ovoclinic: 'Habilitar visualización de todos los pacientes',
              ovobank: 'Habilitar visualización de todos los donantes',
            },
          },
          crmPatientSeeCoworkers: {
            title: {
              ovoclinic: 'Ver pacientes de compañeros',
              ovobank: 'Ver donantes de compañeros',
            },
            description: {
              ovoclinic: 'Esta opción habilita la visualización de los pacientes de compañeros',
              ovobank: 'Esta opción habilita la visualización de los donantes de compañeros',
            },
            label: {
              ovoclinic: 'Habilitar visualización de pacientes de compañeros',
              ovobank: 'Habilitar visualización de donantes de compañeros',
            },
          },
          contacts: {
            accordion: 'Contactos',
          },
          crmContact: {
            title: 'Contactos',
            description: 'Esta opción habilita la visualización de los contactos en el sistema',
            label: 'Habilitar visualización de contactos',
          },
          crmContactCreator: {
            title: 'Crear contactos',
            description: 'Esta opción habilita la creación de los contactos en el sistema',
            label: 'Habilitar creación de contactos',
          },
          crmContactEditor: {
            title: 'Editar contactos',
            description: 'Esta opción habilita la edición de los contactos en el sistema',
            label: 'Habilitar edición de contactos',
          },
          crmContactDestructor: {
            title: 'Eliminar contactos',
            description: 'Esta opción habilita la eliminación de los contactos en el sistema',
            label: 'Habilitar eliminación de contactos',
          },
          activity: {
            accordion: 'Actividades',
          },
          crmActivity: {
            title: 'Actividades',
            description: 'Esta opción habilita la visualización de las actividades en el sistema',
            label: 'Habilitar visualización de actividades',
          },
          crmActivityCreator: {
            title: 'Crear actividades',
            description: 'Esta opción habilita la creación de las actividades en el sistema',
            label: 'Habilitar creación de actividades',
          },
          crmActivityEditor: {
            title: 'Editar actividades',
            description: 'Esta opción habilita la edición de las actividades en el sistema',
            label: 'Habilitar edición de actividades',
          },
          crmActivityDestructor: {
            title: 'Eliminar actividades',
            description: 'Esta opción habilita la eliminación de las actividades en el sistema',
            label: 'Habilitar eliminación de actividades',
          },
          crmActivitySeeAll: {
            title: 'Ver todas las actividades',
            description:
              'Esta opción habilita la visualización de todas las actividades en el sistema',
            label: 'Habilitar visualización de todas las actividades',
          },
          results: {
            accordion: 'Resultados',
          },
          crmResult: {
            title: 'Resultados',
            description: 'Esta opción habilita la visualización de los resultados en el sistema',
            label: 'Habilitar visualización de resultados',
          },
          crmResultCreator: {
            title: 'Crear resultados',
            description: 'Esta opción habilita la creación de los resultados en el sistema',
            label: 'Habilitar creación de resultados',
          },
          crmResultEditor: {
            title: 'Editar resultados',
            description: 'Esta opción habilita la edición de los resultados en el sistema',
            label: 'Habilitar edición de resultados',
          },
          crmResultDestructor: {
            title: 'Eliminar resultados',
            description: 'Esta opción habilita la eliminación de los resultados en el sistema',
            label: 'Habilitar eliminación de resultados',
          },
          crmResultCCEvent: {
            title: 'Editar hitos de Call Center',
            description:
              'Esta opción habilita la edición de los hitos de Call Center en el sistema',
            label: 'Habilitar edición de hitos de Call Center',
          },
          crmResultATPCEvent: {
            title: 'Editar hitos de ATP Comercial',
            description:
              'Esta opción habilita la edición de los hitos de ATP Comercial en el sistema',
            label: 'Habilitar edición de hitos de ATP Comercial',
          },
          crmResultATPIEvent: {
            title: 'Editar hitos de ATP Internacional',
            description:
              'Esta opción habilita la edición de los hitos de ATP Internacional en el sistema',
            label: 'Habilitar edición de hitos de ATP Internacional',
          },
          crmResultReactivity: {
            title: 'Editar reactividad',
            description: 'Esta opción habilita la edición de la reactividad en el sistema',
            label: 'Habilitar edición de reactividad',
          },
          reasons: {
            accordion: 'Motivos',
          },
          crmReason: {
            title: 'Motivos',
            description: 'Esta opción habilita la visualización de los motivos en el sistema',
            label: 'Habilitar visualización de motivos',
          },
          crmReasonCreator: {
            title: 'Crear motivos',
            description: 'Esta opción habilita la creación de los motivos en el sistema',
            label: 'Habilitar creación de motivos',
          },
          crmReasonEditor: {
            title: 'Editar motivos',
            description: 'Esta opción habilita la edición de los motivos en el sistema',
            label: 'Habilitar edición de motivos',
          },
          crmReasonDestructor: {
            title: 'Eliminar motivos',
            description: 'Esta opción habilita la eliminación de los motivos en el sistema',
            label: 'Habilitar eliminación de motivos',
          },
          campaigns: {
            accordion: 'Campañas de marketing',
          },
          crmCampaign: {
            title: 'Campañas de marketing',
            description:
              'Esta opción habilita la visualización de las campañas de marketing en el sistema',
            label: 'Habilitar visualización de campañas de marketing',
          },
          crmCampaignCreator: {
            title: 'Crear Campañas de marketing',
            description:
              'Esta opción habilita la creación de las campañas de marketing en el sistema',
            label: 'Habilitar creación de campañas de marketing',
          },
          crmCampaignEditor: {
            title: 'Editar campañas de marketing',
            description:
              'Esta opción habilita la edición de las campañas de marketing en el sistema',
            label: 'Habilitar edición de campañas de marketing',
          },
          crmCampaignDestructor: {
            title: 'Eliminar campañas de marketing',
            description:
              'Esta opción habilita la eliminación de las campañas de marketing en el sistema',
            label: 'Habilitar eliminación de campañas de marketing',
          },
          funnels: {
            accordion: 'Embudos',
          },
          crmFunnel: {
            title: 'Embudos',
            description: 'Esta opción habilita la visualización de los embudos en el sistema',
            label: 'Habilitar visualización de embudos',
          },
          crmFunnelCreator: {
            title: 'Crear embudos',
            description: 'Esta opción habilita la creación de los embudos en el sistema',
            label: 'Habilitar creación de embudos',
          },
          crmFunnelEditor: {
            title: 'Editar embudos',
            description: 'Esta opción habilita la edición de los embudos en el sistema',
            label: 'Habilitar edición de embudos',
          },
          crmFunnelDestructor: {
            title: 'Eliminar embudos',
            description: 'Esta opción habilita la eliminación de los embudos en el sistema',
            label: 'Habilitar eliminación de embudos',
          },
          channels: {
            accordion: 'Canales',
          },
          crmChannel: {
            title: 'Canales',
            description: 'Esta opción habilita la visualización de los canales en el sistema',
            label: 'Habilitar visualización de canales',
          },
          crmChannelCreator: {
            title: 'Crear canales',
            description: 'Esta opción habilita la creación de los canales en el sistema',
            label: 'Habilitar creación de canales',
          },
          crmChannelEditor: {
            title: 'Editar canales',
            description: 'Esta opción habilita la edición de los canales en el sistema',
            label: 'Habilitar edición de canales',
          },
          crmChannelDestructor: {
            title: 'Eliminar canales',
            description: 'Esta opción habilita la eliminación de los canales en el sistema',
            label: 'Habilitar eliminación de canales',
          },
          programs: {
            accordion: 'Tratamientos',
          },
          crmProgram: {
            title: 'Tratamientos',
            description:
              'Esta opción habilita la visualización de los tratamientos del catálogo en el sistema',
            label: 'Habilitar visualización de tratamientos de catálogo',
          },
          crmProgramCreator: {
            title: 'Crear tratamientos',
            description: 'Esta opción habilita la creación de los tratamientos en el sistema',
            label: 'Habilitar creación de tratamientos',
          },
          crmProgramEditor: {
            title: 'Editar tratamientos',
            description: 'Esta opción habilita la edición de los tratamientos en el sistema',
            label: 'Habilitar edición de tratamientos',
          },
          crmProgramDestructor: {
            title: 'Eliminar tratamientos',
            description: 'Esta opción habilita la eliminación de los tratamientos en el sistema',
            label: 'Habilitar eliminación de tratamientos',
          },
          services: {
            accordion: 'Servicios',
          },
          crmService: {
            title: 'Servicios',
            description: 'Esta opción habilita la visualización de los servicios en el sistema',
            label: 'Habilitar visualización de servicios',
          },
          crmServiceCreator: {
            title: 'Crear servicios',
            description: 'Esta opción habilita la creación de los servicios en el sistema',
            label: 'Habilitar creación de servicios',
          },
          crmServiceEditor: {
            title: 'Editar servicios',
            description: 'Esta opción habilita la edición de los servicios en el sistema',
            label: 'Habilitar edición de servicios',
          },
          crmServiceDestructor: {
            title: 'Eliminar servicios',
            description: 'Esta opción habilita la eliminación de los servicios en el sistema',
            label: 'Habilitar eliminación de servicios',
          },
          techniques: {
            accordion: 'Técnicas',
          },
          crmTechnique: {
            title: 'Técnicas complementarias',
            description:
              'Esta opción habilita la visualización de las técnicas complementarias en el sistema',
            label: 'Habilitar visualización de técnicas complementarias',
          },
          crmTechniqueCreator: {
            title: 'Crear técnicas complementarias',
            description:
              'Esta opción habilita la creación de las técnicas complementarias en el sistema',
            label: 'Habilitar creación de técnicas complementarias',
          },
          crmTechniqueEditor: {
            title: 'Editar técnicas complementarias',
            description:
              'Esta opción habilita la edición de las técnicas complementarias en el sistema',
            label: 'Habilitar edición de técnicas complementarias',
          },
          crmTechniqueDestructor: {
            title: 'Eliminar técnicas complementarias',
            description:
              'Esta opción habilita la eliminación de las técnicas complementarias en el sistema',
            label: 'Habilitar eliminación de técnicas complementarias',
          },
          maintenances: {
            accordion: 'Mantenimientos',
          },
          crmMaintenance: {
            title: 'Mantenimientos',
            description:
              'Esta opción habilita la visualización de los mantenimientos en el sistema',
            label: 'Habilitar visualización de mantenimientos',
          },
          crmMaintenanceCreator: {
            title: 'Crear mantenimientos',
            description: 'Esta opción habilita la creación de los mantenimientos en el sistema',
            label: 'Habilitar creación de mantenimientos',
          },
          crmMaintenanceEditor: {
            title: 'Editar mantenimientos',
            description: 'Esta opción habilita la edición de los mantenimientos en el sistema',
            label: 'Habilitar edición de mantenimientos',
          },
          crmMaintenanceDestructor: {
            title: 'Eliminar mantenimientos',
            description: 'Esta opción habilita la eliminación de los mantenimientos en el sistema',
            label: 'Habilitar eliminación de mantenimientos',
          },
          treatments: {
            accordion: 'Tratamientos',
          },
          crmTreatment: {
            title: 'Tratamientos',
            description: 'Esta opción habilita la visualización de los tratamientos en el sistema',
            label: 'Habilitar visualización de tratamientos',
          },
          crmTreatmentCreator: {
            title: {
              ovoclinic: 'Crear tratamientos para paciente',
              ovobank: 'Crear tratamientos para donante',
            },
            description: {
              ovoclinic:
                'Esta opción habilita la creación de los tratamientos para paciente en el sistema',
              ovobank:
                'Esta opción habilita la creación de los tratamientos para donante en el sistema',
            },
            label: {
              ovoclinic: 'Habilitar creación de tratamientos para paciente',
              ovobank: 'Habilitar creación de tratamientos para donante',
            },
          },
          crmTreatmentEditor: {
            title: {
              ovoclinic: 'Editar tratamientos para paciente',
              ovobank: 'Editar tratamientos para donante',
            },
            description: {
              ovoclinic:
                'Esta opción habilita la edición de los tratamientos para paciente en el sistema',
              ovobank:
                'Esta opción habilita la edición de los tratamientos para donante en el sistema',
            },
            label: {
              ovoclinic: 'Habilitar edición de tratamientos para paciente',
              ovobank: 'Habilitar edición de tratamientos para donante',
            },
          },
          crmTreatmentDestructor: {
            title: {
              ovoclinic: 'Eliminar tratamientos para paciente',
              ovobank: 'Eliminar tratamientos para donante',
            },
            description: {
              ovoclinic:
                'Esta opción habilita la eliminación de los tratamientos para paciente en el sistema',
              ovobank:
                'Esta opción habilita la eliminación de los tratamientos para donante en el sistema',
            },
            label: {
              ovoclinic: 'Habilitar eliminación de tratamientos para paciente',
              ovobank: 'Habilitar eliminación de tratamientos para donante',
            },
          },
          phases: {
            accordion: 'Etapas',
          },
          crmPhase: {
            title: 'Etapas',
            description: 'Esta opción habilita la visualización de las etapas en el sistema',
            label: 'Habilitar visualización de etapas',
          },
          crmPhaseCreator: {
            title: 'Crear etapas',
            description: 'Esta opción habilita la creación de las etapas en el sistema',
            label: 'Habilitar creación de etapas',
          },
          crmPhaseEditor: {
            title: 'Editar etapas',
            description: 'Esta opción habilita la edición de las etapas en el sistema',
            label: 'Habilitar edición de etapas',
          },
          crmPhaseDestructor: {
            title: 'Eliminar etapas',
            description: 'Esta opción habilita la eliminación de las etapas en el sistema',
            label: 'Habilitar eliminación de etapas',
          },
          causes: {
            accordion: 'Motivos',
          },
          crmCause: {
            title: 'Motivos de cancelación',
            description:
              'Esta opción habilita la visualización de los motivos de cancelación en el sistema',
            label: 'Habilitar visualización de motivos de cancelación',
          },
          crmCauseCreator: {
            title: 'Crear motivos de cancelación',
            description:
              'Esta opción habilita la creación de los motivos de cancelación en el sistema',
            label: 'Habilitar creación de motivos de cancelación',
          },
          crmCauseEditor: {
            title: 'Editar motivos de cancelación',
            description:
              'Esta opción habilita la edición de los motivos de cancelación en el sistema',
            label: 'Habilitar edición de motivos de cancelación',
          },
          crmCauseDestructor: {
            title: 'Eliminar motivos de cancelación',
            description:
              'Esta opción habilita la eliminación de los motivos de cancelación en el sistema',
            label: 'Habilitar eliminación de motivos de cancelación',
          },
          cycles: {
            accordion: 'Ciclos de tratamiento',
          },
          crmCycle: {
            title: 'Ciclos de tratamiento',
            description:
              'Esta opción habilita la visualización de los ciclos de tratamiento en el sistema',
            label: 'Habilitar visualización de ciclos de tratamiento',
          },
          crmCycleCreator: {
            title: 'Crear ciclos de tratamiento',
            description:
              'Esta opción habilita la creación de los ciclos de tratamiento en el sistema',
            label: 'Habilitar creación de ciclos de tratamiento',
          },
          crmCycleEditor: {
            title: 'Editar ciclos de tratamiento',
            description:
              'Esta opción habilita la edición de los ciclos de tratamiento en el sistema',
            label: 'Habilitar edición de ciclos de tratamiento',
          },
          crmCycleDestructor: {
            title: 'Eliminar ciclos de tratamiento',
            description:
              'Esta opción habilita la eliminación de los ciclos de tratamiento en el sistema',
            label: 'Habilitar eliminación de ciclos de tratamiento',
          },
          clinic: {
            accordion: 'OVOClinic',
            title: 'OVOClinic',
            description: 'Esta opción habilita el acceso al módulo OVOClinic',
            label: 'Habilitar acceso',
          },
          board: {
            accordion: 'OVOBoard',
            title: 'OVOBoard',
            description: 'Esta opción habilita el acceso al módulo OVOBoard',
            label: 'Habilitar acceso',
          },
          boardLeadsResume: {
            title: 'Resumen de leads',
            label: 'Habilitar resumen de leads',
          },
          boardSaleResume: {
            title: 'Resumen de ventas',
            label: 'Habilitar resumen de ventas',
          },
          boardSaleFunnel: {
            title: 'Embudo de ventas',
            label: 'Habilitar embudo de ventas',
          },
          boardExpectedIntervention: {
            title: 'Previsión de tratamientos',
            label: 'Habilitar previsión de tratamientos',
          },
          boardCoworkerPerformance: {
            title: 'Rendimiento de empleados',
            label: 'Habilitar rendimiento de empleados',
          },
          boardMarketing: {
            title: 'Marketing',
            label: 'Habilitar marketing',
          },
          tory: {
            accordion: 'OVOTory',
            title: 'OVOTory',
            description: 'Esta opción habilita el acceso al módulo OVOTory',
            label: 'Habilitar acceso',
          },
          profile: {
            accordion: 'OVOProfile',
            title: 'OVOProfile',
            description: 'Esta opción habilita el acceso al módulo OVOProfile',
            label: 'Habilitar acceso',
          },
          status: {
            title: 'Rol activo',
            description: 'Habilitar o deshabilitar el rol en el sistema',
            label: 'Rol activo',
          },
          submit: 'Crear rol',
        },
      },
      update: {
        title: 'Actualizar rol',
        subtitle:
          'Actualiza los permisos de un rol existente. Esto afectara a los accesos de los usuarios que tengan asignado este rol.',
        form: {
          name: {
            title: 'Nombre',
            description:
              'Identificador único asignado a un rol que describe su función. Este nombre facilita reconocer y asignar el rol adecuado a los usuarios.',
          },
          general: {
            accordion: 'General',
            title: 'Permisos generales',
            description: 'Permisos generales para el rol',
          },
          viewDisabledRecords: {
            title: 'Ver registros deshabilitados',
            description: 'Esta opción habilita la visualización de los registros deshabilitados',
            label: 'Habilitar visualización de registros deshabilitados',
          },
          viewDisabledLeadRecords: {
            title: 'Ver leads deshabilitados',
            description: 'Esta opción habilita la visualización de los leads deshabilitados',
            label: 'Habilitar visualización de leads deshabilitados',
          },
          viewDisabledPatientRecords: {
            title: {
              ovoclinic: 'Ver pacientes deshabilitados',
              ovobank: 'Ver donantes deshabilitados',
            },
            description: {
              ovoclinic: 'Esta opción habilita la visualización de los pacientes deshabilitados',
              ovobank: 'Esta opción habilita la visualización de los donantes deshabilitados',
            },
            label: {
              ovoclinic: 'Habilitar visualización de pacientes deshabilitados',
              ovobank: 'Habilitar visualización de donantes deshabilitados',
            },
          },
          access: {
            accordion: 'OVO Access',
            title: 'Permiso al módulo OVOAccess',
            description: 'Esta opción habilita el acceso al módulo OVOAccess',
            label: 'Habilitar acceso',
          },
          user: {
            accordion: 'Usuarios',
          },
          accessUser: {
            title: 'Ver usuarios',
            description: 'Esta opción habilita la visualización de los usuarios en el sistema',
            label: 'Habilitar visualización de usuarios',
          },
          accessUserCreator: {
            title: 'Crear usuarios',
            description: 'Esta opción habilita la creación de los usuarios en el sistema',
            label: 'Habilitar creación de usuarios',
          },
          accessUserEditor: {
            title: 'Editar usuarios',
            description: 'Esta opción habilita la edición de los usuarios en el sistema',
            label: 'Habilitar edición de usuarios',
          },
          accessUserDestructor: {
            title: 'Eliminar usuarios',
            description: 'Esta opción habilita la eliminación de los usuarios en el sistema',
            label: 'Habilitar eliminación de usuarios',
          },
          accessUserChangePassword: {
            title: 'Cambiar contraseña',
            description:
              'Esta opción habilita el cambio de contraseña de los usuarios en el sistema',
            label: 'Habilitar cambio de contraseña',
          },
          role: {
            accordion: 'Roles',
          },
          accessRole: {
            title: 'Ver roles',
            description: 'Esta opción habilita la visualización de los roles en el sistema',
            label: 'Habilitar visualización de roles',
          },
          accessRoleCreator: {
            title: 'Crear roles',
            description: 'Esta opción habilita la creación de los roles en el sistema',
            label: 'Habilitar creación de roles',
          },
          accessRoleEditor: {
            title: 'Editar roles',
            description: 'Esta opción habilita la edición de los roles en el sistema',
            label: 'Habilitar edición de roles',
          },
          accessRoleDestructor: {
            title: 'Eliminar roles',
            description: 'Esta opción habilita la eliminación de los roles en el sistema',
            label: 'Habilitar eliminación de roles',
          },
          timetable: {
            accordion: 'Horarios',
          },
          accessTimetable: {
            title: 'Ver horarios',
            description: 'Esta opción habilita la visualización de los horarios en el sistema',
            label: 'Habilitar visualización de horarios',
          },
          accessTimetableCreator: {
            title: 'Crear horarios',
            description: 'Esta opción habilita la creación de los horarios en el sistema',
            label: 'Habilitar creación de horarios',
          },
          accessTimetableEditor: {
            title: 'Editar horarios',
            description: 'Esta opción habilita la edición de los horarios en el sistema',
            label: 'Habilitar edición de horarios',
          },
          accessTimetableDestructor: {
            title: 'Eliminar horarios',
            description: 'Esta opción habilita la eliminación de los horarios en el sistema',
            label: 'Habilitar eliminación de horarios',
          },
          bulletin: {
            accordion: 'Noticias',
          },
          accessBulletin: {
            title: 'Ver noticias',
            description: 'Esta opción habilita la visualización de las noticias en el sistema',
            label: 'Habilitar visualización de noticias',
          },
          accessBulletinCreator: {
            title: 'Crear noticias',
            description: 'Esta opción habilita la creación de las noticias en el sistema',
            label: 'Habilitar creación de noticias',
          },
          accessBulletinEditor: {
            title: 'Editar noticias',
            description: 'Esta opción habilita la edición de las noticias en el sistema',
            label: 'Habilitar edición de noticias',
          },
          accessBulletinDestructor: {
            title: 'Eliminar noticias',
            description: 'Esta opción habilita la eliminación de las noticias en el sistema',
            label: 'Habilitar eliminación de noticias',
          },
          accessClinic: {
            title: 'Ver clínicas',
            description: 'Esta opción habilita la visualización de las clínicas en el sistema',
            label: 'Habilitar visualización de clínicas',
          },
          accessClinicCreator: {
            title: 'Crear clínicas',
            description: 'Esta opción habilita la creación de las clínicas en el sistema',
            label: 'Habilitar creación de clínicas',
          },
          accessClinicEditor: {
            title: 'Editar clínicas',
            description: 'Esta opción habilita la edición de las clínicas en el sistema',
            label: 'Habilitar edición de clínicas',
          },
          accessClinicDestructor: {
            title: 'Eliminar clínicas',
            description: 'Esta opción habilita la eliminación de las clínicas en el sistema',
            label: 'Habilitar eliminación de clínicas',
          },
          department: {
            accordion: 'Departamentos',
          },
          accessDepartment: {
            title: 'Ver departamentos',
            description: 'Esta opción habilita la visualización de los departamentos en el sistema',
            label: 'Habilitar visualización de departamentos',
          },
          accessDepartmentCreator: {
            title: 'Crear departamentos',
            description: 'Esta opción habilita la creación de los departamentos en el sistema',
            label: 'Habilitar creación de departamentos',
          },
          accessDepartmentEditor: {
            title: 'Editar departamentos',
            description: 'Esta opción habilita la edición de los departamentos en el sistema',
            label: 'Habilitar edición de departamentos',
          },
          accessDepartmentDestructor: {
            title: 'Eliminar departamentos',
            description: 'Esta opción habilita la eliminación de los departamentos en el sistema',
            label: 'Habilitar eliminación de departamentos',
          },
          language: {
            accordion: 'Idiomas',
          },
          accessLanguage: {
            title: 'Ver idiomas',
            description: 'Esta opción habilita la visualización de los idiomas en el sistema',
            label: 'Habilitar visualización de idiomas',
          },
          accessLanguageCreator: {
            title: 'Crear idiomas',
            description: 'Esta opción habilita la creación de los idiomas en el sistema',
            label: 'Habilitar creación de idiomas',
          },
          accessLanguageEditor: {
            title: 'Editar idiomas',
            description: 'Esta opción habilita la edición de los idiomas en el sistema',
            label: 'Habilitar edición de idiomas',
          },
          accessLanguageDestructor: {
            title: 'Eliminar idiomas',
            description: 'Esta opción habilita la eliminación de los idiomas en el sistema',
            label: 'Habilitar eliminación de idiomas',
          },
          country: {
            accordion: 'Países',
          },
          accessCountry: {
            title: 'Ver países',
            description: 'Esta opción habilita la visualización de los países en el sistema',
            label: 'Habilitar visualización de países',
          },
          accessCountryCreator: {
            title: 'Crear países',
            description: 'Esta opción habilita la creación de los países en el sistema',
            label: 'Habilitar creación de países',
          },
          accessCountryEditor: {
            title: 'Editar países',
            description: 'Esta opción habilita la edición de los países en el sistema',
            label: 'Habilitar edición de países',
          },
          accessCountryDestructor: {
            title: 'Eliminar países',
            description: 'Esta opción habilita la eliminación de los países en el sistema',
            label: 'Habilitar eliminación de países',
          },
          accessSettings: {
            title: 'Ver configuración',
            description: 'Esta opción habilita la visualización de la configuración en el sistema',
            label: 'Habilitar visualización de configuración',
          },
          specialists: {
            accordion: 'Especialistas',
          },
          accessSpecialist: {
            title: 'Ver especialistas',
            description: 'Esta opción habilita la visualización de los especialistas en el sistema',
            label: 'Habilitar visualización de especialistas',
          },
          accessSpecialistCreator: {
            title: 'Crear especialistas',
            description: 'Esta opción habilita la creación de los especialistas en el sistema',
            label: 'Habilitar creación de especialistas',
          },
          accessSpecialistEditor: {
            title: 'Editar especialistas',
            description: 'Esta opción habilita la edición de los especialistas en el sistema',
            label: 'Habilitar edición de especialistas',
          },
          accessSpecialistDestructor: {
            title: 'Eliminar especialistas',
            description: 'Esta opción habilita la eliminación de los especialistas en el sistema',
            label: 'Habilitar eliminación de especialistas',
          },
          commsChannels: {
            accordion: 'Canales de comunicación',
          },
          accessCommsChannel: {
            title: 'Ver canales de comunicación',
            description:
              'Esta opción habilita la visualización de los canales de comunicación en el sistema',
            label: 'Habilitar visualización de canales de comunicación',
          },
          accessCommsChannelCreator: {
            title: 'Crear canales de comunicación',
            description:
              'Esta opción habilita la creación de los canales de comunicación en el sistema',
            label: 'Habilitar creación de canales de comunicación',
          },
          accessCommsChannelEditor: {
            title: 'Editar canales de comunicación',
            description:
              'Esta opción habilita la edición de los canales de comunicación en el sistema',
            label: 'Habilitar edición de canales de comunicación',
          },
          accessCommsChannelDestructor: {
            title: 'Eliminar canales de comunicación',
            description:
              'Esta opción habilita la eliminación de los canales de comunicación en el sistema',
            label: 'Habilitar eliminación de canales de comunicación',
          },
          ovopoints: {
            accordion: 'Ovopoints',
          },
          accessOvopoint: {
            title: 'Ovopoints',
            description: 'Esta opción habilita la visualización de los ovopoints en el sistema',
            label: 'Habilitar visualización de ovopoints',
          },
          accessOvopointEditor: {
            title: 'Editar ovopoints',
            description: 'Esta opción habilita la edición de los ovopoints en el sistema',
            label: 'Habilitar edición de ovopoints',
          },
          templates: {
            accordion: 'Plantillas',
          },
          accessTemplate: {
            title: 'Ver plantillas de WhatsApp',
            description: 'Esta opción habilita la visualización de las plantillas de WhatsApp',
            label: 'Habilitar visualización de plantillas',
          },
          accessTemplateCreator: {
            title: 'Crear plantillas de WhatsApp',
            description: 'Esta opción habilita la creación de las plantillas de WhatsApp',
            label: 'Habilitar creación de plantillas',
          },
          accessTemplateEditor: {
            title: 'Editar plantillas de WhatsApp',
            description: 'Esta opción habilita la edición de las plantillas de WhatsApp',
            label: 'Habilitar edición de plantillas',
          },
          accessTemplateDestructor: {
            title: 'Eliminar plantillas de WhatsApp',
            description: 'Esta opción habilita la eliminación de las plantillas de WhatsApp',
            label: 'Habilitar eliminación de plantillas',
          },
          crm: {
            accordion: 'OVO CRM',
            title: 'Permiso a la aplicación',
            description: 'Esta opción habilita el acceso al módulo OVOCrm',
            label: 'Habilitar acceso',
          },
          tasks: {
            accordion: 'Tareas',
          },
          crmCalendar: {
            title: 'Calendario',
            description: 'Esta opción habilita la visualización del calendario en el sistema',
            label: 'Habilitar visualización de calendario',
          },
          crmTask: {
            title: 'Tareas',
            description: 'Esta opción habilita la visualización de las tareas en el sistema',
            label: 'Habilitar visualización de tareas',
          },
          crmTaskCreator: {
            title: 'Crear tareas',
            description: 'Esta opción habilita la creación de las tareas en el sistema',
            label: 'Habilitar creación de tareas',
          },
          crmTaskEditor: {
            title: 'Editar tareas',
            description: 'Esta opción habilita la edición de las tareas en el sistema',
            label: 'Habilitar edición de tareas',
          },
          crmTaskDestructor: {
            title: 'Eliminar tareas',
            description: 'Esta opción habilita la eliminación de las tareas en el sistema',
            label: 'Habilitar eliminación de tareas',
          },
          crmTaskSeeAll: {
            title: 'Ver todas las tareas',
            description: 'Esta opción habilita la visualización de todas las tareas en el sistema',
            label: 'Habilitar visualización de todas las tareas',
          },
          crmTaskSeeCoworkers: {
            title: 'Ver tareas de compañeros',
            description:
              'Esta opción habilita la visualización de las tareas de los compañeros en el sistema',
            label: 'Habilitar visualización de tareas de compañeros',
          },
          crmTaskUpdateResponsible: {
            title: 'Actualizar el responsable asignado a una tarea',
            description: 'Esta opción habilita el cambio de responsable de una tarea',
            label: 'Habilitar edicion de responsable de tarea',
          },
          leads: {
            accordion: 'Leads',
          },
          crmLead: {
            title: 'Leads',
            description: 'Esta opción habilita la visualización de los leads en el sistema',
            label: 'Habilitar visualización de leads',
          },
          crmLeadCreator: {
            title: 'Crear leads',
            description: 'Esta opción habilita la creación de los leads en el sistema',
            label: 'Habilitar creación de leads',
          },
          crmLeadEditor: {
            title: 'Editar leads',
            description: 'Esta opción habilita la edición de los leads en el sistema',
            label: 'Habilitar edición de leads',
          },
          crmLeadDestructor: {
            title: 'Eliminar leads',
            description: 'Esta opción habilita la eliminación de los leads en el sistema',
            label: 'Habilitar eliminación de leads',
          },
          crmLeadSeeAll: {
            title: 'Ver todos los leads',
            description: 'Esta opción habilita la visualización de todos los leads en el sistema',
            label: 'Habilitar visualización de todos los leads',
          },
          crmLeadSeeCoworkers: {
            title: 'Ver leads de compañeros',
            description: 'Esta opción habilita la visualización de los leads de compañeros',
            label: 'Habilitar visualización de leads de compañeros',
          },
          crmLeadUpdateCampaign: {
            title: 'Actualizar campaña asignada a leads',
            description: 'Esta opción habilita el cambio de campaña de multiples leads',
            label: 'Habilitar edicion de campaña de leads',
          },
          patients: {
            accordion: {
              ovoclinic: 'Pacientes',
              ovobank: 'Donantes',
            },
          },
          crmPatient: {
            title: {
              ovoclinic: 'Pacientes',
              ovobank: 'Donantes',
            },
            description: {
              ovoclinic: 'Esta opción habilita la visualización de los pacientes en el sistema',
              ovobank: 'Esta opción habilita la visualización de los donantes en el sistema',
            },
            label: {
              ovoclinic: 'Habilitar visualización de pacientes',
              ovobank: 'Habilitar visualización de donantes',
            },
          },
          crmPatientCreator: {
            title: {
              ovoclinic: 'Crear pacientes',
              ovobank: 'Crear donantes',
            },
            description: {
              ovoclinic: 'Esta opción habilita la creación de los pacientes en el sistema',
              ovobank: 'Esta opción habilita la creación de los donantes en el sistema',
            },
            label: {
              ovoclinic: 'Habilitar creación de pacientes',
              ovobank: 'Habilitar creación de donantes',
            },
          },
          crmPatientEditor: {
            title: {
              ovoclinic: 'Editar pacientes',
              ovobank: 'Editar donantes',
            },
            description: {
              ovoclinic: 'Esta opción habilita la edición de los pacientes en el sistema',
              ovobank: 'Esta opción habilita la edición de los donantes en el sistema',
            },
            label: {
              ovoclinic: 'Habilitar edición de pacientes',
              ovobank: 'Habilitar edición de donantes',
            },
          },
          crmPatientDestructor: {
            title: {
              ovoclinic: 'Eliminar pacientes',
              ovobank: 'Eliminar donantes',
            },
            description: {
              ovoclinic: 'Esta opción habilita la eliminación de los pacientes en el sistema',
              ovobank: 'Esta opción habilita la eliminación de los donantes en el sistema',
            },
            label: {
              ovoclinic: 'Habilitar eliminación de pacientes',
              ovobank: 'Habilitar eliminación de donantes',
            },
          },
          crmPatientSeeAll: {
            title: {
              ovoclinic: 'Ver todos los pacientes',
              ovobank: 'Ver todos los donantes',
            },
            description: {
              ovoclinic:
                'Esta opción habilita la visualización de todos los pacientes en el sistema',
              ovobank: 'Esta opción habilita la visualización de todos los donantes en el sistema',
            },
            label: {
              ovoclinic: 'Habilitar visualización de todos los pacientes',
              ovobank: 'Habilitar visualización de todos los donantes',
            },
          },
          crmPatientSeeCoworkers: {
            title: {
              ovoclinic: 'Ver pacientes de compañeros',
              ovobank: 'Ver donantes de compañeros',
            },
            description: {
              ovoclinic: 'Esta opción habilita la visualización de los pacientes de compañeros',
              ovobank: 'Esta opción habilita la visualización de los donantes de compañeros',
            },
            label: {
              ovoclinic: 'Habilitar visualización de pacientes de compañeros',
              ovobank: 'Habilitar visualización de donantes de compañeros',
            },
          },
          contacts: {
            accordion: 'Contactos',
          },
          crmContact: {
            title: 'Contactos',
            description: 'Esta opción habilita la visualización de los contactos en el sistema',
            label: 'Habilitar visualización de contactos',
          },
          crmContactCreator: {
            title: 'Crear contactos',
            description: 'Esta opción habilita la creación de los contactos en el sistema',
            label: 'Habilitar creación de contactos',
          },
          crmContactEditor: {
            title: 'Editar contactos',
            description: 'Esta opción habilita la edición de los contactos en el sistema',
            label: 'Habilitar edición de contactos',
          },
          crmContactDestructor: {
            title: 'Eliminar contactos',
            description: 'Esta opción habilita la eliminación de los contactos en el sistema',
            label: 'Habilitar eliminación de contactos',
          },
          activity: {
            accordion: 'Actividades',
          },
          crmActivity: {
            title: 'Actividades',
            description: 'Esta opción habilita la visualización de las actividades en el sistema',
            label: 'Habilitar visualización de actividades',
          },
          crmActivityCreator: {
            title: 'Crear actividades',
            description: 'Esta opción habilita la creación de las actividades en el sistema',
            label: 'Habilitar creación de actividades',
          },
          crmActivityEditor: {
            title: 'Editar actividades',
            description: 'Esta opción habilita la edición de las actividades en el sistema',
            label: 'Habilitar edición de actividades',
          },
          crmActivityDestructor: {
            title: 'Eliminar actividades',
            description: 'Esta opción habilita la eliminación de las actividades en el sistema',
            label: 'Habilitar eliminación de actividades',
          },
          crmActivitySeeAll: {
            title: 'Ver todas las actividades',
            description:
              'Esta opción habilita la visualización de todas las actividades en el sistema',
            label: 'Habilitar visualización de todas las actividades',
          },
          results: {
            accordion: 'Resultados',
          },
          crmResult: {
            title: 'Resultados',
            description: 'Esta opción habilita la visualización de los resultados en el sistema',
            label: 'Habilitar visualización de resultados',
          },
          crmResultCreator: {
            title: 'Crear resultados',
            description: 'Esta opción habilita la creación de los resultados en el sistema',
            label: 'Habilitar creación de resultados',
          },
          crmResultEditor: {
            title: 'Editar resultados',
            description: 'Esta opción habilita la edición de los resultados en el sistema',
            label: 'Habilitar edición de resultados',
          },
          crmResultDestructor: {
            title: 'Eliminar resultados',
            description: 'Esta opción habilita la eliminación de los resultados en el sistema',
            label: 'Habilitar eliminación de resultados',
          },
          crmResultCCEvent: {
            title: 'Editar hitos de Call Center',
            description:
              'Esta opción habilita la edición de los hitos de Call Center en el sistema',
            label: 'Habilitar edición de hitos de Call Center',
          },
          crmResultATPCEvent: {
            title: 'Editar hitos de ATP Comercial',
            description:
              'Esta opción habilita la edición de los hitos de ATP Comercial en el sistema',
            label: 'Habilitar edición de hitos de ATP Comercial',
          },
          crmResultATPIEvent: {
            title: 'Editar hitos de ATP Internacional',
            description:
              'Esta opción habilita la edición de los hitos de ATP Internacional en el sistema',
            label: 'Habilitar edición de hitos de ATP Internacional',
          },
          crmResultReactivity: {
            title: 'Editar reactividad',
            description: 'Esta opción habilita la edición de la reactividad en el sistema',
            label: 'Habilitar edición de reactividad',
          },
          reasons: {
            accordion: 'Motivos',
          },
          crmReason: {
            title: 'Motivos',
            description: 'Esta opción habilita la visualización de los motivos en el sistema',
            label: 'Habilitar visualización de motivos',
          },
          crmReasonCreator: {
            title: 'Crear motivos',
            description: 'Esta opción habilita la creación de los motivos en el sistema',
            label: 'Habilitar creación de motivos',
          },
          crmReasonEditor: {
            title: 'Editar motivos',
            description: 'Esta opción habilita la edición de los motivos en el sistema',
            label: 'Habilitar edición de motivos',
          },
          crmReasonDestructor: {
            title: 'Eliminar motivos',
            description: 'Esta opción habilita la eliminación de los motivos en el sistema',
            label: 'Habilitar eliminación de motivos',
          },
          campaigns: {
            accordion: 'Campañas de marketing',
          },
          crmCampaign: {
            title: 'Campañas de marketing',
            description:
              'Esta opción habilita la visualización de las campañas de marketing en el sistema',
            label: 'Habilitar visualización de campañas de marketing',
          },
          crmCampaignCreator: {
            title: 'Crear campañas de marketing',
            description:
              'Esta opción habilita la creación de las campañas de marketing en el sistema',
            label: 'Habilitar creación de campañas de marketing',
          },
          crmCampaignEditor: {
            title: 'Editar Campañas de marketing',
            description:
              'Esta opción habilita la edición de las campañas de marketing en el sistema',
            label: 'Habilitar edición de campañas de marketing',
          },
          crmCampaignDestructor: {
            title: 'Eliminar campañas de marketing',
            description:
              'Esta opción habilita la eliminación de las campañas de marketing en el sistema',
            label: 'Habilitar eliminación de campañas de marketing',
          },
          funnels: {
            accordion: 'Embudos',
          },
          crmFunnel: {
            title: 'Embudos',
            description: 'Esta opción habilita la visualización de los embudos en el sistema',
            label: 'Habilitar visualización de embudos',
          },
          crmFunnelCreator: {
            title: 'Crear embudos',
            description: 'Esta opción habilita la creación de los embudos en el sistema',
            label: 'Habilitar creación de embudos',
          },
          crmFunnelEditor: {
            title: 'Editar embudos',
            description: 'Esta opción habilita la edición de los embudos en el sistema',
            label: 'Habilitar edición de embudos',
          },
          crmFunnelDestructor: {
            title: 'Eliminar embudos',
            description: 'Esta opción habilita la eliminación de los embudos en el sistema',
            label: 'Habilitar eliminación de embudos',
          },
          channels: {
            accordion: 'Canales',
          },
          crmChannel: {
            title: 'Canales',
            description: 'Esta opción habilita la visualización de los canales en el sistema',
            label: 'Habilitar visualización de canales',
          },
          crmChannelCreator: {
            title: 'Crear canales',
            description: 'Esta opción habilita la creación de los canales en el sistema',
            label: 'Habilitar creación de canales',
          },
          crmChannelEditor: {
            title: 'Editar canales',
            description: 'Esta opción habilita la edición de los canales en el sistema',
            label: 'Habilitar edición de canales',
          },
          crmChannelDestructor: {
            title: 'Eliminar canales',
            description: 'Esta opción habilita la eliminación de los canales en el sistema',
            label: 'Habilitar eliminación de canales',
          },
          programs: {
            accordion: 'Tratamientos',
          },
          crmProgram: {
            title: 'Tratamientos',
            description:
              'Esta opción habilita la visualización de los tratamientos del catálogo en el sistema',
            label: 'Habilitar visualización de tratamientos de catálogo',
          },
          crmProgramCreator: {
            title: 'Crear tratamientos',
            description: 'Esta opción habilita la creación de los tratamientos en el sistema',
            label: 'Habilitar creación de tratamientos',
          },
          crmProgramEditor: {
            title: 'Editar tratamientos',
            description: 'Esta opción habilita la edición de los tratamientos en el sistema',
            label: 'Habilitar edición de tratamientos',
          },
          crmProgramDestructor: {
            title: 'Eliminar tratamientos',
            description: 'Esta opción habilita la eliminación de los tratamientos en el sistema',
            label: 'Habilitar eliminación de tratamientos',
          },
          services: {
            accordion: 'Servicios',
          },
          crmService: {
            title: 'Servicios',
            description: 'Esta opción habilita la visualización de los servicios en el sistema',
            label: 'Habilitar visualización de servicios',
          },
          crmServiceCreator: {
            title: 'Crear servicios',
            description: 'Esta opción habilita la creación de los servicios en el sistema',
            label: 'Habilitar creación de servicios',
          },
          crmServiceEditor: {
            title: 'Editar servicios',
            description: 'Esta opción habilita la edición de los servicios en el sistema',
            label: 'Habilitar edición de servicios',
          },
          crmServiceDestructor: {
            title: 'Eliminar servicios',
            description: 'Esta opción habilita la eliminación de los servicios en el sistema',
            label: 'Habilitar eliminación de servicios',
          },
          techniques: {
            accordion: 'Técnicas',
          },
          crmTechnique: {
            title: 'Técnicas complementarias',
            description:
              'Esta opción habilita la visualización de las técnicas complementarias en el sistema',
            label: 'Habilitar visualización de técnicas complementarias',
          },
          crmTechniqueCreator: {
            title: 'Crear técnicas complementarias',
            description:
              'Esta opción habilita la creación de las técnicas complementarias en el sistema',
            label: 'Habilitar creación de técnicas complementarias',
          },
          crmTechniqueEditor: {
            title: 'Editar técnicas complementarias',
            description:
              'Esta opción habilita la edición de las técnicas complementarias en el sistema',
            label: 'Habilitar edición de técnicas complementarias',
          },
          crmTechniqueDestructor: {
            title: 'Eliminar técnicas complementarias',
            description:
              'Esta opción habilita la eliminación de las técnicas complementarias en el sistema',
            label: 'Habilitar eliminación de técnicas complementarias',
          },
          maintenances: {
            accordion: 'Mantenimientos',
          },
          crmMaintenance: {
            title: 'Mantenimientos',
            description:
              'Esta opción habilita la visualización de los mantenimientos en el sistema',
            label: 'Habilitar visualización de mantenimientos',
          },
          crmMaintenanceCreator: {
            title: 'Crear mantenimientos',
            description: 'Esta opción habilita la creación de los mantenimientos en el sistema',
            label: 'Habilitar creación de mantenimientos',
          },
          crmMaintenanceEditor: {
            title: 'Editar mantenimientos',
            description: 'Esta opción habilita la edición de los mantenimientos en el sistema',
            label: 'Habilitar edición de mantenimientos',
          },
          crmMaintenanceDestructor: {
            title: 'Eliminar mantenimientos',
            description: 'Esta opción habilita la eliminación de los mantenimientos en el sistema',
            label: 'Habilitar eliminación de mantenimientos',
          },
          treatments: {
            accordion: 'Tratamientos',
          },
          crmTreatment: {
            title: 'Tratamientos',
            description: 'Esta opción habilita la visualización de los tratamientos en el sistema',
            label: 'Habilitar visualización de tratamientos',
          },
          crmTreatmentCreator: {
            title: {
              ovoclinic: 'Crear tratamientos para paciente',
              ovobank: 'Crear tratamientos para donante',
            },
            description: {
              ovoclinic:
                'Esta opción habilita la creación de los tratamientos para paciente en el sistema',
              ovobank:
                'Esta opción habilita la creación de los tratamientos para donante en el sistema',
            },
            label: {
              ovoclinic: 'Habilitar creación de tratamientos para paciente',
              ovobank: 'Habilitar creación de tratamientos para donante',
            },
          },
          crmTreatmentEditor: {
            title: {
              ovoclinic: 'Editar tratamientos para paciente',
              ovobank: 'Editar tratamientos para donante',
            },
            description: {
              ovoclinic:
                'Esta opción habilita la edición de los tratamientos para paciente en el sistema',
              ovobank:
                'Esta opción habilita la edición de los tratamientos para donante en el sistema',
            },
            label: {
              ovoclinic: 'Habilitar edición de tratamientos para paciente',
              ovobank: 'Habilitar edición de tratamientos para donante',
            },
          },
          crmTreatmentDestructor: {
            title: {
              ovoclinic: 'Eliminar tratamientos para paciente',
              ovobank: 'Eliminar tratamientos para donante',
            },
            description: {
              ovoclinic:
                'Esta opción habilita la eliminación de los tratamientos para paciente en el sistema',
              ovobank:
                'Esta opción habilita la eliminación de los tratamientos para donante en el sistema',
            },
            label: {
              ovoclinic: 'Habilitar eliminación de tratamientos para paciente',
              ovobank: 'Habilitar eliminación de tratamientos para donante',
            },
          },
          phases: {
            accordion: 'Etapas',
          },
          crmPhase: {
            title: 'Etapas',
            description: 'Esta opción habilita la visualización de las etapas en el sistema',
            label: 'Habilitar visualización de etapas',
          },
          crmPhaseCreator: {
            title: 'Crear etapas',
            description: 'Esta opción habilita la creación de las etapas en el sistema',
            label: 'Habilitar creación de etapas',
          },
          crmPhaseEditor: {
            title: 'Editar etapas',
            description: 'Esta opción habilita la edición de las etapas en el sistema',
            label: 'Habilitar edición de etapas',
          },
          crmPhaseDestructor: {
            title: 'Eliminar etapas',
            description: 'Esta opción habilita la eliminación de las etapas en el sistema',
            label: 'Habilitar eliminación de etapas',
          },
          causes: {
            accordion: 'Motivos',
          },
          crmCause: {
            title: 'Motivos de cancelación',
            description:
              'Esta opción habilita la visualización de los motivos de cancelación en el sistema',
            label: 'Habilitar visualización de motivos de cancelación',
          },
          crmCauseCreator: {
            title: 'Crear motivos de cancelación',
            description:
              'Esta opción habilita la creación de los motivos de cancelación en el sistema',
            label: 'Habilitar creación de motivos de cancelación',
          },
          crmCauseEditor: {
            title: 'Editar motivos de cancelación',
            description:
              'Esta opción habilita la edición de los motivos de cancelación en el sistema',
            label: 'Habilitar edición de motivos de cancelación',
          },
          crmCauseDestructor: {
            title: 'Eliminar motivos de cancelación',
            description:
              'Esta opción habilita la eliminación de los motivos de cancelación en el sistema',
            label: 'Habilitar eliminación de motivos de cancelación',
          },
          cycles: {
            accordion: 'Ciclos de tratamiento',
          },
          crmCycle: {
            title: 'Ciclos de tratamiento',
            description:
              'Esta opción habilita la visualización de los ciclos de tratamiento en el sistema',
            label: 'Habilitar visualización de ciclos de tratamiento',
          },
          crmCycleCreator: {
            title: 'Crear ciclos de tratamiento',
            description:
              'Esta opción habilita la creación de los ciclos de tratamiento en el sistema',
            label: 'Habilitar creación de ciclos de tratamiento',
          },
          crmCycleEditor: {
            title: 'Editar ciclos de tratamiento',
            description:
              'Esta opción habilita la edición de los ciclos de tratamiento en el sistema',
            label: 'Habilitar edición de ciclos de tratamiento',
          },
          crmCycleDestructor: {
            title: 'Eliminar ciclos de tratamiento',
            description:
              'Esta opción habilita la eliminación de los ciclos de tratamiento en el sistema',
            label: 'Habilitar eliminación de ciclos de tratamiento',
          },
          clinic: {
            accordion: 'OVOClinic',
            title: 'OVOClinic',
            description: 'Esta opción habilita el acceso al módulo OVOClinic',
            label: 'Habilitar acceso',
          },
          board: {
            accordion: 'OVOBoard',
            title: 'OVOBoard',
            description: 'Esta opción habilita el acceso al módulo OVOBoard',
            label: 'Habilitar acceso',
          },
          boardLeadsResume: {
            title: 'Resumen de leads',
            label: 'Habilitar resumen de leads',
          },
          boardSaleResume: {
            title: 'Resumen de ventas',
            label: 'Habilitar resumen de ventas',
          },
          boardSaleFunnel: {
            title: 'Embudo de ventas',
            label: 'Habilitar embudo de ventas',
          },
          boardExpectedIntervention: {
            title: 'Previsión de tratamientos',
            label: 'Habilitar previsión de tratamientos',
          },
          boardCoworkerPerformance: {
            title: 'Rendimiento de empleados',
            label: 'Habilitar rendimiento de empleados',
          },
          boardMarketing: {
            title: 'Marketing',
            label: 'Habilitar marketing',
          },
          tory: {
            accordion: 'OVOTory',
            title: 'OVOTory',
            description: 'Esta opción habilita el acceso al módulo OVOTory',
            label: 'Habilitar acceso',
          },
          profile: {
            accordion: 'OVOProfile',
            title: 'OVOProfile',
            description: 'Esta opción habilita el acceso al módulo OVOProfile',
            label: 'Habilitar acceso',
          },
          status: {
            title: 'Rol activo',
            description: 'Habilitar o deshabilitar el rol en el sistema',
            label: 'Rol activo',
          },
          submit: 'Actualizar rol',
        },
      },
      delete: {
        title: 'Eliminar rol',
        subtitle: 'Al eliminar este elemento se borrará del sistema. Esta acción es irreversible.',
        form: {
          status: {
            title: '¿Estás seguro de que deseas eliminarlo?',
            description: 'Tal vez prefieras desactivar este rol',
            label: 'Sí, deseo eliminar este rol',
          },
          submit: 'Eliminar rol',
        },
      },
      enable: {
        title: 'Habilitar rol',
        subtitle: 'Esta acción permite que el rol esté activo en el sistema',
        form: {
          status: {
            title: '¿Estás seguro de que deseas habilitarlo?',
            description: 'Esta acción permitirá que el rol esté activo en el sistema',
            label: 'Sí, deseo habilitar este rol',
          },
          submit: 'Habilitar rol',
        },
      },
      disable: {
        title: 'Deshabilitar rol',
        subtitle:
          'El elemento no estará disponible en el CRM y solo las personas con un permiso específico podrán visualizar los elementos inactivos.',
        form: {
          status: {
            title: '¿Estás seguro de que deseas deshabilitarlo?',
            description: 'Esta acción deshabilitará el rol en el sistema',
            label: 'Sí, deseo deshabilitar este rol',
          },
          submit: 'Deshabilitar rol',
        },
      },
    },
    clinics: {
      list: {
        title: 'Clínicas',
        description:
          'Representan las diferentes sedes de Ovoclinic donde se gestionan los leads y los pacientes.',
        create: 'Clínica',
        one: 'Clínica',
        many: 'Clínicas',
        columns: {
          name: 'Nombre',
          city: 'Ciudad',
          document: 'CIF',
        },
      },
      create: {
        title: 'Nueva clínica',
        subtitle: 'Registra una nueva sede en el sistema incluyendo su información relevante',
        form: {
          name: {
            title: 'Nombre',
            description: 'Nombre identificativo de la clínica',
          },
          country: {
            title: 'País',
            description: 'País donde se encuentra ubicada clínica',
          },
          state: {
            title: 'Provincia',
            description: 'Provincia en la que se encuentra la clínica.',
          },
          city: {
            title: 'Ciudad',
            description: 'Ciudad donde está ubicada la clínica',
          },
          street: {
            title: 'Calle',
            description: 'Calle donde está ubicada la clínica',
          },
          zip: {
            title: 'Código postal',
            description: 'Código postal donde se encuentra la clínica',
          },
          timezone: {
            title: 'Zona horaria',
            description: 'Zona horaria donde se encuentra clínica',
          },
          center: {
            title: 'Ubicación',
            description: 'Ubicación en el mapa donde está ubicada la clínica',
          },
          status: {
            title: 'Clínica activa',
            description: {
              ovoclinic:
                'Habilitar o deshabilitar la clínica en el sistema para el uso de los pacientes',
              ovobank:
                'Habilitar o deshabilitar la clínica en el sistema para el uso de los donantes',
            },
            label: 'Clínica activa',
          },
          avatar: {
            title: 'Avatar',
            description:
              'Imagen / Avatar asociada de la clínica. Puede ayudar a identificar la clínica.',
          },
          document: {
            title: 'Documento',
            description: 'CIF identificativo de la clínica',
          },
          title: {
            title: 'Título',
            description: 'Introduce el nombre de la clínica para facilitar su identificación',
          },
          description: {
            title: 'Descripción',
            description: 'Introduce una breve descripción que ayude a distinguir la clínica',
          },
          color: {
            title: 'Color',
            description:
              'Color asociado a la clínica en el sistema. Este color ayudará a distinguirlo en el lsitado de clínicas.',
          },
          submit: 'Crear clínica',
        },
      },
      update: {
        title: 'Actualizar clínica',
        subtitle:
          'Modifica la información de una clínica. Los cambios realizados se reflejarán en todas las relaciones y datos vinculados a esa clínica en el sistema.',
        tabs: {
          details: {
            title: 'Detalles',
            description:
              'Permite actualizar la información básica de la clínica, como su nombre, dirección y país, que se ingresó al momento de su creación.',
          },
          departments: {
            title: 'Departamentos',
            description:
              'Son áreas dentro de cada clínica, definidas por el idioma hablado. Cada clínica puede tener diferentes departamentos, los cuales se completan con sus empleados y responsables. Los departamentos son necesaros para distribuir la gestión de los leads y pacientes en la clínica.',
            add: 'Añadir departamento',
          },
        },
        form: {
          name: {
            title: 'Nombre',
            description: 'Nombre identificativo de la clínica',
          },
          country: {
            title: 'País',
            description: 'País donde está ubicada clínica',
          },
          state: {
            title: 'Provincia',
            description: 'Provincia en la que se encuentra la clínica',
          },
          city: {
            title: 'Ciudad',
            description: 'Ciudad donde está ubicada la clínica',
          },
          street: {
            title: 'Calle',
            description: 'Calle donde está ubicada la clínica',
          },
          zip: {
            title: 'Código postal',
            description: 'Código postal de la clínica',
          },
          timezone: {
            title: 'Zona horaria',
            description: 'Zona horaria donde se encuentra clínica',
          },
          center: {
            title: 'Ubicación',
            description: 'Ubicación en el mapa',
          },
          status: {
            title: {
              ovoclinic: 'Clínica activa',
              ovobank: 'Clínica activa',
            },
            description: {
              ovoclinic:
                'Habilitar o deshabilitar la clínica en el sistema para el uso de los pacientes',
              ovobank:
                'Habilitar o deshabilitar la clínica en el sistema para el uso de los donantes',
            },
            label: {
              ovoclinic: 'Clínica activa',
              ovobank: 'Clínica activa',
            },
          },
          avatar: {
            title: 'Avatar',
            description:
              'Imagen / Avatar asociada de la clínica. Puede ayudar a identificar la clínica.',
          },
          document: {
            title: 'Documento',
            description: 'CIF identificativo de la clínica',
          },
          title: {
            title: 'Título',
            description: 'TIntroduce el nombre de la clínica para facilitar su identificación',
          },
          description: {
            title: 'Descripción',
            description: 'Introduce una breve descripción que ayude a distinguir la clínica',
          },
          color: {
            title: 'Color',
            description:
              'Color asociado a la clínica en el sistema. Este color ayudará a distinguirlo en el lsitado de clínicas.',
          },
          submit: 'Actualizar clínica',
        },
      },
      disable: {
        title: 'Deshabilitar clínica',
        subtitle:
          'El elemento no estará disponible en el CRM y solo las personas con un permiso específico podrán visualizar los elementos inactivos.',
        form: {
          status: {
            title: '¿Estás seguro de que deseas deshabilitar esta clinica?',
            description:
              'Deshabilitar una clínica significa que no podrá ser utilizada en el sistema',
            label: 'Sí, deseo deshabilitar esta clínica',
          },
          submit: 'Deshabilitar clínica',
        },
      },
      enable: {
        title: 'Habilitar clínica',
        subtitle: 'Habilitar clínica en el sistema',
        form: {
          status: {
            title: '¿Estás seguro de que deseas habilitar esta clinica?',
            description: 'Habilitar una clínica significa que podrá ser utilizada en el sistema',
            label: 'Sí, deseo habilitar esta clínica',
          },
          submit: 'Habilitar clínica',
        },
      },
      delete: {
        title: 'Deshabilitar clínica',
        subtitle:
          ' El elemento no estará disponible en el CRM y solo las personas con un permiso específico podrán visualizar los elementos inactivos.',
        button: 'Deshabilitar',
        form: {
          status: {
            title: '¿Estás seguro de que deseas deshabilitar esta clinica?',
            description:
              'Deshabilitar una clínica significa que no podrá ser utilizada en el sistema',
            label: 'Sí, deseo deshabilitar esta clínica',
          },
          submit: 'Deshabilitar clínica',
        },
        passwordForm: {
          title: 'Introduce tu contraseña',
          subtitle: 'Para deshabilitar la clínica, introduce tu contraseña',
          password: {
            title: 'Contraseña',
            description: 'Contraseña del usuario que realiza la acción',
          },
          submit: 'Confirmar',
        },
      },
    },
    licenses: {
      list: {
        one: 'Licencia',
        many: 'Licencias',
      },
    },
    movements: {
      list: {
        one: 'Movimiento',
        many: 'Movimientos',
      },
    },
    attempts: {
      list: {
        one: 'Intento',
        many: 'Intentos',
      },
    },
    contracts: {
      list: {
        one: 'Contrato',
        many: 'Contratos',
      },
    },
    timetables: {
      list: {
        title: 'Horarios',
        description:
          'Sección donde se predefinen los días y horas de disponibilidad, que luego se pueden asignar a los usuarios para gestionar su jornada laboral.',
        create: 'Horario',
        one: 'Horario',
        many: 'Horarios',
        columns: {
          name: 'Nombre',
          day: 'Día',
          range: 'Rango',
        },
      },
      create: {
        title: 'Nuevo horario',
        subtitle: 'crear un horario predefinido, especificando los días y horas',
        form: {
          name: {
            title: 'Nombre',
            description:
              'Es el nombre que se asigna al horario para distinguirlo y facilitar su selección al asociarlo a un usuario',
          },
          day: {
            title: 'Día',
            description: 'Día de la semana en el que se aplica el horario',
          },
          started: {
            title: 'Inicio',
            description: 'Hora de inicio del horario',
          },
          ended: {
            title: 'Fin',
            description: 'Hora de fin del horario',
          },
          submit: 'Crear horario',
        },
      },
      update: {
        title: 'Actualizar horario',
        subtitle:
          'Actualiza los detalles de un horario predefinido, como los días y las horas de disponibilidad',
        form: {
          name: {
            title: 'Nombre',
            description:
              'Es el nombre que se asigna al horario para distinguirlo y facilitar su selección al asociarlo a un usuario',
          },
          submit: 'Actualizar horario',
        },
        tabs: {
          timetable: {
            title: 'Horario',
            description:
              'Es el título que se asigna a un horario para poder reconocerlo fácilmente al asociarlo con un usuario, como "Jornada completa" o "Turno mañana".',
          },
          timetableDay: {
            title: 'Día',
            description: 'Elige los días específicos en los que se aplicará el horario',
          },
        },
        timetableDays: {
          create: {
            title: 'Añadir día',
            subtitle: 'Añade un día específico en el horario',
            form: {
              day: {
                title: 'Día',
                description: 'Día de la semana en el que se aplica el horario',
              },
              started: {
                title: 'Inicio',
                description: 'Hora de inicio del horario',
              },
              ended: {
                title: 'Fin',
                description: 'Hora de fin del horario',
              },
              submit: 'Añadir día',
            },
          },
          update: {
            title: 'Actualizar día',
            subtitle: 'Modificación de los datos de un día en el horario',
            form: {
              day: {
                title: 'Día',
                description: 'Día de la semana en el que se aplica el horario',
              },
              started: {
                title: 'Inicio',
                description: 'Hora de inicio del horario',
              },
              ended: {
                title: 'Fin',
                description: 'Hora de fin del horario',
              },
              submit: 'Actualizar día',
            },
          },
          delete: {
            title: 'Eliminar día',
            subtitle:
              'Al eliminar este elemento se borrará del sistema. Esta acción es irreversible.',
            form: {
              status: {
                title: '¿Estás seguro de que deseas eliminar este día?',
                label: 'Sí, deseo eliminar este día',
              },
              submit: 'Eliminar día',
            },
          },
          add: 'Añadir día',
        },
      },
      delete: {
        title: 'Eliminar horario',
        subtitle:
          ' El elemento no estará disponible en el CRM y solo las personas con un permiso específico podrán visualizar los elementos inactivos.',
        form: {
          status: {
            title: '¿Estás seguro de que deseas deshabilitar este horario?',
            description:
              'Deshabilitar un horario significa que no podrá ser utilizado en el sistema',
            label: 'Sí, deseo deshabilitar este horario',
          },
          submit: 'Deshabilitar horario',
        },
      },
      disable: {
        title: 'Deshabilitar horario',
        subtitle:
          'El elemento no estará disponible en el CRM y solo las personas con un permiso específico podrán visualizar los elementos inactivos.',
        form: {
          status: {
            title: '¿Estás seguro de que deseas deshabilitar este horario?',
            description:
              'Deshabilitar un horario significa que no podrá ser utilizado en el sistema',
            label: 'Sí, deseo deshabilitar este horario',
          },
          submit: 'Deshabilitar horario',
        },
      },
      enable: {
        title: 'Habilitar horario',
        subtitle: 'Habilitar horario en el sistema',
        form: {
          status: {
            title: '¿Estás seguro de que deseas habilitar este horario?',
            description: 'Habilitar un horario significa que podrá ser utilizado en el sistema',
            label: 'Sí, deseo habilitar este horario',
          },
          submit: 'Habilitar horario',
        },
      },
    },
    departments: {
      list: {
        title: 'Departamentos',
        description:
          'Áreas dentro de cada clínica organizadas según el idioma hablado. Cada departamento incluye empleados y responsables, y se utiliza para asignar y gestionar leads',
        create: 'Departamento',
        one: 'Departamento',
        many: 'Departamentos',
        columns: {
          name: 'Nombre',
          responsible: 'Responsable',
          language: 'Idioma',
          country: 'País',
          clinic: 'Clínica',
          created: 'Creación',
        },
      },
      create: {
        title: 'Nuevo departamento',
        subtitle:
          'Creación de una nueva área dentro de una clínica, definida por un nombre identificativo, el idioma hablado y un responsable asignado. Posteriormente, se podrán añadir los empleados que conformarán ese departamento.',
        form: {
          name: {
            title: 'Nombre',
            description: 'Nombre identificativo del departamento',
          },
          clinicId: {
            title: 'Clínica',
            description: 'Clínica a la que pertenece el departamento',
          },
          status: {
            title: 'Departamento activo',
            description: 'Habilitar o deshabilitar el departamento en el sistema',
            label: 'Departamento activo',
          },
          languageId: {
            title: 'Idioma',
            description:
              'Este campo define el idioma del departamento, lo cual es clave para distinguir los departamentos y automatizar la distribución de leads desde las campañas al departamento correspondiente según el idioma.',
          },
          responsibleId: {
            title: 'Responsable',
            description:
              'Asigna un responsable al departamento. El responsable será el ecargado de gestionar o distribuir los leads que lleguen a este departamento.',
          },
          submit: 'Crear',
        },
      },
      enable: {
        title: 'Habilitar departamento',
        subtitle: 'Habilitar departamento en el sistema',
        form: {
          status: {
            title: '¿Estás seguro de que deseas habilitar este departamento?',
            label: 'Sí, deseo habilitar este departamento',
          },
          submit: 'Habilitar departamento',
        },
      },
      disable: {
        title: 'Deshabilitar departamento',
        subtitle:
          'El elemento no estará disponible en el CRM y solo las personas con un permiso específico podrán visualizar los elementos inactivos.',
        form: {
          status: {
            title: '¿Estás seguro de que deseas deshabilitar este departamento?',
            label: 'Sí, deseo deshabilitar este departamento',
          },
          submit: 'Deshabilitar departamento',
        },
      },
      update: {
        title: 'Actualizar departamento',
        subtitle:
          'Actualiza los datos básicos del departamento como su nombre, idioma y responsable, y añade o elimina miembros del departamento.',
        department: {
          tab: 'Departamento',
          description:
            'Detalles generales que definen el departamento con datos como el nombre, el idioma y el responsable.',
        },
        clinic: {
          tab: 'Clínica',
          description: 'Clínica a la que pertenece el departamento',
        },
        responsible: {
          tab: 'Responsable',
          description:
            'Asigna un responsable al departamento. El responsable será el ecargado de gestionar o distribuir los leads que lleguen a este departamento.',
        },
        language: {
          tab: 'Idioma',
          description:
            'Este campo define el idioma del departamento, lo cual es clave para distinguir los departamentos y automatizar la distribución de leads desde las campañas al departamento correspondiente según el idioma.',
        },
        tabs: {
          department: {
            title: 'Departamento',
            description: 'Detalles del departamento',
          },
          members: {
            title: 'Miembros',
            description:
              'Sección donde se gestionan los empleados que forman parte de un departamento. Aquí se pueden añadir, eliminar o modificar los miembros asignados a cada área de la clínica.',
          },
        },
        form: {
          name: {
            title: 'Nombre',
            description: 'Nombre identificativo del departamento',
          },
          languageId: {
            title: 'Idioma',
            description:
              'Este campo define el idioma del departamento, lo cual es clave para distinguir los departamentos y automatizar la distribución de leads desde las campañas al departamento correspondiente según el idioma.',
          },
          responsibleId: {
            title: 'Responsable',
            description:
              'Asigna un responsable al departamento. El responsable será el ecargado de gestionar o distribuir los leads que lleguen a este departamento.',
          },
          clinicId: {
            title: 'Clínica',
            description: 'Clínica a la que pertenece el departamento',
          },
          status: {
            title: 'Departamento activo',
            description: 'Habilitar o deshabilitar el departamento en el sistema',
            label: 'Departamento activo',
          },
          submit: 'Actualizar departamento',
        },
        members: {
          one: 'Miembro',
          many: 'Miembros',
          add: 'Añadir miembro',
          create: {
            title: 'Añadir miembro',
            subtitle:
              'Sección donde se gestionan los empleados que forman parte de un departamento. Aquí se pueden añadir, eliminar o modificar los miembros asignados a cada área de la clínica.',
            form: {
              contractId: {
                title: 'Usuario',
                description:
                  'Campo para seleccionar el usuario que pertenece o trabaja en el departamento',
              },
              submit: 'Añadir miembro',
            },
          },
        },
      },
    },
    members: {
      delete: {
        title: 'Eliminar miembro',
        subtitle: 'Al eliminar este elemento se borrará del sistema. Esta acción es irreversible.',
        form: {
          submit: 'Eliminar miembro',
        },
      },
    },
    countries: {
      list: {
        title: 'Países',
        description:
          'Sección para registrar y gestionar los países que estarán disponibles para ser seleccionados al crear o modificar un idioma en el sistema.',
        create: 'País',
        one: 'País',
        many: 'Países',
        columns: {
          name: 'Nombre',
          code: 'Código',
          coin: 'Moneda',
          updated: 'Actualización',
          created: 'Creación',
        },
      },
      update: {
        title: 'Editar país',
        subtitle: 'Sección para modificar los datos registrados del país.',
        form: {
          countryNameEn: {
            title: 'Nombre en inglés',
            description: 'Nombre en inglés del país',
          },
          countryNameEs: {
            title: 'Nombre en español',
            description: 'Nombre en español del país',
          },
          flag: {
            title: 'Bandera',
            description:
              'Bandera del país representada con un emoticono. Al hacer click con la derecha se muestra la opción de añadir emoticonos. Si la opción no aparece se puede copiar y pegar un emoticono desde una busqueda en un explorador como "google".',
          },
          coin: {
            title: 'Moneda',
            description: 'Campo para registrar la moneda oficial de cada país',
          },
          code: {
            title: 'Código de país',
            description: 'Código de país representado en 2 carácteres (ISO 3166-1 alpha-2)',
          },
          prefix: {
            title: 'Prefijo',
            description: 'Campo para registrar el código telefónico internacional del país',
          },
          center: {
            title: 'Ubicación',
            description: 'Ubicación del país',
          },
          geometry: {
            title: 'Geometría en formato json',
            description:
              'Campo que permite agregar información geoespacial en formato GeoJSON. Este campo permite integrar coordenadas geográficas y otros atributos relacionados con la ubicación en el sistema.',
          },
          latitude: {
            title: 'Coordenada latitud',
            description: 'Coordenada latitud del país',
          },
          longitude: {
            title: 'Coordenada longitud',
            description: 'Coordenada longitud del país',
          },
          description: {
            title: 'Descripción',
            description:
              'Campo para proporcionar información adicional sobre el país seleccionado. Esta descripción puede ser utilizada para ofrecer contexto adicional.',
          },
          color: {
            title: 'Color',
            description:
              'El color seleccionado servirá para distinguir más facilmente los países en el listado',
          },
          submit: 'Guardar País',
        },
      },
      delete: {
        title: 'Eliminar país',
        subtitle: 'Al eliminar este elemento se borrará del sistema. Esta acción es irreversible.',
        form: {
          submit: 'Borrar país',
        },
      },
      disable: {
        title: 'Deshabilitar país',
        subtitle:
          'El elemento no estará disponible en el CRM y solo las personas con un permiso específico podrán visualizar los elementos inactivos.',
        form: {
          submit: 'Deshabilitar país',
        },
      },
      enable: {
        title: 'Habilitar país',
        subtitle: 'Habilitar país en el sistema',
        form: {
          submit: 'Habilitar país',
        },
      },
      create: {
        title: 'Nuevo país',
        subtitle:
          'Registra un nuevo país en el sistema incluyendo los datos necesarios para su identificación y uso',
        form: {
          countryNameEn: {
            title: 'Nombre en inglés',
            description: 'Nombre en inglés del país',
          },
          countryNameEs: {
            title: 'Nombre en español',
            description: 'Nombre en español del país',
          },
          flag: {
            title: 'Bandera',
            description:
              'Bandera del país representada con un emoticono. Al hacer click con la derecha se muestra la opción de añadir emoticonos. Si la opción no aparece se puede copiar y pegar un emoticono desde una busqueda en un explorador como "google".',
          },
          coin: {
            title: 'Moneda',
            description: 'Campo para registrar la moneda oficial de cada país',
          },
          code: {
            title: 'Código de país',
            description: 'Código de país representado en 2 carácteres (ISO 3166-1 alpha-2)',
          },
          prefix: {
            title: 'Prefijo',
            description: 'Campo para registrar el código telefónico internacional del país',
          },
          center: {
            title: 'Ubicación',
            description: 'Ubicación del país',
          },
          geometry: {
            title: 'Geometría en formato json',
            description:
              'Campo que permite agregar información geoespacial en formato GeoJSON. Este campo permite integrar coordenadas geográficas y otros atributos relacionados con la ubicación en el sistema.',
          },
          latitude: {
            title: 'Coordenada latitud',
            description: 'Coordenada latitud del país',
          },
          longitude: {
            title: 'Coordenada longitud',
            description: 'Coordenada longitud del país',
          },
          description: {
            title: 'Descripción',
            description:
              'Campo para proporcionar información adicional sobre el país seleccionado. Esta descripción puede ser utilizada para ofrecer contexto adicional.',
          },
          color: {
            title: 'Color',
            description:
              'El color seleccionado servirá para distinguir más facilmente los países en el listado',
          },
          submit: 'Crear País',
        },
      },
    },
    access: {
      create: {
        title: 'Nuevo acceso',
        subtitle: 'Registro de un nuevo acceso en el sistema',
        form: {
          ip: {
            title: 'Dirección IP',
            description: 'Dirección IP del dispositivo que accede al sistema',
          },
          status: {
            title: 'Acceso al sistema',
            description:
              'Habilitar o deshabilitar el acceso al sistema para este usuario y esta ip',
            label: 'Permitir acceso al sistema',
          },
          submit: 'Crear acceso',
        },
      },
    },
    settings: {
      title: 'Configuración',
      subtitle: 'Las configuraciones realizadas en está sección afectarán a toda la plataforma.',
      form: {
        loginEnabled: {
          title: 'Habilitar acceso',
          description:
            'Esta configuración habilita el acceso a los usuarios al sistema. Se puede personalizar el acceso idependientemente en la edición de usuarios.',
          label: 'Activar el permiso de acceso',
        },
        attemptLimitEnabled: {
          title: 'Habilitar límite de intentos',
          description:
            'Si se habilita el límite de intentos de inicio de sesión, el usuario será bloqueado si realiza más intentos de los permitidos para el usuario. Se puede configurar el número de intentos permitidos en la configuración de usuario.',
          label: 'Activar el límite de intentos',
        },
        movementLimitEnabled: {
          title: 'Habilitar límite de movimientos',
          description:
            'Si se habilita el límite de movimientos, el usuario será bloqueado si realiza más movimientos de los permitidos para el usuario. Se puede configurar el número de movimientos permitidos en la configuración de usuario.',
          label: 'Activar el límite de movimientos',
        },
        submit: 'Guardar configuración',
      },
    },
  },
  crm: {
    menu: {
      activity: 'Actividades',
      contacts: 'Contactos',
      marketing: 'Marketing',
      sales: 'Ventas',
      directory: 'Directorio',
      customers: 'Clientes',
      commercial: 'Comercial',
      campaigns: 'Campañas de marketing',
      funnels: 'Embudos',
      channels: 'Canales',
      leads: 'Leads',
      transferLead: 'Transferencia de leads',
      patients: {
        ovoclinic: 'Pacientes',
        ovobank: 'Donantes',
      },
      programs: 'Programas',
      maintenances: 'Mantenimientos',
      tasks: 'Tareas',
      transferTask: 'Transferencia de tareas',
      createTaskMassively: 'Asignacion multiple de taeas',
      actions: 'Calendario',
      origins: 'Orígenes',
      techniques: 'Técnicas',
      services: 'Servicios',
      activities: 'Actividades',
      results: 'Resultados',
      reasons: 'Motivos',
      products: 'Productos',
      categories: 'Categorías',
      dashboard: 'Dashboard',
      catalog: 'Catálogo',
      phases: 'Etapas',
      causes: 'Causas',
      treatments: 'Tratamientos',
      cycles: 'Ciclos',
    },
    faq: {
      title: 'FAQ',
      description: 'Preguntas frecuentes',

      list: {
        1: {
          question: '¿Cómo puedo crear un lead?',
          answer: 'Para crear un lead, sigue los siguientes pasos:',
        },
        2: {
          question: '¿Cómo puedo crear una campaña?',
          answer: 'Para crear una campaña, sigue los siguientes pasos:',
        },
        3: {
          question: '¿Cómo puedo crear una tarea?',
          answer: 'Para crear una tarea, sigue los siguientes pasos:',
        },
        4: {
          question: '¿Cómo puedo transferir un lead?',
          answer: 'Para transferir un lead, sigue los siguientes pasos:',
        },
      },
    },
    information: {
      list: {
        0: {
          title: 'Flujo de trabajo del departamento NACIONAL',
          sublists: {
            0: {
              title: 'Call Center',
            },
            1: {
              title: 'Atención al paciente Comercial',
            },
            2: {
              title: 'Atención al paciente Médico',
            },
          },
        },
        1: {
          title: 'Flujo de trabajo del departamento INTERNACIONAL',
        },
      },
    },
    dashboard: {
      title: 'Dashboard',
      description: 'Panel de control de tu actividad',
      actions: {
        title: 'Tareas',
        description: {
          ovoclinic:
            'Representan las actividades o acciones específicas que deben realizarse dentro del proceso de gestión del lead o paciente. Se utilizan para organizar, asignar y realizar un seguimiento de las acciones necesarias, como llamadas, visitas o tareas.',
          ovobank:
            'Representan las actividades o acciones específicas que deben realizarse dentro del proceso de gestión del lead o paciente. Se utilizan para organizar, asignar y realizar un seguimiento de las acciones necesarias, como llamadas, visitas o tareas.',
        },
      },
      receipts: {
        title: 'Últimos recibos',
        description: 'Pagos realizados a tus leads',
        list: {
          columns: {
            contactName: 'Nombre',
            payments: 'Pagos',
            updated: 'F. actualización',
          },
          many: 'Recibos',
          one: 'Recibo',
        },
      },
      leads: {
        title: {
          ovoclinic: 'Leads',
          ovobank: 'Leads',
        },
        description: {
          ovoclinic:
            'Personas interesadas en los servicios de la clínica, registradas en el sistema para organizar y dar seguimiento a cada paso, desde el primer contacto hasta ser pacientes.',
          ovobank:
            'Personas interesadas en ser donantes, registradas en el sistema para organizar y dar seguimiento en el proceso de evaluación y selección de donantes.',
        },
      },
      campaigns: {
        title: {
          ovoclinic: 'Campañas de marketing',
          ovobank: 'Campañas de marketing',
        },
        description: {
          ovoclinic:
            'Acciones promocionales realizadas a través de diferentes canales como redes sociales o webs, por donde ingresan los leads interesados en los servicios de la clínica.',
          ovobank:
            'Acciones promocionales realizadas a través de diferentes canales como redes sociales o webs, por donde ingresan los leads interesados en ser donantes.',
        },
      },
    },
    transferLead: {
      list: {
        title: 'Transferencia de leads',
        description: 'Transferir campañas de marketing de los leads',
        create: 'Lead',
        one: 'Lead',
        many: 'Leads',
        required: 'Debes seleccionar al menos un lead',
        columns: {
          name: 'Nombre',
          activity: 'Actividad',
          responsible: 'Responsable',
          campaignName: 'Campaña',
          started: 'Fecha estimada',
          clinicName: 'Clínica',
          created: 'Creación',
          resultId: 'Progreso',
          phones: 'Teléfono',
          emails: 'Email',
        },
      },
      confirm: {
        title: 'Transferir leads',
        subtitle: 'Reasignar campaña a multiples leads',
        success: 'Leads transferidos correctamente',
        steps: {
          campaign: {
            form: 'Campaña seleccionada',
          },
        },
        form: {
          campaignId: {
            title: 'Seleccionar camapaña',
            description: 'Campaña a la que se van a reasignar las leads',
          },
          responsibleId: {
            title: 'Seleccionar responsable',
            description: 'Responsable a la que van a reasignar las leads al cambiar de campaña',
          },
          submit: 'Transferir',
        },
      },
    },
    transferTask: {
      list: {
        title: {
          ovoclinic: 'Transferencia de tareas',
          ovobank: 'Transferencia de tareas',
        },
        description: {
          ovoclinic:
            'Pantalla donde se seleccionan varias tareas y se asignan a otro responsable para su gestión y seguimiento.',
          ovobank:
            'Pantalla donde se seleccionan varias tareas y se asignan a otro responsable para su gestión y seguimiento.',
        },
        create: 'Tarea',
        one: 'Tarea',
        many: 'Tareas',
        columns: {
          activity: 'Actividad',
          phone: 'Teléfono',
          contact: 'Contacto',
          subject: 'Asunto',
          resultId: 'Progreso',
          campaign: 'Campaña',
          clinic: 'Clínica',
          contactTime: 'Preferencia',
          created: 'Creación',
          user: 'Responsable',
          started: 'Fecha de inicio estimada',
          finished: 'Duración',
          resultName: 'Resultado',
        },
      },
      confirm: {
        title: 'Transferencia de tareas',
        subtitle:
          'Asigna varias tareas a otro responsable para que las gestione y haga el seguimiento.',
        success: 'Tareas transferidas correctamente',
        form: {
          responsibleId: {
            title: 'Responsable',
            description:
              'Persona a quien se le asignarán las tareas seleccionadas. Estas se mostrarán en su listado de tareas. ',
          },
          submit: 'Transferir',
        },
      },
    },
    createTaskMassively: {
      list: {
        title: 'Asignación de tareas a multiples leads',
        description:
          'Pantalla que permite crear y asignar la misma tarea a múltiples leads al mismo tiempo',
        columns: {
          name: 'Nombre',
          clinicName: 'Clínica',
          campaignName: 'Campaña',
          phones: 'Teléfono',
          emails: 'Email',
          created: 'Creación',
          lastAction: 'Última tarea',
          subject: 'Asunto',
          lastActionDate: 'Fecha tarea pendiente',
        },
        noItemSelected: 'No hay ningún lead seleccionado',
        one: 'Lead',
        many: 'Leads',
      },
      selectActivity: {
        title: 'Tarea a realizar',
        subtitle:
          'Elige la actividad que deseas asignar a los leads seleccionados, como una llamada, visita o tarea.',
        form: {
          activityId: {
            title: 'Actividad',
            description:
              'Elige la acción que se asignará a los leads seleccionados, como una llamada, visita o tarea.',
            required: 'Debes seleccionar una actividad',
          },
          subject: {
            title: 'Asunto',
            description:
              'Escribe un título corto que describa de qué trata la tarea. Este título se mostrará junto a todas las tareas creadas para los leads seleccionados.',
          },
          submit: 'Crear tareas',
        },
      },
    },
    tasks: {
      list: {
        title: {
          ovoclinic: 'Tareas',
          ovobank: 'Tareas',
        },
        description: {
          ovoclinic:
            'Representan las actividades o acciones específicas que deben realizarse dentro del proceso de gestión del lead o paciente. Se utilizan para organizar, asignar y realizar un seguimiento de las acciones necesarias, como llamadas, visitas o tareas.',
          ovobank:
            'Representan las actividades o acciones específicas que deben realizarse dentro del proceso de gestión del lead o paciente. Se utilizan para organizar, asignar y realizar un seguimiento de las acciones necesarias, como llamadas, visitas o tareas.',
        },
        create: {
          ovoclinic: 'Tarea',
          ovobank: 'Tarea',
        },
        one: 'Tarea',
        many: 'Tareas',
        columns: {
          activity: 'Actividad',
          contact: 'Contacto',
          responsible: 'Responsable',
          subject: 'Asunto',
          resultId: 'Progreso',
          resultName: 'Resultado',
          campaign: 'Campaña',
          clinic: 'Clínica',
          contactTime: 'Preferencia',
          created: 'Creación',
          user: 'Responsable',
          started: 'Fecha de inicio estimada',
          finished: 'Duración',
        },
      },
    },
    calendar: {
      actions: {
        create: {
          steps: {
            clinic: {
              title: 'Nueva acción',
              subtitle:
                'Registro de una nueva acción en el sistema. Elige la clínica a la que va dirigida la tarea',
              form: {
                clinicId: {
                  title: 'Clínica',
                  description:
                    'Selecciona la clínica de preferencia del lead. Este campo permite filtrar los leads por la clínica asignada para su seguimiento',
                },
                submit: 'Siguiente',
              },
            },
            lead: {
              title: 'Nueva acción',
              subtitle:
                'Registro de una nueva acción en el sistema. Elige el lead al que va dirigida la tarea',
              form: {
                leadId: {
                  title: 'Lead',
                  description:
                    'Persona interesada en los servicios de la clínica. Al seleccionarlo, la acción queda directamente vinculada a este lead',
                },
                submit: 'Siguiente',
              },
            },
          },
        },
      },
      messages: {
        allDay: 'Todo el día',
        previous: 'Anterior',
        next: 'Siguiente',
        today: 'Hoy',
        month: 'Mes',
        week: 'Semana',
        day: 'Día',
        agenda: 'Agenda',
        date: 'Fecha',
        time: 'Hora',
        event: 'Evento',
      },
    },
    channels: {
      list: {
        title: 'Canales',
        description:
          'Un canal es una vía de captación de leads, como redes sociales, sitios web o anuncios, utilizada para promover los servicios de la clínica mediante campañas de marketing.',
        create: 'Canal',
        one: 'Canal',
        many: 'Canales',
        columns: {
          name: 'Nombre',
          funnels: 'Embudos',
          created: 'Creación',
        },
      },
      create: {
        title: 'Nuevo canal',
        subtitle:
          'El canal es la vía de comunicación por la que ser realizarán las campañas de marketing como páginas webs, redes sociales, etc. Crea un nuevo canal introduciendo el nombre del mismo.',
        form: {
          name: {
            title: 'Nombre',
            description:
              'Introduce un nombre para identificar facilmente el canal de origen del lead',
          },
          status: {
            title: 'Canal activo',
            description: 'Habilitar o deshabilitar el canal en el sistema',
            label: 'Canal activo',
          },
          submit: 'Crear canal',
        },
      },
      update: {
        title: 'Actualizar canal',
        subtitle:
          'Modifica el canal existente ajustando su nombre a la vía de comunicación que se quiera representar.',
        form: {
          name: {
            title: 'Nombre',
            description:
              'Introduce un nombre para identificar facilmente el canal de origen del lead',
          },
          status: {
            title: 'Canal activo',
            description: 'Habilitar o deshabilitar el canal en el sistema',
            label: 'Canal activo',
          },
          submit: 'Actualizar canal',
        },
      },
      disable: {
        title: 'Deshabilitar canal',
        subtitle:
          'El elemento no estará disponible en el CRM y solo las personas con un permiso específico podrán visualizar los elementos inactivos.',
        form: {
          status: {
            title: '¿Estás seguro de que deseas deshabilitar este canal?',
            description: 'Deshabilitar un canal significa que no podrá ser utilizado en el sistema',
            label: 'Sí, deseo deshabilitar este canal',
          },
          submit: 'Deshabilitar canal',
        },
      },
      enable: {
        title: 'Habilitar canal',
        subtitle: 'Habilitar canal en el sistema',
        form: {
          status: {
            title: '¿Estás seguro de que deseas habilitar este canal?',
            description: 'Habilitar un canal significa que podrá ser utilizado en el sistema',
            label: 'Sí, deseo habilitar este canal',
          },
          submit: 'Habilitar canal',
        },
      },
      delete: {
        title: 'Eliminar canal',
        subtitle: 'Al eliminar este elemento se borrará del sistema. Esta acción es irreversible.',
        form: {
          status: {
            title: '¿Estás seguro de que deseas eliminarlo?',
            description: 'Tal vez prefieras desactivar este canal',
            label: 'Sí, deseo eliminar este canal',
          },
          submit: 'Eliminar',
        },
      },
    },
    cycles: {
      list: {
        title: 'Ciclos',
        description:
          'Un ciclo es un intento dentro de un tratamiento. En esta pantalla, puedes ver la lista de ciclos disponibles, crear nuevos ciclos y acceder a los detalles de cada uno para gestionarlos adecuadamente. ',
        create: 'Ciclo',
        one: 'Ciclo',
        many: 'Ciclos',
        columns: {
          name: 'Nombre',
          complexity: 'Complejidad',
          created: 'Fecha de creación',
          code: 'Código',
        },
      },
      create: {
        title: 'Nuevo ciclo',
        subtitle: 'Registro de un nuevo ciclo en el sistema',
        form: {
          name: {
            title: 'Nombre',
            description: 'Nombre identificativo del ciclo',
          },
          complexity: {
            title: 'Complejidad',
            description: 'Nivel de complejidad del ciclo',
          },
          code: {
            title: 'Código identificativo',
            description:
              'Introduce un código único para identificar de manera fácil y rápida el ciclo',
          },
          description: {
            title: 'Descripción',
            description: 'Añade una breve descripción de las caracteristicas de este ciclo',
          },
          submit: 'Crear ciclo',
        },
      },
      update: {
        title: 'Actualizar ciclo',
        subtitle: 'Modificación de los datos de un ciclo en el sistema',
        form: {
          name: {
            title: 'Nombre',
            description: 'Nombre identificativo del ciclo',
          },
          complexity: {
            title: 'Complejidad',
            description: 'Nivel de complejidad del ciclo',
          },
          code: {
            title: 'Código identificativo',
            description:
              'Introduce un código único para identificar de manera fácil y rápida el ciclo',
          },
          description: {
            title: 'Descripción',
            description: 'Añade una breve descripción de las caracteristicas de este ciclo',
          },
          submit: 'Actualizar ciclo',
        },
      },
      enable: {
        title: 'Habilitar ciclo',
        subtitle: 'Habilitar ciclo en el sistema',
        form: {
          status: {
            title: '¿Estás seguro de que deseas habilitar este ciclo?',
            description: 'Habilitar un ciclo significa que podrá ser utilizado en el sistema',
            label: 'Sí, deseo habilitar este ciclo',
          },
          submit: 'Habilitar',
        },
      },
      disable: {
        title: 'Deshabilitar ciclo',
        subtitle:
          'El elemento no estará disponible en el CRM y solo las personas con un permiso específico podrán visualizar los elementos inactivos.',
        form: {
          status: {
            title: '¿Estás seguro de que deseas deshabilitar este ciclo?',
            description: 'Deshabilitar un ciclo significa que no podrá ser utilizado en el sistema',
            label: 'Sí, deseo deshabilitar este ciclo',
          },
          submit: 'Deshabilitar',
        },
      },
    },
    funnels: {
      list: {
        title: 'Embudos',
        description:
          'Los embudos son rutas específicas dentro de los canales de marketing para captar leads, lo que permite una identificación precisa de su origen. En esta pantalla podrás gestionar la creación y edición de los embudos.',
        create: 'Embudo',
        one: 'Embudo',
        many: 'Embudos',
        params: {
          channelId: 'Embudos filtrados por canal de comunicación',
        },
        columns: {
          name: 'Nombre',
          channelName: 'Canal',
          created: 'Creación',
        },
      },
      create: {
        title: 'Nuevo embudo',
        subtitle:
          'En esta pantalla puedes crear un embudo que luego podrás asociar a una campaña en su creación.',
        form: {
          name: {
            title: 'Nombre',
            description:
              'Introduce el nombre del embudo para identificar la procedencia de los leads',
          },
          channelId: {
            title: 'Canal',
            description:
              'Selecciona el canal al que pertenece este embudo (por ejemplo, redes sociales, web, etc.)',
          },
          status: {
            title: 'Embudo activo',
            description: 'Habilitar o deshabilitar el embudo en el sistema',
            label: 'Embudo activo',
          },
          submit: 'Crear embudo',
        },
      },
      update: {
        title: 'Actualizar embudo',
        subtitle: 'Modifica los datos del embudo',
        form: {
          name: {
            title: 'Nombre',
            description:
              'Introduce el nombre del embudo para identificar la procedencia de los leads',
          },
          channelId: {
            title: 'Canal',
            description:
              'Selecciona el canal al que pertenece este embudo (por ejemplo, redes sociales, web, etc.)',
          },
          status: {
            title: 'Embudo activo',
            description: 'Habilitar o deshabilitar el embudo en el sistema',
            label: 'Embudo activo',
          },
          submit: 'Actualizar embudo',
        },
      },
      delete: {
        title: 'Eliminar embudo',
        subtitle: 'Al eliminar este elemento se borrará del sistema. Esta acción es irreversible.',
        form: {
          status: {
            title: '¿Estás seguro de que deseas eliminarlo?',
            description: 'Tal vez prefieras desactivar este embudo',
            label: 'Sí, deseo eliminar este embudo',
          },
          submit: 'Eliminar',
        },
      },
      enable: {
        title: 'Habilitar embudo',
        subtitle: 'Habilitar embudo en el sistema',
        form: {
          status: {
            title: '¿Estás seguro de que deseas habilitar este embudo?',
            description: 'Habilitar un embudo significa que podrá ser utilizado en el sistema',
            label: 'Sí, deseo habilitar este embudo',
          },
          submit: 'Habilitar',
        },
      },
      disable: {
        title: 'Deshabilitar embudo',
        subtitle:
          'El elemento no estará disponible en el CRM y solo las personas con un permiso específico podrán visualizar los elementos inactivos.',
        form: {
          status: {
            title: '¿Estás seguro de que deseas deshabilitar este embudo?',
            description:
              'Deshabilitar un embudo significa que no podrá ser utilizado en el sistema',
            label: 'Sí, deseo deshabilitar este embudo',
          },
          submit: 'Deshabilitar',
        },
      },
    },
    leads: {
      import: {
        title: 'Importación de leads',
        subtitle: 'Importación de leads en el sistema',
        description:
          'Carga de leads en el sistema a través de un fichero csv. Una vez subido, podrás revisar el registro y descargar un archivo .csv con los errores detectados, si existen.',
        list: {
          columns: {
            name: 'Nombre',
            errors: 'Nº de errores',
            created: 'Fecha de importación',
          },
          one: 'Importación',
          many: 'Importaciones',
        },
        delete: {
          title: 'Eliminar registro de importación',
          subtitle:
            'Al eliminar este elemento se borrará del sistema. Esta acción es irreversible.',
          form: {
            submit: 'Eliminar',
          },
        },
      },
      list: {
        title: 'Leads',
        description:
          'Muestra el listado de personas interesadas en los servicios de la clínica (leads). Permite acceder a los detalles y la información relacionada con cada uno.',
        create: 'Lead',
        one: 'Lead',
        many: 'Leads',
        params: {
          originCampaignId: 'Leads filtrados por campaña de marketing de origen',
          originDepartmentId: 'Leads filtrados por departamento de origen',
          originFunnelId: 'Leads filtrados por embudo de origen',
          campaignId: 'Leads filtrados por campaña de marketing',
          funnelId: 'Leads filtrados por embudo',
          year: 'Leads filtrados por año de creación',
          month: 'Leads filtrados por mes de creación',
          clinicId: 'Leads filtrados por clínica',
          departmentId: 'Leads filtrados por departamento',
        },
        columns: {
          name: 'Nombre',
          activity: 'Actividad',
          responsible: 'Responsable',
          campaignName: 'Campaña',
          started: 'Fecha estimada',
          clinicName: 'Clínica',
          created: 'Creación',
          resultId: 'Progreso',
          phones: 'Teléfono',
          emails: 'Email',
        },
        csv: {
          select: 'IMPORTAR .CSV',
          somethingwentwrong: 'Leads no cargados',
          wrongdata: 'el fichero es erróneo, o los datos no corresponden con el formato correcto',
          loaded: 'Leads cargados correctamente',
          downloadCsv:
            'Algunos leads no se cargaron, puedes descargar la lista de leads erróneos en el botón de descarga',
        },
      },
      create: {
        title: 'Nuevo lead',
        subtitle:
          'Registro de una persona interesada en los servicios de la clínica, para gestionar su seguimiento hasta contratar un servicio.',
        form: {
          description: {
            title: 'Descripción',
            description:
              'Añade información relevante sobre el lead, como su interés o necesidades, para personalizar su seguimiento.',
          },
          clinicId: {
            title: 'Clínica',
            description:
              'Selecciona la clínica de preferencia del lead para gestionar su seguimiento y los servicios',
          },
          contactId: {
            title: 'Contacto',
            description:
              'Contacto asociado al lead. Un contacto puede tener varios leads. Esto es debido a que un contacto puede ser un lead en varias campañas de marketing.',
          },
          campaignId: {
            title: 'Campaña de marketing',
            description:
              'Elige la campaña de marketing que originó el interés del contacto. Este paso es necesario para que el lead quede vinculado correctamente a la campaña que lo generó.',
          },
          submit: 'Crear lead',
        },
      },
      update: {
        title: 'Actualizar lead',
        subtitle: 'Modificación de los detalles del lead para mantener la información actualizada',
        status: {
          inactive: {
            title: 'Lead inactivo',
            description:
              'El lead está inactivo y se activará automáticamente si tiene una actividad programada en la fecha correspondiente.',
          },
          canceled: {
            title: 'Lead dado de baja',
            description: 'El lead ha sido desactivado permanentemente del embudo de ventas.',
          },
        },
        disabledBanner: {
          title: 'Lead inactivo',
        },
        tabs: {
          lead: {
            title: 'Lead',
            description:
              'Revisa los datos del contacto y actualiza la información del lead. Puedes modificar los campos relacionados con el lead mientras mantienes visibles los datos del contacto.',
          },
          actions: {
            title: 'Tareas',
            description:
              'Las tareas son actividades específicas que deben llevarse a cabo para avanzar en el proceso de conversión. En este apartado se muestran todas las tareas realizadas y pendientes relacionadas con este lead.',
          },
          responsible: {
            title: 'Responsable',
            description:
              'Persona encargada de supervisar y coordinar el seguimiento del lead. No realiza necesariamente las tareas, pero es quien está a cargo de asegurar que todo el proceso avance correctamente.',
          },
          contact: {
            title: 'Contacto',
            description:
              'Un contacto es el registro permanente de una persona, creado cuando se establece el primer contacto con la empresa. Desde esta pestaña, puedes ver y actualizar los datos del contacto que están asociados a este lead.',
          },
          notes: {
            title: 'Observaciones',
            description:
              'Espacio para compartir información general sobre el lead para recordar detalles importantes o para compartir información con otros comerciales.',
          },
          specialist: {
            title: 'Especialista',
            description:
              'El especialista asignado puede ser un ginecólogo, un embriólogo o un profesional de atención al paciente. Por lo general, se selecciona durante la visita informativa médica para asegurar una atención adecuada al lead según sus necesidades específicas. Desde esta pestaña, puedes elegir al especialista que llevará su caso.',
          },
          communications: {
            title: 'Comunicaciones',
            description:
              'Integración con el sistema de mensajería para monitorizar las comunicaciones con los leads.',
          },
        },
        leadPassport: {
          birthCountry: 'País de nacimiento',
          birthDate: 'Fecha de nacimiento',
          height: 'Altura',
          weight: 'Peso',
          contactTime: 'Preferencia',
          created: 'Fecha de creación',
          consent: {
            title: 'Consentimiento',
            type: {
              legal: 'Legal',
              marketing: 'Marketing',
            },
          },
        },
        form: {
          description: {
            title: 'Descripción',
            description:
              'Añade información relevante sobre el lead, como su interés o necesidades, para personalizar su seguimiento',
          },
          clinicId: {
            title: 'Clínica',
            description:
              'Selecciona la clínica de preferencia del lead para gestionar su seguimiento y los servicios',
          },
          contactId: {
            title: 'Contacto',
            description:
              'Asocia este lead al contacto correspondiente. Un lead se crea cada vez que un contacto muestra interés a través de una campaña o acción comercial. Si aún no existe el contacto, primero deberás crearlo',
          },
          campaignId: {
            title: 'Campaña de marketing',
            description:
              'Elige la campaña de marketing que originó el interés del contacto. Este paso es necesario para que el lead quede vinculado correctamente a la campaña que lo generó',
          },
          reasonId: {
            title: 'Motivo',
            description: 'Motivo',
          },
          submit: 'Actualizar lead',
        },
        lead: {
          tab: 'Lead',
          description: 'Detalles del lead',
        },
        responsible: {
          form: {
            responsibleId: {
              title: 'Responsable',
              description:
                'Selecciona a la persona encargada de supervisar el seguimiento del lead',
            },
            submit: 'Asignar responsable',
          },
        },
        specialist: {
          form: {
            specialistId: {
              title: 'Especialista',
              description:
                'Selecciona al especialista que se asignará a este lead. Puedes elegir entre ginecólogos, embriólogos o personal de atención al paciente.',
            },
            submit: 'Asignar especialista',
          },
        },
        actions: {
          tab: 'Tareas',
          description: 'Tareas del lead',
          add: 'Añadir tarea',
          create: {
            title: 'Nueva tarea',
            subtitle:
              'Al crear una tarea, se asigna al responsable y se vincula al lead o cliente correspondiente. La tarea se reflejará en la lista y el calendario para gestionar llamadas, reuniones o acciones de seguimiento.',
            confirmationMessage:
              'Tarea no programada. \n\nEl lead quedará sin una tarea asignada en la planificación actual. Salir sin programar la tarea puede afectar el seguimiento del lead.\n\n¿Estás seguro de que deseas salir sin planificar esta tarea?\n',
            form: {
              activityId: {
                title: 'Actividad a realizar',
                description:
                  'Acción específica, como una llamada, visita o tarea, para avanzar con el lead',
              },
              responsibleId: {
                title: 'Responsable',
                description:
                  'Persona encargada de realizar la actividad asignada, como un miembro de call center o un comercial',
              },
              resultId: {
                title: 'Resultado',
                description: 'Resultado de la actividad',
              },
              started: {
                title: 'Fecha estimada',
                description:
                  'Indica el día y la hora en que se espera realizar la tarea. Se mostrará visualmente en el calendario de tareas',
              },
              subject: {
                title: 'Asunto de la tarea',
                description:
                  'Título de la tarea que describe su propósito, visible en el listado y detalle para proporcionar más información sobre la actividad',
              },
              observation: {
                title: 'Nota',
                description: 'Un pequeño comentario sobre la actividad',
              },
              submit: 'Crear tarea',
            },
            steps: {
              clinic: {
                form: 'Clínica seleccionada',
              },
              lead: {
                form: 'Lead seleccionado',
              },
            },
          },
          update: {
            title: 'Actualizar tarea',
            subtitle: 'Modificación de los datos relacionados a la tarea.',
            form: {
              activityId: {
                title: 'Actividad a realizar',
                description:
                  'Acción específica, como una llamada, visita o tarea, para avanzar con el lead.',
              },
              responsibleId: {
                title: 'Responsable',
                description:
                  'Persona encargada de realizar la actividad asignada, como un miembro de call center o un comercial.',
              },
              resultId: {
                title: 'Resultado',
                description: 'Resultado de la actividad',
              },
              started: {
                title: 'Fecha estimada',
                description:
                  'Indica el día y la hora en que se espera realizar la tarea. Se mostrará visualmente en el calendario de tareas.',
              },
              subject: {
                title: 'Asunto de la tarea',
                description:
                  'Título de la tarea que describe su propósito, visible en el listado y detalle para proporcionar más información sobre la actividad.',
              },
              observation: {
                title: 'Nota',
                description: 'Un pequeño comentario sobre la actividad',
              },
              submit: 'Actualizar tarea',
            },
            tabs: {
              action: {
                title: 'Tarea',
                description:
                  'Esta sección permite definir los detalles clave de la tarea, como su propósito, la persona responsable y la fecha estimada, para facilitar su gestión y seguimiento',
              },
              actionEnding: {
                title: 'Realización',
                description:
                  'Esta sección permite añadir el resultado en relación a la situación generada una vez realizada la tarea.',
              },
              alerts: {
                title: 'Alertas',
                description:
                  'Alertas asociadas a la tarea que actúan como recordatorios. Generarán notificaciones en el sistema para asegurar el seguimiento adecuado.',
              },
            },
            actionEnding: {
              form: {
                false: {
                  title: 'Resultado',
                },
                result: {
                  title: 'Resultado',
                  description:
                    'Resultado de la actividad. Si se inserta un resultado, la tarea se considerará finalizada y no se podrá modificar más. Si no se inserta un resultado, la tarea se considerará pendiente y se podrá modificar más adelante.',
                },
                communicationChannel: {
                  title: 'Vía de comunicación',
                  description: 'Vía de comunicación utilizada para la actividad',
                },
                submit: 'Finalizar tarea',
                submitNextSuggestion: 'Finalizar',
              },
            },
            alert: {
              list: {
                title: 'Alertas',
                columns: {
                  expectedAlarmTime: 'Fecha de alarma',
                },
                many: 'Alertas',
                one: 'Alerta',
              },
              add: 'Añadir alerta',
            },
          },
          lead: {
            tabs: {
              details: {
                title: 'General',
              },
              notes: {
                title: 'Observaciones',
              },
              contactNotes: {
                title: 'Observaciones',
              },
            },
            fields: {
              newLead: 'Nuevo lead',
              active: 'Lead activo',
              inactive: 'Lead inactivo',
              canceled: 'Lead baja',
              converted: 'Lead convertido',
              clinic: 'Clínica',
              contactTime: 'Preferencia',
              responsible: 'Responsable',
              funnel: 'Embudo',
              consent: 'Consentimiento',
              campaign: 'Campaña',
              originCampaign: 'Campaña de origen',
              created: 'Fecha de creación',
              legal: 'Legal',
              marketing: 'Marketing',
              donationPreference: 'Ciudad',
              birthCountry: 'País de nacimiento',
              birthDate: 'Fecha de nacimiento',
              height: '(CM)',
              weight: '(KG)',
            },
          },
        },
      },
      delete: {
        title: 'Eliminar lead',
        subtitle: 'Al eliminar este elemento se borrará del sistema. Esta acción es irreversible.',
        form: {
          status: {
            title: '¿Estás seguro de que deseas eliminarlo?',
            description: 'Tal vez prefieras desactivar este lead',
            label: 'Sí, deseo eliminar este lead',
          },
          confirm: 'Esta acción eliminará todo lo relacionado con el lead, estás seguro?',
          submit: 'Eliminar',
        },
      },
      disable: {
        title: 'Deshabilitar lead',
        subtitle:
          'El elemento no estará disponible en el CRM y solo las personas con un permiso específico podrán visualizar los elementos inactivos.',
        form: {
          reasonId: {
            title: 'Motivo',
            description: 'Motivo por el que se deshabilita el lead',
          },
          submit: 'Deshabilitar',
        },
        confirmationMessage:
          'Motivo sin adjuntar. ¿Estás seguro de que deseas deshabilitar este lead sin adjuntar un motivo?',
      },
      enable: {
        title: 'Habilitar lead',
        subtitle: 'Habilitar lead en el sistema',
        form: {
          status: {
            title: '¿Estás seguro de que deseas habilitar este lead?',
            description: 'Habilitar un lead significa que podrá ser utilizado en el sistema',
            label: 'Sí, deseo habilitar este lead',
          },
          submit: 'Habilitar',
        },
      },
      addSpecialist: {
        title: 'Asignar especialista',
        subtitle: 'Asignar especialista al lead',
        form: {
          specialistId: {
            title: 'Especialista',
            description:
              'Selecciona al especialista que se asignará a este lead. Puedes elegir entre ginecólogos, embriólogos o personal de atención al paciente.',
          },
          submit: 'Asignar especialista',
        },
      },
    },
    reasons: {
      list: {
        title: 'Motivos de inactividad o baja',
        description:
          'Configura los motivos por los cuales un lead puede darse de baja o considerarse inactivo. Estos motivos se utilizan para registrar y analizar las razones por las que se abandona el proceso del embudo de ventas.',
        create: 'Motivo',
        one: 'Motivo',
        many: 'Motivos',
        columns: {
          name: 'Titulo del motivo',
          created: 'Creación',
        },
      },
      create: {
        title: 'Nuevo motivo',
        subtitle:
          'Añade un motivo para registrar las razones por las que un cliente potencial se descarta en el proceso.',
        form: {
          name: {
            title: 'Título del motivo',
            description:
              'Nombre o breve descripción que identifica la razón por la cual un lead se ha dado de baja',
          },
          description: {
            title: 'Descripción',
            description:
              'Breve explicación sobre la razón por la cual el lead se considera no válido',
          },
          color: {
            title: 'Color',
            description:
              'Color identificativo del motivo. Esto facilitará su reconocimiento en el listado de motivos de baja',
          },
          notify: {
            title: 'Notificar',
            description: 'Notificar o no el motivo en el sistema',
            label: 'Notificar motivo',
          },
          icon: {
            title: 'Icono',
            description: 'Icono asociado al motivo',
            label: 'Seleccionar icono...',
            search: {
              placeholder: 'Buscar icono...',
            },
          },
          canceled: {
            title: 'Baja definitivá',
            description:
              'Activa esta opción para dar de baja al lead de forma definitiva. Se asumé que el lead no va a volver a entrar al embudo de ventas.',
            label: 'Motivo de baja',
          },
          subject: {
            title: 'Asunto',
            description: 'Asunto del motivo',
          },
          message: {
            title: 'Mensaje',
            description: 'Mensaje del motivo',
          },
          submit: 'Crear motivo',
        },
      },
      enable: {
        title: 'Habilitar motivo',
        subtitle: 'Habilitar motivo en el sistema',
        form: {
          status: {
            title: '¿Estás seguro de que deseas habilitar este motivo?',
            description: 'Habilitar un motivo significa que podrá ser utilizado en el sistema',
            label: 'Sí, deseo habilitar este motivo',
          },
          submit: 'Habilitar motivo',
        },
      },
      disable: {
        title: 'Deshabilitar motivo',
        subtitle:
          ' El elemento no estará disponible en el CRM y solo las personas con un permiso específico podrán visualizar los elementos inactivos.',
        form: {
          status: {
            title: '¿Estás seguro de que deseas deshabilitar este motivo?',
            description:
              'Deshabilitar un motivo significa que no podrá ser utilizado en el sistema',
            label: 'Sí, deseo deshabilitar este motivo',
          },
          submit: 'Deshabilitar motivo',
        },
      },
      update: {
        title: 'Actualizar motivo',
        subtitle:
          'Los motivos identifican las razones por las que un lead sale del proceso de ventas. Aquí puedes modificar cada motivo según si el lead puede regresar o si su baja es definitiva.',
        tabs: {
          reason: {
            title: 'Motivo',
            description:
              'En esta sección puedes actualizar los datos generales del motivo, como su nombre y descripción',
          },
          notification: {
            title: 'Configurar notificación',
            description:
              'Configuración de la notificación que se va a enviar cuando se produce un cancelamiento con este motivo en particular',
          },
          activity: {
            title: 'Siguiente actividad',
            description:
              'En esta sección puedes configurar la creación automática de una tarea tras registrar este motivo. El sistema mostrará la sección para la creación de la tarea y una vez programada, reactivará al lead en la fecha seleccionada para continuar con su seguimiento.',
          },
        },
        reason: {
          form: {
            name: {
              title: 'Título del motivo',
              description:
                'Nombre o breve descripción que identifica la razón por la cual un lead se ha descartado del proceso',
            },
            description: {
              title: 'Descripción',
              description:
                'Breve explicación sobre la razón por la cual el lead se considera no válido',
            },
            color: {
              title: 'Color',
              description:
                'Color identificativo del motivo. Esto facilitará su reconocimiento en el listado de motivos de baja',
            },
            notify: {
              title: 'Notificar',
              description: 'Notificar o no el motivo en el sistema',
              label: 'Notificar motivo',
            },
            icon: {
              title: 'Icono',
              description: 'Icono asociado al motivo',
              label: 'Seleccionar icono...',
              search: {
                placeholder: 'Buscar icono...',
              },
            },
            canceled: {
              title: 'Baja',
              description:
                'Activa esta opción para dar de baja al lead de forma definitiva. Se asumé que el lead no va a volver a entrar al embudo de ventas.',
              label: 'Motivo de baja',
            },
            subject: {
              title: 'Asunto',
              description: 'Asunto del motivo',
            },
            message: {
              title: 'Mensaje',
              description: 'Mensaje del motivo',
            },
            submit: 'Actualizar motivo',
          },
          notification: {
            form: {
              notify: {
                title: 'Activar notificación',
                description: 'Notificar o no cuando se produce un cancelamiento con este motivo',
                label: 'Activar sistema de notificación',
              },
              subject: {
                title: 'Asunto',
                description: 'Asunto de la notificación',
              },
              message: {
                title: 'Mensaje',
                description: 'Mensaje que se mostrará en la notificación',
              },
              submit: 'Guardar configuración',
            },
          },
        },
        nextActivity: {
          form: {
            nextActivityId: {
              title: 'Siguiente actividad',
              description:
                'Al añadir este motivo, el sistema sugerirá automáticamente crear una tarea con la actividad seleccionada',
            },
            nextActivityDelay: {
              title: 'Tiempo de espera',
              description:
                'Introduce el tiempo de espera estimado para la siguiente actividad. Este dato permitirá que el sistema sugiera automáticamente una fecha y hora para programar la tarea, calculada a partir del momento en que se introduce el motivo.',
            },
            submit: 'Guardar',
          },
        },
      },
    },
    patients: {
      list: {
        params: {
          clinicId: 'Pacientes filtrados por clínica',
          funnelId: 'Pacientes filtrados por embudo',
          campaignId: 'Pacientes filtrados por campaña de marketing',
          departmentId: 'Pacientes filtrados por departamento',
          year: 'Pacientes filtrados por año de creación',
          month: 'Pacientes filtrados por mes de creación',
        },
        title: {
          ovoclinic: 'Pacientes',
          ovobank: 'Donantes',
        },
        description: {
          ovoclinic:
            'Listado de pacientes activos en la clínica. Permite acceder a los detalles completos de cada paciente, incluyendo su información personal, tratamientos y seguimiento.',
          ovobank:
            'Listado de donantes aptos. Permite acceder a los detalles completos de cada donante, incluyendo su información personal y acciones realizadas de seguimiento.',
        },
        create: {
          ovoclinic: 'Paciente',
          ovobank: 'Donante',
        },
        one: {
          ovoclinic: 'Paciente',
          ovobank: 'Donante',
        },
        many: {
          ovoclinic: 'Pacientes',
          ovobank: 'Donantes',
        },
        columns: {
          contact: {
            name: {
              ovoclinic: 'Contacto',
              ovobank: 'Contacto',
            },
          },
          description: {
            ovoclinic: 'Descripción',
            ovobank: 'Descripción',
          },
          phones: {
            ovoclinic: 'Teléfono',
            ovobank: 'Teléfono',
          },
          lastCycleClinic: {
            name: {
              ovoclinic: 'Clínica del ciclo',
              ovobank: 'Clínica del ciclo',
            },
          },
          clinic: {
            name: {
              ovoclinic: 'Clínica',
              ovobank: 'Clínica',
            },
          },
          created: {
            ovoclinic: 'Creación',
            ovobank: 'Creación',
          },
        },
      },
      create: {
        title: {
          ovoclinic: 'Nuevo paciente',
          ovobank: 'Nuevo donante',
        },
        subtitle: {
          ovoclinic:
            'En esta sección puedes convertir un lead en paciente. Una vez convertido, podrás gestionar toda la información y el seguimiento relacionado con su tratamiento y atención.',
          ovobank:
            'En esta sección puedes convertir un lead en Donante. Una vez convertido, podrás acceder al registro de todos los donantes que han sido aptos.',
        },
        form: {
          description: {
            title: {
              ovobank: 'Descripción',
              ovoclinic: 'Descripción',
            },
            description: {
              ovoclinic:
                'Espacio para agregar información relevante sobre el paciente, como detalles específicos de su situación o tratamiento.',
              ovobank:
                'Espacio para agregar información relevante sobre el donante, como detalles específicos de su situación',
            },
          },
          leadId: {
            title: 'Lead',
            description: {
              ovoclinic: 'Lead asociado al paciente',
              ovobank: 'Lead asociado al donante',
            },
          },
          clinicId: {
            title: 'Clínica',
            description: {
              ovoclinic: 'La clínica de preferencia del paciente.',
              ovobank: 'La clínica de preferencia del donante.',
            },
          },
          campaignId: {
            title: 'Campaña',
            description: {
              ovoclinic:
                'La campaña de marketing de origen del paciente. Mediante esta procedencia se puede analizar la efectividad de las campañas de marketing.',
              ovobank:
                'La campaña de marketing de origen del donante. Mediante esta procedencia se puede analizar la efectividad de las campañas de marketing.',
            },
          },
          contactId: {
            title: 'Contacto',
            description: {
              ovoclinic: 'El contacto por el cual se ha llegado a convertir en un paciente.',
              ovobank: 'El contacto por el cual se ha llegado a convertir en un donante.',
            },
          },
          warrantyAgreement: {
            title: {
              ovoclinic: 'Acuerdo de garantía',
              ovobank: 'Acuerdo de garantía',
            },
            description: {
              ovoclinic:
                'Activa este toggle para confirmar que el paciente ha aceptado el acuerdo de garantía. Es obligatorio que el paciente firme este acuerdo antes de recibir su tratamiento',
              ovobank: 'El donante acepta el acuerdo de garantía',
            },
            label: {
              ovoclinic: 'Confirmar aceptación del acuerdo de garantía',
              ovobank: 'Confirmar aceptación del acuerdo de garantía',
            },
          },
          consentCheck: {
            title: {
              ovoclinic: 'Consentimiento',
              ovobank: 'Consentimiento',
            },
            description: {
              ovoclinic:
                'Activa esta opción para confirmar que el paciente ha dado su consentimiento para recibir el tratamiento. Es obligatorio para continuar con el proceso.',
              ovobank: 'El donante da su consentimiento para ser tratado',
            },
            label: {
              ovoclinic: 'Confirmar aceptación del consentimiento',
              ovobank: 'Confirmar aceptación del consentimiento',
            },
          },
          submit: {
            ovoclinic: 'Crear paciente',
            ovobank: 'Crear donante',
          },
        },
        treatment: {
          create: {
            steps: {
              program: {
                form: 'Tratamiento',
              },
              phase: {
                form: 'Etapa',
              },
            },
          },
        },
        steps: {
          phase: {
            form: {
              phaseId: {
                title: 'Etapa',
                description: 'Etapa en la que se encuentra el tratamiento',
              },
            },
          },
          program: {
            form: {
              programId: {
                title: 'Programa',
                description: 'Selecciona el programa que el paciente ha contratado',
              },
              phaseId: {
                title: 'Etapa',
                description: 'Selecciona la fase en la que se encuentra el tratamiento',
              },
            },
          },
        },
      },
      update: {
        title: {
          ovoclinic: 'Actualizar paciente',
          ovobank: 'Actualizar donante',
        },
        subtitle: {
          ovoclinic:
            'Actualiza los datos del paciente, incluyendo su información personal, detalles del tratamiento y seguimiento, así como cualquier otra información relevante para su atención',
          ovobank:
            'Actualiza los datos del donante, incluyendo su información personal o las tareas de seguimiento, así como cualquier otra información relevante para su atención',
        },
        tabs: {
          patient: {
            title: {
              ovoclinic: 'Paciente',
              ovobank: 'Donante',
            },
            description: {
              ovoclinic:
                'En esta sección puedes ver los consentimientos y la nota específica añadidos al crear el paciente.',
              ovobank:
                'En esta sección puedes ver los consentimientos y la nota específica añadidos al crear el paciente.',
            },
          },
          contact: {
            title: {
              ovoclinic: 'Contacto',
              ovobank: 'Contacto',
            },
            description: {
              ovoclinic:
                'Un contacto es el registro permanente de una persona, creado cuando se establece el primer contacto con la empresa. Desde esta pestaña, puedes ver y actualizar los datos del contacto que están asociados a este paciente.',
              ovobank:
                'Un contacto es el registro permanente de una persona, creado cuando se establece el primer contacto con la empresa. Desde esta pestaña, puedes ver y actualizar los datos del contacto que están asociados a este donante.',
            },
          },
          responsible: {
            title: {
              ovoclinic: 'Responsable',
              ovobank: 'Responsable',
            },
            description: {
              ovoclinic:
                'Persona encargada de supervisar el seguimiento del paciente. No realiza necesariamente las tareas, pero es quien asegura que la atención se desarrolle correctamente.',
              ovobank:
                'Persona encargada de supervisar el seguimiento del donante. No realiza necesariamente las tareas, pero es quien asegura que la atención se desarrolle correctamente.',
            },
          },
          specialist: {
            title: {
              ovoclinic: 'Especialista',
              ovobank: 'Especialista',
            },
            description: {
              ovoclinic:
                'El especialista asignado puede ser un ginecólogo, un embriólogo o un profesional de atención al paciente. Desde esta pestaña, puedes elegir al especialista que llevará su caso y le hará el seguimiento en las visitas médicas.',
              ovobank:
                'El especialista asignado puede ser un ginecólogo, un embriólogo o un profesional de atención al paciente. Desde esta pestaña, puedes elegir al especialista que llevará su caso y le hará el seguimiento en las visitas médicas.',
            },
          },
          receipts: {
            title: {
              ovoclinic: 'Recibos',
              ovobank: 'Recibos',
            },
            description: 'Lista de recibos asociados al cliente',
            list: {
              create: 'Recibo',
            },
            many: 'Recibos',
            one: 'Recibo',
            columns: {
              receipt: {
                name: 'Nombre',
                clinic: 'Clínica',
                created: 'F. creación',
              },
            },
            update: {
              tabs: {
                payments: {
                  list: {
                    create: 'Crear pago',
                  },
                  many: 'Pagos',
                  one: 'Pago',
                  columns: {
                    payment: {
                      price: 'Precio',
                      clinic: 'Clínica',
                      name: 'Nombre',
                      created: 'F. creación',
                    },
                  },
                },
              },
            },
          },
          purchase: {
            title: {
              ovoclinic: 'Contrataciones',
              ovobank: 'Contrataciones',
            },
            description: {
              ovoclinic:
                'En esta pantalla se gestionan todas las contrataciones realizadas por el paciente, ya sean programas, servicios adicionales o mantenimiento, junto con su precio y detalles.',
              ovobank: 'Contrataciones del donante',
            },
            one: 'Contratación',
            many: 'Contrataciones',
            columns: {
              price: 'Precio',
              purchaseType: 'Tipo',
              treatmentName: 'Nombre',
            },
          },
          notes: {
            title: {
              ovoclinic: 'Observaciones',
              ovobank: 'Observaciones',
            },
            description: {
              ovoclinic:
                'Espacio para compartir información general sobre el paciente para recordar detalles importantes o para compartir información con otros comerciales.',
              ovobank:
                'Espacio para compartir información general sobre el donante para recordar detalles importantes o para compartir información con otros compañeros.',
            },
          },
          document: {
            title: {
              ovoclinic: 'Documentos',
              ovobank: 'Documentos',
            },
            description: {
              ovoclinic:
                'Sube y gestiona los documentos del paciente, como su DNI, resultados de estudios, análisis y cualquier otro documento relevante para su tratamiento.',
              ovobank:
                'Sube y gestiona los documentos del donante, como su DNI o resultados de estudios y cualquier otro documento relevante.',
            },
          },
          treatment: {
            title: {
              ovoclinic: 'Tratamiento',
              ovobank: 'Tratamiento',
            },
            description: {
              ovoclinic:
                'En esta pestaña se muestran los programas contratados por el paciente. Cada tratamiento refleja el programa que ha elegido, como, por ejemplo, un programa de garantía de embarazo.\n\nAquí podrás ver el estado de cada tratamiento y acceder a sus detalles.',
              ovobank: 'Programa del donante',
            },
            one: 'Programa',
            many: 'Programas',
            submit: 'Añadir programa',
            columns: {
              programName: 'Programa',
              estimated: 'Fecha estimada',
              finished: 'Fecha finalización',
            },
            document: {
              delete: {
                title: 'Borrar fichero',
                subtitle:
                  '¿Estás seguro de que deseas borrar el fichero?, se borrará también del cloud',
                submit: 'Borrar',
              },
              columns: {
                programName: 'Programa',
                estimated: 'Fecha estimada',
                finished: 'Fecha finalización',
                documentName: 'Documento',
                created: 'Creado',
                size: 'Tamaño',
                vRepoUploaded: 'Sincronizado con Vrepo',
                type: 'Tipo',
              },
              select: 'Subir un fichero pdf',
              unselected: 'Ningún fichero seleccionado',
              unvalid: 'Por favor, selecciona un fichero pdf válido.',
              submit: 'Enviar',
              many: 'Ficheros',
            },
          },
          actions: {
            title: {
              ovoclinic: 'Tareas',
              ovobank: 'Tareas',
            },
            description: {
              ovoclinic:
                'Representan las actividades o acciones específicas que deben realizarse dentro del proceso de gestión de conversión del lead o seguimiento del paciente. Se utilizan para organizar, asignar y realizar un seguimiento de las acciones necesarias, como llamadas, visitas o tareas.',
              ovobank:
                'Representan las actividades o acciones específicas que deben realizarse dentro del proceso de gestión de conversión del lead o seguimiento del donante. Se utilizan para organizar, asignar y realizar un seguimiento de las acciones necesarias, como llamadas, visitas o tareas.',
            },
            add: 'Añadir tarea',
          },
        },
        actions: {
          patient: {
            tabs: {
              details: {
                title: 'General',
              },
              notes: {
                title: 'Observaciones',
              },
              contactNotes: {
                title: 'Observaciones',
              },
            },
            fields: {
              newPatient: {
                ovoclinic: 'Nuevo paciente',
                ovobank: 'Nuevo donante',
              },
              active: {
                ovoclinic: 'Paciente activo',
                ovobank: 'Donante activo',
              },
              inactive: {
                ovoclinic: 'Paciente inactivo',
                ovobank: 'Donante inactivo',
              },
              clinic: 'Clínica',
              contactTime: 'Preferencia',
              responsible: 'Responsable',
              funnel: 'Origen',
              consent: 'Aprobación',
              consentCheck: 'Consentimiento',
              warrantyAgreement: 'Acuerdo garantía',
              campaign: 'Campaña',
              originCampaign: 'Campaña de origen',
            },
          },
        },
        patient: {
          tab: {
            ovoclinic: 'Paciente',
            ovobank: 'Donante',
          },
          description: {
            ovoclinic: 'Detalles del paciente',
            ovobank: 'Detalles del donante',
          },
        },
        contact: {
          tab: 'Contacto',
          description: {
            ovoclinic:
              'Un contacto es el registro permanente de una persona, creado cuando se establece el primer contacto con la empresa. Desde esta pestaña, puedes ver y actualizar los datos del contacto que está asociados a este paciente.',
            ovobank: 'Detalles del contacto del donante',
          },
        },
        responsible: {
          form: {
            responsibleId: {
              title: {
                ovoclinic: 'Responsable',
                ovobank: 'Responsable',
              },
              description: {
                ovoclinic:
                  'Selecciona a la persona encargada de supervisar el seguimiento del paciente',
                ovobank: 'Usuario responsable del donante',
              },
            },
            submit: 'Asignar responsable',
          },
        },
        specialist: {
          form: {
            specialistId: {
              title: 'Especialista',
              description: {
                ovoclinic:
                  'Selecciona al especialista que se asignará a este paciente. Puedes elegir entre ginecólogos, embriólogos o personal de atención al paciente.',
                ovobank: 'Especialista asignado al donante',
              },
            },
            submit: 'Asignar especialista',
          },
        },
        receipts: {
          create: {
            title: 'Crear recibo',
            subtitle: {
              ovoclinic: 'Crear recibo asociado al paciente',
              ovobank: 'Crear recibo asociado al donante',
            },
            form: {
              clinicId: {
                title: 'Clínica',
              },
              submit: 'Crear recibo',
            },
          },
          update: {
            tabs: {
              receipt: {
                title: 'Recibo',
                description: {
                  ovoclinic: 'Actualizar recibo asociado al paciente',
                  ovobank: 'Actualizar recibo asociado al donante',
                },
              },
              payments: {
                title: 'Pagos',
                description: 'Pagos del recibo asociado al cliente',
                list: {
                  create: 'Añadir pago',
                },
              },
            },
            title: 'Actualizar recibo',
            subtitle: {
              ovoclinic: 'Actualizar recibo asociado al paciente',
              ovobank: 'Actualizar recibo asociado al donante',
            },
            form: {
              clinicId: {
                title: 'Clínica',
              },
              submit: 'Guardar recibo',
            },
          },
        },
        form: {
          description: {
            title: {
              ovoclinic: 'Descripción',
              ovobank: 'Descripción',
            },
            description: {
              ovoclinic:
                'Espacio para agregar información relevante sobre el paciente, como detalles específicos de su situación o tratamiento',
              ovobank: 'Una breve descripción para añadir algun comentario sobre el donante.',
            },
          },
          clinicId: {
            title: 'Clínica',
            description: {
              ovoclinic: 'La clínica en la que el paciente desea ser atendido.',
              ovobank: 'La clínica en la que el donante desea ser atendido.',
            },
          },
          leadId: {
            title: 'Lead',
            description: {
              ovoclinic: 'Lead asociado al paciente',
              ovobank: 'Lead asociado al donante',
            },
          },
          contactId: {
            title: 'Contacto',
            description: {
              ovoclinic:
                'Los datos de contacto por los cuales el paciente ha llegado a la clínica.',
              ovobank: 'Los datos de contacto por los cuales el donante ha llegado a la clínica.',
            },
          },
          campaignId: {
            title: 'Campaña',
            description: {
              ovoclinic:
                'La campaña de marketing de origen del paciente. Mediante esta procedencia se puede analizar la efectividad de las campañas de marketing.',
              ovobank:
                'La campaña de marketing de origen del donante. Mediante esta procedencia se puede analizar la efectividad de las campañas de marketing.',
            },
          },
          warrantyAgreement: {
            title: {
              ovoclinic: 'Acuerdo de garantía',
              ovobank: 'Acuerdo de garantía',
            },
            description: {
              ovoclinic:
                'Activa este toggle para confirmar que el paciente ha aceptado el acuerdo de garantía. Es obligatorio que el paciente firme este acuerdo antes de recibir su tratamiento',
              ovobank: 'El donante acepta el acuerdo de garantía',
            },
            label: {
              ovoclinic: 'Confirmar aceptación del acuerdo de garantía',
              ovobank: 'Sí, acepto el acuerdo de garantía',
            },
          },
          consentCheck: {
            title: {
              ovoclinic: 'Consentimiento',
              ovobank: 'Consentimiento',
            },
            description: {
              ovoclinic:
                'Activa esta opción para confirmar que el paciente ha dado su consentimiento para recibir el tratamiento. ',
              ovobank: 'El donante da su consentimiento para ser tratado',
            },
            label: {
              ovoclinic: 'Confirmar aceptación del consentimiento',
              ovobank: 'Sí, doy mi consentimiento',
            },
          },
          submit: {
            ovoclinic: 'Actualizar paciente',
            ovobank: 'Actualizar donante',
          },
        },
        payments: {
          create: {
            title: 'Crear pago',
            subtitle: {
              ovoclinic: 'Añadir pago asociado al recibo del paciente',
              ovobank: 'Añadir pago asociado al recibo del donante',
            },
            form: {
              price: {
                title: 'Precio',
              },
              submit: 'Guardar precio',
            },
          },
          update: {
            title: 'Actualizar pago',
            subtitle: {
              ovoclinic: 'Actualizar pago asociado al recibo del paciente',
              ovobank: 'Actualizar pago asociado al recibo del donante',
            },
            form: {
              price: {
                title: 'Precio',
              },
              submit: 'Guardar precio',
            },
          },
        },
        treatment: {
          create: {
            steps: {
              program: {
                form: 'Tratamiento',
              },
              phase: {
                form: 'Fase',
              },
            },
          },
          update: {
            steps: {
              program: {
                form: 'Tratamiento',
              },
              phase: {
                form: 'Fase',
              },
            },
          },
        },
      },
      disable: {
        title: {
          ovoclinic: 'Deshabilitar paciente',
          ovobank: 'Deshabilitar donante',
        },
        subtitle: {
          ovoclinic:
            ' El elemento no estará disponible en el CRM y solo las personas con un permiso específico podrán visualizar los elementos inactivos.',
          ovobank: 'Deshabilitar donante en el sistema',
        },
        form: {
          status: {
            title: {
              ovoclinic: '¿Estás seguro de que deseas deshabilitar este paciente?',
              ovobank: '¿Estás seguro de que deseas deshabilitar este donante?',
            },
            description: {
              ovoclinic:
                'Deshabilitar un paciente significa que no podrá ser utilizado en el sistema',
              ovobank: 'Deshabilitar un donante significa que no podrá ser utilizado en el sistema',
            },
            label: {
              ovoclinic: 'Sí, deseo deshabilitar este paciente',
              ovobank: 'Sí, deseo deshabilitar este donante',
            },
          },
          submit: 'Deshabilitar',
        },
      },
      enable: {
        title: {
          ovoclinic: 'Habilitar paciente',
          ovobank: 'Habilitar donante',
        },
        subtitle: {
          ovoclinic: 'Habilitar paciente en el sistema',
          ovobank: 'Habilitar donante en el sistema',
        },
        form: {
          status: {
            title: {
              ovoclinic: '¿Estás seguro de que deseas habilitar este paciente?',
              ovobank: '¿Estás seguro de que deseas habilitar este donante?',
            },
            description: {
              ovoclinic: 'Habilitar un paciente significa que podrá ser utilizado en el sistema',
              ovobank: 'Habilitar un donante significa que podrá ser utilizado en el sistema',
            },
            label: {
              ovoclinic: 'Sí, deseo habilitar este paciente',
              ovobank: 'Sí, deseo habilitar este donante',
            },
          },
          submit: 'Habilitar',
        },
      },
      delete: {
        title: {
          ovoclinic: 'Eliminar paciente',
          ovobank: 'Eliminar donante',
        },
        subtitle: {
          ovoclinic:
            'Al eliminar este elemento se borrará del sistema. Esta acción es irreversible.',
          ovobank: 'Esta acción es irreversible',
        },
        form: {
          status: {
            title: '¿Estás seguro de que deseas eliminarlo?',
            description: {
              ovoclinic: 'Tal vez prefieras desactivar este paciente',
              ovobank: 'Tal vez prefieras desactivar este donante',
            },
            label: {
              ovoclinic: 'Sí, deseo eliminar este paciente',
              ovobank: 'Sí, deseo eliminar este donante',
            },
          },
          confirm: {
            ovoclinic: 'Esta acción eliminará todo lo relacionado con el paciente, estás seguro?',
            ovobank: 'Esta acción eliminará todo lo relacionado con el donante, estás seguro?',
          },
          submit: 'Eliminar',
        },
      },
      actions: {
        create: {
          title: 'Nueva tarea',
          subtitle:
            'Al crear una tarea, esta se asigna al responsable y se vincula al lead o cliente correspondiente. La tarea aparecerá en la lista y en el calendario de tareas realizadas o pendientes, según su estado, para seguir el progreso del proceso de conversión o seguimiento del paciente.',
          form: {
            activityId: {
              title: 'Actividad a realizar',
              description:
                'Acción específica, como una llamada, visita o tarea, para avanzar con el lead.',
            },
            responsibleId: {
              title: 'Responsable',
              description: 'Usuario responsable de la tarea',
            },
            started: {
              title: 'Fecha estimada',
              description:
                'Indica el día y la hora en que se espera realizar la tarea. Se mostrará visualmente en el calendario de tareas.',
            },
            subject: {
              title: 'Asunto de la tarea',
              description:
                'Título de la tarea que describe su propósito, visible en el listado y detalle para proporcionar más información sobre la actividad.',
            },
            observation: {
              title: 'Nota',
              description: 'Un pequeño comentario sobre la actividad',
            },
            submit: 'Crear tarea',
          },
        },
        update: {
          title: 'Actualizar tarea',
          subtitle: 'Modificación de los datos de una tarea en el sistema',
          actionEnding: {
            form: {
              submit: 'Finalizar tarea',
              submitNextSuggestion: 'Finalizar y planificar',
            },
          },
          form: {
            result: {
              false: {
                title: 'Resultado',
              },
              result: {
                title: 'Resultado',
                description:
                  'Resultado de la actividad. Si se inserta un resultado, la tarea se considerará finalizada y no se podrá modificar más. Si no se inserta un resultado, la tarea se considerará pendiente y se podrá modificar más adelante.',
              },
            },
            communicationChannel: {
              title: 'Vía de comunicación',
              description: 'Vía de comunicación utilizada para la actividad',
            },
            activityId: {
              title: 'Actividad a realizar',
              description:
                'Acción específica, como una llamada, visita o tarea, para avanzar con el lead.',
            },
            responsibleId: {
              title: 'Responsable',
              description: 'Usuario responsable de la tarea',
            },
            started: {
              title: 'Fecha estimada',
              description:
                'Indica el día y la hora en que se espera realizar la tarea. Se mostrará visualmente en el calendario de tareas.',
            },
            subject: {
              title: 'Asunto de la tarea',
              description:
                'Título de la tarea que describe su propósito, visible en el listado y detalle para proporcionar más información sobre la actividad.',
            },
            observation: {
              title: 'Nota',
              description: 'Un pequeño comentario sobre la actividad',
            },
            submit: 'Actualizar tarea',
          },
          tabs: {
            action: {
              title: 'Tarea',
              description:
                'Esta sección permite definir los detalles clave de la tarea, como su propósito, la persona responsable y la fecha estimada, para facilitar su gestión y seguimiento.',
            },
            actionEnding: {
              title: 'Realización',
              description:
                'Esta sección permite añadir el resultado en relación a la situación generada una vez realizada la tarea.',
            },
            alerts: {
              title: 'Alertas',
              description: 'Alertas asociadas a la tarea',
            },
          },
        },
      },
    },
    purchases: {
      update: {
        title: 'Actualizar contratación',
        subtitle: 'Modificar el precio de la contratación realizada por el paciente.',
        form: {
          price: {
            title: 'Precio',
            description: 'Precio de la contratación',
          },
          submit: 'Actualizar contratación',
        },
      },
      disable: {
        title: 'Deshabilitar contratación',
        subtitle: {
          ovoclinic:
            'El elemento no estará disponible en el CRM y solo las personas con un permiso específico podrán visualizar los elementos inactivos.',
          ovobank: 'Deshabilitar contratación del donante',
        },
        form: {
          submit: 'Deshabilitar',
        },
      },
      enable: {
        title: 'Habilitar contratación',
        subtitle: {
          ovoclinic: 'Habilitar contratación del paciente',
          ovobank: 'Habilitar contratación del donante',
        },
        form: {
          submit: 'Habilitar',
        },
      },
    },
    treatments: {
      create: {
        title: 'Añadir programa',
        subtitle: 'Asigna al paciente el programa contratado',
        form: {
          programId: {
            title: 'Programa',
            description: 'Programa a realizar',
          },
          estimated: {
            title: 'Fecha estimada',
            description: 'Fecha en la que se espera que se realice el programa',
          },
          finished: {
            title: 'Fecha finalización',
            description: 'Fecha en la que se ha finalizado el programa',
          },
          phaseId: {
            title: 'Fase',
            description: 'Selecciona la fase en la que se encuentra el tratamiento',
          },
          discount: {
            title: 'Porcentaje de descuento (%)',
            description: 'Indica el porcentaje del descuento a aplicar al programa',
          },
          submit: 'Crear programa',
        },
      },
      update: {
        title: 'Actualizar programa',
        subtitle:
          'Actualiza las características y los datos del programa contratado por el paciente. Ajusta la información, como técnicas complementarias y ciclos, según el tratamiento que está recibiendo.',
        form: {
          programId: {
            title: 'Programa',
            description: 'Programa a realizar',
          },
          phaseId: {
            title: 'Fase',
            description: 'Selecciona la fase en la que se encuentra el tratamiento',
          },
          estimated: {
            title: 'Fecha estimada',
            description: 'Fecha en la que se espera que se realice el programa',
          },
          finished: {
            title: 'Fecha finalización',
            description: 'Fecha en la que se ha programado el programa',
          },
          discount: {
            title: 'Porcentaje de descuento (%)',
            description: 'Indica el porcentaje del descuento a aplicar al programa',
          },
          submit: 'Actualizar programa',
        },
        tabs: {
          treatment: {
            title: {
              ovoclinic: 'Programa',
              ovobank: 'Programa',
            },
            description: {
              ovoclinic:
                'Información general del programa, fase en la que se encuentra, fecha de finalización si el tratamiento ha terminado y descuento aplicado si corresponde.',
              ovobank:
                'Información general del programa, fase en la que se encuentra, fecha de finalización si el tratamiento ha terminado y descuento aplicado si corresponde.',
            },
          },
          program: {
            title: {
              ovoclinic: 'Tratamiento',
            },
            description: {
              ovoclinic: 'Información del tratamiento',
              ovobank: 'Información del tratamiento',
            },
          },
          procedures: {
            title: {
              ovoclinic: 'Técnicas',
              ovobank: 'Técnicas',
            },
            description: {
              ovoclinic:
                'En esta sección se pueden añadir las técnicas complementarias de este programa y modificar su precio si corresponde.',
              ovobank:
                'Son las técnicas complementarias que se han creado en relación con cada programa. A través de esta pantalla, se pueden gestionar las técnicas específicas que se han aplicado al donante.',
            },
            many: 'Tecnicas',
            one: 'Técnica',
            add: 'Añadir técnica',
          },
          maintenances: {
            title: {
              ovoclinic: 'Mantenimientos',
              ovobank: 'Mantenimientos',
            },
            description: {
              ovoclinic:
                'Mantenimientos del tratamiento. Un mantenimiento es un servicio aplicado a un tratamiento. Este mantenimiento generará una actividad para que el departamento de mantenimiento pueda dar el seguimiento de pagos al paciente',
              ovobank:
                'Mantenimientos del tratamiento. Un mantenimiento es un servicio aplicado a un tratamiento. Este mantenimiento generará una actividad para que el departamento de mantenimiento pueda dar el seguimiento de pagos al donante',
            },
            many: 'Mantenimientos',
            one: 'Mantenimiento',
            add: 'Añadir mantenimiento',
          },
          interventions: {
            title: {
              ovoclinic: 'Tratamientos',
              ovobank: 'Tratamientos',
            },
            description: {
              ovoclinic:
                'Los tratamientos son los ciclos o intentos realizados dentro del programa contratado por el paciente. Está lista representa el registro de todos los ciclos realizados.',
              ovobank:
                'Ciclos realizados en los tratamientos. Mediante esta pantalla se pueden gestionar tratamientos específicos que se le han aplicado al donante.',
            },
            many: 'Tratamientos',
            one: 'Tratamiento',
            columns: {
              interventionClinicName: 'Clínica',
              observation: 'Observación',
              expectedDate: 'Fecha estimada',
              interventionDate: 'Fecha intervención',
              cycleName: 'Ciclo',
            },
            add: 'Añadir tratamiento',
          },
        },
      },
      disable: {
        title: 'Deshabilitar tratamiento',
        subtitle:
          'El elemento no estará disponible en el CRM y solo las personas con un permiso específico podrán visualizar los elementos inactivos.',
        form: {
          status: {
            title: '¿Estás seguro de que deseas deshabilitar este tratamiento?',
            description:
              'Deshabilitar un tratamiento significa que no podrá ser utilizado en el sistema',
            label: 'Sí, deseo deshabilitar este tratamiento',
          },
          submit: 'Deshabilitar tratamiento',
        },
      },
      enable: {
        title: 'Habilitar tratamiento',
        subtitle: 'Habilitar tratamiento en el sistema',
        form: {
          status: {
            title: '¿Estás seguro de que deseas habilitar este tratamiento?',
            description: 'Habilitar un tratamiento significa que podrá ser utilizado en el sistema',
            label: 'Sí, deseo habilitar este tratamiento',
          },
          submit: 'Habilitar tratamiento',
        },
      },
      list: {
        params: {
          clinicId: 'Tratamientos filtrados por clínica',
          departmentId: 'Tratamientos filtrados por departamento',
          firstAttempt: 'Tratamientos filtrados por Garantias (Primer intento)',
          loss: 'Tratamientos filtrados por Perdidas (+1 intento)',
          year: 'Tratamientos filtrados por año de creación',
          month: 'Tratamientos filtrados por mes de creación',
        },
        title: {
          ovoclinic: 'Tratamientos',
          ovobank: 'Tratamientos',
        },
        description: {
          ovoclinic:
            'Consulta los tratamientos activos en curso, con detalles sobre el estado, los ciclos y el progreso de cada paciente en su tratamiento',
          ovobank: 'Gestión de los tratamientos realizadas a los donantes',
        },
        create: 'Tratamiento',
        one: 'Tratamiento',
        many: {
          ovoclinic: 'Tratamientos',
          ovobank: 'Tratamientos',
        },
        columns: {
          cycleName: {
            ovoclinic: 'Ciclo',
            ovobank: 'Ciclo',
          },

          expectedDate: {
            ovoclinic: 'Fecha estimada',
            ovobank: 'Fecha estimada',
          },
          clinicName: {
            ovoclinic: 'Clínica',
            ovobank: 'Clínica',
          },
          patientName: {
            ovoclinic: 'Paciente',
            ovobank: 'Donante',
          },
          programName: {
            ovoclinic: 'Programa',
            ovobank: 'Programa',
          },
          phaseName: {
            ovoclinic: 'Etapa',
            ovobank: 'Etapa',
          },
          estimated: {
            ovoclinic: 'Fecha estimada',
            ovobank: 'Fecha estimada',
          },
          finished: {
            ovoclinic: 'Fecha finalización',
            ovobank: 'Fecha finalización',
          },
        },
      },
    },
    interventions: {
      enable: {
        title: 'Habilitar intervención',
        subtitle: 'Habilitar intervención en el sistema',
        form: {
          submit: 'Habilitar',
        },
      },
      disable: {
        title: 'Deshabilitar intervención',
        subtitle:
          'El elemento no estará disponible en el CRM y solo las personas con un permiso específico podrán visualizar los elementos inactivos.',
        form: {
          submit: 'Deshabilitar',
        },
      },
      create: {
        title: 'Añadir nuevo tratamiento',
        subtitle: 'Registro de un nuevo ciclo a realizar con el paciente',
        form: {
          interventionClinicId: {
            title: 'Clínica',
            description: 'Clínica en la que se realizará el ciclo',
          },
          interventionDate: {
            title: 'Fecha de realización de la intervención',
            description: 'Fecha en la que se ha realizado el ciclo',
          },
          observation: {
            title: 'Nota',
            description: 'Una breve descripción sobre el ciclo a realizar',
          },
          cycleId: {
            title: 'Ciclo',
            description: 'Selecciona el ciclo correspondiente dentro del programa al que pertenece',
          },
          expectedDate: {
            title: 'Fecha de intervención',
            description: 'Fecha programada para la realización del intento',
          },
          submit: 'Añadir tratamiento',
        },
      },
      update: {
        title: 'Actualizar tratamiento',
        subtitle: 'Modificación de los datos de un tratamiento en el sistema',
        form: {
          interventionClinicId: {
            title: 'Clínica',
            description: 'Clínica en la que se ha realizado el tratamiento',
          },
          interventionDate: {
            title: 'Fecha de intervención',
            description: 'Fecha en la que se ha realizado el ciclo',
          },
          observation: {
            title: 'Nota',
            description: 'Observación sobre el tratamiento',
          },
          cycleId: {
            title: 'Ciclo',
            description: 'Tipo de ciclo a realizar',
          },
          expectedDate: {
            title: 'Fecha estimada',
            description: 'Fecha estimada del ciclo',
          },
          submit: 'Actualizar tratamiento',
        },
        tabs: {
          intervention: {
            title: {
              ovoclinic: 'Tratamiento',
              ovobank: 'Tratamiento',
            },
            description: {
              ovoclinic:
                'Detalles sobre el ciclo realizado, como fecha de la intervención y técnicas complementarías realizadas si corresponde',
              ovobank: 'Información del tratamiento',
            },
          },
          techniques: {
            title: {
              ovoclinic: 'Técnicas',
              ovobank: 'Técnicas',
            },
            description: {
              ovoclinic:
                'En esta pantalla se pueden añadir las técnicas complementarias utilizadas en relación a este ciclo',
              ovobank:
                'Técnicas de la intervención. Mediante esta pantalla se pueden gestionar técnicas específicas que se le han aplicado al donante.',
            },
          },
        },
        techniques: {
          delete: {
            title: 'Borrar técnica',
            subtitle: 'Borrar técnica del la intervención',
            form: {
              submit: 'Borrar técnica',
            },
          },
        },
      },
    },
    procedures: {
      create: {
        title: 'Crear técnica complementaria',
        subtitle: 'Procedimientos adicionales a los programa mediante técnicas complementarias.',
        form: {
          techniqueId: {
            title: 'Técnica complementaria',
            description: 'Técnicas adicionales a los programas,',
          },
          name: {
            title: 'Nombre',
            description: 'Nombre identificativo de la técnica complementaria',
          },
          description: {
            title: 'Descripción',
            description: 'Breve descripción sobre las caracteristicas de la técnica',
          },
          submit: 'Crear técnica',
        },
      },
      update: {
        title: 'Actualizar técnica existente',
        subtitle: 'Modificación del precio de la técnica actual',
        form: {
          name: {
            title: 'Nombre',
            description: 'Nombre identificativo del procedimiento',
          },
          description: {
            title: 'Descripción',
            description: 'Descripción detallada del procedimiento',
          },
          price: {
            title: 'Precio',
            description: 'Precio de la técnica correspondiente',
          },
          submit: 'Actualizar precio',
        },
      },
      delete: {
        title: 'Borrar procedimiento',
        subtitle: 'Borrar procedimiento en el sistema',
        form: {
          submit: 'Borrar procedimiento',
        },
      },
    },
    maintenances: {
      create: {
        title: 'Nuevo mantenimiento',
        subtitle: 'Registro de un nuevo mantenimiento para el tratamiento en el sistema',
        form: {
          serviceId: {
            title: 'Servicio',
            description: 'Servicio asociado al mantenimiento',
          },
          submit: 'Crear mantenimiento',
        },
      },
      update: {
        title: 'Actualizar mantenimiento',
        subtitle: 'Modificación de los datos de un mantenimiento en el sistema',
        form: {
          serviceId: {
            title: 'Servicio',
            description: 'Servicio asociado al mantenimiento',
          },
          amount: {
            title: 'Precio',
            description: 'Precio del mantenimiento',
          },
          paymentStatus: {
            title: 'Pago',
            description: 'Estado del pago del mantenimiento',
          },
          estimatedPaymentDate: {
            title: 'Fecha estimada de pago',
            description: 'Fecha estimada de pago del mantenimiento',
          },
          submit: 'Actualizar mantenimiento',
        },
        tabs: {
          maintenance: {
            title: 'Mantenimiento',
            description: 'Detalles generales sobre el mantenimiento',
          },
          action: {
            title: 'Tarea',
            description:
              'Esta sección permite definir los detalles clave de la tarea, como su propósito, la persona responsable y la fecha estimada, para facilitar su gestión y seguimiento',
          },
        },
      },
      delete: {
        title: 'Borrar mantenimiento',
        subtitle: 'Borrar mantenimiento en el sistema',
        form: {
          submit: 'Borrar mantenimiento',
        },
      },
      list: {
        title: {
          ovoclinic: 'Mantenimientos',
          ovobank: 'Mantenimientos',
        },
        description: {
          ovoclinic: 'Gestión de los mantenimientos realizadas a los pacientes',
          ovobank: 'Gestión de los mantenimientos realizadas a los donantes',
        },
        create: 'Mantenimiento',
        one: {
          ovoclinic: 'Mantenimiento',
          ovobank: 'Mantenimiento',
        },
        many: {
          ovoclinic: 'Mantenimientos',
          ovobank: 'Mantenimientos',
        },
        columns: {
          clinicName: {
            ovoclinic: 'Clínica',
            ovobank: 'Clínica',
          },
          patientName: {
            ovoclinic: 'Paciente',
            ovobank: 'Donante',
          },
          serviceName: {
            ovoclinic: 'Servicio',
            ovobank: 'Servicio',
          },
          amount: {
            ovoclinic: 'Precio',
            ovobank: 'Precio',
          },
          paymentStatus: {
            ovoclinic: 'Pago',
            ovobank: 'Pago',
          },
          estimatedPaymentDate: {
            ovoclinic: 'Fecha estimada de pago',
            ovobank: 'Fecha estimada de pago',
          },
          programName: {
            ovoclinic: 'Programa',
            ovobank: 'Programa',
          },
          estimated: {
            ovoclinic: 'Fecha estimada',
            ovobank: 'Fecha estimada',
          },
          finished: {
            ovoclinic: 'Fecha finalización',
            ovobank: 'Fecha finalización',
          },
        },
      },
      enable: {
        title: 'Habilitar mantenimiento',
        subtitle: 'Habilitar mantenimiento en el sistema',
        form: {
          submit: 'Habilitar mantenimiento',
        },
      },
      disable: {
        title: 'Deshabilitar mantenimiento',
        subtitle:
          'El elemento no estará disponible en el CRM y solo las personas con un permiso específico podrán visualizar los elementos inactivos.',
        form: {
          submit: 'Deshabilitar mantenimiento',
        },
      },
    },
    actions: {
      enable: {
        title: 'Habilitar acción',
        subtitle: 'Habilitar acción en el sistema',
        form: {
          status: {
            title: '¿Estás seguro de que deseas habilitar este acción?',
            description: 'Habilitar un acción significa que podrá ser utilizado en el sistema',
            label: 'Sí, deseo habilitar este acción',
          },
          submit: 'Habilitar',
        },
      },
      disable: {
        title: 'Deshabilitar elemento',
        subtitle:
          'El elemento no estará disponible en el CRM y solo las personas con un permiso específico podrán visualizar los elementos inactivos.',
        form: {
          status: {
            title: '¿Estás seguro de que deseas deshabilitar este acción?',
            description:
              'Deshabilitar un acción significa que no podrá ser utilizado en el sistema',
            label: 'Sí, deseo deshabilitar este acción',
          },
          submit: 'Deshabilitar',
        },
      },
      list: {
        title: 'Calendario de tareas',
        description: 'Calendario de las tareas relacionado a los leads',
      },
      delete: {
        title: 'Eliminar tarea',
        subtitle: 'Al eliminar este elemento se borrará del sistema. Esta acción es irreversible.',
        form: {
          submit: 'Eliminar',
        },
      },
      update: {
        alerts: {
          create: {
            title: 'Alertas',
            subtitle: 'Añaadir alertas a la tarea',
            form: {
              alertTime: {
                title: 'Tiempo de alerta',
                description:
                  'Ingresa el número de minutos de antelación para recibir el aviso de la tarea',
              },
              submit: 'Añadir alerta',
            },
          },
          delete: {
            title: 'Eliminar alerta',
            subtitle:
              'Al eliminar este elemento se borrará del sistema. Esta acción es irreversible.',
            form: {
              submit: 'Eliminar alerta',
            },
          },
          update: {
            title: 'Actualizar alerta',
            subtitle: 'Actualizar alerta de la tarea',
            form: {
              alertTime: {
                title: 'Tiempo de alerta',
                description:
                  'Ingresa el número de minutos de antelación para recibir el aviso de la tarea',
              },
              submit: 'Actualizar alerta',
            },
          },
        },
      },
    },
    contacts: {
      list: {
        columns: {
          name: 'Nombre',
          phones: 'Teléfono',
          contactTime: 'Franja',
          consents: 'Consentimientos',
          marketing: 'Marketing',
          legal: 'Legal',
          created: 'Fecha de creación',
        },
        title: {
          ovoclinic: 'Contactos',
          ovobank: 'Contactos',
        },
        description: {
          ovoclinic:
            'Gestión de los contactos existentes en la aplicación. Un contacto es una ficha de una persona que puede ser un lead o un paciente.',
          ovobank:
            'Gestión de los contactos existentes en la aplicación. Un contacto es una ficha de una persona que puede ser un lead o un donante.',
        },
        create: {
          ovoclinic: 'Contacto',
          ovobank: 'Contacto',
        },
        one: 'Contacto',
        many: 'Contactos',
      },
      create: {
        title: {
          ovoclinic: 'Crear nuevo contacto',
          ovobank: 'Crear nuevo contacto',
        },
        subtitle: {
          ovoclinic:
            'Registra a una persona la primera vez que contacta con la empresa. Al completar este formulario, se crea el contacto con su información básica y, de forma automática, un lead vinculado a su origen y clínica de preferencia',
          ovobank:
            'Registra a una persona la primera vez que contacta con la empresa. Al completar este formulario, se crea el contacto con su información básica y, de forma automática, un potencial donante vinculado a su origen y clínica de preferencia',
        },
        form: {
          name: {
            title: 'Nombre',
            description: 'Primer nombre del contacto',
          },
          surname: {
            title: 'Apellidos',
            description: 'Apellidos del contacto',
          },
          prefix: {
            title: 'Prefijo',
            description: 'Código de país del teléfono',
          },
          phone: {
            title: 'Teléfono',
            description: 'Número de contacto para llamadas telefónicas',
          },
          email: {
            title: 'Email',
            description: 'Dirección de correo electrónico',
          },
          zip: {
            title: 'Código postal',
            description:
              'Utilizado para identificar su ubicación exacta y facilitar la segmentación geográfica.',
          },
          marketing: {
            title: 'Aceptación de recepción de marketing',
            label: 'Confirmar aceptación para recibir campañas de marketing',
            description: 'Indica si el contacto ha aceptado recibir comunicaciones de marketing',
          },
          birthDate: {
            title: 'Fecha de nacimiento',
            description: 'Fecha de nacimiento del contacto',
          },
          birthCountry: {
            title: 'País de nacimiento',
            description: 'País de nacimiento del contacto',
          },
          weight: {
            title: 'Peso (kg)',
            description: 'Peso del contacto en kilogramos',
          },
          height: {
            title: 'Altura (cm)',
            description: 'Altura del contacto en centímetros',
          },
          donationPreference: {
            title: 'Preferencia ciudad',
            description: 'Preferencia de ciudad donde realizar la donación',
          },
          legal: {
            title: 'Condiciones Legales',
            label: 'Confirmar aceptación de las condiciones legales',
            description:
              'Confirmación de que el contacto ha aceptado las políticas y términos legales',
          },
          contactTime: {
            title: 'Franja horaria de contacto',
            description: 'El rango de tiempo preferido por el contacto para recibir llamadas',
          },
          clinicId: {
            title: 'Clínica',
            description:
              'Elige la clínica de preferencia del contacto para crear y gestionar su lead desde la ubicación seleccionada ',
          },
          campaignId: {
            title: 'Campaña de marketing',
            description:
              'Selecciona la campaña que originó el interés del contacto. Esto permitirá asociar el lead a su origen',
          },
          center: {
            title: 'Ubicación',
            description: 'Ubicación del contacto',
          },
          photo: {
            title: 'Foto',
            description: 'Foto del contacto',
          },
          submit: 'Crear contacto',
        },
      },
      update: {
        title: {
          ovoclinic: 'Actualizar contacto',
          ovobank: 'Actualizar contacto',
        },
        subtitle: {
          ovoclinic:
            'Actualiza la información de un contacto existente. Al modificar los datos, se actualizan tanto el contacto como el lead vinculado, manteniendo la información al día.',
          ovobank:
            'Actualiza la información de un contacto existente. Al modificar los datos, se actualizan tanto el contacto como el lead vinculado, manteniendo la información al día.',
        },
        tabs: {
          account: {
            title: 'Datos Personales',
            description: 'Información detallada del contacto',
          },
          actions: {
            title: 'Actividades',
            description:
              'Representan las actividades o acciones específicas que deben realizarse dentro del proceso de gestión del lead o paciente. En este apartado se muestran todas las tareas realizadas relacionadas con este contacto.',
          },
          leads: {
            title: 'Campañas de marketing',
            description:
              'Esta pestaña muestra el historial de campañas en las que el contacto ha mostrado interés. Cada vez que el contacto entra por una campaña, se crea un lead asociado y se registra aquí.',
          },
          contact: {
            title: 'Contacto',
            description: 'Información detallada del contacto',
          },
          receipts: {
            title: 'Recibos',
            description: {
              ovoclinic:
                'Recibos creadas sobre el contacto. Recopila los pagos realizados por el contacto como paciente',
              ovobank:
                'Recibos creadas sobre el contacto. Recopila los pagos realizados por el contacto como donante',
            },
          },
          notes: {
            title: 'Observaciones',
            description:
              'Espacio para compartir información general sobre el contacto para recordar detalles importantes o para compartir detalles con otros empleados.',
          },
          purchases: {
            title: 'Contrataciones',
            description:
              'Registro de todas las contrataciones realizadas por este contacto, ya sean programas, servicios adicionales o mantenimiento, junto con su precio y detalles.',
          },
        },
        form: {
          name: {
            title: 'Nombre',
            description: 'Primer nombre del contacto',
          },
          surname: {
            title: 'Apellidos',
            description: 'Apellidos del contacto',
          },
          dni: {
            title: 'DNI',
            description: 'Número de identificación del contacto',
          },
          zip: {
            title: 'Código postal',
            description:
              'Utilizado para identificar su ubicación y facilitar la segmentación geográfica.',
          },
          marketing: {
            title: 'Aceptación de recepción de marketing',
            label: 'Confirmar aceptación para recibir campañas de marketing',
            description: 'Indica si el contacto ha aceptado recibir comunicaciones de marketing',
          },
          legal: {
            title: 'Condiciones Legales',
            label: 'Confirmar aceptación de las condiciones legales',
            description:
              'Confirmación de que el contacto ha aceptado las políticas y términos legales',
          },
          contactTime: {
            title: 'Franja horaria de contacto',
            description: 'El rango de tiempo preferido por el contacto para recibir llamadas',
          },
          center: {
            title: 'Ubicación',
            description: '** Dirección del contacto',
          },
          prefix: {
            title: 'Prefijo',
            description: 'Código de país del teléfono',
          },
          phone: {
            title: 'Teléfono',
            description: 'Número de contacto para llamadas telefónicas',
          },
          email: {
            title: 'Email',
            description: 'Dirección de correo electrónico',
          },
          birthDate: {
            title: 'Fecha de nacimiento',
            description: 'Fecha de nacimiento del contacto',
          },
          birthCountry: {
            title: 'País de nacimiento',
            description: 'País de nacimiento del contacto',
          },
          weight: {
            title: 'Peso (kg)',
            description: 'Peso del contacto en kilogramos',
          },
          height: {
            title: 'Altura (cm)',
            description: 'Altura del contacto en centímetros',
          },
          donationPreference: {
            title: 'Preferencia ciudad',
            description: 'Preferencia de ciudad donde realizar la donación',
          },
          photo: {
            title: 'Foto',
            description: 'Foto del contacto',
          },
          submit: 'Actualizar contacto',
        },
        purchases: {
          list: {
            columns: {
              purchaseType: 'Tipo',
              treatmentName: 'Tratamiento',
              price: 'Precio',
              clinicName: 'Clínica',
            },
            one: 'Contratación',
            many: 'Contrataciones',
          },
        },
      },
      delete: {
        title: 'Eliminar contacto',
        subtitle: 'Al eliminar este elemento se borrará del sistema. Esta acción es irreversible.',
        form: {
          status: {
            title: '¿Estás seguro de que deseas eliminarlo?',
            description: 'Eliminar un contacto significa que no podrá ser utilizado en el sistema',
            label: 'Sí, deseo eliminar este contacto',
          },
          confirm: 'Esta acción eliminará todo lo relacionado con el contacto. ¿Estás seguro?',
          submit: 'Sí, eliminar contacto',
        },
      },
      enable: {
        title: 'Habilitar contacto',
        subtitle: 'Habilitar contacto en el sistema',
        form: {
          status: {
            title: '¿Estás seguro de que deseas habilitar este contacto?',
            description:
              'Al habilitar este contacto vuelve a estar disponible en el sistema ¿Estás seguro?',
            label: 'Sí, deseo habilitar este contacto',
          },
          submit: 'Habilitar',
        },
      },
      disable: {
        title: 'Inhabilitar contacto',
        subtitle: 'Al inhabilitar este elemento dejará de estar disponible en el crm.',
        form: {
          status: {
            title: '¿Estás seguro de que deseas inhabilitar este contacto?',
            description:
              'Inhabilitar un contacto significa que no podrá ser utilizado en el sistema, ¿estás seguro?',
            label: 'Sí, deseo inhabilitar este contacto',
          },
          submit: 'Inhabilitar contacto',
        },
      },
      notes: {
        source: {
          LEAD: 'Lead',
          PATIENT: {
            ovoclinic: 'Paciente',
            ovobank: 'Donante',
          },
        },
      },
    },
    campaigns: {
      list: {
        title: 'Campañas de marketing',
        description:
          'Acciones promocionales realizadas a través de diferentes canales como redes sociales o webs, por donde ingresan los leads interesados en los servicios de la clínica.',
        userId: {
          title: 'Encargado',
          description: 'Encargado de la campaña',
        },
        submit: 'Asignar encargado',
        create: 'Campaña',
        one: 'Campaña',
        many: 'Campañas de marketing',
        columns: {
          name: 'Nombre',
          leads: 'Leads',
          leadsStatus: 'Activos/Inactivos',
          funnelName: 'Embudo',
          languageName: 'Idioma',
          clinics: 'Clínicas',
          managers: 'Encargados',
        },
      },
      create: {
        title: 'Nueva campaña',
        subtitle:
          'Registra aquí los datos principales de la campaña de marketing para su creción y seguimiento en el CRM',
        form: {
          image: {
            title: 'Carátula',
            description:
              'Elige una imagen para identificar la campaña. Escribe una palabra y aparecerán opciones para seleccionar fácilmente',
          },
          name: {
            title: 'Título de la campaña de marketing',
            description:
              'Introduce el nombre de la campaña de marketing para facilitar su identificación',
          },
          facebookId: {
            title: 'Facebook ID',
            description:
              'Introduce el ID de Facebook de la campaña para vincularla con la campaña correspondiente en la plataforma',
          },
          code: {
            title: 'Código identificativo',
            description:
              'Introduce un código único para identificar de manera fácil y rápida la campaña',
          },
          budget: {
            title: 'Presupuesto',
            description:
              'Introduce el monto invertido en la campaña de marketing. Esta información permitirá sacar estadisticas sobre la efectividad de la campaña.',
          },
          clinicId: {
            title: 'Clínica financiadora',
            description: 'Selecciona la clínica que ha financiado la campaña de marketing',
          },
          firstActivityId: {
            title: 'Primera actividad',
            description:
              'Selecciona la primera actividad que se asignará automáticamente al lead al entrar a través de esta campaña',
          },
          funnelId: {
            title: 'Embudo',
            description: 'Elige el embudo que define la procedencia de los leads para esta campaña',
          },
          status: {
            title: 'Campaña activa',
            description: 'Habilitar o deshabilitar la campaña en el sistema',
            label: 'Campaña activa',
          },
          languageId: {
            title: 'Idioma',
            description:
              'Selecciona el idioma en el que se llevará a cabo la campaña. Esta elección determinará el departamento encargado de gestionar los leads según el idioma seleccionado. Por ejemplo, si se selecciona francés, el seguimiento será gestionado por el departamento de habla francesa.',
          },
          startDate: {
            title: 'Fecha de inicio',
            description: 'Fecha de inicio de la campaña',
          },
          submit: 'Crear campaña',
        },
        steps: {
          channel: {
            form: {
              channelId: {
                title: 'Canal',
                description:
                  'Selecciona el canal a través del cual se llevará a cabo la campaña de marketing, como redes sociales, email, sitio web u otros',
              },
            },
          },
        },
      },
      enable: {
        title: 'Habilitar campaña',
        subtitle: 'Habilitar campaña en el sistema',
        form: {
          status: {
            title: '¿Estás seguro de que deseas habilitarla?',
            description: 'De esta manera estará disponible en el sistema',
            label: 'Sí, deseo habilitar esta campaña',
          },
          submit: 'Habilitar',
        },
      },
      disable: {
        title: 'Deshabilitar campaña',
        subtitle:
          'El elemento no estará disponible en el CRM y solo las personas con un permiso específico podrán visualizar los elementos inactivos.',
        form: {
          status: {
            title: '¿Estás seguro de que deseas deshabilitarla?',
            description: 'De esta manera no estará disponible en el sistema',
            label: 'Sí, deseo deshabilitar esta campaña',
          },
          submit: 'Deshabilitar',
        },
      },
      update: {
        title: 'Actualizar campaña',
        subtitle:
          'Edita los detalles de la campaña de marketing actual. Ten en cuenta que cualquier cambio en las integraciones con Facebook puede afectar a la operatividad y al seguimiento de la campaña.',
        tabs: {
          general: {
            title: 'General',
            description: 'Edita los datos principales de la campaña. ',
          },
          contact: {
            title: 'Contacto',
            description:
              'Mediante este formulario puedes crear un contacto de esta campaña. Este formulario es muy útil para validar el comportamiento de la campaña de la misma forma que lo haría un usuario final.',
          },
          managers: {
            title: 'Encargados',
            description:
              'Los encargados de la campaña serán responsables de los leads que ingresen. Si la primera tarea programada está configurada, se asignará automáticamente a ellos. Estos leads y tareas aparecerán en los listados correspondientes.',
          },
          financing: {
            title: 'Financiación',
            description:
              'En esta sección se agrega la información relacionada con la financiación de la campaña. Aquí podrás indicar el monto invertido y la clínica financiadora',
          },

          webhook: {
            title: 'Webhook',
            description:
              'Ingresa el webhook para conectar esta campaña con Facebook. Esto permitirá sincronizar las campañas registradas en Facebook y asociar automáticamente los leads con el origen de esta campaña.',
            label: 'Webhook de la campaña',
            subtitle:
              'Copia este webhook para conectar la landing page con la campaña. Al hacerlo, los datos de los usuarios registrados en la landing se transferirán automáticamente al CRM.',
            label_ovobank: 'Webhook para campañas nacionales',
            subtitle_ovobank:
              'Copia este webhook para conectar la landing page con la campaña. Los datos de los usuarios registrados se transferirán automáticamente al CRM. Esta opción no permite volcar fotos.',
            label_ovobank_static: 'Webhook para campañas internacionales',
            subtitle_ovobank_static:
              'Copia este webhook para conectar la landing page con la campaña. Los datos, incluida la foto y ciudad de preferencia se transferirán automáticamente.',
            toast: 'Link copiado al portapapeles',
          },
          participants: {
            title: 'Clínicas receptoras',
            description:
              'Selecciona las clínicas para las que se ha lanzado la campaña. Los leads generados serán distribuidos entre las clínicas seleccionadas para su seguimiento y gestión.',
          },
        },
        participants: {
          list: {
            one: 'Clínica receptora',
            many: 'Clínicas receptoras',
            columns: {
              clinicName: 'Clínica',
            },
          },
          clinicsAdded: 'Clínicas añadidas',
          create: {
            title: 'Añadir clínica receptora',
            subtitle: 'Añadir una clínica receptora a la campaña',
            form: {
              clinicId: {
                title: 'Clínica',
                description: 'Añadir clínica receptora a la campaña',
              },
              submit: 'Añadir clínica receptora',
            },
          },
          delete: {
            title: 'Eliminar clínica receptora',
            subtitle:
              'Al eliminar este elemento se borrará del sistema. Esta acción es irreversible.',
            form: {
              status: {
                title: '¿Estás seguro de que deseas eliminarlo?',
                description:
                  'Esta clínica receptora dejará de mostrarse en la campaña. ¿estás seguro?',
                label: 'Sí, deseo eliminar esta clínica receptora',
              },
              submit: 'Eliminar',
            },
          },
          add: 'Añadir',
          addAll: 'Añadir todas',
          clinicIdCopied: 'ID de la clínica copiado al portapapeles',
        },
        financing: {
          form: {
            budget: {
              title: 'Presupuesto',
              description:
                'Introduce el monto invertido en la campaña de marketing. Esta información permitirá sacar estadisticas sobre la efectividad de la campaña.',
            },
            financingClinicId: {
              title: 'Clínica financiadora',
              description: 'Selecciona la clínica que ha financiado la campaña de marketing',
            },
            submit: 'Actualizar financiación',
          },
        },
        managers: {
          list: {
            one: 'encargado',
            many: 'encargados',
            columns: {
              name: 'Nombre',
            },
          },
          add: 'Añadir encargado',
        },
        manager: {
          title: 'Asignar encargado',
          subtitle: 'Asignar un encargado a la campaña',
        },
        contact: {
          name: {
            title: 'Nombre',
            description: 'Primer nombre del contacto',
          },
          surname: {
            title: 'Apellidos',
            description: 'Apellidos del contacto',
          },
          prefix: {
            title: 'Prefijo',
            description: 'Código de país del teléfono',
          },
          phone: {
            title: 'Teléfono',
            description: 'Número de contacto para llamadas telefónicas',
          },
          email: {
            title: 'Email',
            description: 'Dirección de correo electrónico',
          },
          zip: {
            title: 'Código postal',
            description:
              'Utilizado para identificar su ubicación y facilitar la segmentación geográfica.',
          },
          legal: {
            title: 'Condiciones Legales',
            label: 'Confirmar aceptación de las condiciones legales',
            description:
              'Confirmación de que el contacto ha aceptado las políticas y términos legales',
          },
          marketing: {
            title: 'Aceptación de recepción de marketing',
            label: 'Confirmar aceptación para recibir campañas de marketing',
            description: 'Indica si el contacto ha aceptado recibir comunicaciones de marketing',
          },
          contactTime: {
            title: 'Franja horaria de contacto',
            description: 'El rango de tiempo preferido por el contacto para recibir llamadas',
          },
          clinicId: {
            title: 'Clínica',
            description:
              'Selecciona la campaña que originó el interés del contacto. Esto permitirá asociar el lead a su origen.',
          },
          center: {
            title: 'Ubicación',
            description: 'Ubicación del contacto',
          },
          birthDate: {
            title: 'Fecha de nacimiento',
            description: 'Fecha de nacimiento del contacto',
          },
          birthCountry: {
            title: 'País de nacimiento',
            description: 'País de nacimiento del contacto',
          },
          weight: {
            title: 'Peso (kg)',
            description: 'Peso del contacto en kilogramos',
          },
          height: {
            title: 'Altura (cm)',
            description: 'Altura del contacto en centímetros',
          },
          donationPreference: {
            title: 'Ciudad de preferencia',
            description: 'Preferencia de ciudad donde realizar la donación',
          },
          photo: {
            title: 'Foto',
            description: 'Foto del contacto',
          },
          submit: 'Añadir contacto',
        },
        form: {
          name: {
            title: 'Título de la campaña de marketing',
            description:
              'Introduce el nombre de la campaña de marketing para facilitar su identificación',
          },
          facebookId: {
            title: 'Facebook ID',
            description:
              'Introduce el ID de Facebook de la campaña para vincularla con la campaña correspondiente en la plataforma',
          },
          code: {
            title: 'Código identificativo',
            description:
              'Introduce un código único para identificar de manera fácil y rápida la campaña',
          },
          budget: {
            title: 'Presupuesto',
            description:
              'Introduce el monto invertido en la campaña de marketing. Esta información permitirá sacar estadisticas sobre la efectividad de la campaña.',
          },
          image: {
            title: 'Carátula',
            description:
              'Elige una imagen para identificar la campaña. Escribe una palabra y aparecerán opciones para seleccionar fácilmente',
          },
          funnelId: {
            title: 'Embudo',
            description: 'Elige el embudo que define la procedencia de los leads para esta campaña',
          },
          clinicId: {
            title: 'Clínica financiadora',
            description: 'Selecciona la clínica que ha financiado la campaña de marketing',
          },
          startDate: {
            title: 'Fecha de inicio',
            description: 'Selecciona la fecha de inicio de la campaña marketing',
          },
          endDate: {
            title: 'Fecha de fin',
            description: 'Selecciona la fecha de fin de la campaña de marketing',
          },
          firstActivityId: {
            title: 'Primera actividad',
            description:
              'Selecciona la primera actividad que se asignará automáticamente al lead al entrar a través de esta campaña',
          },
          status: {
            title: 'Campaña activa',
            description: 'Habilitar o deshabilitar la campaña en el sistema',
            label: 'Campaña activa',
          },
          languageId: {
            title: 'Idioma',
            description:
              'Selecciona el idioma en el que se llevará a cabo la campaña. Esta elección determinará el departamento encargado de gestionar los leads según el idioma seleccionado. Por ejemplo, si se selecciona francés, el seguimiento será gestionado por el departamento de habla francesa.',
          },
          submit: 'Actualizar campaña',
        },
      },
      delete: {
        title: 'Eliminar campaña',
        subtitle: 'Al eliminar este elemento se borrará del sistema. Esta acción es irreversible.',
        manager: {
          title: 'Eliminar campaña',
          subtitle:
            'Esta campaña dejará de existir en el sistema, ¿Estás seguro de querer eliminar la campaña?',
          form: {
            submit: 'Eliminar',
          },
        },
        form: {
          status: {
            title: '¿Estás seguro de que deseas eliminarlo?',
            description:
              'Eliminar la campaña es irreversible, tal vez prefieras desactivar esta campaña. ¿Estás seguro de querer eliminarla?',
            label: 'Sí, deseo eliminar esta campaña',
          },
          success: 'Campaña eliminada correctamente',
          error: 'Error al eliminar la campaña',
          confirm: 'Esta acción eliminará todo lo relacionado con la campaña, ¿estás seguro?',
          submit: 'Eliminar',
        },
      },
    },
    activities: {
      list: {
        title: 'Actividades',
        description:
          'Representan los tipos de actividades que se utilizan en la gestión de tareas para leads o pacientes. Se configuran con características como nombre, duración y prioridad, y se aplican al crear y gestionar las tareas asociadas',
        create: 'Actividad',
        one: 'actividad',
        many: 'actividades',
        columns: {
          name: 'Nombre',
          description: 'Descripción',
          position: 'Posición',
          icon: 'Icono',
          color: 'Color',
          time: 'Duración',
        },
      },
      update: {
        subtitle:
          'Edita las características principales y otros detalles relacionados con la actividad, como posibles resultados, actividades posteriores o ajustes relacionados',
        title: 'Modificar actividad',
        form: {
          submit: 'Guardar',
          name: {
            title: 'Nombre de la actividad',
            description:
              'Representará el tipo de acción a realizar al crear una la tarea, como una llamada, visita o seguimiento',
          },
          icon: {
            title: 'Icono',
            description: 'Elige un icono para identificar fácilmente la actividad',
            label: 'Seleccionar icono...',
            search: {
              placeholder: 'Buscar icono...',
            },
          },
          position: {
            title: 'Posición',
            description:
              'Selecciona la prioridad de la actividad. El número más bajo indica mayor prioridad. En el listado de actividades se visualizarán en este orden',
          },
          description: {
            title: 'Descripción',
            description: 'Proporciona más detalles sobre la actividad con una breve descripción',
          },
          color: {
            title: 'Color',
            description:
              'Selecciona un color para la actividad. Esto ayudará a identificarla fácilmente en el calendario según su tipo',
          },
          time: {
            title: 'Duración',
            description:
              'Indica el tiempo estimado para completar esta actividad. Este valor se utilizará para reservar el espacio correspondiente en el calendario.',
          },
          primary: {
            title: 'Actividad principal',
            description:
              'Las actividades principales son aquellas que son mostradas en el dashboard del comercial para permitir interactuar rápidamente con los contactos',
            label: 'Mostrar en el dashboard',
          },
        },
        tabs: {
          activity: {
            title: 'Actividad',
            description:
              'Edita los datos principales de la actividad, como su nombre, descripción y características.',
          },
          results: {
            title: 'Resultados',
            description: 'Los resultados describen lo ocurrido tras realizar la actividad.',
          },
          scopes: {
            title: 'Ámbitos',
            description:
              'Los ámbitos definen qué departamentos están relacionados con una actividad. Al seleccionar un departamento, los empleados de ese departamento podrán acceder y utilizar dicha actividad.',
          },
          communicationChannels: {
            title: 'Vías de comunicación',
            description:
              'Las vías de comunicación definen los canales disponibles para realizar actividades, como correo electrónico, teléfono o mensajes. Aquí puedes crear y configurar las vías utilizadas en la organización.',
          },
          integrations: {
            title: 'Integraciones',
            description:
              'En esta pantalla puedes gestionar la integracion con Landbot para automatizar el envío de mensajes y la programación atomática de la siguiente actividad',
          },
        },
        results: {
          one: 'resultado',
          many: 'resultados',
          add: 'Añadir resultado',
        },
        scopes: {
          one: 'Ámbito',
          many: 'Ámbitos',
          add: 'Añadir ámbito',
        },
        communicationChannels: {
          add: 'Añadir vía de comunicación',
          list: {
            one: 'Vía de comunicación',
            many: 'Vías de comunicación',
            columns: {
              channelName: 'Nombre',
            },
          },
          create: {
            title: 'Añadir vía de comunicación',
            subtitle: 'Añadir vía de comunicación a la actividad',
            form: {
              title: 'Vía de comunicación',
              subtitle: 'Vía de comunicación asociada a la actividad',
              submit: 'Añadir',
              id: {
                title: 'Vía de comunicación',
                description: 'Vía de comunicación asociada a la actividad',
              },
            },
          },
          delete: {
            title: 'Eliminar vía de comunicación',
            subtitle:
              'Al eliminar este elemento se borrará del sistema. Esta acción es irreversible.',
            form: {
              submit: 'Eliminar',
            },
          },
        },
        integrations: {
          landbotId: {
            title: 'ID del bot de Landbot',
            description:
              'Introduce el ID del bot comercial de Landbot, el cual interactúa con el lead para responder a sus preguntas y generar una actividad en el CRM (como una cita para una visita informativa), una vez que el lead haya respondido al mensaje automático.',
            label: 'Activar integración LandBot',
          },
          landbotTemplateId: {
            title: 'ID de plantilla de Landbot',
            description:
              'Ingresa el ID de la plantilla de Landbot que se enviará automáticamente al contacto en la fecha y hora programadas para esta actividad.',
          },
          landbotActivityId: {
            title: 'Actividad a programar automáticamente',
            description:
              'Elige la actividad que debe programarse automáticamente si el bot alcanza su objetivo. Por ejemplo, una visita informativa comercial, que se organizará automáticamente con los datos proporcionados por el contacto',
          },
          landbotResultId: {
            title: 'Resultado asociado a la acción del bot',
            description:
              'Si el bot cumple con su objetivo (por ejemplo, cerrar una visita informativa), selecciona el resultado correspondiente, el cual se registrará automáticamente para dar paso al siguiente paso en el proceso',
          },
          submit: 'Guardar',
        },
      },
      enable: {
        title: 'Habilitar actividad',
        subtitle: 'Habilitar actividad en el sistema',
        form: {
          status: {
            title: '¿Estás seguro de que deseas habilitar esta actividad?',
            description: 'Habilitar una actividad significa que podrá ser utilizado en el sistema',
            label: 'Sí, deseo habilitar esta actividad',
          },
          submit: 'Habilitar',
        },
      },
      disable: {
        title: 'Deshabilitar actividad',
        subtitle:
          'El elemento no estará disponible en el CRM y solo las personas con un permiso específico podrán visualizar los elementos inactivos.',
        form: {
          status: {
            title: '¿Estás seguro de que deseas deshabilitar esta actividad?',
            description:
              'Deshabilitar una actividad significa que no podrá ser utilizado en el sistema',
            label: 'Sí, deseo deshabilitar esta actividad',
          },
          submit: 'Deshabilitar',
        },
      },
      create: {
        title: 'Crear actividad',
        subtitle: 'Registra una nueva actividad definiendo su tipo y características',
        form: {
          submit: 'Crear',
          name: {
            title: 'Nombre de la actividad',
            description:
              'Representará el tipo de acción a realizar al crear una la tarea, como una llamada, visita o seguimiento',
          },
          icon: {
            title: 'Icon',
            description: 'Elige un icono para identificar fácilmente la actividad',
            label: 'Seleccionar icono...',
            search: {
              placeholder: 'Buscar icono...',
            },
          },
          position: {
            title: 'Posición',
            description:
              'Selecciona la prioridad de la actividad. El número más bajo indica mayor prioridad. En el listado de actividades se visualizarán en este orden',
          },
          description: {
            title: 'Descripción',
            description: 'Proporciona más detalles sobre la actividad con una breve descripción',
          },
          color: {
            title: 'Color',
            description:
              'Selecciona un color para la actividad. Esto ayudará a identificarla fácilmente en el calendario según su tipo',
          },
          time: {
            title: 'Duración',
            description:
              'Indica el tiempo estimado para completar esta actividad. Este valor se utilizará para reservar el espacio correspondiente en el calendario.',
          },
          primary: {
            title: 'Actividad principal',
            description:
              'Las actividades principales son aquellas que son mostradas en el dashboard del comercial para permitir interactuar rápidamente con los contactos',
            label: 'Mostrar en el dashboard',
          },
        },
      },
    },
    scopes: {
      list: {
        title: 'Ámbitos',
        description: 'Gestión de los ambitos de las actividades en el sistema',
        create: 'Ámbito',
        one: 'Ámbito',
        many: 'Ámbitos',
        columns: {
          clinicName: 'Clínica',
          departmentName: 'Departamento',
        },
      },
      create: {
        title: 'Crear ámbito',
        subtitle: 'Crear ámbito en el sistema',
        form: {
          submit: 'Crear',
          clinicId: {
            title: 'Clínica',
            description: 'Clínica a la que pertenece el ámbito',
          },
          departmentId: {
            title: 'Departamento',
            description: 'Departamento al que pertenece el ámbito',
          },
          departmentIds: {
            title: 'Departamentos',
            description: 'Departamentos al que va a tener ámbito la plantilla',
          },
        },
      },
      delete: {
        title: 'Eliminar ámbito',
        subtitle: 'Al eliminar este elemento se borrará del sistema. Esta acción es irreversible.',
        form: {
          submit: 'Eliminar',
        },
      },
    },
    results: {
      list: {
        title: 'Resultados',
        description:
          'Esta pantalla muestra el listado y los detalles de los resultados generados para cada actividad. Los resultados describen lo ocurrido tras realizar la actividad.',
        create: 'Añadir Resultado',
        one: 'resultado',
        many: 'resultados',
        columns: {
          name: 'Nombre',
          description: 'Descripción',
          icon: 'Icono',
          color: 'Color',
          time: 'Duración estimada',
          position: 'Posición',
          activityName: 'Actividad',
          created: 'Creación',
        },
      },
      update: {
        subtitle:
          'Edita las características principales y otros detalles relacionados con el resultado, como siguiente actividad, reactividad o hitos',
        title: 'Modificar resultado',
        form: {
          submit: 'Actualizar',
          name: {
            title: 'Nombre',
            description: 'Nombre identificativo del resultado',
          },
          icon: {
            title: 'Icono',
            label: 'Icono',
            description:
              'Icono para identificar el resultado. Un icono puede ayudar a asociar un resultado.',
            search: {
              placeholder: 'Buscar icono...',
            },
          },
          position: {
            title: 'Posición',
            description:
              'El número más bajo indica mayor prioridad. En el listado de resultados se visualizarán en este orden.',
          },
          description: {
            title: 'Descripción',
            description:
              'Proporciona más detalles sobre el contexto en el que se debe aplicar este resultado con una breve descripción',
          },
          color: {
            title: 'Color',
            description:
              'Selecciona un color para el resultado. Esto ayudará a identificarla fácilmente en el listado de resultados',
          },
          time: {
            title: 'Duración',
            description: 'Duración estimada que se tarda en realizar el resultado.',
          },
          activityId: {
            title: 'Actividad',
            description: 'Actividad a la que pertenece el resultado.',
          },
          nextActivityId: {
            title: 'Siguiente actividad',
            description:
              'La selección de la siguiente actividad permite que, al registrar este resultado, el sistema cree automáticamente una tarea con esta actividad para facilitar la continuidad del seguimiento',
          },
          nextActivityDelay: {
            title: 'Tiempo de espera en días',
            description:
              'Introduce el tiempo de espera medio entre este resultado y la siguiente actividad. Este dato permitirá que el sistema sugiera automáticamente una fecha y hora para la tarea, calculada a partir del momento en que se registre el resultado',
          },
          disableLead: {
            title: 'Deshabilitar lead',
            description:
              'Activa esta opción para deshabilitar automáticamente el lead al registrar este resultado',
            label: 'Deshabilitar automáticamente el lead',
          },
          contacted: {
            title: 'Lead contactado',
            description: 'Se ha establecido contacto con el lead',
            label: 'Sí, el lead ha sido contactado',
          },
          informed: {
            title: 'Visita informativa comercial realizada',
            description:
              'Con esta información se podrá contabilizar cuando el lead acudio a la cita comercial informativa',
            label: 'Sí, el lead ha realizado la VI comercial',
          },
          converted: {
            title: {
              ovoclinic: 'Lead convertido en paciente',
              ovobank: 'Lead convertido en donante',
            },

            description: {
              ovoclinic:
                'El lead ha sido convertido en paciente. Esto implica que el lead va a ser transferido a ATP medico',
              ovobank:
                'El lead ha sido convertido en donante. Esto implica que el lead va a ser transferido a ATP medico',
            },
            label: {
              ovoclinic: 'Sí, el lead ha sido convertido',
              ovobank: 'Sí, el lead ha sido convertido',
            },
          },
          appointed: {
            title: 'Lead citado para visita informativa comercial',
            description:
              'Con esta información se podrá contabilizar cuando se citó al lead para la visita informativa comercial',
            label: 'Sí, el lead ha sido citado para una VI comercial',
          },
          attended: {
            title: 'Visita informativa médica realizada',
            description:
              'Con esta información se podrá contabilizar cuando el lead acudio a la visita informativa médica',
            label: 'Sí, el lead ha tenido una VI médica',
          },
          comercialAttended: {
            title: 'Presupuesto entregado',
            description:
              'Con esta información se podrá contabilizar cuando se entrega un presupuesto al lead',
            label: 'Sí, el lead ha recibido un presupuesto',
          },
          medicalAppointed: {
            title: 'Lead citado para visita informativa médica',
            description:
              'Con esta información se podrán contabilizar cuando se citó para la visita informativa médica',
            label: 'Sí, el lead ha sido citado para una VI médica',
          },
          medicalEvaluated: {
            title: 'Evaluación médica realizada',
            description:
              'Con esta información se podrá contabilizar las evaluaciones médicas realizadas en los departamentos internacionales',
            label: 'Sí, el lead ha sido evaluado',
          },
          templateId: {
            title: 'Plantilla',
            description: 'Selecciona la plantilla que se enviará al introducir este resultado',
          },
          landbotId: {
            title: 'ID del bot de Landbot',
            description:
              'Introduce el ID del bot comercial de Landbot, el cual interactúa con el lead para responder a sus preguntas y generar una actividad en el CRM una vez que el lead haya respondido a la plantilla',
          },
          landbotAppointed: {
            title: 'Citado para visita informativa comercial por LandBot',
            description:
              'Con está información se contabilizá cuando el bot de Lanbot consigué agendar una visita informativa comercial automaticamente',
            label: 'Sí, el bot ha concertado una cita',
          },
        },
        formOvoBank: {
          appointed: {
            title: 'Lead citado para visita informativa comercial',
            description: 'Se ha agendado una cita con el lead',
            label: 'Sí, el lead ha sido citado',
          },
          comercialAttended: {
            title: 'Visita informativa comercial realizada',
            description:
              'Con esta información se podrá contabilizar cuando el lead acudio a la cita comercial informativa',
            label: 'Sí, el lead ha tenido una visita informativa comercial',
          },
          medicalAppointed: {
            title: 'Lead citado para visita informativa médica',
            description:
              'Con esta información se podrá contabilizar cuando se citó para la visita informativa médica',
            label: 'Sí, el lead ha sido citado para una VI médica',
          },
          attended: {
            title: 'Visita informativa médica realizada',
            description:
              'Con esta información se podrá contabilizar cuando el lead acudio a la cita médica',
            label: 'Sí, el lead ha tenido una cita médica',
          },
          converted: {
            title: 'Ápto médico',
            description: 'El lead ha sido considerado apto para donar',
            label: 'Sí, el lead ha sido considerado apto',
          },
          submit: 'Actualizar',
        },
        tabs: {
          result: {
            title: 'Resultado',
            description:
              'Edita los datos principales del resultado, como su nombre, descripción y características.',
          },
          template: {
            title: 'Plantilla',
            description:
              'En esta pantalla puedes gestionar la integracion con Landbot para automatizar el envío de mensajes',
          },
          nextActivity: {
            title: 'Siguiente actividad',
            description:
              'La selección de la siguiente actividad permite que, al registrar este resultado, el sistema cree automáticamente una tarea con esta actividad para facilitar la continuidad del seguimiento',
          },
          ccAchievement: {
            title: 'Hitos de Call Center',
            description:
              'Los hitos registran los logros alcanzados en relación a un resultado específico. Estos registros son clave para medir y reflejar el progreso en el embudo de ventas',
          },
          atpcAchievement: {
            title: 'Hitos de ATP Comercial',
            description:
              'Los hitos registran los logros alcanzados en relación a un resultado específico. Estos registros son clave para medir y reflejar el progreso en el embudo de ventas',
          },
          atpiAchievement: {
            title: 'Hitos de ATP Internacional',
            description:
              'Los hitos registran los logros alcanzados en relación a un resultado específico. Estos registros son clave para medir y reflejar el progreso en el embudo de ventas',
          },
          ovoBankAtpAchievement: {
            title: 'Hitos de Donantes',
            description:
              "Hitos que se deben cumplir para que el resultado sea considerado 'logrado'",
          },
          integrationAchievement: {
            title: 'Hitos de Integración',
            description:
              'Los hitos que se podrían cumplir por alguna integración. Ejemplo: LandBot',
          },
          reactivity: {
            title: 'Reactividad',
            description:
              'La acción que se se configure en está sección activará automáticamente al registrar este resultado',
          },
        },
      },
      enable: {
        title: 'Habilitar resultado',
        subtitle: 'Habilitar resultado en el sistema',
        form: {
          status: {
            title: '¿Estás seguro de que deseas habilitar este resultado?',
            description: 'Habilitar un resultado significa que podrá ser utilizado en el sistema',
            label: 'Sí, deseo habilitar este resultado',
          },
          submit: 'Habilitar',
        },
      },
      disable: {
        title: 'Deshabilitar resultado',
        subtitle:
          'El elemento no estará disponible en el CRM y solo las personas con un permiso específico podrán visualizar los elementos inactivos.',
        form: {
          status: {
            title: '¿Estás seguro de que deseas deshabilitar este resultado?',
            description:
              'Deshabilitar un resultado significa que no podrá ser utilizado en el sistema',
            label: 'Sí, deseo deshabilitar este resultado',
          },
          submit: 'Deshabilitar',
        },
      },
      create: {
        title: 'Crear resultado',
        subtitle: 'Crear resultado de actividad en el sistema',
        form: {
          submit: 'Crear',
          name: {
            title: 'Nombre del resultado',
            description: 'Representará el resultado obtenido',
          },
          icon: {
            title: 'Icono',
            description: 'Elige un icono para identificar fácilmente el resultado',
            label: 'Icono',
            search: {
              placeholder: 'Buscar icono...',
            },
          },
          position: {
            title: 'Posición',
            description:
              'En el listado de los resultados se visualizarán en este orden, el número más bajo será el primero',
          },
          description: {
            title: 'Descripción',
            description:
              'Proporciona más detalles sobre el contexto en el que hay que hacer uso de este resultado con una breve descripción',
          },
          color: {
            title: 'Color',
            description:
              'Color identificativo del resultado. Puede ayudar a identificar el resultado.',
          },
          time: {
            title: 'Duración',
            description: 'Duración estimada que se tarda en realizar el resultado.',
          },
          activityId: {
            title: 'Actividad',
            description: 'Actividad a la que pertenece el resultado.',
          },
          nextActivityId: {
            title: 'Siguiente actividad',
            description:
              'La selección de la siguiente actividad permite que, al registrar este resultado, el sistema cree automáticamente una tarea con esta actividad para facilitar la continuidad del seguimiento',
          },
          nextActivityDelay: {
            title: 'Tiempo de espera en días',
            description:
              'Introduce el tiempo de espera medio entre este resultado y la siguiente actividad. Este dato permitirá que el sistema sugiera automáticamente una fecha y hora para la tarea, calculada a partir del momento en que se registre el resultado',
          },
          contacted: {
            title: 'Lead contactado',
            description: 'Se ha establecido contacto con el lead',
            label: 'Sí, el lead ha sido contactado',
          },
          informed: {
            title: 'Visita informativa comercial realizada',
            description:
              'Con esta información se podrá contabilizar cuando el lead acudio a la cita comercial informativa',
            label: 'Sí, el lead ha sido realizado la visita informativa comercial',
          },
          converted: {
            title: 'Convertir lead',
            description: {
              ovoclinic:
                'El lead ha sido convertido en paciente. Esto implica que el lead va a ser transferido a ATP medico',
              ovobank:
                'El lead ha sido convertido en donante. Esto implica que el lead va a ser transferido a ATP medico',
            },
            label: 'Sí, el lead ha sido convertido',
          },
          appointed: {
            title: 'Lead citado para visita informativa comercial',
            description:
              'Con esta información se podrá contabilizar se citó al lead para la visita comercial informativa',
            label: 'Sí, el lead ha sido citado para la VI comercial',
          },
        },
      },
    },
    services: {
      list: {
        title: 'Servicios',
        description:
          'Servicios independientes de los programas que Ovoclinic ofrece para que los pacientes puedan contratarlos.',
        create: 'Servicio',
        one: 'servicio',
        many: 'servicios',
        columns: {
          name: 'Nombre',
          price: 'Precio',
          nextActivityName: 'Próxima actividad',
          nextActivityDelay: 'Tiempo pospuesto de actividad',
        },
      },
      update: {
        title: 'Modificar servicio',
        subtitle:
          'Modifica los detalles del servicio existente. Las modificaciones se reflejarán en los detalles de los pacientes que tengan contratado este servicio.',
        form: {
          submit: 'Actualizar',
          name: {
            title: 'Nombre',
            description:
              'Nombre identificativo del servicio. Al asignar un servicio al paciente se reconocerá por este nombre.',
          },
          price: {
            title: 'Precio',
            description: 'Precio del servicio para el paciente',
          },
          nextActivityId: {
            title: 'Próxima actividad',
            description:
              'Selecciona la actividad a programar en relación al servicio ofrecido. Por ejemplo, en servicios comunes como el mantenimiento de células, la actividad puede ser el seguimiento periódico de dicho mantenimiento.',
          },
          nextActivityDelay: {
            title: 'Tiempo de planificación (días)',
            description:
              'Indica el número de días que deben transcurrir antes de programar esta actividad',
          },
        },
        tabs: {
          service: {
            title: 'Servicio',
            description:
              'Gestión de los servicios de actividad en el sistema. Un servicio es la información que se introduce cuando se finaliza una actividad.',
          },
          nextActivity: {
            title: 'Siguiente actividad',
            description:
              'La selección de la siguiente actividad permite que el sistema cree automáticamente una tarea con esta actividad para facilitar la continuidad del seguimiento',
          },
        },
      },
      enable: {
        title: 'Habilitar servicio',
        subtitle: 'Habilitar servicio en el sistema',
        form: {
          status: {
            title: '¿Estás seguro de que deseas habilitar este servicio?',
            description: 'Habilitar un servicio significa que podrá ser utilizado en el sistema',
            label: 'Sí, deseo habilitar este servicio',
          },
          submit: 'Habilitar',
        },
      },
      disable: {
        title: 'Deshabilitar servicio',
        subtitle:
          'El elemento no estará disponible en el CRM y solo las personas con un permiso específico podrán visualizar los elementos inactivos.',
        form: {
          status: {
            title: '¿Estás seguro de que deseas deshabilitar este servicio?',
            description:
              'Deshabilitar un servicio significa que no podrá ser utilizado en el sistema',
            label: 'Sí, deseo deshabilitar este servicio',
          },
          submit: 'Deshabilitar',
        },
      },
      create: {
        title: 'Crear servicio',
        subtitle:
          'Completa este formulario para crear un nuevo servicio. Una vez creado, estará disponible en el sistema para asignarlo a cualquier paciente que lo contrate.',
        form: {
          submit: 'Crear',
          name: {
            title: 'Nombre',
            description:
              'Nombre identificativo del servicio. Al asignar un servicio al paciente se reconocerá por este nombre.',
          },
          price: {
            title: 'Precio',
            description: 'Precio del servicio para el paciente',
          },
          nextActivityId: {
            title: 'Próxima actividad',
            description:
              'Selecciona la actividad a programar en relación al servicio ofrecido. Por ejemplo, en servicios comunes como el mantenimiento de células, la actividad puede ser el seguimiento periódico de dicho mantenimiento.',
          },
          nextActivityDelay: {
            title: 'Tiempo de planificación (días)',
            description:
              'Indica el número de días que deben transcurrir antes de programar esta actividad',
          },
        },
      },
    },
    products: {
      list: {
        title: 'Productos',
        description: 'Gestión de los productos en el sistema',
        create: 'Añadir Producto',
        one: 'producto',
        many: 'productos',
        columns: {
          name: 'Nombre',
          price: 'Precio',
          tax: 'Impuestos',
          categoryName: 'Categoría',
          clinicName: 'Clínica',
        },
      },
      update: {
        subtitle: 'Modificar producto en el sistema',
        title: 'Modificar producto',
        form: {
          submit: 'Guardar',
          name: {
            title: 'Nombre',
            description: 'Nombre identificativo del producto',
          },
          icon: {
            title: 'Icono',
            description:
              'Icono identificativo del producto. Un icono puede ayudar a relacionar un producto con mayor facilidad',
          },
          description: {
            title: 'Descripción',
            description: 'Una breve descripción del producto para detallar sus características',
          },
          color: {
            title: 'Color',
            description:
              'Color identificativo del producto. Un color puede ayudar a relacionar un producto con mayor facilidad',
          },
          price: {
            title: 'Precio',
            description: 'Precio de venta del producto',
          },
          tax: {
            title: 'Tax',
            description: 'Impuestos aplicados al producto',
          },
          path: {
            title: 'Path',
            description: 'Path donde se encuentra el producto',
          },
          categoryId: {
            title: 'Categoria',
            description: 'Categoria a la que pertenece el producto',
          },
          clinicId: {
            title: 'Clínica',
            description: 'Clínica a la que pertenece esta categoría',
          },
        },
      },
      enable: {
        title: 'Habilitar producto',
        subtitle: 'Habilitar producto en el sistema',
        form: {
          status: {
            title: '¿Estás seguro de que deseas habilitar este producto?',
            description: 'Habilitar un producto significa que podrá ser utilizado en el sistema',
            label: 'Sí, deseo habilitar este producto',
          },
          submit: 'Habilitar',
        },
      },
      disable: {
        title: 'Deshabilitar producto',
        subtitle:
          'El elemento no estará disponible en el CRM y solo las personas con un permiso específico podrán visualizar los elementos inactivos.',
        form: {
          status: {
            title: '¿Estás seguro de que deseas deshabilitar este producto?',
            description:
              'Deshabilitar un producto significa que no podrá ser utilizado en el sistema',
            label: 'Sí, deseo deshabilitar este producto',
          },
          submit: 'Deshabilitar',
        },
      },
      create: {
        title: 'Crear producto',
        subtitle: 'Crear producto en el sistema',
        steps: {
          category: {
            form: {
              selected: 'Categoría seleccionada',
              categoryId: {
                title: 'Categoria',
                description: 'Categoria a la que pertenece el producto',
              },
            },
          },
          clinic: {
            form: {
              selected: 'Clínica seleccionada',
              clinicId: {
                title: 'Clínica',
                description: 'Clínica a la que pertenece esta categoría',
              },
            },
          },
        },
        form: {
          submit: 'Crear',
          name: {
            title: 'Nombre',
            description: 'Nombre identificativo del producto',
          },
          icon: {
            title: 'Icono',
            description:
              'Icono identificativo del producto. Un icono puede ayudar a relacionar un producto con mayor facilidad',
          },
          description: {
            title: 'Descripción',
            description: 'Una breve descripción del producto para detallar sus características',
          },
          color: {
            title: 'Color',
            description:
              'Color identificativo del producto. Un color puede ayudar a relacionar un producto con mayor facilidad',
          },
          price: {
            title: 'Precio',
            description: 'Precio de venta del producto',
          },
          tax: {
            title: 'Tax',
            description: 'Impuestos aplicados al producto',
          },
          path: {
            title: 'Path',
            description: 'Path donde se encuentra el producto',
          },
        },
      },
    },
    categories: {
      list: {
        title: 'Categorías',
        description: 'Agrupación de productos',
        create: 'Añadir Categoría',
        one: 'categoría',
        many: 'categorías',
        columns: {
          name: 'Nombre',
          clinicName: 'Clínica',
          created: 'Fecha de creación',
        },
      },
      update: {
        subtitle: 'Modificar categoría en el sistema',
        title: 'Modificar categoría',
        form: {
          submit: 'Guardar',
          name: {
            title: 'Nombre',
            description: 'Nombre identificativo de la categoría',
          },
          description: {
            title: 'Descripción',
            description: 'Una breve descripción para detallar la categoría',
          },
        },
      },
      create: {
        title: 'Crear categoría',
        subtitle: 'Crear categoría en el sistema',
        steps: {
          clinic: {
            form: {
              clinicId: {
                title: 'Clínica',
                description: 'Clínica a la que pertenece esta categoría',
              },
            },
          },
        },
        form: {
          submit: 'Crear',
          name: {
            title: 'Nombre',
            description: 'Nombre identificativo de la categoría',
          },
          description: {
            title: 'Descripción',
            description: 'Una breve descripción para detallar la categoría',
          },
        },
      },
      enable: {
        title: 'Habilitar categoría',
        subtitle: 'Habilitar categoría en el sistema',
        form: {
          status: {
            title: '¿Estás seguro de que deseas habilitar esta categoría?',
            description: 'Habilitar una categoría significa que podrá ser utilizado en el sistema',
            label: 'Sí, deseo habilitar esta categoría',
          },
          submit: 'Habilitar',
        },
      },
      disable: {
        title: 'Deshabilitar categoría',
        subtitle:
          'El elemento no estará disponible en el CRM y solo las personas con un permiso específico podrán visualizar los elementos inactivos.',
        form: {
          status: {
            title: '¿Estás seguro de que deseas deshabilitar esta categoría?',
            description:
              'Deshabilitar una categoría significa que no podrá ser utilizado en el sistema',
            label: 'Sí, deseo deshabilitar esta categoría',
          },
          submit: 'Deshabilitar',
        },
      },
    },
    techniques: {
      list: {
        title: 'Técnicas',
        description:
          'Las técnicas complementarias son procedimientos adicionales que pueden formar parte de un programa para mejorar sus resultados o brindar un enfoque más personalizado. En esta pantalla, puedes ver la lista de técnicas disponibles, crear nuevos y acceder a los detalles de cada uno.',
        create: 'Técnica',
        one: 'Técnica',
        many: 'Técnicas',
        columns: {
          name: 'Nombre',
          clinic: 'Clínica',
          created: 'Fecha de creación',
          price: 'Precio',
        },
      },
      create: {
        title: 'Añadir técnica',
        subtitle:
          'Las técnicas complementarias son procedimientos adicionales que se utilizan para mejorar los resultados',
        form: {
          id: {
            title: 'Nombre',
            description:
              'Nombre identificativo de la técnica. Este nombre servirá para reconocer la técnica y asignarla a los programas.',
          },
          name: {
            title: 'Nombre',
            description:
              'Nombre identificativo de la técnica. Este nombre servirá para reconocer la técnica y asignarla a los programas',
          },
          price: {
            title: 'Precio',
            description:
              'El precio de la técnica es lo que se cobraría al paciente, a menos que esté cubierto por una garantía',
          },
          description: {
            title: 'Descripción',
            description:
              'Detalla las características principales de la técnica. Esta descripción ayudará a identificar y diferenciarlo dentro del catálogo.',
          },
          clinicId: {
            title: 'Clínica',
            description: 'Selecciona la clínica donde se realizará la técnica',
          },
          submit: 'Añadir técnica',
        },
      },
      update: {
        title: 'Actualizar técnica',
        subtitle: 'Modifica los detalles relacionados a una técnica existente.',
        form: {
          name: {
            title: 'Nombre',
            description:
              'Nombre identificativo de la técnica. Este nombre servirá para reconocer la técnica y asignarla a los programas',
          },
          price: {
            title: 'Precio',
            description:
              'El precio de la técnica es lo que se cobraría al paciente, a menos que esté cubierto por una garantía',
          },
          description: {
            title: 'Descripción',
            description:
              'Detalla las características principales de la técnica. Esta descripción ayudará a identificar y diferenciarlo dentro del catálogo.',
          },
          clinicId: {
            title: 'Clínica',
            description: 'Selecciona la clínica donde se realizará la técnica',
          },
          submit: 'Actualizar técnica',
        },
      },
      enable: {
        title: 'Habilitar técnica',
        subtitle: 'Habilitar técnica en el sistema',
        form: {
          submit: 'Habilitar',
        },
      },
      disable: {
        title: 'Deshabilitar técnica',
        subtitle:
          'El elemento no estará disponible en el CRM y solo las personas con un permiso específico podrán visualizar los elementos inactivos.',
        form: {
          submit: 'Deshabilitar',
        },
      },
      add: 'Añadir',
    },
    programs: {
      list: {
        title: 'Programas',
        description:
          'Ofertas de Ovoclinic con diferentes precios y complejidades, como FIV, ovodonación o embriodonación. Estos programas se componen de ciclos y se suelen ofrecer en formato de garantías.',
        create: 'Programa',
        one: 'Programa',
        many: 'Programas',
        columns: {
          name: 'Nombre',
          created: 'Fecha de creación',
          price: 'Precio',
          techniques: 'Técnicas',
        },
      },
      create: {
        title: 'Crear programa para el catálogo',
        subtitle:
          'En esta pantalla puedes crear un programa que se añadirá al catálogo, para seleccionarlo luego al asignar un tratamiento al paciente.',
        form: {
          name: {
            title: 'Nombre',
            description:
              'Este nombre será utilizado para reconocer el programa en el catálogo y asignarlo al paciente cuando se elija su tratamiento',
          },
          description: {
            title: 'Descripción',
            description:
              'Detalla las características principales del programa. Esta descripción ayudará a identificar y diferenciaro dentro del catálogo.',
          },
          complexity: {
            title: 'Complejidad',
            description: 'Nivel de complejidad del programa',
          },
          price: {
            title: 'Precio',
            description: 'Introduce el precio del programa en este campo',
          },
          code: {
            title: 'Código identificatico',
            description:
              'Introduce un código único para identificar facilmente el programa en el listado',
          },
          submit: 'Crear programa',
        },
      },
      enable: {
        title: 'Habilitar programa',
        subtitle: 'Habilitar programa en el catálogo',
        form: {
          status: {
            title: '¿Estás seguro de que deseas habilitarlo?',
            description: 'Habilitar un programa significa que podrá ser utilizado en el sistema',
            label: 'Sí, deseo habilitar este programa',
          },
          submit: 'Habilitar',
        },
      },
      disable: {
        title: 'Deshabilitar programa',
        subtitle:
          'El elemento no estará disponible en el CRM y solo las personas con un permiso específico podrán visualizar los elementos inactivos.',
        form: {
          status: {
            title: '¿Estás seguro de que deseas deshabilitarlo?',
            description:
              'Deshabilitar un programa significa que no podrá ser utilizado en el sistema',
            label: 'Sí, deseo deshabilitar este programa',
          },
          submit: 'Deshabilitar',
        },
      },
      update: {
        title: 'Actualizar programa',
        subtitle:
          'Actualiza las caracteristicas principales del programa y otros detalles como técnicas complementarias, etapas y ciclos.',
        form: {
          name: {
            title: 'Nombre',
            description:
              'Este nombre será utilizado para reconocer el programa en el catálogo y asignarlo al paciente cuando se elija su tratamiento',
          },
          description: {
            title: 'Descripción',
            description:
              'Detalla las características principales del programa. Esta descripción ayudará a identificar y diferenciaro dentro del catálogo.',
          },
          complexity: {
            title: 'Complejidad',
            description: 'Nivel de complejidad del programa',
          },
          clinicId: {
            title: 'Clínica',
            description: 'Clínica en la que se realiza el programa',
          },
          price: {
            title: 'Precio',
            description: 'Introduce el precio del programa en este campo',
          },
          code: {
            title: 'Código identificativo',
            description:
              'Introduce un código único para identificar facilmente el programa en el listado',
          },
          submit: 'Actualizar programa',
        },
        tabs: {
          program: {
            title: 'Programas',
            description:
              'Modificar las características principales de un programa, como su nombre, descripción, nivel de complejidad y precio. Realiza los cambios necesarios y guarda la información actualizada.',
          },
          techniques: {
            title: 'Técnicas',
            description:
              'Las técnicas complementarias son procedimientos adicionales que pueden formar parte de un programa para mejorar sus resultados o brindar un enfoque más personalizado. En esta pestaña, puedes añadir las técnicas complementarias que forman parte de este programa.',
          },
          phases: {
            title: 'Etapas',
            description:
              'Las etapas de un programa son los diferentes estados en los que puede encontrarse un tratamiento. En esta pestaña, puedes añadir y gestionar las etapas específicas que componen este programa.',
          },
          cycles: {
            title: 'Ciclos',
            description: 'Selecciona los ciclos para asociar a este programa.',
          },
        },
        cycles: {
          list: {
            title: 'Ciclos',
            description:
              'Un ciclo es un intento dentro de un programa. En esta sección puedes añadir los ciclos asociados a este programa.',
            columns: {
              cycleName: 'Nombre',
              cycleCreated: 'Fecha de creación',
            },
            one: 'Ciclo',
            many: 'Ciclos',
          },
          create: {
            title: 'Añadir ciclo',
            subtitle: 'Selecciona el ciclo para añadir a los ciclos que tiene este programa',
            form: {
              cycleId: {
                title: 'Ciclo',
                description: 'Selecciona el ciclo a añadir al programa',
              },
              submit: 'Añadir',
            },
          },
          delete: {
            title: 'Eliminar ciclo',
            subtitle:
              'Al eliminar este elemento se borrará del sistema. Esta acción es irreversible.',
            form: {
              submit: 'Eliminar',
            },
          },
        },
        phases: {
          create: {
            title: 'Añadir etapa',
            subtitle: 'Selecciona la etapa para añadir a las etapas especificas del programas',
            form: {
              phaseId: {
                title: 'Etapa',
                description:
                  'Selecciona el estado del tratamiento que quieres añadir a este programa',
              },
              submit: 'Añadir',
            },
          },
          delete: {
            title: 'Eliminar etapa',
            subtitle:
              'Al eliminar este elemento se borrará del sistema. Esta acción es irreversible.',
            form: {
              status: {
                title: '¿Estás seguro de que deseas eliminarlo?',
                description:
                  'La acción de eliminar es irreversible, tal vez prefieras desactivar esta etapa. ¿Estás seguro de querer eliminarlo?',
                label: 'Sí, deseo eliminar esta etapa',
              },
              submit: 'Eliminar',
            },
          },
          list: {
            title: 'Etapas',
            description:
              'Las etapas de un programa son los diferentes estados en los que puede encontrarse un tratamiento. En esta pestaña, puedes añadir y gestionar las etapas específicas que componen este programa.',
            columns: {
              phaseName: 'Nombre',
              phaseCreated: 'Fecha de creación',
            },
            one: 'Etapa',
            many: 'Etapas',
          },
        },
        techniques: {
          create: {
            title: 'Añadir técnica complementaria',
            subtitle: 'Añade la técnica complementaria para asignar a este programa en especifico',
            form: {
              id: {
                title: 'Técnica',
                description: 'Selecciona la técnica complentaria para añadir al programa',
              },
              submit: 'Añadir',
            },
          },
          delete: {
            title: 'Eliminar técnica',
            subtitle:
              'Al eliminar este elemento se borrará del sistema. Esta acción es irreversible.',
            form: {
              submit: 'Eliminar',
            },
          },
        },
      },
    },
    phases: {
      list: {
        title: 'Etapas',
        description:
          'Las etapas de un programa son los diferentes estados en los que puede encontrarse un tratamiento. En esta pestaña, puedes gestionar las etapas que posteriormente podrán asignarse especificamente a cada programa',
        create: 'Etapa',
        one: 'Etapa',
        many: 'Etapas',
        columns: {
          name: 'Nombre',
          causes: 'Causas',
          created: 'Fecha de creación',
        },
      },
      create: {
        title: 'Crear etapa',
        subtitle:
          'Define el estado o la fase por la que un paciente puede atravesar durante el tratamiento.',
        form: {
          name: {
            title: 'Nombre',
            description:
              'Introduce un nombre para la etapa. Servirá para identificarla fácilmente al seleccionarla dentro del programa.',
          },
          description: {
            title: 'Descripción',
            description: 'Un breve resumen para dar mas detalles sobre la etapa.',
          },
          icon: {
            title: 'Icono',
            label: 'Icono',
            description: 'Icono para identificar la etapa',
            search: {
              placeholder: 'Buscar icono...',
            },
          },
          color: {
            title: 'Color',
            description: 'Color identificativo de la etapa. Puede ayudar a identificar la etapa.',
          },
          submit: 'Crear etapa',
        },
      },
      update: {
        title: 'Actualizar etapa',
        subtitle: 'Modificación de las caracteristicas de la etapa seleccionada.',
        form: {
          name: {
            title: 'Nombre',
            description:
              'Introduce un nombre para la etapa. Servirá para identificarla fácilmente al seleccionarlo en el tratamiento.',
          },
          description: {
            title: 'Descripción',
            description: 'Un breve resumen para dar mas detalles sobre la etapa',
          },
          icon: {
            title: 'Icono',
            description: 'Icono para identificar la etapa',
            search: {
              placeholder: 'Buscar icono...',
            },
            label: 'Icono',
          },
          color: {
            title: 'Color',
            description: 'Color identificativo de la etapa. Puede ayudar a identificar la etapa.',
          },
          submit: 'Guardar',
        },
        tabs: {
          phase: {
            title: 'Etapa',
            description:
              'Las etapas de un programa son los diferentes estados en los que puede encontrarse un tratamiento',
          },
          causes: {
            title: 'Causas',
            description: 'Causas por las que se puede cancelar un tratamiento',
            one: 'Causa',
            many: 'Causas',
          },
        },
        causes: {
          add: 'Añadir causa',
        },
      },
      enable: {
        title: 'Habilitar etapa',
        subtitle: 'Habilitar etapa en el sistema',
        form: {
          submit: 'Habilitar',
        },
      },
      disable: {
        title: 'Deshabilitar etapa',
        subtitle:
          'El elemento no estará disponible en el CRM y solo las personas con un permiso específico podrán visualizar los elementos inactivos.',
        form: {
          submit: 'Deshabilitar',
        },
      },
    },
    causes: {
      list: {
        title: 'Causas',
        description: 'Gestión de las causas de cancelación de un tratamiento',
        create: 'Causa',
        one: 'Causa',
        many: 'Causas',
        columns: {
          name: 'Nombre',
          created: 'Fecha de creación',
        },
        params: {
          phaseId: 'Causas filtrados por etapa',
        },
      },
      create: {
        title: 'Crear causa',
        subtitle: 'Registro de una nueva causa en el sistema',
        form: {
          name: {
            title: 'Nombre',
            description: 'Nombre de la causa',
          },
          description: {
            title: 'Descripción',
            description: 'Un breve resumen para dar mas detalles sobre la causa',
          },
          icon: {
            title: 'Icono',
            description: 'Icono para identificar la causa.',
            search: {
              placeholder: 'Buscar icono...',
            },
            label: 'Icono',
          },
          color: {
            title: 'Color',
            description: 'Color identificativo de la causa',
          },
          submit: 'Crear causa',
        },
      },
      update: {
        title: 'Actualizar causa',
        subtitle: 'Modificación de una causa en el sistema',
        form: {
          name: {
            title: 'Nombre',
            description: 'Nombre de la causa',
          },
          description: {
            title: 'Descripción',
            description: 'Un breve resumen para dar mas detalles sobre la causa.',
          },
          icon: {
            title: 'Icono',
            description: 'Icono para identificar la causa',
            search: {
              placeholder: 'Buscar icono...',
            },
            label: 'Icono',
          },
          color: {
            title: 'Color',
            description: 'Color identificativo de la causa',
          },
          submit: 'Guardar',
        },
      },
      enable: {
        title: 'Habilitar causa',
        subtitle: 'Habilitar causa en el sistema',
        form: {
          submit: 'Habilitar',
        },
      },
      disable: {
        title: 'Deshabilitar causa',
        subtitle:
          'El elemento no estará disponible en el CRM y solo las personas con un permiso específico podrán visualizar los elementos inactivos.',
        form: {
          submit: 'Deshabilitar',
        },
      },
    },
  },
  board: {
    total: 'Total',
    months: {
      jan: 'Ene',
      feb: 'Feb',
      mar: 'Mar',
      apr: 'Abr',
      may: 'May',
      jun: 'Jun',
      jul: 'Jul',
      aug: 'Ago',
      sep: 'Sep',
      oct: 'Oct',
      nov: 'Nov',
      dec: 'Dic',
    },
    noData: {
      title: 'No hay datos disponibles',
      description: 'Puedes probar seleccionando otros filtros',
    },
    common: {
      leadPerCampaignAndState: {
        title: 'Leads por campaña y estado',
        description: 'Leads por campaña y estado',
        totalLeads: 'Total de leads',
        totalCost: 'Coste total',
        costPerLead: 'Coste por lead',
      },
      leadPerClinicAndState: {
        title: 'Leads por clínica y estado',
        description: 'Leads por clínica y estado',
        totalLeads: 'Total de leads',
        totalCost: 'Coste total',
        costPerLead: 'Coste por lead',
      },
    },
    direction: {
      title: 'Dashboards dirección',
      description: 'Visión ejecutiva de la información del crm en tiempo real',
      cost: 'Coste de lead',
      leadCostByClinicAndMonth: {
        title: 'Coste por lead por clínica',
        description: 'Coste por lead por clínica',
        totalLeads: 'Total de leads',
        totalCost: 'Coste total',
        costPerLead: 'Coste por lead',
      },
      leadCostByFunnelAndMonth: {
        title: 'Distribución de leads por clinicas y departamento',
        description: 'Distribución de leads por clinicas y departamento',
        totalLeads: 'Total de leads',
        totalCost: 'Coste total',
        costPerLead: 'Coste por lead',
      },
      leadCostByCampaignAndMonth: {
        title: 'Coste por lead por campaña',
        description: 'Coste por lead por campaña',
        totalLeads: 'Total de leads',
        totalCost: 'Coste total',
        costPerLead: 'Coste por lead',
      },
    },
    marketing: {
      title: 'Dashboards marketing',
      description:
        'ista general de la información relacionada con las campañas de marketing, que permite analizar su rendimiento, visualizar métricas clave y evaluar los resultados de las acciones promocionales.',
      secondary: {
        title: 'Campañas de marketing',
        description:
          'Muestra el total de leads generados por las campañas de marketing, su estado actual y el porcentaje de conversión.',
      },
      convertionRateByCampaign: {
        title: 'Tasa de conversión por campaña',
        description: 'Tasa de conversión por campaña',
        totalLeads: 'Total de leads',
        totalConvertedLeads: 'Leads convertidos',
        conversionRate: 'Tasa de conversión',
      },
      totalLeads: 'TOTAL DE LEADS',
      leadsState: 'ESTADO DE LEADS',
      leadActiveInactive: 'LEADS ACTIVOS/INACTIVOS',
      leadsPerCampaign: {
        title: 'Leads por campaña',
        description: 'Leads por campaña',
        totalLeads: 'Total de leads',
        totalCost: 'Coste total',
        costPerLead: 'Coste por lead',
        filters: {
          campaign: 'Campaña',
          funnel: 'Embudo',
        },
      },
      leadCostDetail: {
        title: 'Detalle',
        selectedClinic: 'Clínica seleccionada',
        selectedCampaign: 'Campaña seleccionada',
        budget: 'Presupuesto',
      },
      costCampaign: {
        0: 'Clinica seleccionada: ',
        1: 'Campaña seleccionada: ',
        2: 'Presupuesto: ',
        3: 'Número de leads: ',
        4: 'Leads citados: ',
        5: 'Coste total de leads: ',
        6: 'Coste total citados: ',
      },
      leadsPerFunnel: {
        title: 'Leads por embudo',
        description: 'Leads por embudo',
        totalLeads: 'Total de leads',
        totalCost: 'Coste total',
        costPerLead: 'Coste por lead',
      },
      filters: {
        year: 'Año',
        yearSubtitle: 'Filtra por año seleccionado',
        by: 'Filtrar por',
      },
      generalTable: {
        totalLeads: 'Total leads',
        informed: 'V.I realizada',
        total: 'Total acumulado',
        costPerLead: 'Coste por lead',
        totalRow: 'Total',
      },
      inactiveLeadsReason: {
        title: 'Motivos de leads inactivos',
        description: 'Muestra los motivos por los que los leads se encuentran inactivos',
        tableTitle: 'MOTIVOS DE LEADS INACTIVOS',
      },
    },
    subordinates: {
      title: 'Rendimento de los compañeros',
      description: 'Vista general del rendimiento de los empleados.',
      performance: {
        title: 'Hitos realizados',
        description:
          'Vista general del rendimiento de los empleados, mostrando la distribución de los hitos conseguidos. Permite evaluar el progreso y los logros individuales según los hitos alcanzados.',
      },
      performanceSubordinates: {
        title: 'RENDIMIENTO DE MI EQUIPO',
      },
      performanceSubordinate: {
        title: 'RENDIMIENTO DE MI COMPAÑERO',
      },
      filters: {
        clinics: {
          all: 'Todas',
        },
        subordinate: {
          title: 'Empleados',
          subtitle: 'Filtra por empleado seleccionado',
        },
      },
    },
    intervention: {
      title: 'Previsión de tratamientos',
      description:
        'Muestra el número total de tratamientos previstos mensualmente por clínica, con opciones para filtrar por año y tipo de complejidad.',
      expectedInterventions: {
        monthsTable: 'PREVISIÓN DE TRATAMIENTOS',
        yearsTable: 'PREVISIÓN DE TRATAMIENTOS ACUMULADOS',
      },
      segmention: 'Desglose tratamientos',
      lossGainSegmentation: 'Desglose pérdidas y ganancias',
      one: 'Tratamiento',
      many: 'Tratamientos',
      expectedInterventionsSegmentionCountType: {
        total_loss: 'Pérdidas',
        total_first_attempt: 'Garantias (1er intento)',
        total: 'Total',
      },
      expectedInterventionsLossGainSegmentationCountType: {
        total_loss: 'Pérdidas',
        total_first_attempt: 'Ganancias',
        total: 'Total',
      },
      filters: {
        complexity: {
          title: 'Complejidad',
          subtitle: 'Filtra por complejidad seleccionada',
          types: {
            ALL: 'Todas',
            HIGH: 'Alta',
            LOW: 'Baja',
          },
        },
      },
    },
    callcenter: {
      title: 'Dashboards call center',
      description: 'Visión de call center de la información del crm en tiempo real',
    },
    leads: {
      title: 'Dashboard de leads',
      description:
        'Vista general del total de leads ingresados en el crm de Ovoclinic, con opciones de filtrado para obtener estadísticas detalladas y comparativas.',
      totalLeads: {
        title: 'Total de leads ingresados',
        description:
          'Tabla que muestra la comparación entre los leads ingresados en diferentes años para la clínica seleccionada. Permite elegir los años a comparar y visualizar el número total de ingresos según el embudo de ventas. También se puede exportar la información en formato CSV y acceder a la gráfica filtrada por departamentos.',
        filters: {
          clinic: 'Clínica',
          clinicSubtitle: 'Filtra por clínica seleccionada',
          campaign: 'Campaña',
          campaignSubtitle: 'Filtra por campaña seleccionada',
          optionA: 'Opción A',
          optionASubtitle: '1ª opción del año a comparar',
          optionB: 'Opción B',
          optionBSubtitle: '2ª opción del año a comparar',
          funnel: 'Procedencia',
          funnelSubtitle: 'Filtra por procedencia',
          all: 'Todas',
          department: 'Departamento',
          departmentSubtitle: 'Filtra por departamento seleccionado',
          year: 'Año',
          yearSubtitle: 'Filtra por año seleccionado',
          month: 'Mes',
          monthSubtitle: 'Filtra por mes seleccionado',
          allMonths: 'Todos',
        },
        comparativeGraphicTitle: 'COMPARATIVA DE LEADS',
        comparativeAcumulatedGraphicTitle: 'COMPARATIVA DE LEADS/ ACUMULADOS',
        totalLeads: 'LEAD TOTALES',
        summary: 'RESUMEN',
        export: 'EXPORTAR CSV',
        csv: 'CSV',
      },
    },
    saleFunnel: {
      title: 'Embudo de ventas',
      description:
        'Vista general del proceso de ventas, mostrando las etapas del embudo desde la generación de leads hasta la conversión final.',
      totalSales: {
        graphicTitle: 'EMBUDO DE VENTAS',
      },
    },
    sales: {
      title: 'Dashboards sales',
      description: 'Visión de ventas de la información del crm en tiempo real',
      treatmentTechniqueAndServiceByClinic: {
        title: 'Tratamientos, técnicas y servicios por clínica',
        description: 'Tratamientos, técnicas y servicios por clínica',
        totalTreatments: 'Total de tratamientos',
        totalTechniques: 'Total de técnicas',
        totalServices: 'Total de servicios',
      },
      treatmentTechniqueAndServiceByLanguage: {
        title: 'Tratamientos, técnicas y servicios por departamento',
        description: 'Tratamientos, técnicas y servicios por departamento',
        totalTreatments: 'Total de tratamientos',
        totalTechniques: 'Total de técnicas',
        totalServices: 'Total de servicios',
      },
    },
    summarySales: {
      title: {
        ovoclinic: 'Resumen de ventas',
        ovobank: 'Resumen de ventas',
      },
      description: {
        ovoclinic:
          'Muestra el total de ventas y su tasa de conversión, ofreciendo una visión clara del rendimiento de ventas.',
        ovobank: 'Resumen de los leads convertidos en donantes',
      },
      totalSales: {
        title: 'Comparación anual de ventas',
        description:
          'Muestra la comparación de ventas realizadas por la clínica seleccionada en diferentes años. Permite elegir los años a comparar, descargar los datos y ver una gráfica detallada con opción de filtrar por departamento.',
        filters: {
          clinic: 'Clínica',
          clinicSubtitle: 'Filtra por clínica seleccionada',
          optionA: 'Opción A',
          optionASubtitle: '1ª opción de la comparativa',
          optionB: 'Opción B',
          optionBSubtitle: '2ª opción de la comparativa',
          department: 'Departamento',
          departmentSubtitle: 'Filtra por departamento seleccionado',
          month: 'Mes',
          monthSubtitle: 'Filtra por mes seleccionado',
          year: 'Año',
          yearSubtitle: 'Filtra por año seleccionado',
        },
        comparativeGraphicTitle: 'COMPARATIVA DE VENTAS',
        export: 'EXPORTAR CSV',
        csv: 'CSV',
        comparativeAcumulatedGraphicTitle: 'COMPARATIVA DE VENTAS/ ACUMULADOS',
      },
    },
    medical: {
      title: 'Dashboards médico',
      description: 'Visión de departamento médico de la información del crm en tiempo real',
      leadConversionByFunnelAndMonth: {
        title: 'Tasa de conversión de leads por procedencia y mes',
        description: 'Tasa de conversión de leads por procedencia y mes',
        totalLeads: 'Total de leads',
        convertedLeads: 'Leads convertidos',
        conversionRate: 'Tasa de conversión',
      },
      leadConversionPercentageByFunnelAndMonth: {
        title: 'Porcentaje de conversión de leads por procedencia y mes',
        description: 'Porcentaje de conversión de leads por procedencia y mes',
        totalLeads: 'Total de leads',
        convertedLeads: 'Leads convertidos',
        conversionRate: 'Porcentaje de conversión',
      },
      estimatedTreatmentByYear: {
        title: 'Tratamientos programados',
        description:
          'Comparación de los tratamientos programados en el año seleccionado y el anterior',
        estimated: 'Tratamiento programados',
        finished: 'Tratamiento finalizados',
        previousEstimated: 'Tratamientos pogramados',
        previousFinished: 'Tratamientos finalizados',
      },
      finishedTreatmentByYear: {
        title: 'Tratamientos finalizados',
        description:
          'Comparación de los tratamientos finalizados en el año seleccionado y el anterior',
        estimated: 'Tratamiento programados',
        finished: 'Tratamiento finalizados',
        previousEstimated: 'Tratamientos pogramados',
        previousFinished: 'Tratamientos finalizados',
      },
      leadConversionBySpecialist: {
        title: 'Tasa de conversión de leads por especialista',
        description: 'Tasa de conversión de leads por especialista',
        totalLeads: 'Total de leads',
        appointedLeads: 'Leads citados',
        convertedLeads: 'Leads convertidos',
        conversionRate: 'Tasa de conversión',
        treatmentSold: 'Tratamientos vendidos',
        treatmentFinished: 'Tratamientos finalizados',
        specialistData: 'Datos del especialista',
      },
      leadConversionBySpecialistKPI: {
        title: 'KPI Tasa de conversión de leads por especialista',
        conversionRate: 'Tasa de conversión',
      },
      leadConversionBySpecialistAndMonth: {
        title: 'Tasa de conversión de leads por especialista y mes',
        description: 'Tasa de conversión de leads por especialista y mes',
        totalLeads: 'Total de leads',
        appointedLeads: 'Leads citados',
        convertedLeads: 'Leads convertidos',
        conversionRate: 'Tasa de conversión',
        treatmentSold: 'Tratamientos vendidos',
        treatmentFinished: 'Tratamientos finalizados',
        specialistData: 'Datos del especialista',
        leadsAttended: 'Leads atendidos',
        leadsAttendedConverted: 'Leads atendidos convertidos',
      },
      leadConversionBySpecialistAndMonthKPI: {
        title: 'KPI Tasa de conversión de leads por especialista y mes',
        description: 'KPI Tasa de conversión de leads por especialista y mes',
        conversionRate: 'Tasa de conversión',
      },
      leadConversionByFunnel: {
        title: 'Tasa de conversión de leads por procedencia',
        description: 'Tasa de conversión de leads por procedencia',
        totalLeads: 'Total de leads',
        convertedLeads: 'Leads convertidos',
        conversionRate: 'Tasa de conversión',
        appointedLeads: 'Leads citados',
        treatmentSold: 'Tratamientos vendidos',
        treatmentFinished: 'Tratamientos finalizados',
      },
      leadConversionByFunnelKPI: {
        title: 'KPI Tasa de conversión de leads por procedencia',
        description: 'KPI Tasa de conversión de leads por procedencia',
        totalLeads: 'Total de leads',
        convertedLeads: 'Leads convertidos',
        conversionRate: 'Tasa de conversión',
        appointedLeads: 'Leads citados',
        treatmentSold: 'Tratamientos vendidos',
        treatmentFinished: 'Tratamientos finalizados',
        convertedLeadsRate: 'Tasa de conversión de leads',
        treatmentSoldRate: 'Tasa de conversión de tratamientos vendidos',
      },
    },
    menu: {
      leads: 'Resumen Leads',
      directory: 'Directorio',
      direction: 'Dirección',
      expectedIntervention: 'Previsión de tratamientos',
      general: 'General',
      callcenter: 'Call center',
      medical: 'Médico',
      marketing: 'Marketing',
      sale: 'Ventas',
      saleFunnel: 'Embudo de ventas',
      summarySales: 'Resumen de ventas',
      subordinates: 'Rendimiento de empleados',
    },
    sections: {
      marketing: {
        actives: 'Activos',
        inactives: 'Inactivos',
        converted: 'Convertidos',
        appointed: 'Citados',
        informed: 'Informados',
        contacted: 'Contactado',
        leadPerCampaign: {
          title: 'Leads por campaña de marketing',
        },
        leadPerFunnel: {
          title: 'Leads por procedencia de las campañas de marketing',
        },
        leadPerleadPatientPerCampaignFunnel: {
          title: {
            ovoclinic: 'Tasa de conversión lead-paciente por embudo',
            ovobank: 'Tasa de conversión lead-donante por embudo',
          },
        },
        leadPatientPerCampaign: {
          title: {
            ovoclinic: 'Tasa de conversión lead-paciente por campaña',
            ovobank: 'Tasa de conversión lead-donante por campaña',
          },
          patients: {
            ovoclinic: 'Pacientes',
            ovobank: 'Donantes',
          },
          leads: 'Leads',
        },
        leadPatientPerFunnel: {
          title: {
            ovoclinic: 'Tasa de conversión lead-paciente por procedencia',
            ovobank: 'Tasa de conversión lead-donante por procedencia',
          },
          patients: {
            ovoclinic: 'Pacientes',
            ovobank: 'Donantes',
          },
          leads: 'Leads',
        },
        leadPerClinicPerState: {
          total: 'Total',
          title: 'Leads por clínica por estado',
          actives: 'Activos',
          inactives: 'Inactivos',
          converted: 'Convertidos',
          appointed: 'Citados',
          appointedMedic: 'Citados (médico)',
          contacted: 'Contactado',
          informed: 'Informado',
          loss: 'Baja',
          cancelled: 'Canceladas',
        },
        leadPerCampaignPerState: {
          total: 'Total',
          title: 'Leads por campaña por estado',
          actives: 'Activos',
          inactives: 'Inactivos',
          converted: 'Convertidos',
          appointed: 'Citados',
          contacted: 'Contactado',
          informed: 'Informado',
          loss: 'Baja',
        },
        totalLeadsPerState: {
          total: 'Total',
          appointed: 'Citados',
          percentage: 'Porcentaje',
          cost: 'Coste',
          actives: 'Activos',
          inactives: 'Inactivos',
        },
        filter: {
          campaignId: {
            title: 'Filtrar por campaña',
          },
          languageId: {
            title: 'Filtrar por idioma',
          },
          funnelId: {
            title: 'Filtrar por procedencia',
          },
          clinicId: {
            title: 'Filtrar por clínica',
          },
        },
        listLeadsByCampaign: {
          title: 'Desglose de campañas de marketing',
          campaign: 'Campaña de marketing',
          funnel: 'Procedencia',
          financingClinic: 'Clínica financiadora',
          language: 'Departamento',
          budget: 'Presupuesto campaña',
          nLeads: 'Nº de leads',
          costPerLead: 'Coste por lead',
        },
        all: 'Todo',
      },
      callcenter: {
        listLeadsByReason: {
          title: 'Leads por motivo de cancelación',
          reason: 'Razón',
          nLeads: 'Nº de leads',
        },
      },
      direction: {
        costsLeadPerClinic: {
          title: 'Coste de lead por clínica',
          monthFilter: 'Filtrar por mes',
        },
        costsLeadPerFunnel: {
          title: 'Coste de lead por procedencia',
        },
        costsLeadPerCampaign: {
          title: 'Coste de lead por campaña',
        },
        costsLeadPerLanguage: {
          title: 'Coste de lead por departamento',
        },
      },
      sales: {
        salePerClinic: {
          title: 'Tratamientos, técnicas y servicios por clínica',
        },
        salePerLanguage: {
          title: 'Tratamientos, técnicas y servicios por departamento',
        },
      },
      saleFunnel: {
        title: 'Embudo comercial',
        description:
          'Muestra la evolución del estado de los leads a través del embudo de ventas, filtrado por clínica y mes seleccionado. Refleja los estados que han atravesado los leads durante el mes elegido, con una comparativa de hitos logrados y detalles de conversión.',
      },
    },
  },
  profile: {
    menu: {
      profile: 'Perfil',
      account: 'Mi cuenta',
      changePassword: 'Cambiar contraseña',
    },
    changePassword: {
      title: 'Cambiar contraseña',
      subtitle: 'Cambia la contraseña de tu cuenta',
      form: {
        password: {
          title: 'Contraseña',
          description: 'Introduce la nueva clave de acceso que desea establecer',
        },
        repeatPassword: {
          title: 'Repetir contraseña',
          description: 'Repite la contraseña para confirmarla',
        },
        submit: 'Cambiar contraseña',
      },
    },
    account: {
      title: 'Mi cuenta',
      subtitle: 'Actualiza y gestiona los datos personales registrados en el sistema',
      form: {
        name: {
          title: 'Nombre',
          description: 'Introduce tu nombre',
        },
        surname: {
          title: 'Apellidos',
          description: 'Introduce tus apellidos',
        },
        username: {
          title: 'Nombre de usuario',
          description: 'Introduce tu nombre de usuario',
        },
        phone: {
          title: 'Teléfono',
          description: 'Introduce tu número de teléfono',
        },
        submit: 'Actualizar cuenta',
      },
    },
  },
  common: {
    idCopier: {
      copied: 'Identificador copiado en el portapapeles',
      popup: 'Copiar identificador',
    },
    flag: {
      locked: 'Inhabilitado',
      unlocked: 'Habilitado',
    },
    search: {
      placeholder: 'Buscar...',
      submit: 'Buscar',
    },
    actions: {
      create: 'Crear',
      update: 'Actualizar',
      delete: 'Eliminar',
      save: 'Guardar',
      cancel: 'Cancelar',
    },
    tasks: {
      disabled: 'Mostrar inactivos',
      noResult: 'Sin resultado',
    },
    notes: {
      delete: {
        confirm: {
          title: 'Eliminar nota',
          description:
            'Al eliminar este elemento se borrará del sistema. Esta acción es irreversible. ¿Estas seguro de querer eliminarlo?',
          cancel: 'Cancelar',
          submit: 'Eliminar',
        },
      },
      noteType: {
        title: 'Ver observaciones del',
        patient: {
          ovoclinic: 'Paciente',
          ovobank: 'Donante',
        },
        lead: {
          ovoclinic: 'Lead',
          ovobank: 'Lead',
        },
      },
      filter: {
        title: 'Filtrar por',
        tags: {
          medical: 'Médico',
          nursing: 'Enfermeria',
          followUp: 'Seguimiento',
        },
      },
    },
    time: {
      seconds: 'segundos',
      second: 'segundo',
      minutes: 'minutos',
      minute: 'minuto',
      hour: 'hora',
      hours: 'horas',
      days: 'días',
      day: 'día',
      weekDays: {
        0: 'Lunes',
        1: 'Martes',
        2: 'Miércoles',
        3: 'Jueves',
        4: 'Viernes',
        5: 'Sábado',
        6: 'Domingo',
      },
      weekDaysAbbreviated: {
        0: 'Lu',
        1: 'Ma',
        2: 'Mi',
        3: 'Ju',
        4: 'Vi',
        5: 'Sá',
        6: 'Do',
      },
      timeMessage: {
        expiresIn: 'Expira en {{value}} {{unit}}',
        expired: 'Expirado',
        ago: 'Hace',
      },
    },
    status: {
      success: 'Correcto',
      fail: 'Fallido',
    },
    notification: {
      title: 'Tienes una nueva notificación',
    },

    contactTime: {
      MORNING: 'Mañana',
      AFTERNOON: 'Tarde',
      NO_PREFERENCE: 'Sin preferencia',
    },
    complexity: {
      HIGH: 'Alta',
      LOW: 'Baja',
    },
    specialistType: {
      GYNECOLOGIST: 'Ginecólogo',
      EMBRYOLOGIST: 'Embriólogo',
      ATP: 'ATP',
    },
    taskStatus: {
      PENDING: 'Pendientes',
      COMPLETED: 'Realizadas',
      ALL: 'Todas',
    },
    plannedStatus: {
      ALL: 'Todas',
      PLANNED: 'Planificadas',
      UNPLANNED: 'Sin planificar',
    },
    taskProgress: {
      PENDING: 'Pendiente',
      COMPLETED: 'Realizada',
    },
    leadEntryStatus: {
      NEW: 'Nuevo',
      DUPLICATED: 'Duplicado',
      REENTRY: 'Reingresado',
    },
    paymentStatus: {
      PENDING: 'Pendiente',
      PAID: 'Pagado',
    },
    purchaseType: {
      MAINTENANCE: 'Mantenimiento',
      TREATMENT: 'Programa',
      TECHNIQUE: 'Técnica',
    },
    notifications: {
      list: {
        title: 'Notificaciones',
        description: 'Gestión de las notificaciones en el sistema',
        columns: {
          title: 'Título',
          type: 'Tipo de notificación',
          created: 'Fecha de creación',
        },
        one: 'Notificación',
        many: 'Notificaciones',
      },
      types: {
        ACTION_ALERT: 'Alerta de tarea',
        GRAVITY_FORM_ERROR: 'Landing',
        FACEBOOK_ERROR: 'Facebook',
      },
      new: 'Tienes una nueva notificación',
    },
    information: {
      title: 'Recursos',
      description: 'Información y recursos de la plataforma',
    },
  },
  components: {
    fileUploader: {
      title: 'Subir archivo',
      invalidType: 'Tipo de archivo no permitido',
    },
  },
  login: {
    title: 'Iniciar sesión',
    subtitle: 'Acceso a la plataforma de gestión',
    form: {
      email: {
        title: 'Correo electrónico',
      },
      signature: {
        title: 'Contraseña',
      },
      submit: 'Iniciar sesión',
    },
  },
  server: {
    errors: {
      INCORRECT_LOGIN: 'Usuario o contraseña incorrectos',
      WRONG_PERMISSIONS: 'No tienes permisos para ejecutar esta acción',
      service: {
        group: {
          findByDomain: {
            error: 'FATAL: No se ha podido encontrar el grupo',
          },
        },
      },
    },
  },
  space: {
    card: {
      leads: 'Leads',
      patients: 'Clientes',
      users: 'Usuarios',
      roles: 'Roles',
      etc: 'Etc',
      dashboard: 'Dashboard',
      account: 'Cuenta',
      password: 'Contraseñas',
    },
  },
};
