import { TabType } from '@components/tabs/tabs.types';
import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ResultsRoutes } from '../../../result.routes';
import { FormComponent } from '@components/form/form.component';
import { ResultCrmType } from '@human/crm/types/result.crm.type';
import { ResultReactivityCrmRepository } from '@human/crm/repositories/resultReactivity/resultReactivity.repository';
import { useSuccessToast } from '@hooks/successToast/successToast.hook';

export function UpdateResultReactivity(properties: {
  navigation: NavigationProp<ResultsRoutes>;
  route: RouteProp<ResultsRoutes, 'resultUpdate'>;
}): TabType {
  const { showSuccess } = useSuccessToast();
  return {
    icon: 'bolt',
    name: 'reactivity',
    content: (settings) => (
      <FormComponent<{ disableLead: boolean }>
        prefix="crm.results.update.form"
        repository={{
          get: () => {
            return new ResultReactivityCrmRepository().pick({
              resultId: properties.route.params.resultId,
              activityId: properties.route.params.activityId,
            });
          },
          send: (data) => {
            return new ResultReactivityCrmRepository()
              .update({
                id: properties.route.params.resultId,
                item: { ...data.item, activityId: properties.route.params.activityId },
              })
              .then(() => {
                settings?.setIsEditing(false);
                showSuccess();
              });
          },
        }}
        fields={[
          {
            type: 'checkbox',
            name: 'disableLead',
            description: true,
          },
        ]}
      />
    ),
  };
}
