import { SceneComponent } from '@components/scene/scene.component';
import { TableComponent } from '@components/table/table.component';
import { AccessSections } from '@human/access/access.sections';
import { TimetableAccessType } from '@human/access/types/timetable.access.type';
import { NavigationProp, RouteProp } from '@react-navigation/native';
import { TimetableRoutes } from '../../timetables.routes';
import { TimetableAccessRepository } from '@human/access/repositories/timetable/timetable.repository';
import { IconType } from '@components/icon/icon.types';
import { hasPermissionUtilty } from '@utils/permission/has.permission.utility';
import { ColumnProperties } from '@components/table/components/columns/column.properties';

export function ListTimetablesScreen(properties: {
  navigation: NavigationProp<TimetableRoutes>;
  route: RouteProp<TimetableRoutes, 'list'>;
}) {
  const links: {
    name: string;
    icon: IconType;
    onPress: () => void;
  }[] = [];
  if (
    hasPermissionUtilty({
      name: 'access.timetables.creator',
    })
  ) {
    links.push({
      icon: 'plus',
      name: 'create',
      onPress: () => properties.navigation.navigate('create'),
    });
  }

  const columns: ColumnProperties<TimetableAccessType, []>[] = [
    {
      type: 'icon',
      icon: hasPermissionUtilty({ name: 'access.timetables.editor' }) ? 'pencil-alt' : 'eye',
      onPress: (row) => {
        properties.navigation.navigate('update', { timetableId: row.id });
      },
    },
    {
      name: 'name',
      type: 'text',
      width: 250,
      onRender: (row) => row.name,
    },
  ];

  if (hasPermissionUtilty({ name: 'access.timetables.editor' })) {
    columns.unshift({
      type: 'flag',
      status: (row) => row.status,
      onPress: (row) => {
        properties.navigation.navigate(row.status ? 'disable' : 'enable', {
          timetableId: row.id,
        });
      },
    });
  }

  return (
    <SceneComponent
      icon={AccessSections.timetables.icon}
      prefix={'access.timetables.list'}
      links={links}
    >
      <TableComponent<TimetableAccessType, []>
        pagination={true}
        search
        prefix={'access.timetables.list'}
        suffix={'list'}
        repository={(properties) => {
          return new TimetableAccessRepository().list({
            page: properties.page,
            limit: properties.limit,
            search: properties.search,
          });
        }}
        columns={columns}
      />
    </SceneComponent>
  );
}
