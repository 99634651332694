import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ModalComponent } from '@components/modal/modal.component';
import { FormComponent } from '@components/form/form.component';
import { FunnelsRoutes } from '../../funnels.routes';
import { FunnelCrmRepository } from '@human/crm/repositories/funnel/funnel.repository';

export function DisableFunnelScreen(properties: {
  navigation: NavigationProp<FunnelsRoutes>;
  route: RouteProp<FunnelsRoutes, 'disable'>;
}) {
  return (
    <ModalComponent icon="trash-alt" prefix="crm.funnels.disable">
      <FormComponent
        prefix="crm.funnels.disable.form"
        padding
        repository={{
          send: () => {
            return new FunnelCrmRepository()
              .disable({
                funnelId: properties.route.params.id,
              })
              .then(() => {
                properties.navigation.goBack();
              });
          },
        }}
        fields={[]}
      />
    </ModalComponent>
  );
}
