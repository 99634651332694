import { FormComponent } from '@components/form/form.component';
import { ModalComponent } from '@components/modal/modal.component';
import { TabsComponent } from '@components/tabs/tabs.component';
import { ContractAccessRepository } from '@human/access/repositories/contract/contract.repository';
import { CampaignCrmRepository } from '@human/crm/repositories/campaign/campaign.repository';
import { ContactCrmRepository } from '@human/crm/repositories/contact/contact.repository';
import { LeadCrmRepository } from '@human/crm/repositories/lead/lead.repository';
import { NavigationProp, RouteProp } from '@react-navigation/native';
import { LeadsRoutes } from '../../leads.routes';
import { ActionsUpdateLeadPart } from './parts/actions.update.lead.part';
import { LeadCrmType } from '@human/crm/types/lead.crm.type';
import { ResponsibleUpdateLeadPart } from './parts/responsible.update.lead.part';
import { ContactUpdatePart } from '@human/crm/sections/contacts/screens/update/parts/contact.update.part';
import { PassportComponent } from '@components/passport/passport.component';
import { useState } from 'react';
import { NotesComponent } from '@components/notes/notes.component';
import { LeadNoteCrmRepository } from '@human/crm/repositories/leadNote/leadNote.repository';
import { LeadDisabledComponent } from '@components/leadDisabled/lead.disabled.component';
import { ClinicAccessRepository } from '@human/access/repositories/clinic/clinic.repository';
import { useSuccessToast } from '@hooks/successToast/successToast.hook';
import { useDeviceHook } from '@hooks/device/device.hook';
import { hasPermissionUtilty } from '@utils/permission/has.permission.utility';
import { SpecialistUpdateLeadPart } from './parts/specialist.update.lead';
import Workspace from '@utils/workspace/getWorkspace.utility';
import { OvobankPassportComponent } from '@components/passport/ovobank.passport.component';

export function UpdateLeadScreen(properties: {
  navigation: NavigationProp<LeadsRoutes>;
  route: RouteProp<LeadsRoutes, 'leadUpdate'>;
}) {
  const [lead, setLead] = useState<Partial<LeadCrmType>>();
  const { showSuccess } = useSuccessToast();

  const passport = Workspace.select({
    ovoclinic: lead ? <PassportComponent {...properties} contact={lead.contact} /> : <></>,
    ovobank: lead ? <OvobankPassportComponent {...properties} contact={lead.contact} /> : <></>,
  });

  const { phone } = useDeviceHook();

  const leadPromise = () =>
    new LeadCrmRepository()
      .pick({
        id: properties.route.params.id,
        clinicId: properties.route.params.clinicId,
      })
      .then((lead) => {
        setLead(lead);
        return lead;
      });

  const tabs = (
    <TabsComponent
      layout="horizontal"
      prefix="crm.leads.update"
      tabs={[
        {
          name: 'lead',
          icon: 'user',
          content: (settings) => {
            return (
              <>
                {lead && !lead.status && (
                  <LeadDisabledComponent
                    canceled={lead.canceled ?? false}
                    reason={lead.reasonName ?? ''}
                    statusUpdated={lead.statusUpdated ?? new Date()}
                  />
                )}
                <FormComponent<LeadCrmType>
                  onChange={(data) => {
                    if (!data.isLocal) return;
                    settings?.setIsEditing(true);
                  }}
                  rightPadding
                  prefix="crm.leads.update.form"
                  readonly={!hasPermissionUtilty({ name: 'crm.leads.editor' })}
                  repository={{
                    send: (data) => {
                      return new LeadCrmRepository()
                        .update({
                          item: {
                            ...data.item,
                            leadId: properties.route.params.id,
                            oldClinicId: properties.route.params.clinicId,
                          },
                        })
                        .then(() => {
                          properties.navigation.setParams({
                            ...properties.route.params,
                            clinicId: data.item.clinicId,
                          });
                          settings?.setIsEditing(false);
                          showSuccess();
                        });
                    },
                    get: () => {
                      return leadPromise();
                    },
                  }}
                  fields={[
                    {
                      type: 'select',
                      name: 'contactId',
                      required: true,
                      description: true,
                      pick: (properties: { id: string }) => {
                        return new ContactCrmRepository().pickForCombo({
                          contactId: properties.id,
                        });
                      },
                      repository: ({ search }) => {
                        return new ContactCrmRepository().listForCombo({
                          search,
                          active: true,
                        });
                      },
                    },
                    {
                      type: 'select',
                      name: 'clinicId',
                      required: true,
                      description: true,
                      pick: (properties: { id: string }) => {
                        return new ClinicAccessRepository().pickForCombo({ id: properties.id });
                      },
                      repository: ({ search }) => {
                        return new ContractAccessRepository().list({ search }).then((contracts) =>
                          contracts.items.map((contract) => ({
                            value: contract.clinic.clinicId,
                            label: contract.clinic.name,
                          }))
                        );
                      },
                    },
                    {
                      type: 'text',
                      name: 'description',
                      required: false,
                      validations: ['description'],
                      description: true,
                      lines: 6,
                    },
                    {
                      type: 'select',
                      name: 'campaignId',
                      required: true,
                      description: true,
                      pick: (properties: { id: string }) => {
                        return new CampaignCrmRepository().pickForCombo({
                          campaignId: properties.id,
                        });
                      },
                      repository: ({ search }) => {
                        return new CampaignCrmRepository().listForCombo({
                          active: true,
                          search,
                        });
                      },
                    },
                  ]}
                >
                  {lead?.contact && !phone && passport}
                </FormComponent>
              </>
            );
          },
        },
        {
          name: 'contact',
          icon: 'address-card',
          content: (settings) => (
            <ContactUpdatePart
              contactId={properties.route.params.contactId}
              onSend={() => {
                settings?.setIsEditing(false);
                showSuccess();
              }}
              onChange={(data) => {
                if (!data.isLocal) return;
                settings?.setIsEditing(true);
              }}
            />
          ),
        },
        ResponsibleUpdateLeadPart(properties),
        ActionsUpdateLeadPart(properties),
        SpecialistUpdateLeadPart(properties),
        {
          name: 'notes',
          icon: 'sticky-note',
          content: () => (
            <NotesComponent
              repository={{
                get: () =>
                  new LeadNoteCrmRepository().findByLead({
                    leadId: properties.route.params.id,
                    clinicId: properties.route.params.clinicId,
                  }),
                send: (text: string) =>
                  new LeadNoteCrmRepository().create({
                    item: {
                      text,
                      leadId: properties.route.params.id,
                      clinicId: properties.route.params.clinicId,
                    },
                  }),
                update: (settings: { text: string; noteId: string }) =>
                  new LeadNoteCrmRepository().update({
                    text: settings.text,
                    leadNoteId: settings.noteId,
                    leadId: properties.route.params.id,
                    clinicId: properties.route.params.clinicId,
                  }),
                delete: (noteId: string) =>
                  new LeadNoteCrmRepository().delete({
                    leadNoteId: noteId,
                    leadId: properties.route.params.id,
                    clinicId: properties.route.params.clinicId,
                  }),
              }}
            />
          ),
        },
      ]}
    />
  );

  return (
    <ModalComponent icon="user-ninja" prefix="crm.leads.update">
      {tabs}
    </ModalComponent>
  );
}
