import { TabType } from '@components/tabs/tabs.types';
import { NavigationProp, RouteProp } from '@react-navigation/native';
import { FormComponent } from '@components/form/form.component';
import { CoworkerCrmRepository } from '@human/crm/repositories/coworker/coworker.repository';
import { PatientCrmType } from '@human/crm/types/patient.crm.type';
import { PatientCrmRepository } from '@human/crm/repositories/patient/patient.repository';
import { UserAccessRepository } from '@human/access/repositories/user/user.repository';
import { useSuccessToast } from '@hooks/successToast/successToast.hook';
import { PatientsRoutes } from '../../../patient.routes';

export function ResponsibleUpdatePatientPart(properties: {
  navigation: NavigationProp<PatientsRoutes>;
  route: RouteProp<PatientsRoutes, 'patientUpdate'>;
}): TabType {
  const { showSuccess } = useSuccessToast();
  return {
    name: 'responsible',
    icon: 'user-ninja',
    content: (settings) => {
      return (
        <FormComponent<PatientCrmType>
          prefix="crm.patients.update.responsible.form"
          repository={{
            send: (data) => {
              return new PatientCrmRepository()
                .updateResponsible({
                  item: {
                    responsibleId: data.item.responsibleId,
                    clinicId: properties.route.params.clinicId,
                    patientId: properties.route.params.patientId,
                  },
                })
                .then(() => {
                  settings?.setIsEditing(false);
                  showSuccess();
                });
            },
            get: () => {
              return new PatientCrmRepository().pick({
                id: properties.route.params.patientId,
                clinicId: properties.route.params.clinicId,
              });
            },
          }}
          fields={[
            {
              type: 'select',
              name: 'responsibleId',
              required: true,
              description: true,
              multiWorkspace: true,
              pick: (props: { id: string }) => {
                return new CoworkerCrmRepository().pickForCombo({
                  clinicId: properties.route.params.clinicId,
                  coworkerId: props.id,
                });
              },
              repository: (settings) => {
                return new CoworkerCrmRepository().listForCombo({
                  clinicId: properties.route.params.clinicId,
                  active: true,
                  search: settings.search,
                });
              },
            },
          ]}
        />
      );
    },
  };
}
