/**
 * DayJS y Date utilizan el estandard 0 Sunday - 6 Saturday.
 * Nosotros como somos unos iluminados utilizamos el 0 Monday - 6 Sunday.
 * Esta funcion adapta el dia de la semana de DayJS a nuestro estandard teniendo en cuenta que puedo recibir numeros negativos.
 * Ya que voy a hacer cosas como .day() - 1 y si me llega 0 no quiero que me devuelva 6.
 */
export function adaptWeekDayUtility(weekDay: number) {
  if (weekDay === 0) {
    return 6;
  }
  return weekDay - 1;
}
