import { IconComponent } from '@components/icon/icon.component';
import { TextComponent } from '@components/text/text.component';
import { View, TouchableOpacity } from 'react-native';
import { WidgetStyles } from './widget.styles';
import { WidgetProperties } from './widget.properties';
import { useEffect, useState } from 'react';

/**
 * Widget Component
 * @description Sección del componente Performance que contiene un icono, un título y un contador.
 *
 * @param {WidgetProperties} properties
 * @returns {JSX.Element}
 */

export function WidgetComponent(properties: WidgetProperties) {
  const [isOpen, setIsOpen] = useState(false);
  const [firstRender, setFirstRender] = useState(true);

  useEffect(() => {
    if (isOpen && firstRender) setFirstRender(false);
  }, [isOpen]);

  return (
    <View
      style={{
        flex: properties.flex,
      }}
    >
      <TouchableOpacity style={WidgetStyles.container} onPress={() => setIsOpen(!isOpen)}>
        <View style={WidgetStyles.iconContainer}>
          <IconComponent name={properties.icon} iconStyle={WidgetStyles.icon} />
        </View>
        <View style={WidgetStyles.titleContainer}>
          <TextComponent
            bold
            translate
            text={`${properties.name}.title`}
            style={WidgetStyles.titleText}
          />
          <TextComponent
            translate
            text={`${properties.name}.subtitle`}
            style={WidgetStyles.subTitleText}
          />
        </View>
        <View style={WidgetStyles.counterContainer}>
          {properties.counter && (
            <View style={WidgetStyles.counterTextContainer}>
              <TextComponent bold text={`${properties.counter}`} style={WidgetStyles.counterText} />
            </View>
          )}
          <View style={WidgetStyles.angleDownContainer}>
            <IconComponent
              name={isOpen ? 'angle-up' : 'angle-down'}
              iconStyle={WidgetStyles.angleDownIcon}
            />
          </View>
        </View>
      </TouchableOpacity>
      <View
        style={[
          WidgetStyles.content,
          {
            display: isOpen ? 'flex' : 'none',
          },
        ]}
      >
        {!firstRender && properties.children}
      </View>
    </View>
  );
}
