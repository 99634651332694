import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ModalComponent } from '@components/modal/modal.component';
import { FormComponent } from '@components/form/form.component';
import { TreatmentsRoutes } from '../../treatment.routes';
import { TreatmentCrmRepository } from '@human/crm/repositories/treatment/treatment.repository';

export function EnableTreatmentScreen(properties: {
  navigation: NavigationProp<TreatmentsRoutes>;
  route: RouteProp<TreatmentsRoutes, 'enableTreatment'>;
}) {
  return (
    <ModalComponent icon="trash-alt" prefix="crm.treatments.enable">
      <FormComponent
        prefix="crm.treatments.enable.form"
        padding
        repository={{
          send: () => {
            return new TreatmentCrmRepository()
              .enable({
                treatmentId: properties.route.params.treatmentId,
                patientId: properties.route.params.patientId,
                clinicId: properties.route.params.clinicId,
              })
              .then(() => {
                properties.navigation.goBack();
              });
          },
        }}
        fields={[]}
      />
    </ModalComponent>
  );
}
