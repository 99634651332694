import { ModalComponent } from '@components/modal/modal.component';
import { NavigationProp, RouteProp } from '@react-navigation/native';
import { PatientsRoutes } from '../../patient.routes';
import { FormComponent } from '@components/form/form.component';
import { PatientCrmRepository } from '@human/crm/repositories/patient/patient.repository';
import { TabsComponent } from '@components/tabs/tabs.component';
import { ContactUpdatePatientPart } from './parts/contact.update.patient.part';
import { PatientCrmType } from '@human/crm/types/patient.crm.type';
import { NotesUpdatePatientPart } from './parts/notes.update.patient.part';
import { TreatmentUpdatePatientPart } from './parts/treatment.update.patient.part';
import { ActionsUpdatePatientPart } from './parts/actions.update.patient.part';
import { CrmSections } from '@human/crm/crm.sections';
import { useSuccessToast } from '@hooks/successToast/successToast.hook';
import { PurchasesUpdatePatientPart } from './parts/purchases.update.patient.part';
import { ResponsibleUpdatePatientPart } from './parts/responsible.update.patient.part';
import { hasPermissionUtilty } from '@utils/permission/has.permission.utility';
import { SpecialistUpdatePatient } from './parts/specialist.update.patient.part';
import { DocumentUpdatePatientPart } from './parts/document.update.patient.part';
import { getWorkspaceUtility } from '@utils/workspace/getWorkspace.utility';

export function UpdatePatientScreen(properties: {
  navigation: NavigationProp<PatientsRoutes>;
  route: RouteProp<PatientsRoutes, 'patientUpdate'>;
}) {
  const { showSuccess } = useSuccessToast();

  const workSpaceTabs =
    getWorkspaceUtility() === 'ovoclinic'
      ? [
          TreatmentUpdatePatientPart(properties),
          ActionsUpdatePatientPart(properties),
          PurchasesUpdatePatientPart(properties),
        ]
      : [ActionsUpdatePatientPart(properties)];

  return (
    <ModalComponent icon="user-edit" flex={false} prefix="crm.patients.update" multiWorkspace>
      <TabsComponent
        layout="horizontal"
        prefix="crm.patients.update"
        multiworkspace
        tabs={[
          {
            name: 'patient',
            icon: CrmSections.patients.icon,
            content: (settings) => {
              return (
                <FormComponent<PatientCrmType>
                  onChange={(data) => {
                    if (!data.isLocal) return;
                    settings?.setIsEditing(true);
                  }}
                  multiworkspace
                  readonly={!hasPermissionUtilty({ name: 'crm.patients.editor' })}
                  key={'patient'}
                  prefix="crm.patients.update.form"
                  repository={{
                    send: (data) => {
                      return new PatientCrmRepository()
                        .update({
                          item: data.item,
                        })
                        .then(() => {
                          settings?.setIsEditing(false);
                          showSuccess();
                        });
                    },
                    get: () => {
                      return new PatientCrmRepository().pick({
                        id: properties.route.params.patientId,
                        clinicId: properties.route.params.clinicId,
                      });
                    },
                  }}
                  fields={[
                    {
                      type: 'text',
                      name: 'description',
                      required: true,
                      description: true,
                      lines: 6,
                      validations: ['description'],
                      multiWorkspace: true,
                    },
                    {
                      type: 'checkbox',
                      name: 'warrantyAgreement',
                      required: true,
                      description: true,
                      multiWorkspace: true,
                    },
                    {
                      type: 'checkbox',
                      name: 'consentCheck',
                      required: true,
                      description: true,
                      multiWorkspace: true,
                    },
                  ]}
                />
              );
            },
          },
          DocumentUpdatePatientPart(properties),
          ContactUpdatePatientPart(properties),
          ResponsibleUpdatePatientPart(properties),
          NotesUpdatePatientPart({
            patientId: properties.route.params.patientId,
            clinicId: properties.route.params.clinicId,
          }),
          SpecialistUpdatePatient(properties),
          ...workSpaceTabs,
        ]}
      />
    </ModalComponent>
  );
}
