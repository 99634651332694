import { IconComponent } from '@components/icon/icon.component';
import { TextComponent } from '@components/text/text.component';
import { useState } from 'react';
import { TouchableOpacity, View } from 'react-native';
import { TabsStyles } from './tabs.style';
import { TabsProperties } from './tabs.properties';
import { useDeviceHook } from '@hooks/device/device.hook';
import { useTranslation } from 'react-i18next';

/**
 * @name TabsComponent
 * @description
 * TabsComponent renders a tabbed view with accompanying icons and text.
 * The component allows the user to switch between different tabs.
 *
 * @param {TabsProperties} properties - The properties object containing the tabs of the component.
 *
 * @returns {JSX.Element} The rendered JSX element containing the tabs and content.
 *
 * @example
 * <TabsComponent tabs={[{ icon: 'activity', name: 'Activity', content: () => <ActivityComponent /> }]} />
 *
 */

export function TabsComponent(properties: TabsProperties) {
  const { t } = useTranslation();
  const { phone, desktop, miniDesktop } = useDeviceHook();

  const [currentTab, setCurrentTab] = useState(0);
  const [isEditing, setIsEditing] = useState(false);

  let auxLayout = properties.layout;
  if (!desktop && !miniDesktop) auxLayout = 'vertical';

  const isLargeAmountOfTabs =
    auxLayout === 'vertical' && (properties.width ?? 500) / properties.tabs.length < 116;

  return (
    <View
      style={[
        auxLayout === 'horizontal' && TabsStyles.containerHorizontal,
        auxLayout === 'vertical' && TabsStyles.containerVertical,
      ]}
    >
      <View
        style={[
          auxLayout === 'horizontal' && TabsStyles.tabContainerHorizontal,
          auxLayout === 'vertical' && TabsStyles.tabContainerVertical,
          properties.darkMode && { backgroundColor: '#27343d' },
        ]}
      >
        {properties.tabs.map((tab, index) => (
          <TouchableOpacity
            key={index}
            style={[
              auxLayout === 'horizontal' && TabsStyles.tabHorizontal,
              auxLayout === 'vertical' && TabsStyles.tabVertical,
              currentTab !== index && isLargeAmountOfTabs && TabsStyles.largeTabsInactive,
              currentTab === index ? TabsStyles.tabActive : TabsStyles.tabInactive,
              properties.darkMode && {
                backgroundColor: '#1C242A',
                borderBottomColor: '#1C242A',
              },
            ]}
            onPress={() => {
              if (!isEditing) return setCurrentTab(index);
              const result = window.confirm(
                t('form.tabChange.title') ??
                  'Estas editando un formulario. ¿Estás seguro de que deseas cambiar de pestaña?'
              );
              if (result) {
                setIsEditing(false);
                setCurrentTab(index);
              }
            }}
          >
            <IconComponent
              name={tab.icon}
              iconStyle={[
                TabsStyles.tabIcon,
                currentTab === index ? TabsStyles.tabIconActive : TabsStyles.tabIconInactive,
              ]}
            />
            {(currentTab === index || !isLargeAmountOfTabs) && (
              <TextComponent
                translate
                bold
                style={[
                  TabsStyles.tabText,
                  currentTab === index ? TabsStyles.tabTextActive : TabsStyles.tabTextInactive,
                  currentTab === index && properties.darkMode && { color: '#eee' },
                ]}
                text={`${properties.prefix}.tabs.${tab.name}.title`}
                multiWorkspace={properties.multiworkspace}
              />
            )}
          </TouchableOpacity>
        ))}
      </View>

      <View
        style={[TabsStyles.content, properties.darkMode && { backgroundColor: '#1C242A' }]}
        key={currentTab}
      >
        {!properties.disabledDescription && (
          <TextComponent
            translate
            text={`${properties.prefix}.tabs.${properties.tabs[currentTab].name}.description`}
            style={[TabsStyles.description, phone && TabsStyles.descriptionMobile]}
            multiWorkspace={properties.multiworkspace}
          />
        )}
        {properties.tabs[currentTab].content({
          setIsEditing,
        })}
      </View>
    </View>
  );
}
