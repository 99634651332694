import { useTranslation } from 'react-i18next';
import { Text, View } from 'react-native';
import { months } from '@screens/authorized/human/spaces/board/constants/months.constant';
import { inactiveLeadsReasonTableHeaderStyles } from './inactiveLeadsReasonTableHeader.styles';

export function InactiveLeadsReasonTableHeader() {
  const { t } = useTranslation();
  return (
    <View style={inactiveLeadsReasonTableHeaderStyles.container}>
      {months.map(({ month }, index) => {
        return (
          <Text key={index} style={inactiveLeadsReasonTableHeaderStyles.text}>
            {t(`board.months.${month}`)}
          </Text>
        );
      })}
    </View>
  );
}
