import { View, Text } from 'react-native';
import { DepartmentRowStyles } from './departmentRow.styles';
import { DepartmentRowProperties } from './departmentRow.properties';
import { useDeviceHook } from '@hooks/device/device.hook';

export const DepartmentRowComponent = (properties: DepartmentRowProperties) => {
  const { phone } = useDeviceHook();

  return (
    <View
      style={[
        phone ? DepartmentRowStyles.rowContainerMobile : DepartmentRowStyles.rowContainer,
        properties.last
          ? DepartmentRowStyles.rowContainerEven
          : DepartmentRowStyles.rowContainerOdd,
      ]}
    >
      <View
        style={[
          phone
            ? {
                width: 120,
                justifyContent: 'space-between',
              }
            : {
                flexDirection: 'row',
                flex: 2,
                justifyContent: 'space-between',
                padding: 10,
              },
        ]}
      >
        <Text
          style={phone ? DepartmentRowStyles.phoneRowSpaceText : DepartmentRowStyles.rowSpaceText}
        >
          {properties.row.department_name}
        </Text>
      </View>
      {/** TOTAL */}
      <View
        style={
          phone
            ? DepartmentRowStyles.rowColumnContainerMobile
            : DepartmentRowStyles.rowColumnContainer
        }
      >
        <Text
          style={[
            phone && DepartmentRowStyles.centeredRowText,
            !phone && DepartmentRowStyles.rowText,
          ]}
        >
          {properties.row.total_leads}
        </Text>
      </View>
      {/**Contactado */}
      <View
        style={
          phone
            ? DepartmentRowStyles.rowColumnContainerMobile
            : DepartmentRowStyles.rowColumnContainer
        }
      >
        <Text
          style={[
            phone && DepartmentRowStyles.centeredRowText,
            !phone && DepartmentRowStyles.rowText,
          ]}
        >
          {properties.row.lead_contacted_count}
        </Text>
      </View>
      {/** Citada V.I comercial */}
      <View
        style={
          phone
            ? DepartmentRowStyles.rowColumnContainerMobile
            : DepartmentRowStyles.rowColumnContainer
        }
      >
        <Text
          style={[
            phone && DepartmentRowStyles.centeredRowText,
            !phone && DepartmentRowStyles.rowText,
          ]}
        >
          {properties.row.lead_appointed_count}
        </Text>
      </View>
      {/**V.I comercial realizada */}
      <View
        style={
          phone
            ? DepartmentRowStyles.rowColumnContainerMobile
            : DepartmentRowStyles.rowColumnContainer
        }
      >
        <Text
          style={[
            phone && DepartmentRowStyles.centeredRowText,
            !phone && DepartmentRowStyles.rowText,
          ]}
        >
          {properties.row.lead_informed_count}
        </Text>
      </View>
      {/**'V.I Médica' */}
      <View
        style={
          phone
            ? DepartmentRowStyles.rowColumnContainerMobile
            : DepartmentRowStyles.rowColumnContainer
        }
      >
        <Text
          style={[
            phone && DepartmentRowStyles.centeredRowText,
            !phone && DepartmentRowStyles.rowText,
          ]}
        >
          {properties.row.lead_medical_appointed_count}
        </Text>
      </View>
      {/**V.I Médica realizada */}
      <View
        style={
          phone
            ? DepartmentRowStyles.rowColumnContainerMobile
            : DepartmentRowStyles.rowColumnContainer
        }
      >
        <Text
          style={[
            phone && DepartmentRowStyles.centeredRowText,
            !phone && DepartmentRowStyles.rowText,
          ]}
        >
          {properties.row.lead_medic_attended_count}
        </Text>
      </View>
      {/**Presupuesto entregado */}
      <View
        style={
          phone
            ? DepartmentRowStyles.rowColumnContainerMobile
            : DepartmentRowStyles.rowColumnContainer
        }
      >
        <Text
          style={[
            phone && DepartmentRowStyles.centeredRowText,
            !phone && DepartmentRowStyles.rowText,
          ]}
        >
          {properties.row.lead_comercial_attended_count}
        </Text>
      </View>
      {/**Evaluacion médica */}
      <View
        style={
          phone
            ? DepartmentRowStyles.rowColumnContainerMobile
            : DepartmentRowStyles.rowColumnContainer
        }
      >
        <Text
          style={[
            phone && DepartmentRowStyles.centeredRowText,
            !phone && DepartmentRowStyles.rowText,
          ]}
        >
          {properties.row.lead_medical_evaluated_count}
        </Text>
      </View>
      {/**Convertidos */}
      <View
        style={
          phone
            ? DepartmentRowStyles.rowColumnContainerMobile
            : DepartmentRowStyles.rowColumnContainer
        }
      >
        <Text
          style={[
            phone && DepartmentRowStyles.centeredRowText,
            !phone && DepartmentRowStyles.rowText,
          ]}
        >
          {properties.row.lead_converted_count}
        </Text>
      </View>
    </View>
  );
};
