// Modules
import { StyleSheet } from 'react-native';

/**
 * Accordion input Styles
 * @constant {StyleSheet} AccordionInputStyles
 */
export const AccordionInputStyles = StyleSheet.create({
  title: {
    overflow: 'hidden',
    borderRadius: 5,
    shadowColor: '#000',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 40,
    maxHeight: 40,
  },
  titleText: {
    fontSize: 14,
    paddingLeft: 50,
    fontFamily: 'Lato-Bold',
  },
  titleIconContainer: {
    height: 27,
    backgroundColor: 'rgb(255, 222, 238)',
    borderRadius: 50,
    width: 27,
    position: 'absolute',
    left: 7,
    top: 7,
    zIndex: 1,
  },
  titleIconStyle: {
    color: 'rgba(237, 53, 145, 1)',
    fontSize: 16,
    textAlign: 'center',
    lineHeight: 27,
  },
  titleAngleIconContainer: {
    height: 27,
    backgroundColor: 'rgba(0,0,0,0.05)',
    borderRadius: 50,
    width: 27,
    position: 'absolute',
    right: 7,
    top: 7,
    zIndex: 1,
  },
  titleAngleIconStyle: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: 12,
    textAlign: 'center',
    lineHeight: 27,
  },
  container: {
    borderRadius: 5,
    overflow: 'hidden',
    flexDirection: 'column',
    borderColor: '#fff',
    borderWidth: 2,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.1,
    shadowRadius: 3.84,
    elevation: 5,
  },
  containerOpen: {
    borderColor: 'rgba(237, 53, 145, 1)',
  },
  content: {
    flexDirection: 'column',
    gap: 0,
    padding: 20,
    backgroundColor: '#ddd',
  },
});
