import { ScrollView, Text, View } from 'react-native';
import { SubordinatePerformanceMonthStyle } from './subordinatePerformanceMonth.styles';
import { useTranslation } from 'react-i18next';

export function SubordinatePerformanceTable(properties: {
  data: {
    [key: string]: string | number;
  };
}) {
  const { t } = useTranslation();

  const leadEvent: { [key: string]: string } = {
    CONTACTED: t('leadEvents.contacted'),
    APPOINTED: t('leadEvents.appointed'),
    INFORMED: t('leadEvents.informed'),
    MEDICALAPPOINTED: t('leadEvents.medicalAppointed'),
    ATTENDED: t('leadEvents.attended'),
    COMERCIALATTENDED: t('leadEvents.comercialAttended'),
    MEDICALEVALUATED: t('leadEvents.medicalEvaluated'),
    CONVERTED: t('leadEvents.converted'),
  };

  const mapping = {
    CONTACTED: 'lead_contacted_count',
    APPOINTED: 'lead_appointed_count',
    INFORMED: 'lead_informed_count',
    MEDICALAPPOINTED: 'lead_medical_appointed_count',
    ATTENDED: 'lead_medic_attended_count',
    COMERCIALATTENDED: 'lead_comercial_attended_count',
    MEDICALEVALUATED: 'lead_medical_evaluated_count',
    CONVERTED: 'lead_converted_count',
  };

  return (
    <ScrollView horizontal style={{ flexDirection: 'column' }}>
      <View style={{ flex: 1, backgroundColor: 'white' }}>
        <View style={{ height: 20 }} />

        {Object.keys(leadEvent).map((title, i) => (
          <View style={[SubordinatePerformanceMonthStyle.rowContainer]}>
            <View style={SubordinatePerformanceMonthStyle.subRowSpaceColumnContainer}>
              <Text style={[SubordinatePerformanceMonthStyle.subRowSpaceText]}>
                {leadEvent[title]}
              </Text>
            </View>

            <View style={SubordinatePerformanceMonthStyle.subRowColumnContainer}>
              <Text style={[SubordinatePerformanceMonthStyle.subRowText]}>
                {
                  properties.data[
                    mapping[title as keyof typeof leadEvent] as keyof typeof properties.data
                  ]
                }
              </Text>
            </View>
            <View style={SubordinatePerformanceMonthStyle.subRowDivider} />
          </View>
        ))}
      </View>
    </ScrollView>
  );
}
