import { NavigationProp, RouteProp } from '@react-navigation/native';
import { UsersRoutes } from '../../../users.routes';
import { TabType } from '@components/tabs/tabs.types';
import { TableComponent } from '@components/table/table.component';
import { AttemptAccessType } from '@human/access/types/attempt.access.type';
import { AttemptAccessRepository } from '@human/access/repositories/attempt/attempt.repository';
import { useTranslation } from 'react-i18next';
import { ScrollView } from 'react-native';

export function AttemptsUpdateUserPart(properties: {
  navigation: NavigationProp<UsersRoutes>;
  route: RouteProp<UsersRoutes, 'update'>;
}): TabType {
  const { t } = useTranslation();
  return {
    name: 'attempts',
    icon: 'exclamation-triangle',
    content: () => {
      return (
        <ScrollView>
          <TableComponent<AttemptAccessType, []>
            key={'attempts'}
            prefix={'access.attempts.list'}
            suffix={'list'}
            header={false}
            hideNoDataImage
            search={false}
            scrollable={true}
            repository={(settings) => {
              return new AttemptAccessRepository().list({
                userId: properties.route.params.userId,
                page: settings.page,
                limit: settings.limit,
              });
            }}
            columns={[
              {
                type: 'text',
                width: 315,
                onRender: (row) => t(row.status ? 'common.status.success' : 'common.status.fail'),
              },
              {
                type: 'date',
                renderDate: (row) => row.created,
              },
            ]}
          />
        </ScrollView>
      );
    },
  };
}
