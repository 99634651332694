import { SceneComponent } from '@components/scene/scene.component';
import { ClinicAccessRepository } from '@human/access/repositories/clinic/clinic.repository';
import { LanguageAccessRepository } from '@human/access/repositories/language/language.repository';
import { FunnelCrmRepository } from '@human/crm/repositories/funnel/funnel.repository';
import { useState, useEffect } from 'react';
import { View } from 'react-native';
import { BoardHeaderComponent } from '../../../components/header/board.header.component';
import { LeadDetailBoardRepository } from '../../../repositories/leadDetail/leadDetail.repository';
import { ItemByDepartmentYearsComparativeType } from '../components/newLeadsByDepartmentYearsComparative/newLeadByDepartmentYearsComparative.type';
import { useTranslation } from 'react-i18next';
import { BoardFilterComponent } from './components/filter/filter.component';
import { LeadComparativeTable } from './components/leadComparativeTable/leadComparativeTable';
import { LeadComparativeChart } from './components/leadComparativeChart/leadComparativeChart';
import { LeadActiveInactiveChart } from './components/leadActiveInactive/leadActiveInactiveChart';
import { BoardStyles } from '../../common/board.styles';
import { useNavigation } from '@react-navigation/native';
import { CampaignCrmRepository } from '@human/crm/repositories/campaign/campaign.repository';

export function OvoclinicLeadsScreen() {
  const { t } = useTranslation();
  const navigation = useNavigation();

  const [selectedClinic, setSelectedClinic] = useState<string>();

  const [selectedLanguage, setSelectedLanguage] = useState<string>();
  const [languages, setLanguages] = useState<{ label: string; value: string }[]>([]);

  const [firstYear, setFirstYear] = useState<number>(new Date().getFullYear() - 1);
  const [secondYear, setSecondYear] = useState<number>(new Date().getFullYear());

  const [selectedFunnel, setSelectedFunnel] = useState<string>('all');
  const [selectedCampaign, setSelectedCampaign] = useState<string>('all');

  const [data, setData] = useState<ItemByDepartmentYearsComparativeType[]>([]);
  //Indicador para saber cuando esta cargando por una peticion a network
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!selectedClinic || !selectedFunnel || !selectedCampaign) return;
    setLoading(true);
    new LeadDetailBoardRepository()
      .newLeadCountYearsComparative({
        clinicId: selectedClinic,
        firstYear,
        secondYear,
        funnelId: selectedFunnel === 'all' ? undefined : selectedFunnel,
        campaignId: selectedCampaign === 'all' ? undefined : selectedCampaign,
      })
      .then((data) => {
        setData(data);
      })
      .finally(() => setLoading(false));
  }, [selectedClinic, firstYear, secondYear, selectedFunnel, selectedCampaign]);

  useEffect(() => {
    if (!selectedClinic) return;

    new LanguageAccessRepository().listForCombo({}).then((languages) => {
      setLanguages(languages);
      setSelectedLanguage(languages[0]?.value);
    });
  }, [selectedClinic]);

  return (
    <SceneComponent icon={'search'} prefix={'board.leads'} links={[]}>
      <View style={BoardStyles.cardContainer}>
        <BoardHeaderComponent
          title="board.leads.totalLeads.title"
          description="board.leads.totalLeads.description"
        />
        <View style={BoardStyles.fiterContainer}>
          {/** SELECTO DE CLINICA */}
          <BoardFilterComponent
            width={250}
            title={t('board.leads.totalLeads.filters.clinic')}
            subtitle={t('board.leads.totalLeads.filters.clinicSubtitle') || ''}
            value={selectedClinic ?? ''}
            name="clinic"
            onChange={(value: any) => {
              setSelectedClinic(value.clinic);
            }}
            pick={async ({ id }) =>
              new ClinicAccessRepository().pickForCombo({
                id,
              })
            }
            repository={async ({ search }) => {
              const list = await new ClinicAccessRepository().listForCombo({
                search,
              });
              setSelectedClinic(list[0]?.value);
              return list;
            }}
          />
          {/** SELECTOR DE PRIMER AÑO/OPTION A  */}
          <BoardFilterComponent
            width={200}
            title={t('board.leads.totalLeads.filters.optionA')}
            subtitle={t('board.leads.totalLeads.filters.optionASubtitle') || ''}
            value={'' + firstYear}
            name="fistYear"
            onChange={(value: any) => {
              setFirstYear(+value.fistYear);
            }}
            options={Array.from({ length: 8 }, (_, i) => {
              i = i - 8;
              return {
                label: `${new Date().getFullYear() + i}`,
                value: `${new Date().getFullYear() + i}`,
              };
            })}
          />
          {/**SELECTOR SEGUNDO AÑO/OPCION B*/}
          <BoardFilterComponent
            width={200}
            title={t('board.leads.totalLeads.filters.optionB')}
            subtitle={t('board.leads.totalLeads.filters.optionBSubtitle') ?? ''}
            value={'' + secondYear}
            name="secondYear"
            onChange={(value: any) => {
              setSecondYear(+value.secondYear);
            }}
            options={Array.from({ length: 8 }, (_, i) => {
              i = i - 7;
              return {
                label: `${new Date().getFullYear() + i}`,
                value: `${new Date().getFullYear() + i}`,
              };
            })}
          />
          {/**SELECTOR DE PROCEDENCIA */}
          <BoardFilterComponent
            width={200}
            title={t('board.leads.totalLeads.filters.funnel')}
            subtitle={t('board.leads.totalLeads.filters.funnelSubtitle') ?? ''}
            value={selectedFunnel ?? ''}
            name={'selectedFunnel'}
            onChange={(value: any) => {
              setSelectedFunnel(value.selectedFunnel);
              setSelectedCampaign('all');
            }}
            pick={async ({ id }) => {
              if (id === 'all') return t('board.leads.totalLeads.filters.all') ?? '';
              return new FunnelCrmRepository().pickForCombo({
                id,
              });
            }}
            repository={async ({ search }) => {
              const list = await new FunnelCrmRepository().listForCombo({
                search,
              });
              list.unshift({ label: t('board.leads.totalLeads.filters.all') ?? '', value: 'all' });
              return list;
            }}
          />
          <BoardFilterComponent
            width={200}
            title={t('board.leads.totalLeads.filters.campaign')}
            subtitle={t('board.leads.totalLeads.filters.campaignSubtitle') ?? ''}
            value={selectedCampaign ?? ''}
            name={'selectedCampaign'}
            onChange={(value: any) => {
              setSelectedCampaign(value.selectedCampaign);
              setSelectedFunnel('all');
            }}
            pick={async ({ id }) => {
              if (id === 'all') return t('board.leads.totalLeads.filters.all') ?? '';
              return new CampaignCrmRepository().pickForCombo({
                campaignId: id,
              });
            }}
            repository={async ({ search }) => {
              const list = await new CampaignCrmRepository().listForCombo({
                active: true,
                search,
              });
              list.unshift({ label: t('board.leads.totalLeads.filters.all') ?? '', value: 'all' });
              return list;
            }}
          />
        </View>

        {/** TABLA COMPARATIVA DE LEADS  */}
        <LeadComparativeTable
          clinicId={selectedClinic}
          optionA={firstYear}
          optionB={secondYear}
          data={data}
          loading={loading}
          onCellPress={({ departmentId, year, month }) => {
            // @ts-ignore
            navigation.navigate('crm', {
              screen: 'leads',
              params: {
                screen: 'leadsList',
                params: {
                  departmentId,
                  year,
                  month,
                  clinicId: selectedClinic,
                  funnelId: selectedFunnel !== 'all' ? selectedFunnel : undefined,
                  campaignId: selectedCampaign !== 'all' ? selectedCampaign : undefined,
                },
              },
            });
          }}
        />

        {/**DEPARTMENT */}

        <View style={[BoardStyles.fiterContainer, { marginTop: 50 }]}>
          <BoardFilterComponent
            width={250}
            title={t('board.leads.totalLeads.filters.department')}
            subtitle={t('board.leads.totalLeads.filters.departmentSubtitle') || ''}
            value={selectedLanguage ?? ''}
            name="department"
            onChange={(value: any) => {
              setSelectedLanguage(value.department);
            }}
            pick={async ({ id }) =>
              new LanguageAccessRepository().pickForCombo({
                languageId: selectedLanguage ?? '',
              })
            }
            repository={async () => {
              return Promise.resolve(languages);
            }}
          />
        </View>
        {/** GRAFICOS DE LEADS FILTRADOS POR DEPARTAMENTOS */}
        <LeadComparativeChart
          clinicId={selectedClinic}
          optionA={firstYear}
          optionB={secondYear}
          data={data}
          loading={loading}
          languages={languages}
          languagueId={selectedLanguage}
        />

        {/**Active/Inactive */}

        <LeadActiveInactiveChart
          clinicId={selectedClinic}
          optionA={firstYear}
          optionB={secondYear}
          data={data}
          loading={loading}
          languageId={selectedLanguage}
        />
      </View>
    </SceneComponent>
  );
}
