import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ModalComponent } from '@components/modal/modal.component';
import { FormComponent } from '@components/form/form.component';
import { ReasonsRoutes } from '../../reason.routes';
import { ReasonCrmRepository } from '@human/crm/repositories/reason/reason.repository';

export function EnableReasonScreen(properties: {
  navigation: NavigationProp<ReasonsRoutes>;
  route: RouteProp<ReasonsRoutes, 'enable'>;
}) {
  return (
    <ModalComponent icon="trash-alt" prefix="crm.reasons.enable">
      <FormComponent
        prefix="crm.reasons.enable.form"
        padding
        repository={{
          send: () => {
            return new ReasonCrmRepository()
              .enable({
                reasonId: properties.route.params.id,
              })
              .then(() => {
                properties.navigation.goBack();
              });
          },
        }}
        fields={[]}
      />
    </ModalComponent>
  );
}
