import { NavigationProp, RouteProp } from '@react-navigation/native';
import { TemplatesRoutes } from '../../templates.routes';
import { SceneComponent } from '@components/scene/scene.component';
import { TableComponent } from '@components/table/table.component';
import { IconType } from '@components/icon/icon.types';
import { hasPermissionUtilty } from '@utils/permission/has.permission.utility';
import { ColumnProperties } from '@components/table/components/columns/column.properties';
import { TemplateAccessType } from '@human/access/types/template.access.type';
import { TemplateAccessRepository } from '@human/access/repositories/template/template.repository';

export function ListTemplatesScreen(properties: {
  route: RouteProp<TemplatesRoutes, 'list'>;
  navigation: NavigationProp<TemplatesRoutes>;
}) {
  const links: {
    name: string;
    icon: IconType;
    onPress: () => void;
  }[] = [];

  if (
    hasPermissionUtilty({
      name: 'access.templates.creator',
    })
  ) {
    links.push({
      icon: 'plus',
      name: 'create',
      onPress: () => properties.navigation.navigate('create'),
    });
  }

  const columns: ColumnProperties<TemplateAccessType, []>[] = [
    {
      type: 'icon',
      icon: hasPermissionUtilty({ name: 'access.templates.editor' }) ? 'pencil-alt' : 'eye',
      onPress: (row) => {
        properties.navigation.navigate('update', {
          templateId: row.id,
        });
      },
    },
    {
      name: 'label',
      type: 'text',
      width: 200,
      onRender: (row) => row.label,
    },
  ];

  if (hasPermissionUtilty({ name: 'access.templates.editor' })) {
    columns.unshift({
      type: 'flag',
      status: (row) => row.status,
      onPress: (row) => {
        properties.navigation.navigate(row.status ? 'disable' : 'enable', {
          templateId: row.id,
        });
      },
    });
  }

  if (hasPermissionUtilty({ name: 'access.templates.destructor' })) {
    columns.unshift({
      type: 'icon',
      icon: 'trash-alt',
      renderColor: () => 'red',
      onPress: (row) => {
        properties.navigation.navigate('delete', {
          templateId: row.id,
        });
      },
    });
  }

  return (
    <SceneComponent icon="file-alt" prefix={'access.templates.list'} links={links}>
      <TableComponent<TemplateAccessType, []>
        prefix={'access.templates.list'}
        suffix={'list'}
        columns={columns}
        repository={(settings) => {
          return new TemplateAccessRepository().list({
            page: settings.page,
            limit: settings.limit,
          });
        }}
      />
    </SceneComponent>
  );
}
