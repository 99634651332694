import { View, Text, Dimensions } from 'react-native';
import { BoardHeaderComponent } from '../../../../components/header/board.header.component';
import { BoardStyles } from '../../../common/board.styles';
import { useTranslation } from 'react-i18next';
import { FilterSelectInput } from '@components/table/components/columns/components/filterSelect/filter.select.input';
import { ClinicAccessRepository } from '@human/access/repositories/clinic/clinic.repository';
import { CampaignCrmRepository } from '@human/crm/repositories/campaign/campaign.repository';
import { FunnelCrmRepository } from '@human/crm/repositories/funnel/funnel.repository';
import { useEffect, useState } from 'react';
import { InactiveLeadsReasonTableHeader } from './components/inactiveLeadsReasonTableHeader/inactiveLeadsReasonTableHeader.component';
import { LeadDetailBoardRepository } from '../../../../repositories/leadDetail/leadDetail.repository';
import { LanguageAccessRepository } from '@human/access/repositories/language/language.repository';
import { LoaderComponent } from '@components/loader/loader.component';
import { InactiveLeadsReasonTableRowComponent } from './components/inactiveLeadsReasonTableRow/inactiveLeadsReasonTableRow.component';

const height = Dimensions.get('window').height;
export function InactiveLeadsReasonTableComponent() {
  const { t } = useTranslation();

  const [selectedClinic, setSelectedClinic] = useState<string>('all');
  const [selectedDepartment, setSelectedDepartment] = useState<string>('all');
  const [selectedYear, setSelectedYear] = useState<number>(new Date().getFullYear());
  const [selectedFunnel, setSelectedFunnel] = useState<string>('all');
  const [selectedCampaign, setSelectedCampaign] = useState<string>('all');

  const [data, setData] = useState<
    {
      reason_name: string;
      totals: {
        total: number;
        month: number;
      }[];
    }[]
  >([]);
  const [loading, setLoading] = useState<boolean>(true);

  const loadData = async () => {
    setLoading(true);
    const reasonData = await new LeadDetailBoardRepository().leadDisableReason({
      year: selectedYear,
      campaignId: selectedCampaign === 'all' ? undefined : selectedCampaign,
      funnelId: selectedFunnel === 'all' ? undefined : selectedFunnel,
      clinicId: selectedClinic === 'all' ? undefined : selectedClinic,
      departmentId: selectedDepartment === 'all' ? undefined : selectedDepartment,
    });

    const groupByReasonName = reasonData.reduce(
      (acc, item) => {
        if (!acc[item.reason_name]) {
          acc[item.reason_name] = [];
        }
        acc[item.reason_name].push({
          month: item.month,
          total: item.total,
        });
        return acc;
      },
      {} as {
        [key: string]: {
          total: number;
          month: number;
        }[];
      }
    );

    setData(
      Object.keys(groupByReasonName).map((key) => {
        return {
          reason_name: key,
          totals: groupByReasonName[key],
        };
      })
    );
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, [selectedClinic, selectedYear, selectedFunnel, selectedCampaign, selectedDepartment]);

  return (
    <View style={BoardStyles.cardContainer}>
      <BoardHeaderComponent
        title="board.marketing.inactiveLeadsReason.title"
        description="board.marketing.inactiveLeadsReason.description"
      />
      <View style={BoardStyles.fiterContainer}>
        <View style={{ width: 200 }}>
          <View style={BoardStyles.filterTextContainer}>
            <Text style={BoardStyles.filterTitle}>{t('board.marketing.filters.year')}</Text>
            <Text style={BoardStyles.filterSubtitle}>
              {t('board.marketing.filters.yearSubtitle')}
            </Text>
          </View>
          <View>
            <FilterSelectInput
              type="select"
              value={'' + selectedYear}
              name="year"
              onChange={(value: any) => {
                setSelectedYear(+value.year);
              }}
              hideSearch
              options={Array.from({ length: 8 }, (_, i) => {
                i = i - 7;
                return {
                  label: `${new Date().getFullYear() + i}`,
                  value: `${new Date().getFullYear() + i}`,
                };
              })}
            />
          </View>
        </View>
        <View style={{ width: 250 }}>
          <View style={BoardStyles.filterTextContainer}>
            <Text style={BoardStyles.filterTitle}>
              {t('board.leads.totalLeads.filters.clinic')}
            </Text>
            <Text style={BoardStyles.filterSubtitle}>
              {t('board.leads.totalLeads.filters.clinicSubtitle')}
            </Text>
          </View>
          <View>
            <FilterSelectInput
              type="select"
              value={selectedClinic ?? ''}
              name="clinic"
              onChange={(value: any) => {
                setSelectedClinic(value.clinic);
              }}
              pick={async ({ id }) => {
                if (id === 'all') return t('board.leads.totalLeads.filters.all') ?? '';
                return new ClinicAccessRepository().pickForCombo({
                  id,
                });
              }}
              repository={async ({ search }) => {
                const list = await new ClinicAccessRepository().listForCombo({
                  search,
                });
                list.unshift({
                  label: t('board.leads.totalLeads.filters.all') ?? '',
                  value: 'all',
                });
                return list;
              }}
            />
          </View>
        </View>
        <View style={{ width: 250 }}>
          <View style={BoardStyles.filterTextContainer}>
            <Text style={BoardStyles.filterTitle}>
              {t('board.leads.totalLeads.filters.department')}
            </Text>
            <Text style={BoardStyles.filterSubtitle}>
              {t('board.leads.totalLeads.filters.departmentSubtitle')}
            </Text>
          </View>
          <View>
            <FilterSelectInput
              type="select"
              value={selectedDepartment ?? ''}
              name="department"
              onChange={(value: any) => {
                setSelectedDepartment(value.department);
              }}
              pick={async ({ id }) => {
                if (id === 'all') return t('board.leads.totalLeads.filters.all') ?? '';
                return new LanguageAccessRepository().pickForCombo({
                  languageId: id,
                });
              }}
              repository={async ({ search }) => {
                const list = await new LanguageAccessRepository().listForCombo({
                  search,
                });
                list.unshift({
                  label: t('board.leads.totalLeads.filters.all') ?? '',
                  value: 'all',
                });
                return list;
              }}
            />
          </View>
        </View>

        <View style={{ width: 300 }}>
          <View style={BoardStyles.filterTextContainer}>
            <Text style={BoardStyles.filterTitle}>
              {t('board.leads.totalLeads.filters.funnel')}
            </Text>
            <Text style={BoardStyles.filterSubtitle}>
              {t('board.leads.totalLeads.filters.funnelSubtitle')}
            </Text>
          </View>
          <View>
            <FilterSelectInput
              type="select"
              value={selectedFunnel ?? ''}
              name="selectedFunnel"
              onChange={(value: any) => {
                setSelectedFunnel(value.selectedFunnel);
                setSelectedCampaign('all');
              }}
              pick={async ({ id }) => {
                if (id === 'all') return t('board.leads.totalLeads.filters.all') ?? '';
                return new FunnelCrmRepository().pickForCombo({
                  id,
                });
              }}
              repository={async ({ search }) => {
                const list = await new FunnelCrmRepository().listForCombo({
                  search,
                });
                list.unshift({
                  label: t('board.leads.totalLeads.filters.all') ?? '',
                  value: 'all',
                });
                return list;
              }}
            />
          </View>
        </View>

        <View style={{ width: 450 }}>
          <View style={BoardStyles.filterTextContainer}>
            <Text style={BoardStyles.filterTitle}>
              {t('board.leads.totalLeads.filters.campaign')}
            </Text>
            <Text style={BoardStyles.filterSubtitle}>
              {t('board.leads.totalLeads.filters.campaignSubtitle')}
            </Text>
          </View>
          <View>
            <FilterSelectInput
              type="select"
              value={selectedCampaign ?? ''}
              name="selectedCampaign"
              onChange={(value: any) => {
                setSelectedCampaign(value.selectedCampaign);
                setSelectedFunnel('all');
              }}
              pick={async ({ id }) => {
                if (id === 'all') return t('board.leads.totalLeads.filters.all') ?? '';
                return new CampaignCrmRepository().pickForCombo({
                  campaignId: id,
                });
              }}
              repository={async ({ search }) => {
                const list = await new CampaignCrmRepository().listForCombo({
                  active: true,
                  search,
                });
                list.unshift({
                  label: t('board.leads.totalLeads.filters.all') ?? '',
                  value: 'all',
                });
                return list;
              }}
            />
          </View>
        </View>
      </View>
      <View style={BoardStyles.graphicsContainer}>
        <View style={[BoardStyles.graphicContainer, { flex: 4, gap: 15 }]}>
          <View style={BoardStyles.graphicContainerHeader}>
            <Text style={BoardStyles.graphicTitle}>
              {t('board.marketing.inactiveLeadsReason.tableTitle')}
            </Text>
          </View>
          <View style={{ minHeight: height * 0.2 }}>
            <View style={{ flex: 1 }}>
              <View
                style={{
                  flexDirection: 'row',
                }}
              >
                <View
                  style={{
                    width: 200,
                    justifyContent: 'center',
                  }}
                />
                <InactiveLeadsReasonTableHeader />
              </View>
              {loading && (
                <View
                  style={{
                    minHeight: 1500,
                  }}
                >
                  <LoaderComponent loading />
                </View>
              )}
              {!loading &&
                data.map((item) => (
                  <InactiveLeadsReasonTableRowComponent
                    key={item.reason_name}
                    item={item}
                    selectedYear={selectedYear}
                  />
                ))}
            </View>
          </View>
        </View>
      </View>
    </View>
  );
}
