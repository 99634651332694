import { useTranslation } from 'react-i18next';
import { ItemByDepartmentYearsComparativeType } from '../../../../types/itemByDepartmentYearsComparative.type';
import { View, ScrollView, Text } from 'react-native';
import { ExpectedInterventionsYearTableStyles } from './expectedInterventionsYear.styles';
import { InterventionsYearCountRow } from './components/interventionsYearCountRow';
import { useDeviceHook } from '@hooks/device/device.hook';
import { months } from '../../../../constants/months.constant';
import { useEffect, useState } from 'react';

export function ExpectedInterventionsYearTable(properties: {
  selectedYear: number;
  data: ItemByDepartmentYearsComparativeType[];
  onCellPress: (settings: { departmentId: string; month: number }) => void;
}) {
  const [rowsData, setRowsData] = useState<
    {
      department_id: string;
      department_name: string;
      total_leads: number[];
    }[]
  >([]);
  const { t } = useTranslation();
  const { phone } = useDeviceHook();

  useEffect(() => {
    const departments = new Map<string, string>();
    properties.data.forEach((item) => {
      departments.set(item.department_id, item.department_name);
    });
    const formattedData: Array<{
      department_id: string;
      department_name: string;
      total_leads: number[];
    }> = [];
    departments.forEach((department, departmentId) => {
      const departmentData = properties.data
        .filter(
          (item) => item.department_name === department && item.year === properties.selectedYear
        )
        .sort((a, b) => {
          if (a.month === b.month) {
            return a.year - b.year;
          }
          return a.month - b.month;
        });
      formattedData.push({
        department_id: departmentId,
        department_name: department,
        total_leads: departmentData.map((item) => item.total),
      });
    });

    setRowsData(formattedData);
  }, [properties.data]);

  const LeadCountTableColumn = (settings: {
    header: {
      month: string;
    };
    index: number;
  }) => {
    return (
      <View
        style={[
          phone && { width: 75, marginHorizontal: 10 },
          ExpectedInterventionsYearTableStyles.headerColumnContainer,
        ]}
      >
        <Text
          style={
            phone
              ? ExpectedInterventionsYearTableStyles.phoneHeaderText
              : ExpectedInterventionsYearTableStyles.headerText
          }
        >
          {t(`board.months.${settings.header.month}`)}
        </Text>
      </View>
    );
  };

  return (
    <>
      <ScrollView horizontal style={{ flexDirection: 'column', flex: 1 }}>
        <View style={{ flex: 1 }}>
          <View
            style={{
              flexDirection: 'row',
              flex: 1,
              gap: 2,
              marginLeft: 5,
            }}
          >
            <View
              style={[
                ExpectedInterventionsYearTableStyles.headerSpaceHeader,
                phone && { width: 75 },
              ]}
            />
            <View style={ExpectedInterventionsYearTableStyles.headerContainer}>
              {months.map((item, index) => (
                <LeadCountTableColumn key={item.month} header={item} index={index} />
              ))}
            </View>
          </View>

          {rowsData.map((item, index) => (
            <InterventionsYearCountRow
              key={item.department_id}
              item={item}
              index={index}
              totalRows={rowsData.length}
              onCellPress={properties.onCellPress}
            />
          ))}
        </View>
      </ScrollView>
    </>
  );
}
