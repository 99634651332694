import { useEffect, useState } from 'react';
import { ScrollView, Text, View } from 'react-native';
import { NewLeadsByDepartmentYearsComparativeStyles } from './newLeadsByDepartmentYearsComparative.styles';
import { useTranslation } from 'react-i18next';
import { ComparativeYearsLeadCountRow } from './components/comparativeYearsLeadCountRow';
import { ItemByDepartmentYearsComparativeType } from './newLeadByDepartmentYearsComparative.type';
import { useDeviceHook } from '@hooks/device/device.hook';
import { BoardNoDataComponent } from '../../../../components/noData/boardNoData.component';

export function NewLeadsByDepartmentYearsComparative(properties: {
  firstYear: number;
  secondYear: number;
  data: ItemByDepartmentYearsComparativeType[];
  onCellPress: (settings: {
    departmentId: string | undefined;
    year: number;
    month?: number;
  }) => void;
}) {
  const [rowsData, setRowsData] = useState<
    {
      department_id: string;
      department_name: string;
      total_leads: number[];
    }[]
  >([]);
  const { t } = useTranslation();

  const { phone } = useDeviceHook();

  useEffect(() => {
    const departments = new Map<string, string>();
    properties.data.forEach((item) => {
      departments.set(item.department_id, item.department_name);
    });
    const formattedData: Array<{
      department_id: string;
      department_name: string;
      total_leads: number[];
    }> = [];
    departments.forEach((department, departmentId) => {
      const departmentData = properties.data
        .filter((item) => item.department_name === department)
        .sort((a, b) => {
          if (a.month === b.month) {
            return a.year - b.year;
          }
          return a.month - b.month;
        });
      formattedData.push({
        department_id: departmentId,
        department_name: department,
        total_leads: departmentData.map((item) => item.total),
      });
    });

    setRowsData(formattedData);
  }, [properties.data]);

  if (properties.data.length === 0) {
    return <BoardNoDataComponent />;
  }

  const LeadCountTableColumn = (settings: {
    header: {
      month: string;
      subheaders: number[];
    };
    index: number;
  }) => {
    return (
      <View style={[NewLeadsByDepartmentYearsComparativeStyles.headerColumnContainer]}>
        <Text style={NewLeadsByDepartmentYearsComparativeStyles.headerText}>
          {t(`board.months.${settings.header.month}`)}
        </Text>

        <View
          style={
            phone
              ? {
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }
              : {
                  flexDirection: 'row',
                  gap: 10,
                  justifyContent: 'center',
                }
          }
        >
          {settings.header.subheaders.map((item, i) => (
            <Text
              key={item}
              style={[
                phone
                  ? NewLeadsByDepartmentYearsComparativeStyles.phoneHeaderText
                  : NewLeadsByDepartmentYearsComparativeStyles.headerText,
                ,
                (i % 2 === 0 ||
                  (properties.secondYear === new Date().getFullYear() &&
                    settings.index >= new Date().getMonth() + 1)) && { color: '#999' },
              ]}
            >
              {item}
            </Text>
          ))}
        </View>
      </View>
    );
  };

  return (
    <>
      <ScrollView horizontal style={{ flexDirection: 'column', flex: 1 }}>
        <View style={{ flex: 1 }}>
          <View
            style={
              phone
                ? NewLeadsByDepartmentYearsComparativeStyles.headerContainerMobile
                : NewLeadsByDepartmentYearsComparativeStyles.headerContainer
            }
          >
            <View
              style={[
                phone
                  ? { width: 100 }
                  : NewLeadsByDepartmentYearsComparativeStyles.headerSpaceHeader,
              ]}
            />
            {[
              { month: 'jan', subheaders: [properties.firstYear, properties.secondYear] },
              { month: 'feb', subheaders: [properties.firstYear, properties.secondYear] },
              { month: 'mar', subheaders: [properties.firstYear, properties.secondYear] },
              { month: 'apr', subheaders: [properties.firstYear, properties.secondYear] },
              { month: 'may', subheaders: [properties.firstYear, properties.secondYear] },
              { month: 'jun', subheaders: [properties.firstYear, properties.secondYear] },
              { month: 'jul', subheaders: [properties.firstYear, properties.secondYear] },
              { month: 'aug', subheaders: [properties.firstYear, properties.secondYear] },
              { month: 'sep', subheaders: [properties.firstYear, properties.secondYear] },
              { month: 'oct', subheaders: [properties.firstYear, properties.secondYear] },
              { month: 'nov', subheaders: [properties.firstYear, properties.secondYear] },
              { month: 'dec', subheaders: [properties.firstYear, properties.secondYear] },
            ].map((item, index) => (
              <LeadCountTableColumn key={item.month} header={item} index={index} />
            ))}
          </View>
          {rowsData.map((item, index) => (
            <ComparativeYearsLeadCountRow
              firstAttemptRow={undefined}
              isLossRow={undefined}
              firstYear={properties.firstYear}
              lastYear={properties.secondYear}
              last={index === rowsData.length - 1}
              key={item.department_name}
              item={item}
              onCellPress={properties.onCellPress}
            />
          ))}
        </View>
      </ScrollView>
    </>
  );
}
