import { TouchableOpacity } from 'react-native';
import { IconComponent } from '../../../icon/icon.component';
import { useNavigation } from '@react-navigation/native';
import { TextComponent } from '@components/text/text.component';
import { BubbleStyles } from './bubble.styles';
import { ButtonProperties } from './bubble.properties';

/**
 * BubbleComponent renders a bubble button with accompanying text and icon.
 *
 * @param {ButtonProperties} properties - The properties object containing the text, icon, and primary status of the bubble.
 *
 * @returns {JSX.Element} The rendered JSX element containing the bubble, text, and icon.
 *
 * @example
 * <BubbleComponent text="Desktop" icon="desktop" primary />
 *
 */

export function BubbleComponent(properties: ButtonProperties) {
  const navigation = useNavigation<any>();

  return (
    <TouchableOpacity
      onPress={() => {
        properties.onPress();
      }}
      style={[
        BubbleStyles.container,
        properties.primary ? BubbleStyles.containerPrimary : BubbleStyles.containerSecondary,
        properties.primary && properties.text
          ? BubbleStyles.containerPrimaryBorder
          : BubbleStyles.containerSecondaryBorder,
      ]}
    >
      {properties.counter !== undefined && (
        <TextComponent text={properties.counter?.toString()} style={BubbleStyles.counterText} />
      )}
      {properties.icon && <IconComponent name={properties.icon} iconStyle={BubbleStyles.icon} />}
      {properties.text && (
        <TextComponent
          strong
          text={properties.text}
          style={[
            BubbleStyles.text,
            properties.primary ? BubbleStyles.textPrimary : BubbleStyles.textSecondary,
          ]}
        />
      )}
      {properties.text && properties.primary && (
        <IconComponent
          name="check"
          containerstyle={BubbleStyles.iconCheckContainer}
          iconStyle={BubbleStyles.iconCheckStyle}
        />
      )}
    </TouchableOpacity>
  );
}
