import { MainTheme } from '@theme/main/main.theme';
import { StyleSheet } from 'react-native';

export const TagStyles = StyleSheet.create({
  container: {
    borderRadius: 10,
    backgroundColor: 'rgba(155, 155, 155, 0.2)',
    borderColor: 'rgba(0, 0, 0, 0.1)',
    borderWidth: 0.5,
  },
  tagText: {
    paddingHorizontal: 10,
    paddingVertical: 5,
    color: 'rgba(10, 10, 10, 0.5)',
    fontSize: 11,
  },
  selected: {
    color: '#fff',
    textAlign: 'center',
    lineHeight: 20,
    backgroundColor: MainTheme.primaryColor,
    borderRadius: 100,
  },
  selectedText: {
    color: '#fff',
  },
});
