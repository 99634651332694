import { FormComponent } from '@components/form/form.component';
import { ClinicAccessRepository } from '@human/access/repositories/clinic/clinic.repository';
import { OvoBankContactCrmRepository } from '@human/crm/repositories/ovobankContact/ovobankContact.repository';
import { CampaignsRoutes } from '@human/crm/sections/campaings/campaigns.routes';
import { NavigationProp } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';

export function CreateOvoBankContactForm(properties: {
  navigation: NavigationProp<CampaignsRoutes>;
  campaignId: string;
  settings:
    | {
        setIsEditing: (isEditing: boolean) => void;
      }
    | undefined;
}) {
  const { t } = useTranslation();

  return (
    <FormComponent<{
      name: string;
      surname: string;
      email: string;
      prefix: string;
      phone: string;
      zip: string;
      clinicId: string;
      legal: boolean;
      marketing: boolean;
      birthDate?: Date;
      birthCountry?: string;
      weight?: string;
      height?: string;
      donationPreference?: string;
      photo?: File;
    }>
      onChange={(data) => {
        if (!data.isLocal) return;
        properties.settings?.setIsEditing(true);
      }}
      prefix="crm.campaigns.update.contact"
      repository={{
        send: (data) => {
          return new OvoBankContactCrmRepository()
            .createFromCampaign({
              item: {
                ...data.item,
                campaignId: properties.campaignId,
              },
            })
            .then(() => {
              properties.navigation.goBack();
            });
        },
        get: async () => ({
          marketing: false,
          legal: false,
        }),
      }}
      fields={[
        {
          type: 'avatar',
          name: 'photo',
          description: true,
        },
        {
          type: 'pair',
          fields: [
            {
              type: 'text',
              name: 'name',
              description: true,
              validations: ['name'],
              required: true,
            },
            {
              type: 'text',
              name: 'surname',
              description: true,
              validations: ['surname'],
              required: true,
            },
          ],
        },
        {
          type: 'pair',
          fields: [
            {
              type: 'text',
              name: 'prefix',
              description: true,
              validations: ['prefix'],
              required: true,
            },
            {
              type: 'text',
              name: 'phone',
              description: true,
              validations: ['phone'],
              required: true,
            },
          ],
        },
        {
          type: 'text',
          name: 'email',
          description: true,
          validations: ['email'],
          required: true,
        },
        {
          type: 'date',
          name: 'birthDate',
          description: true,
          required: true,
          format: 'YYYY-MM-DD',
        },
        {
          type: 'text',
          name: 'birthCountry',
          description: true,
          required: true,
          validations: ['name'],
        },
        {
          type: 'number',
          name: 'height',
          description: true,
          required: true,
          validations: ['positive'],
        },
        {
          type: 'number',
          name: 'weight',
          description: true,
          required: true,
          validations: ['positive'],
        },

        {
          type: 'text',
          name: 'zip',
          description: true,
          validations: ['zip'],
        },
        {
          type: 'text',
          name: 'donationPreference',
          description: true,
          required: true,
          validations: ['name'],
        },
        {
          type: 'checkbox',
          name: 'legal',
          required: true,
          description: true,
        },
        {
          type: 'checkbox',
          name: 'marketing',
          description: true,
        },
        {
          type: 'select',
          description: true,
          name: 'clinicId',
          required: true,
          repository: ({ search }) => {
            return new ClinicAccessRepository().listForCombo({ search, active: true });
          },
        },
      ]}
    />
  );
}
