import { AuthorizedRepository } from '@core/repository/authorized.repository.core';
import { LeadImportAttemptCrmType } from '@human/crm/types/leadImportAttempt.crm.type';

export class LeadImportAttemptCrmRepository extends AuthorizedRepository {
  async list(properties: {
    search?: string;
    page: number;
    limit: number;
  }): Promise<{ items: LeadImportAttemptCrmType[]; total: number }> {
    return this.fetch('leadImportAttempt/find', {
      method: 'GET',
      params: properties,
    }).then((data) => {
      return {
        items: data.items.map((item: any) => ({
          id: item.id,
          created: new Date(item.created),
          fileName: item.fileName,
          errors: item.errors,
          errorContent: item.errorContent,
        })),
        total: data.total,
      };
    });
  }

  async pick(properties: { id: string }): Promise<LeadImportAttemptCrmType> {
    return this.fetch('leadImportAttempt/pick', {
      method: 'GET',
      params: properties,
    }).then((data) => {
      return data;
    });
  }

  async delete(properties: { id: string }): Promise<void> {
    return this.fetch('leadImportAttempt/delete', {
      method: 'DELETE',
      params: properties,
    });
  }
}
