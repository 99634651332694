import { View, Text } from 'react-native';
import { Pie } from 'react-chartjs-2';
import { PieComparationChartProperties } from './pieComparationChart.properties';
import { useDeviceHook } from '@hooks/device/device.hook';

export function PieComparationChart(properties: PieComparationChartProperties) {
  const { desktop } = useDeviceHook();
  const contactedEmpty = !properties.optionAData.some((item) => item.lead_count > 0);
  const informedEmpty = !properties.optionBData.some((item) => item.lead_count > 0);

  const data1 = {
    labels: contactedEmpty ? [] : properties.optionAData.map((item) => item.department_name),
    datasets: [
      {
        label: contactedEmpty ? undefined : `Leads ${properties.optionASelected}`,
        data: contactedEmpty ? [100] : properties.optionAData.map((item) => item.lead_count),
        borderColor: contactedEmpty ? ['rgba(211, 211, 211, 0.5)'] : properties.colors,
        backgroundColor: contactedEmpty ? ['rgba(245, 245, 245, 0.9)'] : properties.colors,
        borderWidth: 1,
      },
    ],
  };

  const data2 = {
    labels: informedEmpty ? [] : properties.optionBData.map((item) => item.department_name),
    datasets: [
      {
        label: informedEmpty ? undefined : `Leads ${properties.optionBSelected}`,
        data: informedEmpty ? [100] : properties.optionBData.map((item) => item.lead_count),
        backgroundColor: informedEmpty ? ['rgba(245, 245, 245, 0.9)'] : properties.colors,
        borderColor: informedEmpty ? ['rgba(211, 211, 211, 0.5)'] : properties.colors,
        borderWidth: 1,
      },
    ],
  };

  return (
    <View
      style={[
        desktop && { flexDirection: 'row', gap: 20 },
        !desktop && { flexDirection: 'column', gap: 20 },
      ]}
    >
      <View style={[{ gap: 10 }, desktop && { flex: 1 }]}>
        <Text>{`Leads ${properties.optionASelected}`}</Text>
        <Pie
          data={data1}
          options={{
            responsive: true,
            aspectRatio: 1.5,
            plugins: {
              tooltip: {
                enabled: !contactedEmpty,
              },
            },
          }}
        />
      </View>

      <View style={[{ gap: 10 }, desktop && { flex: 1 }]}>
        <Text>{`Leads ${properties.optionBSelected}`}</Text>
        <Pie
          data={data2}
          options={{
            responsive: true,
            aspectRatio: 1.5,
            plugins: {
              tooltip: {
                enabled: !informedEmpty,
              },
            },
          }}
        />
      </View>
    </View>
  );
}
