import { createStackNavigator } from '@react-navigation/stack';
import { DesktopSpace } from './spaces/desktop/desktop.space';
import { AuthorizedSpaces } from './authorized.spaces';
import { AccessSpace } from './spaces/access/access.space';
import { CrmSpace } from './spaces/crm/crm.space';
import { RouteProp } from '@react-navigation/native';
import { AuthorizedBrands } from '../authorized.brands';
import { ProfileSpace } from './spaces/profile/profile.space';
import { BoardSpace } from './spaces/board/board.space';
import { useEffect } from 'react';
import { useNotificationState } from '@states/notification/notification.state';
import { NotificationCommonRepository } from './common/notification/repositories/notification.repository';
import { useNotificationChecker } from '@hooks/notification/useNotificationChecker';

const Drawer = createStackNavigator<AuthorizedSpaces>();
export function HumanNavigator(properties: { route: RouteProp<AuthorizedBrands, 'human'> }) {
  const { setCount } = useNotificationState();
  let interval: ReturnType<typeof setInterval> | undefined = undefined;
  useNotificationChecker();

  useEffect(() => {
    new NotificationCommonRepository().count().then((count) => {
      setCount(count);
    });
    if (!interval) {
      interval = setInterval(() => {
        new NotificationCommonRepository().count().then((count) => {
          if (count > useNotificationState.getState().count) {
            setCount(count);
          }
        });
      }, 60000);
    }
  }, []);

  return (
    <Drawer.Navigator
      screenOptions={{
        headerShown: false,
      }}
    >
      <Drawer.Screen name="desktop" component={DesktopSpace} />
      <Drawer.Screen
        name="crm"
        component={CrmSpace}
        initialParams={{
          groupId: properties.route.params.groupId,
        }}
      />
      <Drawer.Screen
        name="access"
        component={AccessSpace}
        initialParams={{
          groupId: properties.route.params.groupId,
        }}
      />
      <Drawer.Screen
        name="profile"
        component={ProfileSpace}
        initialParams={{ groupId: properties.route.params.groupId }}
      />
      <Drawer.Screen
        name="board"
        component={BoardSpace}
        initialParams={{
          groupId: properties.route.params.groupId,
        }}
      />
    </Drawer.Navigator>
  );
}
