import { ButtonComponent } from '@components/button/button.component';
import { TableComponent } from '@components/table/table.component';
import { ManagerRepository } from '@human/crm/repositories/manager/manager.repository';
import { ManagerCrmType } from '@human/crm/types/manager.crm.type';
import { CampaignsRoutes } from '../../../campaigns.routes';
import { NavigationProp, RouteProp } from '@react-navigation/native';
import { hasPermissionUtilty } from '@utils/permission/has.permission.utility';

export function ManagerCampaingUpdatePart(properties: {
  navigation: NavigationProp<CampaignsRoutes>;
  route: RouteProp<CampaignsRoutes, 'campaignUpdate'>;
}) {
  return (
    <>
      <TableComponent<ManagerCrmType, []>
        key={'managers'}
        prefix={'crm.campaigns.update.managers.list'}
        suffix={'list'}
        hideNoDataImage
        repository={() => new ManagerRepository().list({ campaignId: properties.route.params.id })}
        columns={[
          {
            type: 'icon',
            icon: 'trash-alt',
            onPress: (row) => {
              properties.navigation.navigate('deleteManager', {
                campaignId: properties.route.params.id,
                userId: row.userId,
                level: 1,
              });
            },
          },
          {
            type: 'text',
            name: 'name',
            width: 200,
            onRender: (row) => `${row.userName} ${row.userSurname}`,
          },
        ]}
      />
      {hasPermissionUtilty({ name: 'crm.campaigns.editor' }) && (
        <ButtonComponent
          icon="plus"
          prefix="crm.campaigns.update.managers.add"
          onPress={() => {
            properties.navigation.navigate('createManager', {
              campaignId: properties.route.params.id,
              languageId: properties.route.params.languageId,
              level: 1,
            });
          }}
        />
      )}
    </>
  );
}
