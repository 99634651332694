import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ActivitiesRoutes } from '../../../activity.routes';
import { TabType } from '@components/tabs/tabs.types';
import { ScrollView } from 'react-native';
import { TableComponent } from '@components/table/table.component';
import { ButtonComponent } from '@components/button/button.component';
import { ActivityCommunicationCrmType } from '@human/crm/types/activityCommunicationChannel.type';
import { ActivityCommunicationChannelCrmRepository } from '@human/crm/repositories/activityCommunicationChannel/activityCommunicationChannel.repository';

export function UpdateActivityCommunicationChannelPart(properties: {
  navigation: NavigationProp<ActivitiesRoutes>;
  route: RouteProp<ActivitiesRoutes, 'update'>;
}): TabType {
  return {
    name: 'communicationChannels',
    icon: 'envelope',
    content: () => (
      <ScrollView>
        <TableComponent<ActivityCommunicationCrmType, []>
          prefix={'crm.activities.update.communicationChannels.list'}
          repository={(settings) =>
            new ActivityCommunicationChannelCrmRepository().list({
              page: settings.page,
              limit: settings.limit,
              activityId: properties.route.params.activityId,
            })
          }
          hideNoDataImage
          suffix={'list'}
          columns={[
            {
              type: 'icon',
              renderIcon: () => 'trash-alt',
              onPress: (row) => {
                properties.navigation.navigate('deleteCommunicationChannel', {
                  activityId: properties.route.params.activityId,
                  communicationChannelId: row.channelId,
                  level: 1,
                });
              },
            },
            {
              type: 'text',
              name: 'channelName',
              onRender: (row) => row.channelName,
              width: 200,
            },
          ]}
        />
        <ButtonComponent
          icon="plus"
          prefix="crm.activities.update.communicationChannels.add"
          onPress={() =>
            properties.navigation.navigate('createCommunicationChannel', {
              activityId: properties.route.params.activityId,
              level: 1,
            })
          }
        />
      </ScrollView>
    ),
  };
}
