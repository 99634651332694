import { useTranslation } from 'react-i18next';
import { View, Text, useWindowDimensions } from 'react-native';
import { TotalLeadsByFunnelTableStyles } from './totalLeadsByFunnel.styles';
import { LeadsByFunnelYearCountRow } from './components/leadsByFunnelYearCountRow';
import { months } from '../../../../constants/months.constant';
import { useDeviceHook } from '@hooks/device/device.hook';
import { useEffect, useState } from 'react';

export function TotalLeadsByFunnelTable(properties: {
  data: { department_id: string; department_name: string; month: number; total: number }[];
  year: number;
  onCellPress: (settings: { departmentId: string | undefined; month: number }) => void;
}) {
  const { t } = useTranslation();
  const { phone } = useDeviceHook();
  const { height } = useWindowDimensions();

  const [rowsData, setRowsData] = useState<
    {
      department_id: string;
      department_name: string;
      total_leads: number[];
    }[]
  >([]);

  useEffect(() => {
    const departments = new Map<string, string>();
    properties.data.forEach((item) => {
      departments.set(item.department_id, item.department_name);
    });
    const formattedData: Array<{
      department_id: string;
      department_name: string;
      total_leads: number[];
    }> = [];
    departments.forEach((department, departmentId) => {
      const departmentData = properties.data
        .filter((item) => item.department_name === department)
        .sort((a, b) => {
          return a.month - b.month;
        });
      formattedData.push({
        department_id: departmentId,
        department_name: department,
        total_leads: departmentData.map((item) => item.total),
      });
    });

    setRowsData(formattedData);
  }, [properties.data]);

  const LeadCountTableColumn = (settings: {
    header: {
      month: string;
    };
    index: number;
  }) => {
    return (
      <View
        style={
          phone
            ? { width: 100, marginHorizontal: 10 }
            : TotalLeadsByFunnelTableStyles.headerColumnContainer
        }
      >
        <Text
          style={
            phone
              ? TotalLeadsByFunnelTableStyles.phoneHeaderText
              : TotalLeadsByFunnelTableStyles.headerText
          }
        >
          {t(`board.months.${settings.header.month}`)}
        </Text>
      </View>
    );
  };

  return (
    <View style={{ flexDirection: 'column', flex: 1, minHeight: height * 0.25 }}>
      <View style={{ flex: 1 }}>
        <View
          style={{
            flexDirection: 'row',
          }}
        >
          <View style={phone ? { width: 100 } : TotalLeadsByFunnelTableStyles.headerSpaceHeader} />
          <View style={TotalLeadsByFunnelTableStyles.headerContainer}>
            {months.map((item, index) => (
              <LeadCountTableColumn key={item.month} header={item} index={index} />
            ))}
          </View>
        </View>

        {rowsData.map((item, index) => (
          <LeadsByFunnelYearCountRow
            key={item.department_name}
            item={item}
            last={index === rowsData.length - 1}
            year={properties.year}
            onCellPress={properties.onCellPress}
          />
        ))}
      </View>
    </View>
  );
}
