import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ModalComponent } from '@components/modal/modal.component';
import { useState } from 'react';

import { ActionComponent } from '@components/action/action.component';
import { SelectProgramStep } from '@steps/program/selectProgram.step';
import { ActionsRoutes } from '../../../actions.routes';
import { ProgramPhaseCrmRepository } from '@human/crm/repositories/programPhase/programPhase.repository';
import { FormComponent } from '@components/form/form.component';
import { TreatmentCrmRepository } from '@human/crm/repositories/treatment/treatment.repository';
import { TreatmentCrmType } from '@human/crm/types/treatment.crm.type';

export function UpdateActionCreateTreatmentScreen(properties: {
  navigation: NavigationProp<ActionsRoutes>;
  route: RouteProp<ActionsRoutes, 'updateActionCreateTreatment'>;
}) {
  const [programId, setProgramId] = useState<string>();
  const [programName, setProgramName] = useState<string>();

  const [phaseId, setPhaseId] = useState<string>();
  const [phaseName, setPhaseName] = useState<string>();

  return (
    <ModalComponent
      level={properties.route.params.level}
      icon="tasks"
      prefix="crm.treatments.create"
      flex={false}
    >
      {!programId ? (
        <SelectProgramStep
          programId={programId}
          onSuccess={({ programId, programName }) => {
            setProgramId(programId);
            setProgramName(programName);
          }}
        />
      ) : (
        <ActionComponent
          onPress={() => {
            setProgramId(undefined);
            setPhaseId(undefined);
          }}
          prefix="crm.patients.create.treatment.create.steps.program.form"
          bubble={programName}
        />
      )}
      {programId && (
        <FormComponent<TreatmentCrmType>
          prefix="crm.treatments.create.form"
          padding
          repository={{
            send: (settings) => {
              return new TreatmentCrmRepository()
                .create({
                  item: settings.item,
                  clinicId: properties.route.params.clinicId,
                  patientId: properties.route.params.patientId,
                  programId: programId,
                })
                .then(() => {
                  properties.navigation.goBack();
                  if (
                    properties.route.params.actionId &&
                    properties.route.params.leadId &&
                    properties.route.params.activityId &&
                    properties.route.params.started
                  ) {
                    properties.navigation.navigate('actionNextResultUpdate', {
                      actionId: properties.route.params.actionId,
                      clinicId: properties.route.params.clinicId,
                      leadId: properties.route.params.leadId,
                      level: properties.route.params.level + 1,
                      activityId: properties.route.params.activityId,
                      started: properties.route.params.started,
                      patientId: properties.route.params.patientId,
                    });
                  }
                });
            },
          }}
          fields={[
            {
              type: 'date',
              name: 'expectedDate',
              format: 'YYYY-MM-DD HH:mm:ss',
              description: true,
              required: true,
            },
            {
              type: 'select',
              name: 'phaseId',
              description: true,
              repository: (props: { search?: string }) => {
                return new ProgramPhaseCrmRepository()
                  .list({
                    search: props.search,
                    programId: programId,
                  })
                  .then((phases) => {
                    return phases.items.map((phase) => ({
                      label: phase.phaseName,
                      value: phase.phaseId,
                    }));
                  });
              },
            },
          ]}
        />
      )}
    </ModalComponent>
  );
}
