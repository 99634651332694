// Modules
import { StyleSheet } from 'react-native';

/**
 * UserColumn Styles
 * @constant {StyleSheet} UserColumnStyles
 */
export const UserColumnStyles = StyleSheet.create({
  container: {
    height: 42,
    paddingHorizontal: 7,
    alignContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'rgba(29, 26, 41, 0.1)',
  },
  user: {
    color: '#333',
  },
  image: {
    height: 25,
    width: 25,
    backgroundColor: 'rgb(29, 26, 41)',
    borderRadius: 25,
  },
  iconContainer: {
    height: 25,
    width: 25,
    backgroundColor: 'rgb(255, 255, 255)',
    borderRadius: 25,
  },
  icon: {
    color: 'rgba(237, 53, 145, 0.5)',
  },
});
