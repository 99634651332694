// Modules
import { StyleSheet } from 'react-native';

/**
 * TextColumn Styles
 * @constant {StyleSheet} TextColumnStyles
 */
export const MultipleBadgeColumnStyles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flex: 1,
    paddingHorizontal: 7,
    gap: 10,
  },
  bubblesContainer: {
    flexDirection: 'row',
    overflow: 'hidden',
    gap: 10,
  },
  bubble: {
    backgroundColor: 'rgba(237, 53, 145, 0.1)',
    borderRadius: 3,
    paddingRight: 12,
    paddingVertical: 8,
    flexDirection: 'row',
    overflow: 'hidden',
  },
  icon: {
    padding: 10,
    borderRightColor: 'rgba(0, 0, 0, 0.2)',
    borderRightWidth: 1,
    fontSize: 7,
    color: 'rgb(237, 53, 145)',
    backgroundColor: 'rgb(227, 227, 227)',
  },
  iconShape: {
    color: 'rgb(255, 255, 255)',
    fontSize: 15,
  },
  text: {
    color: 'rgb(237, 53, 145)',
    fontSize: 12,
    paddingLeft: 10,
    textTransform: 'uppercase',
  },
  itemsLeftContainer: {
    justifyContent: 'center',
    backgroundColor: 'rgba(237, 53, 145, 0.1)',
    padding: 4,
    borderRadius: 100,
  },
  itemsLeftText: {
    color: 'rgb(237, 53, 145)',
    fontSize: 12,
    paddingHorizontal: 5,
    textTransform: 'uppercase',
  },
});
