import { FormComponent } from '@components/form/form.component';
import { ModalComponent } from '@components/modal/modal.component';
import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ResultsRoutes } from '../../result.routes';
import { ResultCrmType } from '@human/crm/types/result.crm.type';
import { ResultCrmRepository } from '@human/crm/repositories/result/result.repository';
import { TabsComponent } from '@components/tabs/tabs.component';
import { useSuccessToast } from '@hooks/successToast/successToast.hook';
import { UpdateResultNextActivity } from './nextActivity/update.result.nextActivity';
import { UpdateResultCCAchievement } from './ccAchievement/update.result.ccAchievement';
import { UpdateResultATPCAchievement } from './atpcAchievement/update.result.atpcAchievement';
import { hasPermissionUtilty } from '../../../../../../../../../utils/permission/has.permission.utility';
import { TabType } from '@components/tabs/tabs.types';
import { UpdateResultReactivity } from './reactivity/update.result.reactivity';
import { UpdateResultATPIAchievement } from './atpiAchievement/update.result.atpiAchievement';
import { UpdateResultTemplate } from './template/update.result.template';
import { UpdateResultIntegrationAchievement } from './integrationAchievement/update.result.integrationAchievement';
import { isOvoClinicUtility } from '@utils/workspace/isOvoclinic.utility';
import { isOvoBankUtility } from '@utils/workspace/isOvobank.utility';
import { UpdateResultOvoBankAtpAchievement } from './ovoBankAtpAchievement/update.result.ovoBankAtpAchievement';

export function UpdateResultScreen(properties: {
  navigation: NavigationProp<ResultsRoutes>;

  route: RouteProp<ResultsRoutes, 'resultUpdate'>;
}) {
  const { showSuccess } = useSuccessToast();

  const updateResultTabs: TabType[] = [
    {
      name: 'result',
      icon: 'resolving',
      content: (settings) => (
        <FormComponent<ResultCrmType>
          prefix="crm.results.update.form"
          readonly={!hasPermissionUtilty({ name: 'crm.results.editor' })}
          repository={{
            get: () => {
              return new ResultCrmRepository().pick({
                resultId: properties.route.params.resultId,
                activityId: properties.route.params.activityId,
              });
            },
            send: (data) => {
              return new ResultCrmRepository()
                .update({
                  id: properties.route.params.resultId,
                  item: data.item,
                })
                .then(() => {
                  settings?.setIsEditing(false);
                  showSuccess();
                });
            },
          }}
          fields={[
            {
              name: 'name',
              type: 'text',
              description: true,
              required: true,
              validations: ['name'],
            },
            {
              name: 'icon',
              type: 'icon',
              description: true,
              required: true,
            },
            {
              name: 'position',
              type: 'number',
              description: true,
              required: true,
              validations: ['positive'],
            },
            {
              name: 'description',
              type: 'text',
              description: true,
              required: false,
              validations: ['description'],
              lines: 6,
            },
            {
              name: 'color',
              type: 'color',
            },
          ]}
        />
      ),
    },
    UpdateResultNextActivity(properties),
    UpdateResultTemplate(properties),
  ];

  if (hasPermissionUtilty({ name: 'crm.results.reactivity' })) {
    updateResultTabs.push(UpdateResultReactivity(properties));
  }

  if (isOvoClinicUtility()) {
    if (hasPermissionUtilty({ name: 'crm.results.ccEvent' })) {
      updateResultTabs.push(UpdateResultCCAchievement(properties));
    }

    if (hasPermissionUtilty({ name: 'crm.results.atpcEvent' })) {
      updateResultTabs.push(UpdateResultATPCAchievement(properties));
    }

    if (hasPermissionUtilty({ name: 'crm.results.atpiEvent' })) {
      updateResultTabs.push(UpdateResultATPIAchievement(properties));
    }
  } else if (isOvoBankUtility()) {
    updateResultTabs.push(UpdateResultOvoBankAtpAchievement(properties));
  }

  updateResultTabs.push(UpdateResultIntegrationAchievement(properties));

  return (
    <ModalComponent
      level={properties.route.params.level}
      icon="resolving"
      flex={false}
      prefix="crm.results.update"
    >
      <TabsComponent prefix="crm.results.update" tabs={updateResultTabs} layout="horizontal" />
    </ModalComponent>
  );
}
