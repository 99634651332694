import { RouteProp } from '@react-navigation/native';
import { AccessRoutes } from '@human/access/access.routes';
import { NavigationComponent } from '@components/navigation/navigation.component';
import { EntryTracksRoutes } from './entryTracks.routes';
import { ListEntryTracksScreen } from './screens/list/list.entryTracks.screen';
import { UpdateEntryTrackScreen } from './screens/update/update.entryTrack.screen';

const Navigation = NavigationComponent<EntryTracksRoutes>();

export function EntryTracksSection(properties: { route: RouteProp<AccessRoutes, 'entryTracks'> }) {
  return (
    <Navigation.Navigator>
      <Navigation.Screen name="list" component={ListEntryTracksScreen} />
      <Navigation.Screen name="update" component={UpdateEntryTrackScreen} />
    </Navigation.Navigator>
  );
}
