export function contactTimeParserUtility(
  contactTime: string | undefined
): 'MORNING' | 'AFTERNOON' | 'NO_PREFERENCE' {
  switch (contactTime?.toUpperCase()) {
    case 'MORNING':
    case 'MAÑANA':
    case 'MAÑANAS':
      return 'MORNING';
    case 'AFTERNOON':
    case 'TARDE':
    case 'TARDES':
      return 'AFTERNOON';
    case 'NO_PREFERENCE':
    case 'SIN PREFERENCIA':
    case 'SIN PREFERENCIAS':
      return 'NO_PREFERENCE';
    default:
      return 'NO_PREFERENCE';
  }
}
