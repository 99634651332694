import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ReasonsRoutes } from '../../reason.routes';
import { CrmSections } from '@human/crm/crm.sections';
import { ModalComponent } from '@components/modal/modal.component';
import { FormComponent } from '@components/form/form.component';
import { ReasonCrmType } from '@human/crm/types/reason.crm.type';
import { ReasonCrmRepository } from '@human/crm/repositories/reason/reason.repository';
import { TabsComponent } from '@components/tabs/tabs.component';
import { UpdateReasonNotificationPart } from './notification/update.reason.notification.part';
import { useEffect, useState } from 'react';
import { UpdateReasonNextActivityPart } from './nextActivity/update.reason.nextActivity.part';
import { useSuccessToast } from '@hooks/successToast/successToast.hook';
import { hasPermissionUtilty } from '@utils/permission/has.permission.utility';

export function UpdateReasonScreen(properties: {
  navigation: NavigationProp<ReasonsRoutes>;
  route: RouteProp<ReasonsRoutes, 'update'>;
}) {
  const [reason, setReason] = useState<Partial<ReasonCrmType>>();
  const { showSuccess } = useSuccessToast();

  useEffect(() => {
    new ReasonCrmRepository()
      .pick({
        id: properties.route.params.id,
      })
      .then((response) => {
        setReason(response);
      });
  }, [properties.route.params.id]);

  return (
    <ModalComponent icon={CrmSections.reasons.icon} prefix="crm.reasons.update">
      {reason && (
        <TabsComponent
          layout="horizontal"
          prefix="crm.reasons.update"
          tabs={[
            {
              name: 'reason',
              icon: CrmSections.reasons.icon,
              content: (settings) => (
                <FormComponent<ReasonCrmType>
                  prefix="crm.reasons.update.reason.form"
                  onChange={(data) => {
                    if (!data.isLocal) return;
                    settings?.setIsEditing(true);
                  }}
                  readonly={!hasPermissionUtilty({ name: 'crm.reasons.editor' })}
                  repository={{
                    send: (data) => {
                      return new ReasonCrmRepository()
                        .update({
                          item: data.item,
                        })
                        .then(() => {
                          settings?.setIsEditing(false);
                          showSuccess();
                        });
                    },
                    get: async () => reason,
                  }}
                  fields={[
                    {
                      type: 'text',
                      name: 'name',
                      required: true,
                      validations: ['title'],
                      description: true,
                    },
                    {
                      type: 'text',
                      name: 'description',
                      required: true,
                      validations: ['description'],
                      description: true,
                    },
                    {
                      type: 'icon',
                      name: 'icon',
                      required: true,
                      description: true,
                    },
                    {
                      type: 'color',
                      name: 'color',
                      required: true,
                      description: true,
                    },
                    {
                      type: 'checkbox',
                      name: 'canceled',
                      description: true,
                    },
                  ]}
                />
              ),
            },
            UpdateReasonNotificationPart({
              notify: reason.notify ?? false,
              setNotify: (value) => {
                setReason({
                  ...reason,
                  notify: value,
                });
              },
              subject: reason.subject ?? '',
              setSubject: (value) => {
                setReason({
                  ...reason,
                  subject: value,
                });
              },
              message: reason.message ?? '',
              setMessage: (value) => {
                setReason({
                  ...reason,
                  message: value,
                });
              },
              onSubmit: () => {
                return new ReasonCrmRepository()
                  .updateNotification({
                    id: properties.route.params.id,
                    notify: reason.notify ?? false,
                    subject: reason.subject,
                    message: reason.message,
                  })
                  .then(() => {
                    showSuccess();
                  });
              },
            }),
            UpdateReasonNextActivityPart({
              navigation: properties.navigation,
              route: properties.route,
              showSuccess,
            }),
          ]}
        />
      )}
    </ModalComponent>
  );
}
