// Modules
import { StyleSheet } from 'react-native';

/**
 * Clock Styles
 * @constant {StyleSheet} FilterCheckBoxInput
 */
export const FilterCheckBoxInputStyle = StyleSheet.create({
  container: {
    overflow: 'hidden',
    borderRadius: 5,
    alignItems: 'center',
    flexDirection: 'row',
    height: 40,
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    maxHeight: 40,
    borderWidth: 1,
    borderColor: '#c0c0c0',
  },
  iconContainer: {
    height: 27,
    backgroundColor: 'rgb(255, 222, 238)',
    borderRadius: 50,
    width: 27,
    position: 'absolute',
    left: 9,
    top: 8,
    zIndex: 1,
  },
  iconStyle: {
    color: 'rgba(237, 53, 145, 1)',
    fontSize: 16,
    textAlign: 'center',
    lineHeight: 27,
  },
  label: {
    fontSize: 14,
    color: '#111B22',
  },
  labelIcon: {
    marginLeft: 40,
  },
  labelNoIcon: {
    marginLeft: 10,
    marginRight: 5,
  },
  checkBoxContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 5,
    flex: 1,
  },
  checkBoxIconContainer: {
    height: 40,
    width: 40,
  },
  checkBoxIconStyle: {
    fontSize: 24,
    textAlign: 'center',
    lineHeight: 40,
  },
  checkBoxIconInactive: {
    color: 'rgba(0, 0, 0, 0.42)',
  },
  checkBoxCheckContainer: {
    height: 30,
    width: 60,
    marginLeft: 'auto',
    borderRadius: 3,
  },
  checkBoxCheckIconContainer: {
    margin: 5,
    borderRadius: 3,
    height: 20,
    width: 20,
  },
  checkBoxCheckIconActive: {
    backgroundColor: '#111b22',
    marginLeft: 35,
  },
  checkBoxCheckInactive: {
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
  },
  checkBoxCheckIconInactive: {
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    color: 'red',
    marginLeft: 5,
  },
  checkIconContainer: {
    height: 20,
    width: 20,
  },
  checkIconStyle: {
    color: 'white',
    fontSize: 14,
    textAlign: 'center',
    lineHeight: 20,
  },
});
