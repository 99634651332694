// Modules
import { StyleSheet } from 'react-native';

/**
 * Menu Styles
 * @constant {StyleSheet} MenuStyles
 */
export const MenuStyles = StyleSheet.create({
  container: {
    flex: 1,
    width: 400,
    borderRightWidth: 1,
    borderRightColor: '#ccc',
    flexDirection: 'row',
    backgroundColor: 'rgb(255, 255, 255)',
    overflow: 'hidden',
  },
  mobileContainer: {
    width: 350,
    borderRightWidth: 0,
  },
  absoluteContainer: {
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    flexDirection: 'row',
    right: 0,
  },
  mainContent: {
    flex: 1,
    overflow: 'hidden',
  },
  headerBackground: {
    backgroundColor: 'hwb(330.3 40.78% 19.61% / 0.46)',
  },
  header: {
    padding: 20,
    flexDirection: 'row',
    paddingBottom: 0,
  },
  headerOvoText: {
    fontSize: 25,
    color: '#000',
    opacity: 0.6,
  },
  headerTypeText: {
    fontSize: 25,
    color: 'rgb(237, 53, 145)',
  },
  headerSubtitle: {
    gap: 5,
    marginLeft: 20,
    marginBottom: 20,
    opacity: 0.5,
    flexDirection: 'row',
  },
  headerByText: {
    color: '#000',
    fontSize: 13,
    opacity: 1,
  },
  headerLogotypeText: {
    color: '#000',
    fontSize: 13,
    opacity: 0.8,
  },
  searchIcon: {
    color: '#000',
    fontSize: 20,
    position: 'absolute',
    top: 0,
    left: 0,
    height: 50,
    width: 50,
    lineHeight: 50,
    opacity: 0.5,
    textAlign: 'center',
    zIndex: 1,
  },
  searchInput: {
    height: 50,
    padding: 10,
    paddingLeft: 50,
    fontSize: 17,
    borderWidth: 0,
    borderTopWidth: 1,
    borderColor: 'rgba(255, 255, 255, 0.21)',
    borderBottomWidth: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    borderRadius: 0,
  },
  scrollView: {
    flex: 1,
  },
  menuTitle: {
    fontSize: 14,
    padding: 15,
    paddingTop: 10,
    color: '#666',
    paddingBottom: 10,
  },
  menuItem: {
    borderBottomWidth: 1,
    flexDirection: 'row',
    padding: 16,
    paddingVertical: 5,
    gap: 10,
    alignItems: 'center',
    borderBottomColor: 'rgba(0, 0, 0, 0.1)',
  },
  menuItemActive: {
    backgroundColor: 'rgb(237, 53, 145)',
  },
  menuItemInactive: {
    backgroundColor: '#00000013',
  },
  menuItemIcon: {
    fontSize: 24,
  },
  menuItemIconActive: {
    color: '#fff',
    opacity: 1,
  },
  menuItemIconInactive: {
    color: '#111',
    opacity: 0.3,
  },
  menuItemText: {
    fontSize: 16,
  },
  menuItemTextActive: {
    color: '#fff',
  },
  menuItemTextInactive: {
    color: '#111',
  },
  notificationBadge: {
    height: 30,
    width: 30,
    borderRadius: 100,
    textAlign: 'center',
    lineHeight: 20,
    fontSize: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 'auto',
  },
  notificationBadgeActive: {
    backgroundColor: 'rgba(255, 255, 255, 0.25)',
    color: '#fff',
  },
  notificationBadgeInactive: {
    backgroundColor: '#0000000A',
    color: '#111',
  },
  newsContainer: {
    marginTop: 'auto',
  },
});
