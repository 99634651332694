import { ButtonComponent } from '@components/button/button.component';
import { TableComponent } from '@components/table/table.component';
import { MemberAccessRepository } from '@human/access/repositories/member/member.repository';
import { NavigationProp, RouteProp } from '@react-navigation/native';
import { DepartmentsRoutes } from '../../../departments.routes';
import { ScrollView } from 'react-native';

export function ListDepartmentMemberPart(properties: {
  navigation: NavigationProp<DepartmentsRoutes>;
  route: RouteProp<DepartmentsRoutes, 'departmentUpdate'>;
}) {
  return (
    <ScrollView>
      <TableComponent
        prefix="access.departments.update.members"
        hideNoDataImage
        repository={(settings) =>
          new MemberAccessRepository().listByDepartment({
            page: 0,
            limit: 100,
            direction: 'asc',
            search: settings.search,
            clinicId: properties.route.params.clinicId,
            departmentId: properties.route.params.id,
          })
        }
        suffix="list"
        columns={[
          {
            type: 'icon',
            icon: 'trash-alt',
            onPress: (row) => {
              properties.navigation.navigate('memberDelete', {
                clinicId: properties.route.params.clinicId,
                departmentId: properties.route.params.id,
                contractId: row.contractId,
                level: 1,
              });
            },
          },
          {
            type: 'text',
            width: 169,
            onRender: (row) => row.userName,
          },
        ]}
      />
      <ButtonComponent
        icon="plus"
        prefix="access.departments.update.members.add"
        onPress={() => {
          properties.navigation.navigate('memberCreate', {
            level: 1,
            clinicId: properties.route.params.clinicId,
            departmentId: properties.route.params.id,
          });
        }}
      />
    </ScrollView>
  );
}
