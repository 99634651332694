// Modules
import { StyleSheet } from 'react-native';

/**
 * Form Styles
 * @constant {StyleSheet} FormStyles
 */
export const FormStyles = StyleSheet.create({
  container: {
    flex: 1,
  },
  widthPadding: {
    padding: 20,
  },
  rightPadding: {
    paddingRight: 20,
  },
  submitButton: {
    alignSelf: 'flex-end',
    paddingHorizontal: 20,
    marginTop: 20,
    padding: 10,
    borderRadius: 5,
    backgroundColor: 'rgb(237, 53, 145)',
    borderColor: 'rgb(237, 53, 145)',
    flexDirection: 'row',
    gap: 10,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    height: 42,
    maxHeight: 42,
    minWidth: 150,
    shadowOpacity: 0.1,
    shadowRadius: 3.84,
    elevation: 5,
  },
  submitText: {
    textTransform: 'uppercase',
    color: 'rgb(255, 255, 255)',
    textAlign: 'center',
  },
  submitIcon: {
    color: 'rgba(255, 255, 255, 0.72)',
    fontSize: 20,
    textAlign: 'center',
  },
});
