import { NavigationProp, RouteProp, useNavigation } from '@react-navigation/native';
import { BoardRoutes } from '../../board.routes';
import { Platform, Text, View } from 'react-native';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ClinicAccessRepository } from '@human/access/repositories/clinic/clinic.repository';
import { SaleBoardRepository } from '../../repositories/sale/sale.repository';
import { LoaderComponent } from '@components/loader/loader.component';
import { SceneComponent } from '@components/scene/scene.component';
import { BoardHeaderComponent } from '../../components/header/board.header.component';
import { FilterSelectInput } from '@components/table/components/columns/components/filterSelect/filter.select.input';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { IconComponent } from '@components/icon/icon.component';
import { NewLeadsByDepartmentYearsComparative } from '../leads/components/newLeadsByDepartmentYearsComparative/newLeadsByDepartmentYearsComparative';
import { TwoYearColumnLeadsComparative } from '../leads/components/twoYearColumnLeadsComparative/twoYearColumnLeadsComparative';
import { ItemByDepartmentYearsComparativeType } from '../leads/components/newLeadsByDepartmentYearsComparative/newLeadByDepartmentYearsComparative.type';
import { LinearChartSalesByDepartment } from './components/linearChartSalesByDepartment/linearChartSalesByDepartment.component';
import { TextComponent } from '@components/text/text.component';
import { handleExportTableCSV } from '@utils/export/table/export.table.csv.utility';
import { LanguageAccessRepository } from '@human/access/repositories/language/language.repository';
import { BoardStyles } from '../common/board.styles';
import { useDeviceHook } from '@hooks/device/device.hook';
import { BoardFilterComponent } from '../leads/ovoclinicLeads/components/filter/filter.component';
import { FunnelCrmRepository } from '@human/crm/repositories/funnel/funnel.repository';
import { CampaignCrmRepository } from '@human/crm/repositories/campaign/campaign.repository';

export default function SummarySalesScreen(properties: {
  navigation: NavigationProp<BoardRoutes>;
  route: RouteProp<BoardRoutes, 'summarySales'>;
}) {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const { desktop } = useDeviceHook();
  const [selectedClinic, setSelectedClinic] = useState<string>();
  const [selectedFunnel, setSelectedFunnel] = useState<string>('all');
  const [selectedCampaign, setSelectedCampaign] = useState<string>('all');
  const [selectedLanguage, setSelectedLanguage] = useState<string>();
  const [languages, setLanguages] = useState<{ label: string; value: string }[]>([]);

  const [firstYear, setFirstYear] = useState<number>(new Date().getFullYear() - 1);
  const [secondYear, setSecondYear] = useState<number>(new Date().getFullYear());

  const [data, setData] = useState<ItemByDepartmentYearsComparativeType[]>([]);

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!selectedClinic || !selectedFunnel || !selectedCampaign) return;
    setLoading(true);
    new SaleBoardRepository()
      .newSaleCountYearsComparative({
        clinicId: selectedClinic,
        funnelId: selectedFunnel === 'all' ? undefined : selectedFunnel,
        campaignId: selectedCampaign === 'all' ? undefined : selectedCampaign,
        firstYear,
        secondYear,
      })
      .then((data) => {
        setData(data);
        setLoading(false);
      });
  }, [selectedClinic, firstYear, secondYear, selectedFunnel, selectedCampaign]);

  useEffect(() => {
    if (!selectedClinic) return;

    new LanguageAccessRepository().listForCombo({}).then((languages) => {
      setLanguages(languages);
      setSelectedLanguage(languages[0]?.value);
    });
  }, [selectedClinic]);

  const handleExport = (type: 'yearly' | 'monthly') => {
    if (confirm(t('export.description') ?? 'Exportar datos a CSV')) {
      const headers =
        type === 'monthly'
          ? Array.from({ length: 12 }).map((_, i) => t(`access.users.list.month.${i}`))
          : [firstYear.toString(), secondYear.toString()];

      handleExportTableCSV({
        headers: ['Departamento', ...headers],
        data,
        type,
        fileName: type === 'monthly' ? 'monthlySales.csv' : 'yearlySales.csv',
      });
    }
    return;
  };

  return (
    <SceneComponent icon={'search'} prefix={'board.summarySales'} links={[]} multiWorkspace>
      <View style={BoardStyles.cardContainer}>
        <BoardHeaderComponent
          title="board.summarySales.totalSales.title"
          description="board.summarySales.totalSales.description"
        />
        <View style={BoardStyles.fiterContainer}>
          <View style={{ width: 250 }}>
            <View style={BoardStyles.filterTextContainer}>
              <Text style={BoardStyles.filterTitle}>
                {t('board.summarySales.totalSales.filters.clinic')}
              </Text>
              <Text style={BoardStyles.filterSubtitle}>
                {t('board.summarySales.totalSales.filters.clinicSubtitle')}
              </Text>
            </View>
            <View>
              <FilterSelectInput
                type="select"
                value={selectedClinic ?? ''}
                name="clinic"
                onChange={(value: any) => {
                  setSelectedClinic(value.clinic);
                }}
                pick={async ({ id }) =>
                  new ClinicAccessRepository().pickForCombo({
                    id,
                  })
                }
                repository={async ({ search }) => {
                  const list = await new ClinicAccessRepository().listForCombo({
                    search,
                  });
                  setSelectedClinic(list[0]?.value);
                  return list;
                }}
              />
            </View>
          </View>
          <View style={{ width: 200 }}>
            <View style={BoardStyles.filterTextContainer}>
              <Text style={BoardStyles.filterTitle}>
                {t('board.summarySales.totalSales.filters.optionA')}
              </Text>
              <Text style={BoardStyles.filterSubtitle}>
                {t('board.summarySales.totalSales.filters.optionASubtitle')}
              </Text>
            </View>
            <View>
              <FilterSelectInput
                type="select"
                value={'' + firstYear}
                name="fistYear"
                onChange={(value: any) => {
                  setFirstYear(+value.fistYear);
                }}
                hideSearch
                options={Array.from({ length: 8 }, (_, i) => {
                  i = i - 8;
                  return {
                    label: `${new Date().getFullYear() + i}`,
                    value: `${new Date().getFullYear() + i}`,
                  };
                })}
              />
            </View>
          </View>

          <View style={{ width: 200 }}>
            <View style={BoardStyles.filterTextContainer}>
              <Text style={BoardStyles.filterTitle}>
                {t('board.summarySales.totalSales.filters.optionB')}
              </Text>
              <Text style={BoardStyles.filterSubtitle}>
                {t('board.summarySales.totalSales.filters.optionBSubtitle')}
              </Text>
            </View>
            <View>
              <FilterSelectInput
                type="select"
                value={'' + secondYear}
                name="secondYear"
                onChange={(value: any) => {
                  setSecondYear(+value.secondYear);
                }}
                hideSearch
                options={Array.from({ length: 8 }, (_, i) => {
                  i = i - 7;
                  return {
                    label: `${new Date().getFullYear() + i}`,
                    value: `${new Date().getFullYear() + i}`,
                  };
                })}
              />
            </View>
          </View>
          <BoardFilterComponent
            width={200}
            title={t('board.leads.totalLeads.filters.funnel')}
            subtitle={t('board.leads.totalLeads.filters.funnelSubtitle') ?? ''}
            value={selectedFunnel ?? ''}
            name={'selectedFunnel'}
            onChange={(value: any) => {
              setSelectedFunnel(value.selectedFunnel);
              setSelectedCampaign('all');
            }}
            pick={async ({ id }) => {
              if (id === 'all') return t('board.leads.totalLeads.filters.all') ?? '';
              return new FunnelCrmRepository().pickForCombo({
                id,
              });
            }}
            repository={async ({ search }) => {
              const list = await new FunnelCrmRepository().listForCombo({
                search,
              });
              list.unshift({ label: t('board.leads.totalLeads.filters.all') ?? '', value: 'all' });
              return list;
            }}
          />
          <BoardFilterComponent
            width={200}
            title={t('board.leads.totalLeads.filters.campaign')}
            subtitle={t('board.leads.totalLeads.filters.campaignSubtitle') ?? ''}
            value={selectedCampaign ?? ''}
            name={'selectedCampaign'}
            onChange={(value: any) => {
              setSelectedCampaign(value.selectedCampaign);
              setSelectedFunnel('all');
            }}
            pick={async ({ id }) => {
              if (id === 'all') return t('board.leads.totalLeads.filters.all') ?? '';
              return new CampaignCrmRepository().pickForCombo({
                campaignId: id,
              });
            }}
            repository={async ({ search }) => {
              const list = await new CampaignCrmRepository().listForCombo({
                active: true,
                search,
              });
              list.unshift({ label: t('board.leads.totalLeads.filters.all') ?? '', value: 'all' });
              return list;
            }}
          />
        </View>
        <View style={desktop ? BoardStyles.graphicsContainer : BoardStyles.graphicsContainerColumn}>
          <View
            style={[
              BoardStyles.graphicContainer,
              { flex: 7, minHeight: 300, minWidth: 1000, overflow: 'scroll' },
            ]}
          >
            <View style={BoardStyles.graphicContainerHeader}>
              <Text style={BoardStyles.graphicTitle}>
                {t('board.summarySales.totalSales.comparativeGraphicTitle')}
              </Text>
              {Platform.OS === 'web' && (
                <TouchableOpacity
                  style={BoardStyles.uploadBox}
                  onPress={() => handleExport('monthly')}
                >
                  <View style={BoardStyles.buttonContainer}>
                    <TextComponent
                      text={t('board.leads.totalLeads.export')}
                      style={BoardStyles.buttonLabel}
                    />
                    <IconComponent
                      name="upload"
                      containerstyle={BoardStyles.iconContainer}
                      iconStyle={BoardStyles.icon}
                    />
                  </View>
                </TouchableOpacity>
              )}
            </View>
            {selectedClinic && !loading ? (
              <NewLeadsByDepartmentYearsComparative
                data={data}
                firstYear={firstYear}
                secondYear={secondYear}
                onCellPress={(department) => {
                  // @ts-ignore
                  navigation.navigate('crm', {
                    screen: 'patients',
                    params: {
                      screen: 'patientList',
                      params: {
                        clinicId: selectedClinic,
                        funnelId: selectedFunnel !== 'all' ? selectedFunnel : undefined,
                        campaignId: selectedCampaign !== 'all' ? selectedCampaign : undefined,
                        departmentId: department.departmentId,
                        year: department.year,
                        month: department.month,
                      },
                    },
                  });
                }}
              />
            ) : (
              <LoaderComponent loading />
            )}
          </View>
          <View style={[BoardStyles.graphicContainer]}>
            <View style={BoardStyles.graphicContainerHeader}>
              <Text style={BoardStyles.graphicTitle}>
                {t('board.summarySales.totalSales.comparativeAcumulatedGraphicTitle')}
              </Text>
              {Platform.OS === 'web' && (
                <TouchableOpacity
                  style={BoardStyles.uploadBox}
                  onPress={() => handleExport('yearly')}
                >
                  <View style={BoardStyles.buttonContainer}>
                    <TextComponent
                      text={t('board.leads.totalLeads.csv')}
                      style={BoardStyles.buttonLabel}
                    />
                    <IconComponent
                      name="upload"
                      containerstyle={BoardStyles.iconContainer}
                      iconStyle={BoardStyles.icon}
                    />
                  </View>
                </TouchableOpacity>
              )}
            </View>
            {selectedClinic && !loading ? (
              <TwoYearColumnLeadsComparative
                data={data}
                firstYear={firstYear}
                secondYear={secondYear}
                onCellPress={({ departmentId, year }) => {
                  // @ts-ignore
                  navigation.navigate('crm', {
                    screen: 'patients',
                    params: {
                      screen: 'patientList',
                      params: {
                        clinicId: selectedClinic,
                        departmentId,
                        year,
                      },
                    },
                  });
                }}
              />
            ) : (
              <LoaderComponent loading />
            )}
          </View>
        </View>

        <View style={[BoardStyles.fiterContainer, { marginTop: 50 }]}>
          <View style={{ width: 250 }}>
            <View style={BoardStyles.filterTextContainer}>
              <Text style={BoardStyles.filterTitle}>
                {t('board.summarySales.totalSales.filters.department')}
              </Text>
              <Text style={BoardStyles.filterSubtitle}>
                {t('board.summarySales.totalSales.filters.departmentSubtitle')}
              </Text>
            </View>
            {selectedClinic && (
              <View>
                <FilterSelectInput
                  type="select"
                  value={selectedLanguage ?? ''}
                  name="department"
                  onChange={(value: any) => {
                    setSelectedLanguage(value.department);
                  }}
                  pick={async ({ id }) =>
                    new LanguageAccessRepository().pickForCombo({
                      languageId: selectedLanguage ?? '',
                    })
                  }
                  repository={async () => {
                    return Promise.resolve(languages);
                  }}
                  hideSearch
                />
              </View>
            )}
          </View>
        </View>
        <View style={BoardStyles.graphicsContainer}>
          <View
            style={{
              gap: 30,
              backgroundColor: 'white',
              padding: 15,
              borderRadius: 5,
              flex: 1,
              minHeight: 400,
            }}
          >
            <View>
              <Text style={BoardStyles.graphicTitle}>
                {t('board.summarySales.totalSales.comparativeGraphicTitle')}
              </Text>
            </View>

            {selectedClinic && selectedLanguage && !loading ? (
              <LinearChartSalesByDepartment
                selectedDepartment={selectedLanguage}
                departmentName={
                  languages.find((language) => language.value === selectedLanguage)?.label!
                }
                firstYear={firstYear}
                secondYear={secondYear}
                data={data}
              />
            ) : (
              <LoaderComponent loading />
            )}
          </View>
        </View>
      </View>
    </SceneComponent>
  );
}
