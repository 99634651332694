import { createDrawerNavigator } from '@react-navigation/drawer';
import { RouteProp } from '@react-navigation/native';
import { Platform, View } from 'react-native';
import { MenuComponent } from '../../../../../components/menu/menu.component';
import { PerformanceComponent } from '../../../../../components/performance/performance.component';
import { AuthorizedSpaces } from '../../authorized.spaces';
import { CrmRoutes } from './crm.routes';
import { CrmOvoBankSections, CrmSections } from './crm.sections';
import { ActionsSection } from './sections/actions/actions.section';
import { ActivitiesSection } from './sections/activity/activity.section';
import { ChannelsSection } from './sections/channels/channels.section';
import { ContactsSection } from './sections/contacts/contacts.section';
import { FunnelsSection } from './sections/funnels/funnels.section';
import { LeadsSection } from './sections/leads/leads.section';
import { PatientsSection } from './sections/patients/patient.section';
import { ResultsSection } from './sections/result/result.section';
import { TasksSection } from './sections/tasks/tasks.section';
import { TechniquesSection } from './sections/technique/technique.section';
import { ProgramsSection } from './sections/programs/programs.section';
import { ReasonsSection } from './sections/reasons/reason.section';
import { DashboardSection } from './sections/dashboard/dashboard.section';
import { CampaignsSection } from './sections/campaings/campaings.section';
import { PhasesSection } from './sections/phase/phase.section';
import { CausesSection } from './sections/cause/cause.section';
import { TreatmentsSection } from './sections/treatment/treatment.section';
import { ServicesSection } from './sections/service/service.section';
import { MaintenanceSection } from './sections/maintenance/maintenance.section';
import { useDeviceHook } from '@hooks/device/device.hook';
import { HeaderComponent } from '@components/menu/components/header/header.component';
import { NotificationScreen } from '../../common/notification/screens/notification.screen';
import { useNotificationChecker } from '@hooks/notification/useNotificationChecker';
import { TransferTaskSection } from './sections/transferTask/transferTask.section';
import { CyclesSection } from './sections/cycles/cycles.section';
import { CreateTaskMassivelySection } from './sections/createTaskMassively/createTaskMassively.section';
import { getWorkspaceUtility } from '@utils/workspace/getWorkspace.utility';
import { TransferLeadSection } from './sections/transferLead/transferLead.section';
import { InformationScreen } from './sections/information/screens/information.screen';
import { FaqScreen } from './sections/faq/screens/faq.screens';

const Drawer = createDrawerNavigator<CrmRoutes>();

export function CrmSpace() {
  const { phone, tablet, laptop, desktop } = useDeviceHook();
  useNotificationChecker();

  const mobileConfig: Array<boolean> = [phone, tablet];

  const isMobile = mobileConfig.some((config) => config);

  return (
    <View
      style={{
        flex: 1,
        flexDirection: 'row',
        overflow: 'hidden',
      }}
    >
      <Drawer.Navigator
        drawerContent={(props) => (
          <MenuComponent
            brand="human"
            {...props}
            sections={getWorkspaceUtility() === 'ovoclinic' ? CrmSections : CrmOvoBankSections}
            type="crm"
          />
        )}
        screenOptions={{
          headerTitle: () => <HeaderComponent type={'crm'} brand={'human'} />,
          drawerType: isMobile ? 'front' : 'permanent',
          swipeEnabled: isMobile,
          drawerStyle: {
            width: phone ? 350 : 400,
          },
          headerShown: isMobile,
          headerTintColor: 'rgb(237, 53, 145)',
        }}
      >
        {Platform.OS === 'web' && <Drawer.Screen name="dashboard" component={DashboardSection} />}
        <Drawer.Screen name="campaigns" component={CampaignsSection} />
        <Drawer.Screen name="channels" component={ChannelsSection} />
        <Drawer.Screen name="funnels" component={FunnelsSection} />
        <Drawer.Screen name="contacts" component={ContactsSection} />
        <Drawer.Screen name="patients" component={PatientsSection} />
        <Drawer.Screen name="leads" component={LeadsSection} />
        <Drawer.Screen name="techniques" component={TechniquesSection} />
        <Drawer.Screen name="programs" component={ProgramsSection} />
        <Drawer.Screen name="reasons" component={ReasonsSection} />
        {Platform.OS === 'web' && <Drawer.Screen name="actions" component={ActionsSection} />}
        <Drawer.Screen name="tasks" component={TasksSection} />
        <Drawer.Screen name="transferLead" component={TransferLeadSection} />
        <Drawer.Screen name="activities" component={ActivitiesSection} />
        <Drawer.Screen name="results" component={ResultsSection} />
        <Drawer.Screen name="phases" component={PhasesSection} />
        <Drawer.Screen name="causes" component={CausesSection} />
        <Drawer.Screen name="treatments" component={TreatmentsSection} />
        <Drawer.Screen name="services" component={ServicesSection} />
        <Drawer.Screen name="maintenances" component={MaintenanceSection} />
        <Drawer.Screen name="notifications" component={NotificationScreen} />
        <Drawer.Screen name="information" component={InformationScreen} />
        <Drawer.Screen name="faq" component={FaqScreen} />
        <Drawer.Screen name="transferTask" component={TransferTaskSection} />
        <Drawer.Screen name="cycles" component={CyclesSection} />
        <Drawer.Screen name="createTaskMassively" component={CreateTaskMassivelySection} />
      </Drawer.Navigator>

      {desktop && <PerformanceComponent />}
    </View>
  );
}
