import { StyleSheet } from 'react-native';

export const TotalLeadsByFunnelTableStyles = StyleSheet.create({
  headerContainer: {
    flexDirection: 'row',
    flex: 10,
    marginBottom: 20,
  },
  headerSpaceHeader: {
    flex: 1,
    justifyContent: 'center',
  },
  headerColumnContainer: {
    flex: 1,
  },
  headerText: {
    color: 'black',
    textAlign: 'center',
    fontWeight: '300',
    fontSize: 12,
  },
  phoneHeaderText: {
    color: 'black',
    textAlign: 'center',
    fontWeight: '300',
    fontSize: 16,
  },
  headerSpacer: {
    height: 1,
    backgroundColor: '#ccc',
    marginVertical: 5,
  },
});
