import { View } from 'react-native';
import { ParamsStyles } from './params.styles';
import { TextComponent } from '@components/text/text.component';
import { IconComponent } from '@components/icon/icon.component';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { useNavigation, useRoute } from '@react-navigation/native';
export function ParamsComponent(properties: {
  prefix: string;
  params: {
    [key: string]: string | undefined;
  };
}) {
  const navigation = useNavigation();
  const route = useRoute();

  return (
    <View style={ParamsStyles.container}>
      {Object.keys(properties.params).map((key, index) => {
        if (properties.params[key] === undefined) return null;
        return (
          <View key={index} style={ParamsStyles.item}>
            <TouchableOpacity
              key={index}
              onPress={() => {
                const routeParams = route.params;

                // @ts-ignore
                navigation.setParams({
                  ...routeParams,
                  [key]: undefined,
                });
              }}
              style={ParamsStyles.iconContainer}
            >
              <IconComponent name="broom" iconStyle={ParamsStyles.icon} />
            </TouchableOpacity>
            <TextComponent
              key={index}
              translate
              text={`${properties.prefix}.params.${key}`}
              style={ParamsStyles.text}
            />
          </View>
        );
      })}
    </View>
  );
}
