import { View, Text, Pressable } from 'react-native';
import React from 'react';
import { InterventionYearCountRowStyles } from './interventionYearCountRow';
import { useDeviceHook } from '@hooks/device/device.hook';

export function InterventionsYearCountRow(properties: {
  item: {
    department_id: string;
    department_name: string;
    total_leads: number[];
  };
  index: number;
  totalRows: number;
  onCellPress: (settings: { departmentId: string; month: number }) => void;
}) {
  const { phone } = useDeviceHook();

  const firstAttemptRow = properties.index === properties.totalRows - 3;
  const isLossRow = properties.index === properties.totalRows - 2;
  const last = properties.index === properties.totalRows - 1;

  return (
    <View
      style={[
        InterventionYearCountRowStyles.rowContainer,
        last && InterventionYearCountRowStyles.rowContainerLast,
        isLossRow && InterventionYearCountRowStyles.rowContainerLoss,
        firstAttemptRow && InterventionYearCountRowStyles.rowContainerFirstAttempt,
      ]}
    >
      <View
        style={[phone ? { width: 75 } : InterventionYearCountRowStyles.subRowSpaceColumnContainer]}
      >
        <Text
          style={[
            phone
              ? InterventionYearCountRowStyles.phoneSubrowText
              : InterventionYearCountRowStyles.subRowSpaceText,
            (isLossRow || firstAttemptRow || last) && {
              fontWeight: '500',
            },
            isLossRow && InterventionYearCountRowStyles.subRowLossText,
          ]}
        >
          {properties.item.department_name}
        </Text>
      </View>

      <View
        style={{
          flex: 10,
          flexDirection: 'row',
          gap: 2,
        }}
      >
        {properties.item.total_leads.map((total, index) => (
          <Pressable
            disabled={total === 0}
            style={InterventionYearCountRowStyles.subRowColumnContainer}
            onPress={() =>
              properties.onCellPress({
                departmentId: properties.item.department_id
                  ? properties.item.department_id
                  : undefined,
                month: index + 1,
              })
            }
          >
            <Text
              style={[
                { width: 75, textAlign: 'center' },
                phone
                  ? InterventionYearCountRowStyles.phoneSubrowText
                  : InterventionYearCountRowStyles.subRowText,

                index > new Date().getMonth() && InterventionYearCountRowStyles.subRowFutureText,
                isLossRow && InterventionYearCountRowStyles.subRowLossText,
                // new Date().getMonth() < 0 && InterventionYearCountRowStyles.subRowFutureText,
              ]}
            >
              {total}
            </Text>
          </Pressable>
        ))}
      </View>
    </View>
  );
}
