import { NavigationProp, useNavigation } from '@react-navigation/native';
import { BoardRoutes } from '../../board.routes';
import { Platform, Text, View } from 'react-native';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ItemByDepartmentYearsComparativeType } from '../../types/itemByDepartmentYearsComparative.type';
import { ClinicAccessRepository } from '@human/access/repositories/clinic/clinic.repository';
import { InterventionBoardRepository } from '../../repositories/intervention/intervention.repository';
import { LoaderComponent } from '@components/loader/loader.component';
import { SceneComponent } from '@components/scene/scene.component';
import { FilterSelectInput } from '@components/table/components/columns/components/filterSelect/filter.select.input';
import { BoardHeaderComponent } from '../../components/header/board.header.component';
import { IconComponent } from '@components/icon/icon.component';
import { ExpectedInterventionsStyles } from './expectedInterventions.styles';
import { TextComponent } from '@components/text/text.component';
import { ExpectedInterventionsYearTable } from './components/expectedInterventionsYearTable/expectedInterventionsYear.table';
import { TwoYearColumnLeadsComparative } from '../leads/components/twoYearColumnLeadsComparative/twoYearColumnLeadsComparative';
import { CrmSections } from '@human/crm/crm.sections';
import { ExpectedInterventionsYearLine } from './components/expectedInterventionsYearLine/expectedInterventionsYearLine.component.web';
import { ExpectedInterventionsTwoYearLine } from './components/expectedInterventionsTwoYearLine/expectedInterventionsTwoYearLine.component.web';
import { ExpecteInterventionDepartmentCircle } from './components/expectedInterventionsDepartmentCircle/expectedInterventionsDepartmentCircle.component';
import { LanguageAccessRepository } from '@human/access/repositories/language/language.repository';
import { handleExportTableCSV } from '@utils/export/table/export.table.csv.utility';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { useDeviceHook } from '@hooks/device/device.hook';

export default function ExpectedInterventionsScreen(properties: {
  navigation: NavigationProp<BoardRoutes, 'expectedIntervention'>;
}) {
  enum ComplexityFilter {
    ALL = 'ALL',
    LOW = 'LOW',
    HIGH = 'HIGH',
  }

  const { t } = useTranslation();
  const navigation = useNavigation();
  const { desktop } = useDeviceHook();
  const [selectedClinic, setSelectedClinic] = useState<string>();
  const [clinics, setClinics] = useState<{ label: string; value: string }[]>([]);

  const [selectedComplexity, setSelectedComplexity] = useState<ComplexityFilter>(
    ComplexityFilter.ALL
  );

  const [selectedLanguage, setSelectedLanguage] = useState<string>();
  const [languages, setLanguages] = useState<{ label: string; value: string }[]>([]);
  const [month, setMonth] = useState<number>(new Date().getMonth());

  const [year, setYear] = useState<number>(new Date().getFullYear());

  const [data, setData] = useState<ItemByDepartmentYearsComparativeType[]>([]);
  const [loadingData, setLoadingData] = useState<boolean>(false);

  const [dataByDepartment, setDataByDepartment] = useState<{
    total: number;
    total_loss: number;
    total_first_attempt: number;
  }>();
  const [loadingDataByDepartment, setLoadingDataByDepartment] = useState<boolean>(false);

  useEffect(() => {
    new ClinicAccessRepository().listForCombo({}).then((clinics) => {
      setClinics(clinics);
    });
  }, []);

  useEffect(() => {
    setSelectedClinic(clinics[0]?.value);
  }, [clinics]);

  useEffect(() => {
    if (!selectedClinic || !year || !selectedComplexity) return;
    setLoadingData(true);
    new InterventionBoardRepository()
      .expectedInterventionsDepartmentsComparative({
        clinicId: selectedClinic,
        complexity: selectedComplexity === ComplexityFilter.ALL ? undefined : selectedComplexity,
        year,
      })
      .then((data) => {
        setData(data);
        setLoadingData(false);
      });
  }, [selectedClinic, year, selectedComplexity]);

  useEffect(() => {
    if (!selectedClinic) return;

    new LanguageAccessRepository().listForCombo({}).then((languages) => {
      setLanguages(languages);
    });
  }, [selectedClinic]);

  useEffect(() => {
    setSelectedLanguage(languages[0]?.value);
  }, [languages]);

  useEffect(() => {
    if (!selectedClinic || !selectedLanguage) return;

    setLoadingDataByDepartment(true);
    new InterventionBoardRepository()
      .expectedInterventionsByDepartmentAndMonth({
        clinicId: selectedClinic,
        month: month + 1,
        languageId: selectedLanguage,
        year,
      })
      .then((data) => {
        setDataByDepartment(data);
        setLoadingDataByDepartment(false);
      });
  }, [selectedLanguage, selectedClinic, month, year]);

  const handleExport = (type: 'yearly' | 'monthly') => {
    if (confirm(t('export.description') ?? 'Exportar datos a CSV')) {
      const headers =
        type === 'monthly'
          ? Array.from({ length: 12 }).map((_, i) => t(`access.users.list.month.${i}`))
          : [year.toString()];

      const csvRows = type === 'monthly' ? data.filter((item) => item.year === year) : data;

      handleExportTableCSV({
        headers: ['Departamento', ...headers],
        data: csvRows,
        type,
        fileName:
          type === 'monthly' ? 'monthly_expected_treatments.csv' : 'yearly_expected_treatments.csv',
      });
    }
    return;
  };

  if (!selectedClinic) return <LoaderComponent loading />;

  return (
    <SceneComponent icon={CrmSections.treatments.icon} prefix={'board.intervention'} links={[]}>
      <View style={ExpectedInterventionsStyles.cardContainer}>
        <BoardHeaderComponent
          title="board.intervention.title"
          description="board.intervention.description"
        />
        <View style={ExpectedInterventionsStyles.fiterContainer}>
          <View style={{ width: 250 }}>
            <View style={ExpectedInterventionsStyles.filterTextContainer}>
              <Text style={ExpectedInterventionsStyles.filterTitle}>
                {t('board.leads.totalLeads.filters.clinic')}
              </Text>
              <Text style={ExpectedInterventionsStyles.filterSubtitle}>
                {t('board.leads.totalLeads.filters.clinicSubtitle')}
              </Text>
            </View>
            <View>
              <FilterSelectInput
                type="select"
                value={selectedClinic ?? ''}
                name="clinic"
                onChange={(value: any) => {
                  setSelectedClinic(value.clinic);
                }}
                hideSearch
                pick={async ({ id }) =>
                  new ClinicAccessRepository().pickForCombo({
                    id: selectedClinic ?? '',
                  })
                }
                repository={async () => {
                  return Promise.resolve(clinics);
                }}
              />
            </View>
          </View>
          <View style={{ width: 200 }}>
            <View style={ExpectedInterventionsStyles.filterTextContainer}>
              <Text style={ExpectedInterventionsStyles.filterTitle}>
                {t('board.marketing.filters.year')}
              </Text>
              <Text style={ExpectedInterventionsStyles.filterSubtitle}>
                {t('board.marketing.filters.yearSubtitle')}
              </Text>
            </View>
            <View>
              <FilterSelectInput
                type="select"
                value={'' + year}
                name="year"
                onChange={(value: any) => {
                  setYear(+value.year);
                }}
                hideSearch
                options={Array.from({ length: 8 }, (_, i) => {
                  i = i - 7;
                  return {
                    label: `${new Date().getFullYear() + i}`,
                    value: `${new Date().getFullYear() + i}`,
                  };
                })}
              />
            </View>
          </View>
          <View style={{ width: 250 }}>
            <View style={ExpectedInterventionsStyles.filterTextContainer}>
              <Text style={ExpectedInterventionsStyles.filterTitle}>
                {t('board.intervention.filters.complexity.title')}
              </Text>
              <Text style={ExpectedInterventionsStyles.filterSubtitle}>
                {t('board.intervention.filters.complexity.subtitle')}
              </Text>
            </View>
            <View>
              <FilterSelectInput
                type="select"
                value={'' + selectedComplexity}
                name="complexity"
                onChange={(value: any) => {
                  setSelectedComplexity(value.complexity);
                }}
                hideSearch
                options={Object.values(ComplexityFilter).map((value) => {
                  return {
                    label: t(`board.intervention.filters.complexity.types.${value}`),
                    value,
                  };
                })}
              />
            </View>
          </View>
        </View>
        <View
          style={[
            desktop && ExpectedInterventionsStyles.graphicsContainer,
            !desktop && ExpectedInterventionsStyles.graphicsContainerColumn,
          ]}
        >
          <View style={[ExpectedInterventionsStyles.graphicContainer, { flex: 7, minWidth: 1000 }]}>
            <View style={ExpectedInterventionsStyles.graphicContainerHeader}>
              <Text style={ExpectedInterventionsStyles.graphicTitle}>
                {t('board.intervention.expectedInterventions.monthsTable')}
              </Text>
              {Platform.OS === 'web' && (
                <TouchableOpacity
                  style={ExpectedInterventionsStyles.uploadBox}
                  onPress={() => handleExport('monthly')}
                >
                  <View style={ExpectedInterventionsStyles.buttonContainer}>
                    <TextComponent
                      text={t('board.leads.totalLeads.export')}
                      style={ExpectedInterventionsStyles.buttonLabel}
                    />
                    <IconComponent
                      name="upload"
                      containerstyle={ExpectedInterventionsStyles.iconContainer}
                      iconStyle={ExpectedInterventionsStyles.icon}
                    />
                  </View>
                </TouchableOpacity>
              )}
            </View>
            {selectedClinic && !loadingData ? (
              <ExpectedInterventionsYearTable
                data={data}
                selectedYear={year}
                onCellPress={({ departmentId, month }) => {
                  // @ts-ignore
                  navigation.navigate('crm', {
                    screen: 'treatments',
                    params: {
                      screen: 'listTreatment',
                      params: {
                        clinicId: selectedClinic,
                        year,
                        complexity:
                          selectedComplexity !== ComplexityFilter.ALL
                            ? selectedComplexity
                            : undefined,
                        firstAttempt: departmentId === 'Garantias (Primer intento)' || undefined,
                        loss: departmentId === 'Perdidas (+1 intento)' || undefined,
                        departmentId:
                          departmentId !== 'Garantias (Primer intento)' &&
                          departmentId !== 'Perdidas (+1 intento)'
                            ? departmentId
                            : undefined,
                        month,
                      },
                    },
                  });
                }}
              />
            ) : (
              <LoaderComponent loading />
            )}
          </View>

          <View style={[ExpectedInterventionsStyles.graphicContainer, { gap: 0, padding: 15 }]}>
            <View style={[ExpectedInterventionsStyles.graphicContainerHeader]}>
              <Text style={ExpectedInterventionsStyles.graphicTitle}>
                {t('board.intervention.expectedInterventions.yearsTable')}
              </Text>
              {Platform.OS === 'web' && (
                <TouchableOpacity
                  style={ExpectedInterventionsStyles.uploadBox}
                  onPress={() => handleExport('yearly')}
                >
                  <View style={ExpectedInterventionsStyles.buttonContainer}>
                    <TextComponent
                      text={t('board.leads.totalLeads.csv')}
                      style={ExpectedInterventionsStyles.buttonLabel}
                    />
                    <IconComponent
                      name="upload"
                      containerstyle={ExpectedInterventionsStyles.iconContainer}
                      iconStyle={ExpectedInterventionsStyles.icon}
                    />
                  </View>
                </TouchableOpacity>
              )}
            </View>

            {selectedClinic && !loadingData ? (
              <TwoYearColumnLeadsComparative
                interventionType
                data={data}
                firstYear={year - 1}
                secondYear={year}
                onCellPress={({ year, departmentId }) => {
                  // @ts-ignore
                  navigation.navigate('crm', {
                    screen: 'treatments',
                    params: {
                      screen: 'listTreatment',
                      params: {
                        clinicId: selectedClinic,
                        year,
                        complexity:
                          selectedComplexity !== ComplexityFilter.ALL
                            ? selectedComplexity
                            : undefined,
                        firstAttempt: departmentId === 'Garantias (Primer intento)' || undefined,
                        loss: departmentId === 'Perdidas (+1 intento)' || undefined,
                        departmentId:
                          departmentId !== 'Garantias (Primer intento)' &&
                          departmentId !== 'Perdidas (+1 intento)'
                            ? departmentId
                            : undefined,
                      },
                    },
                  });
                }}
              />
            ) : (
              <LoaderComponent loading />
            )}
          </View>
        </View>
        <View
          style={[
            desktop && ExpectedInterventionsStyles.graphicsContainer,
            !desktop && ExpectedInterventionsStyles.graphicsContainerColumn,
            { marginTop: 30 },
          ]}
        >
          <View style={[ExpectedInterventionsStyles.graphicContainer, { flex: 7 }]}>
            <View style={ExpectedInterventionsStyles.graphicContainerHeader}>
              <Text style={ExpectedInterventionsStyles.graphicTitle}>
                {t('board.intervention.expectedInterventions.monthsTable')}
              </Text>
            </View>
            {selectedClinic && !loadingData ? (
              <ExpectedInterventionsYearLine selectedYear={year} data={data} />
            ) : (
              <LoaderComponent loading />
            )}
          </View>
          <View style={[ExpectedInterventionsStyles.graphicContainer, { height: '100%' }]}>
            <View style={ExpectedInterventionsStyles.graphicContainerHeader}>
              <Text style={ExpectedInterventionsStyles.graphicTitle}>
                {t('board.intervention.expectedInterventions.yearsTable')}
              </Text>
            </View>
            {selectedClinic && !loadingData ? (
              <ExpectedInterventionsTwoYearLine year={year} data={data} />
            ) : (
              <LoaderComponent loading />
            )}
          </View>
        </View>
        <View
          style={[ExpectedInterventionsStyles.fiterContainer, { marginTop: 30, marginBottom: 10 }]}
        >
          <View style={{ width: 250 }}>
            <View style={ExpectedInterventionsStyles.filterTextContainer}>
              <Text style={ExpectedInterventionsStyles.filterTitle}>
                {t('board.leads.totalLeads.filters.department')}
              </Text>
              <Text style={ExpectedInterventionsStyles.filterSubtitle}>
                {t('board.leads.totalLeads.filters.departmentSubtitle')}
              </Text>
            </View>
            <View>
              <FilterSelectInput
                type="select"
                value={selectedLanguage ?? ''}
                name="department"
                onChange={(value: any) => {
                  setSelectedLanguage(value.department);
                }}
                pick={async ({ id }) =>
                  new LanguageAccessRepository().pickForCombo({
                    languageId: selectedLanguage ?? '',
                  })
                }
                repository={async () => {
                  return Promise.resolve(languages);
                }}
                hideSearch
              />
            </View>
          </View>

          <View style={{ width: 200 }}>
            <View style={ExpectedInterventionsStyles.filterTextContainer}>
              <Text style={ExpectedInterventionsStyles.filterTitle}>
                {t('board.summarySales.totalSales.filters.month')}
              </Text>
              <Text style={ExpectedInterventionsStyles.filterSubtitle}>
                {t('board.summarySales.totalSales.filters.monthSubtitle')}
              </Text>
            </View>
            <View>
              <FilterSelectInput
                type="select"
                value={month.toString()}
                name="month"
                onChange={(value: any) => {
                  setMonth(parseInt(value.month));
                }}
                options={Array.from({ length: 12 }, (_, i) => {
                  return {
                    label: t(`access.users.list.month.${i}`),
                    value: i.toString(),
                  };
                })}
                hideSearch
              />
            </View>
          </View>
        </View>
        <View style={ExpectedInterventionsStyles.graphicsContainer}>
          <View style={[ExpectedInterventionsStyles.graphicContainer, { flex: 7, margin: 0 }]}>
            <View style={ExpectedInterventionsStyles.graphicContainerHeader}>
              <Text style={ExpectedInterventionsStyles.graphicTitle}>
                {t('board.intervention.expectedInterventions.monthsTable')}
              </Text>
            </View>
            <View style={{ flex: 1, flexDirection: 'row', gap: 10 }}>
              {selectedClinic && dataByDepartment && !loadingDataByDepartment ? (
                <ExpecteInterventionDepartmentCircle
                  title={t('board.intervention.segmention')}
                  labelTraduction="board.intervention.expectedInterventionsSegmentionCountType"
                  data={dataByDepartment}
                />
              ) : (
                <LoaderComponent loading />
              )}
              {selectedClinic && dataByDepartment && !loadingDataByDepartment ? (
                <ExpecteInterventionDepartmentCircle
                  title={t('board.intervention.lossGainSegmentation')}
                  labelTraduction="board.intervention.expectedInterventionsLossGainSegmentationCountType"
                  data={dataByDepartment}
                />
              ) : (
                <LoaderComponent loading />
              )}
            </View>
          </View>
        </View>
      </View>
    </SceneComponent>
  );
}
