import { ModalComponent } from '@components/modal/modal.component';
import { NavigationProp, RouteProp } from '@react-navigation/native';
import { CampaignsRoutes } from '../../../campaigns.routes';
import { FormComponent } from '@components/form/form.component';
import { ParticipantRepository } from '@human/crm/repositories/participant/participant.repository';
import { ClinicAccessRepository } from '@human/access/repositories/clinic/clinic.repository';

export function CreateParticipantCampaignScreen(properties: {
  navigation: NavigationProp<CampaignsRoutes>;
  route: RouteProp<CampaignsRoutes, 'createParticipant'>;
}) {
  return (
    <ModalComponent
      icon="trash-alt"
      level={properties.route.params.level}
      prefix="crm.campaigns.update.participants.create"
    >
      <FormComponent<{ clinicId: string }>
        padding
        prefix="crm.campaigns.update.participants.create.form"
        repository={{
          send: (settings) => {
            return new ParticipantRepository()
              .create({
                campaignId: properties.route.params.campaignId,
                clinicId: settings.item.clinicId,
              })
              .then(() => properties.navigation.goBack());
          },
        }}
        fields={[
          {
            name: 'clinicId',
            type: 'select',
            description: true,
            required: true,
            repository: ({ search }) => {
              return new ClinicAccessRepository().listForCombo({ search, active: true });
            },
          },
        ]}
      />
    </ModalComponent>
  );
}
