import { FormComponent } from '@components/form/form.component';
import { ModalComponent } from '@components/modal/modal.component';
import { TabsComponent } from '@components/tabs/tabs.component';
import { LanguageAccessRepository } from '@human/access/repositories/language/language.repository';
import { CrmSections } from '@human/crm/crm.sections';
import { CampaignCrmRepository } from '@human/crm/repositories/campaign/campaign.repository';
import { FunnelCrmRepository } from '@human/crm/repositories/funnel/funnel.repository';
import { CampaignCrmType } from '@human/crm/types/campaign.crm.type';
import { NavigationProp, RouteProp } from '@react-navigation/native';
import { CampaignsRoutes } from '../../campaigns.routes';
import { ManagerCampaingUpdatePart } from './parts/manager.campaing.update.screen';
import { ActivityCrmRepository } from '@human/crm/repositories/activity/activity.repository';
import { FinancingCampaignPart } from './parts/financing.campaign.part';
import { WebhookCampaignScreen } from './parts/webhook/webhook.campaign.screen';
import { ListParticipantsCampaignScreen } from './parts/list.participants.campaign.screen';
import { useSuccessToast } from '@hooks/successToast/successToast.hook';
import { hasPermissionUtilty } from '@utils/permission/has.permission.utility';
import Workspace from '@utils/workspace/getWorkspace.utility';
import { CreateContactForm } from './parts/form/create.contact.form';
import { CreateOvoBankContactForm } from './parts/form/create.ovoBank.contact.form';

export function UpdateCampaignScreen(properties: {
  navigation: NavigationProp<CampaignsRoutes>;
  route: RouteProp<CampaignsRoutes, 'campaignUpdate'>;
}) {
  const { showSuccess } = useSuccessToast();

  const contactForm = (
    settings:
      | {
          setIsEditing: (isEditing: boolean) => void;
        }
      | undefined
  ) =>
    Workspace.select({
      ovobank: (
        <CreateOvoBankContactForm
          campaignId={properties.route.params.id}
          navigation={properties.navigation}
          settings={settings}
        />
      ),
      ovoclinic: (
        <CreateContactForm
          campaignId={properties.route.params.id}
          navigation={properties.navigation}
          settings={settings}
        />
      ),
    });

  return (
    <ModalComponent icon={CrmSections.campaigns.icon} prefix="crm.campaigns.update">
      <TabsComponent
        layout="horizontal"
        prefix="crm.campaigns.update"
        tabs={[
          {
            name: 'general',
            icon: 'bullhorn',
            content: (settings) => {
              return (
                <FormComponent<CampaignCrmType>
                  prefix="crm.campaigns.update.form"
                  readonly={!hasPermissionUtilty({ name: 'crm.campaigns.editor' })}
                  repository={{
                    send: (data) => {
                      return new CampaignCrmRepository()
                        .update({
                          item: {
                            id: properties.route.params.id,
                            ...data.item,
                          },
                        })
                        .then(() => {
                          settings?.setIsEditing(false);
                          showSuccess();
                        });
                    },
                    get: () => {
                      return new CampaignCrmRepository().pick({
                        id: properties.route.params.id,
                      });
                    },
                  }}
                  onChange={(data) => {
                    if (!data.isLocal) return;
                    settings?.setIsEditing(true);
                  }}
                  fields={[
                    {
                      type: 'image',
                      name: 'image',
                      description: true,
                    },
                    {
                      type: 'text',
                      name: 'name',
                      description: true,
                      validations: ['title'],
                      required: true,
                    },
                    {
                      type: 'text',
                      name: 'facebookId',
                      description: true,
                      validations: ['title'],
                    },
                    {
                      type: 'text',
                      name: 'code',
                      description: true,
                      validations: ['code'],
                      required: true,
                    },
                    {
                      type: 'select',
                      name: 'funnelId',
                      required: true,
                      pick: (properties: { id: string }) => {
                        return new FunnelCrmRepository().pickForCombo({ id: properties.id });
                      },
                      repository: ({ search }) => {
                        return new FunnelCrmRepository().listForCombo({
                          search,
                          active: true,
                        });
                      },
                    },
                    {
                      type: 'select',
                      name: 'languageId',
                      description: true,
                      required: true,
                      pick: (properties: { id: string }) => {
                        return new LanguageAccessRepository().pickForCombo({
                          languageId: properties.id,
                        });
                      },
                      repository: ({ search }) => {
                        return new LanguageAccessRepository().listForCombo({
                          search,
                          active: true,
                        });
                      },
                    },
                    {
                      type: 'date',
                      name: 'startDate',
                      description: true,
                      required: true,
                      format: 'YYYY-MM-DD',
                    },
                    {
                      type: 'date',
                      name: 'endDate',
                      description: true,
                      format: 'YYYY-MM-DD',
                      nullable: true,
                    },
                    {
                      type: 'select',
                      name: 'firstActivityId',
                      description: true,
                      nullable: true,
                      pick: (properties: { id: string }) => {
                        return new ActivityCrmRepository().pickForCombo({
                          activityId: properties.id,
                        });
                      },
                      repository: ({ search }) =>
                        new ActivityCrmRepository().listForCombo({
                          search,
                          active: true,
                        }),
                    },
                  ]}
                />
              );
            },
          },

          FinancingCampaignPart({
            campaignId: properties.route.params.id,
            navigation: properties.navigation,
            route: properties.route,
          }),
          ListParticipantsCampaignScreen({
            navigation: properties.navigation,
            route: properties.route,
            showSuccess,
          }),
          {
            name: 'managers',
            icon: 'users',
            content: () => (
              <ManagerCampaingUpdatePart
                navigation={properties.navigation}
                route={properties.route}
              />
            ),
          },
          {
            name: 'contact',
            icon: 'user-plus',
            content: (settings) => contactForm(settings),
          },
          {
            name: 'webhook',
            icon: 'link',
            content: () => (
              <WebhookCampaignScreen navigation={properties.navigation} route={properties.route} />
            ),
          },
        ]}
      />
    </ModalComponent>
  );
}
