import { NavigationProp, RouteProp } from '@react-navigation/native';
import { DepartmentsRoutes } from '../../departments.routes';
import { FormComponent } from '@components/form/form.component';
import { ModalComponent } from '@components/modal/modal.component';
import { AccessSections } from '@human/access/access.sections';
import { DepartmentAccessRepository } from '@human/access/repositories/department/department.repository';
import { DepartmentAccessType } from '@human/access/types/department.access.type';
import { LanguageAccessRepository } from '@human/access/repositories/language/language.repository';
import { CoworkerCrmRepository } from '@human/crm/repositories/coworker/coworker.repository';
import { TabsComponent } from '@components/tabs/tabs.component';
import { ListDepartmentMemberPart } from './parts/list.department.member.part';
import { useSuccessToast } from '@hooks/successToast/successToast.hook';
import { hasPermissionUtilty } from '@utils/permission/has.permission.utility';

export function UpdateDepartmentScreen(properties: {
  navigation: NavigationProp<DepartmentsRoutes>;
  route: RouteProp<DepartmentsRoutes, 'departmentUpdate'>;
}) {
  const { showSuccess } = useSuccessToast();

  return (
    <ModalComponent
      icon={AccessSections.departments.icon}
      flex={false}
      prefix="access.departments.update"
      level={properties.route.params.level}
    >
      <TabsComponent
        prefix="access.departments.update"
        layout="horizontal"
        tabs={[
          {
            icon: AccessSections.departments.icon,
            name: 'department',
            content: (settings) => (
              <FormComponent<DepartmentAccessType>
                key={'department'}
                onChange={(data) => {
                  if (!data.isLocal) return;
                  settings?.setIsEditing(true);
                }}
                readonly={!hasPermissionUtilty({ name: 'access.departments.editor' })}
                prefix="access.departments.update.form"
                repository={{
                  get: () => {
                    return new DepartmentAccessRepository().pick({
                      id: properties.route.params.id,
                      clinicId: properties.route.params.clinicId,
                    });
                  },
                  send: (data) => {
                    return new DepartmentAccessRepository()
                      .update({
                        item: data.item,
                      })
                      .then(() => {
                        settings?.setIsEditing(false);
                        showSuccess();
                      });
                  },
                }}
                fields={[
                  {
                    type: 'text',
                    name: 'name',
                    description: true,
                    required: true,
                    validations: ['name'],
                  },
                  {
                    type: 'select',
                    name: 'languageId',
                    description: true,
                    required: true,
                    pick: (properties: { id: string }) => {
                      return new LanguageAccessRepository().pickForCombo({
                        languageId: properties.id,
                      });
                    },
                    repository: ({ search }) =>
                      new LanguageAccessRepository().listForCombo({
                        search,
                        active: true,
                      }),
                  },
                  {
                    type: 'select',
                    name: 'responsibleId',
                    description: true,
                    required: true,
                    pick: (settings: { id: string }) => {
                      return new CoworkerCrmRepository().pickForCombo({
                        clinicId: properties.route.params.clinicId,
                        coworkerId: settings.id,
                      });
                    },
                    repository: ({ search }) =>
                      new CoworkerCrmRepository().listForCombo({
                        clinicId: properties.route.params.clinicId,
                        search,
                        active: true,
                      }),
                  },
                ]}
              />
            ),
          },
          {
            icon: 'users',
            name: 'members',
            content: () => (
              <ListDepartmentMemberPart
                navigation={properties.navigation}
                route={properties.route}
              />
            ),
          },
        ]}
      />
    </ModalComponent>
  );
}
