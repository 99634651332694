import { RoleAccessType } from '@human/access/types/role.access.type';
import { AuthorizedRepository } from '@core/repository/authorized.repository.core';

export class RoleAccessRepository extends AuthorizedRepository {
  async list(properties: {
    groupId: string;
    search?: string;
    page: number;
    limit: number;
    active?: boolean;
  }) {
    return this.fetch('role/find', {
      method: 'GET',
      params: properties,
    }).then((data) => ({
      total: data.total,
      items: data.items.map((role: any) => ({
        id: role.id,
        name: role.name,
        created: new Date(role.created),
        status: role.status,
        licenses: role.licenses,
      })),
    }));
  }

  async listForCombo(properties: { search?: string; active?: boolean }): Promise<
    {
      label: string;
      value: string;
    }[]
  > {
    return this.fetch('role/findForCombo', {
      method: 'GET',
      params: properties,
    });
  }

  async create(properties: { item: Partial<RoleAccessType> }) {
    return this.fetch('role/create', {
      method: 'POST',
      body: properties.item,
    }).then((role) => role);
  }

  async pick(properties: { id: string; groupId: string }) {
    return this.fetch('role/pick', {
      method: 'GET',
      params: properties,
    }).then((role) => role);
  }

  async pickForCombo(properties: { id: string }) {
    return this.fetch('role/pickForCombo', {
      method: 'GET',
      params: properties,
    });
  }

  async update(properties: { item: Partial<RoleAccessType> }) {
    return this.fetch('role/update', {
      method: 'PUT',
      body: properties.item,
    });
  }

  async delete(properties: { id: string }) {
    return this.fetch('role/delete', {
      method: 'DELETE',
      params: properties,
    });
  }

  async disable(properties: { roleId: string }) {
    return this.fetch('role/disable', {
      method: 'PUT',
      params: properties,
    });
  }

  async enable(properties: { roleId: string }) {
    return this.fetch('role/enable', {
      method: 'PUT',
      params: properties,
    });
  }
}
