import { FormComponent } from '@components/form/form.component';
import { ModalComponent } from '@components/modal/modal.component';
import { NavigationProp, RouteProp } from '@react-navigation/native';
import { PhaseCrmType } from '@human/crm/types/phase.crm.type';
import { PhaseCrmRepository } from '@human/crm/repositories/phase/phase.repository';
import { TabsComponent } from '@components/tabs/tabs.component';
import { CrmSections } from '@human/crm/crm.sections';
import { UpdatePhaseCausesPart } from './parts/update.phase.causes.part';
import { PhasesRoutes } from '../../phase.routes';
import { useSuccessToast } from '@hooks/successToast/successToast.hook';
import { hasPermissionUtilty } from '@utils/permission/has.permission.utility';
export function UpdatePhaseScreen(properties: {
  navigation: NavigationProp<PhasesRoutes>;

  route: RouteProp<PhasesRoutes, 'update'>;
}) {
  const { showSuccess } = useSuccessToast();
  return (
    <ModalComponent icon={CrmSections.phases.icon} flex={false} prefix="crm.phases.update">
      <TabsComponent
        layout="horizontal"
        prefix="crm.phases.update"
        tabs={[
          {
            name: 'phase',
            icon: CrmSections.activities.icon,
            content: (settings) => {
              return (
                <FormComponent<PhaseCrmType>
                  prefix="crm.phases.update.form"
                  readonly={!hasPermissionUtilty({ name: 'crm.phases.editor' })}
                  onChange={(data) => {
                    if (!data.isLocal) return;
                    settings?.setIsEditing(true);
                  }}
                  repository={{
                    get: () => {
                      return new PhaseCrmRepository().pick({
                        phaseId: properties.route.params.phaseId,
                      });
                    },
                    send: (data) => {
                      return new PhaseCrmRepository()
                        .update({
                          phaseId: properties.route.params.phaseId,
                          item: data.item,
                        })
                        .then(() => {
                          settings?.setIsEditing(false);
                          showSuccess();
                        });
                    },
                  }}
                  fields={[
                    {
                      name: 'name',
                      type: 'text',
                      description: true,
                      required: true,
                      validations: ['title'],
                    },
                    {
                      name: 'icon',
                      type: 'icon',
                      description: true,
                      required: true,
                    },
                    {
                      name: 'description',
                      type: 'text',
                      description: true,
                      required: true,
                      lines: 6,
                      validations: ['description'],
                    },
                    {
                      name: 'color',
                      type: 'color',
                    },
                  ]}
                />
              );
            },
          },
          UpdatePhaseCausesPart(properties),
        ]}
      />
    </ModalComponent>
  );
}
