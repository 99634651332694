import { IconComponent } from '@components/icon/icon.component';
import { TouchableOpacity, View } from 'react-native';
import { TextInput } from 'react-native-gesture-handler';
import { SearchStyles } from './search.styles';
import { SearchProperties } from './search.properties';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export function SearchComponent(properties: SearchProperties) {
  const { t } = useTranslation();

  const [value, setValue] = useState<string>();

  const handleSearch = () => {
    if (!value) return;
    setValue('');
    properties.onChange(value);
  };
  return (
    <View>
      <IconComponent
        name="search"
        containerstyle={SearchStyles.iconContainer}
        iconStyle={SearchStyles.icon}
      />
      <TextInput
        value={value}
        onChangeText={(text) => setValue(text)}
        placeholder={t('common.search.placeholder') ?? 'Search'}
        placeholderTextColor={'rgba(0, 0, 0, 0.4)'}
        onKeyPress={(e) => {
          if (e.nativeEvent.key === 'Enter') {
            handleSearch();
          }
        }}
        style={SearchStyles.input}
      />
      <TouchableOpacity style={SearchStyles.submitSearchContainer} onPress={handleSearch}>
        <IconComponent name="level-up-alt" iconStyle={SearchStyles.submitText} />
      </TouchableOpacity>
    </View>
  );
}
