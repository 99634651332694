import { NavigationProp, RouteProp } from '@react-navigation/native';
import { FormComponent } from '@components/form/form.component';
import { ModalComponent } from '@components/modal/modal.component';
import { AccessSections } from '@human/access/access.sections';
import { BulletinAccessType } from '@human/access/types/bulletin.access.type';
import { BulletinsRoutes } from '../../bulletins.routes';
import { BulletinAccesRepository } from '@human/access/repositories/bulletin/bulletin.repository';
import { ClinicAccessRepository } from '@human/access/repositories/clinic/clinic.repository';
import { hasPermissionUtilty } from '@utils/permission/has.permission.utility';

export function UpdateBulletinScreen(properties: {
  navigation: NavigationProp<BulletinsRoutes>;
  route: RouteProp<BulletinsRoutes, 'update'>;
}) {
  return (
    <ModalComponent icon={AccessSections.bulletins.icon} prefix="access.bulletins.update">
      <FormComponent<BulletinAccessType>
        padding
        prefix="access.bulletins.update.form"
        readonly={!hasPermissionUtilty({ name: 'access.bulletins.editor' })}
        repository={{
          //   send: (properties: { item: Partial<CountryAccessType> }) => {
          //     return new BulletinAccesRepository().update({
          //       item: properties.item,
          //     });
          //   },
          get: () => {
            return new BulletinAccesRepository().pick({
              bulletinId: properties.route.params.bulletinId,
              clinicId: properties.route.params.clinicId,
            });
          },
        }}
        fields={[
          {
            type: 'select',
            name: 'clinicId',
            description: true,
            pick: (properties: { id: string }) => {
              return new ClinicAccessRepository().pickForCombo({ id: properties.id });
            },
            repository: ({ search }) => {
              return new ClinicAccessRepository().listForCombo({
                search,
                active: true,
              });
            },
          },
          {
            type: 'pair',
            fields: [
              {
                type: 'text',
                name: 'actorName',
                required: true,
                description: true,
              },
              {
                type: 'text',
                name: 'authorName',
                required: true,
                description: true,
              },
            ],
          },
          {
            type: 'text',
            name: 'description',
            description: true,
            required: true,
            lines: 6,
          },
          {
            type: 'image',
            name: 'image',
            description: true,
          },
        ]}
      />
    </ModalComponent>
  );
}
