import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ModalComponent } from '@components/modal/modal.component';
import { FormComponent } from '@components/form/form.component';
import { TimetableAccessRepository } from '@human/access/repositories/timetable/timetable.repository';
import { TimetableAccessType } from '@human/access/types/timetable.access.type';
import { TimetableRoutes } from '../../timetables.routes';
import { TabsComponent } from '@components/tabs/tabs.component';
import { TimetableDayUpdateTimetablePart } from './parts/timetableDay.update.timetable.part';
import { useSuccessToast } from '@hooks/successToast/successToast.hook';
import { hasPermissionUtilty } from '@utils/permission/has.permission.utility';

export function UpdateTimetableScreen(properties: {
  navigation: NavigationProp<TimetableRoutes>;
  route: RouteProp<TimetableRoutes, 'update'>;
}) {
  const { showSuccess } = useSuccessToast();
  return (
    <ModalComponent icon="lock" prefix="access.timetables.update">
      <TabsComponent
        layout="horizontal"
        prefix="access.timetables.update"
        tabs={[
          {
            name: 'timetable',
            icon: 'calendar-times',
            content: (settings) => (
              <FormComponent<TimetableAccessType>
                onChange={(data) => {
                  if (!data.isLocal) return;
                  settings?.setIsEditing(true);
                }}
                readonly={!hasPermissionUtilty({ name: 'access.timetables.editor' })}
                prefix="access.timetables.update.form"
                repository={{
                  send: (params) => {
                    return new TimetableAccessRepository()
                      .update({
                        item: params.item,
                      })
                      .then(() => {
                        settings?.setIsEditing(false);
                        showSuccess();
                      });
                  },
                  get: async () => {
                    return new TimetableAccessRepository().pick({
                      timetableId: properties.route.params.timetableId,
                    });
                  },
                }}
                fields={[
                  {
                    name: 'name',
                    type: 'text',
                    required: true,
                    description: true,
                    validations: ['name'],
                  },
                ]}
              />
            ),
          },
          TimetableDayUpdateTimetablePart(properties),
        ]}
      />
    </ModalComponent>
  );
}
