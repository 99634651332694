import { NavigationProp, RouteProp } from '@react-navigation/native';
import { SceneComponent } from '@components/scene/scene.component';
import { CrmSections } from '@human/crm/crm.sections';
import { MaintenanceCrmType } from '@human/crm/types/maintenance.crm.type';
import { TableComponent } from '@components/table/table.component';
import { MaintenanceCrmRepository } from '@human/crm/repositories/maintenance/maintenance.repository';
import { MaintenancesRoutes } from '../../maintenance.routes';
import { useTranslation } from 'react-i18next';
import { useDeviceHook } from '@hooks/device/device.hook';
import { ColumnProperties } from '@components/table/components/columns/column.properties';
import { hasPermissionUtilty } from '@utils/permission/has.permission.utility';

export function ListMaintenancesScreen(properties: {
  navigation: NavigationProp<MaintenancesRoutes>;
  route: RouteProp<MaintenancesRoutes, 'listMaintenance'>;
}) {
  const { t } = useTranslation();

  const { desktop } = useDeviceHook();

  const columns: ColumnProperties<MaintenanceCrmType, []>[] = [
    {
      type: 'icon',
      icon: hasPermissionUtilty({ name: 'crm.maintenances.editor' }) ? 'pencil-alt' : 'eye',
      onPress: (row) => {
        properties.navigation.navigate('updateMaintenance', {
          maintenanceId: row.maintenanceId,
          clinicId: row.clinicId,
          level: 1,
          patientId: row.patientId,
          treatmentId: row.treatmentId,
        });
      },
    },
    {
      type: 'text',
      name: 'serviceName',
      onRender: (row) => row.serviceName,
      width: 200,
    },
    {
      type: 'clinic',
      name: 'clinicName',
      width: 200,
      onRender: (row) => row.clinicName,
      color: (row) => row.clinicColor,
    },
    {
      type: 'text',
      name: 'patientName',
      onRender: (row) => `${row.contactName} ${row.contactSurname}`,
      width: 200,
    },
    {
      name: 'amount',
      type: 'price',
      width: 140,
      onRender: (row) => {
        return row.amount;
      },
      onCurrency: (row) => {
        return '€';
      },
    },
    {
      type: 'text',
      name: 'paymentStatus',
      onRender: (row) => t(`common.paymentStatus.${row.paymentStatus}`),
      width: 100,
    },
    {
      type: 'date',
      name: 'estimatedPaymentDate',
      renderDate: (row) => row.estimatedPaymentDate,
    },
  ];

  if (hasPermissionUtilty({ name: 'crm.maintenances.editor' })) {
    columns.unshift({
      type: 'flag',
      status: (row) => row.status,
      onPress: (row) => {
        properties.navigation.navigate(row.status ? 'disableMaintenance' : 'enableMaintenance', {
          maintenanceId: row.maintenanceId,
          clinicId: row.clinicId,
          patientId: row.patientId,
          treatmentId: row.treatmentId,
        });
      },
    });
  }

  return (
    <SceneComponent
      multiWorkspace
      icon={CrmSections.maintenances.icon}
      prefix={'crm.maintenances.list'}
    >
      <TableComponent<MaintenanceCrmType, []>
        prefix={'crm.maintenances.list'}
        suffix={'list'}
        multiWorkspace
        repository={(settings) =>
          new MaintenanceCrmRepository().list({
            direction: settings.direction,
            page: settings.page,
            limit: settings.limit,
            search: settings.search,
          })
        }
        columns={columns}
      />
    </SceneComponent>
  );
}
