import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ModalComponent } from '@components/modal/modal.component';
import { FormComponent } from '@components/form/form.component';
import { CampaignsRoutes } from '../../campaigns.routes';
import { CampaignCrmRepository } from '@human/crm/repositories/campaign/campaign.repository';
import { useTranslation } from 'react-i18next';
import { useSuccessToast } from '@hooks/successToast/successToast.hook';
import { useErrorToast } from '@hooks/errorToast/errorToast.hook';

export function DeleteCampaignScreen(properties: {
  navigation: NavigationProp<CampaignsRoutes>;
  route: RouteProp<CampaignsRoutes, 'campaignDelete'>;
}) {
  const { t } = useTranslation();
  const { showSuccess } = useSuccessToast();
  const { showError } = useErrorToast();
  return (
    <ModalComponent icon="trash-alt" prefix="crm.campaigns.delete">
      <FormComponent
        prefix="crm.campaigns.delete.form"
        padding
        repository={{
          send: async () => {
            if (!confirm(t('crm.campaigns.delete.form.confirm') || '')) return;
            return new CampaignCrmRepository()
              .delete({
                campaignId: properties.route.params.id,
              })
              .then(() => {
                showSuccess(t('crm.campaigns.delete.form.success') || '');
                properties.navigation.goBack();
              })
              .catch(() => {
                showError(t('crm.campaigns.delete.form.error') || '');
              });
          },
        }}
        fields={[]}
      />
    </ModalComponent>
  );
}
