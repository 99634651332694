import React from 'react';
import { TextComponent } from '@components/text/text.component';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { WebhookCampaignTabStyles } from './webhookInput.styles';
import { CopyToClipboard } from '@components/copyToClipboard/copy.to.clipboard.component';

export function WebhookInputComponent(properties: {
  apiUrl: string;
  label: string;
  subtitle: string;
}) {
  const { t } = useTranslation();

  return (
    <>
      <View style={WebhookCampaignTabStyles.labelContainer}>
        <TextComponent translate text={properties.label} style={WebhookCampaignTabStyles.label} />
        <TextComponent
          translate
          text={properties.subtitle}
          style={WebhookCampaignTabStyles.subtitle}
        />
      </View>
      <CopyToClipboard
        value={`${properties.apiUrl}`}
        toastText={t('crm.campaigns.update.tabs.webhook.toast') || 'copied'}
      />
    </>
  );
}
