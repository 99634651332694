import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ModalComponent } from '@components/modal/modal.component';
import { FormComponent } from '@components/form/form.component';

import { DepartmentsRoutes } from '../../departments.routes';
import { DepartmentAccessRepository } from '@human/access/repositories/department/department.repository';

export function EnableDepartmentScreen(properties: {
  navigation: NavigationProp<DepartmentsRoutes>;
  route: RouteProp<DepartmentsRoutes, 'departmentEnable'>;
}) {
  return (
    <ModalComponent icon="trash-alt" prefix="access.departments.enable">
      <FormComponent
        prefix="access.departments.enable.form"
        padding
        repository={{
          send: () => {
            return new DepartmentAccessRepository()
              .enable({
                departmentId: properties.route.params.id,
                clinicId: properties.route.params.clinicId,
              })
              .then(() => {
                properties.navigation.goBack();
              });
          },
        }}
        fields={[]}
      />
    </ModalComponent>
  );
}
