import { CSSProperties } from 'react';

export const CalendarStyles: CSSProperties = {
  flex: 1,
  fontFamily: 'Lato-Bold, sans-serif',
};

export const CalendarStylesLoading: CSSProperties = {
  ...CalendarStyles,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};
