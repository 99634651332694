import { ButtonComponent } from '@components/button/button.component';
import { TableComponent } from '@components/table/table.component';
import { TabType } from '@components/tabs/tabs.types';
import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ScrollView } from 'react-native-gesture-handler';
import { TemplatesRoutes } from '../../../templates.routes';
import { TemplateScopeAccessRepository } from '@human/access/repositories/template/templateScope/templateScope.repository';

export function TemplateScopeUpdatePart(properties: {
  navigation: NavigationProp<TemplatesRoutes>;
  route: RouteProp<TemplatesRoutes, 'update'>;
}): TabType {
  return {
    name: 'templateScope',
    icon: 'file-alt',
    content: () => {
      return (
        <ScrollView>
          <TableComponent<
            {
              clinicId: string;
              departmentId: string;
              departmentName: string;
            },
            []
          >
            prefix={'access.templates.templateScopes.list'}
            suffix={'list'}
            hideNoDataImage
            header={false}
            repository={(settings) =>
              new TemplateScopeAccessRepository().list({
                templateId: properties.route.params.templateId,
                limit: settings.limit,
                page: settings.page,
                search: settings.search,
              })
            }
            columns={[
              {
                type: 'icon',
                icon: 'trash-alt',
                onPress: (row) => {
                  properties.navigation.navigate('deleteTemplateScope', {
                    level: 1,
                    departmentId: row.departmentId,
                    templateId: properties.route.params.templateId,
                    clinicId: row.clinicId,
                  });
                },
              },
              {
                type: 'text',
                width: 200,
                onRender: (row) => row.departmentName,
              },
            ]}
          />
          <ButtonComponent
            icon="plus"
            prefix="access.templates.templateScopes.add"
            onPress={() => {
              properties.navigation.navigate('createTemplateScope', {
                level: 1,
                templateId: properties.route.params.templateId,
              });
            }}
          />
        </ScrollView>
      );
    },
  };
}
