import { Text, View } from 'react-native';
import { inactiveLeadsReasonTableRowStyles } from './inactiveLeadsReasonTableRow.styles';

export function InactiveLeadsReasonTableRowComponent(properties: {
  selectedYear: number;
  item: {
    reason_name: string;
    totals: {
      total: number;
      month: number;
    }[];
  };
}) {
  return (
    <View style={inactiveLeadsReasonTableRowStyles.container}>
      <View style={inactiveLeadsReasonTableRowStyles.reasonContainer}>
        <Text style={inactiveLeadsReasonTableRowStyles.reasonText}>
          {properties.item.reason_name}
        </Text>
      </View>
      <View style={inactiveLeadsReasonTableRowStyles.columnsContainer}>
        {properties.item.totals.map((total, index) => (
          <View style={inactiveLeadsReasonTableRowStyles.columnContainer}>
            <Text
              style={[
                index > new Date().getMonth() &&
                  properties.selectedYear === new Date().getFullYear() &&
                  inactiveLeadsReasonTableRowStyles.futureText,
              ]}
            >
              {total.total}
            </Text>
          </View>
        ))}
      </View>
    </View>
  );
}
