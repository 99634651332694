import { NavigationProp, RouteProp } from '@react-navigation/native';
import { TabType } from '@components/tabs/tabs.types';
import { TableComponent } from '@components/table/table.component';
import { MovementAccessType } from '@human/access/types/movement.access.type';
import { MovementAccessRepository } from '@human/access/repositories/movement/movement.repository';
import { UsersRoutes } from '../../../users.routes';
import { ScrollView } from 'react-native-gesture-handler';

export function MovementsUpdateUserPart(properties: {
  navigation: NavigationProp<UsersRoutes>;
  route: RouteProp<UsersRoutes, 'update'>;
}): TabType {
  return {
    name: 'movements',
    icon: 'exchange-alt',
    content: () => {
      return (
        <ScrollView>
          <TableComponent<MovementAccessType, []>
            key={'movements'}
            prefix={'access.movements.list'}
            suffix={'list'}
            header={false}
            search={false}
            hideNoDataImage
            scrollable={true}
            repository={(settings) =>
              new MovementAccessRepository().list({
                userId: properties.route.params.userId,
                page: settings.page,
                limit: settings.limit,
              })
            }
            columns={[
              {
                type: 'text',
                width: 70,
                color: (row) =>
                  row.method === 'GET'
                    ? 'green'
                    : row.method === 'POST'
                      ? 'blue'
                      : row.method === 'PUT'
                        ? 'orange'
                        : 'red',
                background: (row) =>
                  row.method === 'GET'
                    ? 'rgba(0,255,0,0.1)'
                    : row.method === 'POST'
                      ? 'rgba(0,0,255,0.1)'
                      : row.method === 'PUT'
                        ? 'rgba(255,165,0,0.1)'
                        : 'rgba(255,0,0,0.1)',
                onRender: (row) => row.method,
              },
              {
                type: 'text',
                width: 160,
                onRender: (row) => row.path,
              },
              {
                type: 'text',
                width: 160,
                onRender: (row) => row.sessionId,
              },
              {
                type: 'date',
                renderDate: (row) => row.created,
              },
            ]}
          />
        </ScrollView>
      );
    },
  };
}
