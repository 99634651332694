import { IconComponent } from '@components/icon/icon.component';
import { TouchableWithoutFeedback, View } from 'react-native';
import { PeriodStyles } from './period.styles';
import { PeriodProperties } from './period.properties';
import { useEffect, useState } from 'react';
import { SlicerComponent } from './components/slicer/slicer.component.web';
import { TextComponent } from '@components/text/text.component';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { useDeviceHook } from '@hooks/device/device.hook';

export function PeriodComponent(properties: PeriodProperties) {
  const [calendar, setCalendar] = useState<boolean>(false);
  const [focus, setFocus] = useState<boolean>(false);

  const { phone, tablet } = useDeviceHook();

  return (
    <>
      <View style={PeriodStyles.calendarContainer}>
        <View style={PeriodStyles.calendarContentRow}>
          <View style={PeriodStyles.dateContainer}>
            <TouchableOpacity
              onPress={() => {
                setFocus(!focus);
                setCalendar(!calendar);
              }}
            >
              <View style={PeriodStyles.calendarContentRow}>
                <IconComponent
                  name="calendar"
                  containerstyle={PeriodStyles.calendarIcon}
                  iconStyle={[PeriodStyles.icon]}
                />
                {!phone && !tablet && (
                  <>
                    <View style={PeriodStyles.calendarContentColumn}>
                      {properties.datesFields.startDate && properties.datesFields.endDate ? (
                        <View style={PeriodStyles.calendarDates}>
                          <TextComponent
                            style={PeriodStyles.textDates}
                            text={properties.datesFields.startDate.toLocaleDateString() + ' - '}
                            translate        
                          />
                          <TextComponent
                            style={PeriodStyles.textDates}
                            text={properties.datesFields.endDate.toLocaleDateString()}
                            translate
                          />
                        </View>
                      ) : (
                        <TextComponent
                          style={PeriodStyles.textDates}
                          text={'form.date.placeholder'}
                          translate
                        />
                      )}
                    </View>
                    <IconComponent
                      name={focus ? 'angle-up' : 'angle-down'}
                      containerstyle={PeriodStyles.calendarIcon}
                      iconStyle={[PeriodStyles.expandIcon]}
                    />
                  </>
                )}
              </View>
            </TouchableOpacity>
          </View>
        </View>
      </View>
      {calendar ? (
        <View style={PeriodStyles.pickerContainer}>
          <TouchableWithoutFeedback onPress={() => setCalendar(false)}>
            <SlicerComponent
              onClose={() => {
                setCalendar(false);
                setFocus(false);
              }}
              onDateChange={(props) => {
                setFocus(false);
                setCalendar(false);
                properties.onDateChange &&
                  properties.onDateChange({ endDate: props.end, startDate: props.start });
              }}
            />
          </TouchableWithoutFeedback>
        </View>
      ) : null}
    </>
  );
}
