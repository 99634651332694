import { View } from 'react-native';
import { IconComponent } from '../../../../../icon/icon.component';
import { BooleanColumnImplementation } from './boolean.column.properties';
import { BooleanColumnStyles } from './boolean.column.styles';

export function BooleanColumnComponent<T, R>(properties: BooleanColumnImplementation<T, R>) {
  const boolean = properties.renderBoolean(properties.item);

  return (
    <View
      style={[
        BooleanColumnStyles.container,
        boolean ? BooleanColumnStyles.containerBoolean : BooleanColumnStyles.containerNoBoolean,
      ]}
    >
      <IconComponent
        name={properties.icon || boolean ? 'check' : 'times'}
        containerstyle={BooleanColumnStyles.iconContainer}
        iconStyle={[
          BooleanColumnStyles.icon,
          boolean ? BooleanColumnStyles.iconBoolean : BooleanColumnStyles.iconNoBoolean,
        ]}
      />
    </View>
  );
}
