import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ActivitiesRoutes } from '../../activity.routes';
import { ModalComponent } from '@components/modal/modal.component';
import { useState } from 'react';
import { SelectClinicStep } from '@steps/clinic/selectClinic.step';
import { ActionComponent } from '@components/action/action.component';
import { CreateActivityScopeForm } from './create.activity.scope.form';

export function CreateActivityScopeScreen(properties: {
  navigation: NavigationProp<ActivitiesRoutes>;
  route: RouteProp<ActivitiesRoutes, 'createScope'>;
}) {
  const [clinicId, setClinicId] = useState<string>();
  const [clinicName, setClinicName] = useState<string>();

  return (
    <ModalComponent
      icon="tasks"
      level={properties.route.params.level}
      flex={false}
      prefix="crm.scopes.create"
    >
      {!clinicId ? (
        <SelectClinicStep
          clinicId={clinicId}
          onSuccess={({ clinicId, clinicName }) => {
            setClinicId(clinicId);
            setClinicName(clinicName);
          }}
        />
      ) : (
        <ActionComponent
          onPress={() => {
            setClinicId(undefined);
            setClinicId(undefined);
          }}
          prefix="crm.scopes.create.form.clinicId.title"
          bubble={clinicName}
        />
      )}
      {clinicId && (
        <CreateActivityScopeForm
          clinicId={clinicId}
          activityId={properties.route.params.activityId}
          navigation={properties.navigation}
        />
      )}
    </ModalComponent>
  );
}
