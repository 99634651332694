import { TabType } from '@components/tabs/tabs.types';
import { CrmSections } from '@human/crm/crm.sections';
import { NavigationProp, RouteProp } from '@react-navigation/native';
import { TableComponent } from '@components/table/table.component';
import { ButtonComponent } from '@components/button/button.component';
import { IconType } from '@components/icon/icon.types';
import { PhasesRoutes } from '../../../phase.routes';
import { CauseCrmRepository } from '@human/crm/repositories/cause/cause.repository';
import { CauseCrmType } from '@human/crm/types/cause.crm.type';
import { ScrollView } from 'react-native';

export function UpdatePhaseCausesPart(properties: {
  navigation: NavigationProp<PhasesRoutes>;
  route: RouteProp<PhasesRoutes, 'update'>;
}): TabType {
  return {
    name: 'causes',
    icon: CrmSections.causes.icon,
    content: () => (
      <ScrollView>
        <TableComponent<CauseCrmType, []>
          key={'causes'}
          prefix={'crm.phases.update.tabs.causes'}
          header={false}
          suffix="list"
          hideNoDataImage
          repository={(settings) =>
            new CauseCrmRepository().listByPhase({
              phaseId: properties.route.params.phaseId,
              limit: settings.limit,
              page: settings.page,
              search: settings.search,
            })
          }
          columns={[
            {
              type: 'icon',
              icon: 'pen',
              onPress: (row) => {
                properties.navigation.navigate('causeUpdate', {
                  causeId: row.id,
                  phaseId: row.phaseId,
                  level: 1,
                });
              },
            },
            {
              type: 'iconText',
              name: 'name',
              color: (row) => row.color,
              text: (row) => row.name,
              icon: (row) => row.icon as IconType,
            },
          ]}
        />
        <ButtonComponent
          icon="plus"
          prefix="crm.phases.update.causes.add"
          onPress={() => {
            properties.navigation.navigate('causeCreate', {
              phaseId: properties.route.params.phaseId,
              level: 1,
            });
          }}
        />
      </ScrollView>
    ),
  };
}
