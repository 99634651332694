import { TabType } from '@components/tabs/tabs.types';
import { NavigationProp, RouteProp } from '@react-navigation/native';
import { TableComponent } from '@components/table/table.component';
import { ButtonComponent } from '@components/button/button.component';
import { TreatmentsRoutes } from '../../../treatment.routes';
import { MaintenanceCrmRepository } from '@human/crm/repositories/maintenance/maintenance.repository';
import { CrmSections } from '@human/crm/crm.sections';
import { MaintenanceCrmType } from '@human/crm/types/maintenance.crm.type';
import { useTranslation } from 'react-i18next';
import { hasPermissionUtilty } from '@utils/permission/has.permission.utility';
import { ColumnProperties } from '@components/table/components/columns/column.properties';
import { ScrollView } from 'react-native';

export function UpdateTreatmentMaintenancesPart(properties: {
  navigation: NavigationProp<TreatmentsRoutes>;
  route: RouteProp<TreatmentsRoutes, 'updateTreatment'>;
}): TabType {
  const { t } = useTranslation();

  const columns: ColumnProperties<MaintenanceCrmType, []>[] = [
    {
      type: 'icon',
      icon: 'pencil-alt',
      onPress: (row) => {
        properties.navigation.navigate('updateMaintenance', {
          clinicId: properties.route.params.clinicId,
          maintenanceId: row.maintenanceId,
          patientId: properties.route.params.patientId,
          treatmentId: properties.route.params.treatmentId,
          level: properties.route.params.level + 1,
        });
      },
    },
    {
      type: 'text',
      name: 'serviceName',
      onRender: (row) => row.serviceName,
      width: 200,
    },
    {
      type: 'number',
      name: 'amount',
      onRender: (row) => row.amount,
      width: 100,
    },
    {
      type: 'text',
      name: 'paymentStatus',
      onRender: (row) => t(`common.paymentStatus.${row.paymentStatus}`),
      width: 100,
    },
    {
      type: 'date',
      name: 'estimatedPaymentDate',
      renderDate: (row) => row.estimatedPaymentDate,
    },
  ];

  if (hasPermissionUtilty({ name: 'crm.maintenances.editor' })) {
    columns.unshift({
      type: 'flag',
      status: (row) => row.status,
      onPress: (row) => {
        properties.navigation.navigate(row.status ? 'disableMaintenance' : 'enableMaintenance', {
          clinicId: properties.route.params.clinicId,
          patientId: properties.route.params.patientId,
          treatmentId: properties.route.params.treatmentId,
          maintenanceId: row.maintenanceId,
        });
      },
    });
  }

  return {
    name: 'maintenances',
    icon: CrmSections.maintenances.icon,
    content: () => (
      <ScrollView>
        <TableComponent<MaintenanceCrmType, []>
          hideNoDataImage
          prefix={'crm.maintenances.list'}
          suffix={'list'}
          multiWorkspace
          repository={(settings) =>
            new MaintenanceCrmRepository().listByPatient({
              direction: settings.direction,
              page: settings.page,
              limit: settings.limit,
              search: settings.search,
              clinicId: properties.route.params.clinicId,
              patientId: properties.route.params.patientId,
              treatmentId: properties.route.params.treatmentId,
            })
          }
          columns={columns}
        />
        {hasPermissionUtilty({
          name: 'crm.maintenances.creator',
        }) && (
          <ButtonComponent
            icon="plus"
            prefix="crm.treatments.update.tabs.maintenances.add"
            onPress={() => {
              properties.navigation.navigate('createMaintenance', {
                clinicId: properties.route.params.clinicId,
                patientId: properties.route.params.patientId,
                treatmentId: properties.route.params.treatmentId,
                level: 1,
              });
            }}
          />
        )}
      </ScrollView>
    ),
  };
}
