import { Pressable, View } from 'react-native';
import { TextComponent } from '../../../../../text/text.component';
import { TextColumnImplementation } from './text.column.properties';
import { OrderTableType } from '../../../../table.properties';
import { TextColumnStyles } from './text.column.styles';
import { ItemTableType } from '@components/table/table.component';
import { useTool } from './../../../../../../context/tool/tool.context';
import { useRef } from 'react';

export function TextColumnComponent<ITEM extends ItemTableType, ORDER extends OrderTableType<ITEM>>(
  properties: TextColumnImplementation<ITEM, ORDER>
) {
  const columnRef = useRef<View>(null);
  const { updateTool } = useTool();
  const text = properties.onRender?.(properties.item) ?? properties.name;
  const color = properties.color?.(properties.item);
  const background = properties.background?.(properties.item);
  return (
    <Pressable
      ref={columnRef}
      style={[
        TextColumnStyles.container,
        {
          backgroundColor: background,
        },
      ]}
      onHoverIn={() => {
        columnRef.current &&
          text &&
          columnRef.current.measure((x, y, width, height, pageX, pageY) => {
            updateTool({
              content: text,
              left: pageX + 10,
              top: pageY - height / 2,
            });
          });
      }}
      onHoverOut={() => {
        updateTool();
      }}
    >
      <TextComponent lines={1} bold text={`${text}`} style={[TextColumnStyles.text, { color }]} />
    </Pressable>
  );
}
