import { NavigationProp, RouteProp } from '@react-navigation/native';
import { SceneComponent } from '../../../../../../../../../components/scene/scene.component';
import { TableComponent } from '../../../../../../../../../components/table/table.component';
import { CrmSections } from '@human/crm/crm.sections';
import { TreatmentsRoutes } from '../../treatment.routes';
import { TreatmentCrmRepository } from '@human/crm/repositories/treatment/treatment.repository';
import { ColumnProperties } from '@components/table/components/columns/column.properties';
import { hasPermissionUtilty } from '@utils/permission/has.permission.utility';
import { emptyValue } from '@constants/emptyValue.constant';
import { TreatmentCrmType } from '@human/crm/types/treatment.crm.type';

export function ListTreatmentsScreen(properties: {
  navigation: NavigationProp<TreatmentsRoutes>;
  route: RouteProp<TreatmentsRoutes, 'listTreatment'>;
}) {
  const columns: ColumnProperties<TreatmentCrmType, []>[] = [
    {
      type: 'icon',
      icon: hasPermissionUtilty({ name: 'crm.treatments.editor' }) ? 'pencil-alt' : 'eye',
      onPress: (row) => {
        properties.navigation.navigate('updateTreatment', {
          treatmentId: row.id,
          clinicId: row.clinicId,
          patientId: row.patientId,
          programId: row.programId,
          level: 0,
        });
      },
    },
    {
      type: 'text',
      name: 'cycleName',
      width: 200,
      onRender: (row) => row.cycleName || emptyValue,
    },
    {
      type: 'clinic',
      name: 'clinicName',
      onRender: (row) => `${row.lastCycleClinicName || emptyValue}`,
      color: (row) => row.lastCycleClinicColor,
      width: 200,
    },
    {
      type: 'date',
      name: 'expectedDate',
      renderDate: (row) => row.expectedDate,
    },
    {
      type: 'text',
      name: 'patientName',
      onRender: (row) => `${row.patientName} ${row.patientSurname}`,
      width: 200,
    },
    {
      type: 'text',
      name: 'programName',
      onRender: (row) => `${row.programName}`,
      width: 200,
    },
    {
      type: 'text',
      name: 'phaseName',
      onRender: (row) => `${row.phaseName}`,
      width: 200,
    },
    {
      type: 'date',
      name: 'finished',
      renderDate: (row) => row.finished,
    },
  ];

  if (hasPermissionUtilty({ name: 'crm.treatments.editor' })) {
    columns.unshift({
      type: 'flag',
      status: (row) => row.status,
      onPress: (row) => {
        properties.navigation.navigate(row.status ? 'disableTreatment' : 'enableTreatment', {
          treatmentId: row.id,
          clinicId: row.clinicId,
          patientId: row.patientId,
          level: 0,
        });
      },
    });
  }

  return (
    <SceneComponent
      multiWorkspace
      icon={CrmSections.treatments.icon}
      prefix={'crm.treatments.list'}
    >
      <TableComponent<TreatmentCrmType, []>
        prefix={'crm.treatments.list'}
        suffix={'list'}
        search
        dates
        multiWorkspace
        params={{
          clinicId: properties.route.params?.clinicId,
          departmentId: properties.route.params?.departmentId,
          complexity: properties.route.params?.complexity,
          firstAttempt: properties.route.params?.firstAttempt ? 'true' : undefined,
          loss: properties.route.params?.loss ? 'true' : undefined,
          year: properties.route.params?.year?.toString(),
          month: properties.route.params?.month?.toString(),
        }}
        repository={(settings) => {
          return new TreatmentCrmRepository().list({
            ...settings,
            order: settings.order ?? 'id',
            direction: settings.direction,
            page: settings.page,
            limit: settings.limit,
            search: settings.search,
            dates: settings.dates,
          });
        }}
        columns={columns}
      />
    </SceneComponent>
  );
}
