import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ModalComponent } from '@components/modal/modal.component';
import { FormComponent } from '@components/form/form.component';
import { LeadsRoutes } from '../../leads.routes';
import { LeadCrmRepository } from '@human/crm/repositories/lead/lead.repository';
import { useTranslation } from 'react-i18next';

export function DeleteLeadScreen(properties: {
  navigation: NavigationProp<LeadsRoutes>;
  route: RouteProp<LeadsRoutes, 'delete'>;
}) {
  const { t } = useTranslation();
  return (
    <ModalComponent icon="trash-alt" prefix="crm.leads.delete">
      <FormComponent
        prefix="crm.leads.delete.form"
        padding
        repository={{
          send: async () => {
            if (!confirm(t('crm.leads.delete.form.confirm') || '')) return;
            return new LeadCrmRepository()
              .delete({
                leadId: properties.route.params.id,
                clinicId: properties.route.params.clinicId,
              })
              .then(() => {
                properties.navigation.goBack();
              });
          },
        }}
        fields={[]}
      />
    </ModalComponent>
  );
}
