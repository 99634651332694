import { FormComponent } from '@components/form/form.component';
import { ClinicAccessRepository } from '@human/access/repositories/clinic/clinic.repository';
import { CampaignCrmRepository } from '@human/crm/repositories/campaign/campaign.repository';
import { NavigationProp } from '@react-navigation/native';

import { ContactsRoutes } from '../../../contacts.routes';
import { OvoBankContactCrmRepository } from '@human/crm/repositories/ovobankContact/ovobankContact.repository';
import { useTranslation } from 'react-i18next';

export function OvobankContactForm(properties: { navigation: NavigationProp<ContactsRoutes> }) {
  const { t } = useTranslation();
  return (
    <FormComponent<{
      name: string;
      surname: string;
      email: string;
      prefix: string;
      phone: string;
      zip: string;
      clinicId: string;
      legal: boolean;
      marketing: boolean;
      campaignId: string;
      birthDate?: Date;
      birthCountry?: string;
      weight?: string;
      height?: string;
      donationPreference?: string;
      photo?: File;
    }>
      prefix="crm.contacts.create.form"
      padding
      repository={{
        send: async (data) => {
          return new OvoBankContactCrmRepository()
            .createFromCampaign({
              item: data.item,
            })
            .then(() => {
              properties.navigation.goBack();
            });
        },
        get: async () => ({
          legal: false,
          marketing: false,
        }),
      }}
      fields={[
        {
          type: 'avatar',
          name: 'photo',
          description: true,
        },
        {
          type: 'pair',
          fields: [
            {
              type: 'text',
              name: 'name',
              required: true,
              description: true,
              validations: ['name'],
            },
            {
              type: 'text',
              name: 'surname',
              description: true,
              required: true,
              validations: ['surname'],
            },
          ],
        },

        {
          type: 'pair',
          fields: [
            {
              type: 'text',
              name: 'prefix',
              description: true,
              validations: ['prefix'],
              required: true,
            },
            {
              type: 'text',
              name: 'phone',
              description: true,
              validations: ['phone'],
              required: true,
            },
          ],
        },
        {
          type: 'text',
          name: 'email',
          description: true,
          validations: ['email'],
          required: true,
        },
        {
          type: 'date',
          name: 'birthDate',
          description: true,
          required: true,
          format: 'YYYY-MM-DD',
        },
        {
          type: 'text',
          name: 'birthCountry',
          description: true,
          required: true,
          validations: ['name'],
        },
        {
          type: 'pair',
          fields: [
            {
              type: 'number',
              name: 'height',
              description: true,
              required: true,
              validations: ['positive'],
            },
            {
              type: 'number',
              name: 'weight',
              description: true,
              required: true,
              validations: ['positive'],
            },
          ],
        },

        {
          type: 'text',
          name: 'zip',
          description: true,
          validations: ['zip'],
        },
        {
          type: 'text',
          name: 'donationPreference',
          description: true,
          required: true,
          validations: ['name'],
        },
        {
          type: 'checkbox',
          name: 'marketing',
          description: true,
        },
        {
          type: 'checkbox',
          description: true,
          name: 'legal',
        },
        {
          type: 'select',
          description: true,
          name: 'clinicId',
          repository: ({ search }) => {
            return new ClinicAccessRepository().listForCombo({ search, active: true });
          },
        },
        {
          type: 'select',
          description: true,
          name: 'campaignId',
          repository: ({ search }) => {
            return new CampaignCrmRepository().listForCombo({
              active: true,
              search,
            });
          },
        },
      ]}
    />
  );
}
