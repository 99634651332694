import { NavigationProp, RouteProp } from '@react-navigation/native';
import { SceneComponent } from '@components/scene/scene.component';
import { CrmSections } from '@human/crm/crm.sections';
import { TableComponent } from '@components/table/table.component';
import { CauseRoutes } from '../../cause.routes';
import { CauseCrmType } from '@human/crm/types/cause.crm.type';
import { CauseCrmRepository } from '@human/crm/repositories/cause/cause.repository';
import { IconType } from '@components/icon/icon.types';
import { ColumnProperties } from '@components/table/components/columns/column.properties';
import { hasPermissionUtilty } from '@utils/permission/has.permission.utility';

export function ListCauseScreen(properties: {
  navigation: NavigationProp<CauseRoutes>;
  route: RouteProp<CauseRoutes, 'causeList'>;
}) {
  const columns: ColumnProperties<CauseCrmType, []>[] = [
    {
      type: 'icon',
      icon: hasPermissionUtilty({ name: 'crm.causes.editor' }) ? 'pencil-alt' : 'eye',
      onPress: (row) => {
        properties.navigation.navigate('causeUpdate', {
          causeId: row.id,
          phaseId: row.phaseId,
          level: 0,
        });
      },
    },
    {
      type: 'iconText',
      name: 'name',
      color: (row) => row.color,
      text: (row) => row.name,
      icon: (row) => row.icon as IconType,
    },
    {
      type: 'date',
      name: 'created',
      renderDate: (row) => row.created,
    },
  ];

  if (hasPermissionUtilty({ name: 'crm.causes.editor' })) {
    columns.unshift({
      type: 'flag',
      status: (row) => row.status,
      onPress: (row) => {
        properties.navigation.navigate(row.status ? 'causeDisable' : 'causeEnable', {
          causeId: row.id,
          phaseId: row.phaseId,
        });
      },
    });
  }

  return (
    <SceneComponent icon={CrmSections.causes.icon} prefix={'crm.causes.list'}>
      <TableComponent<CauseCrmType, []>
        prefix={'crm.causes.list'}
        suffix={'list'}
        params={{
          phaseId: properties.route.params?.phaseId,
        }}
        repository={(settings) =>
          new CauseCrmRepository().list({
            ...settings,
            order: settings.order ?? 'name',
            direction: settings.direction,
            page: settings.page,
            limit: settings.limit,
            search: settings.search,
          })
        }
        columns={columns}
      />
    </SceneComponent>
  );
}
