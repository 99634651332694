// Modules
import { TouchableOpacity, View } from 'react-native';
import { TextComponent } from '@components/text/text.component';
import { ActionSelectableProperties } from './actionSelectable.properties';
import { ActionSelectableStyles } from './actionSelectable.styles';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getWorkspaceUtility } from '@utils/workspace/getWorkspace.utility';
import { IconComponent } from '@components/icon/icon.component';
import { MainTheme } from '@theme/main/main.theme';

/**
 * Action Component
 * @description Botón horizontal renderizado generalmente en la parte superior
 * de los formularios similar a los botones de navegación de las aplicaciones móviles.
 * @returns {JSX.Element}
 */

export function ActionSelectableComponent(properties: ActionSelectableProperties) {
  const [selected, setSelected] = useState<string | undefined>(
    (properties.firstSelected && properties.bubbles[0]) || undefined
  );

  const onChange = (value: string) => {
    if (selected === value && !properties.firstSelected) {
      setSelected(undefined);
      properties.onChange('');
      return;
    }
    setSelected(value);
    properties.onChange(value);
  };

  return (
    <>
      <View style={ActionSelectableStyles.action}>
        <TextComponent
          translate
          text={properties.title}
          style={ActionSelectableStyles.actionText}
        />
        <View style={{ flexDirection: 'row', gap: 10, alignItems: 'center' }}>
          {properties.bubbles.map((bubble) => (
            <TouchableOpacity
              style={[
                ActionSelectableStyles.bubble,
                { backgroundColor: selected === bubble ? MainTheme.primaryColor : '#33333366' },
              ]}
              onPress={() => {
                onChange(bubble);
              }}
            >
              <TextComponent
                multiWorkspace={properties.multiWorkspace}
                translate
                text={bubble}
                style={[ActionSelectableStyles.bubbleText,
                {color: selected === bubble ? 'white' : '#b2b2b2'},]}
              />
            </TouchableOpacity>
          ))}
          {properties.dissmisable && (
            <TouchableOpacity
              onPress={() => {
                setSelected(undefined);
                properties.onChange('');
              }}
            >
              <IconComponent
                name="times"
                containerstyle={[ActionSelectableStyles.bubble, { backgroundColor: '#33333366' }]}
                iconStyle={ActionSelectableStyles.bubbleText}
              />
            </TouchableOpacity>
          )}
        </View>
      </View>
      <View style={{ height: 1, backgroundColor: 'rgba(0,0,0,0.1)' }} />
    </>
  );
}
