import { IconComponent } from '@components/icon/icon.component';
import { TextComponent } from '@components/text/text.component';
import { TouchableOpacity, View } from 'react-native';
import { PaginationStyles } from './pagination.styles';
import { PaginationProperties } from './pagination.properties';
import { useDeviceHook } from '@hooks/device/device.hook';

export function PaginationComponent(properties: PaginationProperties) {
  const { phone } = useDeviceHook();

  const renderBack = () => {
    const isActive = properties.page > 0;
    return (
      <TouchableOpacity
        onPress={() => {
          isActive && properties.onChange(properties.page - 1);
        }}
        style={[
          phone ? PaginationStyles.paginationButtonMobile : PaginationStyles.paginationButton,
          isActive
            ? PaginationStyles.paginationButtonActive
            : PaginationStyles.paginationButtonInactive,
        ]}
      >
        <IconComponent
          name="angle-left"
          iconStyle={[
            phone
              ? PaginationStyles.paginationButtonIconMobile
              : PaginationStyles.paginationButtonIcon,
            isActive
              ? PaginationStyles.paginationButtonIconActive
              : PaginationStyles.paginationButtonIconInactive,
          ]}
        />
      </TouchableOpacity>
    );
  };

  const renderNext = () => {
    const isActive = properties.page < properties.pages - 1;
    return (
      <TouchableOpacity
        onPress={() => {
          isActive && properties.onChange(properties.page + 1);
        }}
        style={[
          phone ? PaginationStyles.paginationButtonMobile : PaginationStyles.paginationButton,
          isActive
            ? PaginationStyles.paginationButtonActive
            : PaginationStyles.paginationButtonInactive,
        ]}
      >
        <IconComponent
          name="angle-right"
          iconStyle={[
            PaginationStyles.paginationButtonIcon,
            phone && PaginationStyles.paginationButtonIconMobile,
            isActive
              ? PaginationStyles.paginationButtonIconActive
              : PaginationStyles.paginationButtonIconInactive,
          ]}
        />
      </TouchableOpacity>
    );
  };

  const renderPage = (page: number) => {
    const pageText = (page + 1).toString();
    const isActive = properties.page === page;
    return (
      <TouchableOpacity
        key={page}
        onPress={() => {
          properties.onChange(page);
        }}
        style={[
          phone ? PaginationStyles.pageContainerMobile : PaginationStyles.pageContainer,
          isActive ? PaginationStyles.pageContainerActive : PaginationStyles.pageContainerInactive,
        ]}
      >
        <TextComponent
          bold
          text={pageText}
          style={[
            phone ? PaginationStyles.pageTextMobile : PaginationStyles.pageText,
            isActive ? PaginationStyles.pageTextActive : PaginationStyles.pageTextInactive,
          ]}
        />
      </TouchableOpacity>
    );
  };

  const renderPages = () => {
    const pages = [];
    // pagination like 123...456...789
    if (properties.pages > 5) {
      if (properties.page > 2) {
        pages.push(renderPage(0));
        pages.push(<TextComponent key={'a'} text="..." style={{}} />);
      }
      if (properties.page >= 1) {
        pages.push(renderPage(properties.page - 1));
      }
      pages.push(renderPage(properties.page));
      if (properties.page < properties.pages - 2) {
        pages.push(renderPage(properties.page + 1));
      }
      if (properties.page < properties.pages - 3) {
        pages.push(<TextComponent key={'b'} text="..." style={{}} />);
        pages.push(renderPage(properties.pages - 1));
      }
    } else {
      for (let i = 0; i < properties.pages; i++) {
        pages.push(renderPage(i));
      }
    }

    return pages;
  };

  return (
    <View style={PaginationStyles.container}>
      {renderPages()}
      <View style={PaginationStyles.paginationContainer}>
        <TextComponent bold text={`${properties.total}`} style={PaginationStyles.totalPagesText} />
        <TextComponent
          multiWorkspace={properties.multiWorkspace}
          translate
          text={`${properties.prefix}${properties.total === 1 ? '.one' : '.many'}`}
          style={phone ? PaginationStyles.manyOrOneTextMobile : PaginationStyles.manyOrOneText}
        />
      </View>
      {renderBack()}
      {renderNext()}
    </View>
  );
}
