import { Image, View } from 'react-native';
import { UserColumnImplementation } from './user.column.properties';
import { OrderTableType } from '../../../../table.properties';
import { UserColumnStyles } from './user.column.styles';
import { TextComponent } from '@components/text/text.component';
import { IconComponent } from '@components/icon/icon.component';

export function UserColumnComponent<ITEM, ORDER extends OrderTableType<ITEM>>(
  properties: UserColumnImplementation<ITEM, ORDER>
) {
  const user = properties.onRender?.(properties.item) ?? properties.name;
  const color = properties.color?.(properties.item);
  const selfie = properties.onRenderSelfie?.(properties.item);
  const background = properties.background?.(properties.item);
  return (
    <View
      style={[
        UserColumnStyles.container,
        {
          backgroundColor: background,
        },
      ]}
    >
      {selfie && <Image source={{ uri: selfie }} style={UserColumnStyles.image} />}
      <TextComponent
        lines={1}
        bold
        text={`${user}`}
        style={[
          {
            marginLeft: 10,
            color: color ?? UserColumnStyles.user.color,
          },
        ]}
      />
    </View>
  );
}
