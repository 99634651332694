import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ContactsRoutes } from '../../contacts.routes';
import { SceneComponent } from '@components/scene/scene.component';
import { CrmSections } from '@human/crm/crm.sections';
import { TableComponent } from '@components/table/table.component';
import { ContactCrmType } from '@human/crm/types/contact.crm.type';
import { ContactCrmRepository } from '@human/crm/repositories/contact/contact.repository';
import { t } from 'i18next';
import { evalMessageUtility } from '@utils/boolean/evalMessage.utility';
import { useDeviceHook } from '@hooks/device/device.hook';
import { IconType } from '@components/icon/icon.types';
import { hasPermissionUtilty } from '@utils/permission/has.permission.utility';
import { ColumnProperties } from '@components/table/components/columns/column.properties';

export function ListContactsScreen(properties: {
  navigation: NavigationProp<ContactsRoutes>;
  route: RouteProp<ContactsRoutes, 'list'>;
}) {
  const { desktop } = useDeviceHook();

  const links: {
    name: string;
    icon: IconType;
    onPress: () => void;
  }[] = [];
  if (
    hasPermissionUtilty({
      name: 'crm.contacts.creator',
    })
  ) {
    links.push({
      icon: 'plus',
      name: 'create',
      onPress: () => properties.navigation.navigate('create'),
    });
  }

  const columns: ColumnProperties<ContactCrmType, ['name']>[] = [
    {
      type: 'icon',
      icon: hasPermissionUtilty({ name: 'crm.contacts.editor' }) ? 'pencil-alt' : 'eye',
      onPress: (row) => {
        properties.navigation.navigate('update', {
          contactId: row.id,
        });
      },
    },
    {
      name: 'name',
      type: 'riskIndicator',
      order: 'name',
      width: 200,
      icon: (row) => (row.potentialDuplicate ? 'exclamation-triangle' : null),
      variant: (row) => (row.potentialDuplicate ? 'warning' : 'normal'),
      onRender: (row) => `${row.name} ${row.surname}`,
    },
    {
      type: 'text',
      name: 'phones',
      width: 200,
      // last phone added to the contact
      onRender: (row) =>
        row.phones && row.phones.length > 0
          ? `${row.phones[row.phones.length - 1].prefix} ${row.phones[row.phones.length - 1].phone}`
          : '',
    },
    {
      name: 'contactTime',
      type: 'text',
      width: 200,
      onRender: (row) => t(`common.contactTime.${row.contactTime}`),
    },
    {
      name: 'consents',
      type: 'multipleBadge',
      width: 325,
      onRender: (row) =>
        [
          evalMessageUtility({
            value: row.marketing,
            truthyString: t('crm.contacts.list.columns.marketing'),
          }),
          evalMessageUtility({
            value: row.legal,
            truthyString: t('crm.contacts.list.columns.legal'),
          }),
        ].filter((s) => s !== ''),
    },
    {
      name: 'created',
      type: 'date',
      renderDate: (row) => row.created,
    },
  ];

  if (hasPermissionUtilty({ name: 'crm.contacts.editor' })) {
    columns.unshift({
      type: 'flag',
      status: (row) => row.status,
      onPress: (row) => {
        properties.navigation.navigate(row.status ? 'contactDisable' : 'contactEnable', {
          id: row.id,
        });
      },
    });
  }

  if (hasPermissionUtilty({ name: 'crm.contacts.destructor' })) {
    columns.unshift({
      type: 'icon',
      icon: 'trash-alt',
      renderColor: () => 'red',
      onPress: (row) => {
        properties.navigation.navigate('contactDelete', {
          id: row.id,
        });
      },
    });
  }

  return (
    <SceneComponent
      multiWorkspace
      icon={CrmSections.contacts.icon}
      prefix={'crm.contacts.list'}
      links={links}
    >
      <TableComponent<ContactCrmType, ['name']>
        prefix={'crm.contacts.list'}
        suffix={'list'}
        repository={(settings) =>
          new ContactCrmRepository().list({
            order: settings.order || 'created',
            direction: settings.direction,
            page: settings.page,
            limit: settings.limit,
            search: settings.search,
          })
        }
        columns={columns}
      />
    </SceneComponent>
  );
}
