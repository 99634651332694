import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ActionsRoutes } from '../../actions.routes';
import { CalendarComponent } from '@components/calendar/calendar.component.web';
import { ActionCrmRepository } from '@human/crm/repositories/action/action.repository';
import { SearchComponent } from '@components/table/components/columns/components/search/search.component';
import { useState } from 'react';
import { hasPermissionUtilty } from '@utils/permission/has.permission.utility';
import { ScrollView, TouchableOpacity, View } from 'react-native';
import { FilterBadgeComponent } from '@components/table/components/columns/components/search/components/filterBadge/filterBadge.component';
import { SearchStyles } from '@components/table/components/columns/components/search/search.styles';
import { FilterSelectInput } from '@components/table/components/columns/components/filterSelect/filter.select.input';
import { useTranslation } from 'react-i18next';
import { TaskStatus } from '@components/table/table.component';
import { FilterCheckBoxInput } from '@components/table/components/columns/components/filterCheckBox/filterCheckBox.input';

export function ListActionsScreen(properties: {
  navigation: NavigationProp<ActionsRoutes>;
  route: RouteProp<ActionsRoutes, 'list'>;
}) {
  const [search, setSearch] = useState<string[]>([]);
  const [completed, setCompleted] = useState<TaskStatus>('pending');
  const [seeDisabled, setSeeDisabled] = useState(false);
  const { t } = useTranslation();
  const [date, setDate] = useState(new Date());

  return (
    <>
      <View style={{ backgroundColor: 'white', zIndex: 1 }}>
        <View style={{ flexDirection: 'row', marginVertical: 10, marginHorizontal: 20 }}>
          <View style={{ flex: 1 }}>
            <SearchComponent
              datesFields={[
                {
                  label: 'Fecha',
                  value: 'finished',
                  options: {
                    min: undefined,
                    max: undefined,
                  },
                },
              ]}
              onChange={(value) => {
                setSearch([...search, value]);
              }}
              onRemove={(value) => {
                setSearch(search.filter((item) => item !== value));
              }}
              values={search}
            />
          </View>
          {hasPermissionUtilty({ name: 'crm.tasks.seeAll' }) && (
            <View style={{ marginHorizontal: 10 }}>
              <FilterCheckBoxInput
                name="disabled"
                value={seeDisabled}
                prefix="common.tasks"
                title="disabled"
                onChange={(value: any) => {
                  setSeeDisabled(value.disabled);
                }}
              />
            </View>
          )}
          <View>
            <FilterSelectInput
              type="select"
              name="completed"
              onChange={(value: any) => {
                if (!value.completed) return;
                setCompleted(value.completed);
              }}
              options={[
                { label: t('common.taskStatus.ALL'), value: 'all' },
                { label: t('common.taskStatus.COMPLETED'), value: 'completed' },
                { label: t('common.taskStatus.PENDING'), value: 'pending' },
              ]}
              value={completed}
            />
          </View>
        </View>
        <TouchableOpacity style={[SearchStyles.badgesContainer, { marginLeft: 10 }]}>
          {search?.map((value, index) => (
            <FilterBadgeComponent
              key={`${value}${index}`}
              value={value}
              onRemove={(value) => {
                setSearch(search?.filter((item) => item !== value));
              }}
            />
          ))}
        </TouchableOpacity>
      </View>
      <ScrollView contentContainerStyle={{ flex: 1, zIndex: 0 }}>
        <CalendarComponent
          onNavigate={(date, view, action) => {
            setDate(date);
          }}
          repository={async ({ mode }) => {
            return new ActionCrmRepository()
              .list({
                page: 0,
                limit: 1000,
                completed:
                  completed === 'completed' ? true : completed === 'pending' ? false : undefined,
                seeDisabled,
                calendarMode: mode,
                calendarDate: date.toISOString(),
                avoidCount: true,
                //MANDAR A BACKEND COMO UN STRING
                search: search.join(';'),
              })
              .then((response) => {
                return response.items.map((action) => {
                  const started = new Date(action.started);
                  const finished = action.finished
                    ? new Date(action.finished)
                    : new Date(action.started);

                  return {
                    title: `${action.contactName} ${action.contactSurname}`,
                    start: started,
                    subject: action.subject,
                    subtitle: action.activityName,
                    end: finished,
                    color: action.activityColor,
                    created: new Date(action.created),
                    actionId: action.id,
                    leadId: action.leadId,
                    patientId: action.patientId,
                    activityId: action.activityId,
                    clinicId: action.clinicId,
                    clinicName: action.clinicName,
                    contactId: action.contactId,
                    leadStatus: action.leadStatus,
                  };
                });
              });
          }}
          onSlotPress={(slotInfo) => {
            if (!hasPermissionUtilty({ name: 'crm.tasks.creator' })) return;

            properties.navigation.navigate('actionCreateFromCalendar', {
              started: slotInfo.start,
              endend: slotInfo.end,
            });
          }}
          onEventPress={(event) => {
            properties.navigation.navigate('actionUpdate', {
              actionId: event.actionId,
              leadId: event.leadId,
              patientId: event.patientId,
              clinicId: event.clinicId,
              activityId: event.activityId,
              contactId: event.contactId,
              level: 1,
            });
          }}
        />
      </ScrollView>
    </>
  );
}
