// Modules
import { StyleSheet } from 'react-native';

/**
 * SpaceStyles Styles
 * @constant {StyleSheet} SceneStylesStyles
 */
export const FileUploaderStyles = StyleSheet.create({
  container: {
    height: 30,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  uploadBox: {
    backgroundColor: 'rgb(17, 27, 34)',
    borderRadius: 4,
  },
  buttonContainer: {
    borderRadius: 4,
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
    overflow: 'hidden',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
  iconContainer: {
    height: 30,
    width: 30,
    backgroundColor: '#00000029',
    borderLeftColor: '#00000017',
    borderLeftWidth: 1,
  },
  icon: {
    fontSize: 16,
    textAlign: 'center',
    lineHeight: 30,
    color: '#fff',
  },
  fileContainer: {
    backgroundColor: '#dfdfdf',
    borderRadius: 6,
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
    paddingLeft: 10,
    marginLeft: 20,
  },
  selectedContainer: {
    marginLeft: 10,
    display: 'flex',
    flexDirection: 'row',
    height: 40,
    lineHeight: 40,
    maxWidth: 300,
    overflow: 'hidden',
    opacity: 0.8,
    borderRadius: 6,
    paddingLeft: 10,
    paddingRight: 10,
  },
  text: {
    color: '#555',
    fontSize: 14,
    textAlign: 'center',
    maxWidth: 200,
  },
  buttonLabel: {
    color: '#fff',
    fontSize: 10,
    fontWeight: '500',
    lineHeight: 15,
    marginLeft: 15,
  },
  submitButton: {
    marginLeft: 10,
    alignSelf: 'flex-end',
    paddingHorizontal: 4,
    padding: 5,
    borderRadius: 5,
    backgroundColor: 'rgb(237, 53, 145)',
    borderColor: 'rgb(237, 53, 145)',
    flexDirection: 'row',
    gap: 10,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },

    shadowOpacity: 0.1,
    shadowRadius: 3.84,
    elevation: 5,
  },
  submitIcon: {
    color: 'rgba(255, 255, 255, 0.72)',
    fontSize: 20,
    textAlign: 'center',
  },
});
