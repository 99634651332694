import { ButtonComponent } from '@components/button/button.component';
import { ColumnProperties } from '@components/table/components/columns/column.properties';
import { TableComponent } from '@components/table/table.component';
import { DepartmentAccessRepository } from '@human/access/repositories/department/department.repository';
import { ClinicsRoutes } from '@human/access/sections/clinics/clinics.routes';
import { DepartmentAccessType } from '@human/access/types/department.access.type';
import { NavigationProp } from '@react-navigation/native';
import { hasPermissionUtilty } from '@utils/permission/has.permission.utility';
import { ScrollView } from 'react-native';

export function DepartmentsPart(properties: {
  clinicId: string;
  navigation: NavigationProp<ClinicsRoutes>;
}) {
  const columns: ColumnProperties<DepartmentAccessType, ['name']>[] = [
    {
      type: 'icon',
      icon: 'pen',
      onPress: (row) => {
        properties.navigation.navigate('departmentUpdate', {
          id: row.id,
          level: 1,
          clinicId: row.clinicId,
        });
      },
    },
    {
      name: 'name',
      type: 'text',
      width: 170,
      onRender: (row) => row.name,
    },

    {
      name: 'responsible',
      type: 'text',
      width: 170,
      onRender: (row) => `${row.responsibleName} ${row.responsibleSurname}`,
    },
    {
      name: 'language',
      type: 'text',
      width: 100,
      onRender: (row) => row.languageName,
    },
  ];

  if (hasPermissionUtilty({ name: 'access.departments.editor' })) {
    columns.unshift({
      type: 'flag',
      status: (row) => row.status,
      onPress: (row) => {
        properties.navigation.navigate(row.status ? 'departmentDisable' : 'departmentEnable', {
          id: row.id,
          clinicId: row.clinicId,
        });
      },
    });
  }

  return (
    <ScrollView>
      <TableComponent<DepartmentAccessType, ['name']>
        prefix="access.departments.list"
        suffix="list"
        hideNoDataImage
        repository={(settings) => {
          return new DepartmentAccessRepository().listByClinic({
            direction: 'asc',
            page: 0,
            limit: 1000,
            clinicId: properties.clinicId,
            search: settings.search,
          });
        }}
        columns={columns}
      />
      {hasPermissionUtilty({
        name: 'access.departments.creator',
      }) && (
        <ButtonComponent
          icon="plus"
          prefix="access.clinics.update.tabs.departments.add"
          onPress={() => {
            properties.navigation.navigate('departmentCreate', {
              clinicId: properties.clinicId,
            });
          }}
        />
      )}
    </ScrollView>
  );
}
