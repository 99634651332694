// Modules
import { StyleSheet } from 'react-native';
import { MainTheme } from '@theme/main/main.theme';

/**
 * Search Styles
 * @constant {StyleSheet} SearchStyles
 */
export const SearchStyles = StyleSheet.create({
  iconContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: 40,
    width: 40,
    opacity: 0.5,
    zIndex: 1,
  },
  input: {
    height: 40,
    padding: 10,
    paddingLeft: 40,
    fontSize: 14,
    borderWidth: 1,
    borderColor: '#c0c0c0',
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    borderRadius: 5,
    color: '#1111b22',
  },
  badgesContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 5,
  },
  icon: {
    color: '#000',
    opacity: 0.6,
    fontSize: 18,
    lineHeight: 40,
    textAlign: 'center',
  },
  submitSearchContainer: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#293238',
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    padding: 5,
    top: 0,
    right: 0,
    height: 40,
    width: 40,
    zIndex: 1,
  },
  submitText: {
    color: '#fff',
    fontSize: 14,
    fontWeight: '500',
    transform: [
      { rotate: '-90deg' },
      {
        scaleX: -1,
      },
    ],
  },
});
