import { PhaseCrmType } from '@human/crm/types/phase.crm.type';
import { AuthorizedRepository } from '@core/repository/authorized.repository.core';

export class PhaseCrmRepository extends AuthorizedRepository {
  async list(properties: {
    order?: keyof PhaseCrmType;
    direction: 'asc' | 'desc';
    page: number;
    limit: number;
    search?: string;
    active?: boolean;
  }): Promise<{
    items: PhaseCrmType[];
    total: number;
  }> {
    return this.fetch('phase/find', {
      method: 'GET',
      params: properties,
    }).then((data) => ({
      total: data.total,
      items: data.items.map((item: any) => ({
        id: item.id,
        name: item.name,
        description: item.description,
        icon: item.icon,
        position: item.position,
        time: item.time,
        primary: item.primary,
        color: item.color,
        updated: new Date(item.updated),
        created: new Date(item.created),
        status: item.status,
        causes: item.causes,
      })),
    }));
  }

  async create(properties: {
    item: Partial<PhaseCrmType & { signature: string }>;
  }): Promise<Partial<PhaseCrmType>> {
    return this.fetch('phase/create', {
      method: 'POST',
      body: properties.item,
    });
  }

  pick(properties: { phaseId: string }): Promise<PhaseCrmType> {
    return this.fetch('phase/pick', {
      method: 'GET',
      params: properties,
    });
  }

  update(properties: { phaseId: string; item: Partial<PhaseCrmType> }) {
    return this.fetch(`phase/update?phaseId=${properties.phaseId}`, {
      method: 'PUT',
      body: {
        ...properties.item,
        phaseId: properties.phaseId,
      },
    });
  }

  enable(properties: { phaseId: string }) {
    return this.fetch('phase/enable', {
      method: 'PUT',
      body: {
        phaseId: properties.phaseId,
      },
    });
  }

  disable(properties: { phaseId: string }) {
    return this.fetch('phase/disable', {
      method: 'PUT',
      body: {
        phaseId: properties.phaseId,
      },
    });
  }
}
