// Modules
import { StyleSheet } from 'react-native';

/**
 * Pagination Styles
 * @constant {StyleSheet} PaginationStyles
 */
export const PaginationStyles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 10,
    gap: 10,
  },
  paginationContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  totalPagesText: {
    marginLeft: 'auto',
    marginRight: 5,
  },
  manyOrOneText: {
    textAlign: 'right',
    textTransform: 'uppercase',
    letterSpacing: 1,
    opacity: 0.4,
    fontSize: 11,
  },
  manyOrOneTextMobile: {
    textAlign: 'right',
    textTransform: 'uppercase',
    letterSpacing: 1,
    opacity: 0.4,
    fontSize: 8,
  },
  paginationButton: {
    height: 40,
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    width: 40,
    borderRadius: 50,
  },
  paginationButtonMobile: {
    height: 30,
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    width: 30,
    borderRadius: 50,
  },
  paginationButtonActive: {
    backgroundColor: 'rgb(237, 53, 145)',
  },
  paginationButtonInactive: {
    backgroundColor: 'rgb(209, 209, 209)',
  },
  paginationButtonIcon: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  paginationButtonIconMobile: {
    fontSize: 15,
    fontWeight: 'bold',
  },
  paginationButtonIconActive: {
    color: 'rgb(255, 255, 255)',
  },
  paginationButtonIconInactive: {
    color: 'rgb(180, 180, 180)',
  },
  pageContainer: {
    height: 40,
    minWidth: 40,
    paddingHorizontal: 5,
    borderRadius: 50,
  },
  pageContainerMobile: {
    height: 30,
    minWidth: 30,
    paddingHorizontal: 5,
    borderRadius: 50,
  },
  pageContainerActive: {
    backgroundColor: 'rgb(237, 53, 145)',
  },
  pageContainerInactive: {
    backgroundColor: 'rgb(255, 255, 255)',
  },
  pageText: {
    lineHeight: 38,
    textAlign: 'center',
  },
  pageTextMobile: {
    lineHeight: 28,
    textAlign: 'center',
  },
  pageTextActive: {
    color: 'rgb(255, 255, 255)',
  },
  pageTextInactive: {
    color: 'rgb(0, 0, 0)',
  },
});
