import { AuthorizedRepository } from '@core/repository/authorized.repository.core';
import { UserAccessType } from '../../types/user.access.type';
import { LanguageAccessType } from '@human/access/types/language.access.type';

export class UserAccessRepository extends AuthorizedRepository {
  async list(properties: {
    order: keyof UserAccessType;
    direction: 'asc' | 'desc';
    page: number;
    limit: number;
    search?: string;
    dates?: {
      start: Date;
      end: Date;
    };
    roleId?: string;
  }): Promise<{
    items: UserAccessType[];
    total: number;
  }> {
    return this.fetch('user/find', {
      method: 'GET',
      params: properties,
    }).then((data) => ({
      total: data.total,
      items: data.items.map((user: any) => ({
        id: user.id,
        username: user.username,
        name: user.name,
        surname: user.surname,
        email: user.email,
        designation: user.designation,
        extension: user.extension,
        phone: user.phone,
        clinics: user.clinics,
        created: new Date(user.created),
        deleted: new Date(user.deleted),
        status: user.status,
        updated: new Date(user.updated),
      })),
    }));
  }

  async create(properties: {
    item: Partial<UserAccessType & { signature: string }>;
  }): Promise<Partial<UserAccessType>> {
    return this.fetch('user/create', {
      method: 'POST',
      body: properties.item,
    });
  }

  async delete(properties: Partial<{ id: string; password: string }>): Promise<void> {
    return this.fetch('user/delete', {
      method: 'DELETE',
      params: {
        id: properties.id ?? '',
      },
      body: {
        password: properties.password,
      },
    });
  }

  disable(properties: { userId: string }): Promise<void> {
    return this.fetch('user/disable', {
      method: 'PUT',
      params: properties,
    });
  }

  enable(properties: { userId: string }): Promise<void> {
    return this.fetch('user/enable', {
      method: 'PUT',
      params: properties,
    });
  }

  async pick(properties: { userId: string }): Promise<UserAccessType> {
    return this.fetch('user/pick', {
      method: 'GET',
      params: properties,
    });
  }

  async update(properties: { item: Partial<UserAccessType> }) {
    return this.fetch('user/update', {
      method: 'PUT',
      body: properties.item,
    });
  }

  async addLanguage(properties: { userId: string; languageId: string }) {
    return this.fetch('user/createUserLanguage', {
      method: 'POST',
      body: properties,
    });
  }

  async removeLanguage(properties: { userId: string; languageId: string }) {
    return this.fetch('user/removeUserLanguage', {
      method: 'PUT',
      body: properties,
    });
  }

  async findLanguages(properties: { userId: string; search?: string }): Promise<{
    items: LanguageAccessType[];
    total: number;
  }> {
    return this.fetch('user/findLanguages', {
      method: 'GET',
      params: properties,
    }).then((data) => ({
      total: data.length,
      items: data,
    }));
  }

  pickIntegrations(properties: { userId: string }): Promise<{ isLandbot: boolean }> {
    return this.fetch('user/integration/pick', {
      method: 'GET',
      params: properties,
    });
  }

  async updateIntegrations(properties: { userId: string; isLandbot: boolean }): Promise<void> {
    return this.fetch('user/integration/update', {
      method: 'PUT',
      body: properties,
    });
  }

  async clone(properties: { userId: string }): Promise<void> {
    return this.fetch('user/clone', {
      method: 'POST',
      body: properties,
    });
  }

  async listSubordinates(properties: { clinicId?: string; search?: string }): Promise<
    {
      id: string;
      name: string;
      surname: string;
    }[]
  > {
    return this.fetch('user/subordinate/find', {
      method: 'GET',
      params: properties,
    });
  }
}
