// Modules
import { MainTheme } from '@theme/main/main.theme';
import { StyleSheet } from 'react-native';

export const AvatarUploaderStyles = StyleSheet.create({
  container: {
    height: 100,
    borderRadius: 10,
    width: 100,
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    borderWidth: 1,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  pointerContainer: {
    cursor: 'pointer',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    zIndex: 5,
    backdropFilter: 'blur(3px)',
  },
  iconCamera: {
    fontSize: 30,
    color: '#fff',
  },
  expandIcon: {
    fontSize: 16,
    color: '#fff',
  },
  image: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: 10,
  },
  iconUserContainer: {
    backgroundColor: '#fff',
    borderRadius: 10,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconUser: {
    fontSize: 40,
    fontWeight: 'bold',
    color: 'rgba(237, 53, 145, 0.5)',
  },
  expandContainer: {
    position: 'absolute',
    bottom: -10,
    left: 80,
    backgroundColor: MainTheme.primaryColor,
    borderRadius: 20,
    padding: 5,
    zIndex: 6,
    cursor: 'pointer',
  },
});
