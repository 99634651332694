import React, { useState } from 'react';
import { OrderTableType } from '@components/table/table.properties';
import { NumberColumnImplementation } from './number.column.properties';
import { View } from 'react-native';
import { NumberColumnStyles } from './number.column.styles';
import { TextComponent } from '@components/text/text.component';
import { IconComponent } from '@components/icon/icon.component';
import { Pressable } from 'react-native-web';

export function NumberColumnComponent<ITEM, ORDER extends OrderTableType<ITEM>>(
  props: NumberColumnImplementation<ITEM, ORDER>
): JSX.Element {
  const [hover, setHover] = useState(false);

  const text = props.onRender?.(props.item) ?? props.name;
  const suffix = props.onSuffix?.(props.item) ?? '';
  const content = (
    <>
      <TextComponent lines={1} bold text={`${text}`} style={NumberColumnStyles.text} />
      <TextComponent lines={1} text={`${suffix}`} style={NumberColumnStyles.suffix} />
    </>
  );

  if (props.onPress) {
    return (
      <Pressable
        onPress={() => props.onPress?.(props.item)}
        style={[NumberColumnStyles.container, hover && NumberColumnStyles.hover]}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        {content}
        <IconComponent name="angle-right" iconStyle={NumberColumnStyles.icon} />
      </Pressable>
    );
  }
  return <View style={NumberColumnStyles.container}>{content}</View>;
}
