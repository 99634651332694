import { ImageBackground, ScrollView, TouchableOpacity, View } from 'react-native';
import { CoversStyles } from './covers.styles';
import { TextComponent } from '@components/text/text.component';
import { useEffect, useState } from 'react';
import { ItemCoversType } from './covers.types';
import { COVERS_LENGTH } from './covers.constants';
import { LinearGradient } from 'expo-linear-gradient';

export function CoversComponent(properties: {
  infiniteScroll?: boolean;
  search?: string;
  repository: (settings: {
    search?: string;
    page: number;
    limit: number;
  }) => Promise<{ items: ItemCoversType[]; total: number }>;
}) {
  const [items, setItems] = useState<ItemCoversType[]>([]);
  const [loading, setLoading] = useState(false);
  const loadData = () => {
    if (!loading) {
      setLoading(true);
      properties
        .repository({ search: properties.search, limit: COVERS_LENGTH, page: 0 })
        .then((covers) => {
          setItems(covers.items);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <ScrollView
      style={CoversStyles.container}
      horizontal
      contentContainerStyle={CoversStyles.content}
    >
      {items.map((item, index) => {
        return (
          <TouchableOpacity key={index} style={CoversStyles.cover} onPress={item.onPress}>
            <ImageBackground source={{ uri: item.image }} style={CoversStyles.image}>
              <LinearGradient
                colors={['rgba(237, 53, 145, 0.4)', 'rgba(237, 53, 145, 0.1)', '#000']}
                style={{
                  position: 'absolute',
                  zIndex: 1,
                  height: 350,
                  width: 260,
                  borderRadius: 10,
                }}
              />

              <View style={CoversStyles.subtitle}>
                <TextComponent style={CoversStyles.subtitlePrefix} text={'#'} />
                <TextComponent style={CoversStyles.subtitleText} text={item.subtitle} />
              </View>

              <View style={CoversStyles.count}>
                <TextComponent style={CoversStyles.countPrefix} text={'Leads'} />
                <TextComponent style={CoversStyles.countText} text={`${item.count}`} />
              </View>

              <TextComponent style={CoversStyles.title} text={`${item.title}`} />
              <View style={CoversStyles.detail}>
                <TextComponent style={CoversStyles.detailText} text={`${item.detail}`} />
              </View>
            </ImageBackground>
          </TouchableOpacity>
        );
      })}
    </ScrollView>
  );
}
