import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ModalComponent } from '@components/modal/modal.component';
import { FormComponent } from '@components/form/form.component';

import { TemplatesRoutes } from '../../templates.routes';
import { TemplateAccessRepository } from '@human/access/repositories/template/template.repository';

export function DisableTemplateScreen(properties: {
  navigation: NavigationProp<TemplatesRoutes>;
  route: RouteProp<TemplatesRoutes, 'disable'>;
}) {
  return (
    <ModalComponent icon="trash-alt" prefix="access.templates.disable">
      <FormComponent
        prefix="access.templates.disable.form"
        padding
        repository={{
          send: () => {
            return new TemplateAccessRepository()
              .disable({
                templateId: properties.route.params.templateId,
              })
              .then(properties.navigation.goBack);
          },
        }}
        fields={[]}
      />
    </ModalComponent>
  );
}
