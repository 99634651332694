import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ModalComponent } from '@components/modal/modal.component';
import { FormComponent } from '@components/form/form.component';
import { TemplateParamsRoutes } from '../../templateParams.routes';
import { TemplateParamsAccessRepository } from '@human/access/repositories/template/templateParams/templateParams.repository';

export function DeleteTemplateParamsScreen(properties: {
  navigation: NavigationProp<TemplateParamsRoutes>;
  route: RouteProp<TemplateParamsRoutes, 'deleteTemplateParam'>;
}) {
  return (
    <ModalComponent
      icon="trash-alt"
      prefix="access.templates.templateParams.delete"
      level={properties.route.params.level}
    >
      <FormComponent
        prefix="access.templates.templateParams.delete.form"
        padding
        repository={{
          send: (data) => {
            return new TemplateParamsAccessRepository()
              .delete({
                templateId: properties.route.params.templateId,
                templateKeyId: properties.route.params.templateKeyId,
              })
              .then(properties.navigation.goBack);
          },
        }}
        fields={[
          {
            name: 'status',
            icon: 'lock',
            type: 'checkbox',
            validations: ['true'],
            description: true,
          },
        ]}
      />
    </ModalComponent>
  );
}
