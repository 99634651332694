import { useEffect, useState } from 'react';
import { ScrollView, View, Image, Dimensions } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { TableProperties } from './table.properties';
import { ColumnComponent } from './components/columns/column.component';
import { ColumnProperties } from './components/columns/column.properties';
import { TextComponent } from '@components/text/text.component';
import { SearchComponent } from './components/columns/components/search/search.component';
import { IconComponent } from '@components/icon/icon.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { getWrapperStyles, TableStyles } from './table.styles';
import { useIsFocused } from '@react-navigation/native';
import { ParamsComponent } from './components/params/params.component';
import { tableConstants } from './table.constants';
import { PeriodComponent } from './components/columns/components/period/period.component';
import { t } from 'i18next';
import { FilterSelectInput } from './components/columns/components/filterSelect/filter.select.input';
import { SearchStyles } from './components/columns/components/search/search.styles';
import { FilterBadgeComponent } from './components/columns/components/search/components/filterBadge/filterBadge.component';
import { LoaderComponent } from '@components/loader/loader.component';
import { SelectableColumnStyles } from './components/columns/components/selectable/selectable.column.styles';
import { FilterCheckBoxInput } from './components/columns/components/filterCheckBox/filterCheckBox.input';
import { SecondaryButtonComponent } from '@components/button/secondary/secondary.button.component';
import { FileUploaderComponent } from '@components/fileUploader/fileUploader.component.web';

/**
 * Table Component
 * @description Tabla de datos con columnas personalizables y paginación.
 * Permite acceder a la edición, eliminación y visualización de los datos.
 *
 * @param {TableProperties} properties
 * @returns {JSX.Element}
 */

const width = Dimensions.get('window').width;
const height = Dimensions.get('window').height;

export type TaskStatus = 'all' | 'completed' | 'pending';

export type ItemTableType = {
  [key: string]: any;
} & { status?: boolean };

export function TableComponent<ITEM extends ItemTableType, ORDER extends Array<keyof ITEM>>(
  properties: TableProperties<ITEM, ORDER>
) {
  const [firstLoad, setFirstLoad] = useState<boolean>(true);
  const [items, setItems] = useState<ITEM[]>([]);
  const [search, setSearch] = useState<string[] | undefined>(undefined);
  const [order, setOrder] = useState<keyof ITEM>();
  const [selected, setSelected] = useState<boolean>(false);
  const [direction, setDirection] = useState<'asc' | 'desc'>('desc');
  const [pages, setPages] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [limit, setLimit] = useState<number>(tableConstants.pagination);
  const [loading, setLoading] = useState<boolean>(true);
  const [completed, setCompleted] = useState<TaskStatus>('pending');
  const [toggles, setToggles] = useState<
    Array<{
      name: string;
      value: boolean;
    }>
  >(
    properties.toggles?.map((toggle) => ({
      name: toggle.name,
      value: toggle.defaultValue ?? false,
    })) ?? []
  );
  const isFocus = useIsFocused();
  const [startDate, setStartDate] = useState<Date | undefined>();
  const [endDate, setEndDate] = useState<Date | undefined>();

  useEffect(() => {
    if (firstLoad) return;

    isFocus && loadItems();
  }, [isFocus, page, order, direction, properties.params]);

  useEffect(() => {
    if (properties.avoidRefresh && !firstLoad) return;
    if (properties.reload) {
      properties.reload.then(() => {
        loadItems();
      });
    }

    loadItems();
  }, []);

  //useEffect to debounce API search
  useEffect(() => {
    if (firstLoad) return;

    if (search) {
      const timeout = setTimeout(() => {
        loadItems();
      }, 300);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [search]);

  useEffect(() => {
    if (firstLoad) return;

    if (completed) {
      loadItems();
    }
  }, [completed]);

  useEffect(() => {
    if (firstLoad) return;

    if (toggles) {
      loadItems();
    }
  }, [toggles]);

  useEffect(() => {
    if (firstLoad) return;
    loadItems();
  }, [startDate, endDate]);

  const loadItems = () => {
    if (firstLoad) setFirstLoad(false);
    setLoading(true);
    let auxRepoSettings = {
      order: order,
      direction: direction,
      page: page,
      limit: properties.limit || limit,
      search: search ? search.join(';') : undefined,
      dates: startDate && endDate ? { start: startDate, end: endDate } : undefined,
      completed: completed === 'completed' ? true : completed === 'pending' ? false : undefined,
      ...getTogglesStateToRepo(),
    };
    auxRepoSettings = {
      ...auxRepoSettings,
      ...properties.params,
    };

    properties
      .repository?.(auxRepoSettings)
      .then((response) => {
        setItems(response.items);
        setTotal(response.total);
        setPages(Math.ceil(response.total / (properties.limit || limit)));
        setSelected(false);
        properties.onSelectAllClick?.([]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getWidthOfColumn = (column: ColumnProperties<ITEM, ORDER>) => {
    if ('width' in column) {
      return column.width;
    }
    if (
      column.type === 'icon' ||
      column.type === 'flag' ||
      column.type === 'idCopier' ||
      column.type === 'status' ||
      column.type === 'selectable'
    ) {
      return 42;
    }
    if (column.type === 'date' || column.type === 'iconText') {
      return 250;
    }
    return 100;
  };

  const renderActionButtons = () => {
    return properties.actions?.map((action, index) => {
      if (action.hideOnNoData && items.length === 0) return null;
      if (action.fileUpload)
        return (
          <FileUploaderComponent
            key={index}
            fileType={action.fileUpload.fileType}
            onSubmit={action.fileUpload.onSubmit}
          />
        );
      return (
        <SecondaryButtonComponent
          key={index}
          onPress={() => {
            action.onPress({
              search: search,
              dates: startDate && endDate ? { start: startDate, end: endDate } : undefined,
            });
          }}
          icon={action.icon}
          prefix={action.label}
        />
      );
    });
  };

  const renderToggle = (pToggle: { prefix: string; label: string; name: string }) => {
    const auxToggle = toggles.find((toggle) => toggle.name === pToggle.name);
    if (!auxToggle) return null;
    return (
      <FilterCheckBoxInput
        name={pToggle.name}
        value={auxToggle.value}
        prefix={pToggle.prefix}
        title={pToggle.label}
        onChange={(value: any) => {
          setToggles(
            toggles.map((toggle) =>
              toggle.name === pToggle.name
                ? { name: toggle.name, value: value[toggle.name] }
                : toggle
            )
          );
        }}
      />
    );
  };

  /**
   * Esta función es una pequeña fumada para pasarle al repository del tableComponent los valores de los toggles.
   * Imaginemos que hay un array de dos checkboxes, uno para mostrar tareas completadas y otro para mostrar leads deshabilitados
   * En las properties llegará algo como:
   * toggles: [
   * {
   * label: 'completed',
   * name: 'completed',
   * prefix: 'leads.list',
   * },
   * {
   * label: 'disabled',
   * name: 'disabled',
   * prefix: 'leads.list',
   * },
   * ]
   * En el estado de los toggles se guardará algo como:
   * toggles: [
   * {
   * name: 'completed',
   * value: false,
   * },
   * {
   * name: 'disabled',
   * value: false,
   * },
   * ]
   * Mediante esta función se transforma el array de toggles en un objeto con la forma {completed: false, disabled: false} y se lo pasamos al repository para hacer la peticion desde fuera del componente.
   */
  const getTogglesStateToRepo = () => {
    return toggles.reduce((acc: any, toggle: any) => {
      acc[toggle.name] = toggle.value;
      return acc;
    }, {});
  };

  const getRowBackgroundColor = (item: ITEM, index: number) => {
    const baseStyle = TableStyles.tableContainer;

    if (item.status === false) {
      return [baseStyle, TableStyles.disabledTableContainer];
    }
    if (index % 2 !== 0) {
      return [baseStyle, TableStyles.oddTableContainer];
    }
    return [baseStyle, TableStyles.enabledTableContainer];
  };

  return (
    <View>
      {properties.search !== false && (
        <View style={TableStyles.filtersContainer}>
          <View style={TableStyles.searchContainer}>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                flexShrink: 1,
                gap: 8,
              }}
            >
              <View style={{ flex: 1, justifyContent: 'center' }}>
                <SearchComponent
                  onDateChange={(properties) => {
                    setStartDate(properties.startDate);
                    setEndDate(properties.endDate);
                  }}
                  onChange={(value) => {
                    setPage(0);
                    setSearch(search ? [...search, value] : [value]);
                    setSelected(false);
                  }}
                  onRemove={(value) => {
                    setPage(0);
                    setSearch(search?.filter((item) => item !== value));
                    setSelected(false);
                  }}
                  values={search}
                  datesFields={[]}
                />
              </View>
              {properties.dates && (
                <View style={TableStyles.periodContainer}>
                  <PeriodComponent
                    onDateChange={(properties) => {
                      setPage(0);
                      setStartDate(properties.startDate);
                      setEndDate(properties.endDate);
                    }}
                    datesFields={{
                      startDate,
                      endDate,
                    }}
                  />
                </View>
              )}
              {properties.toggles?.map((pToggle, index) => (
                <View style={[TableStyles.completedContainer]} key={index}>
                  {renderToggle(pToggle)}
                </View>
              ))}
              {properties.completed && (
                <View style={[TableStyles.completedContainer]}>
                  <FilterSelectInput
                    type="select"
                    name="completed"
                    hideSearch
                    onChange={(value: any) => {
                      if (!value.completed) return;
                      setCompleted(value.completed);
                    }}
                    options={[
                      { label: t('common.taskStatus.ALL'), value: 'all' },
                      { label: t('common.taskStatus.COMPLETED'), value: 'completed' },
                      { label: t('common.taskStatus.PENDING'), value: 'pending' },
                    ]}
                    value={completed}
                  />
                </View>
              )}
              {renderActionButtons()}
            </View>
          </View>
        </View>
      )}
      <TouchableOpacity style={SearchStyles.badgesContainer}>
        {search?.map((value, index) => (
          <FilterBadgeComponent
            key={`${value}${index}`}
            value={value}
            onRemove={(value) => {
              setPage(0);
              setSearch(search?.filter((item) => item !== value));
            }}
          />
        ))}
      </TouchableOpacity>
      {properties.params && (
        <ParamsComponent params={properties.params} prefix={properties.prefix} />
      )}
      {loading && (
        <View style={TableStyles.loaderContainer}>
          <LoaderComponent loading={loading} />
        </View>
      )}
      {!loading && (
        <ScrollView
          style={{
            minWidth: '100%',
          }}
          contentContainerStyle={{
            minWidth: '100%',
          }}
          horizontal
          nestedScrollEnabled
          showsHorizontalScrollIndicator
          showsVerticalScrollIndicator
        >
          <ScrollView style={getWrapperStyles(!!(search && search.length > 0))} nestedScrollEnabled>
            <View>
              {properties.header !== false && (
                <View style={TableStyles.headerContainer}>
                  {properties.columns.map((column, index) => {
                    const width = getWidthOfColumn(column);
                    return (
                      <TouchableOpacity
                        style={[TableStyles.header, { width: width }]}
                        key={index}
                        disabled={!('order' in column)} // TOFIX: Actualmente muchos de los endpoints no tienen en cuenta el dato de la ordenacion. La cabecera al hacerla clickable parece que se ha roto algo. Ponemos disabled para evitar que se pueda hacer click.
                        onPress={() => {
                          if ('order' in column) {
                            if (order === column.name) {
                              setDirection(direction !== 'asc' ? 'asc' : 'desc');
                            } else {
                              setOrder(column.name);
                              setDirection('asc');
                            }
                          }
                        }}
                      >
                        {'headerIcon' in column && column.headerIcon && (
                          <IconComponent
                            name={column.headerIcon}
                            containerstyle={TableStyles.headerIconContainer}
                            iconStyle={TableStyles.headerIcon}
                          />
                        )}
                        {'name' in column && (
                          <TextComponent
                            translate
                            multiWorkspace={properties.multiWorkspace}
                            text={
                              'name' in column ? `${properties.prefix}.columns.${column.name}` : ''
                            }
                            style={[
                              TableStyles.headerTextName,
                              'order' in column && order === column.order
                                ? TableStyles.headerTextNameActive
                                : TableStyles.headerTextNameInactive,
                            ]}
                          />
                        )}
                        {'selectable' === column.type && properties.onSelectAllClick && (
                          <View
                            style={[
                              SelectableColumnStyles.container,
                              { backgroundColor: 'transparent' },
                            ]}
                          >
                            <TouchableOpacity
                              onPress={() => {
                                properties.onSelectAllClick?.(selected ? [] : items);
                                setSelected(!selected);
                              }}
                              style={[
                                SelectableColumnStyles.checkbox,
                                selected ? SelectableColumnStyles.checked : {},
                              ]}
                            >
                              {selected && (
                                <IconComponent
                                  name="check"
                                  iconStyle={SelectableColumnStyles.checkedIcon}
                                  containerstyle={SelectableColumnStyles.checkedIconContainer}
                                />
                              )}
                            </TouchableOpacity>
                          </View>
                        )}
                        {'order' in column && (
                          <IconComponent
                            name={
                              (order === undefined || order === column.name) && direction === 'desc'
                                ? 'angle-down'
                                : 'angle-up'
                            }
                            containerstyle={TableStyles.orderIconContainer}
                            iconStyle={[
                              TableStyles.orderIcon,
                              order === column.name
                                ? TableStyles.orderIconActive
                                : TableStyles.orderIconInactive,
                            ]}
                          />
                        )}
                      </TouchableOpacity>
                    );
                  })}
                </View>
              )}
              {!loading && !properties.hideNoDataImage && items.length === 0 && (
                <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                  <Image
                    source={require('../../../assets/images/empty-table.png')}
                    style={{ width: width * 0.8, height: height * 0.7, opacity: 0.5 }}
                    resizeMode="center"
                  />
                </View>
              )}
              {items.map((item, index) => (
                <View key={index} style={getRowBackgroundColor(item, index)}>
                  {properties.columns.map((column, index) => {
                    const width = getWidthOfColumn(column);

                    return (
                      <View key={index} style={[TableStyles.columnContainer, { width: width }]}>
                        <ColumnComponent<ITEM, ORDER>
                          component={column}
                          item={item}
                          items={items}
                        />
                      </View>
                    );
                  })}
                </View>
              ))}
            </View>
          </ScrollView>
        </ScrollView>
      )}

      {properties.pagination !== false && !loading && (
        <PaginationComponent
          multiWorkspace={properties.multiWorkspace}
          prefix={properties.prefix}
          page={page}
          pages={pages}
          total={total}
          onChange={(page) => {
            setPage(page);
            setSelected(false);
          }}
        />
      )}
    </View>
  );
}
