import { StyleSheet } from 'react-native';

export const ExpectedInterventionsStyles = StyleSheet.create({
  cardContainer: {
    padding: 20,
    backgroundColor: '#F2F2F2',
    borderRadius: 4,
    marginBottom: 10,
  },
  fiterContainer: {
    zIndex: 1,
    marginVertical: 10,
    flexDirection: 'row',
    gap: 20,
  },
  filterContainerMobile: {
    marginVertical: 10,
    gap: 10,
    zIndex: 10,
  },
  filterTextContainer: {
    marginBottom: 10,
  },
  filterContainer: {
    marginBottom: 10,
    borderRadius: 5,
  },
  filterTitle: {
    fontSize: 12,
    color: 'black',
    fontWeight: '700',
  },
  filterSubtitle: {
    fontSize: 12,
    color: '#888888',
  },
  graphicsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 20,
  },
  graphicsContainerColumn: {
    flexDirection: 'column',
    gap: 20,
  },
  graphicContainer: {
    gap: 30,
    backgroundColor: 'white',
    padding: 20,
    borderRadius: 5,
    flex: 1,
    minHeight: 400,
  },
  exportIcon: {
    fontSize: 20,
    color: 'white',
  },
  exportIconContainer: {
    backgroundColor: '#283038',
    padding: 7,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
  },
  exportButton: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    backgroundColor: '#3F4C57',
    borderRadius: 5,
    gap: 5,
  },
  graphicContainerHeader: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  uploadBox: {
    backgroundColor: 'rgb(17, 27, 34)',
    borderRadius: 4,
  },
  buttonContainer: {
    borderRadius: 4,
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
    overflow: 'hidden',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
  iconContainer: {
    height: 30,
    width: 30,
    backgroundColor: '#00000029',
    borderLeftColor: '#00000017',
    borderLeftWidth: 1,
  },
  icon: {
    fontSize: 16,
    textAlign: 'center',
    lineHeight: 30,
    color: '#fff',
  },
  buttonLabel: {
    color: '#fff',
    fontSize: 10,
    fontWeight: '500',
    lineHeight: 15,
    marginLeft: 15,
  },
  graphicTitle: {
    fontSize: 11,
    color: '#999999',
    fontWeight: '300',
  },
});
