import { TextComponent } from '@components/text/text.component';
import { useDeviceHook } from '@hooks/device/device.hook';
import { View } from 'react-native';
import { BoardNoDataStyles } from './boardNoData.styles';

export function BoardNoDataComponent() {
  const { phone } = useDeviceHook();
  return (
    <View style={BoardNoDataStyles.container}>
      <TextComponent
        style={phone ? BoardNoDataStyles.phoneTitle : BoardNoDataStyles.title}
        text={'board.noData.title'}
        translate
        light
      />
      <TextComponent
        style={phone ? BoardNoDataStyles.phoneDescription : BoardNoDataStyles.description}
        text={'board.noData.description'}
        translate
        light
      />
    </View>
  );
}
