import { OrderTableType } from '@components/table/table.properties';
import { View, Text } from 'react-native';
import { TaskStatusStyles } from './taskStatus.styles';
import { ItemTableType } from '@components/table/table.component';
import { TaskStatusColumnImplementation } from './taskStatus.column.properties';
import { hexToRgba } from '@utils/color/hexToRgba.utility';

export function TaskStatusColumnComponent<
  ITEM extends ItemTableType,
  ORDER extends OrderTableType<ITEM>,
>(props: TaskStatusColumnImplementation<ITEM, ORDER>) {
  const color = props.color?.(props.item);
  const textColor = props.textColor?.(props.item);
  const background = props.background?.(props.item);
  const text = props.onRender?.(props.item);

  return (
    <View style={TaskStatusStyles.container}>
      <View
        style={[
          TaskStatusStyles.wrapper,
          { backgroundColor: color, borderColor: hexToRgba(textColor, 0.15) },
        ]}
      >
        <View style={[TaskStatusStyles.badge, { backgroundColor: textColor }]} />
        <Text style={{ color: textColor, fontSize: 10, lineHeight: 16 }}>{text}</Text>
      </View>
    </View>
  );
}
