import { NavigationProp, RouteProp } from '@react-navigation/native';
import { TimetableRoutes } from '../../../timetables.routes';
import { TabType } from '@components/tabs/tabs.types';
import { TableComponent } from '@components/table/table.component';
import { TimetableDayAccessType } from '@human/access/types/timetableDay.access.type';
import { TimetableDayAccessRepository } from '@human/access/repositories/timetableDay/timetableDay.repository';
import { useTranslation } from 'react-i18next';
import { ButtonComponent } from '@components/button/button.component';
import { ScrollView } from 'react-native';

export function TimetableDayUpdateTimetablePart(properties: {
  navigation: NavigationProp<TimetableRoutes>;
  route: RouteProp<TimetableRoutes, 'update'>;
}): TabType {
  const { t } = useTranslation();
  return {
    name: 'timetableDay',
    icon: 'clock',
    content: () => {
      return (
        <ScrollView>
          <TableComponent<TimetableDayAccessType, []>
            pagination={true}
            search={false}
            prefix={'access.timetables.list'}
            suffix={'list'}
            hideNoDataImage
            repository={(settings) => {
              return new TimetableDayAccessRepository().list({
                page: settings.page,
                limit: settings.limit,
                timetableId: properties.route.params.timetableId,
              });
            }}
            columns={[
              {
                type: 'icon',
                icon: 'pen',
                onPress: (row) => {
                  properties.navigation.navigate('timetableDayUpdate', {
                    level: 1,
                    timetableId: properties.route.params.timetableId,
                    timetableDayId: row.id,
                  });
                },
              },
              {
                type: 'icon',
                icon: 'trash-alt',
                onPress: (row) => {
                  properties.navigation.navigate('timetableDayDelete', {
                    level: 1,
                    timetableId: properties.route.params.timetableId,
                    timetableDayId: row.id,
                  });
                },
              },
              {
                name: 'day',
                type: 'text',
                width: 250,
                onRender: (row) => t(`common.time.weekDays.${row.day}`),
              },
              {
                name: 'range',
                type: 'text',
                width: 250,
                onRender: (row) => `${row.started} - ${row.ended}`,
              },
            ]}
          />
          <ButtonComponent
            icon="plus"
            prefix="access.timetables.update.timetableDays.add"
            onPress={() => {
              properties.navigation.navigate('timetableDayCreate', {
                level: 1,
                timetableId: properties.route.params.timetableId,
              });
            }}
          />
        </ScrollView>
      );
    },
  };
}
