import { NavigationProp, RouteProp } from '@react-navigation/native';
import { FormComponent } from '@components/form/form.component';
import { ModalComponent } from '@components/modal/modal.component';

import { BulletinsRoutes } from '../../bulletins.routes';
export function DeleteBulletinScreen(properties: {
  navigation: NavigationProp<BulletinsRoutes>;
  route: RouteProp<BulletinsRoutes, 'delete'>;
}) {
  return (
    <ModalComponent icon="trash-alt" prefix="access.bulletins.delete">
      <FormComponent
        prefix="access.bulletins.delete.form"
        padding
        fields={[
          {
            name: 'status',
            icon: 'lock',
            type: 'checkbox',
            validations: ['true'],
          },
        ]}
        onNext={() => {
          properties.navigation.navigate('password', {
            bulletinId: properties.route.params.bulletinId,
            clinicId: properties.route.params.clinicId,
            level: 1,
          });
        }}
      />
    </ModalComponent>
  );
}
