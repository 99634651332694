import { useEffect, useRef, useState } from 'react';
import { View, Text } from 'react-native';
import { ContactsStyles } from './contacts.styles';
import { IconComponent } from '@components/icon/icon.component';
import Map, { MapRef, Marker } from 'react-map-gl';
import { MapCrmRepository } from '@human/crm/repositories/map/map.repository';
import { IconType } from '@components/icon/icon.types';
import { RouteProp, NavigationProp } from '@react-navigation/native';
import { DashboardRoutes } from '@human/crm/sections/dashboard/dashboard.routes';
import 'mapbox-gl/dist/mapbox-gl.css';
import { Pressable } from 'react-native-web';
import { MainTheme } from '@theme/main/main.theme';

export function ContactsComponent(properties: {
  route: RouteProp<DashboardRoutes, 'dashboard'>;
  navigation: NavigationProp<DashboardRoutes>;
}) {
  const [markers, setMarkers] = useState<
    {
      latitude: number;
      longitude: number;
      id: string;
      clinicId: string;
      contactId: string;
      initials: string;
      action: string;
      color: string;
    }[]
  >([]);
  const [hovered, setHovered] = useState<string | null>(null);

  const mapRef = useRef<MapRef>(null);

  useEffect(() => {
    new MapCrmRepository().list().then((data) => {
      setMarkers(
        data.items
          .sort((a, b) => new Date(a.created).getTime() - new Date(b.created).getTime())
          .map((item) => ({
            latitude: item.contactLatitude,
            longitude: item.contactLongitude,
            id: item.leadId,
            clinicId: item.clinicId,
            contactId: item.contactId,
            initials: item.contactName[0] + (item.contactSurname ? item.contactSurname[0] : ''),
            action: item.activityIcon,
            color: item.activityColor,
          }))
      );
      setTimeout(() => {
        if (mapRef.current) {
          mapRef.current.flyTo({
            center: [-3.70379, 40.416775],
            zoom: 6,
            duration: 6000,
          });
        }
      }, 1000);
    });
  }, []);

  const handleClick = (settings: { leadId: string; clinicId: string; contactId: string }) => {
    properties.navigation.navigate('leadUpdate', {
      id: settings.leadId,
      clinicId: settings.clinicId,
      contactId: settings.contactId,
    });
  };

  return (
    <View style={ContactsStyles.container}>
      <Map
        ref={mapRef}
        mapboxAccessToken="pk.eyJ1Ijoic2FsYmF0b3JlIiwiYSI6ImNseGppd3ZkNDFxdmQycHFwZTZtaGQ5emwifQ.3QZrmpKvZNja_3yjPyYjZg"
        initialViewState={{
          longitude: -122.4,
          latitude: 37.8,
          zoom: 1,
        }}
        projection={{ name: 'globe' }}
        style={{ height: 400, backgroundColor: 'rgb(36, 9, 42)' }}
        mapStyle="mapbox://styles/mapbox/outdoors-v12"
      >
        {markers.map((marker) => (
          <Marker
            key={marker.id}
            longitude={marker.longitude}
            latitude={marker.latitude}
            onClick={() =>
              handleClick({
                leadId: marker.id,
                clinicId: marker.clinicId,
                contactId: marker.contactId,
              })
            }
          >
            <Pressable
              style={{
                height: 60,
                width: 60,
                backgroundColor:
                  hovered === marker.id ? 'rgba(237, 53, 145, 0.15)' : 'rgba(0, 0, 0, 0.15)',
                borderRadius: 30,
                alignContent: 'center',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              onMouseEnter={() => setHovered(marker.id)}
              onMouseLeave={() => setHovered(null)}
            >
              <IconComponent
                iconStyle={{
                  color: '#fff',
                  fontSize: 16,
                  fontWeight: 'bold',
                }}
                name={marker.action as IconType}
                containerstyle={{
                  position: 'absolute',
                  top: -2,
                  right: -2,
                  height: 30,
                  width: 30,
                  zIndex: 100,
                  backgroundColor: marker.color,
                  borderRadius: 25,
                  alignContent: 'center',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              />
              <View
                style={{
                  height: 36,
                  width: 36,
                  backgroundColor:
                    hovered === marker.id ? MainTheme.primaryColor : 'rgb(36, 9, 42)',
                  borderRadius: 30,
                  alignContent: 'center',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Text
                  numberOfLines={1}
                  style={{
                    color: 'white',
                    fontSize: 10,
                    fontWeight: 'bold',
                  }}
                >
                  {marker.initials}
                </Text>
              </View>
            </Pressable>
          </Marker>
        ))}
      </Map>
    </View>
  );
}
