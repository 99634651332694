import { NavigationProp, RouteProp, useNavigation } from '@react-navigation/native';
import { SceneComponent } from '@components/scene/scene.component';
import { CrmSections } from '@human/crm/crm.sections';
import { TableComponent } from '@components/table/table.component';
import { ChannelsRoutes } from '../../channels.routes';
import { ChannelCrmRepository } from '@human/crm/repositories/channel/channel.repository';
import { ChannelCrmType } from '@human/crm/types/channel.crm.type';
import { CrmRoutes } from '@human/crm/crm.routes';
import { IconType } from '@components/icon/icon.types';
import { hasPermissionUtilty } from '@utils/permission/has.permission.utility';
import { ColumnProperties } from '@components/table/components/columns/column.properties';

export function ListChannelScreen(properties: {
  navigation: NavigationProp<ChannelsRoutes>;
  route: RouteProp<ChannelsRoutes, 'list'>;
}) {
  const crmNavigation = useNavigation<NavigationProp<CrmRoutes>>();

  const links: {
    name: string;
    icon: IconType;
    onPress: () => void;
  }[] = [];
  if (
    hasPermissionUtilty({
      name: 'crm.channels.creator',
    })
  ) {
    links.push({
      icon: 'plus',
      name: 'create',
      onPress: () => properties.navigation.navigate('create'),
    });
  }

  const columns: ColumnProperties<ChannelCrmType, []>[] = [
    {
      type: 'icon',
      icon: hasPermissionUtilty({ name: 'crm.channels.editor' }) ? 'pencil-alt' : 'eye',
      onPress: (row) => {
        properties.navigation.navigate('update', {
          id: row.id,
        });
      },
    },
    {
      type: 'text',
      name: 'name',
      onRender: (row) => row.name,
      width: 200,
    },
    {
      type: 'number',
      name: 'funnels',
      onRender: (row) => row.funnels ?? 0,
      onPress: (row) => {
        crmNavigation.navigate('funnels', {
          screen: 'list',
          params: {
            channelId: row.id,
          },
        });
      },
      width: 105,
    },
    {
      type: 'bar',
      onTotal: (row) => row.funnels ?? 0,
      onMax: (rows) => rows.reduce((max, row) => Math.max(max, row.funnels ?? 0), 0),
      width: 105,
    },
    {
      type: 'date',
      name: 'created',
      renderDate: (row) => row.created,
    },
  ];

  if (hasPermissionUtilty({ name: 'crm.channels.editor' })) {
    columns.unshift({
      type: 'flag',
      status: (row) => row.status,
      onPress: (row) => {
        properties.navigation.navigate(row.status ? 'disable' : 'enable', {
          id: row.id,
        });
      },
    });
  }

  return (
    <SceneComponent icon={CrmSections.channels.icon} prefix={'crm.channels.list'} links={links}>
      <TableComponent<ChannelCrmType, []>
        prefix={'crm.channels.list'}
        suffix={'list'}
        repository={(settings) =>
          new ChannelCrmRepository().list({
            page: settings.page,
            limit: settings.limit,
            direction: settings.direction,
            search: settings.search,
          })
        }
        columns={columns}
      />
    </SceneComponent>
  );
}
