import { StyleSheet } from 'react-native';

export const inactiveLeadsReasonTableRowStyles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-between',
    paddingVertical: 5,
    borderBottomWidth: 0.5,
    borderBottomColor: '#ddd',
    backgroundColor: '#FFF',
  },
  reasonContainer: {
    width: 200,
    justifyContent: 'center',
    marginVertical: 10,
  },
  reasonText: {
    fontSize: 12,
    color: 'black',
  },
  columnsContainer: {
    flex: 10,
    flexDirection: 'row',
  },
  columnContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  futureText: {
    fontWeight: 'normal',
    opacity: 0.3,
  },
});
