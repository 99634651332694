import { View, Text, Pressable } from 'react-native';
import React from 'react';
import { ComparativeYearsLeadCountRows } from './comparativeYearsLeadCountRow.styles';
import { useDeviceHook } from '@hooks/device/device.hook';

export function ComparativeYearsLeadCountRow(properties: {
  item: {
    department_id: string;
    department_name: string;
    total_leads: number[];
  };
  firstAttemptRow: boolean | undefined;
  isLossRow: boolean | undefined;
  firstYear: number;
  lastYear: number;
  last: boolean;
  onCellPress: (settings: {
    departmentId: string | undefined;
    year: number;
    month?: number;
  }) => void; // Si el departamento es undefined, es porque se le ha dado al departamento 'Total'
}) {
  const { phone } = useDeviceHook();

  const lastYearIsActual = new Date().getFullYear() === properties.lastYear;
  return (
    <View
      style={[
        phone
          ? ComparativeYearsLeadCountRows.rowContainerMobile
          : ComparativeYearsLeadCountRows.rowContainer,
        properties.last && ComparativeYearsLeadCountRows.rowContainerLast,
        properties.isLossRow && ComparativeYearsLeadCountRows.rowContainerLoss,
        properties.firstAttemptRow && ComparativeYearsLeadCountRows.rowContainerFirstAttempt,
      ]}
    >
      <View
        style={
          phone
            ? ComparativeYearsLeadCountRows.subRowSpaceColumnContainerMobile
            : ComparativeYearsLeadCountRows.subRowSpaceColumnContainer
        }
      >
        <Text
          style={[
            ComparativeYearsLeadCountRows.subRowSpaceText,
            (properties.isLossRow || properties.firstAttemptRow || properties.last) && {
              fontWeight: '500',
            },
            properties.isLossRow && ComparativeYearsLeadCountRows.subRowLossText,
            phone && ComparativeYearsLeadCountRows.phoneSubRowSpaceText,
          ]}
        >
          {properties.item.department_name}
        </Text>
      </View>

      {properties.item.total_leads.map((total, index) => (
        <>
          <Pressable
            onPress={() =>
              properties.onCellPress({
                departmentId:
                  properties.item.department_name !== 'Total'
                    ? properties.item.department_id
                    : undefined,
                year: index % 2 === 0 ? properties.firstYear : properties.lastYear,
                month:
                  properties.item.total_leads.length === 24
                    ? parseInt((index / 2 + 1).toString())
                    : undefined,
              })
            }
            disabled={index % 2 !== 0 && lastYearIsActual && index / 2 >= new Date().getMonth() + 1}
            style={
              phone
                ? ComparativeYearsLeadCountRows.phoneSubRowColumnContainer
                : ComparativeYearsLeadCountRows.subRowColumnContainer
            }
          >
            <Text
              style={[
                ComparativeYearsLeadCountRows.subRowText,
                phone && ComparativeYearsLeadCountRows.phoneSubRowText,
                (index % 2 === 0 || (lastYearIsActual && index / 2 >= new Date().getMonth() + 1)) &&
                  ComparativeYearsLeadCountRows.subRowFutureText,
                properties.isLossRow && ComparativeYearsLeadCountRows.subRowLossText,
                // new Date().getMonth() < 0 && ComparativeYearsLeadCountRows.subRowFutureText,
              ]}
            >
              {index % 2 !== 0 && lastYearIsActual && index / 2 >= new Date().getMonth() + 1
                ? '-'
                : total}
            </Text>
          </Pressable>
          {index % 2 !== 0 && index !== properties.item.total_leads.length - 1 && (
            <View style={ComparativeYearsLeadCountRows.subRowDivider} />
          )}
        </>
      ))}
    </View>
  );
}
