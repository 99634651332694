import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ModalComponent } from '@components/modal/modal.component';
import { FormComponent } from '@components/form/form.component';
import { CreateTaskMassivelyRoutes } from '../../createTaskMassively.routes';
import { ActivityCrmRepository } from '@human/crm/repositories/activity/activity.repository';
import { ActionCrmRepository } from '@human/crm/repositories/action/action.repository';
import { useTranslation } from 'react-i18next';

export function CreateTaskMassivelySelectActivity(props: {
  navigation: NavigationProp<CreateTaskMassivelyRoutes>;
  route: RouteProp<CreateTaskMassivelyRoutes, 'createTasks'>;
}) {
  const { t } = useTranslation();

  return (
    <ModalComponent icon="tasks" prefix="crm.createTaskMassively.selectActivity">
      <FormComponent<{ activityId: string; subject: string }>
        prefix="crm.createTaskMassively.selectActivity.form"
        padding
        repository={{
          send: async (data) => {
            if (!data.item.activityId) {
              alert(t('crm.createTaskMassively.selectActivity.form.activityId.required'));
              return;
            }

            return new ActionCrmRepository()
              .createMassively({
                activityId: data.item.activityId,
                subject: data.item.subject ?? '',
                leadsData: props.route.params.leadsData,
              })
              .then(() => {
                props.navigation.goBack();
              });
          },
        }}
        fields={[
          {
            type: 'select',
            name: 'activityId',
            description: true,
            required: true,
            options: [],
            repository: (settings) => new ActivityCrmRepository().listForCombo({}),
          },
          {
            type: 'text',
            name: 'subject',
            description: true,
          },
        ]}
      />
    </ModalComponent>
  );
}
