import { StyleSheet } from 'react-native';

export const WebhookCampaignTabStyles = StyleSheet.create({
  label: {
    fontSize: 13,
    color: '#010101',
    fontWeight: 'bold',
  },
  subtitle: {
    fontSize: 12,
    opacity: 0.6,
  },
  labelContainer: {
    gap: 1,
    marginBottom: 10,
  },
});
