import { NavigationProp, RouteProp } from '@react-navigation/native';
import { UsersRoutes } from '../../../users.routes';
import { ModalComponent } from '@components/modal/modal.component';
import { FormComponent } from '@components/form/form.component';
import { LanguageAccessRepository } from '@human/access/repositories/language/language.repository';
import { UserAccessRepository } from '@human/access/repositories/user/user.repository';

export function CreateLanguageScreen(properties: {
  navigation: NavigationProp<UsersRoutes>;
  route: RouteProp<UsersRoutes, 'createUserLanguage'>;
}) {
  return (
    <ModalComponent
      level={properties.route.params.level}
      icon="lock"
      prefix="access.users.update.languages.create"
    >
      <FormComponent<{
        languageId: string;
      }>
        padding
        prefix="access.users.update.languages.create.form"
        repository={{
          send: (settings: {
            item: Partial<{
              languageId: string;
            }>;
          }) => {
            return new UserAccessRepository()
              .addLanguage({
                languageId: settings.item.languageId || '',
                userId: properties.route.params.userId,
              })
              .then(() => {
                properties.navigation.goBack();
              });
          },
        }}
        fields={[
          {
            type: 'select',
            name: 'languageId',
            required: true,
            description: true,
            pick: (properties: { id: string }) => {
              return new LanguageAccessRepository().pickForCombo({ languageId: properties.id });
            },
            repository: ({ search }) => {
              return new LanguageAccessRepository().listForCombo({ search, active: true });
            },
          },
        ]}
      />
    </ModalComponent>
  );
}
