import { NavigationProp, RouteProp } from '@react-navigation/native';
import { InterventionsRoutes } from '../../interventions.routes';
import { ModalComponent } from '@components/modal/modal.component';
import { FormComponent } from '@components/form/form.component';
import { InterventionCrmType } from '@human/crm/types/intervention.crm.type';
import { InterventionCrmRepository } from '@human/crm/repositories/intervention/intervention.repository';
import { ClinicAccessRepository } from '@human/access/repositories/clinic/clinic.repository';
import { CycleCrmRepository } from '@human/crm/repositories/cycles/cycle.repository';
import { ProgramCycleCrmRepository } from '@human/crm/repositories/programCycle/programCycle.repository';

export function CreateInterventionScreen(properties: {
  navigation: NavigationProp<InterventionsRoutes>;
  route: RouteProp<InterventionsRoutes, 'createIntervention'>;
}) {
  return (
    <ModalComponent
      level={properties.route.params.level}
      icon="heartbeat"
      prefix="crm.interventions.create"
    >
      <FormComponent<InterventionCrmType>
        prefix="crm.interventions.create.form"
        padding
        repository={{
          get: async () => ({
            interventionClinicId: properties.route.params.clinicId,
          }),
          send: (settings) => {
            return new InterventionCrmRepository()
              .create({
                item: settings.item,
                clinicId: properties.route.params.clinicId,
                patientId: properties.route.params.patientId,
                treatmentId: properties.route.params.treatmentId,
              })
              .then(() => {
                properties.navigation.goBack();
              });
          },
        }}
        fields={[
          {
            type: 'select',
            name: 'interventionClinicId',
            required: true,
            description: true,
            repository: ({ search }) => {
              return new ClinicAccessRepository().listForCombo({
                search,
                active: true,
              });
            },
          },
          {
            type: 'select',
            name: 'cycleId',
            description: true,
            required: true,
            pick: (settings: { id: string }) => {
              return new CycleCrmRepository().pick({ cycleId: settings.id }).then((cycle) => {
                return `${cycle.name}`;
              });
            },
            repository: ({ search }) => {
              return new ProgramCycleCrmRepository()
                .list({ search, programId: properties.route.params.programId })
                .then((response) =>
                  response.items.map((item) => ({ label: item.cycleName, value: item.cycleId }))
                );
            },
          },
          {
            type: 'date',
            name: 'expectedDate',
            required: true,
            description: true,
            format: 'YYYY-MM-DD HH:mm:ss',
          },
          {
            type: 'date',
            name: 'interventionDate',
            description: true,
            format: 'YYYY-MM-DD HH:mm:ss',
          },
          {
            type: 'text',
            name: 'observation',
            required: true,
            description: true,
            validations: ['observation'],
          },
        ]}
      />
    </ModalComponent>
  );
}
