import { ButtonComponent } from '@components/button/button.component';
import { TableComponent } from '@components/table/table.component';
import { ParticipantRepository } from '@human/crm/repositories/participant/participant.repository';
import { ParticipantCrmType } from '@human/crm/types/participant.crm.type';
import { NavigationProp, RouteProp } from '@react-navigation/native';
import { TabType } from '@components/tabs/tabs.types';
import { CampaignsRoutes } from '../../../campaigns.routes';
import { AccessSections } from '@human/access/access.sections';
import { ScrollView, View } from 'react-native';
import { SubmitComponent } from '@components/submit/submit.component';
import * as Clipboard from 'expo-clipboard';
import { useTranslation } from 'react-i18next';
import { hasPermissionUtilty } from '@utils/permission/has.permission.utility';

export function ListParticipantsCampaignScreen(properties: {
  navigation: NavigationProp<CampaignsRoutes>;
  route: RouteProp<CampaignsRoutes, 'campaignUpdate'>;
  showSuccess: (label?: string) => void;
}): TabType {
  const { t } = useTranslation();
  return {
    name: 'participants',
    icon: AccessSections.clinics.icon,
    content: () => {
      return (
        <ScrollView>
          <TableComponent<ParticipantCrmType, []>
            key={'participants'}
            prefix={'crm.campaigns.update.participants.list'}
            suffix={'list'}
            pagination={false}
            repository={() =>
              new ParticipantRepository().list({ campaignId: properties.route.params.id })
            }
            columns={[
              {
                type: 'icon',
                icon: 'trash-alt',
                onPress: (row) => {
                  properties.navigation.navigate('deleteParticipant', {
                    campaignId: properties.route.params.id,
                    clinicId: row.clinicId,
                    level: 1,
                  });
                },
              },
              {
                type: 'icon',
                icon: 'clipboard',
                onPress: (row) => {
                  Clipboard.setStringAsync(row.clinicId).then(() => {
                    properties.showSuccess('crm.campaigns.update.participants.clinicIdCopied');
                  });
                },
              },
              {
                type: 'text',
                name: 'clinicName',
                width: 200,
                onRender: (row) => row.clinicName,
              },
            ]}
            hideNoDataImage
          />
          {hasPermissionUtilty({ name: 'crm.campaigns.editor' }) && (
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <SubmitComponent
                icon="plus"
                prefix="crm.campaigns.update.participants.addAll"
                repository={async () =>
                  new ParticipantRepository()
                    .createAll({ campaignId: properties.route.params.id })
                    .then(() =>
                      properties.showSuccess('crm.campaigns.update.participants.clinicsAdded')
                    )
                }
              />
              <ButtonComponent
                icon="plus"
                prefix="crm.campaigns.update.participants.add"
                onPress={() => {
                  properties.navigation.navigate('createParticipant', {
                    campaignId: properties.route.params.id,
                    level: 1,
                  });
                }}
              />
            </View>
          )}
        </ScrollView>
      );
    },
  };
}
