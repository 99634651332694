import { TableComponent } from '@components/table/table.component';
import { TabType } from '@components/tabs/tabs.types';
import { NavigationProp, RouteProp } from '@react-navigation/native';
import { PatientsRoutes } from '../../../patient.routes';
import { PurchaseCrmRepository } from '@human/crm/repositories/purchase/purchase.repository';
import { PurchaseCrmType, PurchaseType } from '@human/crm/types/purchase.crm.type';
import { useTranslation } from 'react-i18next';
import { ScrollView } from 'react-native';

export function PurchasesUpdatePatientPart(properties: {
  navigation: NavigationProp<PatientsRoutes>;
  route: RouteProp<PatientsRoutes, 'patientUpdate'>;
}): TabType {
  const { t } = useTranslation();

  /**
   *
   * @param item
   * Funcion para sacar el nombre de la compra. Puede ser de mantenimiento, tecnica o tratamiento
   * @returns
   */
  const getName = (item: PurchaseCrmType): string => {
    switch (item.purchaseType) {
      case 'MAINTENANCE':
        return item.maintenanceName || '';
      case 'TECHNIQUE':
        return item.techniqueName || '';
      case 'TREATMENT':
        return item.treatmentName;
    }
  };

  return {
    name: 'purchase',
    icon: 'money-bill',
    content: () => (
      <ScrollView>
        <TableComponent<PurchaseCrmType, []>
          hideNoDataImage
          prefix="crm.patients.update.tabs.purchase"
          suffix="list"
          repository={(setting) =>
            new PurchaseCrmRepository().list({
              clinicId: properties.route.params.clinicId,
              patientId: properties.route.params.patientId,
              search: setting.search,
              page: setting.page,
              limit: setting.limit,
            })
          }
          columns={[
            {
              type: 'flag',
              status: (row) => row.status,
              onPress: (row) => {
                properties.navigation.navigate(row.status ? 'disablePurchase' : 'enablePurchase', {
                  clinicId: properties.route.params.clinicId,
                  patientId: properties.route.params.patientId,
                  purchaseId: row.id,
                  level: 1,
                });
              },
            },
            {
              type: 'icon',
              icon: 'pen',
              onPress: (row) => {
                properties.navigation.navigate('updatePurchase', {
                  level: 1,
                  clinicId: properties.route.params.clinicId,
                  patientId: properties.route.params.patientId,
                  purchaseId: row.id,
                });
              },
            },
            {
              type: 'multipleBadge',
              name: 'purchaseType',
              onRender: (item) => [t(`common.purchaseType.${item.purchaseType}`)],
              width: 150,
            },
            {
              type: 'text',
              name: 'treatmentName',
              onRender: getName,
              width: 200,
            },
            {
              type: 'price',
              name: 'price',
              onRender: (item) => item.price,
              onCurrency: () => '€',
              width: 100,
            },
          ]}
        />
      </ScrollView>
    ),
  };
}
