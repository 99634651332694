import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ContactsRoutes } from '../../../contacts.routes';
import { TableComponent } from '@components/table/table.component';
import { ActionCrmRepository } from '@human/crm/repositories/action/action.repository';
import { IconType } from '@components/icon/icon.types';
import { ScrollView } from 'react-native';

export function ActionUpdatePart(properties: {
  navigation: NavigationProp<ContactsRoutes>;
  route: RouteProp<ContactsRoutes, 'update'>;
}) {
  return (
    <ScrollView>
      <TableComponent<
        {
          id: string;
          activityId: string;
          activityName: string;
          activityColor: string;
          activityIcon: string;
          contactSurname: string;
          leadId: string;
          patientId?: string;
          clinicId: string;
          clinicName: string;
          clinicColor: string;
          contactId: string;
          contactName: string;
          responsibleName: string;
          responsibleSurname: string;
          resultId: string;
          started: Date;
          finished: Date;
          created: Date;
          selfie: string;
          campaignName: string;
        },
        []
      >
        suffix={'list'}
        prefix={'crm.tasks.list'}
        hideNoDataImage
        repository={(settings) =>
          new ActionCrmRepository().findByContact({
            contactId: properties.route.params.contactId,
            direction: settings.direction,
            page: settings.page,
            limit: settings.limit,
          })
        }
        columns={[
          {
            type: 'icon',
            icon: 'pencil-alt',
            onPress: (row) => {
              properties.navigation.navigate('actionUpdate', {
                actionId: row.id,
                clinicId: row.clinicId,
                contactId: row.contactId,
                leadId: row.leadId,
                patientId: row.patientId,
                level: 1,
                activityId: row.activityId,
              });
            },
          },
          {
            type: 'iconText',
            name: 'activity',
            color: (row) => row.activityColor,
            text: (row) => row.activityName,
            icon: (row) => row.activityIcon as IconType,
          },
          {
            type: 'text',
            name: 'campaign',
            onRender: (row) => row.campaignName,
            width: 200,
          },
          {
            type: 'clinic',
            name: 'clinic',
            onRender: (row) => row.clinicName,
            color: (row) => row.clinicColor,
            width: 200,
          },
          {
            type: 'user',
            name: 'user',
            onRender: (row) => `${row.responsibleName} ${row.responsibleSurname}`,
            onRenderSelfie: (row) => row.selfie,
            width: 200,
          },
          {
            type: 'date',
            name: 'started',
            renderDate: (row) => row.started,
          },
          {
            type: 'date',
            name: 'finished',
            renderDate: (row) => row.finished,
          },
        ]}
      />
    </ScrollView>
  );
}
