import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ActionsRoutes } from '../../../actions.routes';
import { ModalComponent } from '@components/modal/modal.component';
import { FormComponent } from '@components/form/form.component';
import { LeadCrmRepository } from '@human/crm/repositories/lead/lead.repository';
import { ReasonCrmRepository } from '@human/crm/repositories/reason/reason.repository';
import { addDays } from '@utils/addDays/addDays.utility';

export function UpdateActionDisableLeadPart(properties: {
  navigation: NavigationProp<ActionsRoutes>;
  route: RouteProp<ActionsRoutes, 'actionDisableLead'>;
}) {
  return (
    <ModalComponent icon="trash-alt" prefix="crm.leads.disable" confirmationMessage>
      <FormComponent<{
        reasonId: string;
      }>
        prefix="crm.leads.disable.form"
        padding
        repository={{
          send: (data) => {
            return new LeadCrmRepository()
              .disable({
                leadId: properties.route.params.leadId,
                clinicId: properties.route.params.clinicId,
                reasonId: data.item.reasonId,
              })
              .then(() => {
                properties.navigation.goBack();
                if (properties.route.params.nextActivityId)
                  properties.navigation.navigate('actionNextResultUpdate', {
                    actionId: properties.route.params.actionId,
                    clinicId: properties.route.params.clinicId,
                    leadId: properties.route.params.leadId,
                    level: properties.route.params.level,
                    activityId: properties.route.params.nextActivityId,
                    started: properties.route.params.nextActivityId
                      ? addDays(new Date(), properties.route.params.nextActivityDelay)
                      : new Date(),
                  });
              });
          },
        }}
        fields={[
          {
            type: 'select',
            name: 'reasonId',
            nullable: true,
            description: true,
            pick: (properties: { id: string }) => {
              return new ReasonCrmRepository().pick({ id: properties.id }).then((reason) => {
                return `${reason.name}`;
              });
            },
            repository: ({ search }) =>
              new ReasonCrmRepository()
                .list({
                  search,
                  direction: 'desc',
                  limit: 100,
                  page: 0,
                  active: true,
                })
                .then((reasons) =>
                  reasons.items.map((reason) => ({
                    value: reason.id,
                    label: reason.name,
                  }))
                ),
          },
        ]}
      />
    </ModalComponent>
  );
}
