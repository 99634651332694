import { NavigationProp, RouteProp } from '@react-navigation/native';
import { MemberRoutes } from '../member.routes';
import { ModalComponent } from '@components/modal/modal.component';
import { FormComponent } from '@components/form/form.component';
import { MemberAccessRepository } from '@human/access/repositories/member/member.repository';

export function DeleteMemberScreen(properties: {
  navigation: NavigationProp<MemberRoutes>;
  route: RouteProp<MemberRoutes, 'memberDelete'>;
}) {
  return (
    <ModalComponent
      level={properties.route.params.level}
      icon="trash-alt"
      prefix="access.members.delete"
    >
      <FormComponent
        repository={{
          send: () =>
            new MemberAccessRepository()
              .delete({
                clinicId: properties.route.params.clinicId,
                departmentId: properties.route.params.departmentId,
                contractId: properties.route.params.contractId,
              })
              .then(() => properties.navigation.goBack()),
        }}
        prefix="access.members.delete.form"
        padding
        fields={[]}
      />
    </ModalComponent>
  );
}
