import { TabType } from '@components/tabs/tabs.types';
import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ActivitiesRoutes } from '../../../activity.routes';
import { ActivityCrmRepository } from '@human/crm/repositories/activity/activity.repository';
import { FormComponent } from '@components/form/form.component';
import { ResultCrmRepository } from '@human/crm/repositories/result/result.repository';

export function UpdateActivityIntegrationsPart(properties: {
  navigation: NavigationProp<ActivitiesRoutes>;
  route: RouteProp<ActivitiesRoutes, 'update'>;
  showSuccess: () => void;
}): TabType {
  return {
    name: 'integrations',
    icon: 'network-wired',
    content: () => (
      <FormComponent<{
        landbotId: string;
        landbotTemplateId: string;
        landbotActivityId: string;
        landbotResultId: string;
      }>
        prefix="crm.activities.update.integrations"
        repository={{
          get: () =>
            new ActivityCrmRepository().pickIntegrations({
              activityId: properties.route.params.activityId,
            }),
          send: (data) =>
            new ActivityCrmRepository()
              .updateIntegrations({
                activityId: properties.route.params.activityId,
                item: {
                  landbotId: data.item.landbotId,
                  landbotTemplateId: data.item.landbotTemplateId,
                  landbotActivityId: data.item.landbotActivityId,
                  landbotResultId: data.item.landbotResultId,
                },
              })
              .then(properties.showSuccess),
        }}
        fields={[
          {
            type: 'text',
            name: 'landbotId',
            description: true,
            required: true,
            validations: [],
          },
          {
            type: 'text',
            name: 'landbotTemplateId',
            description: true,
            required: true,
            validations: [],
          },
          {
            type: 'select',
            name: 'landbotActivityId',
            description: true,
            required: true,
            pick: (props: { id: string }) => {
              return new ActivityCrmRepository().pickForCombo({
                activityId: props.id,
              });
            },
            repository: ({ search }) =>
              new ActivityCrmRepository().listForCombo({
                search,
                active: true,
              }),
          },
          {
            type: 'select',
            name: 'landbotResultId',
            description: true,
            required: true,
            pick: (props: { id: string }) => {
              return new ResultCrmRepository()
                .pick({
                  resultId: props.id,
                  activityId: properties.route.params.activityId,
                })
                .then((activity) => {
                  return activity.name;
                });
            },
            repository: ({ search }) =>
              new ResultCrmRepository()
                .findByActivity({
                  page: 0,
                  limit: 100,
                  direction: 'asc',
                  activityId: properties.route.params.activityId,
                  search,
                })
                .then((data) => {
                  return data.items.map((item) => ({
                    value: item.id,
                    label: item.name,
                  }));
                }),
          },
        ]}
      />
    ),
  };
}
