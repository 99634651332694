// Modules
import { StyleSheet } from 'react-native';

/**
 * LeadProfile Styles
 * @constant {StyleSheet} LeadProfileStyles
 */
export const LeadProfileStyles = StyleSheet.create({
  header: {
    flexDirection: 'row',
    padding: 20,
    gap: 15,
    alignItems: 'center',
  },
  close: {
    position: 'absolute',
    top: 4,
    right: 0,
    zIndex: 2,
    height: 40,
    width: 40,
    borderRadius: 20,
    justifyContent: 'center',
  },
  closeShape: {
    borderRadius: 20,
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 'auto',
    fontSize: 25,
    color: 'rgba(255, 255, 255, 0.2)',
  },
  closeIcon: {
    flexGrow: 1,
    height: 60,
  },
  collapse: {
    bottom: 0,
    position: 'absolute',
  },
  container: {
    flex: 1,
    backgroundColor: '#27343d',
    width: 500,
    maxWidth: 400,
    zIndex: 10000000,
  },

  headerContainer: {
    marginBottom: 10,
  },

  headerContent: {
    flexDirection: 'row',
    gap: 20,
  },
  profilePicture: {
    width: 70,
    height: 70,
    borderRadius: 100,
    backgroundColor: '#66666666',
    alignItems: 'center',
    justifyContent: 'center',
  },
  profilePictureText: {
    color: 'rgba(255, 255, 255, 0.6)',
    fontSize: 30,
  },
  headerText: {
    gap: 10,
    margin: 5,
  },
  headerNameContainer: {
    maxWidth: 200,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 10,
  },
  headerName: {
    color: '#eee',
    fontSize: 20,
  },
  externalLinkIcon: {
    color: '#86305D',
    fontSize: 20,
  },
  status: {
    borderWidth: 1,
    borderRadius: 15,
    paddingHorizontal: 10,
    paddingVertical: 2,
    alignItems: 'center',
    flexDirection: 'row',
    gap: 6,
    alignSelf: 'flex-start',
  },

  statusIndicator: {
    borderWidth: 1.5,
    marginLeft: 2,
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 4,
    height: 8,
    width: 8,
    borderRadius: 20,
  },

  canceledStatus: {
    backgroundColor: 'rgba(249, 65, 68, 0.1)',
    borderColor: 'rgba(249, 65, 68, 0.2)',
  },
  canceledStatusIndicator: {
    backgroundColor: 'rgba(249, 65, 68, 0.5)',
    borderColor: 'rgba(249, 65, 68, 0.8)',
  },
  canceledStatusText: {
    color: 'rgba(249, 65, 68, 0.8)',
  },
  
  enableStatus: {
    backgroundColor: 'rgba(144, 190, 109, 0.1)',
    borderColor: 'rgba(144, 190, 109, 0.2)',
  },
  enableStatusIndicator: {
    backgroundColor: 'rgba(144, 190, 109, 0.3)',
    borderColor: 'rgba(144, 190, 109, 0.6)',
  },
  enableStatusText: {
    color: 'rgba(144, 190, 109, 1)',
  },

  disableStatus: {
    borderColor: 'rgba(255, 165, 0, 0.1)',
    backgroundColor: 'rgba(255, 165, 0, 0.2)',
  },
  disableStatusIndicator: {
    borderColor: 'rgba(255, 165, 0, 0.8)',
    backgroundColor: 'rgba(255, 165, 0, 0.5)',
  },
  disableStatusText: {
    color: 'rgba(255, 165, 0, 0.9)',
  },

  convertedStatus: {
    borderColor: 'rgba(237, 53, 145, 0.1)',
    backgroundColor: 'rgba(237, 53, 145, 0.2)',
  },
  convertedStatusIndicator: {
    borderColor: 'rgba(237, 53, 145, 0.8)',
    backgroundColor: 'rgba(237, 53, 145, 0.5)',
  },
  convertedStatusText: {
    color: 'rgba(225, 225, 225, 0.9)',
  },

  statusText: {
    color: 'rgba(255, 255, 255, 0.6)',
    fontSize: 12,
  },
  iconRow: {
    flexDirection: 'row',
    gap: 10,
    alignItems: 'center',
    paddingLeft: 20,
  },
  iconContainer: {
    width: 30,
    height: 30,
    borderRadius: 30,
    backgroundColor: '#66666666',
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconStyle: {
    color: 'rgba(255, 255, 255, 0.6)',
    fontSize: 18,
  },
});
