import { NavigationProp, RouteProp } from '@react-navigation/native';
import { PatientsRoutes } from '../../patient.routes';
import { SceneComponent } from '@components/scene/scene.component';
import { CrmSections } from '@human/crm/crm.sections';
import { TableComponent } from '@components/table/table.component';
import { PatientCrmType } from '@human/crm/types/patient.crm.type';
import { PatientCrmRepository } from '@human/crm/repositories/patient/patient.repository';
import { IconType } from '@components/icon/icon.types';
import { hasPermissionUtilty } from '@utils/permission/has.permission.utility';
import { ColumnProperties } from '@components/table/components/columns/column.properties';
import { emptyValue } from '@constants/emptyValue.constant';

export function ListPatientsScreen(properties: {
  navigation: NavigationProp<PatientsRoutes>;
  route: RouteProp<PatientsRoutes, 'patientList'>;
}) {
  const links: {
    name: string;
    icon: IconType;
    onPress: () => void;
  }[] = [];
  if (
    hasPermissionUtilty({
      name: 'crm.patients.creator',
    })
  ) {
    links.push({
      icon: 'plus',
      name: 'create',
      onPress: () => properties.navigation.navigate('patientCreate'),
    });
  }

  const columns: ColumnProperties<
    PatientCrmType & {
      phones: {
        id: string;
        phone: string;
        prefix: string;
      }[];
    },
    []
  >[] = [
    {
      type: 'icon',
      icon: hasPermissionUtilty({ name: 'crm.patients.editor' }) ? 'pencil-alt' : 'eye',
      onPress: (row) => {
        properties.navigation.navigate('patientUpdate', {
          patientId: row.id,
          contactId: row.contactId,
          clinicId: row.clinicId,
          level: 1,
        });
      },
    },
    {
      type: 'text',
      name: 'contact.name',
      width: 200,
      onRender: (row) => row.contactName,
    },
    {
      name: 'phones',
      type: 'text',
      width: 200,
      onRender: (row) =>
        row.phones && row.phones.length > 0
          ? `${row.phones[row.phones.length - 1].prefix} ${row.phones[row.phones.length - 1].phone}`
          : '',
    },
    {
      name: 'description',
      type: 'text',
      width: 200,
      onRender: (row) => `${row.description}`,
    },
    {
      name: 'clinic.name',
      type: 'clinic',
      width: 200,
      onRender: (row) => row.clinicName,
      color: (row) => row.clinicColor,
    },
    {
      name: 'lastCycleClinic.name',
      type: 'clinic',
      width: 200,
      onRender: (row) => row.lastCycleClinicName ?? emptyValue,
      color: (row) => row.lastCycleClinicColor,
    },
    {
      name: 'created',
      type: 'date',
      renderDate: (row) => row.created,
    },
  ];

  if (hasPermissionUtilty({ name: 'crm.patients.editor' })) {
    columns.unshift({
      type: 'flag',
      status: (row) => row.status,
      onPress: (row) => {
        properties.navigation.navigate(row.status ? 'patientDisable' : 'patientEnable', {
          patientId: row.id,
          clinicId: row.clinicId,
        });
      },
    });
  }

  if (hasPermissionUtilty({ name: 'crm.patients.destructor' })) {
    columns.unshift({
      type: 'icon',
      icon: 'trash-alt',
      renderColor: () => 'red',
      onPress: (row) => {
        properties.navigation.navigate('patientDelete', {
          patientId: row.id,
          clinicId: row.clinicId,
        });
      },
    });
  }

  return (
    <SceneComponent
      prefix={'crm.patients.list'}
      icon={CrmSections.patients.icon}
      links={links}
      multiWorkspace
    >
      <TableComponent<PatientCrmType, []>
        prefix={'crm.patients.list'}
        suffix={'list'}
        dates
        params={{
          clinicId: properties.route?.params?.clinicId,
          funnelId: properties.route?.params?.funnelId,
          campaignId: properties.route?.params?.campaignId,
          departmentId: properties.route?.params?.departmentId,
          year: properties.route?.params?.year?.toString(),
          month: properties.route?.params?.month?.toString(),
        }}
        repository={(settings) =>
          new PatientCrmRepository().list({
            ...settings,
            page: settings.page,
            limit: settings.limit,
            order: settings.order ?? 'created',
            direction: settings.direction,
            search: settings.search,
            dates: settings.dates,
          })
        }
        columns={columns}
        multiWorkspace
      />
    </SceneComponent>
  );
}
