// Modules
import { StyleSheet } from 'react-native';

/**
 * News Styles
 * @constant {StyleSheet} NewsStyles
 */
export const NewsStyles = StyleSheet.create({
  container: {
    height: 400,
    margin: 10,
    borderRadius: 7,
    overflow: 'hidden',
  },
  mobileContainer: {
    height: 300,
  },
  iconTimesContainer: {
    position: 'absolute',
    top: 20,
    right: 20,
    zIndex: 10,
  },
  iconTimes: {
    fontSize: 20,
    color: '#FFFFFF6A',
  },
  headerContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1,
  },
  headerContent: {
    flexDirection: 'row',
    padding: 20,
    paddingBottom: 0,
  },
  headerTextLight: {
    fontSize: 20,
    color: '#FFFFFF91',
  },
  headerTextNews: {
    fontSize: 20,
    color: '#fff',
  },
  headerSubtitle: {
    opacity: 0.7,
    padding: 20,
    paddingTop: 0,
    fontSize: 12,
    color: '#fff',
  },
  newsItemContainer: {
    gap: 10,
    overflow: 'hidden',
    flex: 1,
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#000',
  },
  imageBackground: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    filter: 'grayscale(1) brightness(0.5)',
    zIndex: -2,
    opacity: 0.8,
  },
  overlay: {
    zIndex: -1,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 0, 128, 0.58)',
    opacity: 0.5,
  },
  iconQuoteContainer: {
    position: 'absolute',
    left: 10,
    top: 56,
  },
  iconQuote: {
    fontSize: 50,
    textAlign: 'center',
    color: 'rgba(255, 255, 255, 0.3)',
  },
  newsMessage: {
    color: '#fff',
    textShadowColor: 'rgba(0, 0, 0, 0.2)',
    textShadowOffset: { width: 0, height: 1 },
    textShadowRadius: 2,
    padding: 30,
    textAlign: 'center',
    paddingTop: 100,
    zIndex: 1,
    lineHeight: 30,
    width: 300,
  },
  newsMessageLargeText: {
    fontSize: 14,
  },
  newsMessageSmallText: {
    fontSize: 20,
  },
  userContainer: {
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 10,
    alignSelf: 'center',
  },
  userLine: {
    height: 2,
    width: 15,
    position: 'absolute',
    top: -15,
    opacity: 0.4,
    backgroundColor: '#fff',
  },
  userName: {
    opacity: 1,
    color: '#fff',
    fontSize: 22,
    textAlign: 'center',
  },
  userLocation: {
    opacity: 1,
    color: '#FFFFFF',
    fontSize: 12,
    textAlign: 'center',
  },

  bulletinDisabledContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 5,
    height: 50,
    marginHorizontal: 10,
    marginTop: 10,
    borderRadius: 7,
    overflow: 'hidden',
  },
  bulletinNewsDisabled: {
    gap: 10,
    overflow: 'hidden',
    flex: 1,
    backgroundColor: '#000',
    flexDirection: 'row',
    alignItems: 'center',
  },
  disabledOpenButton: {
    height: 30,
    width: 30,
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 10,
  },
  disabledOpenIcon: {
    color: '#FFFFFF8D',
    fontSize: 20,
  },
});
