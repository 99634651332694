import { TextComponent } from '@components/text/text.component';
import { Fragment, useEffect, useRef, useState } from 'react';
import { Pressable, View } from 'react-native';
import { HeatStyles } from './heat.styles';
import { OvopointAccessRepository } from '@human/access/repositories/ovopoint/ovopoint.repository';
import { UserOvopointAccessType } from '@human/access/types/user.ovopoint.access.type';
import dayjs from 'dayjs';
import { useTool } from '../../../../context/tool/tool.context';
import { PerformanceLoaderComponent } from '../performaceLoader/performanceLoader.component';
import { adaptWeekDayUtility } from '@utils/dayjs/adaptWeekDay/adaptWeekDay.utility';

/**
 * Heat Component
 * @description Componente de calor que muestra el rendimiento de un usuario en un periodo de tiempo.
 * Se muestra en la sección Performance del componente Performance.
 *
 * @returns {JSX.Element}
 */

export function HeatComponent() {
  const [ovopoints, setOvopoints] = useState<UserOvopointAccessType[]>([]);
  const columns = 14;

  const { updateTool } = useTool();
  const viewRef = useRef<View>(null);

  useEffect(() => {
    new OvopointAccessRepository().find().then((data) => {
      const auxData = [...data];
      const today = new Date().getDay();
      const addOvoPoints = 7 - today;
      for (let i = 0; i < addOvoPoints; i++) {
        auxData.push({
          date: dayjs()
            .add(i + 1)
            .toDate(),
          total: 0,
        });
      }
      setOvopoints(auxData.slice(auxData.length - columns * 7));
    });
  }, []);

  if (!ovopoints.length) {
    return <PerformanceLoaderComponent />;
  }

  // create array of 60 elements
  const weeks = Array.from({ length: columns }, (_, i) => i);
  let auxDay = 0;
  return (
    <View style={HeatStyles.container}>
      <View
        style={{
          flexDirection: 'column',
          top: 0,
          left: 0,
        }}
      >
        <TextComponent
          translate
          text={`common.time.weekDaysAbbreviated.${adaptWeekDayUtility(dayjs().subtract(6, 'days').day())}`}
          style={HeatStyles.weekDayText}
        />
        <TextComponent
          translate
          text={`common.time.weekDaysAbbreviated.${adaptWeekDayUtility(dayjs().subtract(5, 'days').day())}`}
          style={HeatStyles.weekDayText}
        />
        <TextComponent
          translate
          text={`common.time.weekDaysAbbreviated.${adaptWeekDayUtility(dayjs().subtract(4, 'days').day())}`}
          style={HeatStyles.weekDayText}
        />
        <TextComponent
          translate
          text={`common.time.weekDaysAbbreviated.${adaptWeekDayUtility(dayjs().subtract(3, 'days').day())}`}
          style={HeatStyles.weekDayText}
        />
        <TextComponent
          translate
          text={`common.time.weekDaysAbbreviated.${adaptWeekDayUtility(dayjs().subtract(2, 'days').day())}`}
          style={HeatStyles.weekDayText}
        />
        <View style={HeatStyles.dotted} />
        <TextComponent
          translate
          text={`common.time.weekDaysAbbreviated.${adaptWeekDayUtility(dayjs().subtract(1, 'days').day())}`}
          style={HeatStyles.weekDayText}
        />
        <TextComponent
          translate
          text={`common.time.weekDaysAbbreviated.${adaptWeekDayUtility(dayjs().day())}`}
          style={HeatStyles.weekDayText}
        />
      </View>
      {weeks.map((item, index) => {
        const days = Array.from({ length: 7 }, (_, i) => {
          auxDay++;
          return {
            date: ovopoints[auxDay - 1]?.date,
            total: ovopoints[auxDay - 1]?.total,
          };
        });

        return (
          <View key={index}>
            {days.map((item, index) => {
              return (
                <Fragment key={index}>
                  {index === 5 && <View style={HeatStyles.dotted} />}
                  <View
                    key={index}
                    style={[
                      HeatStyles.textContainer,
                      {
                        backgroundColor: `rgba(237, 53, 145, ${(item.total || 1) / 60})`,
                      },
                    ]}
                  >
                    <Pressable
                      ref={viewRef}
                      onHoverIn={() => {
                        viewRef.current &&
                          item.date &&
                          viewRef.current.measure((x, y, width, height, pageX, pageY) => {
                            updateTool({
                              content: item.date.toLocaleDateString(),
                              left: pageX - 100,
                              top: pageY - 200 + index * 30,
                            });
                          });
                      }}
                      onHoverOut={() => {
                        updateTool();
                      }}
                    >
                      <TextComponent text={item.total?.toString()} style={HeatStyles.text} />
                    </Pressable>
                  </View>
                </Fragment>
              );
            })}
          </View>
        );
      })}
    </View>
  );
}
