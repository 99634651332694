import { NavigationProp, RouteProp, useNavigation } from '@react-navigation/native';
import { SceneComponent } from '../../../../../../../../../components/scene/scene.component';
import { TableComponent } from '../../../../../../../../../components/table/table.component';
import { CrmSections } from '@human/crm/crm.sections';
import { IconType } from '@components/icon/icon.types';
import { PhaseCrmType } from '@human/crm/types/phase.crm.type';
import { PhaseCrmRepository } from '@human/crm/repositories/phase/phase.repository';
import { PhasesRoutes } from '../../phase.routes';
import { hasPermissionUtilty } from '@utils/permission/has.permission.utility';
import { ColumnProperties } from '@components/table/components/columns/column.properties';
import { CrmRoutes } from '@human/crm/crm.routes';

export function ListPhasesScreen(properties: {
  navigation: NavigationProp<PhasesRoutes>;
  route: RouteProp<PhasesRoutes, 'list'>;
}) {
  const crmNavigation = useNavigation<NavigationProp<CrmRoutes>>();

  const links: {
    name: string;
    icon: IconType;
    onPress: () => void;
  }[] = [];
  if (
    hasPermissionUtilty({
      name: 'crm.phases.creator',
    })
  ) {
    links.push({
      icon: 'plus',
      name: 'create',
      onPress: () => properties.navigation.navigate('create'),
    });
  }

  const columns: ColumnProperties<PhaseCrmType, []>[] = [
    {
      type: 'icon',
      icon: hasPermissionUtilty({ name: 'crm.phases.editor' }) ? 'pencil-alt' : 'eye',
      onPress: (row) => {
        properties.navigation.navigate('update', {
          phaseId: row.id,
        });
      },
    },
    {
      type: 'iconText',
      name: 'name',
      color: (row) => row.color,
      text: (row) => row.name,
      icon: (row) => row.icon as IconType,
    },
    {
      type: 'number',
      name: 'causes',
      width: 105,
      onRender: (row) => row.causes,
      onPress: (row) => {
        crmNavigation.navigate('causes', {
          screen: 'causeList',
          params: {
            phaseId: row.id,
          },
        });
      },
    },
    {
      type: 'bar',
      width: 140,
      name: 'causes',
      onTotal: (row) => row.causes,
      onMax: (rows) => rows.reduce((max, row) => Math.max(max, row.causes), 0),
    },
    {
      type: 'date',
      name: 'created',
      renderDate: (row) => row.created,
    },
  ];

  if (hasPermissionUtilty({ name: 'crm.phases.editor' })) {
    columns.unshift({
      type: 'flag',
      status: (row) => row.status,
      onPress: (row) => {
        properties.navigation.navigate(row.status ? 'disable' : 'enable', {
          phaseId: row.id,
        });
      },
    });
  }

  return (
    <SceneComponent icon={CrmSections.phases.icon} prefix={'crm.phases.list'} links={links}>
      <TableComponent<PhaseCrmType, ['name']>
        prefix={'crm.phases.list'}
        suffix={'list'}
        search
        repository={(settings) => {
          return new PhaseCrmRepository().list({
            order: settings.order || 'name',
            direction: settings.direction,
            page: settings.page,
            limit: settings.limit,
            search: settings.search,
          });
        }}
        columns={columns}
      />
    </SceneComponent>
  );
}
