import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ColumnProperties } from '@components/table/components/columns/column.properties';
import { SpecialistAccessType } from '@human/access/types/specialist.access.type';
import { SceneComponent } from '@components/scene/scene.component';
import { TableComponent } from '@components/table/table.component';
import { SpecialistAccessRepository } from '@human/access/repositories/specialist/specialist.repository';
import { SpecialistsRoutes } from '../../specialists.routes';
import { IconType } from '@components/icon/icon.types';
import { t } from 'i18next';
import { hasPermissionUtilty } from '@utils/permission/has.permission.utility';

export function ListSpecialistsScreen(properties: {
  navigation: NavigationProp<SpecialistsRoutes>;
  route: RouteProp<SpecialistsRoutes, 'list'>;
}) {
  const links: {
    name: string;
    icon: IconType;
    onPress: () => void;
  }[] = [];

  if (
    hasPermissionUtilty({
      name: 'access.specialists.creator',
    })
  ) {
    links.push({
      icon: 'plus',
      name: 'create',
      onPress: () => properties.navigation.navigate('create'),
    });
  }

  const columns: ColumnProperties<SpecialistAccessType, []>[] = [
    {
      type: 'icon',
      icon: hasPermissionUtilty({ name: 'access.specialists.editor' }) ? 'pencil-alt' : 'eye',
      onPress: (row) => {
        properties.navigation.navigate('update', {
          specialistId: row.id,
          level: 1,
        });
      },
    },
    {
      name: 'name',
      type: 'text',
      width: 200,
      onRender: (row) => `${row.name}`,
    },
    {
      name: 'surname',
      type: 'text',
      width: 200,
      onRender: (row) => `${row.surname}`,
    },
    {
      name: 'dni',
      type: 'text',
      width: 200,
      onRender: (row) => `${row.dni}`,
    },
    {
      name: 'type',
      type: 'text',
      width: 200,
      onRender: (row) => t(`common.specialistType.${row.type}`),
    },
  ];

  if (hasPermissionUtilty({ name: 'access.specialists.editor' })) {
    columns.unshift({
      type: 'flag',
      status: (row) => row.status,
      onPress: (row) => {
        properties.navigation.navigate(row.status ? 'disable' : 'enable', {
          specialistId: row.id,
        });
      },
    });
  }

  return (
    <SceneComponent icon="user" prefix={'access.specialists.list'} links={links}>
      <TableComponent<SpecialistAccessType, []>
        prefix={'access.specialists.list'}
        suffix={'list'}
        columns={columns}
        repository={(settings) => {
          return new SpecialistAccessRepository().list({
            page: settings.page,
            limit: settings.limit,
            search: settings.search,
          });
        }}
      />
    </SceneComponent>
  );
}
