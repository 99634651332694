import { NavigationProp, RouteProp } from '@react-navigation/native';
import { SceneComponent } from '@components/scene/scene.component';
import { TableComponent } from '@components/table/table.component';
import { ColumnProperties } from '@components/table/components/columns/column.properties';
import { EntryTracksRoutes } from '../../entryTracks.routes';
import { EntryTrackAccessType } from '@human/access/types/entryTrack.access.type';
import { EntryTrackAccessRepository } from '@human/access/repositories/entryTrack/entryTrack.repository';

export function ListEntryTracksScreen(properties: {
  route: RouteProp<EntryTracksRoutes, 'list'>;
  navigation: NavigationProp<EntryTracksRoutes>;
}) {
  const columns: ColumnProperties<EntryTrackAccessType, []>[] = [
    {
      type: 'icon',
      renderIcon: (row) => (row.error ? 'pencil-alt' : 'check'),
      onPress: (row) => {
        if (!row.error) return;

        properties.navigation.navigate('update', {
          id: row.id,
        });
      },
    },
    {
      type: 'text',
      width: 200,
      name: 'name',
      onRender: (row) => row.name,
    },
    {
      type: 'text',
      width: 200,
      name: 'surname',
      onRender: (row) => row.surname,
    },
    {
      type: 'text',
      width: 400,
      name: 'email',
      onRender: (row) => row.email,
    },
    {
      type: 'contactTime',
      width: 200,
      name: 'contactTime',
      onRender: (row) => row.contactTime,
    },
    {
      type: 'text',
      width: 200,
      name: 'phone',
      onRender: (row) => row.phone,
    },
    {
      type: 'text',
      width: 200,
      name: 'type',
      onRender: (row) => row.type,
    },
    {
      type: 'text',
      width: 200,
      name: 'error',
      onRender: (row) => row.error ?? 'N/A',
    },
    {
      type: 'boolean',
      name: 'reentried',
      width: 200,
      renderBoolean: (row) => row.reentried,
    },
    {
      type: 'date',
      name: 'created',
      renderDate: (row) => row.created,
    },
  ];

  return (
    <SceneComponent icon="user-ninja" prefix={'access.entrytracks.list'}>
      <TableComponent<EntryTrackAccessType, []>
        prefix={'access.entrytracks.list'}
        suffix={'list'}
        columns={columns}
        dates
        toggles={[
          {
            label: 'failed',
            name: 'failed',
            prefix: 'access.entrytracks.list',
            defaultValue: true,
          },
          {
            label: 'gravityForms',
            name: 'gravityForms',
            prefix: 'access.entrytracks.list',
            defaultValue: true,
          },
          {
            label: 'facebook',
            name: 'facebook',
            prefix: 'access.entrytracks.list',
            defaultValue: true,
          },
        ]}
        repository={(settings) => {
          return new EntryTrackAccessRepository().list({
            ...settings,
            page: settings.page,
            limit: settings.limit,
          });
        }}
      />
    </SceneComponent>
  );
}
