import { Image } from 'react-native';
import { useState, useRef } from 'react';
import { IconComponent } from '@components/icon/icon.component';
import { useTranslation } from 'react-i18next';
import { AvatarUploaderStyles } from './avatarUploader.styles';

export function AvatarUploaderComponent(properties: {
  onSubmit: (f: File | undefined) => Promise<void>;
  file: string | undefined;
  editable?: boolean;
}) {
  const [file, setFile] = useState<File>();
  const [url404, setUrl404] = useState(false);
  const [hover, setHover] = useState(false);
  const { t } = useTranslation();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile && selectedFile.type.includes('image')) {
      setFile(selectedFile);
      properties.onSubmit(selectedFile);
      setUrl404(false);
    } else {
      alert(t('components.fileUploader.invalidType'));
    }
  };

  return (
    <>
      <div
        onClick={() => fileInputRef.current?.click()}
        onMouseEnter={() => {
          if (properties.editable === false) {
            return;
          }
          setHover(true);
        }}
        onMouseLeave={() => {
          if (!properties.editable === false) {
            return;
          }
          setHover(false);
        }}
        style={{
          ...AvatarUploaderStyles.container,
          ...(properties.editable !== false && AvatarUploaderStyles.pointerContainer),
        }}
      >
        {hover && (
          <div style={AvatarUploaderStyles.overlay}>
            <IconComponent name="camera" iconStyle={AvatarUploaderStyles.iconCamera} />
          </div>
        )}
        {(properties.file || file) && !url404 ? (
          <Image
            source={{ uri: file ? URL.createObjectURL(file) : properties.file }}
            onError={() => {
              setUrl404(true);
            }}
            style={AvatarUploaderStyles.image}
          />
        ) : (
          <IconComponent
            name="user"
            containerstyle={AvatarUploaderStyles.iconUserContainer}
            iconStyle={AvatarUploaderStyles.iconUser}
          />
        )}
        {/* Elemento input de archivo oculto */}
        {properties.editable !== false && (
          <input
            ref={fileInputRef}
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            style={{ display: 'none' }}
          />
        )}
      </div>
      {properties.file && typeof properties.file === 'string' && (
        <div
          style={AvatarUploaderStyles.expandContainer}
          onClick={() => {
            window.open(properties.file, '_blank')?.focus();
          }}
        >
          <IconComponent name="expand" iconStyle={AvatarUploaderStyles.expandIcon} />
        </div>
      )}
    </>
  );
}
