import { useTranslation } from 'react-i18next';
import { IconColumnComponent } from '../icon/icon.column.component';
import * as Clipboard from 'expo-clipboard';
import { IdCopierColumnImplementation } from './idCopier.column.properties';
import { useSuccessToast } from '@hooks/successToast/successToast.hook';

export function IdCopierColumnComponent<T>(properties: IdCopierColumnImplementation<T, []>) {
  const { t } = useTranslation();
  const { showSuccess } = useSuccessToast();

  return (
    <IconColumnComponent<T>
      {...properties}
      type="icon"
      icon="clipboard"
      popup={t('common.idCopier.popup') ?? ''}
      item={properties.item}
      onPress={() => {
        let id: string | undefined = undefined;
        if (!('id' in (properties.item as object))) {
          alert('DEVERROR: Item does not have an id property');
          return;
        } else {
          id = (properties.item as any).id;
        }

        if (!id) {
          alert('DEVERROR: Item id is undefined');
          return;
        }

        Clipboard.setStringAsync(id).then(() => {
          showSuccess('common.idCopier.copied');
        });
      }}
    />
  );
}
