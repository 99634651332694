import { MainTheme } from '@theme/main/main.theme';
import { StyleSheet } from 'react-native';

/**
 * Tagged Notes Styles
 * @constant {StyleSheet} TaggedNotesStyles
 */
export const TaggedNotesStyles = StyleSheet.create({
  container: {
    flex: 1,
  },
  input: {
    backgroundColor: 'rgba(128, 147, 161, 0.32)',
    borderRadius: 10,
    fontSize: 12,
    height: 200,
  },
  inputText: {
    marginVertical: 10,
    marginHorizontal: 2,
    height: 100,
  },
  darkInput: {
    color: '#fff',
  },
  lightInput: {
    color: '#000',
  },
  tagsContainer: {
    flexGrow: 1,
    padding: 16,
    marginVertical: 10,
  },
  tagsContent: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 6,
    justifyContent: 'flex-start',
    marginBottom: 10,
  },
  sendContainer: {
    position: 'absolute',
    bottom: 16,
    right: 16,
    height: 30,
    width: 30,
    borderRadius: 20,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: MainTheme.primaryColor,
  },
  send: {
    color: '#fff',
    fontSize: 15,
  },
});
