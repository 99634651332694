import { NavigationProp, RouteProp } from '@react-navigation/native';
import { UsersRoutes } from '../../../users.routes';
import { ModalComponent } from '@components/modal/modal.component';
import { FormComponent } from '@components/form/form.component';
import { LicenseAccessType } from '@human/access/types/license.access.type';
import { LicenseAccessRepository } from '@human/access/repositories/license/license.repository';
import { RoleAccessRepository } from '@human/access/repositories/role/role.repository';

export function CreateLicenseScreen(properties: {
  navigation: NavigationProp<UsersRoutes>;
  route: RouteProp<UsersRoutes, 'licenseCreate'>;
}) {
  return (
    <ModalComponent
      level={properties.route.params.level}
      icon="lock"
      prefix="access.users.update.licenses.create"
    >
      <FormComponent<LicenseAccessType>
        prefix="access.users.update.licenses.create.form"
        padding
        repository={{
          send: (settings) => {
            return new LicenseAccessRepository()
              .create({
                roleId: settings.item.roleId,
                userId: properties.route.params.userId,
              })
              .then(() => {
                properties.navigation.goBack();
              });
          },
        }}
        fields={[
          {
            type: 'select',
            name: 'roleId',
            description: true,
            required: true,
            repository: ({ search }) =>
              new RoleAccessRepository().listForCombo({
                search,
                active: true,
              }),
          },
        ]}
      />
    </ModalComponent>
  );
}
