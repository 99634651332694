import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ModalComponent } from '@components/modal/modal.component';
import { CrmSections } from '@human/crm/crm.sections';
import { ActionCrmType } from '@human/crm/types/action.crm.type';
import { ActionCrmRepository } from '@human/crm/repositories/action/action.repository';
import { FormComponent } from '@components/form/form.component';
import { ActivityCrmRepository } from '@human/crm/repositories/activity/activity.repository';
import { CoworkerCrmRepository } from '@human/crm/repositories/coworker/coworker.repository';
import { PatientsRoutes } from '@human/crm/sections/patients/patient.routes';
import { useSuccessToast } from '@hooks/successToast/successToast.hook';

export function UpdateActionNextResultPatientPart(properties: {
  navigation: NavigationProp<PatientsRoutes>;
  route: RouteProp<PatientsRoutes, 'patientActionNextResultUpdate'>;
}) {
  const { showSuccess } = useSuccessToast();
  return (
    <ModalComponent
      level={properties.route.params.level}
      flex={false}
      icon={CrmSections.tasks.icon}
      prefix="crm.leads.update.actions.create"
    >
      <FormComponent<Partial<ActionCrmType>>
        padding
        prefix="crm.leads.update.actions.create.form"
        repository={{
          send: (settings) => {
            return new ActionCrmRepository()
              .createPatientAction({
                item: {
                  ...settings.item,
                  patientId: properties.route.params.patientId,
                  clinicId: properties.route.params.clinicId,
                },
              })
              .then(() => showSuccess());
          },
          get: async () => {
            return {
              started: properties.route.params.started,
              activityId: properties.route.params.activityId,
            };
          },
        }}
        fields={[
          {
            type: 'text',
            name: 'subject',
            description: true,
            validations: ['title'],
          },
          {
            type: 'select',
            name: 'activityId',
            required: true,
            description: true,
            pick: (properties: { id: string }) => {
              return new ActivityCrmRepository()
                .pick({ activityId: properties.id })
                .then((activity) => {
                  return `${activity.name}`;
                });
            },
            repository: ({ search }) => {
              return new ActivityCrmRepository().listForCombo({
                search,
                active: true,
              });
            },
          },
          {
            type: 'select',
            name: 'responsibleId',
            required: true,
            description: true,
            pick: (props: { id: string }) => {
              return new CoworkerCrmRepository().pickForCombo({
                clinicId: properties.route.params.clinicId,
                coworkerId: props.id,
              });
            },
            repository: ({ search }) => {
              return new CoworkerCrmRepository().listForCombo({
                clinicId: properties.route.params.clinicId,
                search,
                active: true,
              });
            },
          },
          {
            type: 'date',
            name: 'started',
            required: true,
            description: true,
            format: 'YYYY-MM-DD HH:mm:ss',
          },
        ]}
      />
    </ModalComponent>
  );
}
