import { NavigationProp, RouteProp } from '@react-navigation/native';
import { FormComponent } from '@components/form/form.component';
import { ModalComponent } from '@components/modal/modal.component';
import { CountriesRoutes } from '../../countries.routes';
import { CountryAccessType } from '@human/access/types/country.access.type';
import { CountryAccessRepository } from '@human/access/repositories/country/country.repository';
import { useUserState } from '@states/user/user.state';
export function DeleteCountryScreen(properties: {
  navigation: NavigationProp<CountriesRoutes>;
  route: RouteProp<CountriesRoutes, 'delete'>;
}) {
  return (
    <ModalComponent icon="trash-alt" prefix="access.countries.delete">
      <FormComponent<CountryAccessType>
        padding
        prefix="access.countries.delete.form"
        repository={{
          send: (props: { item: Partial<CountryAccessType> }) => {
            return new CountryAccessRepository().delete({
              id: properties.route.params.id,
            });
          },
        }}
        fields={
          [
            // {
            //   name: 'status',
            //   icon: 'lock',
            //   type: 'checkbox',
            //   validations: ['true'],
            // },
          ]
        }
      />
    </ModalComponent>
  );
}
