import { useTranslation } from 'react-i18next';
import { Image, ImageBackground, TouchableOpacity, View } from 'react-native';
import { PassportStyles } from './passport.styles';
import { useDeviceHook } from '@hooks/device/device.hook';
import { PassportProperties } from './passport.properties';
import { TextComponent } from '@components/text/text.component';
import { IconComponent } from '@components/icon/icon.component';
import { useState } from 'react';
import { AvatarUploaderComponent } from '@components/avatarUploader/avatarUploader.component.web';

export function OvobankPassportComponent(properties: PassportProperties) {
  const { t } = useTranslation();

  const { laptop } = useDeviceHook();
  const [url404, setUrl404] = useState(false);

  const renderImage = () => {
    return (
      <>
        {properties.contact?.photo && !url404 ? (
          <AvatarUploaderComponent
            editable={false}
            file={properties.contact.photo}
            onSubmit={async () => {}}
          />
        ) : (
          <IconComponent
            name="user"
            containerstyle={PassportStyles.imageIconContainer}
            iconStyle={PassportStyles.imageIcon}
          />
        )}
      </>
    );
  };

  const handleNavigateCampaign = () => {
    if (properties.campaign?.id && properties.campaign?.languageId) {
      properties.navigation.navigate('campaignUpdate', {
        id: properties.campaign.id,
        languageId: properties.campaign.languageId,
      });
    }
  };

  return (
    <View style={PassportStyles.container}>
      {properties.contact ? (
        <>
          <View
            style={[
              PassportStyles.primaryContactDetailsContainer,
              laptop && PassportStyles.primaryContactDetailsContainerLaptop,
            ]}
          >
            <View
              style={{
                flex: 1,
                flexDirection: 'column',
                justifyContent: 'center',
                gap: 10,
                padding: 10,
              }}
            >
              <View style={PassportStyles.contactNameContainer}>
                {renderImage()}
                <View style={PassportStyles.contactNameAndSurname}>
                  <TextComponent
                    style={PassportStyles.contactName}
                    text={properties.contact.name || ''}
                  />
                  <TextComponent
                    style={PassportStyles.contactName}
                    text={properties.contact.surname || ''}
                  />
                </View>
              </View>
              <View style={{ gap: 10 }}>
                <View style={PassportStyles.contactInfoRow}>
                  <IconComponent iconStyle={PassportStyles.passportIcon} name="envelope" />
                  <TextComponent
                    style={PassportStyles.contentText}
                    text={properties.contact.emails?.map((email) => email.email).join(',') || ''}
                  />
                </View>
                <View style={PassportStyles.contactInfoRow}>
                  <IconComponent iconStyle={PassportStyles.passportIcon} name="phone" />
                  <TextComponent
                    style={PassportStyles.contentText}
                    text={
                      properties.contact.phones
                        ?.map((phone) => `${phone.prefix} ${phone.phone}`)
                        .join(',') || ''
                    }
                  />
                </View>
                <View style={PassportStyles.contactInfoRow}>
                  <IconComponent iconStyle={PassportStyles.passportIcon} name="calendar" />
                  <TextComponent
                    style={PassportStyles.contentText}
                    text={properties.contact.birthDate?.toLocaleDateString() ?? ''}
                  />
                </View>
              </View>
              <View style={PassportStyles.campaignContainer}>
                {properties.campaign && (
                  <TouchableOpacity
                    style={PassportStyles.touchable}
                    onPress={handleNavigateCampaign}
                  >
                    <ImageBackground
                      source={{ uri: properties.campaign?.image }}
                      style={PassportStyles.imageBackground}
                    >
                      <View style={PassportStyles.overlay} />
                      <View style={PassportStyles.row}>
                        <TextComponent style={PassportStyles.hashText} text={'#'} />
                        <TextComponent
                          style={PassportStyles.campaignCode}
                          text={properties.campaign.code || ''}
                        />
                      </View>
                      <TextComponent
                        style={PassportStyles.campaignTitle}
                        text={`${properties.campaign.title}`}
                      />
                    </ImageBackground>
                  </TouchableOpacity>
                )}
              </View>
            </View>
          </View>
          <View style={PassportStyles.divider} />

          <View style={PassportStyles.detailsContainer}>
            <View style={PassportStyles.fieldContainer}>
              <TextComponent
                translate
                text={'crm.leads.update.leadPassport.weight'}
                style={PassportStyles.titleText}
              />
              <TextComponent
                translate
                text={properties.contact.weight || ''}
                style={PassportStyles.contentText}
              />
            </View>
            <View style={PassportStyles.columnDivider} />
            <View style={PassportStyles.fieldContainer}>
              <TextComponent
                translate
                style={PassportStyles.titleText}
                text={'crm.leads.update.leadPassport.height'}
              />
              <TextComponent
                style={PassportStyles.contentText}
                text={properties.contact.height || ''}
              />
            </View>
            <View style={PassportStyles.columnDivider} />
            <View style={PassportStyles.fieldContainer}>
              <TextComponent
                translate
                style={PassportStyles.titleText}
                text={'crm.leads.update.leadPassport.birthCountry'}
              />
              <TextComponent
                style={PassportStyles.contentText}
                text={properties.contact.birthCountry || ''}
              />
            </View>
          </View>
        </>
      ) : null}
    </View>
  );
}
