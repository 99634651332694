import { NavigationProp, RouteProp } from '@react-navigation/native';
import { LeadsRoutes } from '../../leads.routes';
import { SceneComponent } from '@components/scene/scene.component';
import { CrmSections } from '@human/crm/crm.sections';
import { LeadCrmType } from '@human/crm/types/lead.crm.type';
import { TableComponent } from '@components/table/table.component';
import { LeadCrmRepository } from '@human/crm/repositories/lead/lead.repository';
import { IconType } from '@components/icon/icon.types';
import { hasPermissionUtilty } from '@utils/permission/has.permission.utility';
import { ColumnProperties } from '@components/table/components/columns/column.properties';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { exportCsvUtility } from '@utils/export/export.csv.utility';
import { Platform } from 'react-native';
import { statusColorMap } from '@constants/entryStatus.constants';

export function ListLeadsScreen(properties: {
  navigation: NavigationProp<LeadsRoutes>;
  route: RouteProp<LeadsRoutes, 'leadsList'>;
}) {
  const { t } = useTranslation();
  const [reload, setReload] = useState<boolean>();
  const links: {
    name: string;
    icon: IconType;
    onPress: () => void;
  }[] = [];
  if (
    hasPermissionUtilty({
      name: 'crm.leads.creator',
    })
  ) {
    links.push({
      icon: 'plus',
      name: 'create',
      onPress: () => properties.navigation.navigate('create'),
    });
  }

  const columns: ColumnProperties<LeadCrmType, ['contactName', 'created']>[] = [
    {
      type: 'icon',
      icon: hasPermissionUtilty({ name: 'crm.leads.editor' }) ? 'pencil-alt' : 'eye',
      onPress: (row) => {
        properties.navigation.navigate('leadUpdate', {
          id: row.id,
          clinicId: row.clinicId,
          contactId: row.contactId,
        });
      },
    },
    {
      type: 'text',
      name: 'name',
      order: 'contactName',
      onRender: (row) => row.contactName,
      width: 200,
    },
    {
      type: 'taskStatus',
      onRender: (row) => t(`common.leadEntryStatus.${row.entryStatus}`),
      width: 150,
      color: (row) => statusColorMap[row.entryStatus].backgroundColor,
      background: (row) => statusColorMap[row.entryStatus].textColor,
      textColor: (row) => statusColorMap[row.entryStatus].textColor,
    },
    {
      type: 'clinic',
      name: 'clinicName',
      onRender: (row) => row.clinicName,
      color: (row) => row.clinicColor,
      width: 200,
    },
    {
      type: 'text',
      name: 'campaignName',
      width: 200,
      onRender: (row) => row.campaignName,
    },
    {
      type: 'text',
      name: 'phones',
      width: 200,
      // last phone added to the contact
      onRender: (row) =>
        row.phones && row.phones.length > 0
          ? `${row.phones[row.phones.length - 1].prefix} ${row.phones[row.phones.length - 1].phone}`
          : '',
    },
    {
      type: 'text',
      name: 'emails',
      width: 200,
      // last email added to the contact
      onRender: (row) =>
        row.emails && row.emails.length > 0 ? `${row.emails[row.emails.length - 1].email}` : '',
    },
    {
      type: 'date',
      filter: 'range',
      max: new Date(new Date().setDate(new Date().getDate() + 1)),
      // min: new Date(),
      name: 'created',
      order: 'created',
      renderDate: (row) => row.created,
    },
  ];

  if (hasPermissionUtilty({ name: 'crm.leads.editor' })) {
    columns.unshift({
      type: 'flag',
      status: (row) => row.status,
      onPress: (row) => {
        properties.navigation.navigate(row.status ? 'disable' : 'enable', {
          id: row.id,
          clinicId: row.clinicId,
        });
      },
    });
  }

  if (hasPermissionUtilty({ name: 'crm.leads.destructor' })) {
    columns.unshift({
      type: 'icon',
      icon: 'trash-alt',
      renderColor: () => 'red',
      onPress: (row) => {
        properties.navigation.navigate('delete', {
          id: row.id,
          clinicId: row.clinicId,
        });
      },
    });
  }

  return (
    <SceneComponent icon={CrmSections.leads.icon} prefix={'crm.leads.list'} links={links}>
      <TableComponent<LeadCrmType, ['contactName', 'created']>
        prefix={'crm.leads.list'}
        suffix={'list'}
        dates
        toggles={[
          {
            name: 'planned',
            prefix: 'common.plannedStatus',
            label: 'UNPLANNED',
            defaultValue: false,
          },
        ]}
        reload={
          new Promise((resolve) => {
            if (reload) {
              setReload(false);
              resolve();
            }
          })
        }
        actions={[
          {
            icon: 'arrow-down',
            label: 'export.title',
            hideOnNoData: true,
            onPress: (settings) => {
              if (Platform.OS === 'web') {
                confirm(t('export.description') ?? 'Exportar datos a CSV') &&
                  new LeadCrmRepository()
                    .export({
                      search: settings.search?.join(';'),
                      dates: settings.dates,
                    })
                    .then((response) => {
                      exportCsvUtility({ items: response.items, name: 'crm.leads.list' });
                    });
              }
            },
          },
          {
            icon: 'arrow-up',
            label: 'import.title',
            onPress: () => {
              properties.navigation.navigate('import');
            },
          },
        ]}
        params={{
          campaignId: properties.route.params?.campaignId,
          originCampaignId: properties.route.params?.originCampaignId,
          funnelId: properties.route.params?.funnelId,
          clinicId: properties.route.params?.clinicId,
          year: properties.route.params?.year?.toString(),
          month: properties.route.params?.month?.toString(),
          departmentId: properties.route.params?.departmentId,
          originDepartmentId: properties.route.params?.originDepartmentId,
          originFunnelId: properties.route.params?.originFunnelId,
        }}
        repository={(settings) =>
          new LeadCrmRepository().list({
            ...settings,
            direction: settings.direction,
            order: settings.order ?? 'created',
            page: settings.page,
            limit: settings.limit,
            search: settings.search,
            dates: settings.dates,
          })
        }
        columns={columns}
      />
    </SceneComponent>
  );
}
