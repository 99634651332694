import { isEmail } from '@validator/index';
import { ValidationTextInput } from './text.properties';

export function TextInputValidations(properties: {
  validations: ValidationTextInput[];
  required?: boolean;
  value?: string;
}) {
  const errors: string[] = [];
  /**
   * Name Validation
   * 1. Must be a string
   * 2. Must be at least 3 characters long
   * 3. Must be at most 50 characters long
   */

  if (properties.validations.includes('name')) {
    if (!properties.value) {
      if (properties.required) {
        errors.push('nameIsRequired');
      }
    } else {
      if (properties.value.length > 50) {
        errors.push('nameMustBeAtMost50CharactersLong');
      }
    }
  }

  /**
   * Surname Validation
   * 1. Must be a string
   * 3. Must be at most 50 characters long
   */
  if (properties.validations.includes('surname')) {
    if (!properties.value) {
      if (properties.required) {
        errors.push('surnameIsRequired');
      }
    } else {
      if (properties.value.length > 50) {
        errors.push('surnameMustBeAtMost50CharactersLong');
      }
    }
  }

  /**
   * Email Validation
   * 1. Must be a string
   * 2. Must be at least 3 characters long
   * 3. Must be at most 30 characters long
   * 4. Must contain an @ symbol
   */
  if (properties.validations.includes('email')) {
    if (!properties.value) {
      if (properties.required) {
        errors.push('emailIsRequired');
      }
    } else {
      if (properties.value.length < 3) {
        errors.push('emailMustBeAtLeast3CharactersLong');
      }
      if (properties.value.length > 50) {
        errors.push('emailMustBeAtMost50CharactersLong');
      }
      if (!properties.value.includes('@')) {
        errors.push('emailMustContainAnAtSymbol');
      }
      if (properties.value.match(/ /)) {
        errors.push('emailMustNotContainWhitespace');
      }
      if (!isEmail(properties.value)) {
        errors.push('emailMustBeAValidEmail');
      }
    }
  }

  /**
   * Dni Validation
   * 1. Must be a string
   * 2. Must be at least 8 characters long
   * 3. Must be at most 20 characters long
   * 4. Must not contain any special characters
   * 5. Must not contain any numbers
   */

  if (properties.validations.includes('dni')) {
    if (!properties.value) {
      if (properties.required) {
        errors.push('dniIsRequired');
      }
    } else {
      if (properties.value.length < 6) {
        errors.push('dniMustBeAtLeast8CharactersLong');
      }
      if (properties.value.length > 20) {
        errors.push('dniMustBeAtMost20CharactersLong');
      }
    }
  }
  /**
   * Password Validation
   * 1. Must be a string
   * 2. Must be at least 8 characters long
   * 3. Must be at most 30 characters long
   * 4. Must contain at least one number
   * 5. Must contain at least one special character
   * 6. Must contain at least one uppercase letter
   * 7. Must contain at least one lowercase letter
   * 8. Must not contain any spaces
   */
  if (properties.validations.includes('password')) {
    if (typeof properties.value !== 'string') {
      errors.push('passwordMustBeAString');
    }
    if (!properties.value) {
      if (properties.required) {
        errors.push('passwordIsRequired');
      }
    } else {
      if (properties.value.length < 8) {
        errors.push('passwordMustBeAtLeast8CharactersLong');
      }
      if (properties.value.length > 30) {
        errors.push('passwordMustBeAtMost30CharactersLong');
      }
      if (!properties.value.match(/[0-9]/)) {
        errors.push('passwordMustContainANumber');
      }
      if (!properties.value.match(/[^a-zA-Z0-9]/)) {
        errors.push('passwordMustContainASpecialCharacter');
      }
      if (!properties.value.match(/[A-Z]/)) {
        errors.push('passwordMustContainAnUppercaseLetter');
      }
      if (!properties.value.match(/[a-z]/)) {
        errors.push('passwordMustContainALowercaseLetter');
      }
      if (properties.value.match(/ /)) {
        errors.push('passwordMustNotContainWhitespace');
      }
    }
  }

  /**
   * IP Address Validation
   * 1. Must be a string
   * 2. Must be a valid IP address
   */
  if (properties.validations.includes('ip')) {
    if (!properties.value) {
      if (properties.required) {
        errors.push('ipIsRequired');
      }
    } else {
      if (!properties.value.match(/^(\d{1,3}\.){3}\d{1,3}$/)) {
        errors.push('ipMustBeAValidIpAddress');
      }
    }
  }

  /**
   * Code Validation
   * 1. Must be a string
   * 2. Must be at least 2 characters long
   * 3. Must be at most 30 characters long
   */
  if (properties.validations.includes('code')) {
    if (!properties.value) {
      if (properties.required) {
        errors.push('codeIsRequired');
      }
    } else {
      if (properties.value.length < 2) {
        errors.push('codeMustBeAtLeast2CharactersLong');
      }
      if (properties.value.length > 30) {
        errors.push('codeMustBeAtMost30CharactersLong');
      }
    }
  }

  /**
   * Description Validation
   * 1. Must be a string
   * 2. Must be at least 1 characters long
   * 3. Must be at most 500 characters long
   */
  if (properties.validations.includes('description')) {
    if (!properties.value) {
      if (properties.required) {
        errors.push('descriptionIsRequired');
      }
    } else {
      if (properties.value.length < 1) {
        errors.push('descriptionMustBeAtLeast1CharactersLong');
      }
      if (properties.value.length > 500) {
        errors.push('descriptionMustBeAtMost500CharactersLong');
      }
    }
  }

  /**
   * Title validation
   * 1. Must be a string
   * 2. Must be at least 3 characters long
   * 3. Must be at most 30 characters long
   * Ej. 1º Llamada
   */
  if (properties.validations.includes('title')) {
    if (!properties.value) {
      if (properties.required) {
        errors.push('titleIsRequired');
      }
    } else {
      if (properties.value.length < 3) {
        errors.push('titleMustBeAtLeast3CharactersLong');
      }
      if (properties.value.length > 100) {
        errors.push('titleMustBeAtMost100CharactersLong');
      }
    }
  }

  /**
   * Phone Validation
   * 1. Must be a string
   * 2. Must contain only numbers
   */
  if (properties.validations.includes('phone')) {
    if (!properties.value) {
      if (properties.required) {
        errors.push('phoneIsRequired');
      }
    } else {
      if (!properties.value.match(/^[0-9]*$/)) {
        errors.push('phoneMustContainOnlyNumbers');
      }
      if (properties.value.length > 12) {
        errors.push('phoneMustBeAtMost12CharactersLong');
      }
      if (properties.value.length < 9) {
        errors.push('phoneMustBeAtLeast9CharactersLong');
      }
    }
  }

  /**
   * Extension Validation
   * 1. Must be a string
   * 2. Must contain only numbers
   * 3. Must be at most 4 characters long
   */
  if (properties.validations.includes('extension')) {
    if (!properties.value) {
      if (properties.required) {
        errors.push('extensionIsRequired');
      }
    } else {
      if (!properties.value.match(/^[0-9]*$/)) {
        errors.push('extensionMustContainOnlyNumbers');
      }
      if (properties.value.length < 4) {
        errors.push('extensionMustBeAtLeast4CharactersLong');
      }
    }
  }

  /**
   * Username Validation
   * 1. Must be a string
   * 2. Must be at least 3 characters long
   * 3. Must be at most 30 characters long
   * 4. Must not contain any special characters
   * 5. Must not contain any spaces
   */
  if (properties.validations.includes('username')) {
    if (!properties.value) {
      if (properties.required) {
        errors.push('usernameIsRequired');
      }
    } else {
      if (properties.value.length < 3) {
        errors.push('usernameMustBeAtLeast3CharactersLong');
      }
      if (properties.value.length > 30) {
        errors.push('usernameMustBeAtMost30CharactersLong');
      }
      if (properties.value.match(/^[A-Z0-9 _]*[A-Z0-9][A-Z0-9 _]*$/)) {
        errors.push('usernameMustNotContainSpecialCharacters');
      }
      if (properties.value.match(/ /)) {
        errors.push('usernameMustNotContainWhitespace');
      }
    }
  }

  /* Country code Validation
   * 1. Must be a string
   * 2. Must be 2 characters long
   * 3. Must be Uppercase
   */
  if (properties.validations.includes('countryCode')) {
    if (!properties.value) {
      if (properties.required) {
        errors.push('countryCodeIsRequired');
      }
    } else {
      if (typeof properties.value !== 'string') {
        errors.push('stateMustBeAString');
      }
      if (properties.value.length !== 2) {
        errors.push('countryCodeMustBe2CharactersLong');
      }
      if (properties.value !== properties.value.toUpperCase()) {
        errors.push('countryCodeMustBeUppercase');
      }
    }
  }

  /**
   * Prefix Validation
   * 1. Must be a string
   * 2. First character must be a "+" sign
   * 3. Must contain only numbers
   * 4. Must be at most 3 characters long
   */
  if (properties.validations.includes('prefix')) {
    if (!properties.value) {
      if (properties.required) {
        errors.push('prefixIsRequired');
      }
    } else {
      if (properties.value[0] !== '+') {
        errors.push('prefixFirstCharacterMustBePlusSign');
      }
      const numbers = properties.value.slice(1);
      if (/^\d+$/.test(numbers)) {
        if (numbers.length < 1) {
          errors.push('prefixMustBeAtLeast1CharactersLong');
        }
        if (numbers.length > 3) {
          errors.push('prefixMustBeAtMost3CharactersLong');
        }
      } else {
        errors.push('prefixMustContainOnlyNumbers');
      }
    }
  }

  /**
   * Zip validation
   * 1. Must be a string
   * 2. Must not contain any special characters
   */
  if (properties.validations.includes('zip')) {
    if (!properties.value) {
      if (properties.required) {
        errors.push('zipIsRequired');
      }
    } else {
      if (!properties.value.match(/^[a-zA-Z0-9\s\-]+$/)) {
        errors.push('zipMustNotContainSpecialCharacters');
      }
    }
  }

  /**
   * Street validation
   * 1. Must be a string
   */
  if (properties.validations.includes('street')) {
    if (!properties.value) {
      if (properties.required) {
        errors.push('streetIsRequired');
      }
    } else {
      if (typeof properties.value !== 'string') {
        errors.push('streetMustBeAString');
      }
      if (properties.value.length < 8) {
        errors.push('streetMustBeAtLeast8CharactersLong');
      }
    }
  }

  /**
   * State validation
   * 1. Must be a string
   */
  if (properties.validations.includes('state')) {
    if (!properties.value) {
      if (properties.required) {
        errors.push('stateIsRequired');
      }
    } else {
      if (typeof properties.value !== 'string') {
        errors.push('stateMustBeAString');
      }
    }
  }

  /**
   * City validation
   * 1. Must be a string
   */
  if (properties.validations.includes('city')) {
    if (typeof properties.value !== 'string') {
      errors.push('notString');
    }
    if (!properties.value) {
      if (properties.required) {
        errors.push('cityIsRequired');
      }
    }
  }

  /**
   * Coin validation
   * 1. Must be a string
   */
  if (properties.validations.includes('coin')) {
    if (!properties.value) {
      if (properties.required) {
        errors.push('coinIsRequired');
      }
    } else {
      if (typeof properties.value !== 'string') {
        errors.push('coinMustBeAString');
      }
    }
  }

  /**
   * Flag validation
   * 1. Must be a string
   */
  if (properties.validations.includes('flag')) {
    if (!properties.value) {
      if (properties.required) {
        errors.push('flagIsRequired');
      }
    } else {
      if (typeof properties.value !== 'string') {
        errors.push('flagMustBeAString');
      }
    }
  }

  /**
   * Observation validation
   * 1. Must be a string
   * 2. Must be at least 1 characters long
   * 3. Must be at most 500 characters long
   */
  if (properties.validations.includes('observation')) {
    if (!properties.value) {
      if (properties.required) {
        errors.push('observationIsRequired');
      }
    } else {
      if (properties.value.length < 1) {
        errors.push('observationMustBeAtLeast1CharactersLong');
      }
      if (properties.value.length > 3000) {
        errors.push('observationMustBeAtMost500CharactersLong');
      }
    }
  }

  /**
   * Subject validation
   * 1. Must be a string
   * 2. Must be at least 3 characters long
   * 3. Must be at most 30 characters long
   */
  if (properties.validations.includes('subject')) {
    if (!properties.value) {
      if (properties.required) {
        errors.push('subjectIsRequired');
      }
    } else {
      if (properties.value.length < 3) {
        errors.push('subjectMustBeAtLeast3CharactersLong');
      }
      if (properties.value.length > 3000) {
        errors.push('subjectMustBeAtMost30CharactersLong');
      }
    }
  }

  /**
   * Message validation
   * 1. Must be a string
   * 2. Must be at least 1 characters long
   * 3. Must be at most 500 characters long
   */
  if (properties.validations.includes('message')) {
    if (!properties.value) {
      if (properties.required) {
        errors.push('messageIsRequired');
      }
    } else {
      if (properties.value.length < 1) {
        errors.push('messageMustBeAtLeast1CharactersLong');
      }
      if (properties.value.length > 500) {
        errors.push('messageMustBeAtMost500CharactersLong');
      }
    }
  }

  /**
   * Hour validation
   * 1. Must be a string
   * 2. Must be a valid hour
   */
  if (properties.validations.includes('hour')) {
    if (!properties.value) {
      if (properties.required) {
        errors.push('hourIsRequired');
      }
    } else {
      if (!properties.value.match(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/)) {
        errors.push('hourMustBeValidFormat');
      }
    }
  }

  /**
   * Document validation
   * 1. Must be a string
   * 2. Must be at least 8 characters long
   */
  if (properties.validations.includes('document')) {
    if (!properties.value) {
      if (properties.required) {
        errors.push('documentIsRequired');
      }
    } else {
      if (typeof properties.value !== 'string') {
        errors.push('documentMustBeAString');
      }
      if (properties.value.length < 8) {
        errors.push('documentMustBeAtLeast8CharactersLong');
      }
    }
  }

  return errors;
}
