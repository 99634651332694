import { NavigationProp, RouteProp } from '@react-navigation/native';
import { FormComponent } from '@components/form/form.component';
import { ModalComponent } from '@components/modal/modal.component';
import { LanguagesRoutes } from '../../languages.routes';
import { LanguageAccessRepository } from '@human/access/repositories/language/language.repository';
import { LanguageAccessType } from '@human/access/types/language.access.type';
import { CountryAccessRepository } from '@human/access/repositories/country/country.repository';
import { useState, useEffect } from 'react';
export function CreateLanguageScreen(properties: {
  navigation: NavigationProp<LanguagesRoutes>;
  route: RouteProp<LanguagesRoutes, 'create'>;
}) {
  return (
    <ModalComponent icon="language" prefix="access.languages.create">
      <FormComponent<LanguageAccessType & { signature: string }>
        prefix="access.languages.create.form"
        padding
        repository={{
          send: (settings) => {
            return new LanguageAccessRepository()
              .create({
                item: settings.item,
              })
              .then(() => {
                properties.navigation.navigate('list');
              });
          },
        }}
        fields={[
          {
            name: 'name',
            type: 'text',

            description: true,
            required: true,
            validations: ['name'],
          },
          {
            name: 'code',
            type: 'text',
            description: true,
            required: true,
            validations: ['countryCode'],
          },
          {
            name: 'countryCode',
            description: true,
            type: 'select',
            repository: ({ search }) => {
              return new CountryAccessRepository().listForLangCombo({
                search,
                active: true,
              });
            },
          },
        ]}
      />
    </ModalComponent>
  );
}
