import { NavigationProp, RouteProp } from '@react-navigation/native';
import { MemberRoutes } from '../member.routes';
import { ModalComponent } from '@components/modal/modal.component';
import { FormComponent } from '@components/form/form.component';
import { MemberAccessType } from '@human/access/types/member.access.type';
import { MemberAccessRepository } from '@human/access/repositories/member/member.repository';
import { CoworkerCrmRepository } from '@human/crm/repositories/coworker/coworker.repository';

export function CreateMemberScreen(properties: {
  navigation: NavigationProp<MemberRoutes>;
  route: RouteProp<MemberRoutes, 'memberCreate'>;
}) {
  return (
    <ModalComponent
      level={properties.route.params.level}
      icon="users"
      prefix="access.departments.update.members.create"
    >
      <FormComponent<MemberAccessType>
        prefix="access.departments.update.members.create.form"
        padding
        repository={{
          send: (data) =>
            new MemberAccessRepository()
              .create({
                clinicId: properties.route.params.clinicId,
                contractId: data.item.contractId,
                departmentId: properties.route.params.departmentId,
              })
              .then(() => properties.navigation.goBack()),
        }}
        fields={[
          {
            type: 'select',
            description: true,
            name: 'contractId',
            required: true,
            repository: ({ search }) => {
              return new CoworkerCrmRepository().listForCombo({
                clinicId: properties.route.params.clinicId,
                search,
                active: true,
              });
            },
          },
        ]}
      />
    </ModalComponent>
  );
}
