import { StyleSheet } from 'react-native';

export const SaleFunnelPerDepartmentTableStyles = StyleSheet.create({
  headerContainer: {
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  headerSpaceHeader: {
    flex: 3,
    marginLeft: 10,
  },
  headerColumnContainer: {
    flex: 1,
  },
  headerText: {
    color: '#999',
    fontWeight: '500',
  },
  headerSpacer: {
    height: 1,
    backgroundColor: '#ccc',
    marginTop: 5,
  },
  scrollContainer: {
    backgroundColor: 'white',
    borderRadius: 4,
  },
  columnContainer: {
    flex: 2,
    justifyContent: 'space-around',
  },
  phoneColumnContainer: {
    flex: 2,
    justifyContent: 'space-around',
    marginHorizontal: 10,
    width: 120,
  },
  columnText: {
    color: '#999',
    fontWeight: '300',
    textAlign: 'center',
    fontSize: 12,
  },
  phoneColumnText: {
    color: '#999',
    fontWeight: '300',
    textAlign: 'center',
    fontSize: 16,
  },
  tableContainer: {
    backgroundColor: 'white',
    borderRadius: 4,
  },
  tableWrapper: {
    flexDirection: 'column',
    backgroundColor: 'white',
    borderRadius: 4,
  },
});
