import { CSSProperties } from 'react';
import './styles.css';

export default function LoaderComponentWeb(properties: {
  loading: boolean;
  variant?: 'dark' | 'light';
  style?: CSSProperties;
}) {
  if (!properties.loading) return null;

  const loaderContainerClassName =
    !properties.variant || properties.variant === 'light' ? 'loading' : 'loading loading-dark';

  const loaderClassName =
    !properties.variant || properties.variant === 'light'
      ? 'loading-dots'
      : 'loading-dots loading-dots-dark';

  return (
    <span className={loaderContainerClassName}>
      {Array.from({ length: 3 }).map((_, index) => (
        <span key={index} className={loaderClassName} style={properties.style}></span>
      ))}
    </span>
  );
}
