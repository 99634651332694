import { NavigationProp, RouteProp } from '@react-navigation/native';
import { FormComponent } from '@components/form/form.component';
import { ModalComponent } from '@components/modal/modal.component';
import { TemplatesRoutes } from '../../templates.routes';
import { TemplateAccessRepository } from '@human/access/repositories/template/template.repository';

export function DeleteTemplateScreen(properties: {
  navigation: NavigationProp<TemplatesRoutes>;
  route: RouteProp<TemplatesRoutes, 'delete'>;
}) {
  return (
    <ModalComponent icon="trash-alt" prefix="access.templates.delete">
      <FormComponent
        prefix="access.templates.delete.form"
        padding
        repository={{
          send: (data) => {
            return new TemplateAccessRepository()
              .delete({
                templateId: properties.route.params.templateId,
              })
              .then(properties.navigation.goBack);
          },
        }}
        fields={[
          {
            name: 'status',
            icon: 'lock',
            type: 'checkbox',
            validations: ['true'],
            description: true,
          },
        ]}
      />
    </ModalComponent>
  );
}
