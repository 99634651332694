import { TouchableOpacity, View } from 'react-native';
import { TagProperties } from './tag.properties';
import { TextComponent } from '@components/text/text.component';
import { TagStyles } from './tag.styles';

export function TagComponent(properties: TagProperties) {
  return (
    <TouchableOpacity
      style={[TagStyles.container, properties.selected && TagStyles.selected]}
      onPress={() => properties.onPress(properties.tag)}
    >
      <TextComponent text={properties.tag} style={[TagStyles.tagText, properties.selected && TagStyles.selectedText]} />
    </TouchableOpacity>
  );
}
