import { ActivityCrmType } from '@human/crm/types/activity.crm.type';
import { AuthorizedRepository } from '@core/repository/authorized.repository.core';
import { ActivityScopeCrmType } from '@human/crm/types/activityScope.crm.type';

export class ActivityCrmRepository extends AuthorizedRepository {
  async list(properties: {
    order: keyof ActivityCrmType;
    direction: 'asc' | 'desc';
    page: number;
    limit: number;
    search?: string;
    active?: boolean;
  }): Promise<{
    items: ActivityCrmType[];
    total: number;
  }> {
    return this.fetch('activity/find', {
      method: 'GET',
      params: properties,
    }).then((data) => ({
      total: data.total,
      items: data.items.map((item: any) => ({
        id: item.id,
        name: item.name,
        description: item.description,
        icon: item.icon,
        position: item.position,
        time: item.time,
        primary: item.primary,
        color: item.color,
        updated: new Date(item.updated),
        status: item.status,
      })),
    }));
  }

  async listForCombo(properties: { search?: string; active?: boolean }): Promise<
    {
      label: string;
      value: string;
    }[]
  > {
    return this.fetch('activity/findForCombo', {
      method: 'GET',
      params: properties,
    });
  }

  async create(properties: {
    item: Partial<ActivityCrmType & { signature: string }>;
  }): Promise<Partial<ActivityCrmType>> {
    return this.fetch('activity/create', {
      method: 'POST',
      body: properties.item,
    });
  }

  pick(properties: { activityId: string }): Promise<ActivityCrmType> {
    return this.fetch('activity/pick', {
      method: 'GET',
      params: properties,
    });
  }

  pickForCombo(properties: { activityId: string }): Promise<string> {
    return this.fetch('activity/pickForCombo', {
      method: 'GET',
      params: properties,
    });
  }

  pickIntegrations(properties: { activityId: string }): Promise<
    Partial<{
      landbotId: string;
      landbotTemplateId: string;
    }>
  > {
    return this.fetch('activity/pick/integrations', {
      method: 'GET',
      params: properties,
    });
  }

  update(properties: { activityId: string; item: Partial<ActivityCrmType> }) {
    return this.fetch(`activity/update?activityId=${properties.activityId}`, {
      method: 'PUT',
      body: properties.item,
    });
  }

  updateIntegrations(properties: {
    activityId: string;
    item: Partial<{
      landbotId: string;
      landbotTemplateId: string;
      landbotActivityId: string;
      landbotResultId: string;
    }>;
  }) {
    return this.fetch(`activity/update/integrations?activityId=${properties.activityId}`, {
      method: 'PUT',
      body: properties.item,
    });
  }

  enable(properties: { activityId: string }) {
    return this.fetch('activity/enable', {
      method: 'PUT',
      params: properties,
    });
  }

  disable(properties: { activityId: string }) {
    return this.fetch('activity/disable', {
      method: 'PUT',
      params: properties,
    });
  }

  //SCOPES
  listScopes(properties: { activityId: string; search?: string }): Promise<{
    items: ActivityScopeCrmType[];
    total: number;
  }> {
    return this.fetch('activity/scope/find', {
      params: {
        activityId: properties.activityId,
        search: properties.search ?? '',
      },
      method: 'GET',
    }).then((data) => ({
      total: data.total,
      items: data.items.map((item: any) => ({
        departmentId: item.departmentId,
        departmentName: item.departmentName,
        activityId: item.activityId,
        activityName: item.activityName,
        clinicId: item.clinicId,
        clinicName: item.clinicName,
        clinicColor: item.clinicColor,
      })),
    }));
  }

  createScope(properties: {
    clinicId: string;
    activityId: string;
    departmentId: string;
  }): Promise<void> {
    return this.fetch('activity/scope/create', {
      method: 'POST',
      params: {
        clinicId: properties.clinicId,
        activityId: properties.activityId,
        departmentId: properties.departmentId,
      },
    });
  }

  async createManyScopes(properties: {
    clinicId: string;
    activityId: string;
    departmentIds: string[];
  }) {
    return this.fetch('activity/scope/createMany', {
      method: 'POST',
      body: {
        clinicId: properties.clinicId,
        activityId: properties.activityId,
        departmentIds: properties.departmentIds,
      },
    });
  }

  deleteScope(properties: {
    clinicId: string;
    activityId: string;
    departmentId: string;
  }): Promise<void> {
    return this.fetch('activity/scope/delete', {
      method: 'DELETE',
      params: {
        clinicId: properties.clinicId,
        activityId: properties.activityId,
        departmentId: properties.departmentId,
      },
    });
  }
}
