import { FunnelCrmType } from '@human/crm/types/funnel.crm.type';
import { AuthorizedRepository } from '@core/repository/authorized.repository.core';

export class FunnelCrmRepository extends AuthorizedRepository {
  async list(properties: {
    search?: string;
    channelId?: string;
    direction: 'asc' | 'desc';
    page: number;
    limit: number;
    active?: boolean;
  }): Promise<{ items: FunnelCrmType[]; total: number }> {
    return this.fetch('funnel/find', {
      params: properties,
      method: 'GET',
    }).then((data) => {
      return {
        items: data.items.map((item: any) => ({
          id: item.id,
          name: item.name,
          channelName: item.channelName,
          status: item.status,
          created: new Date(item.created),
        })),
        total: data.total,
      };
    });
  }

  async listForCombo(properties: {
    search?: string;
    channelId?: string;
    active?: boolean;
  }): Promise<{ value: string; label: string }[]> {
    return this.fetch('funnel/findForCombo', {
      params: properties,
      method: 'GET',
    });
  }

  async pick({ id }: { id: string }): Promise<FunnelCrmType> {
    return this.fetch(`funnel/pick?id=${id}`, {
      method: 'GET',
    }).then((data) => ({
      id: data.id,
      name: data.name,
      channelId: data.channelId,
      status: data.status,
    }));
  }

  async pickForCombo(properties: { id: string }): Promise<string> {
    return this.fetch(`funnel/pickForCombo`, {
      method: 'GET',
      params: properties,
    });
  }

  async create({ item }: { item: Partial<FunnelCrmType> }): Promise<void> {
    return this.fetch('funnel/create', {
      method: 'POST',
      body: item,
    });
  }

  async update({ item }: { item: Partial<FunnelCrmType> }): Promise<void> {
    return this.fetch(`funnel/update?id=${item.id}`, {
      method: 'PUT',
      body: item,
    });
  }

  async enable(properties: { funnelId: string }): Promise<boolean> {
    return this.fetch('funnel/enable', {
      method: 'PUT',
      params: properties,
    });
  }

  async disable(properties: { funnelId: string }): Promise<boolean> {
    return this.fetch('funnel/disable', {
      method: 'PUT',
      params: properties,
    });
  }
}
