import { NavigationProp, RouteProp } from '@react-navigation/native';
import { SpecialistsRoutes } from '../../specialists.routes';
import { ModalComponent } from '@components/modal/modal.component';
import { FormComponent } from '@components/form/form.component';
import { useSuccessToast } from '@hooks/successToast/successToast.hook';
import { SpecialistAccessRepository } from '@human/access/repositories/specialist/specialist.repository';
import { SpecialistAccessType } from '@human/access/types/specialist.access.type';
import { useTranslation } from 'react-i18next';
import { TabType } from '@components/tabs/tabs.types';
import { UpdateSpecialistContractsPart } from './parts/update.specialist.contracts.part';
import { TabsComponent } from '@components/tabs/tabs.component';
import { hasPermissionUtilty } from '@utils/permission/has.permission.utility';

export function UpdateSpecialistScreen(properties: {
  navigation: NavigationProp<SpecialistsRoutes, 'update'>;
  route: RouteProp<SpecialistsRoutes, 'update'>;
}) {
  const { showSuccess } = useSuccessToast();
  const { t } = useTranslation();

  const tabs: TabType[] = [
    {
      name: 'specialist',
      icon: 'user',
      content: (settings) => {
        return (
          <FormComponent<SpecialistAccessType>
            readonly={!hasPermissionUtilty({ name: 'access.specialists.editor' })}
            prefix={'access.specialists.update.form'}
            repository={{
              get: () => {
                return new SpecialistAccessRepository().pick({
                  id: properties.route.params.specialistId,
                });
              },
              send: (data) => {
                return new SpecialistAccessRepository()
                  .update({
                    item: data.item,
                    specialistId: properties.route.params.specialistId,
                  })
                  .then(() => showSuccess());
              },
            }}
            fields={[
              {
                name: 'name',
                type: 'text',
                description: true,
                required: true,
                validations: ['name'],
              },
              {
                name: 'surname',
                type: 'text',
                description: true,
                required: true,
                validations: ['name'],
              },
              {
                name: 'dni',
                type: 'text',
                description: true,
                required: true,
                validations: ['dni'],
              },
              {
                name: 'type',
                type: 'select',
                description: true,
                options: [
                  {
                    label: t('common.specialistType.GYNECOLOGIST'),
                    value: 'GYNECOLOGIST',
                  },
                  {
                    label: t('common.specialistType.EMBRYOLOGIST'),
                    value: 'EMBRYOLOGIST',
                  },
                  {
                    label: t('common.specialistType.ATP'),
                    value: 'ATP',
                  },
                ],
              },
            ]}
          />
        );
      },
    },
    UpdateSpecialistContractsPart(properties),
  ];

  return (
    <ModalComponent
      icon="user"
      prefix="access.specialists.update"
      level={properties.route.params.level}
    >
      <TabsComponent tabs={tabs} prefix={'access.specialists.update'} layout="horizontal" />
    </ModalComponent>
  );
}
