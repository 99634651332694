import { StyleSheet } from 'react-native';

export const SessionStyles = StyleSheet.create({
  scroll: {
    flex: 1,
  },
  scrollContainer: {
    gap: 5,
    flex: 1,
    padding: 20,
    maxHeight: 300,
  },
  sessionContainer: {
    flexDirection: 'row',
    borderRadius: 4,
    alignItems: 'center',
    gap: 10,
    overflow: 'hidden',
    backgroundColor: `rgba(255, 255, 255, 0.1)`,
    padding: 5,
  },
  sessionIconContainer: {
    height: 40,
    width: 40,
    backgroundColor: '#00000029',
    borderRightColor: '#00000017',
    borderRightWidth: 1,
  },
  sessionIcon: {
    fontSize: 20,
    textAlign: 'center',
    lineHeight: 40,
  },
  sessionIconSuccess: {
    color: 'rgb(156, 141, 255)',
  },
  sessionIconWarning: {
    color: 'rgb(255, 149, 0)',
  },
  sessionIconDanger: {
    color: 'rgb(255, 0, 85)',
  },
  labelDescriptionContainer: {
    flex: 1,
  },
  label: {
    color: '#fff',
    fontSize: 14,
    lineHeight: 15,
  },
  description: {
    color: '#FFFFFF7B',
    fontSize: 12,
    lineHeight: 15,
  },
  ago: {
    color: '#fff',
    fontSize: 11,
    textAlign: 'center',
    lineHeight: 20,
    backgroundColor: '#00000029',
    paddingLeft: 7,
    paddingRight: 7,
    paddingTop: 2,
    paddingBottom: 2,
    borderRadius: 100,
    marginRight: 8,
  },
});
