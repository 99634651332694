import { FormComponent } from '@components/form/form.component';
import { ClinicAccessRepository } from '@human/access/repositories/clinic/clinic.repository';
import { ContactCrmRepository } from '@human/crm/repositories/contact/contact.repository';
import { CampaignsRoutes } from '@human/crm/sections/campaings/campaigns.routes';
import { NavigationProp } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';

export function CreateContactForm(properties: {
  navigation: NavigationProp<CampaignsRoutes>;
  campaignId: string;
  settings:
    | {
        setIsEditing: (isEditing: boolean) => void;
      }
    | undefined;
}) {
  const { t } = useTranslation();

  return (
    <FormComponent<{
      name: string;
      surname: string;
      email: string;
      prefix: string;
      phone: string;
      zip: string;
      clinicId: string;
      legal: boolean;
      marketing: boolean;
      contactTime: 'MORNING' | 'AFTERNOON' | 'NO_PREFERENCE';
    }>
      onChange={(data) => {
        if (!data.isLocal) return;
        properties.settings?.setIsEditing(true);
      }}
      prefix="crm.campaigns.update.contact"
      repository={{
        send: (data) => {
          return new ContactCrmRepository()
            .createFromCampaign({
              item: {
                ...data.item,
                campaignId: properties.campaignId,
              },
            })
            .then(() => {
              properties.navigation.goBack();
            });
        },
        get: async () => ({
          marketing: false,
          legal: false,
        }),
      }}
      fields={[
        {
          type: 'pair',
          fields: [
            {
              type: 'text',
              name: 'name',
              description: true,
              validations: ['name'],
              required: true,
            },
            {
              type: 'text',
              name: 'surname',
              description: true,
              validations: ['surname'],
              required: true,
            },
          ],
        },
        {
          type: 'pair',
          fields: [
            {
              type: 'text',
              name: 'prefix',
              description: true,
              validations: ['prefix'],
              required: true,
            },
            {
              type: 'text',
              name: 'phone',
              description: true,
              validations: ['phone'],
              required: true,
            },
          ],
        },
        {
          type: 'text',
          name: 'email',
          description: true,
          validations: ['email'],
          required: true,
        },
        {
          type: 'text',
          name: 'zip',
          description: true,
          validations: ['zip'],
        },
        {
          type: 'checkbox',
          name: 'legal',
          description: true,
          required: true,
        },
        {
          type: 'checkbox',
          description: true,
          name: 'marketing',
        },
        {
          type: 'select',
          name: 'contactTime',
          description: true,
          required: true,
          options: [
            {
              value: 'MORNING',
              label: t('common.contactTime.MORNING'),
            },
            {
              value: 'AFTERNOON',
              label: t('common.contactTime.AFTERNOON'),
            },
            {
              value: 'NO_PREFERENCE',
              label: t('common.contactTime.NO_PREFERENCE'),
            },
          ],
        },
        {
          type: 'select',
          description: true,
          name: 'clinicId',
          required: true,
          repository: ({ search }) => {
            return new ClinicAccessRepository().listForCombo({ search, active: true });
          },
        },
      ]}
    />
  );
}
