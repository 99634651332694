// Modules
import { StyleSheet } from 'react-native';

/**
 * Params Styles
 * @constant {StyleSheet} ParamsStyles
 */
export const ParamsStyles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    gap: 10,
  },
  item: {
    alignSelf: 'flex-start',
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: 'rgb(255, 243, 225)',
    borderRadius: 5,
    overflow: 'hidden',
    alignItems: 'center',
    paddingRight: 15,
    marginBottom: 10,
  },
  iconContainer: {
    display: 'flex',
    cursor: 'pointer',
  },
  icon: {
    fontSize: 20,
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    height: 42,
    width: 42,
    marginRight: 15,
    display: 'flex',
    borderRightColor: 'rgba(0, 0, 0, 0.1)',
    borderRightWidth: 1,
    justifyContent: 'center',
    alignItems: 'center',
    color: 'rgb(255, 143, 0)',
  },
  text: {
    fontSize: 13,
    fontWeight: '600',
  },
});
