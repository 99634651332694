import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ModalComponent } from '@components/modal/modal.component';
import { EntryTracksRoutes } from '../../entryTracks.routes';
import { EntryTrackAccessRepository } from '@human/access/repositories/entryTrack/entryTrack.repository';
import { useEffect, useState } from 'react';
import { EntryTrackAccessType } from '@human/access/types/entryTrack.access.type';
import { LoaderComponent } from '@components/loader/loader.component';
import { CreateLeadByEntryTrack } from './screens/createLeadByEntryTrack/create.leadByEntryTrack.screen';
import { CreateContactByEntryTrack } from './screens/createContactByEntryTrack/create.contactByEntryTrack.screen';

/**
 * Update Entry Track Screen
 * @description Esta pantalla se abre cuando seleccionamos una fila en OvoAccess/Registro de entrada de leads
 * Su intencion es checkear la entryTrack de la base de datos. El backend nos devuelve si el contacto ya existe o no mirando la existencia del email o el telefono.
 * Si el contacto ya existe, se abre la pantalla de CreateLeadByEntryTrack para que se cree un lead
 * Si el contacto no existe, se abre la pantalla de CreateContactByEntryTrack para que se cree un contacto y luego un lead asociado al contacto
 */
export function UpdateEntryTrackScreen(properties: {
  navigation: NavigationProp<EntryTracksRoutes>;
  route: RouteProp<EntryTracksRoutes, 'update'>;
}) {
  const [entryTrack, setEntryTrack] = useState<EntryTrackAccessType>();

  useEffect(() => {
    new EntryTrackAccessRepository()
      .pick({
        entryTrackId: properties.route.params.id,
      })
      .then((entryTrack) => {
        setEntryTrack(entryTrack);
      });
  }, []);

  if (!entryTrack) {
    return (
      <ModalComponent icon="user-ninja" prefix="access.entryTracks.update">
        <LoaderComponent loading />;
      </ModalComponent>
    );
  }

  if (entryTrack.contactId) {
    return (
      <CreateLeadByEntryTrack
        entryTrack={{
          ...entryTrack,
          contactId: entryTrack.contactId,
        }}
        navigation={properties.navigation}
        route={properties.route}
      />
    );
  }

  return (
    <CreateContactByEntryTrack
      entryTrack={entryTrack}
      navigation={properties.navigation}
      route={properties.route}
    />
  );
}
