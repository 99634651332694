import { NavigationProp, RouteProp } from '@react-navigation/native';
import { ModalComponent } from '@components/modal/modal.component';
import { FormComponent } from '@components/form/form.component';
import { PatientCrmRepository } from '@human/crm/repositories/patient/patient.repository';
import { useTranslation } from 'react-i18next';
import { PatientsRoutes } from '../../patient.routes';

export function DeletePatientScreen(properties: {
  navigation: NavigationProp<PatientsRoutes>;
  route: RouteProp<PatientsRoutes, 'patientDelete'>;
}) {
  const { t } = useTranslation();
  return (
    <ModalComponent multiWorkspace icon="trash-alt" prefix="crm.patients.delete">
      <FormComponent
        prefix="crm.patients.delete.form"
        padding
        repository={{
          send: async () => {
            if (!confirm(t('crm.patients.delete.form.confirm') || '')) return;
            return new PatientCrmRepository()
              .delete({
                patientId: properties.route.params.patientId,
                clinicId: properties.route.params.clinicId,
              })
              .then(() => {
                properties.navigation.goBack();
              });
          },
        }}
        fields={[]}
      />
    </ModalComponent>
  );
}
