// Modules
import { StyleSheet } from 'react-native';

/**
 * BooleanColumn Styles
 * @constant {StyleSheet} BooleanColumnStyles
 */
export const BooleanColumnStyles = StyleSheet.create({
  container: {
    flex: 1,
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
  },
  containerBoolean: {
    backgroundColor: '#35ED632E',
  },
  containerNoBoolean: {
    backgroundColor: '#ED35352E',
  },
  iconContainer: {
    height: 40,
    borderRadius: 50,
    width: 40,
  },
  icon: {
    fontSize: 19,
    textAlign: 'center',
    lineHeight: 40,
  },
  iconBoolean: {
    color: '#1F953D',
  },
  iconNoBoolean: {
    color: '#ed3535',
  },
});
