import { StyleSheet } from 'react-native';

export const LeadDisabledStyles = StyleSheet.create({
  container: {
    borderWidth: 1,
    borderRadius: 4,
    },
    
  inactiveContainer: {
      borderColor: 'rgba(255, 165, 0, 0.4)',
      backgroundColor: 'rgba(252, 246, 237, 1)',
  },
  canceledContainer: {
      borderColor: 'rgba(249, 65, 68, 0.3)',
      backgroundColor: 'rgba(249, 65, 68, 0.1)',
  },

  contentContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
    overflow: 'hidden',
  },

  iconWrapper: {
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    height: 40,
    width: 40,
  },

  inactiveIconWrapper: {
    backgroundColor: 'rgba(255, 234, 199, 1)'
  },
  canceledIconWrapper: {
    backgroundColor: 'rgba(249, 65, 68, 0.25)',
  },

  icon: {
    fontSize: 20,
    textAlign: 'center',
    lineHeight: 40,
  },
  inactiveIcon: {
    color: 'rgb(255, 165, 0)',
  },
  canceledIcon: {
    color: 'rgba(249, 65, 68, 1)',
  },

  textContainer: {
    flex: 1,
  },
  title: {
    color: 'rgb(96, 96, 96)',
    fontSize: 12,
  },
  description: {
    color: 'rgb(159, 158, 156)',
    fontSize: 10,
    fontWeight: '500',
  },
  dateBadge: {
    fontSize: 10,
    textAlign: 'center',
    lineHeight: 20,
    paddingLeft: 7,
    paddingRight: 7,
    paddingTop: 1,
    paddingBottom: 1,
    borderRadius: 100,
    marginRight: 8,
  },
  inactiveDateBadge: {
    color: 'rgba(255, 165, 0, 0.9)',
    backgroundColor: 'rgba(255, 165, 0, 0.15)',
  },
  canceledDateBadge: {
    color: 'rgba(249, 65, 68, 0.7)',
    backgroundColor: 'rgba(249, 65, 68, 0.2)',
  },
  

});

