import { FieldContainer } from '@components/form/components/fields/field.container';
import { SelectInput } from '@components/form/components/fields/inputs/select/select.input';
import { LeadCrmRepository } from '@human/crm/repositories/lead/lead.repository';

/**
 * Create Action From Calendar Lead Step
 * @description Este formulario sirve para seleccionar un lead para permitir
 * continuar con el proceso de creación de una tarea, su objetivo es desacoplar la necesidad
 * de tener un lead seleccionado y simplificar el proceso de creación de una tarea.
 * @returns JSX.Element
 */
export function SelectLeadStep(properties: {
  clinicId: string;
  leadId?: string;
  onSuccess: (settings: { leadId: string; leadName: string }) => void;
}) {
  return (
    <FieldContainer
      prefix="crm.calendar.actions.create.steps.lead.form"
      type="select"
      required
      padding
      name="leadId"
      description
    >
      <SelectInput<{ leadId: string }>
        type={'select'}
        name="leadId"
        required
        value={{
          leadId: properties.leadId || undefined,
        }}
        description
        onChange={(value, option) => {
          if (!value.leadId) return;
          properties.onSuccess({ leadId: value.leadId, leadName: option?.label ?? '' });
        }}
        pick={(props: { id: string }) => {
          return new LeadCrmRepository()
            .pick({ id: props.id, clinicId: properties.clinicId })
            .then((lead) => {
              return `${lead.contact.name} ${lead.contact.surname}`;
            });
        }}
        repository={(props: { search?: string }) => {
          return new LeadCrmRepository().listByClinicIdForCombo({
            clinicId: properties.clinicId,
            search: props.search,
          });
        }}
      />
    </FieldContainer>
  );
}
