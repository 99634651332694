import { AuthorizedRepository } from '@core/repository/authorized.repository.core';
import { ContactCrmType } from '@human/crm/types/contact.crm.type';
import { LeadCrmType } from '@human/crm/types/lead.crm.type';

export class LeadCrmRepository extends AuthorizedRepository {
  async list(properties: {
    direction: 'asc' | 'desc';
    page: number;
    limit: number;
    search?: string;
    order: string;
    planned?: boolean;
    dates?: {
      start: Date;
      end: Date;
    };
    lastAction?: boolean;
    originCampaignId?: string;
    campaignId?: string;
    clinicId?: string;
    funnelId?: string;
    departmentId?: string;
    originDepartmentId?: string;
    originFunnelId?: string;
    year?: string;
    month?: string;
  }): Promise<{ items: LeadCrmType[]; total: number }> {
    return this.fetch('lead/find', {
      method: 'GET',
      params: properties,
    }).then((data) => {
      return {
        items: data.items.map((item: any) => ({
          id: item.id,
          created: new Date(item.created),
          clinic: item.clinic,
          contactId: item.contactId,
          contactName: item.contactName,
          responsibleId: item.responsibleId,
          responsibleName: item.responsibleName,
          responsibleSurname: item.responsibleSurname,
          clinicId: item.clinicId,
          clinicName: item.clinicName,
          clinicColor: item.clinicColor,
          campaignId: item.campaignId,
          campaignName: item.campaignName,
          reasonId: item.reasonId,
          reasonName: item.reasonName,
          contact: item.contact,
          status: item.status,
          statusUpdated: new Date(item.statusUpdated),
          emails: item.emails,
          phones: item.phones,
          entryStatus: item.entryStatus,
          lastPendingAction: item.lastPendingAction,
        })),
        total: data.total,
      };
    });
  }

  async export(properties: {
    search?: string;
    dates?: {
      start: Date;
      end: Date;
    };
  }): Promise<{ items: LeadCrmType[] }> {
    return this.fetch('lead/export', {
      method: 'GET',
      params: properties,
    });
  }

  async importCsv(properties: { csvFile: File }): Promise<
    | number
    | {
        success: number;
        items: string;
      }
  > {
    const formData = new FormData();
    formData.append('file', properties.csvFile);

    return this.fetchMultiPart('lead/importCsv', {
      formData,
    });
  }

  async listByClinicIdForCombo(properties: {
    clinicId: string;
    search?: string;
  }): Promise<{ value: string; label: string }[]> {
    return this.fetch('lead/findByClinicForCombo', {
      method: 'GET',
      params: properties,
    });
  }

  async listByContactId(properties: {
    contactId: string;
    search?: string;
    direction: 'asc' | 'desc';
    page: number;
    limit: number;
  }): Promise<{ items: LeadCrmType[]; total: number }> {
    return this.fetch('lead/findByContact', {
      method: 'GET',
      params: properties,
    }).then((data) => {
      return {
        items: data.items.map((item: LeadCrmType) => ({
          id: item.id,
          contactId: item.contactId,
          created: new Date(item.created),
          clinicId: item.clinicId,
          clinicName: item.clinicName,
          clinicColor: item.clinicColor,
          campaignId: item.campaignId,
          campaignName: item.campaignName,
          status: item.status,
          statusUpdated: new Date(item.statusUpdated),
        })),
        total: data.total,
      };
    });
  }

  async pick(properties: { id: string; clinicId: string }): Promise<{
    id: string;
    description: string;
    clinicId: string;
    responsibleId: string;
    responsibleName: string;
    responsibleSurname: string;
    contactId: string;
    campaignId: string;
    reasonId: string;
    contact: ContactCrmType;
    specialistId: string;
    specialistName: string;
    phones: {
      id: string;
      phone: string;
      prefix: string;
      contactId: string;
    }[];
    emails: {
      id: string;
      email: string;
      contactId: string;
    }[];
  }> {
    return this.fetch(`lead/pick`, {
      method: 'GET',
      params: {
        leadId: properties.id,
        clinicId: properties.clinicId,
      },
    }).then((data) => ({
      id: data.id,
      description: data.description,
      clinicId: data.clinicId,
      responsibleId: data.responsibleId,
      responsibleName: data.responsibleName,
      responsibleSurname: data.responsibleSurname,
      contactId: data.contactId,
      campaignId: data.campaignId,
      reasonId: data.reasonId,
      reasonName: data.reasonName,
      statusUpdated: new Date(data.statusUpdated),
      specialistId: data.specialistId,
      specialistName: data.specialistName,
      contact: {
        ...data.contact,
        birthDate: new Date(data.contact.birthDate),
        created: new Date(data.contact.created),
      },
      status: data.status,
      canceled: data.canceled,
      phones: data.phones,
      emails: data.emails,
    }));
  }

  async pickProfile(properties: { leadId: string; clinicId: string }): Promise<{
    id: string;
    contactName: string;
    contactSurname: string;
    contactEmails: {
      id: string;
      email: string;
      contactId: string;
    }[];
    contactPhones: {
      id: string;
      phone: string;
      prefix: string;
      contactId: string;
    }[];
    donationPreference?: string;
    birthDate?: Date;
    birthCountry?: string;
    height?: string;
    weight?: string;
    contactLegal: boolean;
    contactMarketing: boolean;
    contactTime: 'MORNING' | 'AFTERNOON' | 'NO_PREFERENCE';
    clinicName: string;
    funnelName: string;
    responsibleName: string;
    responsibleSurname: string;
    status: boolean;
    canceled: boolean;
    campaignName: string;
    originCampaignName: string;
    created: Date;
    patientId?: string;
  }> {
    return this.fetch(`lead/pickProfile`, {
      method: 'GET',
      params: properties,
    }).then((data) => ({
      id: data.id,
      contactName: data.contactName,
      contactSurname: data.contactSurname,
      contactEmails: data.contactEmails,
      contactPhones: data.contactPhones,
      contactLegal: data.contactLegal,
      contactMarketing: data.contactMarketing,
      contactTime: data.contactTime,
      clinicName: data.clinicName,
      funnelName: data.funnelName,
      responsibleName: data.responsibleName,
      responsibleSurname: data.responsibleSurname,
      created: new Date(data.created),
      status: data.status,
      canceled: data.canceled,
      campaignName: data.campaignName,
      originCampaignName: data.originCampaignName,
      donationPreference: data.donationPreference,
      birthDate: new Date(data.birthDate),
      birthCountry: data.birthCountry,
      height: data.height,
      weight: data.weight,
      patientId: data.patientId,
      photo: data.photo,
    }));
  }

  async create({ item }: { item: Partial<LeadCrmType> }): Promise<void> {
    return this.fetch('lead/create', {
      method: 'POST',
      body: item,
    }).then(() => {});
  }

  async update({
    item,
  }: {
    item: Partial<LeadCrmType & { leadId: string; oldClinicId: string }>;
  }): Promise<void> {
    return this.fetch(`lead/update`, {
      method: 'PUT',
      params: {
        item: item.id || '',
      },
      body: item,
    }).then(() => {});
  }

  async updateResponsible({
    item,
  }: {
    item: Partial<{ leadId: string; responsibleId: string; clinicId: string }>;
  }): Promise<void> {
    return this.fetch(`lead/update/responsible`, {
      method: 'PUT',
      body: item,
    }).then(() => {});
  }

  async updateSpecialist(properties: { leadId: string; clinicId: string; specialistId: string }) {
    return this.fetch('lead/updateSpecialist', {
      method: 'PUT',
      body: properties,
    });
  }

  async enable(properties: { leadId: string; clinicId: string }): Promise<void> {
    return this.fetch('lead/enable', {
      method: 'PUT',
      params: properties,
    });
  }

  async disable(properties: {
    leadId: string;
    clinicId: string;
    reasonId?: string;
  }): Promise<void> {
    return this.fetch('lead/disable', {
      method: 'PUT',
      body: {
        leadId: properties.leadId,
        clinicId: properties.clinicId,
        reasonId: properties.reasonId,
      },
    });
  }

  async delete(properties: { leadId: string; clinicId: string }): Promise<void> {
    return this.fetch('lead/delete', {
      method: 'DELETE',
      params: properties,
    });
  }

  async transferLead(properties: {
    campaignId: string;
    leadIds: string[];
    clinicIds: string[];
    responsibleId: string;
  }) {
    return this.fetch('lead/transfer', {
      method: 'PUT',
      body: properties,
    });
  }
}
